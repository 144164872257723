export function clearUserDataInLocalStorage(): void {
  localStorage.removeItem("name");
  localStorage.removeItem("uuid");
  localStorage.removeItem("snsType");
  localStorage.removeItem("email");
  localStorage.removeItem("auto_login");
  localStorage.removeItem("event_notification");
  localStorage.removeItem("service_notification");

  console.log("User data has been cleared from local storage.");
}
