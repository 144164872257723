enum VerifyQrCodeMessage {
  SUCCESS = "success",
  NO_MEMBERSHIP = "no membership",
  NOT_ENOUGH_REMAIN = "not enough remain",
  MEMBERSHIP_DATA_FETCH_FAIL = "Membership data fetch fail",
  INVALID_UUID = "Invalid Uuid",
  STATION_ID_MISMATCH = "station id does not match",
  DOES_NOT_EXIST_QR_DOC = "does not exist qr doc",
  SYSTEM_ERROR = "System error. Please contact your administrator",
}

export default VerifyQrCodeMessage;