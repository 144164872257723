import { Box, Button } from "@mui/material";
import CommonComboBox from "../../../../../component/CommonComboBox";
import CommonNationComboBox, { NationComboBoxMode } from "../../../../../component/CommonNationComboBox";
import VerticalSpacer, { HorizontalSpacer } from "../../../../../component/Spacer";
import ProvinceSelectBox from "../../../../../pagesMobile/MobileProfileEdit/section/ProvinceSelectBox";
import CommonDateRange from "../../../../../component/CommonDateRange";
import { useEffect, useState } from "react";
import { Dayjs } from "dayjs";
import { ApiService } from "../../../../../restAPI/ApiService";
import { CommonSelect } from "../../../../../component/CommonSelect";
import { fontStyle } from "../../../../../theme/Style";
import GHGReductionScooterTabTable from "./component/GHGReductionScooterTabTable";

const GHGReductionScooterTabPage = () => {
  const [docCount, setDocCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // 콤보 박스 검색어
  const [searchContents, setSearchContents] = useState("");
  const [searchSNContents, setSearchSNContents] = useState("");

  // 검색 결과 list 변수
  const [modelNames, setModelNames] = useState([]);
  const [scooterSNList, setScooterSNList] = useState([]);
  const [users, setUsers] = useState([]);
  useEffect(() => {
    ApiService.WebPost("/pw/backend/api/scooterModel/findAll").then((res) => {
      const models = res?.data.body.map((item: { modelName: string }) => item.modelName);
      setModelNames(models); // modelName 배열로 상태 업데이트
    });
  }, []);

  // 필터링을 위해 필요한 변수수
  const [userUuid, setUserUuid] = useState("");
  const [scooterSN, setScooterSN] = useState("");
  const [scooterName, setScooterName] = useState("");
  const [country, setCountry] = useState("");
  const [province, setProvince] = useState("");

  const handleInputChange = (_: any, value: string) => {
    setSearchContents(value); // 검색어 업데이트
  };

  const handleSearchSNContentsChange = (_: any, value: string) => {
    console.log(value);
    setSearchSNContents(value); // 검색어 업데이트
  };

  // 우측 상단 Date Range 관련 변수
  const [dateRange, setDateRange] = useState<{ startDate: Dayjs | null; endDate: Dayjs | null }>({
    startDate: null,
    endDate: null,
  });

  function handleBtnSearch() {
    console.log("userUuid is : ", userUuid);
    console.log("country is : ", country);
    console.log("province is : ", province);
    console.log("dateRange is : ", dateRange);
    console.log("scooterSN is : ", scooterSN);
    console.log("scooterName is : ", scooterName);
    console.log("######################################");
  }

  return (
    <>
      <Box>
        <Box sx={{ display: "flex", gap: "16px" }}>
          <CommonComboBox
            options={users}
            getOptionLabel={(option) => `${option.name} (${option.email})`} // 라벨 형식 정의
            renderOption={(props, option) => (
              <li {...props}>
                {option.name} <br /> {option.phoneNumber} <br /> {option.email}
              </li>
            )} // 옵션 렌더링 정의
            onKeyPress={() => {
              ApiService.WebPost("/pw/users/userList", {
                size: 10,
                page: 1,
                searchCondition: searchContents,
                sns: "",
                status: "",
                membership: "",
              }).then((res) => {
                if (res?.data.body) {
                  console.log(res?.data.body);
                  const formattedRows = res.data.body.list.map((item: any) => ({
                    uuid: item?.value?.uuid || "Unknown",
                    name: item?.value?.name || "Unknown",
                    phoneNumber: item?.value?.phoneNumber || "No Number",
                    email: item?.value?.email || "No Email",
                  }));
                  setUsers(formattedRows);
                }
              });
            }}
            sx={{
              width: "50%",
            }}
            onInputChange={handleInputChange} // 검색어 변경 시 호출
            onChange={(event, value) => {
              if (value?.uuid) {
                setUserUuid(value?.uuid);
              } else {
                setUserUuid("");
              }
            }}
            placeholder="User Name or Email"
          />
          <CommonComboBox
            options={scooterSNList}
            getOptionLabel={(option) => `${option.scooterSN}`} // 라벨 형식 정의
            renderOption={(props, option) => <li {...props}>{option.scooterSN}</li>} // 옵션 렌더링 정의
            onKeyPress={() => {
              ApiService.WebPost("/pw/backend/api/scooter/searchCondition", {
                productName: "",
                searchCondition: searchSNContents,
                size: 10,
                page: 0,
              }).then((res) => {
                console.log(res?.data);
                if (res?.data.body) {
                  const formattedRows = res.data.body.scooterList.map((item: any) => ({
                    scooterSN: item?.serialNumber || "Unknown",
                  }));
                  setScooterSNList(formattedRows);
                }
              });
            }}
            sx={{
              width: "25%",
            }}
            onInputChange={handleSearchSNContentsChange} // 검색어 변경 시 호출
            onChange={(event, value) => {
              if (value?.scooterSN) {
                setScooterSN(value?.scooterSN);
              } else {
                setScooterSN("");
              }
            }}
            placeholder="Scooter Serial Number"
          />
          <CommonSelect
            selectValue={scooterName}
            menuitems={modelNames ? ["All", ...modelNames.map((item: any) => item)] : []}
            onChange={(event) => {
              const selectedValue = event?.target.value;
              // "All"일 경우 빈 문자열로 설정
              setScooterName(selectedValue === "All" ? "" : selectedValue);
            }}
            placeholder="Scooter Name"
            sx={{
              height: "42px",
              width: "25%",
              fontStyle: fontStyle.regularS,
              // color: color.gray600,
              "&.MuiOutlinedInput-root": {
                "& .MuiSelect-select": {
                  display: "flex",
                  alignItems: "center",
                  paddingRight: "16px !important",
                },
                "& fieldset": {
                  borderColor: "white",
                  zIndex: 400,
                },
                "&:hover fieldset": {
                  borderColor: "white",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                  borderWidth: "1px",
                },
              },
            }}
          />
        </Box>
        <VerticalSpacer height={16} />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <CommonNationComboBox
            handleChange={(value) => {
              setCountry(value);
            }}
            mode={NationComboBoxMode.ADMIN}
            placeholder="Country"
          />
          <HorizontalSpacer width={16} />
          <ProvinceSelectBox
            country={country}
            value={province}
            onChange={(event) => {
              setProvince(event);
            }}
            mode={NationComboBoxMode.ADMIN}
            placeholder="City / State / Province"
          />
          <HorizontalSpacer width={16} />
          <CommonDateRange
            value={dateRange}
            onChange={(newValue) => setDateRange(newValue)}
            views={["year", "month", "day"]}
            textFieldSx={{
              // width: "170px",
              height: "42px",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white", // 기본 보더 색상
                },
                "&:hover fieldset": {
                  borderColor: "white",
                },
                "&.Mui-focused fieldset": {
                  border: "1px solid",
                  borderColor: "white",
                },
              },
            }}
            InputPropsSx={{ height: "42px" }}
          />
          <HorizontalSpacer width={16} />
          <Button
            sx={{
              backgroundColor: "#5D38E5",
              borderRadius: 5,
              ":hover": {
                backgroundColor: "#A38BFC",
              },
              color: "white",
              textTransform: "none",
              width: "85px",
              height: "40px",
            }}
            onClick={handleBtnSearch}
          >
            Search
          </Button>
        </Box>
      </Box>
      <VerticalSpacer height={16} />
      <GHGReductionScooterTabTable
        rows={rows}
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </>
  );
};

export default GHGReductionScooterTabPage;
