import { ProductNameDetailResponse } from "../model/ProductNameDetailResponse";
import { ProductNameDetailUiState } from "../model/ProductNameDetailUiState";

export function ProductNameDetailMapper(response: ProductNameDetailResponse) {
  return new ProductNameDetailUiState(
    response.productId,
    response.modelName,
    response.productName,
    response.partsCode,
    response.image
  );
}
