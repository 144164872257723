import { Box, Typography } from "@mui/material";
import { color } from "../../../../../theme/Color";
import { fontStyle } from "../../../../../theme/Style";
import { CarbonReductionUiState } from "../model/CarbonReductionUiState";
import LocalizedDate, {
  DateFormatType,
} from "../../../../../component/LocalizedDate";

interface ReductionHistoryGraphProps {
  uiState: CarbonReductionUiState;
}

export function ReductionHistoryGraph({ uiState }: ReductionHistoryGraphProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "20px",
        height: "276px",
        border: "1px solid",
        borderColor: color.gray300,
        borderRadius: "10px",
      }}
    >
      <Typography fontStyle={fontStyle.regularS}>
        {LocalizedDate({
          date: uiState.historyDay,
          type: DateFormatType.MonthDayWeek,
        })}
      </Typography>
      <Box>
        <Box sx={{ backgroundColor: color.gray200 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              gap: "20px",
              padding: "12px",
            }}
          >
            <Typography
              sx={{ display: "flex", justifyContent: "center", width: "120px" }}
              fontStyle={fontStyle.semiboldS}
              color={color.gray800}
            >
              Start
            </Typography>
            <Typography
              sx={{ display: "flex", justifyContent: "center", width: "120px" }}
              fontStyle={fontStyle.semiboldS}
              color={color.gray800}
            >
              End
            </Typography>
            <Typography
              sx={{ display: "flex", justifyContent: "center", width: "197px" }}
              fontStyle={fontStyle.semiboldS}
              color={color.gray800}
            >
              Mileage
            </Typography>
            <Typography
              sx={{ display: "flex", justifyContent: "center", width: "197px" }}
              fontStyle={fontStyle.semiboldS}
              color={color.gray800}
            >
              Power
            </Typography>
            <Typography
              sx={{ display: "flex", justifyContent: "center", width: "197px" }}
              fontStyle={fontStyle.semiboldS}
              color={color.gray800}
            >
              Carbon Reduction
            </Typography>
          </Box>
        </Box>
        {uiState.reductionHistoryDay.length === 0 && (
          <Typography
            fontStyle={fontStyle.regularS}
            color={color.gray600}
            sx={{ display: "flex", justifyContent: "center", padding: "12px" }}
          >
            There are no driving records for that date.
          </Typography>
        )}
        <Box
          sx={{
            overflowY: "scroll",
            height: "176px",
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: color.gray400,
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: color.white,
            },
          }}
        >
          {uiState.reductionHistoryDay.map((data) => (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                gap: "20px",
                padding: "12px",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "120px",
                }}
                fontStyle={fontStyle.regularS}
                color={color.gray800}
              >
                {data.start}
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "120px",
                }}
                fontStyle={fontStyle.regularS}
                color={color.gray800}
              >
                {data.end}
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "197px",
                }}
                fontStyle={fontStyle.regularS}
                color={color.gray800}
              >
                {data.mileage}
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "197px",
                }}
                fontStyle={fontStyle.regularS}
                color={color.gray800}
              >
                {data.power}
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "197px",
                }}
                fontStyle={fontStyle.regularS}
                color={color.gray800}
              >
                {data.carbonReduction}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
