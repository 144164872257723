import { useState } from "react";
import CommonList from "../../../component/CommonList";
import { Box, Divider } from "@mui/material";
import { ADMIN_CONFIG } from "../../../constants/AdminConfig";
import { HorizontalSpacer } from "../../../component/Spacer";

import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import BatteryReplacementPage from "./section/batteryReplacementTab/BatteryReplacementPage";
import ScooterTabPage from "./section/scooterTab/ScooterTabPage";
import StationPowerUsagePage from "./section/stationPowerUsageTab/StationPowerUsagePage";

const RowDataCheckTablePage = () => {
  const [tabVelue, setTabVelue] = useState("scooter");
  const TabButton = () => {
    return (
      <Box sx={{ display: "flex", height: "24px" }}>
        <HorizontalSpacer width={16} />
        <Box
          sx={{
            fontStyle: fontStyle.semiboldM,
            cursor: "pointer",
            color: tabVelue == "scooter" ? color.primary500 : color.gray600,
          }}
          onClick={() => {
            setTabVelue("scooter");
          }}
        >
          Scooter
        </Box>
        <HorizontalSpacer width={16} />
        <Divider orientation="vertical" />
        <HorizontalSpacer width={16} />
        <Box
          sx={{
            fontStyle: fontStyle.semiboldM,
            cursor: "pointer",
            color: tabVelue == "batteryReplacement" ? color.primary500 : color.gray600,
          }}
          onClick={() => {
            setTabVelue("batteryReplacement");
          }}
        >
          Battery Replacement
        </Box>
        <HorizontalSpacer width={16} />
        <Divider orientation="vertical" />
        <HorizontalSpacer width={16} />
        <Box
          sx={{
            fontStyle: fontStyle.semiboldM,
            cursor: "pointer",
            color: tabVelue == "stationPowerUsage" ? color.primary500 : color.gray600,
          }}
          onClick={() => {
            setTabVelue("stationPowerUsage");
          }}
        >
          Station Power Usage
        </Box>
        <HorizontalSpacer width={16} />
      </Box>
    );
  };
  return (
    <>
      <CommonList title={ADMIN_CONFIG.MENU_ROW_DATA_CHECK.TITLE} setModal={null} tabButton={TabButton()}>
        {tabVelue == "scooter" && <ScooterTabPage />}
        {tabVelue == "batteryReplacement" && <BatteryReplacementPage />}
        {tabVelue == "stationPowerUsage" && <StationPowerUsagePage />}
      </CommonList>
    </>
  );
};

export default RowDataCheckTablePage;
