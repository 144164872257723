import { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import { CommonSelect } from "../../../../component/CommonSelect";
import VerticalSpacer from "../../../../component/Spacer";
import CommonTextField from "../../../../component/CommonTextField";
import CommonErrorText from "../../../../component/CommonErrorText";
import PlaceGoogleMap from "./component/PlaceGoogleMap";
import { RegionMngCreateUiState } from "./model/RegionMngCreateUiState";
import { RegionMngCreateUiEvent } from "./model/RegionMngCreateUiEvent";
import { color } from "../../../../theme/Color";
import { fontStyle } from "../../../../theme/Style";
import { ApiService } from "../../../../restAPI/ApiService";

interface RegionMngCreateGridProps {
  uiState: RegionMngCreateUiState;
  uiEvent: RegionMngCreateUiEvent;
}

const RegionMngCreateGrid = ({
  uiState,
  uiEvent,
}: RegionMngCreateGridProps) => {
  const [countryList, setCountryList] = useState([]);
  useEffect(() => {
    ApiService.WebPost("/pw/aw/contents/getCountry").then((res) => {
      console.log(res?.data.body);
      setCountryList(res?.data.body);
    });
  }, []);

  return (
    <Grid container columnSpacing={2.75} sx={{ mt: 0 }}>
      <Grid item lg={12}>
        <Card
          sx={{
            borderRadius: "15px",
            minHeight: 240,
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardContent sx={{ width: "100%", height: "100%" }}>
            <CardHeader
              title={"Details"}
              titleTypographyProps={{ fontStyle: fontStyle.semiboldL }}
              // sx={{ ml: -2, mb: 0 }}
            />
            <Box
              sx={{
                m: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* 첫번째 줄 */}
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ width: "48.5%" }}>
                  <Typography
                    fontStyle={fontStyle.boldXS}
                    color={color.gray600}
                  >
                    Name
                  </Typography>
                  <CommonTextField
                    value={uiState.Name}
                    onChange={(event) => {
                      uiEvent.HandleUiStateChange("Name", event.target.value);
                    }}
                    sx={{
                      mt: "8px",
                      width: "100%",
                      height: "48px",
                    }}
                    InputProps={{
                      style: {
                        height: "48px",
                        borderRadius: "10px",
                        // backgroundColor: color.gray200,
                        // color: color.gray600, // 비활성화 상태일 때 색상을 지정
                      },
                    }}
                    error={uiState.NameError}
                  />
                  {uiState.NameError && (
                    <CommonErrorText
                      value="Please enter a name."
                      sx={{ ml: 1, mt: "4px" }}
                    />
                  )}
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <Typography
                    fontStyle={fontStyle.boldXS}
                    color={color.gray600}
                  >
                    Country
                  </Typography>
                  <CommonSelect
                    selectValue={uiState.Country}
                    menuitems={countryList}
                    onChange={(event) => {
                      uiEvent.HandleUiStateChange(
                        "Country",
                        event.target.value
                      );
                    }}
                    sx={{
                      height: "48px",
                      width: "100%",
                      mt: "8px",
                      borderRadius: "10px",
                      fontStyle: fontStyle.regularS,
                      color: "black",
                    }}
                    error={uiState.CountryError}
                  />
                  {uiState.CountryError && (
                    <CommonErrorText
                      value="Please enter a country."
                      sx={{ ml: 1, mt: "4px" }}
                    />
                  )}
                </Box>
              </Box>
              <VerticalSpacer height={24} />
              {/* 두번째 줄 */}
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ width: "48.5%" }}>
                  <Typography
                    fontStyle={fontStyle.boldXS}
                    color={color.gray600}
                  >
                    State / Province / Region
                  </Typography>
                  <CommonTextField
                    value={uiState.StateProvinceRegion}
                    onChange={(event) => {
                      uiEvent.HandleUiStateChange(
                        "StateProvinceRegion",
                        event.target.value
                      );
                    }}
                    sx={{
                      mt: "8px",
                      width: "100%",
                      height: "48px",
                    }}
                    InputProps={{
                      style: {
                        height: "48px",
                        borderRadius: "10px",
                        // backgroundColor: color.gray200,
                        // color: color.gray600, // 비활성화 상태일 때 색상을 지정
                      },
                    }}
                    error={uiState.StateProvinceRegionError}
                  />
                  {uiState.StateProvinceRegionError && (
                    <CommonErrorText
                      value="Please enter a State / Province / Region."
                      sx={{ ml: 1, mt: "4px" }}
                    />
                  )}
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <Typography
                    fontStyle={fontStyle.boldXS}
                    color={color.gray600}
                  >
                    Location
                  </Typography>
                  <CommonTextField
                    sx={{
                      mt: "8px",
                      width: "100%",
                      height: "48px",
                    }}
                    InputProps={{
                      style: {
                        height: "48px",
                        borderRadius: "10px",
                        // backgroundColor: color.gray200,
                        // color: color.gray600, // 비활성화 상태일 때 색상을 지정
                      },
                    }}
                    value={uiState.Location}
                    error={uiState.LocationError}
                    disabled
                  />
                </Box>
              </Box>
              <VerticalSpacer height={24} />
              {/* 세번째 줄 */}
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ width: "48.5%" }}>
                  <Typography
                    fontStyle={fontStyle.boldXS}
                    color={color.gray600}
                  >
                    Latitude
                  </Typography>
                  <CommonTextField
                    sx={{
                      mt: "8px",
                      width: "100%",
                      height: "48px",
                    }}
                    InputProps={{
                      style: {
                        height: "48px",
                        borderRadius: "10px",
                        // backgroundColor: color.gray200,
                        // color: color.gray600, // 비활성화 상태일 때 색상을 지정
                      },
                    }}
                    value={uiState.Latitude.toString()}
                    error={uiState.LatitudeError}
                    disabled
                  />
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <Typography
                    fontStyle={fontStyle.boldXS}
                    color={color.gray600}
                  >
                    Longitude
                  </Typography>
                  <CommonTextField
                    sx={{
                      mt: "8px",
                      width: "100%",
                      height: "48px",
                    }}
                    InputProps={{
                      style: {
                        height: "48px",
                        borderRadius: "10px",
                        // backgroundColor: color.gray200,
                        // color: color.gray600, // 비활성화 상태일 때 색상을 지정
                      },
                    }}
                    value={uiState.Longitude.toString()}
                    error={uiState.LongitudeError}
                    disabled
                  />
                </Box>
              </Box>
              <VerticalSpacer height={24} />
              {/* 네번째 줄 */}
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Address
                </Typography>
                <VerticalSpacer height={8} />
                <PlaceGoogleMap uiState={uiState} uiEvent={uiEvent} />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default RegionMngCreateGrid;
