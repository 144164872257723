import { Box, Typography } from "@mui/material";
import { color } from "../../../../theme/Color";
import { fontStyle } from "../../../../theme/Style";
import VerticalSpacer from "../../../../component/Spacer";
import { LocalizedText } from "../../../../di/LanguageRepositoryProvider";
import { ICON_X_DEFAULT } from "../../../../constants/appImagePath";
import { CSReceipt } from "../model/CSReceipt";
import LocalizedDate, {
  DateFormatType,
} from "../../../../component/LocalizedDate";

interface ReceiptDrawerProps {
  receipt: CSReceipt;
  closeDrawer: () => void;
}

const ReceiptDrawer = ({ receipt, closeDrawer }: ReceiptDrawerProps) => {
  const TitleRow = ({ title, price }: { title: string; price: number }) => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography fontStyle={fontStyle.titleL} color={color.default}>
        {title}
      </Typography>
      <Typography fontStyle={fontStyle.titleL} color={color.default}>
        $ {price.toLocaleString()}
      </Typography>
    </Box>
  );

  const ItemRow = ({
    label,
    quantity,
    price,
  }: {
    label: string;
    quantity: number;
    price: number;
  }) => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "16px",
        }}
      >
        <Typography
          fontStyle={fontStyle.bodyLr}
          color={color.gray600}
          sx={{
            width: "230px",
            wordBreak: "break-word",
            whiteSpace: "pre-wrap",
            flexShrink: 0,
          }}
        >
          {label}
        </Typography>
        <Typography
          fontStyle={fontStyle.bodyLr}
          color={color.gray600}
          sx={{
            textAlign: "center",
            minWidth: "20px",
          }}
        >
          {quantity}
        </Typography>
      </Box>
      <Typography fontStyle={fontStyle.bodyLr} color={color.gray600}>
        $ {price.toLocaleString()}
      </Typography>
    </Box>
  );

  const InfoBox = ({
    title,
    content,
    bgcolor,
    titleColor,
  }: {
    title: string;
    content: string;
    bgcolor: string;
    titleColor: string;
  }) => (
    <Box
      sx={{
        bgcolor: bgcolor,
        p: "16px",
        boxSizing: "border-box",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      }}
    >
      <Typography fontStyle={fontStyle.titleM} color={titleColor}>
        {title}
      </Typography>
      <Typography
        fontStyle={fontStyle.bodyMr}
        color={color.gray800}
        sx={{
          whiteSpace: "pre-line",
          wordBreak: "break-word",
        }}
      >
        {content}
      </Typography>
    </Box>
  );

  const getTotalConsumablesPrice = () => {
    let total = 0;
    if ((receipt.repairFee ?? []).length > 0) {
      receipt.repairFee?.forEach((item) => {
        total += item.amount;
      });
    }

    return total;
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        p: "20px",
        boxSizing: "border-box",
      }}
    >
      {/* Header */}
      <Box borderBottom={`2px solid ${color.gray800}`}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            fontStyle={fontStyle.bodyLr}
            color={color.gray700}
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "calc(100% - 40px)",
            }}
          >
            {receipt.stationName}
          </Typography>
          <img
            src={ICON_X_DEFAULT}
            alt="close"
            style={{
              width: "14px",
              height: "14px",
            }}
            onClick={closeDrawer}
          />
        </Box>
        <Typography fontStyle={fontStyle.headingS} color={color.default}>
          $ {receipt.totalFee?.toLocaleString()}
        </Typography>
        <Typography fontStyle={fontStyle.bodyLr} color={color.gray600}>
          {LocalizedDate({
            date: receipt.completeDate,
            type: DateFormatType.YearMonthDayTime,
          })}
        </Typography>
        <VerticalSpacer height={12} />
      </Box>

      {/* Receipt */}
      {receipt.totalFee && receipt.totalFee > 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: "12px",
            gap: "12px",
          }}
        >
          <TitleRow
            title={LocalizedText("ua_f312_consumables")}
            price={getTotalConsumablesPrice()}
          />
          {receipt.repairFee?.map((item, index) => (
            <ItemRow
              key={index}
              label={item.name}
              quantity={item.count}
              price={item.amount}
            />
          ))}
          <Box sx={{ borderTop: `1px dashed ${color.gray300}` }} />
          <TitleRow
            title={LocalizedText("ua_f312_costs")}
            price={receipt.maintenanceCosts ?? 0}
          />
          <Box sx={{ borderTop: `1px dashed ${color.gray300}` }} />
          <TitleRow
            title={LocalizedText("ua_f312_total")}
            price={receipt.totalFee ?? 0}
          />

          <VerticalSpacer height={28} />
        </Box>
      ) : (
        <VerticalSpacer height={20} />
      )}

      {/* Info */}
      <InfoBox
        title={LocalizedText("ua_f312_problem")}
        content={receipt.problem ?? ""}
        bgcolor={color.gray50}
        titleColor={color.gray800}
      />
      <VerticalSpacer height={16} />
      <InfoBox
        title={LocalizedText("ua_f312_correction")}
        content={receipt.correct ?? ""}
        bgcolor={color.primary50}
        titleColor={color.primary500}
      />

      <VerticalSpacer height={16} />
    </Box>
  );
};

export default ReceiptDrawer;
