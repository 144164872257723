import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import { HorizontalSpacer } from "../../../../component/Spacer";
import CommonDatePicker from "../../../../component/CommonDatePicker";
import CommonTextField from "../../../../component/CommonTextField";
import dayjs from "dayjs";
import { CommonSelect } from "../../../../component/CommonSelect";

export function NFTInfoCard({ uiState, uiEvent }: any) {
  const countryMap: { [key: string]: string } = {
    "South Korea": "KR",
    Cambodia: "KH",
    Vietnam: "VN",
  };

  const getCountryName = (code: string) => {
    return (
      Object.keys(countryMap).find((key) => countryMap[key] === code) || code
    );
  };

  return (
    <Grid item lg={12}>
      <Card
        sx={{
          borderRadius: 3,
          width: "100%",
          // display: "flex",
          boxShadow: "unset",
          border: "1px solid",
          borderColor: color.gray300,
        }}
      >
        <CardContent>
          <Box>
            <Typography fontStyle={fontStyle.semiboldL} color={color.default}>
              Information
            </Typography>
          </Box>
        </CardContent>
        <CardContent>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box sx={{ width: "33%" }}>
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Country
              </Typography>
              <CommonSelect
                selectValue={getCountryName(uiState.country)}
                placeholder="Select"
                menuitems={Object.keys(countryMap)}
                onChange={(event) => {
                  uiEvent.handleUiStateChange(
                    "country",
                    countryMap[event.target.value]
                  );
                  uiEvent.LevelInfoReset();
                }}
                sx={{
                  width: "100%",
                  height: "48px",
                  fontStyle: fontStyle.regularS,
                  color: "black",
                  mt: "8px",
                }}
                error={uiState.errors.countryError}
              />
              {uiState.errors.countryError && (
                <Typography fontStyle={fontStyle.regularXS} color={color.error}>
                  Please select a country.
                </Typography>
              )}
            </Box>
            <HorizontalSpacer width={28} />
            <Box sx={{ width: "33%" }}>
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                NFT Character name
              </Typography>
              <CommonTextField
                value={uiState.nftCharacterName}
                onChange={(e) => {
                  uiEvent.handleUiStateChange(
                    "nftCharacterName",
                    e.target.value
                  );
                }}
                sx={{
                  width: "100%",
                  height: "48px",
                  mt: "8px",
                }}
                InputProps={{ style: { height: "48px" } }}
                error={uiState.errors.nftCharacterNameError}
              />
              {uiState.errors.nftCharacterNameError && (
                <Typography fontStyle={fontStyle.regularXS} color={color.error}>
                  Please enter a NFT character name.
                </Typography>
              )}
            </Box>
            <HorizontalSpacer width={28} />
            <Box sx={{ width: "33%" }}>
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Distribute Date
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  mt: 1,
                  justifyContent: "space-between",
                }}
              >
                <CommonDatePicker
                  value={
                    uiState.distributeDate === 0
                      ? null
                      : dayjs(uiState.distributeDate)
                  }
                  onChange={(date) => {
                    uiEvent.handleUiStateChange(
                      "distributeDate",
                      date?.valueOf() || 0
                    );
                  }}
                  views={["year", "month", "day"]}
                  textFieldSx={{ width: "100%" }}
                  InputPropsSx={{ height: "48px" }}
                  placeholder="Select Date"
                  error={uiState.errors.distributeDateError}
                />
              </Box>
              {uiState.errors.distributeDateError && (
                <Typography fontStyle={fontStyle.regularXS} color={color.error}>
                  Please select a distribute date.
                </Typography>
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}
