import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { color } from "../../../../../theme/Color";
import { fontStyle } from "../../../../../theme/Style";
import { NFTInfo } from "../model/UserWalletUiState";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import VerticalSpacer, { HorizontalSpacer } from "../../../../../component/Spacer";
import LocalizedDate, {
  DateFormatType,
} from "../../../../../component/LocalizedDate";

//TODO Api 연동되면 데이터 연동 및 Transaction History 클릭이벤트 추가필요요

interface UserWalletNFTPageProps {
  data: NFTInfo;
}

export function UserWalletNFTPage({ data }: UserWalletNFTPageProps) {
  const renderTextRow = (label: string, value: string) => (
    <Box sx={{ display: "flex", height: "20px", gap: "8px" }}>
      <Typography fontStyle={fontStyle.regularS} color={color.gray600}>
        {label}
      </Typography>
      <Typography fontStyle={fontStyle.regularS}>{value}</Typography>
    </Box>
  );

  return (
    <Grid item xs={4}>
      <Card
        sx={{
          width: "100%",
          padding: "0px",
          border: "1px solid",
          borderColor: color.gray300,
          borderRadius: "15px",
          boxShadow: "unset",
        }}
      >
        <CardContent
          sx={{
            padding: "0px",
            "&:last-child": {
              paddingBottom: "0px",
            },
          }}
        >
          {/* 이미지 영역 */}
          <Box
            component="img"
            src={data.image}
            sx={{ width: "100%", height: "330.67px" }}
          ></Box>

          <Box sx={{ width: "100%", height: "246px", padding: "20px" }}>
            {/* NFT 타입과 구분선 */}
            <Box sx={{ display: "flex", alignItems: "center", height: "18px" }}>
              <Typography
                fontStyle={fontStyle.semiboldXS}
                color={color.gray600}
              >
                NFT
              </Typography>
              <Box
                sx={{
                  width: "1px",
                  height: "12px",
                  backgroundColor: color.gray400,
                  margin: "0 8px",
                }}
              />
              <Typography
                fontStyle={fontStyle.semiboldXS}
                color={color.gray600}
              >
                {data.nftType}
              </Typography>
            </Box>
            {/* NFT 이름 */}
            <Box sx={{ height: "28px" }}>
              <Typography fontStyle={fontStyle.semiboldL}>
                {data.nftName}
              </Typography>
            </Box>
            {/* Issuer, Issued, Received, Symbol */}
            <Box
              sx={{
                height: "112px",
                borderBottom: "1px solid",
                borderColor: color.gray300,
              }}
            >
              {renderTextRow("Issuer", data.issuer)}
              {renderTextRow(
                "Issued",
                LocalizedDate({
                  date: data.issued,
                  type: DateFormatType.AdminType,
                })
              )}
              {renderTextRow(
                "Received",
                LocalizedDate({
                  date: data.received,
                  type: DateFormatType.AdminType,
                })
              )}
              {renderTextRow("Symbol", data.symbol)}
            </Box>
            <VerticalSpacer height={20} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Typography fontStyle={fontStyle.semiboldS} color={color.gray600}>
                Transaction History
              </Typography>
              <HorizontalSpacer width={4} />
              <ArrowForwardIosRoundedIcon
                sx={{ height: "12px", width: "12px", color: color.gray600 }}
              />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}
