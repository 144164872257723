export class BatteryDetailUiState {
  status: string;
  data: BatteryDetailDataType;
  battSerialNumber: string;
  backDialog: boolean;
  updateDialog: boolean;
  constructor(
    status: string = "",
    data: BatteryDetailDataType = defaultBatteryDetailData,
    battSerialNumber: string = "",
    backDialog: boolean = false,
    updateDialog: boolean = false
  ) {
    this.status = status;
    this.data = data;
    this.battSerialNumber = battSerialNumber;
    this.backDialog = backDialog;
    this.updateDialog = updateDialog;
  }
}

export type BatteryDetailDataType = {
  battSerialNumber: string;
  timestamp: number;
  type: string;
  station: string;
  status: string;
  balancing: string;
  alarm: string;
  alarmStatus: string;
  serialPararell: string;
  packCapacity: string;
  userD: string;
  packCount: string;
  voltage: string;
  soc: string;
  cv1: string;
  cv2: string;
  cv3: string;
  cv4: string;
  cv5: string;
  cv6: string;
  cv7: string;
  cv8: string;
  cv9: string;
  cv10: string;
  t1: string;
  t2: string;
  t3: string;
  t4: string;
  t5: string;
  t6: string;
  registerDt: string;
  lastUptDt: string;
};

export const defaultBatteryDetailData: BatteryDetailDataType = {
  battSerialNumber: "",
  timestamp: 0,
  type: "",
  station: "",
  status: "",
  balancing: "",
  alarm: "",
  alarmStatus: "",
  serialPararell: "",
  packCapacity: "",
  userD: "",
  packCount: "",
  voltage: "",
  soc: "",
  cv1: "",
  cv2: "",
  cv3: "",
  cv4: "",
  cv5: "",
  cv6: "",
  cv7: "",
  cv8: "",
  cv9: "",
  cv10: "",
  t1: "",
  t2: "",
  t3: "",
  t4: "",
  t5: "",
  t6: "",
  registerDt: "",
  lastUptDt: "",
};
