import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { color } from "../../../../../theme/Color";
import { fontStyle } from "../../../../../theme/Style";
import VerticalSpacer from "../../../../../component/Spacer";
import { ICON_SELECT } from "../../../../../constants/imagePath";
import { CarbonReductionUiState } from "../model/CarbonReductionUiState";
import LocalizedDate, {
  DateFormatType,
} from "../../../../../component/LocalizedDate";
import { CarbonReductionUiEvent } from "../model/CarbonReductionUiEvent";

interface CouponCardProps {
  uiState: CarbonReductionUiState;
  uiEvent: CarbonReductionUiEvent;
}

export function CouponCard({ uiState, uiEvent }: CouponCardProps) {
  return (
    <Grid item lg={12}>
      <Card
        sx={{
          padding: "0px",
          border: "1px solid",
          borderColor: color.gray300,
          borderRadius: "15px",
          boxShadow: "unset",
        }}
      >
        <CardContent
          sx={{
            padding: "24px",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography fontStyle={fontStyle.semiboldL}>Coupon</Typography>
            {uiState.coupon.length > 6 && (
              <Box
                component="img"
                src={ICON_SELECT}
                sx={{
                  width: "28px",
                  height: "28px",
                  display: "flex",
                  cursor: "pointer",
                  transform:
                    uiState.visibleCoupon === 6 ? "" : "rotate(180deg)",
                }}
                onClick={uiEvent.handleLearnMore}
              />
            )}
          </Box>
          <VerticalSpacer height={16} />
          {uiState.coupon.length === 0 && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid",
                borderColor: color.gray300,
                borderRadius: "10px",
                height: "120px",
                padding: "16px",
                marginBottom: "20px",
              }}
            >
              <Typography fontStyle={fontStyle.regularS} color={color.gray600}>
                There is no coupon history.
              </Typography>
            </Box>
          )}
          <Grid container spacing={2.5}>
            {uiState.coupon
              .sort((a, b) => {
                // Usable 상태를 우선순위로 정렬
                if (a.status === "Usable" && b.status !== "Usable") return -1;
                if (a.status !== "Usable" && b.status === "Usable") return 1;

                // 같은 그룹 내에서는 날짜를 기준으로 최신순 정렬
                const dateA =
                  a.status === "Usable" ? a.availableDate : a.issuedDate;
                const dateB =
                  b.status === "Usable" ? b.availableDate : b.issuedDate;

                return new Date(dateB).getTime() - new Date(dateA).getTime(); // 최신순 정렬
              })
              .slice(0, uiState.visibleCoupon)
              .map((info, index) => (
                <Grid item xs={4} key={index}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      px: "20px",
                      backgroundColor:
                        info.status === "Usable" ? "" : color.gray200,
                      border: "1px solid",
                      borderColor: color.gray300,
                      borderRadius: "10px",
                      width: "100%",
                      height: "120px",
                    }}
                  >
                    <Box sx={{ flex: 1 }}>
                      <Typography
                        fontStyle={fontStyle.regularS}
                        color={color.gray700}
                      >
                        {info.name}
                      </Typography>
                      <Typography
                        fontStyle={fontStyle.semiboldL}
                        color={
                          info.status === "Usable"
                            ? color.primary500
                            : color.gray600
                        }
                        sx={{
                          width: "205px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {info.content}
                      </Typography>
                      {/*Usable일때 */}
                      <Typography
                        fontStyle={fontStyle.regularS}
                        color={color.gray700}
                      >
                        {info.status === "Usable" &&
                          `~ ${LocalizedDate({
                            date: info.availableDate,
                            type: DateFormatType.AdminType,
                          })}`}
                      </Typography>
                      {/*Used, Expired일때 */}
                      {(info.status === "Used" ||
                        info.status === "Expired") && (
                        <Box>
                          <Box sx={{ display: "flex", gap: "5px" }}>
                            <Typography
                              fontStyle={fontStyle.regularS}
                              color={color.gray600}
                            >
                              Issue Date
                            </Typography>
                            <Typography fontStyle={fontStyle.regularS}>
                              {LocalizedDate({
                                date: info.issuedDate,
                                type: DateFormatType.AdminType,
                              })}
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", gap: "5px" }}>
                            <Typography
                              fontStyle={fontStyle.regularS}
                              color={color.gray600}
                            >
                              {info.status}
                            </Typography>
                            <Typography fontStyle={fontStyle.regularS}>
                              {LocalizedDate({
                                date: info.usedDate,
                                type: DateFormatType.AdminType,
                              })}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        sx={{
                          px: "8px",
                          py: "4px",
                          border: "1px solid",
                          borderRadius: "8px",
                          borderColor: color.gray300,
                        }}
                        fontStyle={fontStyle.boldXS}
                        color={color.gray700}
                      >
                        {info.status}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              ))}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
