import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Dialog, DialogContent, DialogContentText, IconButton, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CommonList from "../../component/CommonList";
import InquiryDetailsGrid from "./section/InquiryDetailsGrid";
import { InquiryDetailsSideEffect } from "./model/InquiryDetailsSideEffect";
import { InquiryDetailsUiState } from "./model/InquiryDetailsUiState";
import { InquiryDetailsUiEvent } from "./model/InquiryDetailsUiEvent";
import VerticalSpacer from "../../component/Spacer";
import { ADMIN_CONFIG } from "../../constants/AdminConfig";

interface InquiryDetailsRenderProps {
  uiState: InquiryDetailsUiState;
  uiEvent: InquiryDetailsUiEvent;
  sideEffect: InquiryDetailsSideEffect;
}

export default function InquiryDetailsRender({ uiState, uiEvent, sideEffect }: InquiryDetailsRenderProps) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOk = () => {
    navigate("/inquiryMng");
    window.location.reload();
  };
  const HeaderBackButton = () => {
    return (
      <IconButton
        sx={{
          ":hover": { backgroundColor: "#F2F2F2" },
          mb: 0.5,
          mr: 1,
        }}
        onClick={handleClickOpen}
      >
        <ArrowBackIosNewIcon fontSize="medium" />
      </IconButton>
    );
  };

  return (
    <CommonList title="1:1 Inquiry Details" headerBackButton={HeaderBackButton()}>
      <VerticalSpacer height={12} />
      <InquiryDetailsGrid uiState={uiState} uiEvent={uiEvent} sideEffect={sideEffect} />
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogContent
          sx={{
            width: 450,
            height: 170,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              fontSize: 15,
              color: "black",
            }}
          >
            {ADMIN_CONFIG.MSG.EDIT_STOP_AND_MOVE}<br/>
            {ADMIN_CONFIG.MSG.EDIT_STOP_NOT_SAVED}
          </DialogContentText>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              mt: 1.5,
            }}
          >
            <Button
              onClick={handleClose}
              sx={{
                textTransform: "none",
                height: 40,
                //   backgroundColor: "#5D38E5",
                color: "#5D38E5",
                borderRadius: 5,
                border: 1,
                borderColor: "#5D38E5",
                width: 95,
                ":hover": {
                  backgroundColor: "#E9E9E9",
                },
              }}
            >
              Cancle
            </Button>
            <Button
              onClick={handleOk}
              sx={{
                textTransform: "none",
                height: 40,
                backgroundColor: "#5D38E5",
                color: "white",
                borderRadius: 5,
                width: 75,
                ml: 2,
                ":hover": {
                  backgroundColor: "#A38BFC",
                },
              }}
              autoFocus
            >
              OK
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </CommonList>
  );
}
