import { Box, CardContent, Typography } from "@mui/material";
import { fontStyle } from "../../../../../../../theme/Style";
import VerticalSpacer, { HorizontalSpacer } from "../../../../../../../component/Spacer";
import { color } from "../../../../../../../theme/Color";
import { CommonRadio } from "../../../../../../../component/CommonRadio";
import { useState } from "react";
import { MaterialType } from "../../model/MaterialsData";
import { CommonSelect } from "../../../../../../../component/CommonSelect";
import { ICON_ADDIMAGE, ICON_NOIMAGE } from "../../../../../../../constants/imagePath";
import CommonTextField from "../../../../../../../component/CommonTextField";
import { ADMIN_CONFIG } from "../../../../../../../constants/AdminConfig";

interface Field {
  value?: MaterialType;
  label: string;
}

export function MaterialBOMTree() {
  const [type, setType] = useState<MaterialType>(MaterialType.NONE);
  const [quantities, setQuantities] = useState("");
  const [used, setUsed] = useState("");

  const radioTypes: Field[] = [
    { label: "None", value: MaterialType.NONE },
    { label: "Serial Number", value: MaterialType.SERIAL_NUMBER },
    { label: "Manual Count", value: MaterialType.MANUAL_COUNT },
    { label: "Memo", value: MaterialType.MEMO },
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (Object.values(MaterialType).includes(value as MaterialType)) {
      setType(value as MaterialType);
      console.log("Selected Material Type:", value);
    }
  };
  return (
    <CardContent
      sx={{
        width: "100%",
        padding: "0px",
        "&:last-child": {
          paddingBottom: 0,
        },
      }}
    >
      <Typography fontStyle={fontStyle.semiboldL}>Bill of Material Tree</Typography>
      <VerticalSpacer height={16} />
      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "50%" }}>
          <Box>
            <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
              Category
            </Typography>
            <CommonSelect
              placeholder="Select"
              menuitems={[
                ADMIN_CONFIG.PAGE_MATERIAL.AL,
                ADMIN_CONFIG.PAGE_MATERIAL.ASSY,
                ADMIN_CONFIG.PAGE_MATERIAL.ELECTRIC,
                ADMIN_CONFIG.PAGE_MATERIAL.ETC,
                ADMIN_CONFIG.PAGE_MATERIAL.HARNESS,
                ADMIN_CONFIG.PAGE_MATERIAL.MD,
                ADMIN_CONFIG.PAGE_MATERIAL.PD,
                ADMIN_CONFIG.PAGE_MATERIAL.SEMI_ASSY,
              ]}
              onChange={() => {}}
              selectValue=""
              sx={{
                height: "48px",
                width: "100%",
                mt: "8px",
                borderRadius: "10px",
                fontStyle: fontStyle.regularS,
                color: color.gray600,
              }}
            />
          </Box>
          <VerticalSpacer height={24} />
          <Box>
            <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
              Parts Code
            </Typography>
            <CommonTextField
              onChange={() => {}}
              value=""
              sx={{ width: "100%", height: "48px", mt: "8px" }}
              InputProps={{
                sx: {
                  height: "48px",
                },
              }}
            />
          </Box>
          <VerticalSpacer height={24} />
          <Box>
            <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
              Product Name
            </Typography>
            <CommonTextField
              onChange={() => {}}
              value=""
              sx={{ width: "100%", height: "48px", mt: "8px" }}
              InputProps={{
                sx: {
                  height: "48px",
                },
              }}
            />
          </Box>
          <VerticalSpacer height={24} />
          <Box>
            <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
              Company
            </Typography>
            <CommonTextField
              onChange={() => {}}
              value=""
              sx={{ width: "100%", height: "48px", mt: "8px" }}
              InputProps={{
                sx: {
                  height: "48px",
                },
              }}
            />
          </Box>
          <VerticalSpacer height={24} />
          <Box>
            <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
              Spec
            </Typography>
            <CommonTextField
              onChange={() => {}}
              multiline
              rows={6}
              value=""
              sx={{ width: "100%", height: "160px", mt: "8px" }}
              InputProps={{
                sx: {
                  height: "160px",
                },
              }}
            />
          </Box>
        </Box>
        <HorizontalSpacer width={28} />
        <Box sx={{ width: "50%" }}>
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Type
          </Typography>
          <VerticalSpacer height={8} />
          <Box sx={{ display: "flex", height: "48px", alignItems: "center" }}>
            {radioTypes.map((radioType, index) => (
              <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
                <CommonRadio<MaterialType>
                  selectValue={type}
                  label={radioType.label}
                  value={radioType.value}
                  onchange={handleChange}
                  sx={{ width: "14.4px", height: "14.4px" }}
                />
                <HorizontalSpacer width={8} />
                <Typography fontStyle={fontStyle.semiboldXS} color={color.gray800}>
                  {radioType.label}
                </Typography>
                <HorizontalSpacer width={16} />
              </Box>
            ))}
          </Box>
          <VerticalSpacer height={40} />
          <Box sx={{ display: "flex", flexGrow: 1 }}>
            <Box
              sx={{
                display: "flex",
                flexGrow: 1,
                flexDirection: "column",
                width: "50%",
              }}
            >
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Quantities
              </Typography>
              <VerticalSpacer height={8} />
              <CommonSelect
                sx={{ minHeight: "48px", flexGrow: 1 }}
                selectValue={quantities}
                onChange={(event) => {
                  setQuantities(event.target.value);
                }}
                menuitems={["al", "ASSY", "ETC"]}
                placeholder="Select"
              />
            </Box>
            <HorizontalSpacer width={12} />
            <Box
              sx={{
                display: "flex",
                flexGrow: 1,
                flexDirection: "column",
                width: "50%",
              }}
            >
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Used
              </Typography>
              <VerticalSpacer height={8} />
              <CommonSelect
                sx={{
                  minHeight: "48px",
                  flexGrow: 1,
                  borderColor: color.gray300,
                  backgroundColor: color.gray200,
                }}
                selectValue={used}
                onChange={(event) => {
                  setUsed(event.target.value);
                }}
                menuitems={["al", "ASSY", "ETC"]}
                placeholder="-"
                disabled
              />
            </Box>
          </Box>
          <VerticalSpacer height={40} />
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Image
          </Typography>
          <VerticalSpacer height={8} />
          <Box
            sx={{
              borderRadius: "10px",
              height: "324px",
              background: color.gray200,
              display: "flex",
              justifyContent: "center",
              alignItems: "stretch",
              cursor: "pointer",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "113px",
                flexGrow: 1,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box component="img" src={ICON_ADDIMAGE} sx={{ width: "140px", height: "140px" }} />
              <Typography fontStyle={fontStyle.semiboldS} color={color.gray600}>
                Add Image
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </CardContent>
  );
}
