import { useState } from "react";
import HasNFTRender from "./HasNFTRender";
import { HasNFTUiState } from "./HasNFTUiState";
import { HasNFTUiEvent } from "./HasNFTUiEvent";
import { HasScooterUiEvent } from "../../HasScooterUiEvent";
import { MoreCarbonReductionNFTUiState } from "../../../MoreCarbonReductionNFTUiState";
import { MoreCarbonReductionNFTUiEvent } from "../../../MoreCarbonReductionNFTUiEvent";
import useAndroidBackHandler from "../../../../../customHook/useAndroidBackHandler";

interface HasNFTPageProps {
  parentUiState: MoreCarbonReductionNFTUiState;
  parentUiEvent: MoreCarbonReductionNFTUiEvent;
}

const HasNFTPage = ({ parentUiState, parentUiEvent }: HasNFTPageProps) => {
  const [uiState, setUiState] = useState(new HasNFTUiState());
  const uiEventHandler: HasNFTUiEvent = {
    HandleDrawerOpenChange: (event: boolean) => {
      if (window.Android) {
        window.Android.isShownDialog(event);
      }

      if (window.webkit) {
        window.webkit.messageHandlers.isShownDialog.postMessage(event);
      }
      setUiState((prev) => {
        return {
          ...prev,
          drawerOpen: event,
        };
      });
    },
  };

  useAndroidBackHandler(() => {
    window.history.back();
  }, []);

  return (
    <>
      <HasNFTRender
        uiState={uiState}
        uiEvent={uiEventHandler}
        parentUiState={parentUiState}
        parentUiEvent={parentUiEvent}
      />
    </>
  );
};

export default HasNFTPage;
