import { NFTResponse } from "../model/NFTResponse";
import { NFTInfo, NFTUiState } from "../model/NFTUiState";

export function NFTMapper(response: NFTResponse) {
  const nftInfo: NFTInfo[] = [
    {
      no: response.no,
      nftImage: response.nftImage,
      nftType: response.nftType,
      symbol: response.symbol,
      nftId: response.nftId,
      issuer: response.issuer,
      issueDate: response.issueDate,
      lastUpdatedDate: response.lastUpdatedDate,
    },
  ];
  return new NFTUiState(nftInfo);
}
