import { Box, FormControl, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import { useState } from "react";
import { MoreProfileEditPageUiEvent } from "../MoreProfileEditPageUiEvent";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import { color } from "../../../theme/Color";
import { useNavigate } from "react-router-dom";
import SimpleDialog from "../../../component/dialog/SimpleDialog";
import CommonNationComboBox, { NationComboBoxMode } from "../../../component/CommonNationComboBox";

interface EditPhoneNumberFieldProps {
  type?: string;
  title?: string;
  localNumber?: string;
  phoneNumber?: string;
  localNumberError?: string;
  phoneNumberError?: string;
  uiEvent?: MoreProfileEditPageUiEvent;
}

const formatPhoneNumber = (value: string) => {
  // 숫자만 추출
  const cleaned = ("" + value).replace(/\D/g, "");

  // 포맷에 맞게 변환
  if (cleaned.length <= 3) {
    return cleaned;
  }
  if (cleaned.length <= 7) {
    return cleaned.slice(0, 3) + "-" + cleaned.slice(3);
  }
  return cleaned.slice(0, 3) + "-" + cleaned.slice(3, 7) + "-" + cleaned.slice(7, 11);
};

const EditPhoneNumberField = ({ type, title, localNumber, phoneNumber, localNumberError, phoneNumberError, uiEvent }: EditPhoneNumberFieldProps) => {
  // const handleLocaleNumberChange = (event: SelectChangeEvent) => {
  //   uiEvent?.HandleLocaleNumberChange(event.target.value);
  // };
  const handleLocaleNumberChange = (value: string) => {
    uiEvent?.HandleLocaleNumberChange(value);
  };

  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    // const formattedPhoneNumber = formatPhoneNumber(value);
    uiEvent?.HandlePhoneNumberChange(value);
  };

  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  return (
    <>
      <Typography fontStyle={fontStyle.titleS}>{title}</Typography>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {type === "Phone" && (
          <Box sx={{ display: "flex", width: "100%", height: "50px" }}>
            {/* <Select
              sx={{
                height: "50px",
                minWidth: "70px",
                bgcolor: "white",
                "&:before": {
                  borderColor: localNumberError ? color.error : color.gray400, // 호버 시 테두리 색상
                },
                "&:hover:not(.Mui-disabled):before": {
                  borderColor: localNumberError
                    ? color.error
                    : color.primary500, // 호버 시 테두리 색상
                },
                "&.Mui-focused:after": {
                  borderColor: localNumberError
                    ? color.error
                    : color.primary500, // 포커스 시 테두리 색상
                },
                "&:after": {
                  borderBottomColor: localNumberError
                    ? color.error
                    : color.primary500, // 포커스가 풀린 후 기본 색상
                },
              }}
              variant="standard"
              value={localNumber}
              // onClick={(event) => {
              //   setDialogOpen(true);
              // }}
              onChange={handleLocaleNumberChange}
              disabled={false}
            >
              <MenuItem value="+82">+82</MenuItem>
              <MenuItem value="+855">+855</MenuItem>
            </Select> */}
            <CommonNationComboBox mode={NationComboBoxMode.PHONE} handleChange={handleLocaleNumberChange} defaultValue={localNumber} />
            <TextField
              variant="standard"
              size="medium"
              value={phoneNumber}
              autoComplete="off"
              inputProps={{
                style: {
                  height: "50px",
                  padding: "0px 0px",
                },
                inputMode: "numeric",
                typeof: "tel",
              }}
              // onClick={(event) => {
              //   setDialogOpen(true);
              // }}
              onChange={handlePhoneNumberChange}
              sx={{
                ml: "8px",
                flexGrow: 1,
                "& .MuiInput-root": {
                  "&:before": {
                    borderColor: phoneNumberError ? color.error : color.gray400, // 호버 시 테두리 색상
                  },
                  "&:hover:not(.Mui-disabled):before": {
                    borderColor: phoneNumberError ? color.error : color.primary500, // 호버 시 테두리 색상
                  },
                  "&.Mui-focused:after": {
                    borderColor: phoneNumberError ? color.error : color.primary500, // 포커스 시 테두리 색상
                  },
                  "&:after": {
                    borderBottomColor: phoneNumberError ? color.error : color.primary500, // 포커스가 풀린 후 기본 색상
                  },
                },
              }}
              // InputProps= {
              //   {readOnly: true}
              // }
            />
          </Box>
        )}
        {localNumberError === "" && phoneNumberError === "" ? null : (
          <>
            <Typography fontStyle={fontStyle.bodySr} color={"#FF4C4C"} sx={{ mt: "8.75px" }}>
              {LocalizedText("ua_g210_enterphonenumber")}
            </Typography>
          </>
        )}
      </Box>
      <SimpleDialog
        children={LocalizedText("ua_g210_alert_updatephone_message")}
        isOpen={dialogOpen}
        onNagative={() => {
          setDialogOpen(false);
        }}
        onPositive={() => {
          navigate("/MoreProfilePhoneAuth");
        }}
        onClose={() => {
          setDialogOpen(false);
        }}
        positiveText="OK"
      />
    </>
  );
};

export default EditPhoneNumberField;
