import { TableRow } from "@mui/material";
import { RegisterFindPartsTableCell } from "./RegisterFindPartsTableCell";
import { CheckboxState, CommonCheckbox } from "../../../../../../component/CommonCheckbox";
import { Text } from "../../../../../../component/Text";
import { fontStyle } from "../../../../../../theme/Style";
import { color } from "../../../../../../theme/Color";
import { ProductionManagementCellData } from "../../../../model/ProductionManagementCellData";

interface RegisterFindPartsTableRowProps {
  row: any;
}

export function RegisterFindPartsTableRow({ row }: RegisterFindPartsTableRowProps) {
  const productCellData: ProductionManagementCellData[] = [
    new ProductionManagementCellData("", "left", "20px", "4px"),
    new ProductionManagementCellData(`${row.partsCode}`, "left", "120px"),
    new ProductionManagementCellData(`${row.productName}`, "left", "123px"),
    new ProductionManagementCellData(`${row.spec}`, "left"),
    new ProductionManagementCellData(`${row.company}`, "left", "120px"),
    new ProductionManagementCellData(`${row.category}`, "left", "120px", "", "4px"),
  ];
  return (
    <TableRow>
      {productCellData.map((data: ProductionManagementCellData, index: number) => (
        <RegisterFindPartsTableCell
          key={data.value}
          sx={{
            paddingLeft: data.paddingLeft,
            paddingRight: data.paddingRight,
            width: data.width || "auto",
            ...(index === productCellData.length - 3 ? { flex: 1, maxWidth: "232px" } : {}),
          }}
          childrenContainerSx={{ width: data.width }}
          align={data.align}
          children={
            index === 0 ? (
              <CommonCheckbox
                checkboxState={CheckboxState.Unchecked} // 초기 상태
                checkboxName=""
                onClick={(state) => console.log("Item 1:", state)} // 개별 체크박스 클릭 핸들러
                sx={{ height: "16px", width: "16px", margin: 0 }}
              />
            ) : (
              <Text
                sx={{
                  fontStyle: fontStyle.regularXS,
                  color: color.gray800,
                }}
                text={data.value}
              />
            )
          }
        />
      ))}
    </TableRow>
  );
}
