import { Box, Typography } from "@mui/material";
import CommonButton from "../../component/CommonButton";
import { MoreProfilePageSideEffect } from "./MoreProfilePageSideEffect";
import Toolbar from "../../component/Toolbar";
import VerticalSpacer from "../../component/Spacer";
import { fontStyle } from "../../theme/Style";
import InputField from "./section/InputField";
import { LocalizedText } from "../../di/LanguageRepositoryProvider";
import LocalizedDate, { DateFormatType } from "../../component/LocalizedDate";
import { ProfilePageUiState } from "./model/ProfilePageUiState";
import ProfileDetailItem from "./section/ProfileDetailItem";
import { color } from "../../theme/Color";
import { Divider, HorizontalGrayDivider } from "../../component/Divider";

interface MoreProfileRenderProps {
  uiState: ProfilePageUiState;
  sideEffect: MoreProfilePageSideEffect;
}

const MoreProfileRender = ({ uiState, sideEffect }: MoreProfileRenderProps) => {
  return (
    <>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* 상단 toolbar */}
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Toolbar
            title={LocalizedText("ua_g200_profile")}
            onBack={() => {
              window.history.back();
            }}
          />
        </Box>
        {/* 중간 요소 */}
        <Box
          sx={{
            backgroundColor: color.gray100,
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
        >
          <ProfileDetailItem
            label={LocalizedText("ua_g200_name")}
            content={uiState.name}
          />
          <ProfileDetailItem
            label={LocalizedText("ua_g200_email")}
            content={uiState.email}
          />
          <ProfileDetailItem
            label={LocalizedText("ua_g210_phonenumber")}
            content={`+${uiState.countryNumber} ${uiState.phoneNumber}`}
          />
          <ProfileDetailItem
            label={LocalizedText("ua_g200_birthofdate")}
            content={
              uiState.birthday == 0
                ? ""
                : LocalizedDate({
                    date: uiState.birthday,
                    type: DateFormatType.YearMonthDay,
                  })
            }
          />
          <ProfileDetailItem
            label={LocalizedText("ua_g200_gender")}
            content={uiState.gender}
          />
          <Divider height={12} />
          <ProfileDetailItem
            label={LocalizedText("ua_g200_address")}
            content={uiState.address.trim() == "," ? "" : uiState.address}
          />
          <Divider height={12} />
          <ProfileDetailItem
            label={LocalizedText("ua_g200_tribecategory")}
            content={
              uiState.tribeCategory != ""
                ? `${uiState.tribeCategory},\n${uiState.tribeName}`
                : ""
            }
          />
          <Divider height={12} />
          <VerticalSpacer height={12} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
            onClick={() => {
              sideEffect.navigate("Withdrawal");
            }}
          >
            <Typography
              sx={{
                //display: "inline-block",
                borderBottom: 1,
                color: color.gray600,
                textAlign: "center",
              }}
              fontStyle={fontStyle.subTitleM}
            >
              {LocalizedText("ua_g220_deleteaccount")}
            </Typography>
          </Box>
        </Box>
        {/* 하단 버튼 */}
        <Box
          sx={{
            position: "absolute",
            bottom: "0px",
            width: "100%",
            display: "flex",
          }}
        >
          <CommonButton
            title={LocalizedText("ua_g200_editprofile_button")}
            textColor="white"
            onClick={() => {
              sideEffect.navigate("EditProfile");
            }}
            sx={{
              mx: "20px",
              mb: "14px",
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default MoreProfileRender;
