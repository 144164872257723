import { useState } from "react";
import { Box, ClickAwayListener, IconButton, Typography } from "@mui/material";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import { HorizontalSpacer } from "../../../../component/Spacer";
import CommonTooltip from "../../../../component/CommonTooltip";
import {
  ICON_INFO_CIRCLE_GRAY,
  ICON_COPY,
} from "../../../../constants/appImagePath";
import CopyToClipboard from "react-copy-to-clipboard";
import LocalizedDate, {
  DateFormatType,
} from "../../../../component/LocalizedDate";
import { LocalizedText } from "../../../../di/LanguageRepositoryProvider";

interface CSDetailInfoProps {
  vin: string;
  station: string;
  stationAddress: string;
  date: number;
  openToast: () => void;
}

export function CSDetailInfo({
  vin,
  station,
  stationAddress,
  date,
  openToast,
}: CSDetailInfoProps) {
  const [tootltipOpen, setTooltipOpen] = useState(false);

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  const tooltipContent = (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        maxWidth: "300px",
      }}
    >
      <Typography
        fontStyle={fontStyle.bodySr}
        color={color.gray700}
        sx={{ flex: 1 }}
      >
        {stationAddress}
      </Typography>
      <CopyToClipboard
        text={stationAddress}
        onCopy={() => {
          openToast();
        }}
      >
        <IconButton
          sx={{
            ml: 1,
            p: 0,
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <img
            src={ICON_COPY}
            alt="Copy"
            style={{
              width: "16px",
              height: "16px",
            }}
          />
        </IconButton>
      </CopyToClipboard>
    </Box>
  );

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography fontStyle={fontStyle.titleM} color={color.gray800}>
          {LocalizedText("ua_f312_vin")}
        </Typography>

        <HorizontalSpacer width={8} />
        <Box
          bgcolor={color.gray300}
          sx={{
            height: "12px",
            width: "1px",
          }}
        />
        <HorizontalSpacer width={8} />
        <Typography fontStyle={fontStyle.bodyMr} color={color.default}>
          {vin}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography
          fontStyle={fontStyle.titleM}
          color={color.gray800}
          sx={{
            flexShrink: 0,
          }}
        >
          {LocalizedText("ua_f312_csstation")}
        </Typography>
        <HorizontalSpacer width={8} />
        <Box
          bgcolor={color.gray300}
          sx={{
            height: "12px",
            width: "1px",
          }}
        />
        <HorizontalSpacer width={8} />
        <Typography
          fontStyle={fontStyle.bodyMr}
          color={color.default}
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {station}
        </Typography>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Box>
            <CommonTooltip
              open={tootltipOpen}
              onClose={handleTooltipClose}
              title={tooltipContent}
            >
              <IconButton
                sx={{
                  py: 0,
                }}
                onClick={handleTooltipOpen}
              >
                <img
                  src={ICON_INFO_CIRCLE_GRAY}
                  alt="Alert"
                  style={{
                    width: "18px",
                    height: "18px",
                  }}
                />
              </IconButton>
            </CommonTooltip>
          </Box>
        </ClickAwayListener>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography fontStyle={fontStyle.titleM} color={color.gray800}>
          {LocalizedText("ua_f312_dateandtime")}
        </Typography>

        <HorizontalSpacer width={8} />
        <Box
          bgcolor={color.gray300}
          sx={{
            height: "12px",
            width: "1px",
          }}
        />
        <HorizontalSpacer width={8} />
        <Typography fontStyle={fontStyle.bodyMr} color={color.default}>
          {LocalizedDate({
            date: date,
            type: DateFormatType.YearMonthDayTime,
          })}
        </Typography>
      </Box>
    </Box>
  );
}
