import React, { createContext, useContext, useRef, useState } from "react";
import { ToastDuration, ToastModel, ToastType } from "./ToastModel";
import ConcurrentToast from "./Toast";
import { getLanguageCode } from "../../customHook/useLocale";

/**
 * Global Toast 사용 방법
 * 1. const { showToast } = useToast(); 선언
 * 2. showToast("메시지", duration, type); 호출
 */

interface ToastContextProps {
  showToast: (
    message: string,
    duration?: ToastDuration,
    type?: ToastType,
  ) => void;
}

const ToastContext = createContext<ToastContextProps | undefined>(undefined);

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};

// useTemporalErrorToast 커스텀 Hook
export const useTemporalErrorToast = () => {
  const { showToast } = useToast();

  // 언어팩 key : common_toast_unknown
  const getErrorMessage = (language: string) => {
    switch (language) {
      case "km":
        return "មិនស្គាល់កំហុសមួយបានកើតឡើង។";
      case "ko":
        return "알 수 없는 오류가 발생했어요.";
      default:
        return "An unknown error occurred.";
    }
  };

  return () => {
    const language = getLanguageCode();
    const message = getErrorMessage(language);

    showToast(message, ToastDuration.SHORT, ToastType.error);
  };
};

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [toasts, setToasts] = useState<ToastModel[]>([]);
  const timerRef = useRef<NodeJS.Timeout | null>(null); // 타이머를 추적하는 ref

  const showToast = (
    message: string,
    duration = ToastDuration.SHORT,
    type: ToastType = ToastType.info,
  ) => {
    const id = Date.now().toString();
    const newToast: ToastModel = { id, message, duration, type };

    setToasts([newToast]); // 기존 토스트를 모두 제거하고 새로운 토스트만 추가

    // 기존 타이머가 있다면 클리어하고 새로운 타이머를 설정
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // 일정 시간이 지나면 자동 제거
    timerRef.current = setTimeout(() => {
      setToasts([]); // 모든 토스트 제거
    }, duration);
  };

  /**
   * 아래 주석된 코드는 Toast를 스텍형식으로 쌓는 방식입니다.
   * */
  // const showToast = (
  //   message: string,
  //   duration = ToastDuration.SHORT,
  //   type: ToastType = ToastType.info,
  // ) => {
  //   const id = Date.now().toString();
  //   const newToast: ToastModel = { id, message, duration, type };
  //
  //   setToasts((prev) => {
  //     // 중복된 메시지의 토스트 제거
  //     const filteredToasts = prev.filter((toast) => toast.message !== message);
  //
  //     return [...filteredToasts, newToast];
  //   });
  //
  //   // 일정 시간이 지나면 자동 제거
  //   setTimeout(() => {
  //     setToasts((prev) => prev.filter((toast) => toast.id !== id));
  //   }, duration);
  // };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <div
        className="toast-container"
        style={{
          position: "fixed", // 화면에 고정
          bottom: "16px", // 하단 여백
          display: "flex",
          flexDirection: "column",
          left: "50%", // 중앙 정렬
          transform: "translateX(-50%)", // 정확하게 중앙으로
          zIndex: 9999, // 최상위
          alignItems: "center",
          pointerEvents: "none", // 클릭 이벤트 통과
        }}
      >
        {toasts.map((toast) => (
          <ConcurrentToast key={toast.id} toast={toast} />
        ))}
      </div>
    </ToastContext.Provider>
  );
};
