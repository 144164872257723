import { useEffect, useState } from "react";
import { MaterialsTable } from "./MaterialsTable";
import { MaterialsData } from "./model/MaterialsData";
import CommonSearch from "../../../../../component/CommonSearch";
import { HeadCell } from "../../../../../component/CommonTable";
import VerticalSpacer from "../../../../../component/Spacer";
import { Box } from "@mui/material";
import { MaterialsRadio } from "./MaterialsRadio";
import { color } from "../../../../../theme/Color";
import { ApiService } from "../../../../../restAPI/ApiService";

export function Materials() {
  const [searchContents, setSearchContents] = useState("");
  const [docCount, setDocCount] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [rows, setRows] = useState<MaterialsData[]>([]);
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState(0);

  // useEffect(() => {
  //   const filteredData = MaterialsList.filter((item) => {
  //     return searchContents
  //       ? item.category.toLowerCase().includes(searchContents.toLowerCase()) || item.company.toLowerCase().includes(searchContents.toLowerCase())
  //       : true;
  //   });
  //   const startData = page * rowsPerPage;
  //   const endData = startData + rowsPerPage;
  //   const pagedata = filteredData.slice(startData, endData);
  //   setRows(pagedata);
  //   setPageCount(Math.ceil(filteredData.length / rowsPerPage)); // 전체 페이지 수 계산
  //   setDocCount(filteredData.length);
  // }, [page, rowsPerPage, searchContents]);

  useEffect(() => {
    ApiService.webPost("/pw/aw/material/manage/list", {
      page: page + 1,
      size: rowsPerPage,
      searchContents: searchContents,
    })
      .then((res) => {
        if (res.data.body) {
          setRows(res?.data.body || []);
          setPageCount(Math.ceil(res?.data.docCount / rowsPerPage));
          setDocCount(res?.data.docCount);
        }
      })
      .catch(() => {});
  }, [page, rowsPerPage, searchContents]);
  return (
    <>
      <CommonSearch placeHolder={"Parts Code, Product Name, Company"} searchContents={searchContents} setSearchContents={setSearchContents}>
        <Box
          sx={{
            display: "flex",
            backgroundColor: color.white,
            borderRadius: "10px",
            px: "16px",
            py: "12px",
          }}
        >
          <MaterialsRadio labels={{ forwardLabel: "Forward", reverseLabel: "Reverse" }} />
        </Box>
      </CommonSearch>
      <VerticalSpacer height={16} />
      <MaterialsTable
        headCells={MaterialsHeadCells}
        docCount={docCount}
        rows={rows}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </>
  );
}

const MaterialsHeadCells: HeadCell[] = [
  {
    disablePadding: false,
    align: "left",
    id: "no",
    label: "No",
  },

  // {
  //   disablePadding: false,
  //   align: "left",
  //   id: "level",
  //   label: "level",
  // },
  {
    disablePadding: false,
    align: "left",
    id: "category",
    label: "Category",
  },
  {
    disablePadding: false,
    align: "left",
    id: "partsCode",
    label: "Parts Code",
  },
  {
    disablePadding: false,
    align: "left",
    id: "productName",
    label: "Product Name",
  },
  {
    disablePadding: false,
    align: "left",
    id: "spec",
    label: "Spec",
  },
  {
    disablePadding: false,
    align: "left",
    id: "quantities",
    label: "Quantities",
  },
  // {
  //   disablePadding: false,
  //   align: "left",
  //   id: "amount",
  //   label: "Amount",
  // },
  {
    disablePadding: false,
    align: "left",
    id: "company",
    label: "Company",
  },
];
