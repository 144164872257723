import { Box, Typography } from "@mui/material";
import { color } from "../../../theme/Color";
import { fontStyle } from "../../../theme/Style";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";

export interface MonthlyReductionData {
  month: string;
  value: number;
}

interface MonthlyReductionChartProps {
  data: MonthlyReductionData[];
  currentDate: string;
}

const MonthlyReductionChart = ({
  data,
  currentDate,
}: MonthlyReductionChartProps) => {
  const noCarbonReductionString = LocalizedText("ua_h100_no_carbonreduction");

  const maxValue = Math.max(...data.map((item) => item.value));
  const isFullWidth = data.length === 6;

  //막대바 Width 계산
  const totalGaps = 5 * 16;
  const barWidth = `calc((100% - ${totalGaps}px) / 6)`;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        bgcolor: "white",
        borderRadius: "10px",
        p: "16px",
        gap: "16px",
      }}
    >
      <Typography fontStyle={fontStyle.titleM} color={color.default}>
        {LocalizedText("ua_h100_monthly_carbonreduction")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          height: "166px",
          gap: "16px",
          justifyContent: isFullWidth ? "space-between" : "center",
          px: "20px",
          boxSizing: "border-box",
        }}
      >
        {data.length === 0 && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Typography fontStyle={fontStyle.bodySr} color={color.gray600}>
              {noCarbonReductionString}
            </Typography>
          </Box>
        )}
        {data.length > 0 &&
          data.map((item, index) => {
            const barHeight = (item.value / maxValue) * 110;
            const isLastItem = index === data.length - 1;

            return (
              <Box
                key={index}
                sx={{
                  width: barWidth,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                <Typography
                  fontStyle={
                    isLastItem ? fontStyle.subTitleS : fontStyle.bodySr
                  }
                  color={isLastItem ? color.secondary600 : color.gray500}
                >
                  {item.value}
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    height: `${barHeight}px`,
                    bgcolor: isLastItem ? color.secondary600 : color.gray200,
                    borderRadius: "8px",
                    transition: "height 0.3s ease",
                    flexGrow: 1,
                  }}
                />
                <Typography fontStyle={fontStyle.bodySr} color={color.gray600}>
                  {item.month}
                </Typography>
              </Box>
            );
          })}
      </Box>
      <Typography
        fontStyle={fontStyle.bodySr}
        color={color.gray600}
        sx={{ textAlign: "right" }}
      >
        {currentDate}
      </Typography>
    </Box>
  );
};

export default MonthlyReductionChart;
