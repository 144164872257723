import { useEffect, useState } from "react";
import { ProductNameDetailPage } from "./ProductNameDetailPage";
import { ProductNameDetailUiState } from "./model/ProductNameDetailUiState";
import { ProductNameDetailUiEvent } from "./model/ProductNameDetailUiEvent";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiService } from "../../../../../restAPI/ApiService";
import { ProductNameDetailMapper } from "./mapper/ProductNameDetailMapper";
import { ProductNameDetailResponse } from "./model/ProductNameDetailResponse";
import { UpdateResponse } from "./model/UpdateResponse";

export function ProductNameDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const id = pathname.split("/").pop() || "";
  const [uiState, setUiState] = useState(new ProductNameDetailUiState());
  const uiEvent: ProductNameDetailUiEvent = {
    HandleUiStateChange: (key: keyof ProductNameDetailUiState, value: any) => {
      setUiState((prevState) => ({
        ...prevState,
        [key]: value, // key에 해당하는 상태를 업데이트
      }));
    },
    backButtonClick: () => {
      uiEvent.HandleUiStateChange("backDialogOpen", true);
    },

    backClose: () => {
      uiEvent.HandleUiStateChange("backDialogOpen", false);
    },
    backOk: () => {
      navigate("/modelManagement");
      window.location.reload();
    },
    updateButtonClick: () => {
      uiEvent.HandleUiStateChange("updateDialogOpen", true);
    },

    updateClose: () => {
      uiEvent.HandleUiStateChange("updateDialogOpen", false);
    },
    updateOk: () => {
      uiEvent.HandleUiStateChange("updateDialogOpen", false);
      checkAndUpdate();
    },
  };
  useEffect(() => {
    const fetchDetailInfo = async () => {
      try {
        if (!uiState.productId) {
          console.log("failed!");
          return;
        }
        const result = await getProductNameDetail();
        if (result === undefined) {
          return;
        }
        setUiState(ProductNameDetailMapper(result));
      } catch {
        console.log("failed");
      }
    };
    fetchDetailInfo();
  }, [uiState.productId]);

  const checkAndUpdate = async () => {
    try {
      const result = await updateProductName();
      if (result?.result) {
        window.location.reload();
      } else {
        console.log("update failed");
      }
    } catch (e) {
      console.log("update failed");
    }
  };

  async function getProductNameDetail(): Promise<
    ProductNameDetailResponse | undefined
  > {
    const url = "";
    const requestBody = {};

    try {
      const res = await ApiService.webPost(url, requestBody);
      const data = res.data;
      if (typeof data === "object" && Object.keys(data).length === 0)
        return undefined;
      return data;
    } catch (e) {
      throw e;
    }
  }

  async function updateProductName(): Promise<UpdateResponse | undefined> {
    const url = "";
    const requestBody = {};

    try {
      const res = await ApiService.webPost(url, requestBody);
      const data = res.data;
      if (typeof data === "object" && Object.keys(data).length === 0)
        return undefined;
      return data;
    } catch (e) {
      throw e;
    }
  }

  return <ProductNameDetailPage uiState={uiState} uiEvent={uiEvent} />;
}
