import { Box, Radio, FormControlLabel, Typography } from "@mui/material";
import { useState } from "react";
import { color } from "../../../../../theme/Color";
import { fontStyle } from "../../../../../theme/Style";

enum MaterialsRadioType {
  FORWARD = "FORWARD",
  REVERSE = "REVERSE",
}

interface MaterialsRadioProps {
  labels: { forwardLabel: string; reverseLabel: string };
}

export function MaterialsRadio({ labels }: MaterialsRadioProps) {
  const [selectedValue, setSelectedValue] = useState<MaterialsRadioType>(MaterialsRadioType.FORWARD);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value as MaterialsRadioType);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", height: "18px", gap: "16px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "18px",
          width: "69px",
          gap: "4px",
        }}
      >
        <Radio
          sx={{
            padding: 0,
            width: "16px",
            height: "16px",

            "& .MuiSvgIcon-root": {
              fontSize: 16,
            },
            "&.Mui-checked": {
              color: color.primary500,
            },
            "&.Mui-checked + label": {
              color: color.gray600,
            },
          }}
          value={MaterialsRadioType.FORWARD}
          checked={selectedValue === MaterialsRadioType.FORWARD}
          onChange={handleChange}
          name="radio-buttons"
        />
        <Typography
          sx={{
            height: "18px",
            display: "flex",
            alignItems: "center",
            fontStyle: fontStyle.semiboldXS,
            color: selectedValue === MaterialsRadioType.FORWARD ? color.primary500 : color.gray600,
          }}
        >
          {labels.forwardLabel}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "18px",
          width: "69px",
          gap: "4px",
        }}
      >
        <Radio
          sx={{
            width: "16px",
            height: "16px",
            alignItems: "center",
            "& .MuiSvgIcon-root": {
              fontSize: 16,
            },
            "&.Mui-checked": {
              color: color.primary500,
            },
            "&.Mui-checked + label": {
              color: color.gray600,
            },
          }}
          value={MaterialsRadioType.REVERSE}
          checked={selectedValue === MaterialsRadioType.REVERSE}
          onChange={handleChange}
          name="radio-buttons"
        />
        <Typography
          sx={{
            height: "18px",
            display: "flex",
            alignItems: "center",
            fontStyle: fontStyle.semiboldXS,
            color: selectedValue === MaterialsRadioType.REVERSE ? color.primary500 : color.gray600,
          }}
        >
          {labels.reverseLabel}
        </Typography>
      </Box>
    </Box>
  );
}
