import {
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  linearProgressClasses,
  styled,
  Typography,
} from "@mui/material";
import { color } from "../../../../../theme/Color";
import { fontStyle } from "../../../../../theme/Style";
import VerticalSpacer from "../../../../../component/Spacer";
import LocalizedDate, {
  DateFormatType,
} from "../../../../../component/LocalizedDate";
import { CharacterInfo } from "../model/CarbonReductionUiState";

interface CharacterCardProps {
  data: CharacterInfo;
}

export function CharacterCard({ data }: CharacterCardProps) {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    borderRadius: "64px",
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[200],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: "64px",
      backgroundColor: color.primary500,
    },
  }));
  return (
    <Grid item lg={4}>
      <Card
        sx={{
          padding: "0px",
          border: "1px solid",
          borderColor: color.gray300,
          borderRadius: "15px",
          boxShadow: "unset",
          maxWidth: "348.46px",
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            "&:last-child": {
              paddingBottom: "20px",
            },
          }}
        >
          <Box
            component="img"
            src={data.image}
            sx={{
              width: "100%",
              height: "288px",
            }}
          ></Box>
          <VerticalSpacer height={12} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography fontStyle={fontStyle.semiboldS} color={color.gray600}>
              {`Lv ${data.level}`}
            </Typography>
            <Typography fontStyle={fontStyle.semiboldL}>{data.id}</Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
              <Typography
                fontStyle={fontStyle.displayM}
                color={color.primary500}
              >
                {data.carbonReduction}
              </Typography>
              <Typography fontStyle={fontStyle.semiboldS} color={color.gray600}>
                kgCO₂-eq
              </Typography>
            </Box>
            <BorderLinearProgress
              variant="determinate"
              value={(data.exp / data.maxExp) * 100}
            />
          </Box>
          <Box sx={{ display: "flex", gap: 0.5 }}>
            <Typography fontStyle={fontStyle.semiboldXS} color={color.gray600}>
              Issue Date
            </Typography>
            <Typography fontStyle={fontStyle.semiboldXS}>
              {LocalizedDate({
                date: data.issueDate,
                type: DateFormatType.AdminType,
              })}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 0.5 }}>
            <Typography fontStyle={fontStyle.semiboldXS} color={color.gray600}>
              Completion Date
            </Typography>
            <Typography fontStyle={fontStyle.semiboldXS}>
              {LocalizedDate({
                date: data.completionDate,
                type: DateFormatType.AdminType,
              })}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}
