import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { color } from "../../../theme/Color";
import { fontStyle } from "../../../theme/Style";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";

export interface NoticeData {
  title: string;
  id: string;
}
interface NoticeSliderProps {
  notices: NoticeData[];
  interval?: number; // 알림 슬라이드 간격 (ms)
  onClick: (notice: NoticeData) => void;
}

const NoticeSlider = ({
  notices,
  interval = 3000,
  onClick,
}: NoticeSliderProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [prevIndex, setPrevIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (notices.length <= 1) return;

    const timer = setInterval(() => {
      setPrevIndex(currentIndex);
      setCurrentIndex((prev) => (prev + 1) % notices.length);
      setIsAnimating(true);

      setTimeout(() => {
        setIsAnimating(false);
      }, 500);
    }, interval);

    return () => clearInterval(timer);
  }, [notices, interval, currentIndex]);

  const getTransform = (index: number) => {
    if (!isAnimating) {
      // 애니메이션이 없을 때는 현재 인덱스만 보이도록
      return currentIndex === index ? "translateY(0)" : "translateY(100%)";
    }

    if (index === currentIndex) {
      // 새로운 아이템은 아래에서 위로
      return "translateY(0)";
    } else if (index === prevIndex) {
      // 이전 아이템은 위로 사라짐
      return "translateY(-100%)";
    } else {
      // 나머지는 화면 밖에 위치
      return "translateY(100%)";
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "white",
        borderRadius: "10px",
        p: "12px",
        overflow: "hidden",
        boxSizing: "border-box",
        height: "45px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          position: "relative",
          minHeight: "22px",
        }}
      >
        {/* NOTICE BADGE - 고정위치 */}
        <Box
          sx={{
            bgcolor: color.backgroundProgress,
            borderRadius: "4px",
            px: "6px",
            py: "2px",
            mr: "8px",
            boxSizing: "border-box",
            flexSghrink: 0,
          }}
        >
          <Typography
            fontStyle={fontStyle.subTitleXS}
            color={color.progress}
            sx={{ whiteSpace: "nowrap" }}
          >
            {LocalizedText("ua_f100_notice")}
          </Typography>
        </Box>

        {/* 슬라이드 영역 */}
        <Box
          sx={{
            position: "relative",
            flex: 1,
            overflow: "hidden",
            minHeight: "20px",
          }}
        >
          {notices.map((notice, index) => (
            <Box
              sx={{
                width: "100%",
                height: "100%",
              }}
              onClick={() => onClick(notice)}
            >
              <Typography
                key={index}
                fontStyle={fontStyle.subTitleS}
                color={color.default}
                sx={{
                  position: "absolute",
                  width: "100%",
                  opacity: currentIndex === index ? 1 : 0,
                  transform: getTransform(index),
                  transition: "all 0.5s ease",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {notice.title}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default NoticeSlider;
