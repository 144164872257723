import { Box, Typography } from "@mui/material";
import { AdditionalInfoUiEvent } from "./AdditionalInfoUiEvent";
import { AdditionalInfoUiState } from "./AdditionalInfoUiState";
import { AdditionalInfoSideEffect } from "./AdditionalInfoSideEffect";
import Toolbar from "../../../component/Toolbar";
import VerticalSpacer from "../../../component/Spacer";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import EditBirthDateField from "../../MobileProfileEdit/section/EditBirthDateField";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import EditGenderField from "../../MobileProfileEdit/section/EditGenderField";
import EditAddressField from "../../MobileProfileEdit/section/EditAddressField";
import CommonButton from "../../../component/CommonButton";
import SimpleDialog from "../../../component/dialog/SimpleDialog";
import { ROUTE_PATHS } from "../../../constants/RouteConstants";

interface AdditionalInfoRenderProps {
  uiState: AdditionalInfoUiState;
  uiEvent: AdditionalInfoUiEvent;
  sideEffect: AdditionalInfoSideEffect;
}

const AdditionalInfoRender = ({
  uiState,
  uiEvent,
  sideEffect,
}: AdditionalInfoRenderProps) => {
  return (
    <>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          overflowY: "auto",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {/* 상단 toolbar */}
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Toolbar
            title=""
            onBack={() => {
              uiEvent.ChangeExitDialogState(true);
            }}
          />
        </Box>
        <VerticalSpacer height={12} />

        {/* 중간 요소 */}
        <Box
          sx={{
            width: "calc(100% - 40px)",
          }}
        >
          <Typography
            fontStyle={fontStyle.headingS}
            color={color.default}
            sx={{
              whiteSpace: "pre-line",
            }}
          >
            Please enter your information.
          </Typography>
          <VerticalSpacer height={8} />
          <Typography fontStyle={fontStyle.bodyLr} color={color.gray800}>
            Enter the personal information we need to hand over your scooter.
          </Typography>
          <VerticalSpacer height={36} />
          <EditBirthDateField
            title={LocalizedText("ua_g210_birthofdate")}
            Day={uiState.day}
            Month={uiState.month}
            Year={uiState.year}
            dayError={uiState.dayError}
            monthError={uiState.monthError}
            yearError={uiState.yearError}
            uiEvent={uiEvent}
          />
          <VerticalSpacer height={40} />
          <EditAddressField
            type="Address"
            title={LocalizedText("ua_g210_address")}
            country={uiState.country}
            cityCountyDistrict={uiState.cityCountyDistrict}
            cityDetail={uiState.cityDetail}
            countryError={uiState.countryError}
            cityCountyDistrictError={uiState.cityCountyDistrictError}
            cityDetailError={uiState.cityDetailError}
            uiEvent={uiEvent}
          />
          <VerticalSpacer height={40} />
          <EditGenderField
            type="Gender"
            title={LocalizedText("ua_g210_gender")}
            gender={uiState.gender}
            genderError={uiState.genderError}
            uiEvent={uiEvent}
          />
        </Box>

        {/* 하단 버튼 */}
        <Box
          sx={{
            position: "absolute",
            bottom: "0px",
            width: "100%",
            display: "flex",
          }}
        >
          <CommonButton
            title={LocalizedText("ua_g200_editprofile_button")}
            textColor="white"
            onClick={uiEvent.VerifyingData}
            sx={{
              mx: "20px",
              mb: "14px",
            }}
          />
        </Box>

        <SimpleDialog
          children={LocalizedText("common_alert_goprevious")}
          isOpen={uiState.isShowExitDialog}
          onPositive={() => {
            window.history.back();
          }}
          onNagative={() => {
            uiEvent.ChangeExitDialogState(false);
          }}
          onClose={() => {
            uiEvent.ChangeExitDialogState(false);
          }}
          positiveText={LocalizedText("common_alert_confirm_button")}
          nagativeText={LocalizedText("common_alert_cancel_button")}
        />

        <SimpleDialog
          children={LocalizedText("ua_g210_alert_updated_message")}
          isOpen={uiState.isShowCompleteDialog}
          onPositive={() => {
            sideEffect.navigate(ROUTE_PATHS.SCOOTER_WAITING);
          }}
          onClose={() => {
            uiEvent.ChangeCompleteDialogState(false);
          }}
          positiveText={LocalizedText("common_alert_confirm_button")}
        />
      </Box>
    </>
  );
};

export default AdditionalInfoRender;
