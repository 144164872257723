import { Box, Typography } from "@mui/material";
import { fontStyle } from "../../../../../theme/Style";
import { color } from "../../../../../theme/Color";
import { ICON_SELECT } from "../../../../../constants/imagePath";
import VerticalSpacer from "../../../../../component/Spacer";
import { CarbonReductionUiState } from "../model/CarbonReductionUiState";
import { CarbonReductionUiEvent } from "../model/CarbonReductionUiEvent";

interface ReductionBarChartProps {
  uiState: CarbonReductionUiState;
  uiEvent: CarbonReductionUiEvent;
}

const ReductionBarChart = ({ uiState, uiEvent }: ReductionBarChartProps) => {
  
  const maxValue = Math.max(
    ...uiState.reductionHistory.map((item) => item.value)
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "20px",
        height: "276px",
        border: "1px solid",
        borderColor: color.gray300,
        borderRadius: "10px",
      }}
    >
      {uiState.reductionHistory.length === 0 ? (
        <Typography
          fontStyle={fontStyle.regularS}
          color={color.gray600}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          There are no driving records.
        </Typography>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              maxWidth: "100%", // 전체 너비를 제한
              height: "174px", // 차트의 최대 높이 설정
            }}
          >
            {/* 바 차트 그리기 */}
            {uiState.reductionHistory.map((item) => (
              <Box
                key={item.label}
                sx={{
                  display: "flex",
                  flexDirection: "column-reverse", // 바를 아래에서 위로 그리기 위해 사용
                  alignItems: "center",
                  width: "44px",
                  height: "174px",
                }}
              >
                {/* 바의 높이 비율을 계산하여 설정 */}
                <Typography
                  fontStyle={fontStyle.regularS}
                  sx={{ marginTop: "4px" }}
                >
                  {item.label}
                </Typography>
                <Box
                  sx={{
                    width: "24px",
                    height: `${(item.value / maxValue) * 100}%`, // maxValue 대비 상대적인 높이
                    background:
                      "linear-gradient(180deg, #16DEC2 0%, #AD9BF1 100%)",
                    borderTopRightRadius: "24px",
                    borderTopLeftRadius: "24px",
                    position: "relative",
                    minHeight: "10px", // 최소 높이를 설정
                  }}
                >
                  <Typography
                    fontStyle={fontStyle.semiboldS}
                    sx={{
                      position: "absolute",
                      top: "-20px", // 막대 위에 텍스트를 배치하기 위해 설정
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                  >
                    {`${item.value}`}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
          <VerticalSpacer height={10} />
          <Box sx={{ display: "flex", justifyContent: "center", gap: "8px" }}>
            <img
              src={ICON_SELECT}
              onClick={uiEvent.handlePrevYear}
              style={{
                transform: "rotate(90deg)",
                cursor: "pointer",
                height: "20px",
                width: "20px",
              }}
            ></img>
            <Typography fontStyle={fontStyle.regularS} color={color.gray800}>
              {uiState.historyYear}
            </Typography>
            <img
              src={ICON_SELECT}
              onClick={uiEvent.handleNextYear}
              style={{
                transform: "rotate(-90deg)",
                cursor: "pointer",
                height: "20px",
                width: "20px",
              }}
            ></img>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ReductionBarChart;
