// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 기본 폰트 설정 */
body {
  margin: 0;
  font-family: "Pretendard", sans-serif;
}

/* Animation */
body#web * {
  /* font-family: "OpenSans", sans-serif; */
  /* transition-property: all; */
  /* transition-duration: 0.2s; */
  /* transition-timing-function: ease; */
}

body#mobile * {
  /* 텍스트 & 이미지 복사 기능 block */
  -webkit-user-select: none; /* Safari */ /* Firefox */ /* Internet Explorer/Edge */
  user-select: none;
}

body#web button { border-radius: 30px; }

/* label */
.label {
  color: #99989d;
  cursor: pointer;
  display: inline-block;
}

/* error message under the element */
.errMsg {
  color: red;
  font-size: 8px;
}

.errBorder { border: 1px solid red; }
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,aAAa;AACb;EACE,SAAS;EACT,qCAAqC;AACvC;;AAEA,cAAc;AACd;EACE,yCAAyC;EACzC,8BAA8B;EAC9B,+BAA+B;EAC/B,sCAAsC;AACxC;;AAEA;EACE,0BAA0B;EAC1B,yBAAyB,EAAE,WAAW,EACd,YAAY,EACb,2BAA2B;EAClD,iBAAiB;AACnB;;AAEA,kBAAkB,mBAAmB,EAAE;;AAEvC,UAAU;AACV;EACE,cAAc;EACd,eAAe;EACf,qBAAqB;AACvB;;AAEA,oCAAoC;AACpC;EACE,UAAU;EACV,cAAc;AAChB;;AAEA,aAAa,qBAAqB,EAAE","sourcesContent":["/* 기본 폰트 설정 */\nbody {\n  margin: 0;\n  font-family: \"Pretendard\", sans-serif;\n}\n\n/* Animation */\nbody#web * {\n  /* font-family: \"OpenSans\", sans-serif; */\n  /* transition-property: all; */\n  /* transition-duration: 0.2s; */\n  /* transition-timing-function: ease; */\n}\n\nbody#mobile * {\n  /* 텍스트 & 이미지 복사 기능 block */\n  -webkit-user-select: none; /* Safari */\n  -moz-user-select: none; /* Firefox */\n  -ms-user-select: none; /* Internet Explorer/Edge */\n  user-select: none;\n}\n\nbody#web button { border-radius: 30px; }\n\n/* label */\n.label {\n  color: #99989d;\n  cursor: pointer;\n  display: inline-block;\n}\n\n/* error message under the element */\n.errMsg {\n  color: red;\n  font-size: 8px;\n}\n\n.errBorder { border: 1px solid red; }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
