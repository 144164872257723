export class WalletDetailUiState {
  walletInfo: WalletInfo;
  nftInfo: NFTInfo[];
  transactionInfo: TransactionInfo[];
  docCount: number;
  rowsPerPage: number;
  page: number;
  pageCount: number;

  backDialogOpen: boolean;
  constructor(
    walletInfo: WalletInfo = {
      id: "",
      address: "",
      stationName: "",
      balance: "",
      txCount: 0,
      registrationDate: 0,
      appearDate: 0,
      lastUpdateDate: 0,
      token: "",
    },
    nftInfo: NFTInfo[] = [],
    transactionInfo: TransactionInfo[] = [],
    docCount: number = 0,
    rowsPerPage: number = 10,
    page: number = 0,
    pageCount: number = 0,
    
    backDialogOpen: boolean = false
  ) {
    this.walletInfo = walletInfo;
    this.nftInfo = nftInfo;
    this.transactionInfo = transactionInfo;
    this.docCount = docCount;
    this.rowsPerPage = rowsPerPage;
    this.page = page;
    this.pageCount = pageCount;
    this.backDialogOpen = backDialogOpen;
  }
}

export interface WalletInfo {
  id: string;
  address: string;
  stationName: string;
  balance: string;
  txCount: number;
  registrationDate: number;
  appearDate: number;
  lastUpdateDate: number;
  token: string;
}

export interface NFTInfo {
  image: string | undefined;
  nftType: string;
  nftId: string;
  issuer: string;
  issueDate: number;
  receivedDate: number;
}

export interface TransactionInfo {
  block: string;
  txHash: number;
  age: number;
  txType: boolean;
  form: string;
  to: string;
  value: number;
  txFee: number;
}
