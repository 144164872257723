import { useState } from "react";
import CommonList from "../../../../component/CommonList";
import { RequestManagementTab, RequestManagementTabType } from "./section/RequestManagementTab";
import { RequestManagementColtrol } from "./requestmanagementcontrol/RequestManagementControl";
import { CommonWebHeaderButton } from "../../../../component/CommonWebHeaderButton";
import { RequestHistory } from "./requesthistory/RequestHistory";
import { useNavigate } from "react-router-dom";

const RequestManagement = () => {
  const [currentTab, setCurrentTab] = useState<RequestManagementTabType>(RequestManagementTabType.REQUEST_MANAGEMENT_CONTROL);
  const navigate = useNavigate();
  return (
    <CommonList
      title="Request"
      tabButton={<RequestManagementTab currentTab={currentTab} changeTab={(tab) => setCurrentTab(tab)} />}
      headerButton={
        currentTab == RequestManagementTabType.REQUEST_MANAGEMENT_CONTROL && (
          <CommonWebHeaderButton
            width="122px"
            height="45px"
            buttonContent="Register"
            addIcon={true}
            onClick={() => {
              navigate("/requestRegistration");
            }}
          />
        )
      }
    >
      {currentTab == RequestManagementTabType.REQUEST_MANAGEMENT_CONTROL && <RequestManagementColtrol />}
      {currentTab == RequestManagementTabType.REQUEST_HISTORY && <RequestHistory />}
    </CommonList>
  );
};

export default RequestManagement;
