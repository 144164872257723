import { TableRow } from "@mui/material";
import { ProductConfirmationListTableCell } from "./ProductConfirmationListTableCell";
import { ChangeEvent } from "react";
import { CommonRadio } from "../../../../component/CommonRadio";
import { Text } from "../../../../component/Text";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import { ProductionManagementCellData } from "../../model/ProductionManagementCellData";
import { ProductData } from "../../section/productionmanagement/production/section/model/ProductData";

import { FindInListUiState } from "./model/FindInListUiState";
import { FindInListUiEvent } from "./model/FindInListUiEvent";

interface ProductConfirmationListTableRowProps {
  row: ProductData;
  uiState: FindInListUiState;
  uiEvent: FindInListUiEvent;
}

export function ProductConfirmationListTableRow({ row, uiState, uiEvent }: ProductConfirmationListTableRowProps) {
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    uiEvent.HandleUiStateChange("ProductId", event.target.value);
  };

  const productCellData: ProductionManagementCellData[] = [
    new ProductionManagementCellData(`${row.category}`, "left", "120px"),
    new ProductionManagementCellData(`${row.partsCode}`, "left", "200px"),
    new ProductionManagementCellData(`${row.productName}`, "left", "200px"),
    new ProductionManagementCellData(`${row.spec}`, "left"),
  ];
  return (
    <TableRow onClick={() => {}}>
      <ProductConfirmationListTableCell>
        <CommonRadio selectValue={uiState.ProductId} onchange={onChange} value={row.id} />
      </ProductConfirmationListTableCell>
      {productCellData.map((data: ProductionManagementCellData, index: number) => (
        <ProductConfirmationListTableCell
          key={`${row.partsCode}-${index}`}
          sx={{
            paddingLeft: data.paddingLeft,
            paddingRight: data.paddingRight,
            width: data.width || "auto",
            ...(index === productCellData.length - 1 ? { flex: 1 } : {}),
          }}
          childrenContainerSx={{ width: data.width }}
          align={data.align}
          children={
            <Text
              sx={{
                fontStyle: fontStyle.regularXS,
                color: color.gray800,
              }}
              text={data.value}
            />
          }
        />
      ))}
    </TableRow>
  );
}
