export class ProductNameDetailUiState {
  productId: string;
  modelName: string;
  productName: string;
  partsCode: string;
  image: string;
  backDialogOpen: boolean;
  updateDialogOpen: boolean;
  constructor(
    productId: string = "",
    modelName: string = "",
    productName: string = "",
    partsCode: string = "",
    image: string = "",
    backDialogOpen: boolean = false,
    updateDialogOpen: boolean = false
  ) {
    this.productId = productId;
    this.modelName = modelName;
    this.productName = productName;
    this.partsCode = partsCode;
    this.image = image;
    this.backDialogOpen = backDialogOpen;
    this.updateDialogOpen = updateDialogOpen;
  }
}
