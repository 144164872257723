import { useState } from "react";
import { LocalizationProvider, DatePicker, DatePickerProps } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SxProps, TextField, Theme } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { color } from "../theme/Color";
import { fontStyle } from "../theme/Style";

interface CommonDatePickerProps {
  disabled?: boolean; // disabled prop 추가
  sx?: SxProps<Theme>; // sx prop 추가
  InputPropsSx?: SxProps<Theme>; // sx prop 추가
  textFieldSx?: SxProps<Theme>; // sx prop 추가
  onChange?: (newDate: Dayjs | null) => void; // onChange prop 추가
  views?: Array<"year" | "month" | "day">; // views prop 추가
  value: Dayjs | null; // 외부에서 관리할 수 있는 value prop 추가
  error?: boolean;
  clearable?: boolean;
  minDate?: Dayjs; // 외부에서 주입 가능한 minDate prop 추가
  maxDate?: Dayjs; // 외부에서 주입 가능한 maxDate prop 추가
  placeholder?: string;
}

const CommonDatePicker = ({
  disabled,
  InputPropsSx,
  textFieldSx,
  onChange,
  value,
  views, // 기본값 설정
  error,
  clearable,
  sx,
  minDate,
  maxDate,
  placeholder,
}: CommonDatePickerProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        format="DD MMM YYYY"
        value={value}
        onChange={onChange}
        disabled={disabled}
        openTo={views?.[0]} // 첫 번째 요소를 openTo로 설정
        views={views} // views prop 전달
        minDate={minDate} // 외부에서 주입된 minDate를 설정
        maxDate={maxDate} // 외부에서 주입된 maxDate를 설정
        sx={{
          ...sx,
        }}
        slotProps={{
          field: { clearable: clearable }, //Date Text Field에 X 버튼 생겨 값 초기화 시켜주는 버튼 활성화 여부
          textField: {
            // variant: "outlined",
            error: error,
            sx: {
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: color.gray300, // 기본 보더 색상
                },
                "&:hover fieldset": {
                  borderColor: disabled ? color.gray300 : color.primary500, // 호버 시 보더 색상
                },
                "&.Mui-focused fieldset": {
                  border: "1px solid",
                  borderColor: color.primary500,
                },
                "&.Mui-disabled fieldset": {
                  borderColor: color.gray300, // 비활성화 시 border 색상
                  color: color.gray300,
                },
              },
              ...textFieldSx,
            },

            placeholder: placeholder,
            InputProps: {
              sx: {
                borderRadius: "10px",
                backgroundColor: disabled ? color.gray200 : "white",
                ...InputPropsSx,
              },
            },
            inputProps: {
              style: {
                fontSize: fontStyle.regularS.fontSize, // fontSize 추가
                lineHeight: fontStyle.regularS.lineHeight, // lineHeight 추가
                fontWeight: fontStyle.regularS.fontWeight, // fontWeight 추가
                zIndex: 100,
              },
            },
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default CommonDatePicker;
