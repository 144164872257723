import { useEffect, useState } from "react";
import CommonList from "../../component/CommonList";
import CommonSearch from "../../component/CommonSearch";
import { ApiService } from "../../restAPI/ApiService";
import { Box } from "@mui/material";
import VerticalSpacer, { HorizontalSpacer } from "../../component/Spacer";
import StatisticsScooterTable from "./component/StatisticsScooterTable";
import CommonDatePicker from "../../component/CommonDatePicker";
import dayjs, { Dayjs } from "dayjs";
import CommonDateRange from "../../component/CommonDateRange";
import { color } from "../../theme/Color";
import { ADMIN_CONFIG } from "../../constants/AdminConfig";

const StatisticsScooterPage = () => {
  // 페이지네이션 기능을 위한 변수
  const [rowsPerPage, setRowsPerPage] = useState(10); // 한 테이블당 보여줄 row 의 갯수
  const [rows, setRows] = useState([]); // 받아온 데이터
  const [page, setPage] = useState<number>(0); // 현재 페이지의 번호
  const [docCount, setDocCount] = useState<number>(0); // 총 row의 수
  const [pageCount, setPageCount] = useState(0); // 테이블 왼쪽 하단에 보여질 페이지의 갯수
  // 검색어 관련 변수
  const [searchContents, setSearchContents] = useState("");
  // 우측 상단 Date Range 관련 변수
  const [dateRange, setDateRange] = useState<{ startDate: Dayjs | null; endDate: Dayjs | null }>({
    startDate: null,
    endDate: null,
  });
  useEffect(() => {
    ApiService.WebPost("/fms/api/v1/scooter-info", {
      imei: searchContents,
      page: page + 1,
      size: rowsPerPage,
      startDate: dateRange.startDate == null ? 0 : dateRange.startDate.valueOf(),
      endDate: dateRange.endDate == null ? 0 : dateRange.endDate.valueOf() + 24 * 3600 * 1000,
    }).then((res) => {
      if (res?.data.body) {
        setRows(res?.data.body);
        setPageCount(Math.ceil(res?.data.docCount / rowsPerPage));
        setDocCount(res?.data.docCount);
      }
    });
  }, [rowsPerPage, page]);

  useEffect(() => {
    console.log(dateRange);
  }, [dateRange]);
  return (
    <CommonList title={ADMIN_CONFIG.MENU_STATISTICS_SCOOTER.TITLE}>
      <CommonSearch
        placeHolder={""}
        setSearchContents={setSearchContents}
        onKeyPress={() => {
          ApiService.WebPost("/fms/api/v1/scooter-info", {
            imei: searchContents,
            page: page + 1,
            size: rowsPerPage,
            startDate: dateRange.startDate == null ? 0 : dateRange.startDate.valueOf(),
            endDate: dateRange.endDate == null ? 0 : dateRange.endDate.valueOf() + 24 * 3600 * 1000,
          }).then((res) => {
            if (res?.data.body) {
              setRows(res?.data.body);
              setPageCount(Math.ceil(res?.data.docCount / rowsPerPage));
              setDocCount(res?.data.docCount);
            }
          });
          setPage(0);
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          {/* <CommonDatePicker
            value={startDate}
            views={["day"]}
            textFieldSx={{ width: "220px" }}
            InputPropsSx={{ height: "42px", width: "220px" }}
            onChange={(newDate) => {
              setStartDate(newDate);
            }}
          />
          <HorizontalSpacer width={12} />
          <CommonDatePicker
            value={endDate}
            views={["day"]}
            textFieldSx={{ width: "220px" }}
            InputPropsSx={{ height: "42px", width: "220px" }}
            onChange={(newDate) => {
              setEndDate(newDate);
            }}
          /> */}

          <CommonDateRange
            value={dateRange}
            onChange={(newValue) => setDateRange(newValue)}
            views={["year", "month", "day"]}
            textFieldSx={{
              width: "220px",
              height: "42px",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white", // 기본 보더 색상
                },
                "&:hover fieldset": {
                  borderColor: "white",
                },
                "&.Mui-focused fieldset": {
                  border: "1px solid",
                  borderColor: "white",
                },
              },
            }}
            InputPropsSx={{ height: "42px", width: "220px" }}
          />
        </Box>
      </CommonSearch>
      <VerticalSpacer height={16} />
      <StatisticsScooterTable
        mngRows={rows}
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </CommonList>
  );
};

export default StatisticsScooterPage;
