import { Category } from "../../../MobileSupport/faq/list/model/Category";

export class CustomerSupportRegistrationPageUiState {
  vinList: Category[];
  vinError: boolean;
  preferredStationCategoryList: Category[];
  preferredStationError: boolean;
  titleInputValue: string;
  titleInputError: boolean;
  titleInputMaxLength: number;
  contentInputValue: string;
  contentInputMaxLength: number;
  fileUrls: string[];
  preferredDate: number | undefined;
  preferredDateError: boolean;
  preferredTime: number | undefined;
  preferredTimeError: boolean;
  preferredDateTime: number | undefined;
  openTime: string;
  closeTime: string;
  isBackDialog: boolean;
  isSubmitDialog: boolean;
  blockedTime: number[];

  constructor(
    vinList: Category[] = [],
    vinError: boolean = false,
    preferredStationList: Category[] = [],
    preferredStationError: boolean = false,
    titleInputValue: string = "",
    titleInputError: boolean = false,
    titleInputMaxLength: number = 45,
    contentInputValue: string = "",
    contentInputMaxLength: number = 1000,
    fileUrls: string[] = [],
    preferredDate: number | undefined = undefined,
    preferredDateError: boolean = false,
    preferredTime: number | undefined = undefined,
    preferredTimeError: boolean = false,
    preferredDateTime: number | undefined = undefined,
    openTime: string = "",
    closeTime: string = "",
    isBackDialog: boolean = false,
    isSubmitDialog: boolean = false,
    blockedTime: number[] = [],
  ) {
    this.vinList = vinList;
    this.vinError = vinError;
    this.preferredStationCategoryList = preferredStationList;
    this.preferredStationError = preferredStationError;
    this.titleInputValue = titleInputValue;
    this.titleInputError = titleInputError;
    this.titleInputMaxLength = titleInputMaxLength;
    this.contentInputValue = contentInputValue;
    this.contentInputMaxLength = contentInputMaxLength;
    this.fileUrls = fileUrls;
    this.preferredDate = preferredDate;
    this.preferredDateError = preferredDateError;
    this.preferredTime = preferredTime;
    this.preferredTimeError = preferredTimeError;
    this.preferredDateTime = preferredDateTime;
    this.openTime = openTime;
    this.closeTime = closeTime;
    this.isBackDialog = isBackDialog;
    this.isSubmitDialog = isSubmitDialog;
    this.blockedTime = blockedTime;
  }
}
