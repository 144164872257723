import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { GridRowModes, GridRowModesModel, GridRowsProp } from "@mui/x-data-grid";
import { randomId } from "@mui/x-data-grid-generator";
import CommonList from "../../component/CommonList";
import CommonSearch from "../../component/CommonSearch";
import MultilingualGrid from "./section/MultilingualGrid";
import axios from "axios";
import { ApiService } from "../../restAPI/ApiService";
import { CommonSelect } from "../../component/CommonSelect";
import VerticalSpacer, { HorizontalSpacer } from "../../component/Spacer";
import MultilingualTable from "./section/MultilingualTable";
import CommonDialog from "../../component/CommonDialog";
import CommonCardDialog from "../../component/dialog/CommonCardDialog";
import { color } from "../../theme/Color";
import CommonTextField from "../../component/CommonTextField";
import { fontStyle } from "../../theme/Style";
import { MultilingualUiState } from "./model/MultilingualUiState";
import { MultilingualUiEvent } from "./model/MultilingualUiEvent";
import CreateModal from "./component/CreateModal";
import DetailModal from "./component/DetailModal";

const Multilingual = () => {
  const [uiState, setUiState] = useState(new MultilingualUiState());
  const uiEvent: MultilingualUiEvent = {
    HandleUiStateChange: (key: keyof MultilingualUiState, value: any) => {
      setUiState((prevState) => ({
        ...prevState,
        [key]: value, // key에 해당하는 상태를 업데이트
      }));
    },
  };
  // 페이지네이션 기능을 위한 변수
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [rows, setRows] = useState<[]>([]);
  const [page, setPage] = useState<number>(0);
  const [docCount, setDocCount] = useState<number>(0);
  const [pageCount, setPageCount] = useState(0);
  // 검색어 관련 변수
  const [searchContents, setSearchContents] = useState("");
  // language 관련 변수
  const [language, setLanguage] = useState("");
  // type 관련 변수
  const [type, setType] = useState("");
  // state 관련 변수
  const [state, setState] = useState("");
  useEffect(() => {
    ApiService.WebPost("/pw/aw/system/multilingual/filter", {
      size: rowsPerPage,
      searchContents: searchContents,
      language: language,
      page: page + 1,
      type: type,
      state: state,
    }).then((res) => {
      setRows(res?.data.body);
      setPageCount(Math.ceil(res?.data.docCount / rowsPerPage));
      setDocCount(res?.data.docCount);
    });
  }, [page, rowsPerPage, language, type, state]);
  const HeaderButton = () => {
    return (
      <Button
        sx={{
          height: 45,
          backgroundColor: color.primary500,
          color: "white",
          borderRadius: 5,
          textTransform: "none",
          width: 111,
          ":hover": {
            backgroundColor: color.toolingPurple500Hover,
          },
        }}
        onClick={() => {
          uiEvent.HandleUiStateChange("CreateDialogOpen", true);
        }}
      >
        <AddIcon fontSize="small" />
        Create
      </Button>
    );
  };

  return (
    <CommonList title="Multi-language Settings" setModal={null} headerButton={HeaderButton()}>
      <CommonSearch
        placeHolder={"Search"}
        searchContents={searchContents}
        setSearchContents={setSearchContents}
        onKeyPress={() => {
          ApiService.WebPost("/pw/aw/system/multilingual/filter", {
            size: rowsPerPage,
            searchContents: searchContents,
            language: language,
            page: page + 1,
            type: type,
            state: state,
          }).then((res) => {
            setRows(res?.data.body);
            setPageCount(Math.ceil(res?.data.docCount / rowsPerPage));
            setDocCount(res?.data.docCount);
          });
        }}
      >
        <Box sx={{ display: "flex" }}>
          <CommonSelect
            sx={{
              height: "42px",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
            }}
            menuitems={["All", "page", "alert", "system_alert", "dropdown", "modal", "infotip", "toast", "notification"]}
            selectValue={type}
            onChange={(event) => {
              if (event.target.value == "All") {
                setType("");
              } else {
                setType(event.target.value);
              }
            }}
            placeholder="Type"
          />
          <HorizontalSpacer width={12} />
          {/* <CommonSelect
            sx={{
              height: "42px",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
            }}
            menuitems={["All", "Active", "Not Use", "Deleted"]}
            selectValue={state}
            onChange={(event) => {
              if (event.target.value == "All") {
                setState("");
              } else {
                setState(event.target.value);
              }
            }}
            placeholder="State"
          />
          <HorizontalSpacer width={12} /> */}
          <CommonSelect
            sx={{
              height: "42px",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
            }}
            menuitems={["All", "Cambodian(km_KH)", "English(en_US)", "Korean(ko_KR)", "Vietnamese(vi_VN)"]}
            selectValue={language}
            onChange={(event) => {
              if (event.target.value == "All") {
                setLanguage("");
              } else if (event.target.value == "Cambodian(km_KH)") {
                setLanguage("km");
              } else if (event.target.value == "English(en_US)") {
                setLanguage("en");
              } else if (event.target.value == "Korean(ko_KR)") {
                setLanguage("ko");
              } else if (event.target.value == "Vietnamese(vi_VN)") {
                setLanguage("vi");
              }
            }}
            placeholder="Language"
          />
        </Box>
      </CommonSearch>

      <VerticalSpacer height={16} />
      <MultilingualTable
        uiState={uiState}
        uiEvent={uiEvent}
        mngRows={rows}
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
      <CreateModal uiState={uiState} uiEvent={uiEvent} />
      <DetailModal uiState={uiState} uiEvent={uiEvent} />
    </CommonList>
  );
};

export default Multilingual;
