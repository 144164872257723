import {
  APP_LOGO,
  ICON_HOME_BATTERY,
  ICON_HOME_MAGNIFYING_GLASS,
  IMAGE_DODO_LEVEL4,
  IMAGE_HOME_DUMMYBANNER,
  IMAGE_HOME_MAP,
  IMAGE_HOME_STATION,
  IMAGE_PETER_LEVEL3,
  IMAGE_PONYA_LEVEL2,
} from "../../constants/appImagePath";
import { color } from "../../theme/Color";
import { MobileHomeSideEffect } from "./model/MobileHomeSideEffect";
import { MobileHomeUiEvent } from "./model/MobileHomeUiEvent";
import { MobileHomeUiState } from "./model/MobileHomeUiState";
import { Box, Typography } from "@mui/material";
import { fontStyle } from "../../theme/Style";
import PullToRefresh from "../../component/PullToRefresh";
import NoticeSlider from "./section/NoticeSlider";
import ActionCard from "./section/ActionCard";
import MileageCard from "./section/MileageCard";
import BannerSwiper from "./section/BannerSwiper";
import { BannerModel } from "../MobilePartners/model/BannerModel";
import BatterySection from "./section/BatterySection";
import CharacterNFTSwiper from "./section/CharacterNFTSwiper";
import VerticalSpacer from "../../component/Spacer";
import TotalCarbonCard from "./section/TotalCarbonCard";
import MonthlyReductionChart, {
  MonthlyReductionData,
} from "./section/MonthlyReductionChart";
import { LocalizedText } from "../../di/LanguageRepositoryProvider";
import { ROUTE_PATHS } from "../../constants/RouteConstants";

interface MobileHomeRenderProps {
  uiState: MobileHomeUiState;
  uiEvent: MobileHomeUiEvent;
  sideEffect: MobileHomeSideEffect;
}

const MobileHomeRender = ({
  uiState,
  uiEvent,
  sideEffect,
}: MobileHomeRenderProps) => {
  const banners: BannerModel[] = [
    {
      image: IMAGE_HOME_DUMMYBANNER,
      link: "",
    },
    {
      image: IMAGE_HOME_DUMMYBANNER,
      link: "",
    },
    {
      image: IMAGE_HOME_DUMMYBANNER,
      link: "",
    },
  ];
  const characters = [
    {
      type: "Character",
      name: "DODO#123456",
      image: IMAGE_DODO_LEVEL4,
      issuedDate: "2 Jan 2025",
    },
    {
      type: "Character",
      name: "PONYA#123456",
      image: IMAGE_PONYA_LEVEL2,
      issuedDate: "5 Dec 2024",
    },
    {
      type: "Character",
      name: "PETER#123456",
      image: IMAGE_PETER_LEVEL3,
      issuedDate: "23 NOV 2023",
    },
  ];
  const monthlyData: MonthlyReductionData[] = [
    { month: "Jan", value: 129 },
    { month: "Feb", value: 298 },
    { month: "Mar", value: 163 },
    { month: "Apr", value: 270 },
    { month: "May", value: 324 },
    { month: "Jun", value: 172 },
  ];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100vw",
          height: "100vh",
          backgroundColor: color.gray100,
          alignItems: "center",
        }}
      >
        {/* Toolbar - 로고*/}
        <Box
          sx={{
            width: "100%",
            height: "44px",
            py: "12px",
            px: "20px",
            boxSizing: "border-box",
            display: "flex",
          }}
        >
          <Box
            component="img"
            src={APP_LOGO}
            alt="logo"
            sx={{ width: "80px", height: "20px" }}
          />
        </Box>

        {/* Content */}
        <PullToRefresh onRefresh={uiEvent.handleRefresh}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              overflowY: "auto",
              scrollbarWidth: "none",
              "&::-webkit-scrollbar": {
                display: "none",
              },
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                pt: "12px",
                px: "20px",
                gap: "12px",
                boxSizing: "border-box",
              }}
            >
              <NoticeSlider
                notices={uiState.notices}
                onClick={(notice) => {
                  sideEffect.navigate(ROUTE_PATHS.NOTICE_DETAIL, {
                    state: { id: notice.id },
                  });
                  console.log("Notice Clicked: ", notice.id);
                }}
              />

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  gap: "12px",
                }}
              >
                <ActionCard
                  title={LocalizedText("ua_h100_directions")}
                  description={LocalizedText("ua_h100_findnearby")}
                  image={IMAGE_HOME_MAP}
                  onClick={() => {
                    sideEffect.navigate(ROUTE_PATHS.MAP);
                  }}
                />
                <ActionCard
                  title={LocalizedText("ua_h100_stationqr")}
                  description={LocalizedText("ua_h100_qrscan")}
                  image={IMAGE_HOME_STATION}
                  imgWidth="48px"
                  imgHeight="60px"
                  onClick={() => {
                    if (window.Android) {
                      window.Android.openQRCamera();
                    } else if (window.webkit) {
                      window.webkit.messageHandlers.openQRCamera.postMessage(
                        "openQRCamera"
                      );
                    } else {
                      console.log("Can not use QR Scanner in Desktop");
                    }
                  }} // TODO: QR Scan 페이지로 이동
                />
              </Box>

              <MileageCard />

              <BannerSwiper banners={banners} onClick={() => {}} />

              <BatterySection
                batteryPercentage={78}
                estimatedRange={80}
                ridingTime={180}
              />
            </Box>
            <VerticalSpacer height={12} />
            <CharacterNFTSwiper
              characters={characters}
              onClick={(character) => {
                console.log("Character Clicked: ", character);
              }}
            />
            <VerticalSpacer height={12} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                px: "20px",
                gap: "12px",
                boxSizing: "border-box",
              }}
            >
              <TotalCarbonCard
                totalCarbonReduction={3248}
                levelName="Purple"
                levelMax={5000}
                availableCoupon={3}
              />

              <MonthlyReductionChart
                data={monthlyData}
                currentDate="1 Jul 2024 16:00:23"
              />

              {/* Guide Buttons */}
              <Box
                sx={{
                  bgcolor: "white",
                  borderRadius: "10px",
                  p: "16px",
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                <img
                  src={ICON_HOME_BATTERY}
                  alt="Battery"
                  style={{ width: "28px", height: "28px" }}
                />
                <Typography fontStyle={fontStyle.titleM} color={color.default}>
                  {LocalizedText("ua_h100_battery_guide")}
                </Typography>
              </Box>

              <Box
                sx={{
                  bgcolor: "white",
                  borderRadius: "10px",
                  p: "16px",
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                <img
                  src={ICON_HOME_MAGNIFYING_GLASS}
                  alt="Magnifying Glass"
                  style={{ width: "28px", height: "28px" }}
                />
                <Typography fontStyle={fontStyle.titleM} color={color.default}>
                  {LocalizedText("ua_h100_explore_faqs")}
                </Typography>
              </Box>

              {/* Support Section */}
              <Box
                sx={{
                  display: "flex",
                  gap: "12px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    bgcolor: "white",
                    borderRadius: "10px",
                    p: "16px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "2px",
                    flex: 1,
                  }}
                >
                  <Typography
                    fontStyle={fontStyle.subTitleS}
                    color={color.gray600}
                  >
                    {LocalizedText("ua_h100_more_questions")}
                  </Typography>
                  <Typography
                    fontStyle={fontStyle.titleL}
                    color={color.gray900}
                  >
                    {LocalizedText("ua_h100_inquiry")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    bgcolor: "white",
                    borderRadius: "10px",
                    p: "16px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "2px",
                    flex: 1,
                  }}
                >
                  <Typography
                    fontStyle={fontStyle.subTitleS}
                    color={color.gray600}
                  >
                    {LocalizedText("ua_h100_need_help")}
                  </Typography>
                  <Typography
                    fontStyle={fontStyle.titleL}
                    color={color.gray900}
                  >
                    {LocalizedText("ua_h100_cs_apply")}
                  </Typography>
                </Box>
              </Box>
              <VerticalSpacer height={30} />
            </Box>
          </Box>
        </PullToRefresh>
      </Box>
    </>
  );
};

export default MobileHomeRender;
