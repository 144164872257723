import { Button, SxProps, Typography } from "@mui/material";
import { fontStyle } from "../theme/Style";
import { color } from "../theme/Color";

interface CommonButtonProps {
  title?: string;
  buttonColor?: string;
  disabledButtonColor?: string;
  textColor?: string;
  disableTextColor?: string;
  radius?: number;
  height?: number;
  disabled?: boolean;
  sx?: SxProps;
  onClick: () => void;
}

export default function CommonButton({
  title,
  buttonColor = color.primary500,
  disabledButtonColor = color.gray500,
  textColor = color.white,
  disableTextColor = color.white,
  radius = 10,
  height = 52,
  disabled = false,
  sx,
  onClick,
}: CommonButtonProps) {
  let currentButtonColor = disabled ? disabledButtonColor : buttonColor;

  let currentTextColor = disabled ? disableTextColor : textColor;

  return (
    <Button
      sx={{
        width: "100%",
        height: `${height}px`,
        minHeight: `${height}px`,
        borderRadius: `${radius}px`,
        backgroundColor: currentButtonColor,
        textTransform: "none",
        ":hover": {
          backgroundColor: currentButtonColor,
        },
        zIndex: 1200,
        ...sx,
      }}
      disabled={disabled}
      onClick={onClick}
    >
      <Typography
        sx={{
          ...fontStyle.titleL,
          color: currentTextColor,
          textAlign: "center",
        }}
      >
        {title}
      </Typography>
    </Button>
  );
}
