import {
  Box
} from "@mui/material";
import CommonButton from "../../../../component/CommonButton";
import { color } from "../../../../theme/Color";
import { TabType } from "./model/TabType";

interface UserWalletTabProps {
  currentTab: TabType;
  onClick: (tab: TabType) => void;
}

function UserWalletTab({ currentTab, onClick }: UserWalletTabProps) {
  return (
      <Box sx={{ mt: "16px", display: "flex", gap: "12px" }}>
         <CommonButton
          onClick={() => onClick(TabType.NFT)}
          title="NFT"
          textColor={currentTab === TabType.NFT ? color.white : color.gray600}
          sx={{
            height: "44px",
            width: "84px",
            borderRadius: "54px",
            backgroundColor:
              currentTab === TabType.NFT ? color.primary500 : color.white,
          }}
        />
        <CommonButton
          onClick={() => onClick(TabType.VC)}
          title="VC"
          textColor={currentTab === TabType.VC ? color.white : color.gray600}
          sx={{
            height: "44px",
            width: "84px",
            borderRadius: "54px",
            backgroundColor:
              currentTab === TabType.VC ? color.primary500 : color.white,
          }}
        />
      </Box>
  );
}

export default UserWalletTab;
