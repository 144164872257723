import { Box, Typography } from "@mui/material";
import { color } from "../../../theme/Color";
import { fontStyle } from "../../../theme/Style";

interface ActionCardProps {
  title: string;
  description: string;
  image: string;
  imgWidth?: string;
  imgHeight?: string;
  onClick?: () => void;
}

const ActionCard = ({
  title,
  description,
  image,
  imgWidth = "60px",
  imgHeight = "60px",
  onClick,
}: ActionCardProps) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        width: "100%",
        bgcolor: "white",
        borderRadius: "10px",
        pt: "16px",
        pl: "16px",
        boxSizing: "border-box",
        position: "relative",
        height: "102px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        overflow: "hidden",
      }}
    >
      <Typography
        fontStyle={fontStyle.titleM}
        color={color.default}
        sx={{ mb: "4px", maxWidth: "90%" }}
      >
        {title}
      </Typography>
      <Typography
        fontStyle={fontStyle.bodySr}
        color={color.gray700}
        sx={{ maxWidth: "65%", whiteSpace: "pre-line" }}
      >
        {description}
      </Typography>
      <Box
        component="img"
        src={image}
        alt={title}
        sx={{
          position: "absolute",
          right: "-3px",
          bottom: "-3px",
          width: imgWidth,
          height: imgHeight,
          objectFit: "contain",
        }}
      />
    </Box>
  );
};

export default ActionCard;
