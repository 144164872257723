import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CommonList from "../../component/CommonList";
import { Box, Button, Card, CardContent, CardHeader, Grid, IconButton, MenuItem, Select, Tab, Tabs, TextField, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import UserDetailGrid from "./section/UserDetailGrid";
import UserScooterInfoGrid from "./section/UserScooterInfoGrid";
import UserMembershipGrid from "./section/UserMembershipGrid";
import { UserDetailsUiState } from "./UserDetailsUiState";
import { UserDetailsUiEvent } from "./UserDetailsUiEvent";
import { ApiService } from "../../restAPI/ApiService";
import { ADMIN_CONFIG } from "../../constants/AdminConfig";
import { UserWallet } from "./section/userwallet/UserWallet";
import { color } from "../../theme/Color";
import { fontStyle } from "../../theme/Style";
import { UserNFC } from "./section/nfc/UserNFC";
import { CarbonReduction } from "./section/carbonreduction/CarbonReduction";

const UserDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const userId = pathname.split("/").pop(); // URL에서 마지막 부분을 추출

  const [value, setValue] = useState("one");

  useEffect(() => {
    uiEvent.HandleUiStateChange("uuid", userId);
  }, [userId]);

  useEffect(() => {
    ApiService.WebPost("/pw/users/getUserData", {
      uuid: userId,
    }).then((res) => {
      const userData = res?.data.body;
      console.log(userData);
      uiEvent.HandleUiStateChange("Name", userData?.name);
      uiEvent.HandleUiStateChange("Email", userData?.email);
      uiEvent.HandleUiStateChange("LocalNumber", userData?.countryNumber);
      uiEvent.HandleUiStateChange("PhoneNumber", userData?.phoneNumber);
      uiEvent.HandleUiStateChange("Status", userData?.status);

      if (userData?.status == ADMIN_CONFIG.PAGE_MEMBER.DELETED) {
        uiEvent.HandleUiStateChange("DeleteAlert", true);
      } else {
        uiEvent.HandleUiStateChange("DeleteAlert", false);
      }
      if (userData?.blockEndDate) {
        uiEvent.HandleUiStateChange("BlockEndDate", userData?.blockEndDate);
      }
      uiEvent.HandleUiStateChange("IdCardImageFileName", userData?.idCardImageFileName);
      uiEvent.HandleUiStateChange("idCardImageURL", userData?.idCardImage);
      uiEvent.HandleUiStateChange("Type", userData?.type);
      uiEvent.HandleUiStateChange("Group", userData?.group);
      uiEvent.HandleUiStateChange("BirthOfDate", userData?.birthday);
      uiEvent.HandleUiStateChange("Gender", userData?.gender);
      uiEvent.HandleUiStateChange("CountryAddress", userData?.addressCountry);
      uiEvent.HandleUiStateChange("CityAddress", userData?.addressProvince);
      uiEvent.HandleUiStateChange("DetailAddress", userData?.addressDetail);
      uiEvent.HandleUiStateChange("UserVerifiedDate", userData?.verifiedDate);
      uiEvent.HandleUiStateChange("RegistrationDate", userData?.registrationDate);
      uiEvent.HandleUiStateChange("LastModifiedDate", userData?.lastModifieddate);
      uiEvent.HandleUiStateChange("TribeCategory", userData?.tribeCategory);
      uiEvent.HandleUiStateChange("TribeName", userData?.tribeName);
      //
      // uiEvent.HandleUiStateChange("Name", userData.name);
      // uiEvent.HandleUiStateChange("Name", userData.name);
      // uiEvent.HandleUiStateChange("Name", userData.name);
    });
  }, [userId, value]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const handleNavigate = (route: string) => {
    navigate(route);
    // 페이지를 새로고침
    window.location.reload();
  };
  const HeaderBackButton = () => {
    return (
      <IconButton
        sx={{
          ":hover": { backgroundColor: "#F2F2F2" },
          mb: 0.5,
          mr: 1,
        }}
        onClick={() => {
          handleNavigate("/usrMng");
        }}
      >
        <ArrowBackIosNewIcon fontSize="medium" />
      </IconButton>
    );
  };

  const [uiState, setUiState] = useState(new UserDetailsUiState());
  // uiEvent 핸들러 정의
  const uiEvent: UserDetailsUiEvent = {
    HandleUiStateChange: (key: keyof UserDetailsUiState, value: any) => {
      setUiState((prevState) => ({
        ...prevState,
        [key]: value, // key에 해당하는 상태를 업데이트
      }));
    },
  };
  const HeaderButton = () => {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Button
          sx={{
            height: 40,
            backgroundColor: "#5D38E5",
            color: "white",
            borderRadius: 5,
            width: 110,
            ":hover": {
              backgroundColor: "#A38BFC",
            },
            textTransform: "none",
          }}
          onClick={() => {
            const localNumberError = uiState.LocalNumber.trim() === "";
            const phoneNumberError = uiState.PhoneNumber.trim() === "";
            const blockEndDateError = uiState.BlockEndDate === 0;
            // 에러 상태를 한 번에 업데이트
            uiEvent.HandleUiStateChange("LocalNumberError", localNumberError);
            uiEvent.HandleUiStateChange("PhoneNumberError", phoneNumberError);
            uiEvent.HandleUiStateChange("BlockEndDateError", blockEndDateError);
            // 에러가 하나라도 있으면 함수 종료
            if (localNumberError || phoneNumberError || (uiState.Status == ADMIN_CONFIG.PAGE_MEMBER.BLOCKED && blockEndDateError)) {
              return;
            } else {
              const formData = new FormData();

              if (uiState.IDCard) {
                formData.append("file", uiState.IDCard);
              } else {
                formData.append("file", "");
              }
              const userData = {
                uuid: uiState.uuid,
                phoneNumber: uiState.PhoneNumber,
                status: uiState.Status,
                blockEndDate: uiState.Status == ADMIN_CONFIG.PAGE_MEMBER.BLOCKED ? uiState.BlockEndDate : null,
                type: uiState.Type,
                group: uiState.Group,
                birthday: uiState.BirthOfDate,
                gender: uiState.Gender,
                tribeCategory: uiState.TribeCategory,
                tribeName: uiState.TribeName,
                verifiedBy: uiState.UserVerified,
                addressCountry: uiState.CountryAddress,
                addressProvince: uiState.CityAddress,
                addressDetail: uiState.DetailAddress,
              };
              console.log(userData);
              const blob = new Blob([JSON.stringify(userData)], {
                type: "application/json",
              });
              formData.append("userData", blob);

              ApiService.WebPost("/pw/users/updateUserData", formData, {
                "Content-Type": "multipart/form-data",
              }).then((res) => {
                console.log(res?.data);
                if (res?.data.body == "update user data") {
                  window.location.reload();
                }
              });
            }
          }}
        >
          Update
        </Button>
      </Box>
    );
  };

  useEffect(() => {
    console.log(uiState.BlockEndDate);
  }, [uiState.BlockEndDate]);
  return (
    <CommonList title="Member Details" setModal={null} headerButton={value == "one" && HeaderButton()} headerBackButton={HeaderBackButton()}>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="inherit"
          TabIndicatorProps={{
            style: {
              backgroundColor: color.primary500,
            },
          }}
          sx={{ mt: 1 }}
        >
          <Tab
            value="one"
            label={
              <Typography
              sx={{
                fontStyle:fontStyle.semiboldM,
                color: value === "one" ? color.primary500: color.gray500,
                textTransform: "none"
              }}
              
              >
                User
              </Typography>
            }
            sx={{
              minWidth: 0,
              minHeight: 0,
              height: "35px",
              pl: 0.7,
              pr: 0.7,
              mt: 2.7,
            }} // 최소 너비 설정
          />
          <Tab
            value="two"
            label={
              <Typography
              sx={{
                fontStyle:fontStyle.semiboldM,
                color: value === "two" ? color.primary500: color.gray500,
                textTransform: "none"
              }}
              >
                Scooter
              </Typography>
            }
            sx={{
              minWidth: 0,
              minHeight: 0,
              height: "35px",
              pl: 1.5,
              pr: 1.5,
              mt: 2.7,
              ml: 2.5,
            }} // 최소 너비 설정
          />
          <Tab
            value="three"
            label={
              <Typography
              sx={{
                fontStyle:fontStyle.semiboldM,
                color: value === "three" ? color.primary500: color.gray500,
                textTransform: "none"
              }}
              >
                Membership
              </Typography>
            }
            sx={{
              minWidth: 0,
              minHeight: 0,
              height: "35px",
              pl: 1.5,
              pr: 1.5,
              mt: 2.7,
              ml: 2.5,
            }} // 최소 너비 설정
          />
          <Tab
            value="four"
            label={
              <Typography
              sx={{
                fontStyle:fontStyle.semiboldM,
                color: value === "four" ? color.primary500: color.gray500,
                textTransform: "none"
              }}
              >
                Carbon Reduction
              </Typography>
            }
            sx={{
              minWidth: 0,
              minHeight: 0,
              height: "35px",
              pl: 1.5,
              pr: 1.5,
              mt: 2.7,
              ml: 2.5,
            }} // 최소 너비 설정
          />
          <Tab
            value="five"
            label={
              <Typography
              sx={{
                fontStyle:fontStyle.semiboldM,
                color: value === "five" ? color.primary500: color.gray500,
                textTransform: "none"
              }}
              >
                Wallet
              </Typography>
            }
            sx={{
              minWidth: 0,
              minHeight: 0,
              height: "35px",
              pl: 1.5,
              pr: 1.5,
              mt: 2.7,
              ml: 2.5,
            }} // 최소 너비 설정
          />
           <Tab
            value="six"
            label={
              <Typography
              sx={{
                fontStyle:fontStyle.semiboldM,
                color: value === "six" ? color.primary500: color.gray500,
                textTransform: "none"
              }}
              >
                NFC
              </Typography>
            }
            sx={{
              minWidth: 0,
              minHeight: 0,
              height: "35px",
              pl: 1.5,
              pr: 1.5,
              mt: 2.7,
              ml: 2.5,
            }} // 최소 너비 설정
          />
        </Tabs>
        {value == "one" && <UserDetailGrid uiState={uiState} uiEvent={uiEvent} />}
        {value == "two" && <UserScooterInfoGrid uiState={uiState} uiEvent={uiEvent} />}
        {value == "three" && <UserMembershipGrid />}
        {value == "four" && <CarbonReduction />}
        {value == "five" && <UserWallet />}
        {value == "six" && <UserNFC />}
      </Box>
    </CommonList>
  );
};

export default UserDetails;
