import { Button } from "@mui/material";

export function DrawerButton({ onClick }: { onClick: () => void }) {
  return (
    <Button
      onClick={onClick}
      sx={{
        minWidth: 0,
        padding: 0,
        width: "32px",
        height: "32px",
        borderRadius: "5px",
        backgroundColor: "rgba(255, 255, 255, 0.2)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.2)", // Hover 시 효과
        },
      }}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 6H20M4 12H20M4 18H20"
          stroke="white" // 아이콘 색상
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Button>
  );
}
