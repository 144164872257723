import { Box, CardContent, Typography } from "@mui/material";
import { fontStyle } from "../../../../../theme/Style";
import VerticalSpacer from "../../../../../component/Spacer";
import CommonSearch from "../../../../../component/CommonSearch";
import { color } from "../../../../../theme/Color";
import { ApiService } from "../../../../../restAPI/ApiService";
import { ProductConfirmationListTable } from "../../../component/findInList/ProductConfirmationListTable";
import { HeadCell } from "../../../../../component/CommonTable";
import { SetStateAction, useEffect, useState } from "react";
import { ProductNameRegistrationUiState } from "./model/ProductNameRegistrationUiState";
import { ProductNameRegistrationUiEvent } from "./model/ProductNameRegistrationUiEvent";

interface FindInListProps {
  uiState: ProductNameRegistrationUiState;
  uiEvent: ProductNameRegistrationUiEvent;
}

export function FindInListCard({ uiState, uiEvent }: FindInListProps) {
  async function productListInfo(): Promise<boolean | undefined> {
    const url = "/pw/aw/material/prodName/search";
    const requestBody = {
      searchContents: uiState.searchContents,
      page: uiState.page,
    };
    try {
      const res = await ApiService.webPost(url, requestBody);
      const data = res.data;
      console.log("data : ", data);
      uiEvent.HandleUiStateChange(
        "pageCount",
        Math.ceil(data.docCount / uiState.rowsPerPage)
      );
      uiEvent.HandleUiStateChange("docCount", data.docCount);
      uiEvent.HandleUiStateChange("productList", data.body);
      if (typeof data === "object" && Object.keys(data).length === 0)
        return undefined;
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    const fetchProductInfo = async () => {
      try {
        const result = await productListInfo();
        if (result === undefined) {
          uiEvent.HandleUiStateChange("pageCount", 1);
          return;
        }
      } catch {
        console.log("failed");
      }
    };
    fetchProductInfo();
  }, [uiState.rowsPerPage]);

  useEffect(() => {
    if (uiState.ProductId) {
      const partsCode = uiState.productList.filter(
        (data: any) => data.id == uiState.ProductId
      )[0].partsCode;
      const productName = uiState.productList.filter(
        (data: any) => data.id == uiState.ProductId
      )[0].productName;
      const image = uiState.productList.filter(
        (data: any) => data.id == uiState.ProductId
      )[0].image;
      uiEvent.HandleUiStateChange("PartsCode", partsCode);
      uiEvent.HandleUiStateChange("ProductName", productName);
      uiEvent.HandleUiStateChange("image", image);
      uiEvent.HandleUiStateChange("modelName", "");
    }
  }, [uiState.ProductId]);
  return (
    <>
      <CardContent
        sx={{
          width: "100%",
          padding: "0px",
        }}
      >
        <Typography fontStyle={fontStyle.semiboldL}>Find in List</Typography>
        <VerticalSpacer height={16} />
      </CardContent>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <CommonSearch
          placeHolder="Code, Product Name, Spec, Manufacturer"
          placeholderSx={{ fontStyle: fontStyle.regularS }}
          searchContents={uiState.searchContents}
          setSearchContents={(newSearchContents: string) => {
            uiEvent.HandleUiStateChange("searchContents", newSearchContents);
          }}
          sx={{
            width: "100%",
            borderRadius: "10px",
            border: "1px solid",
            borderColor: color.gray300,
            minHeight: "48px",
          }}
          onKeyPress={() => {
            productListInfo();
          }}
        />
      </Box>
      <VerticalSpacer height={24} />
      <ProductConfirmationListTable
        headCells={productConfirmationHeadCells}
        docCount={uiState.docCount}
        rowsPerPage={uiState.rowsPerPage}
        pageCount={uiState.pageCount}
        page={uiState.page}
        setPage={(newPage: SetStateAction<number>) => {
          uiEvent.HandleUiStateChange("page", newPage);
        }}
        setRowsPerPage={(newRowsperPage: SetStateAction<number>) => {
          uiEvent.HandleUiStateChange("rowsPerPage", newRowsperPage);
        }}
        productList={uiState.productList}
        MaterialId={uiState.ProductId}
        uiState={uiState}
        uiEvent={uiEvent}
      />
    </>
  );
}

const productConfirmationHeadCells: HeadCell[] = [
  {
    disablePadding: false,
    align: "left",
    id: "radio",
    label: "",
  },
  {
    disablePadding: false,
    align: "left",
    id: "category",
    label: "Category",
  },
  {
    disablePadding: false,
    align: "left",
    id: "partsCode",
    label: "Parts Code",
  },
  {
    disablePadding: false,
    align: "left",
    id: "productName",
    label: "Product Name",
  },
  {
    disablePadding: false,
    align: "left",
    id: "spec",
    label: "Spec",
  },
];
