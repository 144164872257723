import { Box, TextField, Typography } from "@mui/material";
import CommonButton from "../../component/CommonButton";
import { MoreProfileEditPageSideEffect } from "./MoreProfileEditPageSideEffect";
import Toolbar from "../../component/Toolbar";
import VerticalSpacer from "../../component/Spacer";
import InputField from "./section/InputField";
import EditPhoneNumberField from "./section/EditPhoneNumberField";
import EditGenderField from "./section/EditGenderField";
import EditBirthDateField from "./section/EditBirthDateField";
import EditAddressField from "./section/EditAddressField";
import { MoreProfileEditPageUiState } from "./MoreProfileEditPageUiState";
import { MoreProfileEditPageUiEvent } from "./MoreProfileEditPageUiEvent";
import SimpleDialog from "../../component/dialog/SimpleDialog";
import { LocalizedText } from "../../di/LanguageRepositoryProvider";
import { color } from "../../theme/Color";
import { fontStyle } from "../../theme/Style";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../constants/RouteConstants";

interface MoreProfileEditRenderProps {
  sideEffect: MoreProfileEditPageSideEffect;
  uiState: MoreProfileEditPageUiState;
  uiEvent: MoreProfileEditPageUiEvent;
}

const MoreProfileEditRender = ({
  sideEffect,
  uiState,
  uiEvent,
}: MoreProfileEditRenderProps) => {
  const navigate = useNavigate();
  // useEffect(() => {
  //   alert("window.innerHeight is : " + `${window.innerHeight}`);
  // }, [window.innerHeight]);

  // useEffect(() => {
  //   alert(
  //     "window.visualViewport?.height is : " + `${window.visualViewport?.height}`
  //   );
  // }, [window.visualViewport?.height]);

  // const fullHeight = useRef(0);

  // useEffect(() => {
  //   const handleVisualViewportResize = () => {
  //     // aOS에서 키패드가 노출된 경우
  //     if (fullHeight.current > window.innerHeight) {
  //       alert("aaaaaa");
  //     }
  //   };

  //   fullHeight.current = window.innerHeight;

  //   visualViewport?.addEventListener("resize", handleVisualViewportResize);

  //   return () =>
  //     visualViewport?.removeEventListener("resize", handleVisualViewportResize);
  // }, []);

  return (
    <>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          overflowY: "scroll",
          scrollbarWidth: "none", // Firefox에서 스크롤바 숨기기
          "&::-webkit-scrollbar": {
            display: "none", // Webkit 브라우저에서 스크롤바 숨기기
          },
        }}
      >
        {/* 상단 toolbar */}
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Toolbar
            title={LocalizedText("ua_g210_editprofile")}
            onBack={() => {
              uiEvent.ShowAlertDialog();
            }}
          />
        </Box>
        {/* 중간 요소 */}
        <VerticalSpacer height={23.83} />
        <Box
          sx={{
            width: "calc(100% - 40px)",
            // height:
            // flexGrow: 1,
          }}
        >
          <InputField
            type={LocalizedText("ua_g210_name")}
            content={uiState.name}
          />
          <VerticalSpacer height={30} />
          <InputField
            type={LocalizedText("ua_g210_email")}
            content={uiState.email}
          />
          <VerticalSpacer height={30} />
          <EditPhoneNumberField
            type="Phone"
            title={LocalizedText("ua_g210_phonenumber")}
            localNumber={uiState.localNumber}
            phoneNumber={uiState.phoneNumber}
            localNumberError={uiState.localNumberError}
            phoneNumberError={uiState.phoneNumberError}
            uiEvent={uiEvent}
          />
          <VerticalSpacer height={30} />
          <EditBirthDateField
            title={LocalizedText("ua_g210_birthofdate")}
            Day={uiState.day}
            Month={uiState.month}
            Year={uiState.year}
            dayError={uiState.dayError}
            monthError={uiState.monthError}
            yearError={uiState.yearError}
            uiEvent={uiEvent}
          />
          <VerticalSpacer height={30} />
          <EditGenderField
            type="Gender"
            title={LocalizedText("ua_g210_gender")}
            gender={uiState.gender}
            genderError={uiState.genderError}
            uiEvent={uiEvent}
          />
          <VerticalSpacer height={30} />
          <EditAddressField
            type="Address"
            title={LocalizedText("ua_g210_address")}
            country={uiState.country}
            cityCountyDistrict={uiState.cityCountyDistrict}
            cityDetail={uiState.cityDetail}
            countryError={uiState.countryError}
            cityCountyDistrictError={uiState.cityCountyDistrictError}
            cityDetailError={uiState.cityDetailError}
            uiEvent={uiEvent}
          />
          <VerticalSpacer height={30} />
          <Typography fontStyle={fontStyle.titleS} sx={{ mt: "8.75px" }}>
            Tribe (Only if applicable)
          </Typography>
          <TextField
            variant="standard"
            size="medium"
            value={uiState.tribeCategory}
            placeholder="Category"
            onChange={(event) => {
              uiEvent.HandleTribeCategory(event.target.value);
            }}
            inputProps={{
              style: {
                height: "50px",
                padding: "0px 0px",
              },
            }}
            sx={{
              width: "100%",
              flexGrow: 1,
              "& .MuiInput-root": {
                "&:before": {
                  // borderBottomColor: color.gray300, // 기본 테두리 색상
                },
                "&:hover:not(.Mui-disabled):before": {
                  borderBottomColor: color.primary500, // 호버 시 테두리 색상
                },
                "&.Mui-focused:after": {
                  borderBottomColor: color.primary500, // 포커스 시 테두리 색상
                },
                "&:after": {
                  borderBottomColor: color.primary500, // 포커스가 풀린 후 기본 색상
                },
              },
            }}
          />
          <VerticalSpacer height={14} />
          <TextField
            variant="standard"
            size="medium"
            placeholder="Name"
            value={uiState.tribeName}
            onChange={(event) => {
              uiEvent.HandleTribeName(event.target.value);
            }}
            inputProps={{
              style: {
                height: "50px",
                padding: "0px 0px",
              },
            }}
            sx={{
              width: "100%",
              flexGrow: 1,
              "& .MuiInput-root": {
                "&:before": {
                  // borderBottomColor: color.gray300, // 기본 테두리 색상
                },
                "&:hover:not(.Mui-disabled):before": {
                  borderBottomColor: color.primary500, // 호버 시 테두리 색상
                },
                "&.Mui-focused:after": {
                  borderBottomColor: color.primary500, // 포커스 시 테두리 색상
                },
                "&:after": {
                  borderBottomColor: color.primary500, // 포커스가 풀린 후 기본 색상
                },
              },
            }}
          />

          <VerticalSpacer height={30} />
          <VerticalSpacer height={66} />
        </Box>
        {/* 하단 버튼 */}
        <Box
          sx={{
            position: "absolute",
            bottom: "0px",
            width: "100%",
            display: "flex",
          }}
        >
          <CommonButton
            title={LocalizedText("ua_g210_complete_button")}
            textColor="white"
            onClick={uiEvent.VerifyingData}
            sx={{
              mx: "20px",
              mb: "14px",
            }}
          />
        </Box>
        <SimpleDialog
          children={LocalizedText("common_alert_goprevious")}
          isOpen={uiState.isShowAlertDialog}
          onNagative={uiEvent.HideAlertDialog}
          onPositive={() => {
            sideEffect.navigate("Profile");
          }}
          onClose={uiEvent.HideAlertDialog}
          positiveText="OK"
        />
        <SimpleDialog
          children={LocalizedText("ua_g210_alert_updateinfo_message")}
          isOpen={uiState.isDoEditCompleteDialog}
          onNagative={() => {
            uiEvent.SetIsDoEditCompleteDialog(false);
          }}
          onPositive={() => {
            sideEffect.requestUpdateProfile();
            console.log("update profile");
          }}
          onClose={() => {
            uiEvent.SetIsDoEditCompleteDialog(false);
          }}
          positiveText="OK"
        />
        <SimpleDialog
          children={LocalizedText("ua_g210_alert_updated_message")}
          isOpen={uiState.isEditCompleteDialog}
          onPositive={() => {
            uiEvent.SetIsEditCompleteDialog(false);
            window.history.back();
          }}
          onClose={() => {
            uiEvent.SetIsEditCompleteDialog(false);
          }}
          positiveText="OK"
        />
        <SimpleDialog
          children={LocalizedText("ua_g210_alert_updatephone_message")}
          isOpen={uiState.isShownPhoneNumberVerificationDialog}
          onPositive={() => {
            uiEvent.ShowAndHidePhoneNumberVerificationDialog(false);
            navigate(ROUTE_PATHS.PHONE_AUTH, {
              state: {
                path: ROUTE_PATHS.EDIT_PROFILE,
                localNumber: uiState.localNumber,
                phoneNumber: uiState.phoneNumber,
              },
            });
          }}
          onClose={() => {
            uiEvent.ShowAndHidePhoneNumberVerificationDialog(false);
          }}
          onNagative={() => {
            uiEvent.ShowAndHidePhoneNumberVerificationDialog(false);
          }}
          nagativeText={"Cancel"}
          positiveText={"OK"}
        />
      </Box>
    </>
  );
};

export default MoreProfileEditRender;
