import { Box } from "@mui/material";
import { BannerModel } from "../../MobilePartners/model/BannerModel";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import { color } from "../../../theme/Color";
import { fontStyle } from "../../../theme/Style";

interface BannerSwiperProps {
  banners: BannerModel[];
  onClick: (link: string) => void;
}

const BannerSwiper = ({ banners, onClick }: BannerSwiperProps) => {
  type CustomCSSProperties = React.CSSProperties & {
    "--swiper-pagination-color"?: string;
    "--swiper-pagination-top"?: string;
    "--swiper-pagination-bottom"?: string;
    "--swiper-pagination-left"?: string;
    "--swiper-pagination-right"?: string;
    "--swiper-pagination-fraction-color"?: string;
  };

  // Define the custom styles
  const swiperStyles: CustomCSSProperties = {
    "--swiper-pagination-fraction-color": "white",
    height: "100%",
    width: "100%",
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Swiper
        style={swiperStyles}
        slidesPerView={1}
        spaceBetween={10}
        autoplay={{
          delay: 6000, // 6초
          disableOnInteraction: false, // 사용자 인터랙션 후에도 자동 슬라이드
          reverseDirection: false, // 역방향 금지 
        }}
        loop={true} // 무한 루프
        direction="horizontal" // 수평 슬라이드
        pagination={{
          type: "fraction",
          renderFraction: function (currentClass: any, totalClass: any) {
            return (
              '<div style="background-color: ' +
              color.baseDimmer +
              '; border-radius: 50px; display: flex; align-items: center; justify-content: center; padding: 2px 8px; width: fit-content; position: absolute; right: 10px; bottom: 8px">' +
              '<span class="' +
              currentClass +
              '" style="' +
              "font-size: " +
              fontStyle.bodySr.fontSize +
              "; " +
              "line-height: " +
              fontStyle.bodySr.lineHeight +
              "; " +
              "font-weight: " +
              fontStyle.bodySr.fontWeight +
              ';">' +
              "</span>" +
              '<span style="color: ' +
              color.gray300 +
              "; " +
              "font-size: " +
              fontStyle.bodySr.fontSize +
              "; " +
              "line-height: " +
              fontStyle.bodySr.lineHeight +
              "; " +
              "font-weight: " +
              fontStyle.bodySr.fontWeight +
              '; margin: 0 2px;"> · </span>' +
              '<span class="' +
              totalClass +
              '" style="color: ' +
              color.gray300 +
              "; " +
              "font-size: " +
              fontStyle.bodySr.fontSize +
              "; " +
              "line-height: " +
              fontStyle.bodySr.lineHeight +
              "; " +
              "font-weight: " +
              fontStyle.bodySr.fontWeight +
              ';">' +
              "</span>" +
              "</div>"
            );
          },
        }}
        modules={[Pagination, Autoplay]} // Autoplay 모듈 추가
      >
        {banners.map((item, index) => (
          <SwiperSlide key={index}>
            <img
              src={item.image}
              style={{ width: "100%", height: "175px", borderRadius: 10 }}
              onClick={() => {
                onClick(item.link);
              }}
            />
            <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default BannerSwiper;
