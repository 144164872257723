import {
  CharacterResponse,
  CouponResponse,
  ReductionHistoryResponse,
} from "../model/CarbonReductionResponse";
import {
  CarbonReductionUiState,
  CharacterInfo,
  CouponInfo,
  ReductionHistory,
} from "../model/CarbonReductionUiState";

export function CarbonReductionMapper(
  characterResponse: CharacterResponse,
  couponResponse: CouponResponse,
  reductionHistoryResponse: ReductionHistoryResponse
) {
  const characterInfo: CharacterInfo[] = [
    {
      image: characterResponse.image,
      level: characterResponse.level,
      id: characterResponse.id,
      carbonReduction: characterResponse.carbonReduction,
      exp: characterResponse.exp,
      maxExp: characterResponse.maxExp,
      issueDate: characterResponse.issueDate,
      completionDate: characterResponse.completionDate,
    },
  ];

  const couponInfo: CouponInfo[] = [
    {
      name: couponResponse.name,
      content: couponResponse.content,
      availableDate: couponResponse.availableDate,
      issuedDate: couponResponse.issuedDate,
      usedDate: couponResponse.usedDate,
      status: couponResponse.status,
    },
  ];

  const reductionHistory: ReductionHistory[] = [
    {
      label: reductionHistoryResponse.label || "",
      value: reductionHistoryResponse.value || 0,
    },
  ];
  return new CarbonReductionUiState(
    characterInfo,
    couponInfo,
    reductionHistory
  );
}
