import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase";
import { SERVER_CONFIG } from "../constants/ServerConfig";
import { getCountryCode, getLanguageCode } from "../customHook/useLocale";

/**
 * Logger Class
 *
 * Firebase Analytics 및 콘솔 로깅을 위한 전역 로깅 시스템.
 * - Firebase Analytics에 이벤트를 기록하거나, 개발 환경에서는 콘솔에 로그를 출력합니다.
 * - 이벤트 레벨(`error`, `warn`, `info`, `debug`)에 따라 로그의 심각도를 분류합니다.
 *
 * 사용 예시:
 *  Logger.error("api_failure", { endpoint: "/login", status: 500 });
 *  Logger.warn("low_memory", { available: "100MB" });
 *  Logger.info("user_signed_up", { user_id: "12345" });
 *  Logger.debug("Fetching data from API");
 *
 */

type Level = "error" | "warn" | "info" | "debug";

// 로그 레벨의 우선순위 설정
const levelPriority: Record<Level, number> = {
  error: 1,
  warn: 2,
  info: 3,
  debug: 4,
};

// 현재 환경에 따라 로그 레벨 설정 (운영 환경에서는 info 이상만 출력)
const currentLevel: Level = SERVER_CONFIG.IS_PRODUCTION ? "info" : "debug";

class Logger {
  private static instance: Logger | null = null;
  private analytics = analytics;

  private constructor() {}

  // 싱글톤 인스턴스 반환
  public static getInstance(): Logger {
    if (!Logger.instance) {
      Logger.instance = new Logger();
    }
    return Logger.instance;
  }

  // 에러 레벨 로그
  public static error(eventName: string, params: Record<string, any> = {}) {
    Logger.getInstance().log("error", eventName, params);
  }

  // 경고 레벨 로그
  public static warn(eventName: string, params: Record<string, any> = {}) {
    Logger.getInstance().log("warn", eventName, params);
  }

  // 정보 레벨 로그
  public static info(eventName: string, params: Record<string, any> = {}) {
    Logger.getInstance().log("info", eventName, params);
  }

  // 디버그 레벨 로그
  public static debug(message: string) {
    Logger.getInstance().log("debug", "debug", { message });
  }

  // 로그 레벨에 따라 출력 여부 결정
  private shouldLog(level: Level): boolean {
    return levelPriority[level] <= levelPriority[currentLevel];
  }

  // 내부 이벤트 로깅 처리
  private log(level: Level, eventName: string, params: Record<string, any>) {
    const mergedParams = {
      ...getDefaultParams(),
      ...params,
    };

    // Firebase 전송 (운영 환경에서 info 이상)
    if (
      SERVER_CONFIG.IS_PRODUCTION &&
      levelPriority[level] <= levelPriority["info"]
    ) {
      logEvent(this.analytics, eventName, mergedParams);
    }

    // 콘솔 출력 (현재 로그 레벨에 따라 결정)
    if (this.shouldLog(level)) {
      this.logToConsole(level, eventName, mergedParams);
    }
  }

  // 콘솔에 로그 출력
  private logToConsole(
    level: Level,
    eventName: string,
    params: Record<string, any>,
  ) {
    const message = `[Logger] ${eventName}`;
    const detailedParams = JSON.stringify(params, null, 2); // 객체를 보기 좋게 문자열로 변환

    switch (level) {
      case "error":
        console.error(message, detailedParams);
        break;
      case "warn":
        console.warn(message, detailedParams);
        break;
      case "info":
        console.info(message, detailedParams);
        break;
      case "debug":
      default:
        console.debug(message, detailedParams);
        break;
    }
  }
}

// 플랫폼 및 기본 정보 자동 감지
const getDefaultParams = () => {
  const platform =
    typeof window !== "undefined" && (window.Android || window.webkit)
      ? window.Android
        ? "AOS"
        : "iOS"
      : "WEB";

  const emailKey = platform === "WEB" ? "user_email" : "email";
  const uuidKey = platform === "WEB" ? "user_uuid" : "uuid";

  const email = getLocalStorageItem(emailKey, "unknown");
  const uuid = getLocalStorageItem(uuidKey, "unknown");
  const languageCode = getLanguageCode();
  const countryCode = getCountryCode();
  const osVersion = getLocalStorageItem("os_version", "unknown");
  const appVersion = getLocalStorageItem("app_version", "unknown");

  return {
    platform: platform,
    email: email,
    uuid: uuid,
    countryCode: countryCode,
    languageCode: languageCode,
    osVersion: osVersion,
    appVersion: appVersion,
  };
};

// 안전한 localStorage 접근 함수
const getLocalStorageItem = (key: string, defaultValue: string) => {
  try {
    return localStorage.getItem(key) || defaultValue;
  } catch (error) {
    return defaultValue;
  }
};

export default Logger;
