import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { color } from "../../../../../theme/Color";
import { fontStyle } from "../../../../../theme/Style";
import ReductionBarChart from "./ReductionBarChart";
import { ReductionHistoryTab } from "./ReductionHistoryTab";
import { CarbonReductionUiState } from "../model/CarbonReductionUiState";
import { CarbonReductionUiEvent } from "../model/CarbonReductionUiEvent";
import VerticalSpacer from "../../../../../component/Spacer";
import { TabType } from "../model/TabType";
import { ReductionHistoryGraph } from "./ReductionHistoryGraph";

interface ReductionHistoryProps {
  uiState: CarbonReductionUiState;
  uiEvent: CarbonReductionUiEvent;
}

export function ReductionHistory({ uiState, uiEvent }: ReductionHistoryProps) {
  return (
    <Grid item lg={12}>
      <Card
        sx={{
          padding: "0px",
          border: "1px solid",
          borderColor: color.gray300,
          borderRadius: "15px",
          boxShadow: "unset",
        }}
      >
        <CardContent
          sx={{
            padding: "24px",
            "&:last-child": {
              paddingBottom: "40px",
            },
            height: "420px",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              <Typography fontStyle={fontStyle.semiboldL}>
                Reduction History
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                <Typography
                  fontStyle={fontStyle.displayM}
                  color={color.primary500}
                >
                  {uiState.reductionHistory
                    .reduce((sum, item) => sum + item.value, 0)
                    .toFixed(1)}
                </Typography>
                <Typography
                  fontStyle={fontStyle.semiboldL}
                  color={color.gray600}
                >
                  kgCO2-eq
                </Typography>
              </Box>
            </Box>
            <ReductionHistoryTab
              onClick={(tab) => {
                uiEvent.OnClickTab(tab);
              }}
              uiState={uiState}
            />
          </Box>
          <VerticalSpacer height={16} />
          {(uiState.currentTab === TabType.YEAR ||
            uiState.currentTab === TabType.MONTH) && (
            <ReductionBarChart uiState={uiState} uiEvent={uiEvent} />
          )}
          {uiState.currentTab === TabType.DAY && (
            <ReductionHistoryGraph uiState={uiState} />
          )}
        </CardContent>
      </Card>
    </Grid>
  );
}
