import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { color } from "../../../../../theme/Color";
import { fontStyle } from "../../../../../theme/Style";
import VerticalSpacer from "../../../../../component/Spacer";
import { CommonSelect } from "../../../../../component/CommonSelect";
import { CommonWebHeaderButton } from "../../../../../component/CommonWebHeaderButton";
import { UserNFCCreateUiState } from "./model/UserNFCCreateUiState";
import { UserNFCCreateUiEvent } from "./model/UserNFCCreateUiEvent";
import { useEffect } from "react";

interface UserNFCCreateCardProps {
  uiState: UserNFCCreateUiState;
  uiEvent: UserNFCCreateUiEvent;
}

export function UserNFCCreateCard({
  uiState,
  uiEvent,
}: UserNFCCreateCardProps) {
  const getType = (id: string) => {
    const card = uiState.nfcCard.find((card) => card.cardid === id);
    return card ? card.type : "";
  };

  const getValid = (id: string) => {
    const card = uiState.nfcCard.find((card) => card.cardid === id);
    return card ? card.valid : "";
  };

  useEffect(() => {
    const valid = getValid(uiState.cardId);
    uiEvent.handleUiStateChange("valid", valid);
  }, [uiState.cardId]);

  const reasonMenuItem =
    uiState.valid === "Active"
      ? [
          "Regist(New)",
          "Regist(Lost)",
          "Regist(Defect)",
          "Regist(Request)",
          "Regist(etc)",
        ]
      : [
          "No Regist",
          "Regist(Lost)",
          "Regist(Defect)",
          "Regist(Request)",
          "Regist(etc)",
        ];

  return (
    <Grid item xs={6}>
      <Card
        sx={{
          width: "100%",
          padding: "0px",
          border: "1px solid",
          borderColor: color.gray300,
          borderRadius: "15px",
          boxShadow: "unset",
        }}
      >
        <CardContent sx={{ padding: "24px" }}>
          <Typography fontStyle={fontStyle.semiboldL}>Information</Typography>
          <VerticalSpacer height={16} />
          <Box>
            <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
              Card
            </Typography>
            <VerticalSpacer height={8} />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <CommonSelect
                selectValue={uiState.cardId}
                onChange={(event) => {
                  uiEvent.handleUiStateChange("cardId", event.target.value);
                }}
                menuitems={uiState.nfcCard.map((data) => data.cardid)}
                sx={{ height: "48px" }}
                error={uiState.errors.cardIdError}
                placeholder="Select"
              />
            </Box>
            {uiState.errors.cardIdError && (
              <Typography fontStyle={fontStyle.regularXS} color={color.error}>
                Please select a card.
              </Typography>
            )}
            <VerticalSpacer height={40} />
            <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
              Type
            </Typography>
            <VerticalSpacer height={8} />
            <Box
              sx={{
                height: "48px",
                backgroundColor: color.gray200,
                border: "1px solid",
                borderRadius: "10px",
                borderColor: color.gray300,
                px: "16px",
                py: "12px",
              }}
            >
              <Typography fontStyle={fontStyle.regularS} color={color.gray600}>
                {getType(uiState.cardId) || "-"}
              </Typography>
            </Box>
            <VerticalSpacer height={40} />
            <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
              Valid
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <CommonSelect
                selectValue={getValid(uiState.cardId)}
                onChange={(event) => {}}
                menuitems={[]}
                sx={{ height: "48px" }}
                disabled
                placeholder="Select"
              />
            </Box>
            <VerticalSpacer height={40} />
            <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
              Reason
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <CommonSelect
                selectValue={uiState.reason}
                onChange={(event) => {
                  uiEvent.handleUiStateChange("reason", event.target.value);
                }}
                menuitems={reasonMenuItem}
                sx={{ height: "48px" }}
                disabled={uiState.cardId === ""}
                error={uiState.errors.reasonError}
                placeholder="Select"
              />
            </Box>
            {uiState.errors.reasonError && (
              <Typography fontStyle={fontStyle.regularXS} color={color.error}>
                Please select a reason.
              </Typography>
            )}
            <VerticalSpacer height={40} />
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <CommonWebHeaderButton
                sx={{
                  width: "85px",
                  height: "45px",
                  borderRadius: "40px",
                  display: "flex",
                  flexDirection: "column",
                }}
                buttonContent="Create"
                onClick={uiEvent.handleCreateNFC}
              />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}
