export class StationUiState {
  country: string;
  status: string;
  data: StationDataType[];
  countryList: { label: string; code: string }[] = [];

  constructor(country: string = "", status: string = "", data: StationDataType[] = [], countryList: { label: string; code: string }[] = []) {
    this.country = country;
    this.status = status;
    this.data = data;
    this.countryList = countryList;
  }
}

export type StationDataType = {
  no: number;
  svcId: number;
  svcStt: string;
  svcNm: string;
  svcAreaNm: string;
  ctryCd: string;
  managerId: string;
  lastUptDt: number;
  registerDt: number;
};
