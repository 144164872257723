import CommonTable, { HeadCell, useVisibleRows } from "../../../../../component/CommonTable";
import { MaterialsTableRow } from "./MaterialsTableRow";
import { MaterialsData } from "./model/MaterialsData";

interface MaterialsTableProps {
  headCells?: HeadCell[];
  rowsPerPage?: number;
  rows?: any;
  setRowsPerPage?: React.Dispatch<React.SetStateAction<number>>;
  page?: number;
  pageCount?: number;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  docCount?: number;
}

function isMaterialsData(data: any): data is MaterialsData {
  return (
    typeof (data as MaterialsData).id === "string" &&
    typeof (data as MaterialsData).no === "number" &&
    // typeof (data as MaterialsData).level === "number" &&
    typeof (data as MaterialsData).category === "string" &&
    typeof (data as MaterialsData).partsCode === "string" &&
    // typeof (data as MaterialsData).productName === "string" &&
    typeof (data as MaterialsData).spec === "string" &&
    // typeof (data as MaterialsData).quantities === "string" &&
    // typeof (data as MaterialsData).amount === "number" &&
    typeof (data as MaterialsData).company === "string"
  );
}

export function MaterialsTable({ headCells, rowsPerPage, rows, setRowsPerPage, docCount, pageCount, page, setPage }: MaterialsTableProps) {
  const { order, orderBy, handleRequestSort, handleChangePage, visibleRows } = useVisibleRows(rows, rowsPerPage || 10, setPage, page || 0);
  // const pagedata: MaterialsData[] = (visibleRows as unknown[])
  //   .filter(isMaterialsData)
  //   .slice((page || 0) * (rowsPerPage || 10), ((page || 0) + 1) * (rowsPerPage || 10));
  // console.log(pagedata);
  return (
    <CommonTable
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      page={page}
      docCount={docCount}
      pageCount={pageCount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      handleRequestSort={handleRequestSort}
      handleChangePage={handleChangePage}
      setPage={setPage}
    >
      {visibleRows.map((data: any, index: number) => (
        <MaterialsTableRow row={data} key={index} />
      ))}
    </CommonTable>
  );
}
