import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MobileHomeUiEvent } from "./model/MobileHomeUiEvent";
import { MobileHomeUiState } from "./model/MobileHomeUiState";
import { MobileHomeSideEffect } from "./model/MobileHomeSideEffect";
import MobileHomeRender from "./MobileHomeRender";
import useAndroidBackHandler from "../../customHook/useAndroidBackHandler";
import { getCountryCode } from "../../customHook/useLocale";
import { ApiService } from "../../restAPI/ApiService";

const MobileHomePage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [uiState, setUiState] = useState(new MobileHomeUiState());

  useEffect(() => {
    updateNoticeList();
  }, []);

  const uiEventHandler: MobileHomeUiEvent = {
    handleRefresh: async () => {
      await handleRefresh();
    },
  };

  const sideEffect: MobileHomeSideEffect = {
    navigate: (target: string, options?: { state: any }) => {
      navigate(target, options);
    },
  };

  const handleRefresh = async () => {
    setLoading(true);
    //데이터 새로고침 로직(API 호출 등)
    await new Promise((resolve) => setTimeout(resolve, 1500));
    console.log("새로고침 완료");
    setLoading(false);
  };

  async function updateNoticeList() {
    const url = "/pw/ua/contents/announce/recent";
    const reqBody = {
      country: getCountryCode(),
    };

    try {
      const res = await ApiService.post(url, reqBody);
      if (res != null || res != undefined) {
        setUiState((prev) => ({
          ...prev,
          notices: res.data?.body,
        }));
      }
    } catch (error) {
      console.log("공지사항 조회 실패", error);
    }
  }

  return (
    <>
      <MobileHomeRender
        uiState={uiState}
        uiEvent={uiEventHandler}
        sideEffect={sideEffect}
      />
    </>
  );
};

export default MobileHomePage;
