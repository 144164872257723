import { TableRow } from "@mui/material";
import { ProductionHistoryTableCell } from "./ProductionHistoryTableCell";
import { fontStyle } from "../../../../../theme/Style";
import { color } from "../../../../../theme/Color";
import { Text } from "../../../../../component/Text";
import LocalizedDate, { DateFormatType } from "../../../../../component/LocalizedDate";

interface ProductionTableRowProps {
  row?: any;
}

export function ProductionHistoryTableRow({ row }: ProductionTableRowProps) {
  return (
    <TableRow>
      <ProductionHistoryTableCell
        sx={{ paddingLeft: "24px" }}
        childrenContainerSx={{ width: "36px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={row.no}
          />
        }
        align={"center"}
      />
      <ProductionHistoryTableCell
        childrenContainerSx={{ width: "74px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={row.category}
          />
        }
        align={"center"}
      />
      <ProductionHistoryTableCell
        childrenContainerSx={{ width: "94px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={row.partsCode}
          />
        }
        align={"center"}
      />
      <ProductionHistoryTableCell
        childrenContainerSx={{ width: "107px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={row.productName}
          />
        }
        align={"center"}
      />
      <ProductionHistoryTableCell
        childrenContainerSx={{ width: "66px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={row.spec}
          />
        }
        align={"center"}
      />
      <ProductionHistoryTableCell
        childrenContainerSx={{ width: "91px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={row.made}
          />
        }
        align={"center"}
      />
      <ProductionHistoryTableCell
        childrenContainerSx={{ width: "61px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={row.quantities}
          />
        }
        align={"center"}
      />
      <ProductionHistoryTableCell
        childrenContainerSx={{ width: "49px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={row.amount}
          />
        }
        align={"center"}
      />
      <ProductionHistoryTableCell
        childrenContainerSx={{
          width: "70px",
          alignItems: "center",
        }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={row.updater}
          />
        }
        align={"center"}
      />
      <ProductionHistoryTableCell
        childrenContainerSx={{ width: "120px" }}
        sx={{ paddingRight: "24px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={LocalizedDate({
              date: row.registrationDate,
              type: DateFormatType.AdminType,
            })}
          />
        }
      />
    </TableRow>
  );
}
