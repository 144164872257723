import React, { useEffect, useState } from "react";
import LoginPageRender from "./LoginPageRender";
import { LoginPageUiEvent } from "./model/LoginPageUiEvent";
import { LoginPageSideEffect } from "./model/LoginPageSideEffect";
import { ApiService } from "../../restAPI/ApiService";
import { ROUTE_PATHS } from "../../constants/RouteConstants";
import { UserInfo } from "./model/UserInfo";
import { useNavigate } from "react-router-dom";
import {
  getLanguageCode,
  LANGUAGE_CODE_STORAGE_KEY,
  setLanguageCode,
} from "../../customHook/useLocale";
import {
  LocalizedText,
  useLanguageRepository,
} from "../../di/LanguageRepositoryProvider";
import { LoginPageUiState } from "./model/LoginPageUiState";

import { Box } from "@mui/material";
import axios from "axios";
import ProgressBar from "../../component/ProgressBar";
import { useTemporalErrorToast } from "../../component/toast/ConcurrentToastManager";
import Logger from "../../utils/Logger";

function onSuccessLogin(userData: object): void {
  const event = new CustomEvent("onSuccessLogin", { detail: userData });
  window.dispatchEvent(event);
}

(window as any).onSuccessLogin = onSuccessLogin;

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const languageRepository = useLanguageRepository();
  const [progress, setProgress] = useState(false);
  const showTemporalErrorToast = useTemporalErrorToast();

  const [uiState, setUiState] = useState(
    new LoginPageUiState(
      LocalizedText("ua_b100_title"),
      LocalizedText("ua_b100_message"),
      LocalizedText("ua_b100_google_button"),
      LocalizedText("ua_b100_apple_button"),
      LocalizedText("ua_b100_facebook_button"),
    ),
  );

  useEffect(() => {
    const authEvent = (event: Event) => {
      const customEvent = event as CustomEvent<{
        user_name: string;
        user_uuid: string;
        user_email: string;
        sns_Type: string;
      }>;

      // JSON 데이터에 접근하기
      const name = customEvent.detail.user_name;
      const uuid = customEvent.detail.user_uuid;
      const snsType = customEvent.detail.sns_Type;
      const email = customEvent.detail.user_email;

      const info: UserInfo = {
        name: name,
        uuid: uuid,
        snsType: snsType,
        email: email,
      };

      sideEffect.successLogin(info);
    };

    window.addEventListener("onSuccessLogin", authEvent);
    return () => {
      window.removeEventListener("onSuccessLogin", authEvent);
    };
  }, []);
  useEffect(() => {
    console.log(uiState.googleLoginText);

    languageRepository.getLocalizedText(
      "ua_g100_title",
      localStorage.getItem(LANGUAGE_CODE_STORAGE_KEY) ?? getLanguageCode(),
    );
  }, [uiState]);

  const uiEvent: LoginPageUiEvent = {
    onClickFacebookLogin: function (): void {
      sideEffect.requestFacebookLogin();
    },
    onClickGoogleLogin: function (): void {
      sideEffect.requestGoogleLogin();
    },
    onClickAppleLogin: function (): void {
      sideEffect.requestAppleLogin();
    },
    onClickEmailLogin: function (email: string, password: string): void {
      sideEffect.requestEmailLogin(email, password);
    },
    changeLanguage: function (languageCode: string): void {
      sideEffect.onChangeLanguage(languageCode);
      setUiState((prev) => {
        return {
          ...prev,
          title: languageRepository.getLocalizedText(
            "ua_b100_title",
            languageCode,
          ),
          subTitle: languageRepository.getLocalizedText(
            "ua_b100_message",
            languageCode,
          ),
          googleLoginText: languageRepository.getLocalizedText(
            "ua_b100_google_button",
            languageCode,
          ),
          appleLoginText: languageRepository.getLocalizedText(
            "ua_b100_apple_button",
            languageCode,
          ),
          facebookLoginText: languageRepository.getLocalizedText(
            "ua_b100_facebook_button",
            languageCode,
          ),
        };
      });
    },
  };

  const sideEffect: LoginPageSideEffect = {
    requestFacebookLogin: function (): void {
      if (window.Android) {
        console.log("requestFacebookLogin");
        window.Android.requestFacebookLogin();
      }
      if (window.webkit) {
        window.webkit.messageHandlers.requestFacebookLogin.postMessage(
          "requestFacebookLogin",
        );
      }
    },
    requestGoogleLogin: async function (): Promise<void> {
      if (window.Android) {
        console.log("requestGoogleLogin");
        window.Android.requestGoogleLogin();
      }
      if (window.webkit) {
        window.webkit.messageHandlers.requestGoogleLogin.postMessage(
          "requestGoogleLogin",
        );
      }
    },
    requestAppleLogin: function (): void {
      if (window.webkit) {
        window.webkit.messageHandlers.requestAppleLogin.postMessage(
          "requestAppleLogin",
        );
      }
    },
    requestEmailLogin: function (email: string, password: string): void {
      // 현재 미사용 기능입니다.
    },
    successLogin: function (currentUser: UserInfo): void {
      console.log("successLogin");
      validateAndStoreUserData(currentUser).then((r) => {
        // const uuid = currentUser.uuid;
        // if (uuid === null || uuid === "") {
        const email = currentUser.email;
        if (email === null || email === "") {
          showTemporalErrorToast();
        } else {
          checkUserRecentlyDeleted(email);
        }
      });
    },
    onChangeLanguage: function (languageCode: string): void {
      setLanguageCode(languageCode);
    },
  };

  function validateAndStoreUserData(currentUser: UserInfo): Promise<void> {
    return new Promise((resolve) => {
      // 유효한 데이터만 저장
      Object.entries(currentUser).forEach(([key, value]) => {
        if (value !== undefined) {
          localStorage.setItem(key, value ?? "");
        }
      });

      resolve();
    });
  }

  async function checkUserRecentlyDeleted(value: string) {
    setProgress(true);
    const requestBody = {
      // uuid: uuid,
      email: value,
    };
    try {
      const url = "/pw/users/checkUser";
      const response = await ApiService.post(url, requestBody);
      const data = response?.data?.body;
      if (data) {
        switch (data.status) {
          case "active": // 로그인
            console.log("[DEV] User is active");
            loginFinished();
            break;
          case "available": // 회원가입
            console.log("[DEV] User is available");
            navigate(ROUTE_PATHS.LOGIN_TERMS_OF_USE);
            break;
          case "notAvailable": // 탈퇴유저(가입 불가)
            console.log("[DEV] User is not available");
            const withdrawnTimestamp = data.withdrawnTimestamp as number;
            const withdrawnDate = new Date(withdrawnTimestamp);
            console.log("WITHDRAWN DATE", withdrawnDate);
            navigate(ROUTE_PATHS.WITHDRAWAL_INFO, {
              state: {
                email: data.email,
                withdrawalTimeMillisecond: withdrawnTimestamp,
              },
            });
            break;
          default:
            console.log("User status is invalid");
            break;
        }
      }
    } catch (e) {
      Logger.debug(`error ${e}`);
      showTemporalErrorToast();
    } finally {
      setProgress(false);
    }
  }

  async function loginFinished() {
    const url = "/nft/getUsersCharacterNft";
    const requestBody = {
      uuid: localStorage.getItem("uuid"),
    };
    try {
      const res = await axios.post("/blockchain" + url, requestBody, {});
      if (res.data.body.length <= 0) {
        // NFT 길이로 판단
        navigate(ROUTE_PATHS.CARBON_REDUCTION_NFT, {
          state: {
            state: {
              lastPath: ROUTE_PATHS.PHONE_AUTH,
            },
          }, // 상태 전달
        });
      } else {
        if (window.Android) {
          window.Android.onLoginSuccess();
        }
        if (window.webkit) {
          window.webkit.messageHandlers.onLoginSuccess.postMessage(
            "successLogin",
          );
        }
      }
    } catch (e) {
      // TODO 예외처리
      console.log(e);
    }
  }

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      {progress ? <ProgressBar /> : null}
      <LoginPageRender
        uiState={uiState}
        uiEvent={uiEvent}
        sideEffect={sideEffect}
      />
    </Box>
  );
};

export default LoginPage;
