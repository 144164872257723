export class WalletUiState {
  searchContents: string;
  walletInfo: WalletInfo[];
  docCount: number;
  rowsPerPage: number;
  page: number;
  pageCount: number;

  constructor(
    searchContents: string = "",
    walletInfo: WalletInfo[] = [
      {
        no: 1,
        id: "Test",
        address: "Test",
        stationName: "Test",
        balance: "Test",
        txCount: 1,
        lastUpdateDate: 1736327010739,
      },
      {
        no: 2,
        id: "Test2",
        address: "Test2",
        stationName: "Test2",
        balance: "Test2",
        txCount: 1,
        lastUpdateDate: 1673360300000,
      },
    ],
    docCount: number = 0,
    rowsPerPage: number = 10,
    page: number = 0,
    pageCount: number = 0
  ) {
    this.searchContents = searchContents;
    this.walletInfo = walletInfo;
    this.docCount = docCount;
    this.rowsPerPage = rowsPerPage;
    this.page = page;
    this.pageCount = pageCount;
  }
}

export interface WalletInfo {
  no: number;
  id: string;
  address: string;
  stationName: string;
  balance: string;
  txCount: number;
  lastUpdateDate: number;
}
