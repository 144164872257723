export class NFTCreateUiState {
  nftCharacterName: string = "";
  distributeDate: number = 0;
  country: string = "";
  rewardOptions: CouponInfo[] = [];

  // NFTLevelInfo
  levelInfos: NFTLevelInfo[] = Array.from({ length: 5 }, (_, i) => ({
    level: i + 1,
    levelName: "",
    image: undefined,
    maxExp: "",
    uuid: "",
    coupon: {
      id: "",
      name: "",
    },
  }));

  //validation
  errors = {
    nftCharacterNameError: false,
    distributeDateError: false,
    countryError: false,
    levelInfosError: Array.from({ length: 5 }, () => ({
      levelNameError: false,
      levelImageError: false,
      maxExpError: false,
      rewardError: false,
    })),
  };
}

export interface NFTLevelInfo {
  level: number;
  levelName: string;
  image: File | undefined;
  maxExp: string;
  uuid: string;
  coupon: CouponInfo;
}

export interface CouponInfo {
  id: string;
  name: string;
}
