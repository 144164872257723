import { Box, Button, Grid, IconButton } from "@mui/material";
import { color } from "../../../../../theme/Color";
import { fontStyle } from "../../../../../theme/Style";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import AddIcon from "@mui/icons-material/Add";
import { useState, useEffect } from "react";
import { UserDetailsUiEvent } from "../../../UserDetailsUiEvent";
import { UserDetailsUiState } from "../../../UserDetailsUiState";

interface UserScooterHeaderProps {
  uiEvent: UserDetailsUiEvent;
  uiState: UserDetailsUiState;
}

const UserScooterHeader = ({ uiEvent, uiState }: UserScooterHeaderProps) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  // 컴포넌트가 렌더링될 때 또는 selectedIndex가 변경될 때마다 선택한 스쿠터를 업데이트합니다.
  useEffect(() => {
    if (uiState.ScooterSheet.length > 0) {
      uiEvent.HandleUiStateChange("SelectedScooterContractSheet", uiState.ScooterSheet[selectedIndex]);
    }
  }, [selectedIndex, uiState.ScooterSheet]);

  const handleNext = () => {
    if (selectedIndex < uiState.ScooterSheet.length - 1) {
      setSelectedIndex(selectedIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);
    }
  };

  return (
    <>
      {/*  상단 페이지네이션 카드 섹션 */}
      <Grid item lg={12}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              width: "168px",
              height: "44px",
              display: "flex",
              bgcolor: "white",
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: "15px",
              border: 1,
              borderColor: color.gray300,
            }}
          >
            <IconButton onClick={handlePrevious} disabled={selectedIndex === 0}>
              <ChevronLeft />
            </IconButton>
            <Box
              sx={{
                display: "flex",
                flex: 1,
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
                borderRight: 1,
                borderLeft: 1,
                borderColor: color.gray300,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontStyle: fontStyle.semiboldS,
                }}
              >
                {selectedIndex + 1} / {uiState.ScooterSheet.length}
                {/* 인덱스 표시 */}
              </Box>
            </Box>
            <IconButton onClick={handleNext} disabled={selectedIndex === uiState.ScooterSheet.length - 1}>
              <ChevronRight />
            </IconButton>
          </Box>
          <Button
            sx={{
              display: "flex",
              width: "148px",
              height: "45px",
              bgcolor: color.primary500,
              color: "white",
              borderRadius: "45px",
              ":hover": {
                bgcolor: color.primary300,
              },
              textTransform: "none",
            }}
            onClick={() => {
              uiEvent.HandleUiStateChange("ScooterSheet", [
                ...uiState.ScooterSheet, // 기존 배열 데이터 유지
                "newSheet", // 추가할 새로운 데이터
              ]);
              // uiEvent.HandleUiStateChange(
              //   "SelectedScooterContractSheet",
              //   uiState.ScooterSheet[1]
              // );
            }}
          >
            <AddIcon fontSize="small" sx={{ mr: "6px" }} />
            Add Scooter
          </Button>
        </Box>
      </Grid>
    </>
  );
};

export default UserScooterHeader;
