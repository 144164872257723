import { Grid } from "@mui/material";
import { NFTInfoCard } from "./NFTInfoCard";
import { NFTLevelCard } from "./NFTLevelCard";
import { NFTDetailUiState } from "../model/NFTDetailUiState";
import { NFTDetailUiEvent } from "../model/NFTDetailUiEvent";

interface NFTDetailGridProps {
  uiState: NFTDetailUiState;
  uiEvent: NFTDetailUiEvent;
}

const NFTDetailGrid = ({ uiState, uiEvent }: NFTDetailGridProps) => {
  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75} sx={{ mt: -3 }}>
      <NFTInfoCard uiState={uiState} uiEvent={uiEvent} />
      {uiState.levelInfos.map((level, index) => (
        <NFTLevelCard
          key={index}
          level={index + 1}
          uiState={uiState}
          uiEvent={uiEvent}
        />
      ))}
    </Grid>
  );
};

export default NFTDetailGrid;
