import { Box, Button, Typography } from "@mui/material";
import CheckSharpIcon from "@mui/icons-material/CheckSharp";
import VerticalSpacer from "../../../component/Spacer";
import { MyRideUiState } from "../model/MyRideUiState";
import ScooterContractListItem from "../component/ScooterContractListItem";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import { MyRideUiEvent } from "../model/MyRideUiEvent";
import IconTextDialog, {
  IconTextDialogType,
} from "../../../component/dialog/IconTextDialog";
import { ROUTE_PATHS } from "../../../constants/RouteConstants";
import { useRef } from "react";

interface ScooterContractListScreenProps {
  uiState: MyRideUiState;
  uiEvent: MyRideUiEvent;
}

const ScooterContractListScreen = ({
  uiState,
  uiEvent,
}: ScooterContractListScreenProps) => {
  const contractItem = useRef<any>(null);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "white",
          width: "100vw",
          height: "100vh",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Box
          sx={{
            width: "calc(100% - 40px)",
            height: "auto",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <VerticalSpacer height={20} />
            <Typography fontSize={24} fontWeight={"bold"}>
              {LocalizedText("ua_e200_available_title").replace(
                "%s",
                uiState.scooterContractList.length.toString()
              )}
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Typography
                sx={{ display: "flex", alignItems: "flex-start", mb: 1 }}
              >
                <CheckSharpIcon
                  fontSize="small"
                  sx={{ color: "#5D38E5", mr: 0.5 }}
                />
                {LocalizedText("ua_e200_available_inform_message")}
              </Typography>
              <Typography
                sx={{ display: "flex", alignItems: "flex-start", mb: 1 }}
              >
                <CheckSharpIcon
                  fontSize="small"
                  sx={{ color: "#5D38E5", mr: 0.5 }}
                />
                {LocalizedText("ua_e200_available_register_message")}
              </Typography>
              <Box sx={{ display: "flex", alignItems: "flex-start", mb: 1 }}>
                <CheckSharpIcon
                  fontSize="small"
                  sx={{ color: "#5D38E5", mr: 0.5 }}
                />
                {LocalizedText("ua_e200_available_idcard_message")}
              </Box>
            </Box>
            <Box sx={{ mt: 3 }}>
              {/* 첫번째 요소 */}
              {uiState.scooterContractList.map((data: any, index: any) => {
                return (
                  <Box key={index}>
                    <ScooterContractListItem
                      contractListItem={data}
                      uiEvent={uiEvent}
                      uiState={uiState}
                      onClick={(contractListItem) => {
                        uiEvent.onClickPickUp(contractListItem);
                        contractItem.current = contractListItem;
                      }}
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
        <IconTextDialog
          title={LocalizedText("ua_e200_alert_additionalinfo")}
          content={LocalizedText("ua_e200_alert_aditionalinfo_message")}
          type={IconTextDialogType.WARNING}
          positiveText={LocalizedText("common_alert_confirm_button")}
          isOpen={uiState.isShownUserInfoEnterDialog}
          onPositive={() => {
            uiEvent.goToAdditionalInfoPage(contractItem.current);
          }}
          onClose={() => {
            uiEvent.closeUserInfoEnterDialog();
          }}
        />
      </Box>
    </>
  );
};

export default ScooterContractListScreen;
