import { useEffect, useState } from "react";
import CommonSearch from "../../../../../component/CommonSearch";
import { Box } from "@mui/material";
import { CommonSelect } from "../../../../../component/CommonSelect";
import VerticalSpacer, { HorizontalSpacer } from "../../../../../component/Spacer";
import { ReceiveDeliverTable } from "./ReceiveDeliverTable";
import { ReceiveDeliverData } from "./model/RecieveDeliverData";
import { HeadCell } from "../../../../../component/CommonTable";
import { ApiService } from "../../../../../restAPI/ApiService";

export function ReceiveDeliver() {
  // 페이지네이션 기능을 위한 변수
  const [rowsPerPage, setRowsPerPage] = useState(10); // 한 테이블당 보여줄 row 의 갯수
  const [rows, setRows] = useState<ReceiveDeliverData[]>([]); // 받아온 데이터
  const [page, setPage] = useState<number>(0); // 현재 페이지의 번호
  const [docCount, setDocCount] = useState<number>(0); // 총 row의 수
  const [pageCount, setPageCount] = useState(0); // 테이블 왼쪽 하단에 보여질 페이지의 갯수
  // 검색어 관련 변수
  const [searchContents, setSearchContents] = useState("");
  // status 관련 변수
  const [status, setStatus] = useState("");
  // 카테고리 관련 변수
  const [category, setCategory] = useState("");
  // quantities 관련 변수
  const [quantities, setQuantities] = useState("");

  useEffect(() => {
    ApiService.WebPost("/pw/aw/material/io/filter", {
      page: page + 1,
      size: rowsPerPage,
      searchContents: searchContents,
      isIncoming: status,
    }).then((res) => {
      console.log(res?.data);
      if (res?.data.body) {
        const IOData = res?.data;
        setRows(IOData.body);
        setPageCount(Math.ceil(IOData.docCount / rowsPerPage));
        setDocCount(IOData.docCount);
      }
    });
  }, [page, rowsPerPage, status, category, quantities]);

  return (
    <>
      <CommonSearch
        placeHolder={"Parts Code, Product Name, Spec, Company"}
        searchContents={searchContents}
        setSearchContents={setSearchContents}
        onKeyPress={() => {
          ApiService.WebPost("/pw/aw/material/io/filter", {
            page: page + 1,
            size: rowsPerPage,
            searchContents: searchContents,
            isIncoming: status,
          }).then((res) => {
            console.log(res?.data);
            if (res?.data.body) {
              const IOData = res?.data;
              setRows(IOData.body);
              setPageCount(Math.ceil(IOData.docCount / rowsPerPage));
              setDocCount(IOData.docCount);
            }
          });
        }}
      >
        <Box sx={{ display: "flex" }}>
          <CommonSelect
            sx={{
              width: "93px",
              height: "42px",
            }}
            menuitems={["All", "Receive", "Deliver"]}
            selectValue={status}
            onChange={(event) => {
              setStatus(event.target.value);
            }}
            placeholder="Status"
          />
          <HorizontalSpacer width={12} />
          <CommonSelect
            sx={{
              width: "108px",
              height: "42px",
            }}
            menuitems={["All", "AL", "ASSY", "ELECTRIC", "ETC", "HARNESS", "M/D", "P/D", "SEMI ASSY"]}
            selectValue={category}
            onChange={(event) => {
              setCategory(event.target.value);
            }}
            placeholder="Category"
          />
          <HorizontalSpacer width={12} />
          <CommonSelect
            sx={{
              width: "117px",
              height: "42px",
            }}
            menuitems={["All", "SET", "EA", "g", "m"]}
            selectValue={quantities}
            onChange={(event) => {
              setQuantities(event.target.value);
            }}
            placeholder="Quantities"
          />
        </Box>
      </CommonSearch>
      <VerticalSpacer height={16} />
      <ReceiveDeliverTable
        mngRows={rows}
        headCells={receiveDeliverHeadCells}
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </>
  );
}

const receiveDeliverHeadCells: HeadCell[] = [
  {
    disablePadding: false,
    align: "left",
    id: "no",
    label: "No",
  },
  {
    disablePadding: false,
    align: "left",
    id: "category",
    label: "Category",
  },
  {
    disablePadding: false,
    align: "left",
    id: "partsCode",
    label: "Parts Code",
  },
  {
    disablePadding: false,
    align: "left",
    id: "productName",
    label: "Product Name",
  },
  {
    disablePadding: false,
    align: "left",
    id: "spec",
    label: "Spec",
  },
  {
    disablePadding: false,
    align: "left",
    id: "company",
    label: "Company",
  },
  {
    disablePadding: false,
    align: "left",
    id: "quantities",
    label: "Quantities",
  },
  {
    disablePadding: false,
    align: "left",
    id: "branch",
    label: "Branch",
  },
  {
    disablePadding: false,
    align: "left",
    id: "amount",
    label: "Amount",
  },
  {
    disablePadding: false,
    align: "left",
    id: "status",
    label: "Status",
  },
  {
    disablePadding: false,
    align: "left",
    id: "registerDate",
    label: "Register Date",
  },
];
