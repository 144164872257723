import { Box, CardContent, Typography } from "@mui/material";
import LocalizedDate, { DateFormatType } from "../../../../../../../component/LocalizedDate";
import { fontStyle } from "../../../../../../../theme/Style";
import { color } from "../../../../../../../theme/Color";
import VerticalSpacer, { HorizontalSpacer } from "../../../../../../../component/Spacer";
import { CommonSelect } from "../../../../../../../component/CommonSelect";
import { useState } from "react";
import CommonTextField from "../../../../../../../component/CommonTextField";
import { ProductUiState } from "../model/ProductUiState";
import { ProductUiEvent } from "../model/ProductUiEvent";

interface ProductionDetailsDateCardPossibleProps {
  uiState: ProductUiState;
  uiEvent: ProductUiEvent;
}

export function ProductionDetailsDateCardPossible({ uiState, uiEvent }: ProductionDetailsDateCardPossibleProps) {
  console.log(uiState.RegistrationDate);
  return (
    <CardContent
      sx={{
        padding: "0px",
        "&:last-child": {
          paddingBottom: 0,
        },
      }}
    >
      <Box sx={{ display: "flex" }}>
        <Box sx={{ display: "flex", flexDirection: "column" }} flex={1}>
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Regist Date
          </Typography>
          <VerticalSpacer height={8} />
          <CommonTextField
            value={LocalizedDate({ date: uiState.RegistrationDate, type: DateFormatType.AdminType })}
            inputProps={{ style: { padding: "14px 16px", height: "20px", zIndex: 100 } }}
            disabled
          />
        </Box>
        <HorizontalSpacer width={24} />
        <Box flex={1}>
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Confirm Date
          </Typography>
          <VerticalSpacer height={8} />
          <Box display="flex" flex={1}>
            {uiState.ConfirmDate ? (
              <CommonTextField
                sx={{ width: "100%" }}
                inputProps={{ style: { padding: "14px 16px", height: "20px", zIndex: 100 } }}
                value={LocalizedDate({ date: uiState.ConfirmDate, type: DateFormatType.AdminType })}
                disabled
              />
            ) : (
              <CommonSelect
                sx={{
                  minHeight: "48px",
                  flexGrow: 1,
                }}
                selectValue={uiState.Confirm}
                placeholder="Select"
                onChange={(event) => {
                  uiEvent.HandleUiStateChange("Confirm", event.target.value);
                }}
                menuitems={["Reject", "Confirm"]}
                disabled={uiState.AdminRole != "Lead"}
              />
            )}
          </Box>
        </Box>
        <HorizontalSpacer width={24} />
        <Box sx={{ display: "flex", flexDirection: "column" }} flex={1}>
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Last Update Date
          </Typography>
          <VerticalSpacer height={8} />
          <CommonTextField
            inputProps={{ style: { padding: "14px 16px", height: "20px", zIndex: 100 } }}
            value={LocalizedDate({ date: uiState.RegistrationDate, type: DateFormatType.AdminType })}
            disabled
          />
        </Box>
      </Box>
    </CardContent>
  );
}
