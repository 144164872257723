import { Box, Card, CardContent, Typography } from "@mui/material";
import { color } from "../../../../../theme/Color";
import { fontStyle } from "../../../../../theme/Style";
import VerticalSpacer, {
  HorizontalSpacer,
} from "../../../../../component/Spacer";
import CommonTextField from "../../../../../component/CommonTextField";
import { ICON_ADDIMAGE } from "../../../../../constants/imagePath";
import { ProductNameRegistrationUiState } from "./model/ProductNameRegistrationUiState";
import { ProductNameRegistrationUiEvent } from "./model/ProductNameRegistrationUiEvent";

interface ProductCardProps {
  uiState: ProductNameRegistrationUiState;
  uiEvent: ProductNameRegistrationUiEvent;
}

export function ProductCard({ uiState, uiEvent }: ProductCardProps) {
  return (
    <Card
      sx={{
        padding: "0px",
        border: "1px solid",
        borderColor: color.gray300,
        borderRadius: "15px",
        boxShadow: "unset",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          "&:last-child": {
            paddingBottom: "20px",
          },
        }}
      >
        <Typography fontStyle={fontStyle.semiboldL}>Product</Typography>
        <VerticalSpacer height={16} />
        <Box sx={{ display: "flex" }}>
          <Box sx={{ width: "50%" }}>
            <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
              Model Name
            </Typography>
            <VerticalSpacer height={8} />
            <CommonTextField
              sx={{ width: "100%" }}
              value={uiState.modelName}
              onChange={(event) => {
                uiEvent.HandleUiStateChange("modelName", event.target.value);
              }}
              disabled={uiState.ProductId === ""}
            />
            <VerticalSpacer height={40} />
            <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
              Product Name
            </Typography>
            <VerticalSpacer height={8} />
            <Box
              sx={{
                width: "100%",
                height: "48px",
                padding: "12px 16px",
                borderRadius: "10px",
                border: "1px solid",
                borderColor: color.gray300,
                backgroundColor: color.gray200,
              }}
            >
              <Typography fontStyle={fontStyle.regularS} color={color.gray600}>
                {uiState.ProductName}
              </Typography>
            </Box>
            <VerticalSpacer height={40} />
            <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
              Parts Code
            </Typography>
            <VerticalSpacer height={8} />
            <Box
              sx={{
                width: "100%",
                height: "48px",
                padding: "12px 16px",
                borderRadius: "10px",
                border: "1px solid",
                borderColor: color.gray300,
                backgroundColor: color.gray200,
              }}
            >
              <Typography fontStyle={fontStyle.regularS} color={color.gray600}>
                {uiState.PartsCode}
              </Typography>
            </Box>
            <VerticalSpacer height={40} />
          </Box>
          <HorizontalSpacer width={28} />
          <Box sx={{ width: "50%" }}>
            <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
              Image
            </Typography>
            <VerticalSpacer height={8} />
            {uiState.image === "" ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: color.gray200,
                  borderRadius: "10px",
                  width: "100%",
                  height: "324px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  component="img"
                  src={ICON_ADDIMAGE}
                  sx={{
                    width: "40px", // 작은 아이콘 크기
                    height: "40px",
                  }}
                />
                <Box>
                  <VerticalSpacer height={16} />
                  <Typography
                    fontStyle={fontStyle.semiboldM}
                    color={color.gray600}
                  >
                    No Image
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Box
                component="img"
                src={uiState.image}
                sx={{
                  borderRadius: "10px",
                  width: "100%",
                  height: "324px",
                }}
              ></Box>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
