import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Dialog, DialogContent, DialogContentText, IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CommonList from "../../component/CommonList";
import { ApiService } from "../../restAPI/ApiService";
import FAQDetailGrid from "./section/FAQDetailGrid";
import { ICON_TRASH } from "../../constants/imagePath";
import { color } from "../../theme/Color";
import { ADMIN_CONFIG } from "../../constants/AdminConfig";

const FAQDetailsPage = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [faqDetailsData, setFaqDetailsData] = useState<any>();
  const location = useLocation();
  const pathname = location.pathname;
  const FAQId = pathname.split("/").pop(); // URL에서 마지막 부분을 추출
  console.log(FAQId);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOk = () => {
    navigate("/FAQMng");
    window.location.reload();
  };

  useEffect(() => {
    ApiService.WebPost(`/pw/aw/contents/faq/detail`, {
      id: FAQId,
    }).then((res) => {
      setFaqDetailsData(res?.data.body);
    });
  }, [FAQId]);

  const handleDelete = () => {
    ApiService.WebPost(`/pw/aw/contents/faq/delete`, {
      id: FAQId,
    }).then((res) => {
      if (res?.data.message == "delete complete") {
        navigate("/FAQMng");
        window.location.reload();
      }
    });
  };

  const HeaderButton = () => {
    return (
      <Box sx={{ display: "flex", flexDirection: "row", gap: "8px" }}>
        <Button
          sx={{
            height: 40,
            borderRadius: 5,
            px: "12px",
            textTransform: "none",
            backgroundColor: color.white,
            fontStyle: color.error,
            color: color.error,
            ":hover": {
              backgroundColor: color.red01,
            },
          }}
          onClick={handleDelete}
        >
          <img src={ICON_TRASH}></img>Delete
        </Button>
        <Button
          sx={{
            height: 40,
            backgroundColor: "#5D38E5",
            color: "white",
            borderRadius: 5,
            width: 85,
            ":hover": {
              backgroundColor: "#A38BFC",
            },
            textTransform: "none",
          }}
          onClick={() => {
            setEditDialogOpen(true);
          }}
        >
          Update
        </Button>
      </Box>
    );
  };
  const HeaderBackButton = () => {
    return (
      <IconButton
        sx={{
          ":hover": { backgroundColor: "#F2F2F2" },
          mb: 0.5,
          mr: 1,
        }}
        onClick={() => {
          handleClickOpen();
        }}
      >
        <ArrowBackIosNewIcon fontSize="medium" />
      </IconButton>
    );
  };
  return (
    <CommonList title="FAQ Details" headerButton={HeaderButton()} headerBackButton={HeaderBackButton()}>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent
          sx={{
            width: 450,
            height: 170,
            // mt: -1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              fontSize: 15,
              color: "black",
            }}
          >
            {ADMIN_CONFIG.MSG.EDIT_STOP_AND_MOVE}<br/>
            {ADMIN_CONFIG.MSG.EDIT_STOP_NOT_SAVED}
          </DialogContentText>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              mt: 1.5,
            }}
          >
            <Button
              onClick={handleClose}
              sx={{
                textTransform: "none",
                height: 40,
                //   backgroundColor: "#5D38E5",
                color: "#5D38E5",
                borderRadius: 5,
                border: 1,
                borderColor: "#5D38E5",
                width: 95,
                ":hover": {
                  backgroundColor: "#E9E9E9",
                },
              }}
            >
              Cancle
            </Button>
            <Button
              onClick={handleOk}
              sx={{
                textTransform: "none",
                height: 40,
                backgroundColor: "#5D38E5",
                color: "white",
                borderRadius: 5,
                width: 75,
                ml: 2,
                ":hover": {
                  backgroundColor: "#A38BFC",
                },
              }}
              autoFocus
            >
              OK
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <FAQDetailGrid faqDetailsData={faqDetailsData} editDialogOpen={editDialogOpen} setEditDialogOpen={setEditDialogOpen} />
    </CommonList>
  );
};

export default FAQDetailsPage;
