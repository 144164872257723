import { Box, Card, CardContent, Typography } from "@mui/material";
import { color } from "../../../../../theme/Color";
import { fontStyle } from "../../../../../theme/Style";
import VerticalSpacer from "../../../../../component/Spacer";
import { UserNFCUiState } from "../model/UserNFCUiState";
import LocalizedDate, {
  DateFormatType,
} from "../../../../../component/LocalizedDate";

interface UserNFCDetailTagLogCardProps {
  uiState: UserNFCUiState;
}

export function UserNFCDetailTagLogCard({
  uiState,
}: UserNFCDetailTagLogCardProps) {
  return (
    <Card
      sx={{
        width: "100%",
        padding: "0px",
        border: "1px solid",
        borderColor: color.gray300,
        borderRadius: "15px",
        boxShadow: "unset",
      }}
    >
      <CardContent sx={{ padding: "24px" }}>
        <Typography fontStyle={fontStyle.semiboldL}>Tag Log</Typography>
        <VerticalSpacer height={16} />
        <Box
          sx={{
            display: "flex",
            borderBottom: "0.8px solid",
            borderColor: color.gray300,
            height: "34px",
          }}
        >
          <Typography
            fontStyle={fontStyle.boldXS}
            color={color.gray600}
            sx={{ px: "8px", py: "4px", width: "198px" }}
          >
            Station
          </Typography>
          <Typography
            fontStyle={fontStyle.boldXS}
            color={color.gray600}
            sx={{ px: "8px", py: "4px", width: "88px" }}
          >
            Status
          </Typography>
          <Typography
            fontStyle={fontStyle.boldXS}
            color={color.gray600}
            sx={{ px: "8px", py: "4px", width: "132px" }}
          >
            Tag Date
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {uiState.tagLog.map((value, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                px: "8px",
                py: "4px",
                height: "34px",
                alignItems: "center",
              }}
            >
              <Typography
                fontStyle={fontStyle.regularXS}
                color={color.gray800}
                sx={{ width: "198px" }}
              >
                {value.station}
              </Typography>
              <Box
                sx={{
                  width: "7.5px",
                  height: "7.5px",
                  borderRadius: "50%",
                  backgroundColor:
                    value.status === "Success"
                      ? color.successComplete
                      : value.status === "Failed"
                        ? color.error
                        : "",
                  marginRight: "9.25px",
                }}
              />
              <Typography
                fontStyle={fontStyle.regularXS}
                color={color.gray800}
                sx={{ width: "88px" }}
              >
                {value.status}
              </Typography>
              <Typography
                fontStyle={fontStyle.regularXS}
                color={color.gray800}
                sx={{ width: "132px" }}
              >
                {LocalizedDate({
                  date: value.tagDate,
                  type: DateFormatType.numericTime,
                })}
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
}
