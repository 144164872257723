import { Box, Typography, Button } from "@mui/material";
import CommonCardDialog from "../../../component/dialog/CommonCardDialog";
import { fontStyle } from "../../../theme/Style";
import VerticalSpacer from "../../../component/Spacer";
import { color } from "../../../theme/Color";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";

interface QrCardDialogProps {
  isOpen: boolean;
  image: string;
  title: string;
  description: string;
  onClick: () => void;
}

const QrCardDialog = ({
  isOpen,
  image,
  title,
  description,
  onClick,
}: QrCardDialogProps) => {
  return (
    <CommonCardDialog
      isOpen={isOpen}
      onClose={() => {}}
      onPositive={() => {}}
      children={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box component="img" src={image} sx={{ width: "78.5%" }} />
          <VerticalSpacer height={20} />
          <Box
            sx={{
              width: "100%",
              textAlign: "center",
            }}
          >
            <Typography fontStyle={fontStyle.titleL} color={color.default}>
              {title}
            </Typography>
            <VerticalSpacer height={4} />
            <Typography fontStyle={fontStyle.bodyMr} color={color.gray800}>
              {description}
            </Typography>
            <VerticalSpacer height={8} />
          </Box>
        </Box>
      }
      button={
        <Button
          sx={{
            width: "100%",
            height: "49px",
            borderRadius: "10px",
            backgroundColor: color.primary500,
            textTransform: "none",
            ":hover": {
              backgroundColor: color.primary500,
            },
            zIndex: 1200,
            color: "white",
          }}
          onClick={onClick}
        >
          {LocalizedText("common_alert_confirm_button")}
        </Button>
      }
      sx={{ width: "71%", borderRadius: "10px" }}
    />
  );
};

export default QrCardDialog;
