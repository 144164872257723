import { Box } from "@mui/material";
import Toolbar from "../../component/Toolbar";
import { GuideCard } from "./GuideCard";
import {
  IMAGE_BATTERY_STATION_USAGE_GUIDE_01,
  IMAGE_BATTERY_STATION_USAGE_GUIDE_02,
  IMAGE_BATTERY_STATION_USAGE_GUIDE_03,
  IMAGE_BATTERY_STATION_USAGE_GUIDE_04,
  IMAGE_BATTERY_STATION_USAGE_GUIDE_05,
  IMAGE_BATTERY_STATION_USAGE_GUIDE_06,
} from "../../constants/appImagePath";
import VerticalSpacer from "../../component/Spacer";

export function BatteryStationUsageGuidePage() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100vw",
        height: "100vh",
      }}
    >
      <Toolbar
        // title={LocalizedText("ua_f310_cs")}
        title={"Battery Station Usage Guide 언어팩 적용안됨"}
        onBack={() => {
          window.history.back();
        }}
      />
      <Box
        sx={{
          flex: 1, // 나머지 공간을 차지하도록 설정
          width: "100%",
          overflowY: "auto", // 세로 스크롤 가능
          boxSizing: "border-box",
          px: "20px",
          py: "28px",
        }}
      >
        <GuideCard
          title={"1. POPLE 스테이션에 도착합니다."}
          content={
            "POPLE World 앱의 Map에서 내 위치에 가까운 스테이션을 찾아보세요."
          }
          image={IMAGE_BATTERY_STATION_USAGE_GUIDE_01}
        />
        <VerticalSpacer height={80} />
        <GuideCard
          title={"2. 스쿠터 시동을 끄고 배터리팩을 꺼냅니다."}
          content={
            "스쿠터의 트렁크를 열어 안전에 유의하여 배터리팩을 하나씩 꺼내주세요."
          }
          image={IMAGE_BATTERY_STATION_USAGE_GUIDE_02}
        />
        <VerticalSpacer height={80} />
        <GuideCard
          title={
            "3. 팝플월드 앱으로 QR 스캔을 하거나 스쿠터와 함께 받으신 NFC를 태깅하여 사용자 인증을 진행해주세요."
          }
          content={
            "POPLE World 앱의 Map에서 QR 스캔 버튼을 눌러 스테이션에 보이는 QR 코드를 스캔합니다. 스테이션의 네트워크가 잘 되지않을 때에는 NFC 카드를 이용하여 태깅해 주세요. "
          }
          image={IMAGE_BATTERY_STATION_USAGE_GUIDE_03}
        />
        <VerticalSpacer height={80} />
        <GuideCard
          title={"4. 화면의 안내에 따라 배터리 반납을 진행하세요."}
          content={
            "스테이션 화면에서 안내하는 빈 슬롯에 사용한 배터리팩을 넣어주세요."
          }
          image={IMAGE_BATTERY_STATION_USAGE_GUIDE_04}
        />
        <VerticalSpacer height={80} />
        <GuideCard
          title={"5. 화면의 안내에 따라 배터리 대여를 진행하세요."}
          content={
            "파란색 불이 깜빡거리는 슬롯의 배터리팩을 꺼내주세요. 완충된 배터리는 파란색, 충전중인 배터리는 빨간색입니다."
          }
          image={IMAGE_BATTERY_STATION_USAGE_GUIDE_05}
        />
        <VerticalSpacer height={80} />
        <GuideCard
          title={"6. 새 배터리팩을 장착하세요."}
          content={"새로 대여한 배터리팩을 스쿠터에 장착해주세요."}
          image={IMAGE_BATTERY_STATION_USAGE_GUIDE_06}
        />
      </Box>
    </Box>
  );
}
