import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SearchIcon from "@mui/icons-material/Search";
import { ReactNode } from "react";
import styled from "styled-components";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import {
  FormControlLabel,
  FormGroup,
  TextField,
  Select,
  MenuItem,
  Typography,
  Box,
  Grid,
  Checkbox,
  InputAdornment,
  FormHelperText,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material";
import React, { ChangeEvent, useEffect, SyntheticEvent, useState } from "react";
import { useParams } from "react-router-dom";
import { ArgumentDetailFormData } from "../ParamDetailsPage";
import { Methodology } from "../../../../context/MethodologyContext";
import { ParamType, PurposeEnum } from "../../../../store/paramType";

interface ParamChangeProps {
  formData: ArgumentDetailFormData;
  setFormData: React.Dispatch<React.SetStateAction<ArgumentDetailFormData>>;
  methodologies: Methodology[];
  onHandleMethodologyChange: (event: SelectChangeEvent<number>) => void;
  errors: { [key: string]: string };
}
const CustomTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
  minHeight: "40px",
  padding: "12px 16px",
  borderRadius: "10px",
  border: "1px solid transparent",
  opacity: 1,
  marginLeft: "10px",
}));
interface Country {
  translations: {
    kor: { common: string };
  };
}
interface ValueListItem {
  id: number;
  item: string;
  value: number;
}
interface CustomTypographyProps {
  children: ReactNode;
  sx?: object;
}
const ParamChange: React.FC<ParamChangeProps> = ({
  formData,
  setFormData,
  methodologies,
  onHandleMethodologyChange,
  errors,
}) => {
  const [valueList, setValueList] = useState([
    { id: Date.now(), item: "", value: "" },
  ]);
  const [fileName, setFileName] = useState<string>("");
  const handleInputChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (
    name: string,
    value: string,
    checked: boolean
  ) => {
    setFormData((prev) => {
      const prevList = (prev?.purpose || []) as PurposeEnum[];

      const newValue = prevList?.includes(value as PurposeEnum)
        ? prevList.filter((v) => v !== value)
        : [...prevList, value];
      return { ...prev, [name]: newValue };
    });
  };

  const handleSelectChange = (event: SelectChangeEvent<ParamType>) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setFileName(file.name);
      console.log("FILE NAME: ", file.name);
      setFormData((prevData) => ({
        ...prevData,
        relatedDocumentFile: file,
      }));
    }
  };
  useEffect(() => {
    if (formData.relatedDocumentFile) {
      if (typeof formData.relatedDocumentFile === "string") {
        const fileNameFromUrl =
          formData.relatedDocumentFile.split("/").pop() || "Unknown File";
        setFileName(fileNameFromUrl);
      } else {
        setFileName(formData.relatedDocumentFile.name);
      }
    }
  }, [formData.relatedDocumentFile]);

  const [countries, setCountries] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://restcountries.com/v3.1/all");
        const data = await response.json();

        const countryNames = data.map(
          (country: Country) => country.translations.kor.common
        );
        setCountries(countryNames);
      } catch (error) {
        console.log("Error fetching countries", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCountries();
  }, []);
  const handleUploadClick = () => {
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  };
  const filteredCountries = countries.filter((country) =>
    country.includes(searchTerm)
  );
  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const CustomTypography: React.FC<CustomTypographyProps> = ({
    children,
    ...props
  }) => {
    return (
      <Typography
        {...props}
        sx={{
          color: "#99989D",
          ...props.sx,
          lineHeight: "18px",
          fontSize: "13px",
          fontWeight: 700,
          width: "80px",
        }}
      >
        {children}
      </Typography>
    );
  };

  return (
    <Grid container spacing={6}>
      <Grid item xs={6}>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 5,
            }}
          >
            <CustomTypography sx={{ fontWeight: "bold", mt: 2.5 }}>
              데이터/인자
            </CustomTypography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "column",
              }}
            >
              <CustomTextField
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                    height: "40px",
                    width: "390px",
                    display: "flex",
                    marginLeft: 0,
                  },
                }}
                error={!!errors.name}
              />
              <FormHelperText
                sx={{
                  color: "red",
                  mt: "-10px",
                  ml: 4,
                  fontSize: "12px",
                }}
              >
                {errors.name}
              </FormHelperText>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <CustomTypography sx={{ fontWeight: "bold", fontSize: 16, mt: 2 }}>
              단위
            </CustomTypography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "column",
              }}
            >
              <CustomTextField
                name="unit"
                value={formData.unit || ""}
                onChange={handleInputChange}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                    height: "40px",
                    width: "390px",
                    display: "flex",
                    marginLeft: 0,
                  },
                }}
                error={!!errors.unit}
              />
              <FormHelperText
                sx={{
                  color: "red",
                  mt: "-10px",
                  ml: 4,
                  fontSize: "12px",
                }}
              >
                {errors.unit}
              </FormHelperText>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <CustomTypography sx={{ fontWeight: "bold", fontSize: 16, mt: 2 }}>
              정의
            </CustomTypography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "column",
              }}
            >
              <CustomTextField
                name="definition"
                value={formData.definition}
                onChange={handleInputChange}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                    height: "40px",
                    width: "390px",
                    display: "flex",
                    marginLeft: 0,
                  },
                }}
                error={!!errors.definition}
              />
              <FormHelperText
                sx={{
                  color: "red",
                  mt: "-10px",
                  ml: 4,
                  fontSize: "12px",
                }}
              >
                {errors.definition}
              </FormHelperText>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <CustomTypography sx={{ fontWeight: "bold", mt: 1 }}>
              데이터 목적
            </CustomTypography>
            <FormGroup row sx={{ width: "80%" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.purpose?.includes(PurposeEnum.BASELINE)}
                    name="BASELINE"
                    onChange={(
                      event: SyntheticEvent<Element, Event>,
                      checked: boolean
                    ) => handleCheckboxChange("purpose", "BASELINE", checked)}
                  />
                }
                label={
                  <Typography sx={{ fontSize: "14px" }}>베이스라인</Typography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.purpose?.includes(PurposeEnum.PROJECT)}
                    name="PROJECT"
                    onChange={(
                      event: SyntheticEvent<Element, Event>,
                      checked: boolean
                    ) => handleCheckboxChange("purpose", "PROJECT", checked)}
                  />
                }
                label={
                  <Typography sx={{ fontSize: "14px" }}>프로젝트</Typography>
                }
              />
              <FormHelperText
                sx={{
                  color: "red",
                  mt: 1,
                  ml: 1,
                  fontSize: "12px",
                }}
              >
                {errors.purpose}
              </FormHelperText>
            </FormGroup>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <CustomTypography sx={{ fontWeight: "bold", fontSize: 16, mt: 2 }}>
              데이터 출처
            </CustomTypography>
            <CustomTextField
              name="output"
              value={formData.output}
              onChange={(e) =>
                setFormData({ ...formData, output: e.target.value })
              }
              InputProps={{
                style: {
                  borderRadius: "10px",
                  height: "40px",
                },
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <CustomTypography sx={{ fontWeight: "bold", fontSize: 16, mt: 2 }}>
              측정 절차
            </CustomTypography>
            <CustomTextField
              name="measurementProcedure"
              value={formData.measurementProcedure}
              onChange={handleInputChange}
              multiline
              minRows={4}
              maxRows={10}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  resize: "vertical",
                },
              }}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <CustomTypography sx={{ fontWeight: "bold", mt: 5 }}>
            방법론명
          </CustomTypography>
          <Select
            name="methodologyId"
            value={formData.methodologyId}
            onChange={onHandleMethodologyChange}
            IconComponent={(props) => (
              <KeyboardArrowDown {...props} sx={{ fontSize: "16px" }} />
            )}
            sx={{
              marginRight: "15px",
              width: "392px",

              opacity: 1,
              borderRadius: "10px",
              mt: 4.2,
              gap: "2px",
              backgroundColor: "#ffff",
              "&:hover": {
                borderColor: "#F9F6FD",
              },
            }}
            style={{ borderRadius: "10px", height: "40px" }}
            renderValue={(val) => {
              const selected =
                methodologies.find((e: any) => e.id === val) || null;
              return selected ? `${selected?.title}` : `방법론 선택`;
            }}
          >
            {methodologies.length > 0 ? (
              methodologies.map((methodology) => (
                <MenuItem key={methodology.id} value={methodology.id}>
                  {methodology.title}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="" disabled>
                조회 데이터가 없습니다.
              </MenuItem>
            )}
          </Select>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 4,
          }}
        >
          <CustomTypography sx={{ fontWeight: "bold", mt: 2 }}>
            모니터링 주기
          </CustomTypography>
          <CustomTextField
            name="monitoringWeeks"
            value={formData.monitoringWeeks}
            onChange={(e) =>
              setFormData({ ...formData, monitoringWeeks: e.target.value })
            }
            InputProps={{
              style: {
                borderRadius: "10px",
                height: "40px",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <CustomTypography sx={{ fontWeight: "bold", mt: 2 }}>
            QA/QA 절차
          </CustomTypography>
          <CustomTextField
            name="qaqcProcedure"
            value={formData.qaqcProcedure}
            onChange={handleInputChange}
            InputProps={{
              style: {
                borderRadius: "10px",
                height: "40px",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <CustomTypography sx={{ fontWeight: "bold", mt: 3 }}>
            버전
          </CustomTypography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
            }}
          >
            <CustomTextField
              name="version"
              value={formData.version}
              onChange={handleInputChange}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  height: "40px",
                  width: "390px",
                  display: "flex",
                  marginLeft: 0,
                },
              }}
              error={!!errors.version}
            />
            <FormHelperText
              sx={{
                color: "red",
                mt: "-10px",
                ml: 4,
                fontSize: "12px",
              }}
            >
              {errors.version}
            </FormHelperText>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <CustomTypography sx={{ fontWeight: "bold", mt: 3 }}>
            지역
          </CustomTypography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
            }}
          >
            <Select
              name="region"
              onChange={handleSelectChange}
              IconComponent={(props) => (
                <KeyboardArrowDown {...props} sx={{ fontSize: "16px" }} />
              )}
              error={!!errors.region}
              sx={{
                marginRight: "15px",
                width: "392px",
                mt: 2,
                opacity: 1,
                borderRadius: "10px",

                gap: "2px",
                backgroundColor: "#ffff",
                "&:hover": {
                  borderColor: "#F9F6FD",
                },
              }}
              value={formData.region as "" | undefined}
              style={{ borderRadius: "10px", height: "40px" }}
              renderValue={(val) => val || "선택하게요"}
            >
              <CustomTextField
                placeholder="검색..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                    height: "40px",
                    width: "380px",
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {filteredCountries.map((country, index) => (
                <MenuItem key={index} value={country}>
                  {country}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText
              sx={{
                color: "red",
                ml: 1,
                fontSize: "12px",
              }}
            >
              {errors.region}
            </FormHelperText>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <CustomTypography sx={{ fontWeight: "bold", mt: 4 }}>
            기타 의견
          </CustomTypography>
          <CustomTextField
            name="otherComments"
            value={formData.otherComments}
            onChange={handleInputChange}
            multiline
            minRows={4}
            maxRows={10}
            InputProps={{
              style: {
                borderRadius: "10px",
                resize: "vertical",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <CustomTypography sx={{ fontWeight: "bold", mt: 2 }}>
            관련 문서
          </CustomTypography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              position: "relative",
              width: "540px",
              marginRight: "10px",
            }}
          >
            <CustomTextField
              className="methodologyFile"
              variant="outlined"
              value={fileName}
              onChange={handleTextFieldChange}
              sx={{
                width: "140px",
                "& .MuiInputBase-root": {
                  borderRadius: 2,
                  height: "45px",
                },
              }}
              aria-readonly
            />
            <Typography
              component="span"
              onClick={handleUploadClick}
              sx={{
                position: "absolute",
                top: "-10px",
                marginRight: "10px",
                right: "0",
                fontWeight: "bold",
                fontSize: "12px",
                textDecoration: "underline",
                cursor: "pointer",
                "&:hover": {
                  color: "primary.dark",
                },
              }}
            >
              Upload File
            </Typography>
            <input
              type="file"
              id="fileInput"
              name="uploadedFile"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ParamChange;
