import { Box, Typography } from "@mui/material";
import { color } from "../../../theme/Color";
import { fontStyle } from "../../../theme/Style";

interface BatteryPercentageIconProps {
  percentage: number;
  width?: number;
  height?: number;
}

const BatteryPercentageIcon = ({
  percentage,
  width = 126,
  height = 158,
}: BatteryPercentageIconProps) => {
  const getBackgroundColor = (percentage: number) => {
    if (percentage < 30) {
      return color.backgroundError;
    } else if (percentage < 70) {
      return color.backgroundWarning;
    } else {
      return color.backgroundSuccess;
    }
  };

  const getTextColor = (percentage: number) => {
    if (percentage < 30) {
      return color.error;
    } else if (percentage < 70) {
      return color.warning;
    } else {
      return color.success;
    }
  };

  return (
    <Box sx={{ position: "relative", width, height }}>
      {/* 배터리 본체 */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          height: "95%",
          border: `2px solid ${color.gray300}`,
          borderRadius: "10px",
          p: "6px",
          boxSizing: "border-box",
          overflow: "hidden",
          transform: "scaleY(-1)",
        }}
      >
        {/* 배터리 잔량 텍스트 표시 */}
        <Typography
          fontStyle={fontStyle.subTitleXXL}
          color={getTextColor(percentage)}
          sx={{
            zIndex: 1,
            transform: "scaleY(-1)",
          }}
        >
          {percentage}%
        </Typography>

        {/* 배터리 잔량 표시 배경 */}
        <Box
          sx={{
            position: "absolute",
            top: "4px",
            bottom: "4px",
            left: "4px",
            right: "4px",
            width: "auto",
            height: "auto",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: percentage > 100 ? "100%" : `${percentage}%`,
              bgcolor: getBackgroundColor(percentage),
              transition: "height 0.3s ease",
            }}
          />
        </Box>
      </Box>

      {/* 배터리 상단 돌출부 */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: "50%",
          transform: "translateX(-50%)",
          width: "35%",
          height: "5%",
          bgcolor: color.gray300,
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
        }}
      />
    </Box>
  );
};

export default BatteryPercentageIcon;
