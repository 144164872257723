export class UserNFCCreateUiState {
  nfcCard: NFCCard[];
  cardId: string;
  type: string;
  valid: string;
  reason: string;

  //validation
  errors = {
    cardIdError: false,
    reasonError: false,
  };
  constructor(
    nfcCard: NFCCard[] = [
      {
        cardid: "Card Test",
        type: "Type Test",
        valid: "Active",
        reason: "",
      },
      {
        cardid: "Card Test2",
        type: "Type Test2",
        valid: "Inactive",
        reason: "",
      },
    ],
    cardId: string = "",
    type: string = "",
    valid: string = "",
    reason: string = ""
  ) {
    this.nfcCard = nfcCard;
    this.cardId = cardId;
    this.type = type;
    this.valid = valid;
    this.reason = reason;
  }
}

interface NFCCard {
  cardid: string;
  type: string;
  valid: string;
  reason: string;
}
