import { Box, CardContent, Typography } from "@mui/material";

import { SetStateAction, useEffect, useState } from "react";

import { ProductConfirmationListTable } from "./ProductConfirmationListTable";
import { fontStyle } from "../../../../theme/Style";
import VerticalSpacer from "../../../../component/Spacer";
import CommonSearch from "../../../../component/CommonSearch";
import { color } from "../../../../theme/Color";
import { HeadCell } from "../../../../component/CommonTable";
import { ApiService } from "../../../../restAPI/ApiService";

import { FindInListUiState } from "./model/FindInListUiState";
import { FindInListUiEvent } from "./model/FindInListUiEvent";

interface ProductConfirmationListProps {
  uiState: FindInListUiState;
  uiEvent: FindInListUiEvent;
}

export function ProductConfirmationList({ uiState, uiEvent }: ProductConfirmationListProps) {
  const [searchContents, setSearchContents] = useState("");
  const [docCount, setDocCount] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(4);
  const [rows, setRows] = useState<any>([]);
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    ApiService.WebPost("/pw/aw/material/product/search", {
      searchContents: searchContents,
      page: page + 1,
    })
      .then((res) => {
        const data = res?.data;
        console.log(res?.data);
        if (data.body) {
          setPageCount(Math.ceil(data.docCount / rowsPerPage));
          setDocCount(data.docCount);
          setRows(data.body);
        }
      })
      .catch((e) => {});
  }, [page]);

  useEffect(() => {
    if (uiState.ProductId) {
      const partsCode = rows.filter((data: any) => data.id == uiState.ProductId)[0].partsCode;
      uiEvent.HandleUiStateChange("PartsCode", partsCode);
    }
  }, [uiState.ProductId]);

  return (
    <>
      <CardContent
        sx={{
          width: "100%",
          padding: "0px",
        }}
      >
        <Typography fontStyle={fontStyle.semiboldL}>Find in List</Typography>
        <VerticalSpacer height={16} />
      </CardContent>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <CommonSearch
          placeHolder="Code, Product Name, Spec, Manufacturer"
          placeholderSx={{ fontStyle: fontStyle.regularS }}
          searchContents={searchContents}
          setSearchContents={setSearchContents}
          sx={{
            width: "100%",
            borderRadius: "10px",
            border: "1px solid",
            borderColor: color.gray300,
            minHeight: "48px",
          }}
          onKeyPress={() => {
            ApiService.WebPost("/pw/aw/material/product/search", {
              searchContents: searchContents,
              page: page + 1,
            })
              .then((res) => {
                const data = res?.data;
                console.log(res?.data);
                uiEvent.HandleUiStateChange("ProductId", "");
                uiEvent.HandleUiStateChange("PartsCode", "");
                if (data.body) {
                  setPageCount(Math.ceil(data.docCount / rowsPerPage));
                  setDocCount(data.docCount);
                  setRows(data.body);
                }
              })
              .catch((e) => {});
          }}
        />
      </Box>
      <VerticalSpacer height={24} />
      <ProductConfirmationListTable
        headCells={productConfirmationHeadCells}
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        productList={rows}
        MaterialId={uiState.ProductId}
        uiState={uiState}
        uiEvent={uiEvent}
      />
    </>
  );
}

const productConfirmationHeadCells: HeadCell[] = [
  {
    disablePadding: false,
    align: "left",
    id: "radio",
    label: "",
  },
  {
    disablePadding: false,
    align: "left",
    id: "category",
    label: "Category",
  },
  {
    disablePadding: false,
    align: "left",
    id: "partsCode",
    label: "Parts Code",
  },
  {
    disablePadding: false,
    align: "left",
    id: "productName",
    label: "Product Name",
  },
  {
    disablePadding: false,
    align: "left",
    id: "spec",
    label: "Spec",
  },
];
