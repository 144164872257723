import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import NFTCardImage from "./NFTCardImage";
import { useNavigate } from "react-router-dom";
import { color } from "../../../../../../theme/Color";
import VerticalSpacer, {
  HorizontalSpacer,
} from "../../../../../../component/Spacer";
import { fontStyle } from "../../../../../../theme/Style";
import { LocalizedText } from "../../../../../../di/LanguageRepositoryProvider";
import { ApiService } from "../../../../../../restAPI/ApiService";
import useSWR from "swr";

const ColorData = [
  {
    grade: "Red",
    background: color.toolingRedBg,
    fontColor: color.toolingRed,
  },
  {
    grade: "Pink",
    background: color.toolingPink2Bg,
    fontColor: color.toolingPink02,
  },
  {
    grade: "Purple",
    background: color.toolingPrimaryBg,
    fontColor: color.toolingPrimary,
  },
  {
    grade: "Blue",
    background: color.toolingBlueBg,
    fontColor: color.toolingBlue,
  },
  {
    grade: "Mint",
    background: color.toolingMintBg,
    fontColor: color.toolingMint,
  },
];

// fetcher 함수 정의
const fetchCarbonReductionData = async (url: string, uuid: string) => {
  const requestBody = {
    uuid: localStorage.getItem("uuid"),
  };
  const response = await ApiService.post(url, requestBody);
  return response.data.body;
};

interface NFTCardAndInfoProps {
  NFTCharData: any;
  NFTCharDataLength?: number;
}

const NFTCardAndInfo = ({ NFTCharData }: NFTCardAndInfoProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();

  const { data: totalCarbonReduction, error: carbonReductionError } =
    useSWR<number>(
      `/pw/ua/carbonReduction/sumCarbonReductionByUuid`,
      fetchCarbonReductionData,
      {
        fallbackData: 0, // 기본값 0으로 설정
      },
    );

  // 현재 슬라이드의 NFTCharData의 grade 값을 기반으로 ColorData에서 배경 및 폰트 색상을 가져오기
  const currentNFTGrade = NFTCharData && NFTCharData[activeIndex]?.grade;
  const currentColorData = ColorData.find(
    (color) => color.grade === currentNFTGrade,
  );

  // 해당하는 컬러 정보가 없을 때 기본 색상 설정
  const backgroundGradient =
    NFTCharData &&
    `linear-gradient(to top, ${currentColorData?.background}, #FFFFFF)`;

  const swiperStyles: React.CSSProperties = {
    width: "100%",
    paddingBottom: 30,
    "--swiper-pagination-color": "#fff",
    "--swiper-pagination-fraction-color": "#fff",
    "--swiper-pagination-bottom": "0%",
  } as React.CSSProperties;

  return (
    <>
      <Box
        sx={{
          width: "100%",
          background: backgroundGradient,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          zIndex: 10,
          transition: "background 3s ease-in-out",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
          }}
        >
          <Swiper
            key={NFTCharData && NFTCharData.length} // NFTCharData 길이가 변경될 때 Swiper 재렌더링
            initialSlide={NFTCharData && NFTCharData.length - 1} // 마지막 요소로 초기 슬라이드 설정
            pagination={{
              dynamicBullets: false,
            }}
            slidesPerView={"auto"}
            spaceBetween={-5}
            centeredSlides={true}
            style={swiperStyles}
            onSlideChange={(swiper: any) => {
              setActiveIndex(swiper.activeIndex); // 슬라이드 변경 시 인덱스 업데이트
            }}
          >
            {NFTCharData &&
              NFTCharData?.map((data: any, index: any) => (
                <SwiperSlide
                  key={index}
                  style={{
                    background: "transparent",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "80vw",
                    transform:
                      activeIndex === index ? "scale(1)" : "scale(0.8)",
                    zIndex: activeIndex === index ? 100 : 0,
                    transition: "transform 0.3s ease-in-out",
                  }}
                >
                  <NFTCardImage
                    image={data.imageURI}
                    fontColor={currentColorData?.fontColor}
                    issuedAtDate={data.issuedAt}
                  />
                </SwiperSlide>
              ))}
          </Swiper>
        </Box>
        <VerticalSpacer height={2} />
        <Box sx={{ width: "calc(100% - 40px)" }}>
          <Typography fontStyle={fontStyle.titleL}>
            {LocalizedText("ua_g320_totalcarbon")}
          </Typography>
          <VerticalSpacer height={4} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              fontStyle={fontStyle.headingL}
              color={currentColorData?.fontColor || color.gray700}
              sx={{ display: "flex", alignItems: "center" }}
            >
              {totalCarbonReduction}
              <HorizontalSpacer width={4} />
              <Typography fontStyle={fontStyle.titleL} color={color.gray700}>
                kgCO₂-eq
              </Typography>
            </Typography>
            <Box
              sx={{
                width: "auto",
                height: "34px",
                px: "12px",
                bgcolor: "white",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                navigate("/NFTGradePage");
              }}
            >
              <Typography fontStyle={fontStyle.titleM} color={color.gray700}>
                {LocalizedText("ua_g320_levelinfo")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <VerticalSpacer height={32} />
      </Box>
    </>
  );
};

export default NFTCardAndInfo;
