import { Box, Typography } from "@mui/material";
import { fontStyle } from "../../theme/Style";
import { useNavigate } from "react-router-dom";
import { ServiceCountry } from "./model/ServiceCountry";
import { IMAGE_KHMER, IMAGE_KOREA, IMAGE_VIETNAM } from "../../constants/appImagePath";
import { useState } from "react";
import Toolbar from "../../component/Toolbar";
import VerticalSpacer from "../../component/Spacer";
import { color } from "../../theme/Color";
import { LocalizedText } from "../../di/LanguageRepositoryProvider";
import CountryButton from "./component/CountryButton";
import CommonButton from "../../component/CommonButton";
import SimpleDialog from "../../component/dialog/SimpleDialog";
import { COUNTRY_CODE_STORAGE_KEY, LOCALE_STORAGE_KEY } from "../../customHook/useLocale";
import { ROUTE_PATHS } from "../../constants/RouteConstants";
import useAndroidBackHandler from "../../customHook/useAndroidBackHandler";

const LoginServiceCountryPage = () => {
  const navigate = useNavigate();
  const [selectedCountry, setSelectedCountry] = useState<ServiceCountry | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const countries: ServiceCountry[] = [
    {
      name: "Cambodia",
      code: "KH",
      flagImg: IMAGE_KHMER,
    },
    {
      name: "South Korea",
      code: "KR",
      flagImg: IMAGE_KOREA,
    },
    {
      name: "Vietnam",
      code: "VN",
      flagImg: IMAGE_VIETNAM,
    },
  ];

  const handleCountrySelect = (country: ServiceCountry) => {
    setSelectedCountry(country);
    const storedLocale = localStorage.getItem(LOCALE_STORAGE_KEY);
    if (storedLocale) {
      const [languageCode, countryCode] = storedLocale.split("-");
      const newCountryCode = country.code;
      localStorage.setItem(COUNTRY_CODE_STORAGE_KEY, newCountryCode);
      // TODO: LOCALE 값은 핸드폰 세팅 고유값인지, 같이 바꿔줘야하는지 문의 필요
      localStorage.setItem(LOCALE_STORAGE_KEY, `${languageCode}-${newCountryCode}`);
      console.log(`COUNTRY CODE CHANGED ${countryCode} -> ${newCountryCode}`);
    }
  };

  useAndroidBackHandler(() => {
    if (!isDialogOpen) {
      setIsDialogOpen(true);
    } else if (isDialogOpen) {
      setIsDialogOpen(false);
    }
  }, [isDialogOpen]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100vw",
          height: "100vh",
          alignItems: "center",
        }}
      >
        <Toolbar
          title=""
          onBack={() => {
            setIsDialogOpen(true);
          }}
        />
        <VerticalSpacer height={12} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            px: "20px",
            boxSizing: "border-box",
          }}
        >
          <Typography fontStyle={fontStyle.headingS} color={color.default}>
            {LocalizedText("ua_b123_select_servicecountry")}
          </Typography>
          <VerticalSpacer height={8} />
          <Typography fontStyle={fontStyle.bodyLr} color={color.gray800}>
            {LocalizedText("ua_b123_select_servicecountry_message")}
          </Typography>
          <VerticalSpacer height={16} />

          {countries.map((country) => (
            <CountryButton
              key={country.code}
              title={country.name}
              image={country.flagImg}
              isSelected={selectedCountry?.code === country.code}
              onClick={() => handleCountrySelect(country)}
            />
          ))}
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: "0px",
            width: "100%",
            display: "flex",
          }}
        >
          <CommonButton
            title={LocalizedText("ua_b123_next_button")}
            textColor="white"
            onClick={() => {
              navigate(ROUTE_PATHS.PHONE_AUTH);
            }}
            sx={{
              mx: "20px",
              mb: "14px",
            }}
            disabled={!selectedCountry}
          />
        </Box>
        <SimpleDialog
          children={LocalizedText("common_alert_goprevious")}
          isOpen={isDialogOpen}
          positiveText={LocalizedText("common_alert_confirm_button")}
          nagativeText={LocalizedText("common_alert_cancel_button")}
          onPositive={() => {
            window.history.back();
          }}
          onNagative={() => {
            setIsDialogOpen(false);
          }}
          onClose={() => {
            setIsDialogOpen(false);
          }}
        />
      </Box>
    </>
  );
};

export default LoginServiceCountryPage;
