import { TabType } from "./model/TabType";
import { EcoPlatformWalletUiEvent } from "./model/EcoPlatformWalletUiEvent";
import { Wallet } from "./wallet/Wallet";
import CommonList from "../../component/CommonList";
import { CommonWebHeaderButton } from "../../component/CommonWebHeaderButton";
import { EcoPlatformWalletUiState } from "./model/EcoPlatformWalletUiState";
import { EcoPlatformWalletTab } from "./EcoPlatformWalletTab";
import { ICON_WALLET } from "../../constants/imagePath";
import { InfoTip } from "./InfoTip";
import { Box } from "@mui/material";
import { NFT } from "./nft/NFT";
interface EcoPlatformWalletPageProps {
  currentTab: TabType;
  uiEvent: EcoPlatformWalletUiEvent;
  uiState: EcoPlatformWalletUiState;
}

export function EcoPlatformWalletPage({
  currentTab,
  uiEvent,
  uiState,
}: EcoPlatformWalletPageProps) {
  return (
    <CommonList
      title="Eco-Platform Wallet"
      tabButton={
        <EcoPlatformWalletTab uiEvent={uiEvent} currentTab={currentTab} />
      }
      // TODO - Pople World 의 월렛 DID 정보 추가 필요요
      // headerButton={
      //   <Box sx={{ position: "relative" }}>
      //     <CommonWebHeaderButton
      //       onClick={uiEvent.addressButtonClick}
      //       buttonContent="Eco-Platform Address"
      //       icon={<img src={ICON_WALLET}></img>}
      //     />
      //     {uiState.infoTipOpen === true && (
      //       <InfoTip uiEvent={uiEvent} uiState={uiState} />
      //     )}
      //   </Box>
      // }
    >
      {currentTab === TabType.WALLET && <Wallet />}
      {currentTab === TabType.NFT && <NFT />}
    </CommonList>
  );
}
