import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import RegionMngCreateGrid from "./RegionMngCreateGrid";
import { RegionMngCreateUiEvent } from "./model/RegionMngCreateUiEvent";
import { RegionMngCreateUiState } from "./model/RegionMngCreateUiState";
import CommonList from "../../../../component/CommonList";
import CommonDialog from "../../../../component/CommonDialog";
import { ApiService } from "../../../../restAPI/ApiService";
import { ADMIN_CONFIG } from "../../../../constants/AdminConfig";

const RegionMngCreatePage = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOk = () => {
    navigate("/regionAndServiceManagement");
    window.location.reload();
  };
  const HeaderButton = () => {
    const handleCreate = () => {
      // 각 필드에 대한 오류 여부를 계산
      const updatedErrors = {
        NameError: uiState.Name === "",
        CountryError: uiState.Country === "Country",
        StateProvinceRegionError: uiState.StateProvinceRegion === "",
//         CityError: uiState.City === "",
        LatitudeError: uiState.Latitude === 0,
        LongitudeError: uiState.Longitude === 0,
      };

      // 오류 상태를 uiState에 반영
      Object.entries(updatedErrors).forEach(([key, value]) => {
        uiEvent.HandleUiStateChange(key as keyof RegionMngCreateUiState, value);
      });

      // 오류가 없으면 콘솔 로그 출력
      if (!Object.values(updatedErrors).includes(true)) {
        const createData = {
          ctryCd: uiState.Country,
          province: uiState.StateProvinceRegion,
          location: uiState.Location,
          geoPoint: {
            lon: uiState.Longitude,
            lat: uiState.Latitude,
          },
          svcAreaNm: uiState.Name,
        };
        console.log(createData);
        ApiService.WebPost("/pw/backend/api/geo/save", createData).then(
          (res) => {
            navigate(
              `/regionAndServiceManagement/regionDetailPage/${res?.data.svcAreaId}` //생성 완료되면 상세페이지로 이동
            );
            window.location.reload();
          }
        );
      }
    };

    return (
      <Button
        sx={{
          backgroundColor: "#5D38E5",
          borderRadius: 5,
          ":hover": {
            backgroundColor: "#A38BFC",
          },
          color: "white",
          width: 110,
          height: 40,
        }}
        onClick={handleCreate}
      >
        Create
      </Button>
    );
  };

  const HeaderBackButton = () => {
    return (
      <IconButton
        sx={{
          ":hover": { backgroundColor: "#F2F2F2" },
          mb: 0.5,
          mr: 1,
        }}
        onClick={() => {
          handleClickOpen();
        }}
      >
        <ArrowBackIosNewIcon fontSize="medium" />
      </IconButton>
    );
  };

  const [uiState, setUiState] = useState(new RegionMngCreateUiState());
  const uiEvent: RegionMngCreateUiEvent = {
    HandleUiStateChange: (key: keyof RegionMngCreateUiState, value: any) => {
      setUiState((prevState) => ({
        ...prevState,
        [key]: value, // key에 해당하는 상태를 업데이트
      }));
    },
  };

  return (
    <CommonList
      title="Area Registration"
      headerButton={HeaderButton()}
      headerBackButton={HeaderBackButton()}
    >
      <CommonDialog open={open} handleClose={handleClose} handleOk={handleOk}>
        {ADMIN_CONFIG.MSG.EDIT_STOP_AND_MOVE}<br/>
        {ADMIN_CONFIG.MSG.EDIT_STOP_NOT_SAVED}
      </CommonDialog>
      <RegionMngCreateGrid uiState={uiState} uiEvent={uiEvent} />
    </CommonList>
  );
};

export default RegionMngCreatePage;
