import { Box, Typography } from "@mui/material";
import CheckSharpIcon from "@mui/icons-material/CheckSharp";
import Toolbar from "../../../component/Toolbar";
import VerticalSpacer from "../../../component/Spacer";
import useAndroidBackHandler from "../../../customHook/useAndroidBackHandler";
import { useEffect, useState } from "react";
import { ApiService } from "../../../restAPI/ApiService";
import ScooterContractListItem from "../component/ScooterContractListItem";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import { fontStyle } from "../../../theme/Style";
import EmptyScooterScreen from "../EmptyScooterScreen";
import LocalizedDate, {
  DateFormatType,
} from "../../../component/LocalizedDate";
import { useNavigate } from "react-router-dom";

const MyScooterContractListScreen = () => {
  useAndroidBackHandler(() => {
    window.history.back();
  }, []);

  const [scooterContractList, setScooterContractList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const requestBody = {
      uuid: localStorage.getItem("uuid") || "",
    };
    ApiService.MobilePost(
      "/pw/backend/api/scooterAgreement/list",
      requestBody,
    ).then((res) => {
      console.log(res?.data.body.handOverList);
      setScooterContractList(res?.data.body.handOverList);
    });
  }, []);

  const ua_e200_available_title = LocalizedText(
    "ua_e200_available_title",
    scooterContractList.length.toString(),
  );
  const ua_e200_available_inform_message = LocalizedText(
    "ua_e200_available_inform_message",
  );
  const ua_e200_available_register_message = LocalizedText(
    "ua_e200_available_register_message",
  );
  const ua_e200_available_idcard_message = LocalizedText(
    "ua_e200_available_idcard_message",
  );

  function goToWaitingPage(contractListItem: any) {
    navigate(`/ScooterWating`, {
      state: {
        state: {
          productName: contractListItem.productName,
          agreementDt: LocalizedDate({
            date: contractListItem.agreementDt,
            type: DateFormatType.YearMonthDay,
          }),
          agreementNumber: contractListItem.agreementNumber,
          modelPicUrl: contractListItem.modelPicUrl,
          receiptConfirmation: contractListItem.receiptConfirmation,
        },
      }, // 상태 전달
    });
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "white",
          width: "100vw",
          height: "100vh",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {/* 상단 toolbar */}
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Toolbar
            title={LocalizedText("ua_e310_gnb")}
            onBack={() => {
              window.history.back();
            }}
          />
        </Box>
        {scooterContractList.length > 0 ? (
          // 스쿠터 계약 목록이 있을 시 나오는 화면
          <Box
            sx={{
              width: "calc(100% - 40px)",
              // height: "auto",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <VerticalSpacer height={20} />
              <Typography fontStyle={fontStyle.headingS}>
                {ua_e200_available_title}
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Typography
                  sx={{ display: "flex", alignItems: "flex-start", mb: 1 }}
                >
                  <CheckSharpIcon
                    fontSize="small"
                    sx={{ color: "#5D38E5", mr: 0.5 }}
                  />
                  {ua_e200_available_inform_message}
                </Typography>
                <Typography
                  sx={{ display: "flex", alignItems: "flex-start", mb: 1 }}
                >
                  <CheckSharpIcon
                    fontSize="small"
                    sx={{ color: "#5D38E5", mr: 0.5 }}
                  />
                  {ua_e200_available_register_message}
                </Typography>
                <Typography
                  sx={{ display: "flex", alignItems: "flex-start", mb: 1 }}
                >
                  <CheckSharpIcon
                    fontSize="small"
                    sx={{ color: "#5D38E5", mr: 0.5 }}
                  />
                  {ua_e200_available_idcard_message}
                </Typography>
              </Box>
              <Box sx={{ mt: 3 }}>
                {scooterContractList.map((data: any, index: any) => {
                  return (
                    <Box key={index}>
                      <ScooterContractListItem
                        contractListItem={data}
                        onClick={() => {
                          goToWaitingPage(data);
                        }}
                      />
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
        ) : (
          // 스쿠터 계약 목록이 없을 시 나오는 화면
          <EmptyScooterScreen />
        )}
      </Box>
    </>
  );
};

export default MyScooterContractListScreen;
