import { Box, Typography } from "@mui/material";
import Toolbar from "../../component/Toolbar";
import useAndroidBackHandler from "../../customHook/useAndroidBackHandler";
import SettingButton from "./SettingButton";
import VerticalSpacer from "../../component/Spacer";
import { HorizontalGrayDivider } from "../../component/Divider";
import { useNavigate } from "react-router-dom";
import { LocalizedText } from "../../di/LanguageRepositoryProvider";
import { ROUTE_PATHS } from "../../constants/RouteConstants";
import { fontStyle } from "../../theme/Style";
import { color } from "../../theme/Color";

const SettingPage = () => {
  const navigate = useNavigate();

  useAndroidBackHandler(() => {
    if (window.Android) {
      window.history.back();
    }
  }, []);

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Toolbar
        onBack={() => {
          window.history.back();
        }}
        title={LocalizedText("ua_g700_setting")}
      />

      <Box
        sx={{
          overflowY: "scroll",
          px: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <VerticalSpacer height={16} />
        <Typography fontStyle={fontStyle.subTitleM} color={color.gray500}>
          {LocalizedText("ua_g700_usersetting")}
        </Typography>
        <VerticalSpacer height={4} />
        <SettingButton
          title={LocalizedText("ua_g700_notification")}
          onClick={() => {
            navigate(ROUTE_PATHS.NOTIFICATION);
          }}
        />
        <HorizontalGrayDivider />
        <SettingButton
          title={LocalizedText("ua_g700_language")}
          onClick={() => {
            navigate(ROUTE_PATHS.LANGUAGE);
          }}
        />
        <HorizontalGrayDivider />
        <SettingButton
          title={LocalizedText("ua_g740_servicecountry")}
          onClick={() => {
            navigate(ROUTE_PATHS.SERVICE_COUNTRY);
          }}
        />
        <VerticalSpacer height={24} />
        <Typography fontStyle={fontStyle.subTitleM} color={color.gray500}>
          {LocalizedText("ua_g700_terms")}
        </Typography>
        <VerticalSpacer height={4} />
        <SettingButton
          title={LocalizedText("ua_g700_termsofuse")}
          onClick={() => {
            navigate(ROUTE_PATHS.TERMS_OF_SERVICE);
          }}
        />
        <HorizontalGrayDivider />
        <SettingButton
          title={LocalizedText("ua_g700_policy")}
          onClick={() => {
            navigate(ROUTE_PATHS.PRIVACY_POLICY);
          }}
        />
        <HorizontalGrayDivider />
        <SettingButton
          title={LocalizedText("ua_g733_location")}
          onClick={() => {
            navigate(ROUTE_PATHS.LOCATION_TERMS_OF_SERVICE);
          }}
        />
        <HorizontalGrayDivider />
        <SettingButton
          title={LocalizedText("ua_g734_battery")}
          onClick={() => {
            navigate(ROUTE_PATHS.BATTERY_TERMS_OF_SERVICE);
          }}
        />
      </Box>
    </Box>
  );
};

export default SettingPage;
