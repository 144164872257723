import React, { ReactNode, useEffect, useState } from "react";
import { LanguageRepositoryProvider } from "./LanguageRepositoryProvider";
import { getLanguageCode, useLocale } from "../customHook/useLocale";
import ProgressBar from "../component/ProgressBar";
import { createTheme, ThemeProvider } from "@mui/material";
import { ToastProvider } from "../component/toast/ConcurrentToastManager";

const CombinedProvider = ({ children }: { children: ReactNode }) => {
  const loading = useLocale();
  const [languageCode, setLanguageCode] = useState(getLanguageCode());

  const getFontFamily = (langCode: string) => {
    switch (langCode) {
      case "en":
        return "'Poppins', sans-serif";
      case "ko":
        return "'Pretendard', sans-serif";
      case "km":
        return "'NotoSansKhmer', sans-serif";
      default:
        return "'Poppins', sans-serif"; // 언어코드 기본 값 en
    }
  };

  const [theme, setTheme] = useState(
    createTheme({
      typography: {
        fontFamily: getFontFamily(languageCode),
      },
    }),
  );

  // 언어 코드 변경 시 테마 업데이트
  useEffect(() => {
    const body = document.querySelector("body") as HTMLElement;
    setTheme(
      createTheme({
        typography: {
          fontFamily: getFontFamily(languageCode),
        },
      }),
    );
    body.id = "mobile";
  }, [languageCode]);

  // 언어 변경 이벤트 감지
  useEffect(() => {
    const handleLanguageChange = () => {
      setLanguageCode(getLanguageCode());
    };
    window.addEventListener("languageChange", handleLanguageChange);
    return () => {
      window.removeEventListener("languageChange", handleLanguageChange);
    };
  }, []);

  if (loading) {
    return <ProgressBar />;
  }

  return (
    <ThemeProvider theme={theme}>
      <ToastProvider>
        <LanguageRepositoryProvider>{children}</LanguageRepositoryProvider>
      </ToastProvider>
    </ThemeProvider>
  );
};

export default CombinedProvider;
