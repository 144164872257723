import { Box, Typography } from "@mui/material";
import { color } from "../../../../../theme/Color";
import { fontStyle } from "../../../../../theme/Style";
import { ICON_CLOSE } from "../../../../../constants/imagePath";
import { UserWalletUiEvent } from "../model/UserWalletUiEvent";
import { UserWalletUiState } from "../model/UserWalletUiState";
import VerticalSpacer from "../../../../../component/Spacer";
import LocalizedDate, {
  DateFormatType,
} from "../../../../../component/LocalizedDate";

interface UserWalletVCModalProps {
  uiEvent: UserWalletUiEvent;
  uiState: UserWalletUiState;
}

export function UserWalletVCModal({
  uiEvent,
  uiState,
}: UserWalletVCModalProps) {
  return (
    <Box
      sx={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 2000,
        backgroundColor: "rgba(0, 0, 0, 0.3)", // 반투명 검정 배경
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "400px",
          height: "472px",
          backgroundColor: color.white,
          border: "1px solid",
          borderColor: color.gray300,
          borderRadius: "15px",
          padding: "24px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography fontStyle={fontStyle.semiboldL}>
            Issued History
          </Typography>
          <Box
            component="img"
            src={ICON_CLOSE}
            sx={{ cursor: "pointer" }}
            onClick={() =>
              uiEvent.HandleUiStateChange("issuedHistoryModal", false)
            }
          ></Box>
        </Box>
        <VerticalSpacer height={8} />
        <Box
          sx={{
            flex: 1, 
            overflowY: "scroll", 
            height: "388px",
            "&::-webkit-scrollbar": {
              width: "6px", 
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: color.gray400, 
              borderRadius: "4px", 
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: color.white,
            },
          }}
        >
          {uiState.issuedHistory?.map((data, index) => (
            <Box
              key={index}
              sx={{
                mx: "4px",
                my: "12px",
                height: "62px",
                borderBottom: "1px solid",
                borderColor: color.gray300,
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Typography fontStyle={fontStyle.regularM}>
                  {`[`}
                  {data.text1}
                  {`] `}
                </Typography>
                <Typography fontStyle={fontStyle.regularM}>
                  {data.text2}
                </Typography>
              </Box>
              <VerticalSpacer height={4} />
              <Box>
                <Typography
                  fontStyle={fontStyle.regularS}
                  color={color.gray600}
                >
                  {LocalizedDate({
                    date: data.date,
                    type: DateFormatType.YearMonthDay,
                  })}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
