import { Box, Grid, Typography } from "@mui/material";
import UserWalletTab from "./UserWalletTab";
import { TabType } from "./model/TabType";
import { UserWalletUiState } from "./model/UserWalletUiState";
import { UserWalletUiEvent } from "./model/UserWalletUiEvent";
import { UserWalletVCPage } from "./section/UserWalletVCCard";
import { UserWalletNFTPage } from "./section/UserWalletNFTCard";
import VerticalSpacer, { HorizontalSpacer } from "../../../../component/Spacer";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import { UserWalletVCModal } from "./section/UserWalletVCModal";

interface UserWalletPageProps {
  currentTab: TabType;
  uiState: UserWalletUiState;
  uiEvent: UserWalletUiEvent;
}

export function UserWalletPage({
  currentTab,
  uiState,
  uiEvent,
}: UserWalletPageProps) {
  return (
    <Box>
      <Box>
        <UserWalletTab
          currentTab={currentTab}
          onClick={(tab) => {
            uiEvent.OnClickTab(tab);
          }}
        />
        <Grid container rowSpacing={4.5} columnSpacing={2.75} sx={{ mt: -3 }}>
          {currentTab === TabType.NFT &&
            uiState.nftInfo?.map((info, index) => (
              <UserWalletNFTPage key={info.nftId || index} data={info} />
            ))}
          {currentTab === TabType.VC &&
            uiState.vcInfo?.map((info, index) => (
              <UserWalletVCPage key={info.vcId || index} data={info} />
            ))}
        </Grid>
      </Box>
      <VerticalSpacer height={16} />
      <Box>
        {currentTab === TabType.VC && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() =>
              uiEvent.HandleUiStateChange("issuedHistoryModal", true)
            }
          >
            <Typography fontStyle={fontStyle.semiboldS} color={color.gray600}>
              Issued History
            </Typography>
            <HorizontalSpacer width={4} />
            <ArrowForwardIosRoundedIcon
              sx={{ height: "12px", width: "12px", color: color.gray600 }}
            />
          </Box>
        )}
      </Box>
      {currentTab === TabType.VC && uiState.issuedHistoryModal === true && (
        <UserWalletVCModal uiEvent={uiEvent} uiState={uiState} />
      )}
    </Box>
  );
}
