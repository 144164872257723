import { Box, Checkbox } from "@mui/material";
import React from "react";
import { color } from "../../../../../theme/Color";
import { fontStyle } from "../../../../../theme/Style";
import { Text } from "../../../../../component/Text";
import { HorizontalSpacer } from "../../../../../component/Spacer";

interface DateCheckboxProps {
  checkedStates: boolean[]; // [All day, Sun, Mon, Tue, Wed, Thu, Fri, Sat]
  onChange: (newStates: boolean[]) => void;
  disabled?: boolean;
}

export function DateCheckbox({
  checkedStates,
  onChange,
  disabled,
}: DateCheckboxProps) {
  const dayLabels = [
    "All day",
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
  ];

  const handleChange = (index: number) => {
    const newStates = [...checkedStates];

    // "All day" 체크박스 처리
    if (index === 0) {
      const newValue = !newStates[0];
      newStates.fill(newValue); // 모두 같은 값으로 설정
    } else {
      newStates[index] = !newStates[index];
      newStates[0] = newStates.slice(1).every((checked) => checked); // 모든 요일이 선택되면 "All day"도 선택
    }

    onChange(newStates);
  };
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(84px, auto))", // 필요한 공간만 차지
        rowGap: "12px",
        columnGap: "28px",
        justifyContent: "start", // 남은 공간을 채우지 않도록 왼쪽 정렬
      }}
    >
      {dayLabels.map((label, index) => (
        <Box
          key={label}
          sx={{
            display: "inline-flex", // 요소가 필요한 공간만 차지하도록 설정
            flexDirection: "row",
            alignItems: "center",
            height: "20px",
          }}
        >
          <Checkbox
            sx={{
              padding: 0,
            }}
            disabled={disabled}
            checked={checkedStates[index]}
            onChange={() => handleChange(index)}
          />
          <HorizontalSpacer width={8} />
          <Text
            sx={{
              fontStyle: fontStyle.regularS,
              color: color.gray600,
            }}
            text={label}
          />
        </Box>
      ))}
    </Box>
  );
}
