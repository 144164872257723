import { Box, Divider } from "@mui/material";
import { HorizontalSpacer } from "../../component/Spacer";
import { fontStyle } from "../../theme/Style";
import { TabType } from "./model/TabType";
import { color } from "../../theme/Color";
import { EcoPlatformWalletUiEvent } from "./model/EcoPlatformWalletUiEvent";

export interface EcoPlatformWalletTabProps {
  currentTab: TabType;
  uiEvent: EcoPlatformWalletUiEvent;
}

export function EcoPlatformWalletTab({
  currentTab,
  uiEvent,
}: EcoPlatformWalletTabProps) {
  return (
    <Box sx={{ display: "flex", height: "24px" }}>
      <HorizontalSpacer width={16} />
      <Box
        sx={{
          fontStyle: fontStyle.semiboldM,
          cursor: "pointer",
          color:
            currentTab == TabType.WALLET ? color.primary500 : color.gray600,
        }}
        onClick={() => {
          uiEvent.onClickTab(TabType.WALLET);
        }}
      >
        Wallet
      </Box>
      <HorizontalSpacer width={16} />
      {localStorage.getItem("user_type") == "Partner" ? (
        ""
      ) : (
        <>
          <Divider orientation="vertical" />
          <HorizontalSpacer width={16} />
          <Box
            sx={{
              fontStyle: fontStyle.semiboldM,
              cursor: "pointer",
              color:
                currentTab == TabType.NFT ? color.primary500 : color.gray600,
            }}
            onClick={() => {
              uiEvent.onClickTab(TabType.NFT);
            }}
          >
            NFT
          </Box>
        </>
      )}
    </Box>
  );
}
