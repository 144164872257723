// AppConfig.js

export const ADMIN_CONFIG = {
  TRUE: "true",
  FALSE: "false",

  MSG: {
    EDIT_STOP_AND_MOVE: "Do you want to stop editing and move?",
    EDIT_STOP_NOT_SAVED: "The current content will not be saved.",
  },

  /****** MENU Start ******/
  MENU_TYPE: {
    COLLAPSE: "collapse",
    ITEM: "item",
    GROUP: "group",
  },

  // 통계
  MENU_STATISTICS: {
    TITLE: "Statistics",
    KEY: "statistics",
    URL: "",
    SHOW: true,
  },

  MENU_STATISTICS_STATISTICS: {
    TITLE: "Statistics",
    KEY: "statistics",
    URL: "/dashboard",
    SHOW: true,
  },

  MENU_STATISTICS_SCOOTER: {
    TITLE: "Scooter Data",
    KEY: "scooterData",
    URL: "/scooterData",
    SHOW: true,
  },
  MENU_STATISTICS_LOCAL_CARBON_REDUCTION: {
    TITLE: "Local Carbon Reduction",
    KEY: "localCarbonReduction",
    URL: "/localCarbonReduction",
    SHOW: true,
  },

  // 사용자 관리
  MENU_USER_MANAGEMENT: {
    TITLE: "User Management",
    KEY: "userManagement",
    URL: "",
    SHOW: true,
  },

  // 회원 관리
  MENU_USER_MANAGEMENT_MEMBER: {
    TITLE: "Member",
    KEY: "member",
    URL: "/usrMng",
    SHOW: true,
  },

  // 관리자 관리
  MENU_USER_MANAGEMENT_ADMINISTRATOR: {
    TITLE: "Administrator",
    KEY: "administrator",
    URL: "/adminMng",
    SHOW: true,
  },

  // 그룹 관리
  MENU_USER_MANAGEMENT_GROUP: {
    TITLE: "Group",
    KEY: "group",
    URL: "/grpMng",
    SHOW: false,
  },

  // 컨텐츠 관리
  MENU_CONTENT: {
    TITLE: "Content",
    KEY: "content",
    URL: "",
    SHOW: true,
  },

  // FAQ 관리
  MENU_CONTENT_FAQ: {
    TITLE: "FAQ",
    KEY: "faq",
    URL: "/FAQMng",
    SHOW: true,
  },

  // 1:1 문의 관리
  MENU_CONTENT_INQUIRY: {
    TITLE: "1:1 Inquiry",
    KEY: "inquiry",
    URL: "/inquiryMng",
    SHOW: true,
  },

  // CS 관리
  MENU_CONTENT_CS: {
    TITLE: "CS",
    KEY: "cs",
    URL: "/csMng",
    SHOW: true,
  },

  // 공지 사항 관리
  MENU_CONTENT_NOTICE: {
    TITLE: "Notice",
    KEY: "notice",
    URL: "/announcement",
    SHOW: true,
  },

  // 푸시 알람 관리
  MENU_CONTENT_PUSH_NOTIFICATION: {
    TITLE: "Push Notification",
    KEY: "pushNotification",
    URL: "/pushMng",
    SHOW: false,
  },

  // 팝업 관리
  MENU_CONTENT_POPUP_BANNER: {
    TITLE: "Popup/Banner",
    KEY: "popupBanner",
    URL: "/popupMng",
    SHOW: true,
  },

  // 상품 관리
  MENU_MERCHANDISE: {
    TITLE: "Merchandise",
    KEY: "merchandise",
    URL: "/",
    SHOW: true,
  },

  // 멤버십 바우처 설정
  MENU_MERCHANDISE_MEMBERSHIP_VOUCHER_SETTINGS: {
    TITLE: "Membership Voucher Settings",
    KEY: "membershipVoucher",
    URL: "/membershipVoucherSetting",
    SHOW: true,
  },

  // NFT 설정
  MENU_MERCHANDISE_NFT_SETTINGS: {
    TITLE: "NFT Settings",
    KEY: "nft",
    URL: "/NFTManagement",
    SHOW: true,
  },

  // 쿠폰 설정
  MENU_MERCHANDISE_COUPON_SETTINGS: {
    TITLE: "Coupon Settings",
    KEY: "coupon",
    URL: "/couponManagement",
    SHOW: true,
  },

  // 결제 관리
  MENU_MERCHANDISE_PAYMENT: {
    TITLE: "Payment",
    KEY: "payment",
    URL: "/paymentManagement",
    SHOW: true,
  },

  // Production
  MENU_PRODUCTION_MATERIAL: {
    TITLE: "Production Material",
    KEY: "productionMaterial",
    URL: "",
    SHOW: true,
  },

  // 생산 관리
  MENU_PRODUCTION_MATERIAL_PRODUCTION: {
    TITLE: "Production",
    KEY: "production",
    URL: "/productionManagement",
    SHOW: true,
  },

  // 재고 관리
  MENU_PRODUCTION_MATERIAL_INVENTORY: {
    TITLE: "Inventory",
    KEY: "inventory",
    URL: "/inventoryManagement",
    SHOW: true,
  },

  // 요청 관리
  MENU_PRODUCTION_MATERIAL_REQUEST: {
    TITLE: "Request",
    KEY: "request",
    URL: "/requestManagement",
    SHOW: true,
  },

  // 모델 관리
  MENU_PRODUCTION_MATERIAL_PRODUCT_NAME: {
    TITLE: "Product Name",
    KEY: "productName",
    URL: "/modelManagement",
    SHOW: true,
  },

  // 운영 관리
  MENU_OPERATIONS: {
    TITLE: "Operations",
    KEY: "operations",
    URL: "",
    SHOW: true,
  },

  // 지역 및 서비스 관리
  MENU_OPERATIONS_AREA_AND_SERVICE: {
    TITLE: "Area and Service",
    KEY: "regionService",
    URL: "/regionAndServiceManagement",
    SHOW: true,
  },

  // 스쿠터 관리
  MENU_OPERATIONS_SCOOTER: {
    TITLE: "Scooter",
    KEY: "scooter",
    URL: "/vehicleMng",
    SHOW: true,
  },

  // 스테이션 관리
  MENU_OPERATIONS_STATION: {
    TITLE: "Station",
    KEY: "station",
    URL: "/stationMng",
    SHOW: true,
  },

  // 배터리 관리
  MENU_OPERATIONS_BATTERY: {
    TITLE: "Battery",
    KEY: "battery",
    URL: "/batteryMng",
    SHOW: true,
  },

  // NFC 카드 관리
  MENU_OPERATIONS_NFC_CARD: {
    TITLE: "NFC Card",
    KEY: "nfcCard",
    URL: "/nfcCardManagement",
    SHOW: true,
  },

  // 탄소감축량관리
  MENU_CARBON_REDUCTION: {
    TITLE: "Carbon Reduction",
    KEY: "carbonReduction",
    URL: "",
    SHOW: true,
  },

  //탄소감축량 정보
  MENU_CARBON_REDUCTION_INFO: {
    TITLE: "Carbon Reduction Info",
    KEY: "carbonReductionInfo",
    URL: "/carbonReductionInfo",
    SHOW: true,
  },

  // 방법론 관리
  MENU_CARBON_REDUCTION_METHODOLOGY: {
    TITLE: "Methodology",
    KEY: "methodology",
    URL: "/methodologyMng",
    SHOW: true,
  },

  // 프로젝트 관리
  MENU_CARBON_REDUCTION_PROJECT: {
    TITLE: "Project",
    KEY: "project",
    URL: "/projectMng",
    SHOW: true,
  },

  // 데이터/인자 관리
  MENU_CARBON_REDUCTION_DATA_PARAMETER: {
    TITLE: "Data/Parameter",
    KEY: "dataParameter",
    URL: "/paramDataMng",
    SHOW: true,
  },

  // 모니터링 관리
  MENU_CARBON_REDUCTION_MONITORING: {
    TITLE: "Monitoring",
    KEY: "monitoring",
    URL: "/monitoringMng",
    SHOW: true,
  },

  //로우 데이터 확인
  MENU_ROW_DATA_CHECK: {
    TITLE: "Raw Data",
    KEY: "rowDataCheck",
    URL: "/rowDataCheck",
    SHOW: true,
  },

  // 블록체인 관리
  MENU_BLOCKCHAIN: {
    TITLE: "Blockchain",
    KEY: "blockchain",
    URL: "",
    SHOW: true,
  },

  // 블록체인 모니터링
  MENU_BLOCKCHAIN_BLOCKCHAIN_MONITORING: {
    TITLE: "Blockchain Monitoring",
    KEY: "monitoring",
    URL: "/blockchainMonitoring",
    SHOW: true,
  },

  // 에코플랫폼 월렛
  MENU_BLOCKCHAIN_ECO_PLATFORM_WALLET: {
    TITLE: "Eco-Platform Wallet",
    KEY: "wallet",
    URL: "/wallet",
    SHOW: true,
  },

  // 시스템 관리
  MENU_SYSTEM_SETTING: {
    TITLE: "System Setting",
    KEY: "system",
    URL: "",
    SHOW: true,
  },

  // 알림 설정
  MENU_SYSTEM_SETTING_NOTIFICATION: {
    TITLE: "Notification",
    KEY: "notification",
    URL: "/notiMng",
    SHOW: false,
  },

  // 다국어 설정
  MENU_SYSTEM_SETTING_MULTI_LANGUAGE: {
    TITLE: "Multi-language",
    KEY: "multiLanguage",
    URL: "/multilingual",
    SHOW: true,
  },

  // 이력 관리
  MENU_SYSTEM_SETTING_HISTORY_MANAGEMENT: {
    TITLE: "History Management",
    KEY: "history",
    URL: "/historyMng",
    SHOW: false,
  },

  // 연계/배치 관리
  MENU_SYSTEM_SETTING_BATCH: {
    TITLE: "Batch",
    KEY: "batch",
    URL: "/linkBatchMng",
    SHOW: false,
  },

  // MQTT
  MENU_SYSTEM_SETTING_MQTT: {
    TITLE: "MQTT",
    KEY: "mqtt",
    URL: "",
    SHOW: false,
  },
  /****** MENU End ******/

  /****** Member Page Start ******/
  PAGE_MEMBER: {
    ACTIVE: "Active",
    BLOCKED: "Blocked",
    DELETED: "Deleted",
  },
  /****** Member Page Start ******/

  /****** Dashboard Page Start ******/
  PAGE_DASHBOARD: {
    TITLE: "Analysis Overview",
    ADMIN: "Admin",
    BATTERY: "Battery",
    CS: "CS",
    INQUIRY: "Inquiry",
    SCOOTER: "Scooter",
    STATION: "Station",
    USER: "User",
    BLOCKS: "Blocks",
    TRANSACTIONS: "Transactions",
  },
  /****** Dashboard Page End ******/

  /****** Material Page Start ******/
  PAGE_MATERIAL: {
    AL: "AL",
    ASSY: "ASSY",
    ELECTRIC: "ELECTRIC",
    ETC: "ETC",
    HARNESS: "HARNESS",
    MD: "M/D",
    PD: "P/D",
    SEMI_ASSY: "SEMI ASSY",
  },

  /****** materialRegistration Page End ******/

  // 운영관리 - 지역 및 서비스 관리
  // src\pages\regionandservicemanagement\... 에서 사용
  CAFE: "Cafe",
  STATION: "Station",
  CSCENTER: "CS Center",
  PARTNER: "Partner",
  NEW: "NEW",
  PREPARING: "PREPARING",
  OPEN: "OPEN",
  CLOSE: "CLOSE",
  CLOSURE: "CLOSURE",
  HOT_SPOT: "Hot Spot",
  RESTAURANT: "Restaurant",
  HOTEL: "Hotel",

  /****** Methodology Page Start ******/
  PAGE_METHODOLOGY: {
    NO: "No",
    REGISTER: "Methodological Register", // 방법론 등록
    DETAIL: "Methodological Detail", // 방법론 상세
    CRITERIA: "Methodology Standard", // 방법론 기준
    NAME: "Methodology Name", // 방법론명
    ID: "Methodology ID", // 방법론 ID
    VERSION: "Version", // 버전
    EXPIRATION: "Expiration Date", // 유효 기간
    EXPIRATION_START_DATE: "Expiration Start Date", // 시작일
    REGISTER_DATE: "Register Date", // 생성일
    NO_DATA: "No Data",
    INFORMATION: "Basic Information", // 기본 정보
    ATTACHMENT: "Attach File", // 첨부 파일
    METHODOLOGICAL_FILE: "Methodology Document", // 방법론 문서 파일
    FIELD: "Field", // 분야
    DETAILED_FIELD: "Subfield", // 세부 분야
    EXPIRATION_YEAR: "Expiration Year", // 유효 기간(년수)
  },
  /****** Methodology Page End ******/

  /****** Project Page Start ******/
  PAGE_PROJECT: {
    NO: "No",
    ID: "Project ID", // 프로젝트 ID
    NAME: "Project Name", // 프로젝트명
    APPLY_TYPE: "Apply Type", // 신청 유형
    METHODOLOGY_ID: "Methodology ID", // 적용 방법론
    COMPANY: "Company Name", // 기업명
    REPORT: "Report", // 사업 검증 보고서
    EXPIRATION_START_DATE: "Start Date", // 유효기간 시작일
    EXPIRATION_END_DATE: "End Date", // 유효기간 종료일
    REGISTER_DATE: "Register Date", // 생성일
    REGISTER: "Project Register", // 프로젝트 등록
    DETAIL: "Project Detail", // 프로젝트 상세
    COMPANY_INFORMATION: "Company Information", // 기업 정보
    PROJECT_INFORMATION: "Project Information", // 프로젝트 정보
    OWNER: "Owner", // 대표자명
    BUSINESS_REGISTRATION_NUMBER: "Business Registration Number", // 사업자 등록번호
    BUSINESS_CERTIFICATE: "Business Certificate", // 사업인증서
    HOMEPAGE: "Homepage", // 홈페이지
    FAX: "FAX Number", // 팩스번호
    ADDRESS: "Address", // 주소
    ADDRESS_DETAIL: "Address Detail", // 상세 주소 입력
    LAT: "Latitude", // 위도
    LONG: "Longitude", // 경도
    REPRESENTATIVE_INFORMATION: "Representative Information", // 담당자 정보
    REPRESENTATIVE_NAME: "Representative Name", // 담당자명
    DEPARTMENT_NAME: "Department Name", // 부서명
    NUMBER: "Phone Number", // Number
    EMAIL: "Email", // Email

    REGISTRATION_OF_OTHER_INSTITUTIONS: "Registration of other institutions (whether to apply)", // 타 기관 등록(신청) 여부
    PROJECT_NAME: "Project Name", // 프로젝트명
    VALIDITY: "Validity", // 유효기간
    GREENHOUSE_GAS_REDUCTIONS_IN_BUSINESS_PLANS: "Greenhouse gas reductions in business plans", // 사업 계획서 상의 온실가스 감축량
    METHODOLOGY: "Methodology", // 방법론
    REDUCTION_FIELD: "Reduction Field", // 감축 분야
    SUBFIELD: "Subfield", // 세부 분야
    PHOTOS_OF_YOUR_BUSINESS: "Photos of your business", // 사업장 사진
    REGISTER_BUSINESS_VERIFICATION_REPORT: "Register Business Verification Report", // 사업 검증 보고서 등록
    PROJECT_CONTENT_AND_PURPOSE: "Project Content and Purpose", // 프로젝트 내용 및 목적
    OOBOAGGR: "Ownership of Business Operators and Greenhouse Gas Reduction", // 사업자 및 온실가스 감축량 소유권
    EOROPCP: "Evalution of Redundancy of Project Certification Performance", // 프로젝트 인증실적의 중복성 평가
    // 사업 전,후 공정
    // 사업 이행 상태
    // 사업 승인 후 변경사항
  },
  /****** Project Page End ******/

  /****** Data/Parameter Page Start ******/
  PAGE_DATA_PARAMETER: {},
  /****** Data/Parameter Page End ******/

  /****** Monitoring Page Start ******/
  PAGE_MONITORING: {},
  /****** Monitoring Page End ******/

  /****** Battery Detail Page *******/
  VALUE: {
    HYPHEN: "-",
    WARNING: "Warning",
    ERROR: "Error",
    ERROR_MESSAGE: {
      UT_PROTECTION_CHARGE: "UT Protection Charge",
      UT_WARNING_CHARGE: "UT Warning Charge",
      OT_PROTECTION_CHARGE: "OT Protection Charge",
      OT_WARNING_CHARGE: "OT Warning Charge",
      PACK_OV_PROTECTION: "Pack OV Protection",
      PACK_OV_WARNING: "Pack OV Warning",
      PACK_UV_PROTECTION: "Pack UV Protection",
      PACK_UV_WARNING: "Pack UV Warning",
      CELL_DELTA_V_PERMANENT_ERROR: "Cell Delta V Permanent Error",
      CELL_DELTA_V_ERROR: "Cell Delta V Error",
      COMMUNICATION_PROTECTION: "Communication Protection",
      OC_PROTECTION_DISCHARGE: "OC Protection Discharge",
      OC_WARNING_DISCHARGE: "OC Warning Discharge",
      OC_PROTECTION_CHARGE: "OC Protection Charge",
      OC_WARNING_CHARGE: "OC Warning Charge",
      UT_PROTECTION_DISCHARGE: "UT Protection Discharge",
      UT_WARNING_DISCHARGE: "UT Warning Discharge",
      OT_PROTECTION_DISCHARGE: "OT Protection Discharge",
      OT_WARNING_DISCHARGE: "OT Warning Discharge",
      OV_PROTECTION: "OV Protection",
      OV_WARNING: "OV Warning",
      UV_PROTECTION: "UV Protection",
      UV_WARNING: "UV Warning",
    },
  },

  /****** Battery Detail Page *******/
};
