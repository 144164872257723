import { Box, styled, TableCell, TableRow, Typography } from "@mui/material";
import CommonSearch from "../../../component/CommonSearch";
import { NFTUiState } from "./model/NFTUiState";
import { NFTUiEvent } from "./model/NFTUiEvent";
import VerticalSpacer from "../../../component/Spacer";
import CommonTable, {
  HeadCell,
  useVisibleRows,
} from "../../../component/CommonTable";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import LocalizedDate, {
  DateFormatType,
} from "../../../component/LocalizedDate";

interface NFTPageProps {
  uiState: NFTUiState;
  uiEvent: NFTUiEvent;
}

const NFTTableCell = styled(TableCell)(({ theme }) => ({
  height: "76px",
  padding: "16px 12px",
}));

export function NFTPage({ uiState, uiEvent }: NFTPageProps) {
  const { order, orderBy, handleRequestSort, handleChangePage, visibleRows } =
    useVisibleRows(
      uiState.nftInfo,
      uiState.rowsPerPage,
      (newPage: number) => uiEvent.handleUiStateChange("page", newPage),
      uiState.page
    );
  return (
    <>
      <CommonSearch
        placeHolder={"NFT ID, Address"}
        searchContents={uiState.searchContents}
        setSearchContents={(newSearchContents: string) => {
          uiEvent.handleUiStateChange("searchContents", newSearchContents);
        }}
      ></CommonSearch>
      <VerticalSpacer height={16} />
      <CommonTable
        headCells={nftHeadCells}
        docCount={uiState.docCount}
        page={uiState.page}
        pageCount={uiState.pageCount}
        rowsPerPage={uiState.rowsPerPage}
        setRowsPerPage={(newRowsperPage: number) =>
          uiEvent.handleUiStateChange("rowsPerPage", newRowsperPage)
        }
        setPage={(newPage: number) =>
          uiEvent.handleUiStateChange("page", newPage)
        }
        order={order}
        orderBy={orderBy}
        handleChangePage={handleChangePage}
        handleRequestSort={handleRequestSort}
      >
        {visibleRows.map(
          (data: { [x: string]: string | number }, index: number) => (
            <TableRow key={index}>
              <NFTTableCell sx={{ paddingLeft: "24px" }}>
                <Typography
                  sx={{ width: "36px" }}
                  fontStyle={fontStyle.regularXS}
                  color={color.gray800}
                >
                  {data.no}
                </Typography>
              </NFTTableCell>
              <NFTTableCell>
                <Typography
                  sx={{ width: "80px" }}
                  fontStyle={fontStyle.regularXS}
                  color={color.gray800}
                >
                  {data.nftImage}
                </Typography>
              </NFTTableCell>
              <NFTTableCell>
                <Typography
                  sx={{ width: "100px" }}
                  fontStyle={fontStyle.regularXS}
                  color={color.gray800}
                >
                  {data.nftType}
                </Typography>
              </NFTTableCell>
              <NFTTableCell>
                <Typography
                  sx={{ width: "60px" }}
                  fontStyle={fontStyle.regularXS}
                  color={color.gray800}
                >
                  {data.symbol}
                </Typography>
              </NFTTableCell>
              <NFTTableCell>
                <Typography
                  sx={{ width: "228px" }}
                  fontStyle={fontStyle.regularXS}
                  color={color.gray800}
                >
                  {data.nftId}
                </Typography>
              </NFTTableCell>
              <NFTTableCell>
                <Typography
                  sx={{ width: "72px" }}
                  fontStyle={fontStyle.regularXS}
                  color={color.gray800}
                >
                  {LocalizedDate({
                    date: data.issuer,
                    type: DateFormatType.numericTime,
                  })}
                </Typography>
              </NFTTableCell>
              <NFTTableCell>
                <Typography
                  sx={{ width: "120px" }}
                  fontStyle={fontStyle.regularXS}
                  color={color.gray800}
                >
                  {LocalizedDate({
                    date: data.issueDate,
                    type: DateFormatType.numericTime,
                  })}
                </Typography>
              </NFTTableCell>
              <NFTTableCell sx={{ paddingRight: "24px" }}>
                <Typography
                  sx={{ width: "120px" }}
                  fontStyle={fontStyle.regularXS}
                  color={color.gray800}
                >
                  {LocalizedDate({
                    date: data.lastUpdatedDate,
                    type: DateFormatType.numericTime,
                  })}
                </Typography>
              </NFTTableCell>
            </TableRow>
          )
        )}
      </CommonTable>
    </>
  );
}

const nftHeadCells: HeadCell[] = [
  {
    disablePadding: false,
    align: "left",
    id: "no",
    label: "No",
  },
  {
    disablePadding: false,
    align: "left",
    id: "nftImage",
    label: "NFT Image",
  },
  {
    disablePadding: false,
    align: "left",
    id: "nftType",
    label: "NFT Type",
  },
  {
    disablePadding: false,
    align: "left",
    id: "symbol",
    label: "Symbol",
  },
  {
    disablePadding: false,
    align: "left",
    id: "nftId",
    label: "NFT ID",
  },
  {
    disablePadding: false,
    align: "left",
    id: "issuer",
    label: "Issuer",
  },
  {
    disablePadding: false,
    align: "left",
    id: "issueDate",
    label: "Issue Date",
  },
  {
    disablePadding: false,
    align: "left",
    id: "lastupdatedDate",
    label: "Last Updated Date",
  },
];
