import { Box, SxProps, TableCell, TableRow, Theme } from "@mui/material";
import CommonTable, { useVisibleRows } from "../../../../component/CommonTable";
import LocalizedDate, {
  DateFormatType,
} from "../../../../component/LocalizedDate";
import { Text } from "../../../../component/Text";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import { useNavigate } from "react-router-dom";

interface NFTManagementTableProps {
  rows: any[];
  headCells?: any;
  rowsPerPage?: any;
  setRowsPerPage?: any;
  page?: any;
  pageCount?: any;
  setPage?: any;
  docCount?: any;
}

interface RowProps {
  row: any;
  index: any;
}

interface CustomTableCellProps {
  childrenContainerSx?: SxProps<Theme>;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  children: JSX.Element;
  sx?: SxProps<Theme>;
}

export function CustomTableCell({
  childrenContainerSx,
  children,
  align,
  sx,
}: CustomTableCellProps) {
  return (
    <TableCell
      sx={{
        py: "4px",
        px: "8px",
        minHeight: "58px",
        ...sx,
      }}
      align={align}
    >
      <Box
        sx={{
          py: 0,
          display: "flex", // flexbox로 변경
          alignItems: "center", // 수직 중앙 정렬
          ...childrenContainerSx,
        }}
      >
        {children}
      </Box>
    </TableCell>
  );
}

const Row = ({ row, index }: RowProps) => {
  const navigate = useNavigate();
  return (
    <>
      <TableRow>
        <CustomTableCell
          sx={{ paddingLeft: "24px" }}
          childrenContainerSx={{ width: "30px" }}
          children={
            <Text
              sx={{
                fontStyle: fontStyle.regularXS,
                color: color.gray800,
                whiteSpace: "pre-line",
              }}
              text={index + 1}
            />
          }
          align={"center"}
        />

        <CustomTableCell
          childrenContainerSx={{ width: "50px" }}
          children={
            <Box
              sx={{
                width: "50px",
                height: "50px",
                backgroundImage: `url(${row.nftImg})`,
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            />
          }
        />
        <CustomTableCell
          childrenContainerSx={{ width: "90px" }}
          children={
            <Text
              sx={{
                fontStyle: fontStyle.regularXS,
                color: color.gray800,
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/NFTDetail/${row.nftCharacterNm}`);
                console.log("navigate : ", row.nftCharacterNm);
              }}
              text={row.nftCharacterNm}
            />
          }
          align={"left"}
        />
        <CustomTableCell
          sx={{ whiteSpace: "pre-line" }}
          childrenContainerSx={{ width: "259px" }}
          children={
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {row.compensation
                .sort((a: string, b: string) => {
                  const getLevelNumber = (text: string) => {
                    const match = text.match(/Level(\d+)/);
                    return match ? parseInt(match[1], 10) : 0;
                  };
                  return getLevelNumber(a) - getLevelNumber(b);
                })
                .map((compensation: string, index: number) => (
                  <Text
                    key={index}
                    sx={{
                      fontStyle: fontStyle.regularXS,
                      color: color.gray800,
                      whiteSpace: "pre-line",
                    }}
                    text={compensation}
                  />
                ))}
            </Box>
          }
          align={"left"}
        />
        <CustomTableCell
          childrenContainerSx={{ width: "86px" }}
          children={
            <Text
              sx={{
                fontStyle: fontStyle.regularXS,
                color: color.gray800,
              }}
              text={row.writer}
            />
          }
          align={"left"}
        />
        <CustomTableCell
          childrenContainerSx={{ width: "117px" }}
          children={
            <Text
              sx={{
                fontStyle: fontStyle.regularXS,
                color: color.gray800,
              }}
              text={LocalizedDate({
                date: row.distributeDt,
                type: DateFormatType.YearMonthDay,
              })}
            />
          }
          align={"left"}
        />
        <CustomTableCell
          childrenContainerSx={{ width: "120px" }}
          children={
            <Text
              sx={{
                fontStyle: fontStyle.regularXS,
                color: color.gray800,
              }}
              text={LocalizedDate({
                date: row.lastUptDt,
                type: DateFormatType.AdminType,
              })}
            />
          }
        />
        <CustomTableCell
          sx={{ paddingRight: "24px" }}
          childrenContainerSx={{ whdth: "120px" }}
          children={
            <Text
              sx={{
                fontStyle: fontStyle.regularXS,
                color: color.gray800,
              }}
              text={LocalizedDate({
                date: row.registerDt,
                type: DateFormatType.AdminType,
              })}
            />
          }
        />
      </TableRow>
    </>
  );
};

const NFTTable = ({
  rows,
  headCells,
  rowsPerPage,
  setRowsPerPage,
  docCount,
  pageCount,
  page,
  setPage,
}: NFTManagementTableProps) => {

  const { order, orderBy, handleRequestSort, handleChangePage, visibleRows } =
    useVisibleRows(rows, rowsPerPage, setPage, page);

  return (
    <CommonTable
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      page={page}
      docCount={docCount}
      pageCount={pageCount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      handleRequestSort={handleRequestSort}
      handleChangePage={handleChangePage}
      setPage={setPage}
    >
      {visibleRows.map((data: any, index: any) => (
        <Row row={data} index={index} key={index} />
      ))}
    </CommonTable>
  );
};

export default NFTTable;
