import { Box, Typography } from "@mui/material";
import React from "react";
import { fontStyle } from "../../theme/Style";
import { color } from "../../theme/Color";
import VerticalSpacer from "../../component/Spacer";

interface GuideCardProps {
  title: string;
  content: string;
  image: string;
}

export function GuideCard({ title, content, image }: GuideCardProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Typography
        sx={{
          fontStyle: fontStyle.titleXL,
          color: color.gray900,
        }}
      >
        {title}
      </Typography>
      <VerticalSpacer height={4} />
      <Typography
        sx={{
          fontStyle: fontStyle.bodyLr,
          color: color.gray800,
        }}
      >
        {content}
      </Typography>
      <VerticalSpacer height={20} />
      <img
        src={image}
        alt={title}
        style={{
          width: "100%",
        }}
      />
    </Box>
  );
}
