import { useEffect, useState } from "react";
import CommonList from "../../../component/CommonList";
import CommonSearch from "../../../component/CommonSearch";
import { Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import NFTTable from "./section/NFTTable";
import VerticalSpacer, { HorizontalSpacer } from "../../../component/Spacer";
import { NFTHeadCells } from "../model/NFTHeadCells";
import { NFTManagementTab } from "./section/NFTManagementTab";
import NFTInfo from "../model/NFT";
import { ApiService } from "../../../restAPI/ApiService";

const NFTManagement = () => {
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [rows, setRows] = useState<NFTInfo[]>([]);
  const [page, setPage] = useState<number>(0);
  const [docCount, setDocCount] = useState<number>(0);
  const [searchContents, setSearchContents] = useState("");
  const [tabValue, setTabValue] = useState("mng");
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const HeaderButton = () => {
    return (
      <Button
        sx={{
          height: 40,
          backgroundColor: "#5D38E5",
          color: "white",
          borderRadius: 5,
          width: 110,
          ":hover": {
            backgroundColor: "#A38BFC",
          },
        }}
        onClick={() => {
          navigate("/NFTCreate");
        }}
      >
        <AddIcon fontSize="small" />
        Create
      </Button>
    );
  };

  useEffect(() => {
    console.log("SEARCH IN NFT MANAGEMENT: ", searchContents);
    ApiService.WebPost("/pw/aw/product/nftCoupon/list", {
      searchCondition: searchContents,
      page: page,
      size: rowsPerPage,
    })
      .then((res) => {
        console.log("NFT DATA: ", res?.data);
        const nftData = res?.data.body.nftList || [];
        setRows(nftData);
        setPageCount(Math.ceil(res?.data.body.docCount / rowsPerPage) || 1);
        setDocCount(res?.data.body.docCount || 0);
      })
      .catch((e) => {
        setRows([]);
        setPageCount(1);
        setDocCount(0);
      })
      .finally(() => {});
  }, [page, rowsPerPage]);

  return (
    <CommonList
      title="NFT Settings"
      tabButton={<NFTManagementTab />} //추후 tab에 맞는 table 기능 구현
      headerButton={HeaderButton()}
    >
      <CommonSearch
        placeHolder={"Name, Reward, Writer"}
        searchContents={searchContents}
        setSearchContents={setSearchContents}
        onKeyPress={() => {
          ApiService.WebPost("/pw/aw/product/nftCoupon/list", {
            searchCondition: searchContents,
            page: page,
            size: rowsPerPage,
          })
            .then((res) => {
              console.log("NFT DATA: ", res?.data);
              const nftData = res?.data.body.nftList || [];
              setPage(0);
              setRows(nftData);
              setPageCount(Math.ceil(res?.data.body.docCount / rowsPerPage));
              setDocCount(res?.data.body.docCount || 0);
              console.log("page : ", page);
            })
            .catch((e) => {
              setRows([]);
              setPageCount(1);
              setDocCount(0);
            });
        }}
      >
        <Box sx={{ display: "flex" }}>
          <HorizontalSpacer width={12} />
        </Box>
      </CommonSearch>
      <VerticalSpacer height={16} />
      <NFTTable
        rows={rows}
        headCells={NFTHeadCells}
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </CommonList>
  );
};

export default NFTManagement;
