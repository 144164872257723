import { TableCell, TableRow, Typography } from "@mui/material";
import { fontStyle } from "../../../../../../theme/Style";
import CommonTable, { useVisibleRows } from "../../../../../../component/CommonTable";
import { color } from "../../../../../../theme/Color";

interface RowProps {
  row: any;
  index: any;
}

const Row = ({ row, index }: RowProps) => {
  return (
    <>
      <TableRow sx={{ cursor: "pointer" }}>
        <TableCell align="center" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.no}</Typography>
        </TableCell>

        <TableCell align="center" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.userName}</Typography>
        </TableCell>

        <TableCell align="center" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.province}</Typography>
        </TableCell>

        <TableCell align="center" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.scooterName}</Typography>
        </TableCell>

        <TableCell align="center" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.scooterSN}</Typography>
        </TableCell>

        <TableCell align="center" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.mileage}</Typography>
        </TableCell>

        <TableCell align="center" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.batteryPowerUsage}</Typography>
        </TableCell>

        <TableCell align="center" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.baselineEmissions}</Typography>
        </TableCell>
        <TableCell align="center" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.GHGReductions}</Typography>
        </TableCell>
        <TableCell align="center" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.registrationDate}</Typography>
        </TableCell>
      </TableRow>
    </>
  );
};

interface GHGReductionScooterTabTableProps {
  rows?: any;
  docCount?: number;
  rowsPerPage?: any;
  pageCount?: any;
  page?: any;
  setPage?: any;
  setRowsPerPage?: any;
}

const GHGReductionScooterTabTable = ({ rows, docCount, rowsPerPage, pageCount, page, setPage, setRowsPerPage }: GHGReductionScooterTabTableProps) => {
  const headCells = [
    {
      id: "no",
      align: "center",
      disablePadding: false,
      label: "No",
    },
    {
      id: "uuid",
      align: "left",
      disablePadding: false,
      label: "User UUID",
    },
    {
      id: "province",
      align: "left",
      disablePadding: false,
      label: "City/State/Province",
    },
    {
      id: "scooterName",
      align: "left",
      disablePadding: false,
      label: "Scooter Name",
    },
    {
      id: "scooterSN",
      align: "left",
      disablePadding: false,
      label: "Scooter S/N",
    },
    {
      id: "mileage",
      align: "left",
      disablePadding: false,
      label: "Mileage",
    },
    {
      id: "batteryPowerUsage",
      align: "left",
      disablePadding: false,
      label: "Battery Power Usage",
    },
    {
      id: "baselineEmissions",
      align: "left",
      disablePadding: false,
      label: "Baseline Emissions",
    },

    {
      id: "scooterEmissions",
      align: "left",
      disablePadding: false,
      label: "Scooter Emissions",
    },

    {
      id: "GHGReductions",
      align: "left",
      disablePadding: false,
      label: "GHG Reductionss",
    },
    {
      id: "registrationDate",
      align: "left",
      disablePadding: false,
      label: "Registration Date",
    },
  ];

  const { order, orderBy, handleRequestSort, handleChangePage, visibleRows } = useVisibleRows(rows, rowsPerPage, setPage, page);

  return (
    <CommonTable
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      page={page}
      docCount={docCount}
      pageCount={pageCount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      handleRequestSort={handleRequestSort}
      handleChangePage={handleChangePage}
      setPage={setPage}
    >
      {visibleRows?.length == 0 ? (
        <TableRow sx={{ px: " 4px", height: "1px" }}>
          <TableCell
            colSpan={headCells?.length}
            align="center"
            sx={{
              minWidth: "1032px",
              px: "13px",
              py: "12px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontStyle: fontStyle.regularS,
                color: color.gray600,
              }}
            >
              No Data
            </Typography>
          </TableCell>
        </TableRow>
      ) : (
        visibleRows.map((row: any, index: any) => <Row row={row} index={index} key={index} />)
      )}
    </CommonTable>
  );
};

export default GHGReductionScooterTabTable;
