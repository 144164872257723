import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Divider,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import { CsDetailsSideEffect } from "../model/CsDetailsSideEffect";
import { CsDetailsUiEvent } from "../model/CsDetailsUiEvent";
import { CsDetailsUiState } from "../model/CsDetailsUiState";
import { ICON_INDETERMINATE } from "../../../constants/imagePath";
import { Height } from "@mui/icons-material";

function priceRow(count: number, unit: number) {
  return count * unit;
}

function createRow(desc: string, count: number, unit: number) {
  const price = priceRow(count, unit);
  return { desc, count, unit, price };
}

interface Row {
  desc: string;
  qty: number;
  unit: number;
  price: number;
}

function subtotal(items: readonly Row[]) {
  return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
}

interface SpanningTableProps {
  uiState: CsDetailsUiState;
  uiEvent: CsDetailsUiEvent;
  sideEffect: CsDetailsSideEffect;
}

export default function SpanningTable({
  uiState,
  uiEvent,
  sideEffect,
}: SpanningTableProps) {
  const [isComposing, setIsComposing] = useState(false);
  const inputPropsStyle = {
    min: 0,
    maxLength: 10,
    style: {
      textAlign: "right" as "right",
      padding: "14px 16px",
    },
  };
  return (
    <Box sx={{mt:"16px"}}>
      <TableContainer component={Paper} elevation={0}>
        <Table sx={{ minWidth: 700, }} aria-label="spanning table">
          <TableHead sx={{
            "& .MuiTableCell-root": {
              border: "none",
              }, }}>
            <TableRow>
              <TableCell sx={{ width: "35%", padding:"0px" }} align="left">
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Name
                </Typography>
              </TableCell>
              <TableCell sx={{ width: "15%", padding:"0px 6px 0px 12px" }} align="left">
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Count
                </Typography>
              </TableCell>
              <TableCell sx={{ width: "25%", padding:"0px 12px 0px 6px" }} align="left">
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Price
                </Typography>
              </TableCell>
              <TableCell sx={{ width: "25%", padding: "0px" }} align="left">
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Amount
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            id="test"
          >
            {uiState?.status === "Receipt" && uiState?.repairFee?.map((row: any, index) => (
              <TableRow
              key={index}
                sx={{
                  ".MuiTableCell-root": {
                    border: 0,
                    paddingTop: 0.5,
                    paddingBottom: 0.5,
                  },
                }}
              >
                <TableCell sx={{padding:"0px"}}>
                  <TextField
                    sx={{
                      borderRadius: "10px",
                      width: "100%",
                    }}
                    inputProps={{
                      style:{
                        padding: "14px 16px",
                      }
                    }}
                    value={row?.name}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    onChange={(event) => {
                      uiEvent.onChangeRepairFeeLineName(event.target.value, index);
                    }}
                  />
                </TableCell>
                <TableCell align="right" sx={{padding:"8px 6px 8px 12px"}}>
                  <TextField
                    sx={{
                      borderRadius: "10px",
                      width: "100%",
                    }}
                    inputProps={{
                      ...inputPropsStyle,
                      min: 0,
                      maxLength: 3,
                      pattern: "/^[0-9]*$",
                    }}
                    value={row?.count}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    onChange={(event) => {
                      if (isComposing) {                        
                        return;
                      }                    
                      const input = event.target.value;                        
                      uiEvent.onChangeRepairFeeLineCount(input, index);
                    }}
                    onKeyDown={(event) => {
                      if(Number(event.key) || event.key == "."){
                        setIsComposing(false);
                      }                      
                    }}
                    onCompositionStart={(event) => {                                           
                      setIsComposing(true);
                    }}
                    onCompositionEnd={(event) => {                        
                      setIsComposing(false);                                                               
                    }}
                  />
                </TableCell>
                <TableCell align="right" sx={{padding:"8px 12px 8px 6px"}}>
                  <TextField
                    sx={{
                      borderRadius: "10px",
                      width: "100%",
                    }}
                    inputProps={inputPropsStyle}
                      value={row?.price}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                      onChange={(event) => {
                        if (isComposing) {                        
                          return;
                        }                    
                        let input = event.target.value;      
                        input = input.replace(/^0+/, ''); // 정규식으로 앞의 0을 제거                  
                        if (input.includes(".")) {
                          const parts = input.split(".");
                          input = parts[0] + "." + parts[1].slice(0, 2); // 소수점 둘째 자리까지만 허용
                        }
                        uiEvent.onChangeRepairFeeLinePrice(input, index);
                        console.log(input)
                      }}
                      onKeyDown={(event) => {
                        if(Number(event.key) || event.key == "."){
                          setIsComposing(false);
                        }                      
                      }}
                      onCompositionStart={(event) => {                                           
                        setIsComposing(true);
                      }}
                      onCompositionEnd={(event) => {                        
                        setIsComposing(false);                                                               
                      }}
                    />
                  </TableCell>
                  <TableCell align="right" sx={{padding: "0px"}}>
                   <Box sx={{
                    display:"flex",
                    flexDirection:"row-reverse",
                    height: "100%",
                    border:"1px solid",
                    borderRadius: "10px",
                    borderColor: color.gray300,
                    px:"16px",
                    py:"14px"
                   }}>
                    <Typography>
                    {row?.amount}
                    </Typography>
                   </Box>
                  </TableCell>
                  <TableCell sx={{ padding:"0px", paddingLeft:"12px" }}>
                    <IconButton
                      sx={{
                        backgroundColor: "#53515A",
                        color: "white",
                        ":hover": { backgroundColor: "#8E8E8E" },
                        height:"20px",
                        width:"20px"
                      }}
                      onClick={() => {
                        uiEvent.onRemoveRepairFeeRow(index);
                      }}
                    >
                    <img
                    src={ICON_INDETERMINATE}
                    style={{ width: "16px", height: "16px" }}
                    ></img>
                    </IconButton>
                    </TableCell>
                  
                </TableRow>
              ))}
            {uiState?.status === "Complete" && uiState?.repairFee?.map((row: any) => (
              <TableRow
                key={row.name}
                sx={{
                  ".MuiTableCell-root": {
                    border: 0,
                    paddingTop: 0.5,
                    paddingBottom: 0.5,
                  },
                }}
              >
                <TableCell sx={{padding:"0px"}}>
                  <TextField
                    disabled={true}
                    sx={{
                      backgroundColor: `${color.disableColor}`,
                      borderRadius: "10px",
                      width: "100%",
                    }}
                    inputProps={{
                      style:{
                        padding: "14px 16px",
                      }
                    }}
                    value={row.name}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </TableCell>
                <TableCell align="right" sx={{padding:"8px 6px 8px 12px"}}>
                  <TextField
                    disabled={true}
                    sx={{
                      backgroundColor: `${color.disableColor}`,
                      borderRadius: "10px",
                      width: "100%",
                    }}
                    inputProps={{
                      ...inputPropsStyle,
                      min: 0,
                      maxLength: 3,
                      pattern: "/^[0-9]*$",
                    }}
                    value={row.count}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </TableCell>
                <TableCell align="right" sx={{padding:"8px 12px 8px 6px"}}>
                  <TextField
                    disabled={true}
                    sx={{
                      backgroundColor: `${color.disableColor}`,
                      borderRadius: "10px",
                      width: "100%",
                    }}
                    inputProps={inputPropsStyle}
                    value={(row?.price).toLocaleString("en-US",{maximumFractionDigits:2})}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </TableCell>
                <TableCell align="right" sx={{padding: "0px"}}>
                  <TextField
                    disabled={true}
                    sx={{
                      backgroundColor: `${color.disableColor}`,
                      borderRadius: "10px",
                      width: "100%",
                    }}
                    inputProps={inputPropsStyle}
                    value={(row?.amount).toLocaleString("en-US", {maximumFractionDigits:2})}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {uiState?.status === "Receipt" && (
        <Box sx={{
          display: "flex",
          justifyContent: "center",
          alignItems:"center",
          height: "44px",
          mt: "12px",
          border:"1px dashed",
          borderRadius:"10px",
          borderColor: color.primary500,
          cursor:"pointer",
          gap: "4px",
        }}
        onClick={() => {
          uiEvent.onAddNewRepairFeeRow();
        }}>

          <IconButton
            sx={{
              backgroundColor: color.primary500,
              color: "white",
              ":hover": { backgroundColor: color.primary400 },
              height:"20px",
              width:"20px",
            }}
          >
            <AddIcon />
          </IconButton>
           <Typography fontStyle={fontStyle.regularS} color={color.primary500}>
            Add Product
            </Typography>
        </Box>
      )}
      <Box sx={{
        mt: "40px",
        mb: "40px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}>
        <Typography fontStyle={fontStyle.semiboldM}>
          Total Amount
        </Typography>
        <Typography fontStyle={fontStyle.displayM} color={color.primary500}>
          $ {(uiState?.totalFee).toLocaleString("en-US", {maximumFractionDigits: 2})}
        </Typography>
      </Box>
    </Box>
  );
};
