import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Box, Card, Grid, IconButton } from "@mui/material";
import CommonList from "../../../../../../../component/CommonList";
import { CommonWebHeaderButton } from "../../../../../../../component/CommonWebHeaderButton";

import VerticalSpacer, { HorizontalSpacer } from "../../../../../../../component/Spacer";
import { ICON_TRASH } from "../../../../../../../constants/imagePath";
import { AdminType } from "../model/ProductData";
import { ProductionDetailsCardPossible } from "./ProductionDetailsCardPossible";
import { ProductionDetailsCardImpossible } from "./ProductionDetailsCardImpossible";
import { ProductionDetailsDateCardPossible } from "./ProductionDetailsDateCardPossible";
import { ProductionDetailsDateCardImpossible } from "./ProductionDetailsDateCardImpossible";
import { ProductUiEvent } from "../model/ProductUiEvent";
import { ProductUiState } from "../model/ProductUiState";
import CommonDialog from "../../../../../../../component/CommonDialog";
import { color } from "../../../../../../../theme/Color";
import { fontStyle } from "../../../../../../../theme/Style";
import { ApiService } from "../../../../../../../restAPI/ApiService";
import { ADMIN_CONFIG } from "../../../../../../../constants/AdminConfig";

export function ProductionDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const productId = pathname.split("/").pop(); // URL에서 마지막 부분을 추출
  const currentAdminUuid = localStorage.getItem("user_uuid");

  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  useEffect(() => {
    uiEvent.HandleUiStateChange("AdminRole", localStorage.getItem("user_role"));
  }, []);

  const [uiState, setUiState] = useState(new ProductUiState());
  const uiEvent: ProductUiEvent = {
    HandleUiStateChange: (key: keyof ProductUiState, value: any) => {
      setUiState((prevState) => ({
        ...prevState,
        [key]: value, // key에 해당하는 상태를 업데이트
      }));
    },
  };
  useEffect(() => {
    ApiService.WebPost("/pw/aw/material/searchBranch").then((res) => {
      if (res?.data.body) {
        uiEvent.HandleUiStateChange("MadeList", res?.data.body);
      }
    });
  }, []);

  useEffect(() => {
    if (productId) {
      //productId 로 api 질의 필요
      ApiService.WebPost("/pw/aw/material/product/detail", {
        id: productId,
      })
        .then((res) => {
          if (res?.data.body) {
            const detailsData = res?.data.body;
            console.log("detailsData is : ", detailsData);
            uiEvent.HandleUiStateChange("MaterialId", detailsData.materialId);
            uiEvent.HandleUiStateChange("ProductName", detailsData.productName);
            uiEvent.HandleUiStateChange("PartsCode", detailsData.partsCode);
            uiEvent.HandleUiStateChange("Made", detailsData.made);
            const selectData: any = uiState.MadeList.filter((data) => data.name === detailsData.made);
            uiEvent.HandleUiStateChange("MadeId", selectData[0]?.id);
            uiEvent.HandleUiStateChange("SerialNumber", detailsData.serialNumber);
            uiEvent.HandleUiStateChange("SerialNumberCELLPACK", detailsData.serialNumberCellPack);
            uiEvent.HandleUiStateChange("SerialNumberMBMSV240", detailsData.serialNumberMBmsV);
            uiEvent.HandleUiStateChange("SerialNumberFETV240", detailsData.serialNumberFetV);
            uiEvent.HandleUiStateChange("MemoCtSensorHarness", detailsData.memoCtSensorHarness);
            uiEvent.HandleUiStateChange("ImgUrl", detailsData.image);
            uiEvent.HandleUiStateChange("RegistrationDate", detailsData.registrationDate);
            uiEvent.HandleUiStateChange("lastUpdateDate", detailsData.lastUpdateDate);
            uiEvent.HandleUiStateChange("Writer", detailsData.writer);
            uiEvent.HandleUiStateChange("Confirm", detailsData.state);
            if (detailsData.confirmDate) {
              uiEvent.HandleUiStateChange("ConfirmDate", detailsData.confirmDate);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [productId, uiState.MadeList]);

  const HeaderBackButton = () => {
    return (
      <IconButton
        sx={{
          ":hover": { backgroundColor: "#F2F2F2" },
          mb: 0.5,
          mr: 1,
        }}
        onClick={() => {
          setDialogOpen(true);
        }}
      >
        <ArrowBackIosNewIcon fontSize="medium" />
      </IconButton>
    );
  };
  const handleUpdateClick = () => {
    // 에러 상태를 업데이트
    const updatedPartsCodeError = !uiState.PartsCode; // falsy 값 체크
    const updatedMadeError = !uiState.Made || uiState.Made === "This made does not exist";
    const updatedSerialNumberError = !uiState.SerialNumber;
    const updatedSerialNumberCELLPACKError = !uiState.SerialNumberCELLPACK;
    const updatedSerialNumberMBMSV240Error = !uiState.SerialNumberMBMSV240;
    const updatedSerialNumberFETV240Error = !uiState.SerialNumberFETV240;
    const updatedMemoCTSENSORHARNESSError = !uiState.MemoCtSensorHarness;

    // 상태 변경
    uiEvent.HandleUiStateChange("PartsCodeError", updatedPartsCodeError);
    uiEvent.HandleUiStateChange("MadeError", updatedMadeError);
    uiEvent.HandleUiStateChange("SerialNumberError", updatedSerialNumberError);
    uiEvent.HandleUiStateChange("SerialNumberCELLPACKError", updatedSerialNumberCELLPACKError);
    uiEvent.HandleUiStateChange("SerialNumberMBMSV240Error", updatedSerialNumberMBMSV240Error);
    uiEvent.HandleUiStateChange("SerialNumberFETV240Error", updatedSerialNumberFETV240Error);
    uiEvent.HandleUiStateChange("MemoCtSensorHarnessError", updatedMemoCTSENSORHARNESSError);

    // 에러 상태가 업데이트된 후 검증
    if (
      !updatedPartsCodeError &&
      !updatedMadeError &&
      !updatedSerialNumberError &&
      !updatedSerialNumberCELLPACKError &&
      !updatedSerialNumberMBMSV240Error &&
      !updatedSerialNumberFETV240Error &&
      !updatedMemoCTSENSORHARNESSError
    ) {
      const ProductionRegistData = {
        serialNumberMBmsV: uiState.SerialNumberMBMSV240,
        serialNumberFetV: uiState.SerialNumberFETV240,
        serialNumber: uiState.SerialNumber,
        memoCtSensorHarness: uiState.MemoCtSensorHarness,
        made: uiState.MadeId,
        id: productId,
        materialId: uiState.MaterialId,
        serialNumberCellPack: uiState.SerialNumberCELLPACK,
        confirm: uiState.Confirm,
      };
      console.log("ProductionRegistData is : ", ProductionRegistData);
      ApiService.WebPost("/pw/aw/material/product/update", ProductionRegistData).then((res) => {
        if (res?.data.message == "Success") {
          window.location.reload();
        }
      });
    }
  };
  const handleDeletaClick = () => {
    ApiService.WebPost("/pw/aw/material/product/delete", {
      id: productId,
    }).then((res) => {
      if (res?.data.message === "Success") {
        navigate("/productionManagement");
      }
    });
  };
  return (
    <CommonList
      title="Production Details"
      headerBackButton={HeaderBackButton()}
      headerButton={
        uiState.ConfirmDate ? null : uiState.AdminRole == "Lead" || currentAdminUuid == uiState.Writer ? (
          <Box sx={{ display: "flex" }}>
            <CommonWebHeaderButton
              width="90px"
              height="45px"
              buttonContent="Update"
              sx={{ display: "flex", flexDirection: "column" }}
              onClick={handleUpdateClick}
            />
            <HorizontalSpacer width={12} />
            <CommonWebHeaderButton
              buttonContent="Delete"
              sx={{
                backgroundColor: color.white,
                fontStyle: color.error,
                ":hover": {
                  backgroundColor: color.red01,
                },
              }}
              icon={<img src={ICON_TRASH}></img>}
              height="45px"
              textColor={color.error}
              onClick={() => {
                setDeleteDialogOpen(true);
              }}
            />
          </Box>
        ) : null
      }
    >
      <Grid item lg={12}>
        <Card
          sx={{
            borderRadius: "15px",
            padding: "24px",
            paddingBottom: "40px",
            boxShadow: 0,
          }}
        >
          <ProductionDetailsCardPossible uiState={uiState} uiEvent={uiEvent} />
        </Card>
        <VerticalSpacer height={20} />
        <Card
          sx={{
            borderRadius: "15px",
            padding: "24px",
            boxShadow: 0,
          }}
        >
          <ProductionDetailsDateCardPossible uiState={uiState} uiEvent={uiEvent} />
        </Card>
      </Grid>
      <CommonDialog
        textSx={{ fontStyle: fontStyle.regularS, color: color.gray800 }}
        open={dialogOpen}
        handleOk={() => {
          navigate("/productionManagement");
        }}
        handleClose={() => {
          setDialogOpen(false);
        }}
      >
        {ADMIN_CONFIG.MSG.EDIT_STOP_AND_MOVE}
        <br />
        {ADMIN_CONFIG.MSG.EDIT_STOP_NOT_SAVED}
      </CommonDialog>
      <CommonDialog
        textSx={{ fontStyle: fontStyle.regularS, color: color.gray800 }}
        open={deleteDialogOpen}
        handleOk={handleDeletaClick}
        handleClose={() => {
          setDeleteDialogOpen(false);
        }}
      >
        Production properties that you delete can't be recovered. Are you sure you want to delete your production properties?
      </CommonDialog>
    </CommonList>
  );
}
