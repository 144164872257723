import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { GridRowsProp } from "@mui/x-data-grid";
import CommonList from "../../component/CommonList";
import CommonSearch from "../../component/CommonSearch";
import FAQMngGrid from "./section/FAQMngGrid";
import CommonTable, { useVisibleRows } from "../../component/CommonTable";
import FAQMngTable from "./section/FAQMngTable";
import { ApiService } from "../../restAPI/ApiService";
import VerticalSpacer, { HorizontalSpacer } from "../../component/Spacer";
import { ICON_HIDE, ICON_SHOW } from "../../constants/imagePath";
import { fontStyle } from "../../theme/Style";
import { color } from "../../theme/Color";

const FAQMngListPage = () => {
  const navigate = useNavigate();
  // 페이지네이션 기능을 위한 변수
  const [rowsPerPage, setRowsPerPage] = useState(10); // 한 테이블당 보여줄 row 의 갯수
  const [rows, setRows] = useState([]); // 받아온 데이터
  const [page, setPage] = useState<number>(0); // 현재 페이지의 번호
  const [docCount, setDocCount] = useState<number>(0); // 총 row의 수
  const [pageCount, setPageCount] = useState(0); // 테이블 왼쪽 하단에 보여질 페이지의 갯수

  // 검색어 관련 변수
  const [searchContents, setSearchContents] = useState("");

  // 국가관련 변수
  const [countryList, setCountryList] = useState([]);
  const [country, setCountry] = useState("Country");
  const [countryCode, setCountryCode] = useState("");
  const handleCountryChange = (event: SelectChangeEvent) => {
    setCountry(event.target.value[0] as string);
    setCountryCode(event.target.value[1] as string);
  };

  // 카테고리 관련 변수
  const [categoryList, setCategoryList] = useState([]);
  const [category, setCategory] = useState("");
  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };

  // showHide 관련 변수
  const [showHide, setShowHide] = useState("");
  const handleShowHideChange = (event: SelectChangeEvent) => {
    setShowHide(event.target.value as string);
  };

  const HeaderButton = () => {
    return (
      <Button
        sx={{
          height: 40,
          backgroundColor: "#5D38E5",
          color: "white",
          borderRadius: 5,
          width: 110,
          ":hover": {
            backgroundColor: "#A38BFC",
          },
          textTransform: "none",
        }}
        onClick={() => {
          navigate("/FAQMng/FAQCreate");
        }}
      >
        <AddIcon fontSize="small" />
        Create
      </Button>
    );
  };

  // 서버에 select 목록들 가져오기
  useEffect(() => {
    ApiService.WebPost("/pw/aw/contents/getCountry").then((res) => {
      setCountryList(res?.data.body);
    });
    ApiService.WebPost("/pw/aw/contents/faq/getCategory").then((res) => {
      setCategoryList(res?.data.body);
    });
  }, []);

  // 서버에 filter된 리스트들 가져오기
  useEffect(() => {
    console.log(searchContents);
    ApiService.WebPost("/pw/aw/contents/faq/filter", {
      size: rowsPerPage,
      page: page + 1,
      searchContents: searchContents,
      country: countryCode,
      category: category,
      showHide: showHide,
    }).then((res) => {
      setRows(res?.data.body || []);
      setPageCount(Math.ceil(res?.data.docCount / rowsPerPage));
      setDocCount(res?.data.docCount);
    });
  }, [page, rowsPerPage, countryCode, category, showHide]);

  return (
    <CommonList title="FAQ" headerButton={HeaderButton()}>
      <CommonSearch
        placeHolder={"Title, Context"}
        searchContents={searchContents}
        setSearchContents={setSearchContents}
        onKeyPress={() => {
          ApiService.WebPost("/pw/aw/contents/faq/filter", {
            size: rowsPerPage,
            page: page + 1,
            searchContents: searchContents,
            country: countryCode,
            category: category,
            showHide: showHide,
          }).then((res) => {
            setRows(res?.data.body || []);
            setPageCount(Math.ceil(res?.data.docCount / rowsPerPage));
            setDocCount(res?.data.docCount);
          });
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Select
            value={country}
            onChange={handleCountryChange}
            renderValue={(v) => {
              return v?.length ? v : `Country`;
            }}
            sx={{
              width: "auto",
              height: "42px",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&.MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "red",
                },
                "&:hover fieldset": {
                  borderColor: "yellow",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
              },
              bgcolor: "white",
              borderRadius: "10px",
              fontStyle: fontStyle.semiboldXS,
              color: color.gray600,
            }}
          >
            <MenuItem value={["Country", ""]} sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}>
              all
            </MenuItem>
            {countryList.map((data: any, index: any) => {
              return (
                <MenuItem value={[data.label, data.code]} key={index} sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}>
                  {data.label}
                </MenuItem>
              );
            })}
          </Select>
          <HorizontalSpacer width={12} />
          <Select
            value={category}
            onChange={handleCategoryChange}
            displayEmpty
            renderValue={(v) => (v?.length ? v : `Category`)}
            sx={{
              width: "auto",
              height: "42px",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&.MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "red",
                },
                "&:hover fieldset": {
                  borderColor: "yellow",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
              },
              bgcolor: "white",
              borderRadius: "10px",
              fontStyle: fontStyle.semiboldXS,
              color: color.gray600,
            }}
          >
            <MenuItem value={""} sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}>
              all
            </MenuItem>
            {categoryList.map((data: any, index: any) => {
              return (
                <MenuItem value={data} key={index} sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}>
                  {data}
                </MenuItem>
              );
            })}
          </Select>
          <HorizontalSpacer width={12} />
          <Select
            value={showHide}
            onChange={handleShowHideChange}
            displayEmpty
            renderValue={(v) => (v?.length ? v : `Show/Hide`)}
            sx={{
              width: "auto",
              height: "42px",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&.MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "red",
                },
                "&:hover fieldset": {
                  borderColor: "yellow",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
              },
              bgcolor: "white",
              borderRadius: "10px",
              fontStyle: fontStyle.semiboldXS,
              color: color.gray600,
            }}
          >
            <MenuItem value={""} sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}>
              all
            </MenuItem>
            <MenuItem value={"show"} sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}>
              show
            </MenuItem>
            <MenuItem value={"hide"} sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}>
              hide
            </MenuItem>
          </Select>
        </Box>
      </CommonSearch>
      <VerticalSpacer height={16} />
      <FAQMngTable
        mngRows={rows}
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </CommonList>
  );
};

export default FAQMngListPage;
