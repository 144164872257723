import React, { useEffect, useState, useCallback } from "react";
import { Box, CircularProgress } from "@mui/material";
import { color } from "../theme/Color";

const THRESHOLD = 80; // Pull threshold in pixels
const LOADING_DELAY = 1000; // Loading delay in milliseconds

interface PullToRefreshProps {
  onRefresh: () => Promise<void>;
  children: React.ReactNode;
}

const PullToRefresh = ({ onRefresh, children }: PullToRefreshProps) => {
  const [refreshing, setRefreshing] = useState(false);
  const [startY, setStartY] = useState<number | null>(null);
  const [pullDistance, setPullDistance] = useState(0);
  const contentRef = React.useRef<HTMLDivElement>(null);

  const handleTouchStart = useCallback(
    (e: TouchEvent) => {
      if (!refreshing && contentRef.current) {
        const scrollTop = contentRef.current.scrollTop;
        if (scrollTop === 0) {
          setStartY(e.touches[0].clientY);
        }
      }
    },
    [refreshing]
  );

  const handleTouchMove = useCallback(
    (e: TouchEvent) => {
      if (startY === null || refreshing || !contentRef.current) return;

      const currentY = e.touches[0].clientY;
      const distance = Math.max(0, currentY - startY);

      if (distance > 0) {
        setPullDistance(Math.min(distance, THRESHOLD));
        e.preventDefault();
      }
    },
    [startY, refreshing]
  );

  const handleTouchEnd = useCallback(async () => {
    if (pullDistance >= THRESHOLD && !refreshing) {
      setRefreshing(true);

      try {
        await onRefresh();
      } catch (error) {
        console.error("Refresh failed:", error);
      } finally {
        setTimeout(() => {
          setRefreshing(false);
          setPullDistance(0);
          setStartY(null);
        }, LOADING_DELAY);
      }
    } else {
      setPullDistance(0);
      setStartY(null);
    }
  }, [pullDistance, refreshing, onRefresh]);

  useEffect(() => {
    const handleStart = (e: TouchEvent) => handleTouchStart(e);
    const handleMove = (e: TouchEvent) => handleTouchMove(e);
    const handleEnd = () => handleTouchEnd();

    document.addEventListener("touchstart", handleStart);
    document.addEventListener("touchmove", handleMove, { passive: false });
    document.addEventListener("touchend", handleEnd);

    return () => {
      document.removeEventListener("touchstart", handleStart);
      document.removeEventListener("touchmove", handleMove);
      document.removeEventListener("touchend", handleEnd);
    };
  }, [handleTouchStart, handleTouchMove, handleTouchEnd]);

  return (
    <Box position="relative" width="100%" height="calc(100% - 44px)">
      {/* Loading indicator */}
      {refreshing && (
        <Box
          position="absolute"
          top="20px"
          left="50%"
          style={{
            transform: "translateX(-50%)",
            zIndex: 10,
          }}
        >
          <CircularProgress
            size={24}
            sx={{
              color: color.primary500,
            }}
          />
        </Box>
      )}

      {/* Content container */}
      <Box
        ref={contentRef}
        style={{
          transform: `translateY(${pullDistance}px)`,
          transition: "transform 0.2s ease",
          overflowY: "auto",
          height: "100%",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default PullToRefresh;
