import { CardContent, Typography } from "@mui/material";

import { useEffect, useState } from "react";
import { fontStyle } from "../../../../theme/Style";
import VerticalSpacer from "../../../../component/Spacer";
import { ProductBOMTreeTable } from "./ProductBOMTreeTable";
import { HeadCell } from "../../../../component/CommonTable";
import { ProductData } from "../../section/productionmanagement/production/section/model/ProductData";
import { ApiService } from "../../../../restAPI/ApiService";
import { FindInListUiEvent } from "./model/FindInListUiEvent";
import { FindInListUiState } from "./model/FindInListUiState";
import CommonDialog from "../../../../component/CommonDialog";
import { color } from "../../../../theme/Color";

interface ProductBOMTreeProps {
  uiState: FindInListUiState;
  uiEvent: FindInListUiEvent;
}

export function ProductBOMTree({ uiState, uiEvent }: ProductBOMTreeProps) {
  const [searchContents, setSearchContents] = useState("");
  const [docCount, setDocCount] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(6);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (uiState.ProductId) {
      ApiService.WebPost("/pw/aw/material/product/search/detail", {
        id: uiState.ProductId,
      }).then((res) => {
        console.log(res?.data);
        if (res?.data.body) {
          if (res?.data.body.length > 0) {
            setRows(res?.data.body);
          } else {
            setDialogOpen(true);
            uiEvent.HandleUiStateChange("PartsCode", "");
            setRows([]);
          }
        }
      });
    }
  }, [uiState.ProductId]);

  return (
    <CardContent
      sx={{
        width: "100%",
        padding: "0px",
        "&:last-child": {
          paddingBottom: 0,
        },
      }}
    >
      <Typography fontStyle={fontStyle.semiboldL}>Bill of Material Tree</Typography>
      <VerticalSpacer height={16} />
      <ProductBOMTreeTable
        headCells={productBOMTreeHeadCells}
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        productList={rows}
      />
      <CommonDialog
        textSx={{ fontStyle: fontStyle.regularS, color: color.gray800 }}
        open={dialogOpen}
        handleOk={() => {
          setDialogOpen(false);
        }}
        handleClose={() => {
          setDialogOpen(false);
        }}
        cancleButton={false}
      >
        This product requires BOM Tree registration first.
      </CommonDialog>
    </CardContent>
  );
}

const productBOMTreeHeadCells: HeadCell[] = [
  {
    disablePadding: false,
    align: "left",
    id: "category",
    label: "Category",
  },
  {
    disablePadding: false,
    align: "left",
    id: "partsCode",
    label: "Parts Code",
  },
  {
    disablePadding: false,
    align: "left",
    id: "productName",
    label: "Product Name",
  },
  {
    disablePadding: false,
    align: "left",
    id: "spec",
    label: "Spec",
  },
  {
    disablePadding: false,
    align: "left",
    id: "quantities",
    label: "Quantities",
  },
  {
    disablePadding: false,
    align: "left",
    id: "necessary",
    label: "Necessary",
  },
  {
    disablePadding: false,
    align: "left",
    id: "amount",
    label: "Amount",
  },
  {
    disablePadding: false,
    align: "left",
    id: "company",
    label: "Company",
  },
];
