import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import VerticalSpacer from "../../../component/Spacer";
import { MoreProfileEditPageUiEvent } from "../MoreProfileEditPageUiEvent";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import { color } from "../../../theme/Color";
import CommonNationComboBox, {
  NationComboBoxMode,
} from "../../../component/CommonNationComboBox";
import ProvinceSelectBox from "./ProvinceSelectBox";
import { AdditionalInfoUiEvent } from "../../MobileMyRide/additionalInfo/AdditionalInfoUiEvent";

interface EditAddressFieldProps {
  type?: string;
  title?: string;
  country?: string;
  cityCountyDistrict?: string;
  cityDetail?: string;
  countryError?: string;
  cityCountyDistrictError?: string;
  cityDetailError?: string;
  uiEvent?: MoreProfileEditPageUiEvent | AdditionalInfoUiEvent;
}

const EditAddressField = ({
  type,
  title,
  country,
  cityCountyDistrict,
  cityDetail,
  countryError,
  cityCountyDistrictError,
  cityDetailError,
  uiEvent,
}: EditAddressFieldProps) => {
  // 국가
  // 시/군/구
  const addressProvincePlaceholder = LocalizedText("ua_b123_citystate");
  const isProvinceSupported = country
    ? ["Korea, Republic of", "Cambodia", "Vietnam"].includes(country)
    : false;

  const handleCountryChange = (value: string) => {
    uiEvent?.HandleCountryChange(value);
  };

  const handleProvinceChange = (value: string) => {
    uiEvent?.HandleCityCountyDistrictChange(value);
  };

  const handleCityCountyDistrictChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    uiEvent?.HandleCityCountyDistrictChange(event.target.value);
  };

  const handleCityDetailChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    uiEvent?.HandleCityDetailChange(event.target.value);
  };

  const ua_g210_selectcountry = LocalizedText("ua_g210_selectcountry");
  const ua_g210_enterstatecity = LocalizedText("ua_g210_enterstatecity");
  const ua_g210_enteraddress = LocalizedText("ua_g210_enteraddress");

  return (
    <>
      <Typography fontStyle={fontStyle.titleS}>{title}</Typography>
      <Box>
        <CommonNationComboBox
          mode={NationComboBoxMode.ADDRESS}
          error={!!countryError}
          handleChange={handleCountryChange}
          defaultValue={country}
          placeholder={LocalizedText("ua_b123_selectcountry")}
        />
        {countryError === "error" && (
          <>
            <Typography
              fontStyle={fontStyle.bodySr}
              color={"#FF4C4C"}
              sx={{ mt: "8.75px" }}
            >
              {ua_g210_selectcountry}
            </Typography>
          </>
        )}
        <VerticalSpacer height={14} />

        {isProvinceSupported ? (
          <ProvinceSelectBox
            country={country || ""}
            value={cityCountyDistrict}
            onChange={handleProvinceChange}
            error={!!cityCountyDistrictError}
          />
        ) : (
          <TextField
            variant="standard"
            size="medium"
            placeholder={addressProvincePlaceholder}
            value={cityCountyDistrict}
            error={!!cityCountyDistrictError}
            onChange={handleCityCountyDistrictChange}
            inputProps={{
              style: {
                height: "50px",
                padding: "0px 0px",
              },
            }}
            sx={{
              width: "100%",
              flexGrow: 1,
              "& .MuiInput-root": {
                "&:before": {
                  // borderBottomColor: color.gray300, // 기본 테두리 색상
                },
                "&:hover:not(.Mui-disabled):before": {
                  borderBottomColor: color.primary500, // 호버 시 테두리 색상
                },
                "&.Mui-focused:after": {
                  borderBottomColor: color.primary500, // 포커스 시 테두리 색상
                },
                "&:after": {
                  borderBottomColor: color.primary500, // 포커스가 풀린 후 기본 색상
                },
              },
            }}
          />
        )}
        {cityCountyDistrictError === "error" && (
          <>
            <Typography
              fontStyle={fontStyle.bodySr}
              color={"#FF4C4C"}
              sx={{ mt: "8.75px" }}
            >
              {ua_g210_enterstatecity}
            </Typography>
          </>
        )}
        <VerticalSpacer height={14} />
        <TextField
          variant="standard"
          size="medium"
          placeholder={LocalizedText("ua_b123_enterdetail")}
          value={cityDetail}
          error={!!cityDetailError}
          onChange={handleCityDetailChange}
          inputProps={{
            style: {
              height: "50px",
              padding: "0px 0px",
            },
          }}
          sx={{
            width: "100%",
            flexGrow: 1,
            "& .MuiInput-root": {
              "&:before": {
                // borderBottomColor: color.gray300, // 기본 테두리 색상
              },
              "&:hover:not(.Mui-disabled):before": {
                borderBottomColor: color.primary500, // 호버 시 테두리 색상
              },
              "&.Mui-focused:after": {
                borderBottomColor: color.primary500, // 포커스 시 테두리 색상
              },
              "&:after": {
                borderBottomColor: color.primary500, // 포커스가 풀린 후 기본 색상
              },
            },
          }}
        />

        {cityDetailError === "error" && (
          <>
            <Typography
              fontStyle={fontStyle.bodySr}
              color={"#FF4C4C"}
              sx={{ mt: "8.75px" }}
            >
              {ua_g210_enteraddress}
            </Typography>
          </>
        )}
      </Box>
    </>
  );
};

export default EditAddressField;
