import { Box, Button, FormControl, InputAdornment, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import { useEffect, useState } from "react";

import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import { color } from "../../../theme/Color";
import VerticalSpacer, { HorizontalSpacer } from "../../../component/Spacer";
import { ICON_CLOCK } from "../../../constants/appImagePath";
import CommonNationComboBox, { NationComboBoxMode } from "../../../component/CommonNationComboBox";

interface EditAuthPhoneNumberFieldProps {
  type?: string;
  authCode: string;
  setAuthCode: any;
  authCodeError: string;
  timeRemaining: number;
  setTimeRemaining: any;
  localNumberError: string;
  setLocalNumberError: any;
  phoneNumberError: string;
  setPhoneNumberError: any;
  localNumber: string;
  phoneNumber: string;
  handleLocaleNumberChange: any;
  handlePhoneNumberChange: any;
  authButtonClick: boolean;
  setAuthButtonClick: any;
}

const EditAuthPhoneNumberField = ({
  type,
  authCode,
  setAuthCode,
  authCodeError,
  timeRemaining,
  setTimeRemaining,
  localNumberError,
  setLocalNumberError,
  phoneNumberError,
  setPhoneNumberError,
  localNumber,
  phoneNumber,
  handleLocaleNumberChange,
  handlePhoneNumberChange,
  authButtonClick,
  setAuthButtonClick,
}: EditAuthPhoneNumberFieldProps) => {
  const errotText = LocalizedText("ua_g210_enterphonenumber");
  const errotAuthText = LocalizedText("ua_b123_digitcode");
  const ua_b123_receivemessage = LocalizedText("ua_b123_receivemessage");

  const formatTime = (milliseconds: number) => {
    const minutes = Math.floor(milliseconds / (60 * 1000));
    const seconds = Math.floor((milliseconds % (60 * 1000)) / 1000);
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  };
  // 매초마다 카운트다운하는 useEffect 추가
  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (authButtonClick && timeRemaining > 0) {
      interval = setInterval(() => {
        setTimeRemaining((prevTime: any) => Math.max(prevTime - 1000, 0));
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [authButtonClick, timeRemaining]);

  useEffect(() => {
    console.log(formatTime(timeRemaining));
  }, [timeRemaining]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {type === "Phone" && (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "50px",
              alignItems: "center",
            }}
          >
            <FormControl
              sx={{
                minWidth: "70px",
                display: localNumberError ? "flex" : "block",
              }}
              error={localNumberError == "error"}
            >
              {/* <Select
                sx={{
                  height: "50px",
                  minWidth: "70px",
                  bgcolor: "white",
                  "&:hover:not(.Mui-disabled):before": {
                    borderColor: color.primary500, // 호버 시 테두리 색상
                  },
                  "&.Mui-focused:after": {
                    borderColor: color.primary500, // 포커스 시 테두리 색상
                  },
                  "&:after": {
                    borderBottomColor: color.primary500, // 포커스가 풀린 후 기본 색상
                  },
                }}
                variant="standard"
                value={localNumber}
                onChange={handleLocaleNumberChange}
              >
                <MenuItem value="+1">+1</MenuItem>
                <MenuItem value="+82">+82</MenuItem>
                <MenuItem value="+855">+855</MenuItem>
              </Select> */}
              <CommonNationComboBox mode={NationComboBoxMode.PHONE} handleChange={handleLocaleNumberChange} defaultValue={localNumber} />
            </FormControl>
            <TextField
              variant="standard"
              size="medium"
              value={phoneNumber}
              autoComplete="off"
              onChange={handlePhoneNumberChange}
              error={phoneNumberError == "error"}
              inputProps={{
                style: {
                  height: "50px",
                  padding: "0px 0px",
                },
                type: "number", // 숫자만 입력 가능
              }}
              sx={{
                ml: "8px",
                flexGrow: 1,
                "& .MuiInput-root": {
                  "&:before": {
                    // borderBottomColor: color.gray300, // 기본 테두리 색상
                  },
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottomColor: color.primary500, // 호버 시 테두리 색상
                  },
                  "&.Mui-focused:after": {
                    borderBottomColor: color.primary500, // 포커스 시 테두리 색상
                  },
                  "&:after": {
                    borderBottomColor: color.primary500, // 포커스가 풀린 후 기본 색상
                  },
                },
              }}
            />
            <HorizontalSpacer width={4} />
            <Button
              sx={{
                minWidth: "88px",
                height: "36px",
                mt: 1.5,
                color: authButtonClick ? color.gray500 : color.primary500,
                border: 1,
                borderColor: authButtonClick ? color.gray500 : color.primary500,
                fontStyle: fontStyle.titleS,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50px",
                "&:hover": {
                  backgroundColor: "white", // hover 시 배경색
                },
              }}
              onClick={() => {
                if (localNumber == "") {
                  setLocalNumberError("error");
                }
                if (phoneNumber == "") {
                  setPhoneNumberError("error");
                }

                if (localNumber != "" || phoneNumber != "") {
                  if (localNumber != "") {
                    setLocalNumberError("noError");
                  }
                  if (phoneNumber != "") {
                    setPhoneNumberError("noError");
                  }
                  if (localNumber != "" && phoneNumber != "") {
                    console.log("all good");
                    setTimeRemaining(3 * 60 * 1000); // 버튼을 누르면 3분으로 초기화
                    setAuthButtonClick(true);
                  }
                }
              }}
            >
              {authButtonClick ? LocalizedText("ua_b123_resend") : LocalizedText("ua_b123_verify")}
            </Button>
          </Box>
        )}
        {phoneNumberError == "" && localNumberError == "" ? (
          <></>
        ) : phoneNumberError == "noError" && localNumberError == "noError" && authButtonClick == true ? (
          <>
            <TextField
              variant="standard"
              size="medium"
              value={authCode}
              autoComplete="off"
              onChange={(event) => {
                console.log(event.target.value);
                setAuthCode(event.target.value);
              }}
              error={authCodeError == "error"}
              inputProps={{
                style: {
                  height: "50px",
                  padding: "0px 0px",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ mt: 1 }}>
                    <Box
                      sx={{
                        width: "74px",
                        display: "flex",
                        alignItems: "center",
                        // justifyContent: "center",
                      }}
                    >
                      <Box component="img" src={ICON_CLOCK} />
                      <HorizontalSpacer width={4.6} />
                      <Typography fontStyle={fontStyle.subTitleM} color={timeRemaining == 0 ? color.gray600 : color.primary500}>
                        {formatTime(timeRemaining)}
                      </Typography>
                    </Box>
                  </InputAdornment>
                ),
              }}
              sx={{
                mt: "20px",
                width: "100%",
                // flexGrow: 1,
                "& .MuiInput-root": {
                  "&:before": {
                    // borderBottomColor: color.gray300, // 기본 테두리 색상
                  },
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottomColor: color.primary500, // 호버 시 테두리 색상
                  },
                  "&.Mui-focused:after": {
                    borderBottomColor: color.primary500, // 포커스 시 테두리 색상
                  },
                },
              }}
            />
            {authCodeError == "error" && (
              <>
                <Typography fontStyle={fontStyle.bodySr} color={"#FF4C4C"} sx={{ mt: "8.75px" }}>
                  {errotAuthText}
                </Typography>
              </>
            )}
            <VerticalSpacer height={20} />
            <Box
              sx={{
                bgcolor: color.gray50,
                width: "100%",
                borderRadius: "10px",
                color: color.gray800,
                fontStyle: fontStyle.bodyMr,
              }}
            >
              <Box sx={{ px: "16px", py: "12px" }}>{ua_b123_receivemessage}</Box>
            </Box>
          </>
        ) : phoneNumberError == "error" || localNumberError == "error" ? (
          <>
            <Typography fontStyle={fontStyle.bodySr} color={"#FF4C4C"} sx={{ mt: "8.75px" }}>
              {errotText}
            </Typography>
          </>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
};

export default EditAuthPhoneNumberField;
