import { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { CouponManagementUiState } from "../model/CouponManagementUiState";
import { CouponManagementUiEvent } from "../model/CouponManagementUiEvent";
import { color } from "../../../theme/Color";
import { fontStyle } from "../../../theme/Style";
import { ApiService } from "../../../restAPI/ApiService";
import { ICON_EDIT_BLUE, ICON_TRASH } from "../../../constants/imagePath";
import { CommonSelect } from "../../../component/CommonSelect";
import VerticalSpacer from "../../../component/Spacer";
import CommonTextField from "../../../component/CommonTextField";
import { CommonWebHeaderButton } from "../../../component/CommonWebHeaderButton";
import CommonDialog from "../../../component/CommonDialog";
import { ADMIN_CONFIG } from "../../../constants/AdminConfig";

interface CouponManagementCardProps {
  data?: any;
  countryLists: any;
  uiState: CouponManagementUiState;
  uiEvent: CouponManagementUiEvent;
  createCard: boolean;
  handleCancel: () => void;
  countryCodeMap: { [key: string]: string };
  handleEdit: ((id: string) => void) | undefined;
  createState: boolean;
  originalData: any;
  setOriginalData: any;
}

export function CouponManagementCard({
  data,
  uiState,
  uiEvent,
  createCard = false,
  handleCancel,
  handleEdit,
  createState,
  originalData,
  setOriginalData,
  countryCodeMap,
}: CouponManagementCardProps) {
  const [couponData, setCouponData] = useState(data);
  const headerName = data.name;
  const getCountryName = (countryCode: string): string => {
    return countryCodeMap[countryCode as keyof typeof countryCodeMap] || countryCode;
  };
  couponData.country = getCountryName(couponData.country);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [confirmDialogOpen, setconfirmDialogOpen] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);

  useEffect(() => {
    if (couponData) {
      uiEvent.HandleUiStateChange("id", couponData.id);
      uiEvent.HandleUiStateChange("name", couponData.name);
      uiEvent.HandleUiStateChange("country", couponData.country);
      uiEvent.HandleUiStateChange("type", couponData.type);
      uiEvent.HandleUiStateChange("availableDays", couponData.availableDays);
      uiEvent.HandleUiStateChange("description", couponData.description);
    }
  }, [couponData]);

  useEffect(() => {
    setOriginalData(data);
    setCouponData(data);
  }, [data]);

  useEffect(() => {
  }, [couponData]);

  const updateCouponData = (key: string, value: any) => {
    setCouponData((prevState: any) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const HandleDialogCancel = () => {
    setDeleteDialogOpen(false);
    setCancelDialogOpen(false);
    handleCancel();
    setCouponData(originalData);
    window.location.reload();
  };

  const Delete = () => {
    ApiService.WebDelete("/pw/aw/product/coupon/delete", {
      id: couponData.id,
      uuid: localStorage.getItem("user_uuid"),
    }).then((res) => {
      setDeleteDialogOpen(false);
      window.location.reload();
    });
  };

  const hasUnsavedChanges = () => {
    return (
      uiState.name !== "" ||
      uiState.country !== "Select" ||
      uiState.type !== "Select" ||
      uiState.availableDays !== "" ||
      uiState.description !== ""
    );
  };

  const handleEditCard = (id: string) => {
    if (createState && hasUnsavedChanges()) {
      // 입력한 값이 빈 값이나 "Select"가 아니라면 확인창 띄우기
      setconfirmDialogOpen(true);
    } else {
      // 내용이 없으면 바로 수정 모드로 전환
      if (handleEdit) {
        handleEdit(id);
        uiEvent.HandleUiStateChange("editCardId", id);
      }
    }
  };

  const confirmCreateCancel = () => {
    setconfirmDialogOpen(false);
  };

  const confirmCreateCancelOk = () => {
    setconfirmDialogOpen(false);
    createState = false;
    handleEditCard(couponData.id);
  };

  const HandleCreateOk = () => {
    setCreateDialogOpen(false)
    uiEvent.HandleCreateCoupon();
  }
  const HandleCreateCancel = () => {
    setCreateDialogOpen(false)
  }

  
  const HandleUpdateOk = () => {
    setUpdateDialogOpen(false)
    uiEvent.HandleUpdateCoupon();
  }
  const HandleUpdateCancel = () => {
    setUpdateDialogOpen(false)
  }
  return (
    <Grid item lg={4}>
      <Card elevation={0} sx={{ borderRadius: "10px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {createCard && (
            <Typography
              fontStyle={fontStyle.semiboldM}
              color={color.gray600}
              flex={0.8}
              ml="24px"
              mt="20px"
            >
              Create New
            </Typography>
          )}
          {createCard || (
            <CardHeader
              title={headerName}
              titleTypographyProps={{ fontStyle: fontStyle.semiboldM }}
            />
          )}
          {uiState.editCardId != couponData.id && (
            <Box sx={{ display: "flex", m: "16px" }}>
              <IconButton
                onClick={() => {
                  setDeleteDialogOpen(true);
                }}
              >
                <Box
                  component={"img"}
                  src={ICON_TRASH}
                  sx={{ height: "20px" }}
                />
              </IconButton>
              <IconButton onClick={() => handleEditCard(couponData.id)}>
                <Box component={"img"} src={ICON_EDIT_BLUE} />
              </IconButton>
            </Box>
          )}
        </Box>

        <Box sx={{ m: "24px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              fontStyle={fontStyle.boldXS}
              color={color.gray600}
              flex={0.8}
            >
              Name
            </Typography>
            <Box flex={1.2} sx={{ position: "relative" }}>
              <CommonTextField
                value={couponData.name}
                onChange={(event) => {
                  updateCouponData("name", event.target.value);
                  uiEvent.HandleUiStateChange("name", couponData.name);
                }}
                sx={{ height: "40px" }}
                InputProps={{
                  sx: {
                    height: "40px",
                  },
                }}
                disabled={uiState.editCardId != couponData.id}
                error={
                  uiState.editCardId === couponData.id &&
                  uiState.inValidDataCheck.name
                }
              />
              {uiState.editCardId === couponData.id &&
                uiState.inValidDataCheck.name && (
                  <Typography
                    fontStyle={fontStyle.regularXS}
                    color={color.error}
                  >
                    Please enter a name.
                  </Typography>
                )}
            </Box>
          </Box>
          <VerticalSpacer height={24} />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              fontStyle={fontStyle.boldXS}
              color={color.gray600}
              flex={0.8}
            >
              Country
            </Typography>
            <Box flex={1.2} sx={{ position: "relative" }}>
              <CommonSelect
                selectValue={couponData.country}
                menuitems={["Cambodia(KH)", "Vietnam(VN)", "South Korea(KR)"]}
                onChange={(event) => {
                  const selectedCountry = event.target.value;
                  const countryCode = selectedCountry
                    .split("(")[1]
                    .split(")")[0];
                  updateCouponData("country", countryCode);
                  uiEvent.HandleUiStateChange("country", countryCode);
                  console.log(uiState.country)
                }}
                sx={{
                  height: "40px",
                  fontStyle: fontStyle.regularS,
                  color: "black",
                  width: "100%",
                }}
                disabled={uiState.editCardId != couponData.id}
                error={
                  uiState.editCardId === couponData.id &&
                  uiState.inValidDataCheck.country
                }
              />
              {uiState.editCardId === couponData.id &&
                uiState.inValidDataCheck.country && (
                  <Typography
                    fontStyle={fontStyle.regularXS}
                    color={color.error}
                  >
                    Please select country.
                  </Typography>
                )}
            </Box>
          </Box>
          <VerticalSpacer height={24} />
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
            >
              <Typography
                fontStyle={fontStyle.boldXS}
                color={color.gray600}
                flex={0.8}
              >
                Type
              </Typography>
              <Box flex={1.2} sx={{ position: "relative" }}>
                <CommonSelect
                  selectValue={couponData.type}
                  menuitems={["NFT Character", "Welcome Coupon"]}
                  onChange={(event) => {
                    updateCouponData("type", event.target.value);
                    uiEvent.HandleUiStateChange("type", couponData.type);
                  }}
                  sx={{
                    height: "40px",
                    fontStyle: fontStyle.regularS,
                    color: "black",
                    width: "100%",
                  }}
                  disabled={uiState.editCardId != couponData.id}
                  error={
                    uiState.editCardId === couponData.id &&
                    uiState.inValidDataCheck.type
                  }
                />
                {uiState.editCardId === couponData.id &&
                  uiState.inValidDataCheck.type && (
                    <Typography
                      fontStyle={fontStyle.regularXS}
                      color={color.error}
                    >
                      Please select type.
                    </Typography>
                  )}
              </Box>
            </Box>
            <VerticalSpacer height={24} />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                fontStyle={fontStyle.boldXS}
                color={color.gray600}
                flex={0.8}
                sx={{ mt: "8px" }}
              >
                Availability Days
              </Typography>
              <Box sx={{ flex: 1.2 }}>
                <CommonTextField
                  value={couponData.availableDays}
                  onChange={(event) => {
                    updateCouponData("availableDays", event.target.value);
                    uiEvent.HandleUiStateChange(
                      "availableDays",
                      couponData.availableDays
                    );
                  }}
                  sx={{ height: "40px" }}
                  InputProps={{
                    sx: {
                      height: "40px",
                    },
                  }}
                  disabled={uiState.editCardId != couponData.id}
                  error={
                    uiState.editCardId === couponData.id &&
                    uiState.inValidDataCheck.availableDays
                  }
                />
                {uiState.editCardId === couponData.id &&
                  uiState.inValidDataCheck.availableDays && (
                    <Typography
                      fontStyle={fontStyle.regularXS}
                      color={color.error}
                    >
                      Please enter a number of days.
                    </Typography>
                  )}
              </Box>
            </Box>
            <VerticalSpacer height={24} />
            <Box
              sx={{
                display: "flex",
                // alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                fontStyle={fontStyle.boldXS}
                color={color.gray600}
                flex={0.8}
                sx={{ mt: "8px" }}
              >
                Description
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ flex: 1.2 }}>
                  <CommonTextField
                    value={couponData.description}
                    onChange={(event) => {
                      updateCouponData("description", event.target.value);
                      uiEvent.HandleUiStateChange(
                        "description",
                        couponData.description
                      );
                    }}
                    sx={{ height: "40px", width:"160px" }}
                    InputProps={{
                      sx: {
                        minHeight: "60px",
                      },
                    }}
                    multiline
                    disabled={uiState.editCardId != couponData.id}
                  />
                </Box>
                <VerticalSpacer height={20} />
                {uiState.editCardId === couponData.id &&
                  uiState.inValidDataCheck.description && (
                    <Typography
                      fontStyle={fontStyle.regularXS}
                      color={color.error}
                    >
                      Please enter a description.
                    </Typography>
                  )}
              </Box>
            </Box>
            <VerticalSpacer height={50} />
            {uiState.editCardId == couponData.id && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    fontStyle: fontStyle.semiboldS,
                    color: color.gray600,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setCancelDialogOpen(true);
                  }}
                >
                  Cancel
                </Box>
                {createCard || (
                  <CommonWebHeaderButton
                    sx={{ display: "flex", flexDirection: "column" }}
                    buttonContent="Update"
                    onClick={() => {
                      setUpdateDialogOpen(true)
                    }}
                  />
                )}
                {createCard && (
                  <CommonWebHeaderButton
                    sx={{ display: "flex", flexDirection: "column" }}
                    buttonContent="Post"
                    onClick={() => {
                     setCreateDialogOpen(true)
                    }}
                  />
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Card>
      <CommonDialog
        textSx={{ fontStyle: fontStyle.regularS, color: color.gray800 }}
        open={deleteDialogOpen}
        handleOk={Delete}
        handleClose={HandleDialogCancel}
      >
        Are you sure you want to delete the coupon?
        <br />
        It cannot be recovered oncedeleted.
      </CommonDialog>
      <CommonDialog
        textSx={{ fontStyle: fontStyle.regularS, color: color.gray800 }}
        open={cancelDialogOpen}
        handleOk={HandleDialogCancel}
        handleClose={HandleDialogCancel}
      >
        {ADMIN_CONFIG.MSG.EDIT_STOP_AND_MOVE}<br/>
        {ADMIN_CONFIG.MSG.EDIT_STOP_NOT_SAVED}
      </CommonDialog>
      <CommonDialog
        textSx={{ fontStyle: fontStyle.regularS, color: color.gray800 }}
        open={confirmDialogOpen}
        handleOk={confirmCreateCancelOk}
        handleClose={confirmCreateCancel}
      >
        {ADMIN_CONFIG.MSG.EDIT_STOP_AND_MOVE}<br/>
        {ADMIN_CONFIG.MSG.EDIT_STOP_NOT_SAVED}
      </CommonDialog>
      <CommonDialog
        textSx={{ fontStyle: fontStyle.regularS, color: color.gray800 }}
        open={createDialogOpen}
        handleOk={HandleCreateOk}
        handleClose={HandleCreateCancel}
      >
        Would you like to post the coupon?
      </CommonDialog>
      <CommonDialog
        textSx={{ fontStyle: fontStyle.regularS, color: color.gray800 }}
        open={updateDialogOpen}
        handleOk={HandleUpdateOk}
        handleClose={HandleUpdateCancel}
      >
         Would you like to update the coupon?
      </CommonDialog>
    </Grid>
  );
}
