import { NFTDetailInfoResponse } from "../model/NFTDetailInfoResponse";
import { NFTDetailUiState } from "../model/NFTDetailUiState";

export function convertNFTDetailUiModel(
  response: NFTDetailInfoResponse
): NFTDetailUiState {
  return new NFTDetailUiState(
    response.nftCharacterName || "",
    response.distributeDt || 0,
    response.country || "",
    response.rewardOptions?.map((item) => ({
      id: item.compensationId || "",
      name: item.compensationNm || "",
    })) || [],
    response.registerDt || 0,
    response.lastUptDt || 0,
    response.nftList?.map((item) => ({
      level: endsWithNumber(item.nftLevel) || 0,
      levelName: item.nftLevelNm || "",
      image: item.nftImage || undefined,
      maxExp: item.maxExp || "",
      coupon: {
        id: item.compensationId || "",
        name: item.compensationNm || "",
      },
      action: "unchanged",
      id: item.id || "",
    })) || []
  );
}

function endsWithNumber(levelName: string): number | null {
  const regex = /^Level(\d+)$/;
  const match = levelName.match(regex);

  return match ? parseInt(match[1], 10) : null;
}
