import { SetStateAction } from "react";
import { ProductConfirmationListTableRow } from "./ProductConfirmationListTableRow";
import { Box, TableCell, TableRow, Typography } from "@mui/material";
import CommonTable, { HeadCell, useVisibleRows } from "../../../../component/CommonTable";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import { ProductData } from "../../section/productionmanagement/production/section/model/ProductData";

import { FindInListUiState } from "./model/FindInListUiState";
import { FindInListUiEvent } from "./model/FindInListUiEvent";

interface ProductConfirmationListTableProps {
  headCells: HeadCell[];
  rowsPerPage: number;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  pageCount: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  docCount: number;
  productList: any;
  MaterialId: string;
  uiState: FindInListUiState;

  uiEvent: FindInListUiEvent;
}

export function ProductConfirmationListTable({
  headCells,
  rowsPerPage,
  setRowsPerPage,
  docCount,
  pageCount,
  page,
  setPage,
  productList,
  MaterialId,
  uiState,
  uiEvent,
}: ProductConfirmationListTableProps) {
  const { order, orderBy, handleChangePage, handleRequestSort, visibleRows } = useVisibleRows(productList, rowsPerPage || 4, setPage, page || 0);
  const pagedata: any = visibleRows.slice((page || 0) * (rowsPerPage || 4), ((page || 0) + 1) * (rowsPerPage || 4));
  return (
    <Box>
      <CommonTable
        headCells={headCells}
        order={order}
        orderBy={orderBy}
        page={page}
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        setRowsPerPage={setRowsPerPage}
        handleChangePage={handleChangePage}
        handleRequestSort={handleRequestSort}
        setPage={setPage}
      >
        {pagedata?.length == 0 ? (
          <TableRow sx={{ px: " 4px", height: "1px" }}>
            <TableCell
              colSpan={headCells.length}
              align="center"
              sx={{
                px: "4px",
                py: "8px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  fontStyle: fontStyle.regularXS,
                  color: color.gray600,
                }}
              >
                No Data
              </Typography>
            </TableCell>
          </TableRow>
        ) : (
          pagedata.map((data: ProductData) => <ProductConfirmationListTableRow row={data} key={data.partsCode} uiState={uiState} uiEvent={uiEvent} />)
        )}
      </CommonTable>
    </Box>
  );
}
