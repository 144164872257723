import { Grid } from "@mui/material";
import CommonList from "../../../../component/CommonList";
import { InformationCard } from "./component/InformationCard";
import { StatusCard } from "./component/StatusCard";
import VerticalSpacer from "../../../../component/Spacer";
import { WalletDetailUiState } from "./model/WalletDetailUiState";
import { WalletDetailUiEvent } from "./model/WalletDetailUiEvent";
import { TabType } from "./model/TabType";
import CommonDialog from "../../../../component/CommonDialog";
import { ADMIN_CONFIG } from "../../../../constants/AdminConfig";
import { color } from "../../../../theme/Color";
import { fontStyle } from "../../../../theme/Style";

interface WalletDetailPageProps {
  uiState: WalletDetailUiState;
  uiEvent: WalletDetailUiEvent;
  currentTab: TabType;
}

export function WalletDetailPage({
  uiState,
  uiEvent,
  currentTab,
}: WalletDetailPageProps) {
  return (
    <CommonList
      title="Wallet Details"
      headerBackButton
      backbuttonOnclick={uiEvent.backButtonClick}
    >
      <Grid>
        <InformationCard uiState={uiState} uiEvent={uiEvent} />
        <VerticalSpacer height={20} />
        <StatusCard
          uiState={uiState}
          uiEvent={uiEvent}
          currentTab={currentTab}
        />
      </Grid>
      <CommonDialog
        open={uiState.backDialogOpen}
        handleClose={uiEvent.backClose}
        handleOk={uiEvent.backOk}
        textSx={{ fontStyle: fontStyle.regularS, color: color.gray800 }}
      >
        {ADMIN_CONFIG.MSG.EDIT_STOP_AND_MOVE}<br/>
        {ADMIN_CONFIG.MSG.EDIT_STOP_NOT_SAVED}
      </CommonDialog>
    </CommonList>
  );
}
