import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CommonList from "../../../component/CommonList";
import NFTDetailGrid from "./section/NFTDetailGrid";
import { CouponInfo, NFTDetailUiState } from "./model/NFTDetailUiState";
import { NFTDetailUiEvent } from "./model/NFTDetailUiEvent";
import CommonDialog from "../../../component/CommonDialog";
import { ApiService } from "../../../restAPI/ApiService";
import ProgressBar from "../../../component/ProgressBar";
import { NFTDetailInfoResponse } from "./model/NFTDetailInfoResponse";
import { convertNFTDetailUiModel } from "./mapper/NFTModelMapper";
import { ADMIN_CONFIG } from "../../../constants/AdminConfig";
import { color } from "../../../theme/Color";
import { fontStyle } from "../../../theme/Style";
import axios from "axios";

interface UpdateNFTresult {
  body: string;
  result: boolean;
  timestamp: number;
}

const NFTDetail = () => {
  const navigate = useNavigate();
  const [uiState, setUiState] = useState(new NFTDetailUiState());
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [openBackAlert, setOpenBackAlert] = useState(false);
  const [openValidationAlert, setOpenValidationAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const pathname = location.pathname;
  const id = pathname.split("/").pop();

  useEffect(() => {
    ApiService.webPost("/pw/aw/product/nftCoupon/detail", {
      nftCharacterNm: id,
    }).then((res) => {
      setUiState(convertNFTDetailUiModel(res?.data.body));
      uiEvent.handleUiStateChange("nftCharacterName", id);
      console.log(res?.data)
    });
  }, []);

  useEffect(() => {
    const fetchRewardOptions = async () => {
      try {
        const rewardOptions = await getRewardOptions();
        if (rewardOptions == undefined) {
          alert("Failed to get reward options");
          return;
        }
        setUiState((prev) => {
          return { ...prev, rewardOptions: rewardOptions };
        });
      } catch (e) {
        alert("Failed to get reward options: " + e);
      }
    };

    if (uiState.country != "") {
      fetchRewardOptions();
    }
  }, [uiState.country]);

  async function getRewardOptions(): Promise<CouponInfo[] | undefined> {
    const url = "/pw/aw/product/coupon/nft";
    const requestBody = {
      country: uiState.country,
    };

    try {
      const res = await ApiService.webPost(url, requestBody);
      console.log("CouponInfo: ", res.data.body);
      if (res.data && res.data.body) {
        const rewardOptions = res.data.body;
        return rewardOptions;
      } else {
        throw new Error("Failed to get reward options");
      }
    } catch (e) {
      throw e;
    }
  }

  async function updateNFT(): Promise<UpdateNFTresult | undefined> {
    const nftList = uiState.levelInfos.map((level) => ({
      nftLevel: `Level${level.level}`,
      id: level.id,
      maxExp: level.maxExp,
      action: level.action,
      compensation: level.coupon.id,
      nftLevelNm: level.levelName,
      writer: localStorage.getItem("user_uuid") || "",
      ...(level.action === "unchanged" && { nftImage: level.image }),
    }));

    const requestBody = {
      nftList: nftList,
      distributeDt: uiState.distributeDate,
      nftCharacterNm: uiState.nftCharacterName,
      country: uiState.country,
    };

    const nftImages = uiState.levelInfos
      .filter((level) => level.action === "update" && level.image) // action이 "update"인 항목만 필터링
      .map((level) => level.image);

    const formData = new FormData();
    formData.append(
      "body",
      new Blob([JSON.stringify(requestBody)], { type: "application/json" })
    );

    nftImages.forEach((image, index) => {
      if (image) {
        formData.append("nftImages", image, `NFT_image_level${index + 1}.jpg`);
      }
    });

    setLoading(true);
    try {
      const url = "/pw/aw/product/nftCoupon/update";
      const res = await ApiService.webPost(url, formData);
      if (typeof res.data === "object" && Object.keys(res.data).length === 0)
        return undefined;
      console.log("res", res);
      return res.data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.error("서버 에러 응답: ", e.response?.data);
      }
      console.error("에러 발생: ", e);
      throw e;
    }
  }

  const handleUpdateNFT = async () => {
    setUpdateDialogOpen(false);
    try {
      // NFT 수정
      const result = await updateNFT();
      if (result?.result) {
        navigate("/NFTManagement");
      } else {
        if (result?.body === "Existed NftCharacterName") {
          setOpenValidationAlert(true);
        } else {
          alert("Failed to update NFT");
        }
      }
    } catch (e) {
      alert(`Failed to update NFT - ${e}`);
    } finally {
      setLoading(false);
    }
  };

  const uiEvent: NFTDetailUiEvent = {
    handleUiStateChange(key, value) {
      setUiState((prev) => {
        return { ...prev, [key]: value };
      });
    },

    handleLevelInfoChange(levelIndex, key, value) {
      setUiState((prev) => {
        const updatedLevels = [...prev.levelInfos];
        updatedLevels[levelIndex] = {
          ...updatedLevels[levelIndex],
          [key]: value,
        };
        return { ...prev, levelInfos: updatedLevels };
      });
    },

    validateFields(): boolean {
      const newErrors = {
        nftCharacterNameError: uiState.nftCharacterName == "",
        distributeDateError:
          uiState.distributeDate == 0 || uiState.distributeDate == null,
        countryError: uiState.country == "",
        levelInfosError: uiState.levelInfos.map(
          (level: any, index: number) => ({
            levelNameError: level.levelName == "",
            levelImageError: level.image == undefined,
            maxExpError:
              index < uiState.levelInfos.length - 1 && level.maxExp == 0,
            rewardError:
              index < uiState.levelInfos.length - 1 && level.coupon.id == "",
          })
        ),
      };

      setUiState((prev) => {
        return { ...prev, errors: newErrors };
      });

      if (
        newErrors.nftCharacterNameError ||
        newErrors.distributeDateError ||
        newErrors.countryError ||
        newErrors.levelInfosError.some(
          (levelError: {
            levelNameError: boolean;
            levelImageError: boolean;
            maxExpError: boolean;
            rewardError: boolean;
          }) => Object.values(levelError).includes(true)
        )
      ) {
        return false;
      }

      return true;
    },

    LevelInfoReset() {
      const resetReward = uiState.levelInfos.map((levelInfo) => ({
        ...levelInfo,
        coupon: "",
      }));
      uiEvent.handleUiStateChange("levelInfos", resetReward);
    },
  };

  const handleClickBackButton = () => {
    setOpenBackAlert(true);
  };

  const handleUpdate = () => {
    if (uiEvent.validateFields()) {
      setUpdateDialogOpen(true);
    }
  };

  const HeaderButton = () => {
    return (
      <Button
        sx={{
          height: 40,
          backgroundColor: color.primary500,
          color: "white",
          borderRadius: 5,
          width: "85px",
          px: "20px",
          textTransform: "none",
          fontStyle: fontStyle.semiboldS,
          ":hover": {
            backgroundColor: "#A38BFC",
          },
        }}
        onClick={() => {
          handleUpdate();
        }}
      >
        Update
      </Button>
    );
  };
  const HeaderBackButton = () => {
    return (
      <IconButton
        sx={{
          ":hover": { backgroundColor: "#F2F2F2" },
          mb: 0.5,
          mr: 1,
        }}
        onClick={() => {
          handleClickBackButton();
        }}
      >
        <ArrowBackIosNewIcon fontSize="medium" />
      </IconButton>
    );
  };

  if (loading) {
    return (
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          overflow: "hidden",
          zIndex: 1300,
        }}
      >
        <ProgressBar />
      </Box>
    );
  }

  return (
    <CommonList
      title="NFT Details"
      headerButton={HeaderButton()}
      headerBackButton={HeaderBackButton()}
    >
      <NFTDetailGrid uiState={uiState} uiEvent={uiEvent} />
      <CommonDialog
        open={openBackAlert}
        handleClose={() => setOpenBackAlert(false)}
        handleOk={() => navigate("/NFTManagement")}
      >
        {ADMIN_CONFIG.MSG.EDIT_STOP_AND_MOVE}<br/>
        {ADMIN_CONFIG.MSG.EDIT_STOP_NOT_SAVED}
      </CommonDialog>
      <CommonDialog
        open={updateDialogOpen}
        handleClose={() => setUpdateDialogOpen(false)}
        handleOk={handleUpdateNFT}
      >
        Do you want to update NFT?
      </CommonDialog>
      <CommonDialog
        open={openValidationAlert}
        // handleClose={() => setOpenValidationAlert(false)}
        handleOk={() => setOpenValidationAlert(false)}
        cancleButton={false}
      >
        This character name is already registered.
      </CommonDialog>
    </CommonList>
  );
};

export default NFTDetail;
