import { Box, Typography } from "@mui/material";
import { color } from "../../../theme/Color";
import { fontStyle } from "../../../theme/Style";
import BatteryPercentageIcon from "./BatteryPercentageIcon";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";

interface BatterySectionProps {
  batteryPercentage: number;
  estimatedRange: number;
  ridingTime: number;
}

const BatterySection = ({
  batteryPercentage,
  estimatedRange,
  ridingTime,
}: BatterySectionProps) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        gap: "12px",
      }}
    >
      <Box
        sx={{
          bgcolor: "white",
          borderRadius: "10px",
          p: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        <Typography fontStyle={fontStyle.titleM} color={color.default}>
          {LocalizedText("ua_f211_battery")}
        </Typography>
        <BatteryPercentageIcon percentage={batteryPercentage} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          flex: 1,
        }}
      >
        <Box
          sx={{
            bgcolor: "white",
            borderRadius: "10px",
            p: "16px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            flex: 1,
          }}
        >
          <Typography fontStyle={fontStyle.titleM} color={color.default}>
            {LocalizedText("ua_h100_estimated_range")}
          </Typography>
          <Box sx={{ display: "flex", gap: "4px", alignItems: "flex-end" }}>
            <Typography fontStyle={fontStyle.subTitleXXL} color={color.gray900}>
              {estimatedRange}
            </Typography>
            <Typography fontStyle={fontStyle.titleL} color={color.gray900}>
              km
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            bgcolor: "white",
            borderRadius: "10px",
            p: "16px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            flex: 1,
          }}
        >
          <Typography fontStyle={fontStyle.titleM} color={color.default}>
            {LocalizedText("ua_h100_average_speed")}
          </Typography>
          <Box sx={{ display: "flex", gap: "4px", alignItems: "flex-end" }}>
            <Typography fontStyle={fontStyle.subTitleXXL} color={color.gray900}>
              {ridingTime}
            </Typography>
            <Typography fontStyle={fontStyle.titleL} color={color.gray900}>
              minute
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BatterySection;
