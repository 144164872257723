import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAndroidBackHandler from "../../../customHook/useAndroidBackHandler";
import { PayQRUiEvent } from "./PayQRUiEvent";
import { PayQRUiState } from "./PayQRUiState";
import { PayQRSideEffect } from "./PayQRSideEffect";
import PayQRRender from "./PayQRRender";
import ProgressBar from "../../../component/ProgressBar";
import { ApiService } from "../../../restAPI/ApiService";
import SimpleDialog from "../../../component/dialog/SimpleDialog";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import {
  useTemporalErrorToast,
  useToast,
} from "../../../component/toast/ConcurrentToastManager";
import { ToastDuration, ToastType } from "../../../component/toast/ToastModel";
import axios from "axios";

function onImageDownloadSuccess(): void {
  const event = new CustomEvent("onImageDownloadSuccess");
  window.dispatchEvent(event);
}

(window as any).onImageDownloadSuccess = onImageDownloadSuccess;

const PayQRPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [uiState, setUiState] = useState(new PayQRUiState());
  const [isError, setIsError] = useState(false);
  const showAlert = useTemporalErrorToast();
  const { showToast } = useToast();

  const errorMessage = LocalizedText("ua_g411_alert_qrerror");
  const tryAgainString = LocalizedText("ua_a300_alert_error_button");
  const cancelString = LocalizedText("common_alert_cancel_button");

  useAndroidBackHandler(() => {
    window.history.back();
  });

  useEffect(() => {
    const handleDownloadSuccess = (event: Event) => {
      const customEvent = event as CustomEvent;
      uiEventHandler.OpenToast();
    };

    window.addEventListener("onImageDownloadSuccess", handleDownloadSuccess);
    return () => {
      window.removeEventListener(
        "onImageDownloadSuccess",
        handleDownloadSuccess
      );
    };
  }, []);

  useEffect(() => {
    const locationData = location.state as {
      productId: string;
      userUUID: string;
    };

    setUiState((prev) => ({
      ...prev,
      productId: locationData.productId,
    }));

    (async () => {
      try {
        if (locationData) {
          updateQRCode(
            localStorage.getItem("uuid") || "",
            locationData.productId ?? ""
          );
        }
      } catch (error) {
        console.error("Failed to update QR code :", error);
      }
    })();
  }, [location.state]);

  const uiEventHandler: PayQRUiEvent = {
    OpenToast: function (): void {
      setUiState((prev) => ({
        ...prev,
        isToastOpen: true,
      }));
    },

    CloseToast: function (): void {
      setUiState((prev) => ({
        ...prev,
        isToastOpen: false,
      }));
    },
  };

  const sideEffectHandlers: PayQRSideEffect = {
    navigate: function (target: string, options?: { state: any }): void {
      console.log("navigate", target);
      navigate(target, options);
    },
    downloadQrCode: function (): void {
      axios
        .get(uiState.qrcodeUrl, { responseType: "blob" })
        .then((response) => {
          const blob = response.data;
          return convertBlobToBase64(blob);
        })
        .then((qr) => {
          if (window.Android) {
            window.Android.downloadQr(qr);
          } else if (window.webkit) {
            window.webkit.messageHandlers.downloadQr.postMessage(qr);
          } else {
            console.log("Web Download QR - ", qr);
          }
          
        })
        .catch((error) => {
          showToast(
            "Failed to download QR code. - 402",
            ToastDuration.LONG,
            ToastType.error
          );
          console.error("Failed to download QR code :", error);
        });
    },
  };

  // 멤버십, 바우처 구매 QR 생성

  async function getQRCode(uuid: string, productId: string) {
    const requestBody = {
      id: productId,
      uuid: uuid,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    try {
      const response = await ApiService.post(
        "/pw/ua/infoplus/createQrFEV",
        requestBody,
        undefined,
        "blob"
      );

      if (!response || !response.data) {
        throw new Error("Failed to get QR code: No data.");
      }

      const qrcodeUrl = URL.createObjectURL(response.data);
      const headers = response.headers;

      if (!headers?.name || !headers?.description || !headers?.price) {
        throw new Error("Failed to get QR code: Missing header data.");
      }

      return {
        qrcodeUrl,
        productName: headers.name,
        productDescription: headers.description,
        productPrice: headers.price,
      };
    } catch (error) {
      throw error;
    }
  }

  async function updateQRCode(uuid: string, productId: string) {
    try {
      const qrCodeData = await getQRCode(uuid, productId);

      if (!qrCodeData) {
        throw new Error("QR code data is undefined.");
      }
      const { qrcodeUrl, productName, productDescription, productPrice } =
        qrCodeData;

      setUiState((prev) => ({
        ...prev,
        qrcodeUrl,
        productName,
        productDescription,
        productPrice,
      }));
    } catch (error) {
      showToast(
        "Failed to update QR code. - 403",
        ToastDuration.LONG,
        ToastType.error
      );
      console.error("Failed to update QR code :", error);
      setIsError(true);
    } finally {
      setLoading(false);
    }
  }

  function convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Data = reader.result as string;

        const base64String = base64Data ? base64Data.split(",")[1] : ""; // remove the prefix(data:image/png;base64,)
        resolve(base64String);
      };
      reader.onerror = (error) => {
        showToast(
          "Failed to convert blob to base64 - 401",
          ToastDuration.LONG,
          ToastType.error
        );
        console.error("Failed to convert blob to base64 :", error);
      };

      reader.readAsDataURL(blob);
    });
  }

  if (loading) {
    return <ProgressBar />;
  }

  if (isError) {
    return (
      <>
        <SimpleDialog
          children={errorMessage}
          isOpen={isError}
          positiveText={tryAgainString}
          nagativeText={cancelString}
          onPositive={() => {
            setLoading(true);
            updateQRCode(localStorage.getItem("uuid") || "", uiState.productId);
            setIsError(false);
          }}
          onNagative={() => {
            window.history.back();
          }}
          onClose={() => {
            window.history.back();
          }}
        />
      </>
    );
  }

  return (
    <>
      <PayQRRender
        uiState={uiState}
        uiEvent={uiEventHandler}
        sideEffect={sideEffectHandlers}
      />
    </>
  );
};

export default PayQRPage;
