import { Children } from "react";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import DnsOutlinedIcon from "@mui/icons-material/DnsOutlined";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import MiscellaneousServicesOutlinedIcon from "@mui/icons-material/MiscellaneousServicesOutlined";
import { Box } from "@mui/material";
import {
  ICON_BLOCKCHAIN,
  ICON_CARBON,
  ICON_CHAT,
  ICON_CONTENTS,
  ICON_PACKAGE,
  ICON_PROFILE,
  ICON_STATISTICS,
  ICON_SYSTEM,
  ICON_CASH,
} from "../constants/imagePath";
import { ADMIN_CONFIG } from "../constants/AdminConfig";

export interface MenuItem {
  title: string;
  key: string;
  type: string;
  url: string;
  show: boolean;
  Icon?: JSX.Element;
  Children?: MenuItem[];
}

const ItemIcon = () => {
  return (
    <FiberManualRecordOutlinedIcon
      sx={{
        width: 12,
        height: 12,
        ml: 1.5,
      }}
    />
  );
};

export const MenuList: { [key: string]: MenuItem } = {
  Home: {
    title: "",
    key: "",
    type: ADMIN_CONFIG.MENU_TYPE.GROUP,
    url: "",
    show: true,
    Children: [
      {
        // 통계
        title: ADMIN_CONFIG.MENU_STATISTICS.TITLE,
        key: ADMIN_CONFIG.MENU_STATISTICS.KEY,
        type: ADMIN_CONFIG.MENU_TYPE.COLLAPSE,
        url: ADMIN_CONFIG.MENU_STATISTICS.URL,
        show: ADMIN_CONFIG.MENU_STATISTICS.SHOW,
        Icon: <Box component="img" src={ICON_STATISTICS} />,
        Children: [
          {
            title: ADMIN_CONFIG.MENU_STATISTICS_STATISTICS.TITLE,
            key: ADMIN_CONFIG.MENU_STATISTICS_STATISTICS.KEY,
            type: ADMIN_CONFIG.MENU_TYPE.ITEM,
            url: ADMIN_CONFIG.MENU_STATISTICS_STATISTICS.URL,
            show: ADMIN_CONFIG.MENU_STATISTICS_STATISTICS.SHOW,
            Icon: ItemIcon(),
          },
        ],
      },
      {
        // 사용자 관리
        title: ADMIN_CONFIG.MENU_USER_MANAGEMENT.TITLE,
        key: ADMIN_CONFIG.MENU_USER_MANAGEMENT.KEY,
        type: ADMIN_CONFIG.MENU_TYPE.COLLAPSE,
        url: ADMIN_CONFIG.MENU_USER_MANAGEMENT.URL,
        show: ADMIN_CONFIG.MENU_USER_MANAGEMENT.SHOW,
        Icon: <Box component="img" src={ICON_PROFILE} />,
        Children: [
          {
            // 회원 관리
            title: ADMIN_CONFIG.MENU_USER_MANAGEMENT_MEMBER.TITLE,
            key: ADMIN_CONFIG.MENU_USER_MANAGEMENT_MEMBER.KEY,
            type: ADMIN_CONFIG.MENU_TYPE.ITEM,
            url: ADMIN_CONFIG.MENU_USER_MANAGEMENT_MEMBER.URL,
            show: ADMIN_CONFIG.MENU_USER_MANAGEMENT_MEMBER.SHOW,
            Icon: ItemIcon(),
          },
          {
            // 관리자 관리
            title: ADMIN_CONFIG.MENU_USER_MANAGEMENT_ADMINISTRATOR.TITLE,
            key: ADMIN_CONFIG.MENU_USER_MANAGEMENT_ADMINISTRATOR.KEY,
            type: ADMIN_CONFIG.MENU_TYPE.ITEM,
            url: ADMIN_CONFIG.MENU_USER_MANAGEMENT_ADMINISTRATOR.URL,
            show: ADMIN_CONFIG.MENU_USER_MANAGEMENT_ADMINISTRATOR.SHOW,
            Icon: ItemIcon(),
          },
          {
            // 그룹 관리
            title: ADMIN_CONFIG.MENU_USER_MANAGEMENT_GROUP.TITLE,
            key: ADMIN_CONFIG.MENU_USER_MANAGEMENT_GROUP.KEY,
            type: ADMIN_CONFIG.MENU_TYPE.ITEM,
            url: ADMIN_CONFIG.MENU_USER_MANAGEMENT_GROUP.URL,
            show: ADMIN_CONFIG.MENU_USER_MANAGEMENT_GROUP.SHOW,
            Icon: ItemIcon(),
          },
        ],
      },
      {
        // 컨텐츠 관리
        title: ADMIN_CONFIG.MENU_CONTENT.TITLE,
        key: ADMIN_CONFIG.MENU_CONTENT.KEY,
        type: ADMIN_CONFIG.MENU_TYPE.COLLAPSE,
        url: ADMIN_CONFIG.MENU_CONTENT.URL,
        show: ADMIN_CONFIG.MENU_CONTENT.SHOW,
        Icon: <Box component="img" src={ICON_CONTENTS} />,
        Children: [
          {
            // FAQ 관리
            title: ADMIN_CONFIG.MENU_CONTENT_FAQ.TITLE,
            key: ADMIN_CONFIG.MENU_CONTENT_FAQ.KEY,
            type: ADMIN_CONFIG.MENU_TYPE.ITEM,
            url: ADMIN_CONFIG.MENU_CONTENT_FAQ.URL,
            show: ADMIN_CONFIG.MENU_CONTENT_FAQ.SHOW,
            Icon: ItemIcon(),
          },
          {
            // 1:1 문의 관리
            title: ADMIN_CONFIG.MENU_CONTENT_INQUIRY.TITLE,
            key: ADMIN_CONFIG.MENU_CONTENT_INQUIRY.KEY,
            type: ADMIN_CONFIG.MENU_TYPE.ITEM,
            url: ADMIN_CONFIG.MENU_CONTENT_INQUIRY.URL,
            show: ADMIN_CONFIG.MENU_CONTENT_INQUIRY.SHOW,
            Icon: ItemIcon(),
          },
          {
            // CS 관리
            title: ADMIN_CONFIG.MENU_CONTENT_CS.TITLE,
            key: ADMIN_CONFIG.MENU_CONTENT_CS.KEY,
            type: ADMIN_CONFIG.MENU_TYPE.ITEM,
            url: ADMIN_CONFIG.MENU_CONTENT_CS.URL,
            show: ADMIN_CONFIG.MENU_CONTENT_CS.SHOW,
            Icon: ItemIcon(),
          },
          {
            // 공지 사항 관리
            title: ADMIN_CONFIG.MENU_CONTENT_NOTICE.TITLE,
            key: ADMIN_CONFIG.MENU_CONTENT_NOTICE.KEY,
            type: ADMIN_CONFIG.MENU_TYPE.ITEM,
            url: ADMIN_CONFIG.MENU_CONTENT_NOTICE.URL,
            show: ADMIN_CONFIG.MENU_CONTENT_NOTICE.SHOW,
            Icon: ItemIcon(),
          },
          {
            // 푸시 알람 관리
            title: ADMIN_CONFIG.MENU_CONTENT_PUSH_NOTIFICATION.TITLE,
            key: ADMIN_CONFIG.MENU_CONTENT_PUSH_NOTIFICATION.KEY,
            type: ADMIN_CONFIG.MENU_TYPE.ITEM,
            url: ADMIN_CONFIG.MENU_CONTENT_PUSH_NOTIFICATION.URL,
            show: ADMIN_CONFIG.MENU_CONTENT_PUSH_NOTIFICATION.SHOW,
            Icon: ItemIcon(),
          },
          {
            // 팝업 관리
            title: ADMIN_CONFIG.MENU_CONTENT_POPUP_BANNER.TITLE,
            key: ADMIN_CONFIG.MENU_CONTENT_POPUP_BANNER.KEY,
            type: ADMIN_CONFIG.MENU_TYPE.ITEM,
            url: ADMIN_CONFIG.MENU_CONTENT_POPUP_BANNER.URL,
            show: ADMIN_CONFIG.MENU_CONTENT_POPUP_BANNER.SHOW,
            Icon: ItemIcon(),
          },
        ],
      },
      {
        // 상품 관리
        title: ADMIN_CONFIG.MENU_MERCHANDISE.TITLE,
        key: ADMIN_CONFIG.MENU_MERCHANDISE.KEY,
        type: ADMIN_CONFIG.MENU_TYPE.COLLAPSE,
        url: ADMIN_CONFIG.MENU_MERCHANDISE.URL,
        show: ADMIN_CONFIG.MENU_MERCHANDISE.SHOW,
        Icon: <Box component="img" src={ICON_CASH} />,
        Children: [
          {
            // 멤버십 바우처 설정
            title: ADMIN_CONFIG.MENU_MERCHANDISE_MEMBERSHIP_VOUCHER_SETTINGS.TITLE,
            key: ADMIN_CONFIG.MENU_MERCHANDISE_MEMBERSHIP_VOUCHER_SETTINGS.KEY,
            type: ADMIN_CONFIG.MENU_TYPE.ITEM,
            url: ADMIN_CONFIG.MENU_MERCHANDISE_MEMBERSHIP_VOUCHER_SETTINGS.URL,
            show: ADMIN_CONFIG.MENU_MERCHANDISE_MEMBERSHIP_VOUCHER_SETTINGS.SHOW,
            Icon: ItemIcon(),
          },
          {
            // NFT 설정
            title: ADMIN_CONFIG.MENU_MERCHANDISE_NFT_SETTINGS.TITLE,
            key: ADMIN_CONFIG.MENU_MERCHANDISE_NFT_SETTINGS.KEY,
            type: ADMIN_CONFIG.MENU_TYPE.ITEM,
            url: ADMIN_CONFIG.MENU_MERCHANDISE_NFT_SETTINGS.URL,
            show: ADMIN_CONFIG.MENU_MERCHANDISE_NFT_SETTINGS.SHOW,
            Icon: ItemIcon(),
          },
          {
            // 쿠폰 설정
            title: ADMIN_CONFIG.MENU_MERCHANDISE_COUPON_SETTINGS.TITLE,
            key: ADMIN_CONFIG.MENU_MERCHANDISE_COUPON_SETTINGS.KEY,
            type: ADMIN_CONFIG.MENU_TYPE.ITEM,
            url: ADMIN_CONFIG.MENU_MERCHANDISE_COUPON_SETTINGS.URL,
            show: ADMIN_CONFIG.MENU_MERCHANDISE_COUPON_SETTINGS.SHOW,
            Icon: ItemIcon(),
          },
          {
            // 결제 관리
            title: ADMIN_CONFIG.MENU_MERCHANDISE_PAYMENT.TITLE,
            key: ADMIN_CONFIG.MENU_MERCHANDISE_PAYMENT.KEY,
            type: ADMIN_CONFIG.MENU_TYPE.ITEM,
            url: ADMIN_CONFIG.MENU_MERCHANDISE_PAYMENT.URL,
            show: ADMIN_CONFIG.MENU_MERCHANDISE_PAYMENT.SHOW,
            Icon: ItemIcon(),
          },
        ],
      },
      {
        // Production
        title: ADMIN_CONFIG.MENU_PRODUCTION_MATERIAL.TITLE,
        key: ADMIN_CONFIG.MENU_PRODUCTION_MATERIAL.KEY,
        type: ADMIN_CONFIG.MENU_TYPE.COLLAPSE,
        url: ADMIN_CONFIG.MENU_PRODUCTION_MATERIAL.URL,
        show: ADMIN_CONFIG.MENU_PRODUCTION_MATERIAL.SHOW,
        Icon: <Box component="img" src={ICON_PACKAGE} />,
        Children: [
          {
            // 생산 관리
            title: ADMIN_CONFIG.MENU_PRODUCTION_MATERIAL_PRODUCTION.TITLE,
            key: ADMIN_CONFIG.MENU_PRODUCTION_MATERIAL_PRODUCTION.KEY,
            type: ADMIN_CONFIG.MENU_TYPE.ITEM,
            url: ADMIN_CONFIG.MENU_PRODUCTION_MATERIAL_PRODUCTION.URL,
            show: ADMIN_CONFIG.MENU_PRODUCTION_MATERIAL_PRODUCTION.SHOW,
            Icon: ItemIcon(),
          },
          {
            // 재고 관리
            title: ADMIN_CONFIG.MENU_PRODUCTION_MATERIAL_INVENTORY.TITLE,
            key: ADMIN_CONFIG.MENU_PRODUCTION_MATERIAL_INVENTORY.KEY,
            type: ADMIN_CONFIG.MENU_TYPE.ITEM,
            url: ADMIN_CONFIG.MENU_PRODUCTION_MATERIAL_INVENTORY.URL,
            show: ADMIN_CONFIG.MENU_PRODUCTION_MATERIAL_INVENTORY.SHOW,
            Icon: ItemIcon(),
          },
          {
            // 요청 관리
            title: ADMIN_CONFIG.MENU_PRODUCTION_MATERIAL_REQUEST.TITLE,
            key: ADMIN_CONFIG.MENU_PRODUCTION_MATERIAL_REQUEST.KEY,
            type: ADMIN_CONFIG.MENU_TYPE.ITEM,
            url: ADMIN_CONFIG.MENU_PRODUCTION_MATERIAL_REQUEST.URL,
            show: ADMIN_CONFIG.MENU_PRODUCTION_MATERIAL_REQUEST.SHOW,
            Icon: ItemIcon(),
          },
          {
            // 모델 관리
            title: ADMIN_CONFIG.MENU_PRODUCTION_MATERIAL_PRODUCT_NAME.TITLE,
            key: ADMIN_CONFIG.MENU_PRODUCTION_MATERIAL_PRODUCT_NAME.KEY,
            type: ADMIN_CONFIG.MENU_TYPE.ITEM,
            url: ADMIN_CONFIG.MENU_PRODUCTION_MATERIAL_PRODUCT_NAME.URL,
            show: ADMIN_CONFIG.MENU_PRODUCTION_MATERIAL_PRODUCT_NAME.SHOW,
            Icon: ItemIcon(),
          },
        ],
      },
      {
        // 운영 관리
        title: ADMIN_CONFIG.MENU_OPERATIONS.TITLE,
        key: ADMIN_CONFIG.MENU_OPERATIONS.KEY,
        type: ADMIN_CONFIG.MENU_TYPE.COLLAPSE,
        url: ADMIN_CONFIG.MENU_OPERATIONS.URL,
        show: ADMIN_CONFIG.MENU_OPERATIONS.SHOW,
        Icon: <Box component="img" src={ICON_CHAT} />,
        Children: [
          {
            // 지역 및 서비스 관리
            title: ADMIN_CONFIG.MENU_OPERATIONS_AREA_AND_SERVICE.TITLE,
            key: ADMIN_CONFIG.MENU_OPERATIONS_AREA_AND_SERVICE.KEY,
            type: ADMIN_CONFIG.MENU_TYPE.ITEM,
            url: ADMIN_CONFIG.MENU_OPERATIONS_AREA_AND_SERVICE.URL,
            show: ADMIN_CONFIG.MENU_OPERATIONS_AREA_AND_SERVICE.SHOW,
            Icon: ItemIcon(),
          },
          {
            // 스쿠터 관리
            title: ADMIN_CONFIG.MENU_OPERATIONS_SCOOTER.TITLE,
            key: ADMIN_CONFIG.MENU_OPERATIONS_SCOOTER.KEY,
            type: ADMIN_CONFIG.MENU_TYPE.ITEM,
            url: ADMIN_CONFIG.MENU_OPERATIONS_SCOOTER.URL,
            show: ADMIN_CONFIG.MENU_OPERATIONS_SCOOTER.SHOW,
            Icon: ItemIcon(),
          },
          {
            // 스테이션 관리
            title: ADMIN_CONFIG.MENU_OPERATIONS_STATION.TITLE,
            key: ADMIN_CONFIG.MENU_OPERATIONS_STATION.KEY,
            type: ADMIN_CONFIG.MENU_TYPE.ITEM,
            url: ADMIN_CONFIG.MENU_OPERATIONS_STATION.URL,
            show: ADMIN_CONFIG.MENU_OPERATIONS_STATION.SHOW,
            Icon: ItemIcon(),
          },
          {
            // 배터리 관리
            title: ADMIN_CONFIG.MENU_OPERATIONS_BATTERY.TITLE,
            key: ADMIN_CONFIG.MENU_OPERATIONS_BATTERY.KEY,
            type: ADMIN_CONFIG.MENU_TYPE.ITEM,
            url: ADMIN_CONFIG.MENU_OPERATIONS_BATTERY.URL,
            show: ADMIN_CONFIG.MENU_OPERATIONS_BATTERY.SHOW,
            Icon: ItemIcon(),
          },
          {
            // NFC 카드 관리
            title: ADMIN_CONFIG.MENU_OPERATIONS_NFC_CARD.TITLE,
            key: ADMIN_CONFIG.MENU_OPERATIONS_NFC_CARD.KEY,
            type: ADMIN_CONFIG.MENU_TYPE.ITEM,
            url: ADMIN_CONFIG.MENU_OPERATIONS_NFC_CARD.URL,
            show: ADMIN_CONFIG.MENU_OPERATIONS_NFC_CARD.SHOW,
            Icon: ItemIcon(),
          },
        ],
      },
      {
        // 탄소감축량관리
        title: ADMIN_CONFIG.MENU_CARBON_REDUCTION.TITLE,
        key: ADMIN_CONFIG.MENU_CARBON_REDUCTION.KEY,
        type: ADMIN_CONFIG.MENU_TYPE.COLLAPSE,
        url: ADMIN_CONFIG.MENU_CARBON_REDUCTION.URL,
        show: ADMIN_CONFIG.MENU_CARBON_REDUCTION.SHOW,
        Icon: <Box component="img" src={ICON_CARBON} />,
        Children: [
          {
            // 탄소감축량 정보
            title: ADMIN_CONFIG.MENU_CARBON_REDUCTION_INFO.TITLE,
            key: ADMIN_CONFIG.MENU_CARBON_REDUCTION_INFO.KEY,
            type: ADMIN_CONFIG.MENU_TYPE.ITEM,
            url: ADMIN_CONFIG.MENU_CARBON_REDUCTION_INFO.URL,
            show: ADMIN_CONFIG.MENU_CARBON_REDUCTION_INFO.SHOW,
            Icon: ItemIcon(),
          },
          {
            // 방법론 관리
            title: ADMIN_CONFIG.MENU_CARBON_REDUCTION_METHODOLOGY.TITLE,
            key: ADMIN_CONFIG.MENU_CARBON_REDUCTION_METHODOLOGY.KEY,
            type: ADMIN_CONFIG.MENU_TYPE.ITEM,
            url: ADMIN_CONFIG.MENU_CARBON_REDUCTION_METHODOLOGY.URL,
            show: ADMIN_CONFIG.MENU_CARBON_REDUCTION_METHODOLOGY.SHOW,
            Icon: ItemIcon(),
          },
          {
            // 프로젝트 관리
            title: ADMIN_CONFIG.MENU_CARBON_REDUCTION_PROJECT.TITLE,
            key: ADMIN_CONFIG.MENU_CARBON_REDUCTION_PROJECT.KEY,
            type: ADMIN_CONFIG.MENU_TYPE.ITEM,
            url: ADMIN_CONFIG.MENU_CARBON_REDUCTION_PROJECT.URL,
            show: ADMIN_CONFIG.MENU_CARBON_REDUCTION_PROJECT.SHOW,
            Icon: ItemIcon(),
          },
          {
            // 데이터/인자 관리
            title: ADMIN_CONFIG.MENU_CARBON_REDUCTION_DATA_PARAMETER.TITLE,
            key: ADMIN_CONFIG.MENU_CARBON_REDUCTION_DATA_PARAMETER.KEY,
            type: ADMIN_CONFIG.MENU_TYPE.ITEM,
            url: ADMIN_CONFIG.MENU_CARBON_REDUCTION_DATA_PARAMETER.URL,
            show: ADMIN_CONFIG.MENU_CARBON_REDUCTION_DATA_PARAMETER.SHOW,
            Icon: ItemIcon(),
          },
          {
            // 모니터링 관리
            title: ADMIN_CONFIG.MENU_CARBON_REDUCTION_MONITORING.TITLE,
            key: ADMIN_CONFIG.MENU_CARBON_REDUCTION_MONITORING.KEY,
            type: ADMIN_CONFIG.MENU_TYPE.ITEM,
            url: ADMIN_CONFIG.MENU_CARBON_REDUCTION_MONITORING.URL,
            show: ADMIN_CONFIG.MENU_CARBON_REDUCTION_MONITORING.SHOW,
            Icon: ItemIcon(),
          },

          {
            // 로우 데이터 확인인
            title: ADMIN_CONFIG.MENU_ROW_DATA_CHECK.TITLE,
            key: ADMIN_CONFIG.MENU_ROW_DATA_CHECK.KEY,
            type: ADMIN_CONFIG.MENU_TYPE.ITEM,
            url: ADMIN_CONFIG.MENU_ROW_DATA_CHECK.URL,
            show: ADMIN_CONFIG.MENU_ROW_DATA_CHECK.SHOW,
            Icon: ItemIcon(),
          },
        ],
      },
      {
        // 블록체인 관리
        title: ADMIN_CONFIG.MENU_BLOCKCHAIN.TITLE,
        key: ADMIN_CONFIG.MENU_BLOCKCHAIN.KEY,
        type: ADMIN_CONFIG.MENU_TYPE.COLLAPSE,
        url: ADMIN_CONFIG.MENU_BLOCKCHAIN.URL,
        show: ADMIN_CONFIG.MENU_BLOCKCHAIN.SHOW,
        Icon: <Box component="img" src={ICON_BLOCKCHAIN} />,
        Children: [
          {
            // 블록체인 모니터링
            title: ADMIN_CONFIG.MENU_BLOCKCHAIN_BLOCKCHAIN_MONITORING.TITLE,
            key: ADMIN_CONFIG.MENU_BLOCKCHAIN_BLOCKCHAIN_MONITORING.KEY,
            type: ADMIN_CONFIG.MENU_TYPE.ITEM,
            url: ADMIN_CONFIG.MENU_BLOCKCHAIN_BLOCKCHAIN_MONITORING.URL,
            show: ADMIN_CONFIG.MENU_BLOCKCHAIN_BLOCKCHAIN_MONITORING.SHOW,
            Icon: ItemIcon(),
          },
          {
            // 에코플랫폼 월렛
            title: ADMIN_CONFIG.MENU_BLOCKCHAIN_ECO_PLATFORM_WALLET.TITLE,
            key: ADMIN_CONFIG.MENU_BLOCKCHAIN_ECO_PLATFORM_WALLET.KEY,
            type: ADMIN_CONFIG.MENU_TYPE.ITEM,
            url: ADMIN_CONFIG.MENU_BLOCKCHAIN_ECO_PLATFORM_WALLET.URL,
            show: ADMIN_CONFIG.MENU_BLOCKCHAIN_ECO_PLATFORM_WALLET.SHOW,
            Icon: ItemIcon(),
          },
        ],
      },
      {
        // 시스템 관리
        title: ADMIN_CONFIG.MENU_SYSTEM_SETTING.TITLE,
        key: ADMIN_CONFIG.MENU_SYSTEM_SETTING.KEY,
        type: ADMIN_CONFIG.MENU_TYPE.COLLAPSE,
        url: ADMIN_CONFIG.MENU_SYSTEM_SETTING.URL,
        show: ADMIN_CONFIG.MENU_SYSTEM_SETTING.SHOW,
        Icon: <Box component="img" src={ICON_SYSTEM} />,
        Children: [
          {
            // 알림 설정
            title: ADMIN_CONFIG.MENU_SYSTEM_SETTING_NOTIFICATION.TITLE,
            key: ADMIN_CONFIG.MENU_SYSTEM_SETTING_NOTIFICATION.KEY,
            type: ADMIN_CONFIG.MENU_TYPE.ITEM,
            url: ADMIN_CONFIG.MENU_SYSTEM_SETTING_NOTIFICATION.URL,
            show: ADMIN_CONFIG.MENU_SYSTEM_SETTING_NOTIFICATION.SHOW,
            Icon: ItemIcon(),
          },
          {
            // 다국어 설정
            title: ADMIN_CONFIG.MENU_SYSTEM_SETTING_MULTI_LANGUAGE.TITLE,
            key: ADMIN_CONFIG.MENU_SYSTEM_SETTING_MULTI_LANGUAGE.KEY,
            type: ADMIN_CONFIG.MENU_TYPE.ITEM,
            url: ADMIN_CONFIG.MENU_SYSTEM_SETTING_MULTI_LANGUAGE.URL,
            show: ADMIN_CONFIG.MENU_SYSTEM_SETTING_MULTI_LANGUAGE.SHOW,
            Icon: ItemIcon(),
          },
          {
            // 이력 관리
            title: ADMIN_CONFIG.MENU_SYSTEM_SETTING_HISTORY_MANAGEMENT.TITLE,
            key: ADMIN_CONFIG.MENU_SYSTEM_SETTING_HISTORY_MANAGEMENT.KEY,
            type: ADMIN_CONFIG.MENU_TYPE.ITEM,
            url: ADMIN_CONFIG.MENU_SYSTEM_SETTING_HISTORY_MANAGEMENT.URL,
            show: ADMIN_CONFIG.MENU_SYSTEM_SETTING_HISTORY_MANAGEMENT.SHOW,
            Icon: ItemIcon(),
          },
          {
            // 연계/배치 관리
            title: ADMIN_CONFIG.MENU_SYSTEM_SETTING_BATCH.TITLE,
            key: ADMIN_CONFIG.MENU_SYSTEM_SETTING_BATCH.KEY,
            type: ADMIN_CONFIG.MENU_TYPE.ITEM,
            url: ADMIN_CONFIG.MENU_SYSTEM_SETTING_BATCH.URL,
            show: ADMIN_CONFIG.MENU_SYSTEM_SETTING_BATCH.SHOW,
            Icon: ItemIcon(),
          },
          {
            title: ADMIN_CONFIG.MENU_SYSTEM_SETTING_MQTT.TITLE,
            key: ADMIN_CONFIG.MENU_SYSTEM_SETTING_MQTT.KEY,
            type: ADMIN_CONFIG.MENU_TYPE.ITEM,
            url: ADMIN_CONFIG.MENU_SYSTEM_SETTING_MQTT.URL,
            show: ADMIN_CONFIG.MENU_SYSTEM_SETTING_MQTT.SHOW,
            Icon: <DashboardOutlinedIcon />,
          },
        ],
      },
    ],
  },
};
