import { useEffect, useState } from "react";
import CommonSearch from "../../../../../component/CommonSearch";
import { Box, SelectChangeEvent } from "@mui/material";
import { CommonSelect } from "../../../../../component/CommonSelect";
import VerticalSpacer, { HorizontalSpacer } from "../../../../../component/Spacer";
import { ProductionTable } from "./ProductionTable";
import { HeadCell } from "../../../../../component/CommonTable";
import { ProductionData, ProductionList } from "./model/ProductionData";
import { ApiService } from "../../../../../restAPI/ApiService";

export function Production() {
  // 페이지네이션 기능을 위한 변수
  const [rowsPerPage, setRowsPerPage] = useState(10); // 한 테이블당 보여줄 row 의 갯수
  const [rows, setRows] = useState<ProductionData[]>([]); // 받아온 데이터
  const [page, setPage] = useState<number>(0); // 현재 페이지의 번호
  const [docCount, setDocCount] = useState<number>(0); // 총 row의 수
  const [pageCount, setPageCount] = useState(0); // 테이블 왼쪽 하단에 보여질 페이지의 갯수
  // 검색어 관련 변수
  const [searchContents, setSearchContents] = useState("");
  // 카테고리 관련 변수
  const [category, setCategory] = useState("");
  // quantities 관련 변수
  const [quantities, setQuantities] = useState("");
  // status 관련 변수
  const [state, setState] = useState("");

  useEffect(() => {
    ApiService.WebPost("/pw/aw/material/product/filter", {
      page: page + 1,
      size: rowsPerPage,
      searchContents: searchContents,
      category: category,
      quantities: quantities,
      state: state,
    })
      .then((res) => {
        setRows(res?.data.body || []);
        setPageCount(Math.ceil(res?.data.countDoc / rowsPerPage));
        setDocCount(res?.data.countDoc);
      })
      .catch(() => {});
  }, [page, rowsPerPage, category, quantities, state]);

  return (
    <>
      <CommonSearch
        placeHolder={"Parts Code, Product Name, Spec, Made"}
        searchContents={searchContents}
        setSearchContents={setSearchContents}
        onKeyPress={() => {
          ApiService.WebPost("/pw/aw/material/product/filter", {
            page: page + 1,
            size: rowsPerPage,
            searchContents: searchContents,
            category: category,
            quantities: quantities,
            state: state,
          })
            .then((res) => {
              setRows(res?.data.body || []);
              setPageCount(Math.ceil(res?.data.countDoc / rowsPerPage));
              setDocCount(res?.data.countDoc);
            })
            .catch(() => {});
        }}
      >
        <Box sx={{ display: "flex" }}>
          <CommonSelect
            sx={{
              width: "108px",
              height: "42px",
            }}
            menuitems={["All", "ASSY", "SEMI ASSY"]}
            selectValue={category}
            onChange={(event) => {
              setCategory(event.target.value);
            }}
            placeholder="Category"
          />
          <HorizontalSpacer width={12} />
          <CommonSelect
            sx={{
              width: "117px",
              height: "42px",
            }}
            menuitems={["All", "SET", "EA", "g", "M"]}
            selectValue={quantities}
            onChange={(event) => {
              setQuantities(event.target.value);
            }}
            placeholder="Quantities"
          />
          <HorizontalSpacer width={12} />
          <CommonSelect
            sx={{
              width: "93px",
              height: "42px",
            }}
            menuitems={["All", "Update", "Reject", "Confirm"]}
            selectValue={state}
            onChange={(event) => {
              setState(event.target.value);
            }}
            placeholder="Status"
          />
        </Box>
      </CommonSearch>
      <VerticalSpacer height={16} />
      <ProductionTable
        rows={rows}
        headCells={productionHeadCells}
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </>
  );
}

const productionHeadCells: HeadCell[] = [
  {
    disablePadding: false,
    align: "left",
    id: "no",
    label: "No",
  },
  {
    disablePadding: false,
    align: "left",
    id: "category",
    label: "Category",
  },
  {
    disablePadding: false,
    align: "left",
    id: "partsCode",
    label: "Parts Code",
  },
  {
    disablePadding: false,
    align: "left",
    id: "productName",
    label: "Product Name",
  },
  {
    disablePadding: false,
    align: "left",
    id: "spec",
    label: "Spec",
  },
  {
    disablePadding: false,
    align: "left",
    id: "made",
    label: "Made",
  },
  {
    disablePadding: false,
    align: "left",
    id: "quantities",
    label: "Quantities",
  },
  {
    disablePadding: false,
    align: "left",
    id: "amount",
    label: "Amount",
  },
  {
    disablePadding: false,
    align: "left",
    id: "state",
    label: "State",
  },
  {
    disablePadding: false,
    align: "left",
    id: "registrationDate",
    label: "Registration Date",
  },
  {
    disablePadding: false,
    align: "left",
    id: "lastUpdateDate",
    label: "Last Update Date",
  },
];
