import { Card, Grid } from "@mui/material";
import CommonList from "../../../../../../component/CommonList";
import { color } from "../../../../../../theme/Color";
import { MaterialRegistrationInspector } from "./MaterialRegistrationInspector";
import { CommonWebHeaderButton } from "../../../../../../component/CommonWebHeaderButton";
import { MaterialRegistrationFindParts } from "./MaterialRegistrationFindParts";
import VerticalSpacer from "../../../../../../component/Spacer";
import { useEffect, useState } from "react";
import { ReceiveDeliverUiState } from "../model/ReceiveDeliverUiState";
import { ReceiveDeliverUiEvent } from "../model/ReceiveDeliverUiEvent";
import { ApiService } from "../../../../../../restAPI/ApiService";
import { useNavigate } from "react-router-dom";

export function ReceiveDeliverRegistration() {
  const navigate = useNavigate();
  const [uiState, setUiState] = useState(new ReceiveDeliverUiState());
  const uiEvent: ReceiveDeliverUiEvent = {
    HandleUiStateChange: (key: keyof ReceiveDeliverUiState, value: any) => {
      setUiState((prevState) => ({
        ...prevState,
        [key]: value, // key에 해당하는 상태를 업데이트
      }));
    },
  };

  useEffect(() => {
    uiEvent.HandleUiStateChange("Inspector", localStorage.getItem("user_uuid"));
  }, []);

  useEffect(() => {
    ApiService.WebPost("/pw/aw/material/searchBranch").then((res) => {
      if (res?.data.body) {
        uiEvent.HandleUiStateChange("BranchList", res?.data.body);
      }
    });
  }, []);

  const registerClick = () => {
    // 에러 상태를 업데이트
    const updatedBranchError = uiState.BranchName === "";
    const updatedCommnetError = uiState.Commnet === "";
    const updatedMaterialNameError = uiState.MaterialName === "";
    const updatedCountError = uiState.Count === 0;

    // 상태 변경
    uiEvent.HandleUiStateChange("BranchError", updatedBranchError);
    uiEvent.HandleUiStateChange("CommnetError", updatedCommnetError);
    uiEvent.HandleUiStateChange("MaterialNameError", updatedMaterialNameError);
    uiEvent.HandleUiStateChange("CountError", updatedCountError);

    // 에러 상태가 업데이트된 후 검증
    if (!updatedBranchError && !updatedCommnetError && !updatedMaterialNameError && !updatedCountError) {
      const MaterialRegistData = {
        branchId: uiState.BranchId,
        registerMaterialName: uiState.MaterialName,
        registerMaterialAmount: uiState.Count,
        branchName: uiState.BranchName,
        comment: uiState.Commnet,
        inspector: uiState.Inspector,
        ioDate: uiState.FinalRegisterDate,
        isIncoming: uiState.Type == "Recieve" ? true : false,
      };
      ApiService.WebPost("/pw/aw/material/io/create", MaterialRegistData).then((res) => {
        if (res?.data.message == "create success") {
          navigate(`/inventoryManagementDetails/${res.data.createdId}`);
        }
      });
    }
  };

  return (
    <CommonList
      title="Stock In and Out Registration"
      headerBackButton={true}
      navigateURL={`/inventoryManagement?tab=receivedeliver`}
      headerButton={
        <CommonWebHeaderButton
          buttonContent="Register"
          height="45px"
          sx={{ display: "flex", flexDirection: "column" }}
          onClick={registerClick} //TODO : 버튼 눌렀을 때 동작 추가
        />
      }
    >
      <Grid item lg={12}>
        <Card
          sx={{
            borderRadius: "15px",
            border: "1px solid",
            borderColor: color.gray300,
            padding: "24px",
            paddingBottom: "40px",
            boxShadow: 0,
          }}
        >
          <MaterialRegistrationInspector uiState={uiState} uiEvent={uiEvent} />
        </Card>
        <VerticalSpacer height={20} />
        <Card
          sx={{
            borderRadius: "15px",
            border: "1px solid",
            borderColor: color.gray300,
            padding: "24px",
            paddingBottom: "40px",
            boxShadow: 0,
          }}
        >
          <MaterialRegistrationFindParts uiState={uiState} uiEvent={uiEvent} />
        </Card>
      </Grid>
    </CommonList>
  );
}
