import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import ProgressBar from "../../../component/ProgressBar";
import { SERVER_CONFIG } from "../../../constants/ServerConfig";
import { ROUTE_PATHS } from "../../../constants/RouteConstants";
import {
  COUNTRY_CODE_STORAGE_KEY,
  getLanguageCode,
} from "../../../customHook/useLocale";
import PhoneAuthPageRender from "./PhoneAuthPageRender";
import { PhoneAuthPageUiState } from "./model/PhoneAuthPageUiState";
import { PhoneAuthPageUiEvent } from "./model/PhoneAuthPageUiEvent";
import { PhoneAuthPageSideEffect } from "./model/PhoneAuthPageSideEffect";
import { ApiService } from "../../../restAPI/ApiService";
import useAndroidBackHandler from "../../../customHook/useAndroidBackHandler";

export default function PhoneAuthPage() {
  const [uiState, setUiState] = useState(new PhoneAuthPageUiState());
  const navigate = useNavigate();
  const [progress, setProgress] = useState(false);
  const location = useLocation();
  const previousScreenState = location.state as
    | {
        path?: string;
        localNumber?: string;
        phoneNumber?: string;
      }
    | undefined;

  useAndroidBackHandler(() => {
    if (progress) return;
    if (uiState.isShownContactAdminDialog) {
      uiEvent.changeContactAdminDialogState(false);
    } else if (uiState.isShownExitPageDialog) {
      uiEvent.changeExitPageDialog(false);
    } else if (uiState.isShownAuthFailDialog) {
      uiEvent.changeAuthCodeRequestFailDialogState(false);
    } else if (uiState.isShownAuthCodeValidateFailDialog) {
      uiEvent.changeAuthCodeValidateFailDialogState(false);
    } else {
      uiEvent.changeExitPageDialog(true);
    }
  }, [
    uiState.isShownContactAdminDialog,
    uiState.isShownExitPageDialog,
    uiState.isShownAuthFailDialog,
    uiState.isShownAuthCodeValidateFailDialog,
    progress,
  ]);

  useEffect(() => {
    setUiState((prev) => {
      return {
        ...prev,
        localNumber:
          previousScreenState?.localNumber || getInitialLocalNumber(),
        inputPhoneNumber: previousScreenState?.phoneNumber || "",
      };
    });
  }, []);

  function getInitialLocalNumber(): string {
    const countryCode = localStorage.getItem(COUNTRY_CODE_STORAGE_KEY);
    switch (countryCode) {
      case "KR":
        return "82";
      case "KH":
        return "855";
      case "VN":
        return "84";
      default:
        return "";
    }
  }

  useEffect(() => {
    if (uiState.timer === 0) {
      setUiState((prev) => {
        return {
          ...prev,
          isTimerInProgress: false,
        };
      });
    }
  }, [uiState.timer]);

  const uiEvent: PhoneAuthPageUiEvent = {
    changeExitPageDialog: function (state: boolean): void {
      setUiState((prev) => {
        return {
          ...prev,
          isShownExitPageDialog: state,
        };
      });
    },
    setTimer: function (timer: number): void {
      setUiState((prev) => {
        return {
          ...prev,
          timer: timer,
        };
      });
    },
    changeAuthText: function (text: string): void {
      const onlyNumbers = text.replace(/[^0-9]/g, "");
      setUiState((prev) => {
        return {
          ...prev,
          inputAuthNumber: onlyNumbers,
        };
      });
    },
    changeLocalNumber: function (text: string): void {
      setUiState((prev) => {
        return {
          ...prev,
          localNumber: text,
        };
      });
    },
    changePhoneNumber: function (text: string): void {
      const onlyNumbers = text.replace(/[^0-9]/g, "");
      setUiState((prev) => {
        return {
          ...prev,
          inputPhoneNumber: onlyNumbers,
        };
      });
    },
    changeLocalNumberError: function (state: boolean): void {
      setUiState((prev) => {
        return {
          ...prev,
          localNumberError: state,
        };
      });
    },
    changePhoneNumberError: function (state: boolean): void {
      setUiState((prev) => {
        return {
          ...prev,
          phoneNumberError: state,
        };
      });
    },
    changeAuthCodeNumberError: function (state: boolean): void {
      setUiState((prev) => {
        return {
          ...prev,
          authCodeError: state,
        };
      });
    },
    changeTimerState: function (state: boolean): void {
      setUiState((prev) => {
        return {
          ...prev,
          isTimerInProgress: state,
          timer: 3 * 60 * 1000,
        };
      });
    },
    onClickSendButton: function (): void {
      sideEffect.requestAuthCode(uiState.localNumber, uiState.inputPhoneNumber);
    },
    showAndHideToast: function (state: boolean): void {
      setUiState((prev) => {
        return {
          ...prev,
          isShownAuthCodeSuccessRequestToast: state,
        };
      });
    },
    changeAuthCodeRequestFailDialogState: function (state: boolean): void {
      setUiState((prev) => {
        return {
          ...prev,
          isShownAuthFailDialog: state,
        };
      });
    },
    changeAuthCodeValidateFailDialogState: function (state: boolean): void {
      setUiState((prev) => {
        return {
          ...prev,
          isShownAuthCodeValidateFailDialog: state,
        };
      });
    },
    changeContactAdminDialogState: function (state: boolean): void {
      setUiState((prev) => {
        return {
          ...prev,
          isShownContactAdminDialog: state,
        };
      });
    },
    openAdminExternalLink: function (): void {
      const telegramLink = "https://t.me/VERYWORDSGEM";
      if (window.Android) {
        window.Android.openExternalBrowser(telegramLink);
      } else if (window.webkit) {
        window.webkit.messageHandlers.openExternalBrowser.postMessage(telegramLink);
      } else {
        console.log("Web browser open: ", telegramLink);
      }
    },
  };
  const sideEffect: PhoneAuthPageSideEffect = {
    navigate: function (path: string, state?: any): void {
      navigate(path, state);
    },
    setTimer: function (timer: number): void {
      setUiState((prev) => {
        return {
          ...prev,
          timer: timer,
        };
      });
    },
    requestAuthCode: async function (
      countryNumber: string,
      phoneNumber: string
    ) {
      const convertCountryNumber = countryNumber.replace("+", "");
      const isTester =
        SERVER_CONFIG.ON_FOR_PASS_KOREAN_NUMBER &&
        convertCountryNumber === "82" &&
        phoneNumber.startsWith("010");
      if (isTester) {
        localStorage.setItem("user_phone_number", phoneNumber);
        localStorage.setItem("user_country_number", convertCountryNumber);
        localStorage.setItem("temp_user_phone_number", phoneNumber);
        localStorage.setItem("temp_user_country_number", convertCountryNumber);

        if (previousScreenState?.path === ROUTE_PATHS.EDIT_PROFILE) {
          window.history.back();
        } else {
          await signUp();
        }
      } else {
        if (
          (convertCountryNumber === "82" || convertCountryNumber === "855") &&
          !phoneNumber.startsWith("0")
        ) {
          phoneNumber = "0" + phoneNumber;
          uiEvent.changePhoneNumber(phoneNumber);
        }
        await getAuthCode(getLanguageCode(), countryNumber, phoneNumber);
      }
    },
    validateAuthCode: function (
      countryNumber: string,
      phoneNumber: string,
      authCode: string
    ): void {
      validateAuthCode(countryNumber, phoneNumber, authCode);
    },
  };

  // http://dev.verywords.com/documents/207
  async function getAuthCode(
    language: string,
    countryNumber: string,
    phoneNumber: string
  ) {
    const url = "/pw/sms/sendCode";
    const requestBody = {
      language: language,
      countryNumber: countryNumber.replace("+", ""),
      phoneNumber: phoneNumber,
    };
    const isTester =
      SERVER_CONFIG.ON_FOR_TEST &&
      uiState.inputPhoneNumber === SERVER_CONFIG.MASTER_NUMBER;
    // 마스터 번호
    if (isTester) {
      await signUp();
    } else {
      try {
        setProgress(true);
        await ApiService.SmsAuthPost(url, requestBody);
        uiEvent.showAndHideToast(true);
        uiEvent.changeTimerState(true);
      } catch (error) {
        uiEvent.changeAuthCodeRequestFailDialogState(true);
      } finally {
        setProgress(false);
      }
    }
  }

  async function validateAuthCode(
    countryNumber: string,
    phoneNumber: string,
    authCode: string
  ) {
    const url = "/pw/sms/checkCode";
    const convertCountryNumber = countryNumber.replace("+", "");
    const requestBody = {
      countryNumber: convertCountryNumber,
      phoneNumber: phoneNumber,
      authCode: authCode,
    };
    const isTester =
      SERVER_CONFIG.ON_FOR_TEST &&
      uiState.inputAuthNumber === SERVER_CONFIG.MASTER_CODE;
    localStorage.setItem("user_phone_number", phoneNumber);
    localStorage.setItem("user_country_number", convertCountryNumber);
    localStorage.setItem("temp_user_phone_number", phoneNumber);
    localStorage.setItem("temp_user_country_number", convertCountryNumber);

    if (isTester) {
      await successPhoneAuth();
    } else {
      try {
        setProgress(true);
        await ApiService.SmsAuthPost(url, requestBody).then(() => {
          successPhoneAuth();
        });
      } catch (error) {
        uiEvent.changeAuthCodeValidateFailDialogState(true);
        setProgress(false);
      } finally {
      }
    }
  }

  async function signUp() {
    setProgress(true);
    const url = "/pw/users/join";
    const requestBody = {
      birthday: 0,
      addressCountry: "",
      addressProvince: "",
      addressDetail: "",
      gender: "",
      name: getName(),
      type: "General User",
      snsType: getSnsType(), // Google, Facebook, Apple
      uuid: localStorage.getItem("uuid"),
      email: localStorage.getItem("email"),
      isServiceConsent: getBoolFromString("isServiceConsent"),
      isMarketingConsent: getBoolFromString("isMarketingConsent"),
      isMinor: getBoolFromString("isMinor"),
      country: localStorage.getItem("COUNTRY_CODE_STORAGE_KEY"),
      countryNumber: localStorage.getItem("user_country_number") ?? "82",
      phoneNumber: localStorage.getItem("user_phone_number") ?? "01000000000",
    };

    await ApiService.post(url, requestBody)
      .then((res) => {
        sideEffect.navigate(ROUTE_PATHS.CARBON_REDUCTION_NFT, {
          state: {
            state: {
              lastPath: ROUTE_PATHS.PHONE_AUTH,
            },
          }, // 상태 전달
        });
      })
      .catch((e) => {
        console.error(e);
        uiEvent.changeAuthCodeValidateFailDialogState(true);
      })
      .finally(() => {
        setProgress(false);
      });
  }

  function getName(): string {
    return `${localStorage.getItem("name")}`;
  }

  function getSnsType(): string {
    const snsType = localStorage.getItem("snsType");
    if (snsType == "google.com") {
      return "Google";
    } else if (snsType == "facebook.com") {
      return "Facebook";
    } else {
      return "Apple";
    }
  }

  function getBoolFromString(value: string): boolean {
    return localStorage.getItem(value) == "true";
  }

  async function successPhoneAuth() {
    if (previousScreenState?.path === ROUTE_PATHS.EDIT_PROFILE) {
      window.history.back();
    } else {
      await signUp();
    }
  }

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      {progress ? <ProgressBar /> : null}
      <PhoneAuthPageRender
        uiState={uiState}
        uiEvent={uiEvent}
        sideEffect={sideEffect}
      />
    </Box>
  );
}
