import { Box, SxProps, Theme } from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";

interface StatusBadgeProps {
  status: string;
  sx?: SxProps<Theme>
  font?: { fontSize: string; lineHeight: string; fontWeight: string; };
}

export function StatusBadge({ status, sx, font = fontStyle.regularXXS }: StatusBadgeProps) {
  return (
    <Box fontStyle={font}>
      {status == "Complete" && (
        <Box
          sx={{
            width: "64px",
            height: "18px",
            borderRadius: "5px",
            bgcolor: color.backgroundSuccess,
            color: color.success,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            px:"8px",
            ...sx
          }}
        >
          {status}
        </Box>
      )}
      {status == "Receipt" && (
        <Box
          sx={{
            width: "54px",
            height: "18px",
            borderRadius: "5px",
            bgcolor: color.proceedBg,
            color: color.proceed,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            px:"8px",
            ...sx
          }}
        >
          {status}
        </Box>
      )}

      {status == "Reservation" && (
        <Box
          sx={{
            width: "76px",
            height: "18px",
            borderRadius: "5px",
            bgcolor: color.backgroundWarning2,
            color: color.warning2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            px:"8px",
            ...sx
          }}
        >
          {status}
        </Box>
      )}

      {status == "Deleted" && (
        <Box
          sx={{
            width: "49px",
            height: "18px",
            borderRadius: "5px",
            bgcolor: color.gray200,
            color: color.gray700,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            px:"8px",
            ...sx
          }}
        >
          {status}
        </Box>
      )}
    </Box>
  );
}
