import CommonList from "../../../../../component/CommonList";
import { Card } from "@mui/material";
import { ProductCard } from "./ProductCard";
import { color } from "../../../../../theme/Color";
import VerticalSpacer from "../../../../../component/Spacer";
import { ProductNameRegistrationUiState } from "./model/ProductNameRegistrationUiState";
import { ProductNameRegistrationUiEvent } from "./model/ProductNameRegistrationUiEvent";
import CommonDialog from "../../../../../component/CommonDialog";
import { ADMIN_CONFIG } from "../../../../../constants/AdminConfig";
import { fontStyle } from "../../../../../theme/Style";
import { CommonWebHeaderButton } from "../../../../../component/CommonWebHeaderButton";
import { FindInListCard } from "./FindInListCard";

interface ProductNameRegistrationPageProps {
  uiState: ProductNameRegistrationUiState;
  uiEvent: ProductNameRegistrationUiEvent;
}

export function ProductNameRegistrationPage({
  uiState,
  uiEvent,
}: ProductNameRegistrationPageProps) {
  return (
    <CommonList
      title="Product Name Registration"
      headerButton={
        <CommonWebHeaderButton
          buttonContent="Register"
          sx={{ display: "flex", flexDirection: "column" }}
          onClick={uiEvent.registerButtonClick}
        />
      }
      headerBackButton
      backbuttonOnclick={uiEvent.backButtonClick}
    >
      <Card
        sx={{
          padding: "24px",
          border: "1px solid",
          borderColor: color.gray300,
          borderRadius: "15px",
          boxShadow: "unset",
        }}
      >
        <FindInListCard uiState={uiState} uiEvent={uiEvent} />
      </Card>
      <CommonDialog
        open={uiState.backDialogOpen}
        handleClose={uiEvent.backClose}
        handleOk={uiEvent.backOk}
        textSx={{ fontStyle: fontStyle.regularS, color: color.gray800 }}
      >
        {ADMIN_CONFIG.MSG.EDIT_STOP_AND_MOVE}
        <br />
        {ADMIN_CONFIG.MSG.EDIT_STOP_NOT_SAVED}
      </CommonDialog>
      <CommonDialog
        open={uiState.registerDialogOpen}
        handleClose={uiEvent.registerClose}
        handleOk={uiEvent.registerOk}
        textSx={{ fontStyle: fontStyle.regularS, color: color.gray800 }}
      >
        Would you like to register the product name?
      </CommonDialog>
      <VerticalSpacer height={20} />
      <ProductCard uiState={uiState} uiEvent={uiEvent} />
    </CommonList>
  );
}
