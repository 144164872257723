import { Box, Typography } from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import { ServiceCountry } from "../../MobileLogin/model/ServiceCountry";
import { IMAGE_KHMER, IMAGE_KOREA, IMAGE_VIETNAM } from "../../../constants/appImagePath";
import { useEffect, useState } from "react";
import Toolbar from "../../../component/Toolbar";
import VerticalSpacer from "../../../component/Spacer";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import CountryButton from "../../MobileLogin/component/CountryButton";
import CommonButton from "../../../component/CommonButton";
import SimpleDialog from "../../../component/dialog/SimpleDialog";
import { COUNTRY_CODE_STORAGE_KEY, LOCALE_STORAGE_KEY } from "../../../customHook/useLocale";
import useAndroidBackHandler from "../../../customHook/useAndroidBackHandler";

const ServiceCountryPage = () => {
  useAndroidBackHandler(() => {
    if (window.Android) {
      window.history.back();
    }
  }, []);
  const [selectedCountry, setSelectedCountry] = useState<ServiceCountry | null>(null);
  const [isModified, setIsModified] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const countries: ServiceCountry[] = [
    {
      name: "Cambodia",
      code: "KH",
      flagImg: IMAGE_KHMER,
    },
    {
      name: "South Korea",
      code: "KR",
      flagImg: IMAGE_KOREA,
    },
    {
      name: "Vietnam",
      code: "VN",
      flagImg: IMAGE_VIETNAM,
    },
  ];

  useEffect(() => {
    const initialCountry = getInitialCountry();
    setSelectedCountry(initialCountry);
  }, []);

  useEffect(() => {
    const initialCountryCode = localStorage.getItem(COUNTRY_CODE_STORAGE_KEY);
    if (selectedCountry && selectedCountry.code !== initialCountryCode) {
      setIsModified(true);
    } else {
      setIsModified(false);
    }
  }, [selectedCountry]);

  function getInitialCountry(): ServiceCountry {
    switch (localStorage.getItem(COUNTRY_CODE_STORAGE_KEY)) {
      case "KH":
        return countries[0];
      case "KR":
        return countries[1];
      case "VN":
        return countries[2];
      default:
        return countries[0];
    }
  }

  const handleCountrySelect = (country: ServiceCountry) => {
    setSelectedCountry(country);
  };

  const handleSaveClick = () => {
    if (selectedCountry) {
      const storedLocale = localStorage.getItem(LOCALE_STORAGE_KEY);
      if (storedLocale) {
        const [languageCode, countryCode] = storedLocale.split("-");
        const newCountryCode = selectedCountry.code;

        // TODO: LOCALE 값은 핸드폰 세팅 고유값인지, 같이 바꿔줘야하는지 문의 필요
        localStorage.setItem(COUNTRY_CODE_STORAGE_KEY, newCountryCode);
        localStorage.setItem(LOCALE_STORAGE_KEY, `${languageCode}-${newCountryCode}`);
        console.log(`COUNTRY CODE CHANGED ${countryCode} -> ${newCountryCode}`);
      }

      setIsDialogOpen(true);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100vw",
          height: "100vh",
          alignItems: "center",
        }}
      >
        <Toolbar
          title={LocalizedText("ua_g740_servicecountry")}
          onBack={() => {
            window.history.back();
          }}
        />
        <VerticalSpacer height={12} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            px: "20px",
            boxSizing: "border-box",
          }}
        >
          <Typography fontStyle={fontStyle.headingS} color={color.default}>
            {LocalizedText("ua_b123_select_servicecountry")}
          </Typography>
          <VerticalSpacer height={8} />
          <Typography fontStyle={fontStyle.bodyLr} color={color.gray800}>
            {LocalizedText("ua_b123_select_servicecountry_message")}
          </Typography>
          <VerticalSpacer height={16} />

          {countries.map((country) => (
            <CountryButton
              key={country.code}
              title={country.name}
              image={country.flagImg}
              isSelected={selectedCountry?.code === country.code}
              onClick={() => handleCountrySelect(country)}
            />
          ))}
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: "0px",
            width: "100%",
            display: "flex",
          }}
        >
          <CommonButton
            title={LocalizedText("ua_g740_save")}
            textColor="white"
            onClick={handleSaveClick}
            sx={{
              mx: "20px",
              mb: "14px",
            }}
            disabled={!isModified || !selectedCountry}
          />
        </Box>

        <SimpleDialog
          isOpen={isDialogOpen}
          children={LocalizedText("ua_g740_complete_servicecountry")}
          onPositive={() => {
            window.history.back();
          }}
          onClose={() => {
            setIsDialogOpen(false);
          }}
          positiveText={LocalizedText("common_alert_confirm_button")}
        />
      </Box>
    </>
  );
};

export default ServiceCountryPage;
