import { useEffect, useState } from "react";
import CommonSearch from "../../../../../component/CommonSearch";
import { ModelManagementTable } from "./ModelManagementTable";
import { HeadCell } from "../../../../../component/CommonTable";
import { ModelData, ModelList } from "../model/ModelData";
import VerticalSpacer from "../../../../../component/Spacer";

export function ModelManagementPage() {
  const [searchContents, setSearchContents] = useState("");
  const [docCount, setDocCount] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [rows, setRows] = useState<ModelData[]>();
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    const filteredData = ModelList.filter((item) => {
      return searchContents
        ? item.partsCode.toLowerCase().includes(searchContents.toLowerCase()) ||
            item.partsCode.toLowerCase().includes(searchContents.toLowerCase()) ||
            item.productName.toLowerCase().includes(searchContents.toLowerCase()) ||
            item.category.toLowerCase().includes(searchContents.toLowerCase())
        : true;
    });
    const startData = page * rowsPerPage;
    const endData = startData + rowsPerPage;
    const pagedata = filteredData.slice(startData, endData);
    setRows(pagedata);
    setPageCount(Math.ceil(filteredData.length / rowsPerPage)); // 전체 페이지 수 계산
    setDocCount(filteredData.length);
  }, [page, rowsPerPage, searchContents]);

  return (
    <>
      <CommonSearch placeHolder={"Search"} searchContents={searchContents} setSearchContents={setSearchContents}></CommonSearch>
      <VerticalSpacer height={16} />
      <ModelManagementTable
        headCells={modelHeadCells}
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      ></ModelManagementTable>
    </>
  );
}

const modelHeadCells: HeadCell[] = [
  {
    disablePadding: false,
    align: "left",
    id: "no",
    label: "No",
  },
  {
    disablePadding: false,
    align: "left",
    id: "category",
    label: "Category",
  },
  {
    disablePadding: false,
    align: "left",
    id: "modelName",
    label: "Model Name",
  },
  {
    disablePadding: false,
    align: "left",
    id: "partsCode",
    label: "Parts Code",
  },
  {
    disablePadding: false,
    align: "left",
    id: "productName",
    label: "Product Name",
  },
  {
    disablePadding: false,
    align: "left",
    id: "lastUpdateDate",
    label: "Last Update Date",
  },
  {
    disablePadding: false,
    align: "left",
    id: "registerDate",
    label: "Register Date",
  },
];
