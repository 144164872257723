import { Box, Card, Grid, Typography } from "@mui/material";
import { color } from "../../../../../../theme/Color";
import VerticalSpacer, { HorizontalSpacer } from "../../../../../../component/Spacer";
import CommonTextField from "../../../../../../component/CommonTextField";
import LocalizedDate from "../../../../../../component/LocalizedDate";
import { fontStyle } from "../../../../../../theme/Style";
import { CommonSelect } from "../../../../../../component/CommonSelect";
import CommonDatePicker from "../../../../../../component/CommonDatePicker";
import { HeadCell } from "../../../../../../component/CommonTable";
import { useState } from "react";
import { RequestDetailGridTable } from "./RequestDetailGridTable";

const RequestDetailGrid = () => {
  const [docCount, setDocCount] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(4);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState(0);
  return (
    <>
      <Grid item lg={12}>
        {/* Request 카드 */}
        <Card
          sx={{
            borderRadius: "15px",
            border: "1px solid",
            borderColor: color.gray300,
            padding: "24px",
            paddingBottom: "40px",
            boxShadow: 0,
          }}
        >
          <Typography fontStyle={fontStyle.semiboldL}>Request</Typography>
          <VerticalSpacer height={16} />
          <Box sx={{ display: "flex", gap: "28px", justifyContent: "space-between" }}>
            {/* Request 카드 왼쪽 요소 */}
            <Box sx={{ width: "100%" }}>
              {/* 첫번째 줄 */}
              <Box sx={{ display: "flex", justifyContent: "space-between", gap: "16px" }}>
                <Box sx={{ width: "100%" }}>
                  <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                    Desired Pickup Date
                  </Typography>
                  <VerticalSpacer height={8} />
                  <CommonDatePicker value={null} textFieldSx={{ width: "100%" }} InputPropsSx={{ height: "48px" }} />
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                    Request Branch
                  </Typography>
                  <CommonSelect
                    selectValue={""}
                    menuitems={[]}
                    onChange={(event) => {}}
                    sx={{
                      height: "48px",
                      width: "100%",
                      mt: "8px",
                      fontStyle: fontStyle.regularS,
                      color: "black",
                    }}
                  />
                </Box>
              </Box>
              <VerticalSpacer height={24} />
              {/* 두번째 줄 */}
              <Box sx={{ display: "flex", justifyContent: "space-between", gap: "16px" }}>
                <Box sx={{ width: "100%" }}>
                  <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                    Branch
                  </Typography>
                  <CommonSelect
                    selectValue={""}
                    menuitems={[]}
                    onChange={(event) => {}}
                    sx={{
                      height: "48px",
                      width: "100%",
                      mt: "8px",
                      fontStyle: fontStyle.regularS,
                      color: "black",
                    }}
                  />
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                    Requester
                  </Typography>
                  <CommonSelect
                    selectValue={""}
                    menuitems={[]}
                    onChange={(event) => {}}
                    sx={{
                      height: "48px",
                      width: "100%",
                      mt: "8px",
                      fontStyle: fontStyle.regularS,
                      color: "black",
                    }}
                    disabled
                  />
                </Box>
              </Box>
              <VerticalSpacer height={24} />
              {/* 세번째 줄 */}
              <Box sx={{ display: "flex", justifyContent: "space-between", gap: "16px" }}>
                <Box sx={{ width: "100%" }}>
                  <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                    Branch
                  </Typography>
                  <CommonSelect
                    selectValue={""}
                    menuitems={[]}
                    onChange={(event) => {}}
                    sx={{
                      height: "48px",
                      width: "100%",
                      mt: "8px",
                      fontStyle: fontStyle.regularS,
                      color: "black",
                    }}
                    disabled
                  />
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                    Requester
                  </Typography>
                  <CommonSelect
                    selectValue={""}
                    menuitems={[]}
                    onChange={(event) => {}}
                    sx={{
                      height: "48px",
                      width: "100%",
                      mt: "8px",
                      fontStyle: fontStyle.regularS,
                      color: "black",
                    }}
                    disabled
                  />
                </Box>
              </Box>
            </Box>
            {/* Request 카드 오른쪽 요소 */}
            <Box sx={{ width: "100%" }}>
              {/* 첫번째 줄 */}
              <Box sx={{ display: "flex", justifyContent: "space-between", gap: "16px" }}>
                <Box sx={{ width: "100%" }}>
                  <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                    Comment
                  </Typography>
                  <CommonTextField
                    value=""
                    sx={{ width: "100%", height: "146px", mt: "8px" }}
                    InputProps={{
                      sx: {
                        height: "146px",
                      },
                    }}
                    rows={6}
                    multiline
                  />
                </Box>
              </Box>
              <VerticalSpacer height={24} />
              {/* 두번째 줄 */}
              <Box sx={{ display: "flex", justifyContent: "space-between", gap: "16px" }}>
                <Box sx={{ width: "100%" }}>
                  <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                    Branch
                  </Typography>
                  <CommonSelect
                    selectValue={""}
                    menuitems={[]}
                    onChange={(event) => {}}
                    sx={{
                      height: "48px",
                      width: "100%",
                      mt: "8px",
                      fontStyle: fontStyle.regularS,
                      color: "black",
                    }}
                    disabled
                  />
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                    Requester
                  </Typography>
                  <CommonTextField
                    value="-"
                    onChange={(event) => {}}
                    sx={{
                      height: "48px",
                      width: "100%",
                      mt: "8px",
                      fontStyle: fontStyle.regularS,
                      color: "black",
                    }}
                    InputProps={{
                      sx: {
                        height: "48px",
                      },
                    }}
                    disabled
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Card>
        <VerticalSpacer height={20} />
        {/* Request List 카드 */}
        <Card
          sx={{
            borderRadius: "15px",
            border: "1px solid",
            borderColor: color.gray300,
            padding: "24px",
            paddingBottom: "40px",
            boxShadow: 0,
          }}
        >
          <Typography fontStyle={fontStyle.semiboldL}>Request List</Typography>
          <VerticalSpacer height={16} />

          <RequestDetailGridTable
            headCells={RegisterBillOfMaterialTreeHeadCells}
            docCount={docCount}
            rowsPerPage={rowsPerPage}
            pageCount={pageCount}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            materialsList={rows}
          />
        </Card>
      </Grid>
    </>
  );
};

export default RequestDetailGrid;

const RegisterBillOfMaterialTreeHeadCells: HeadCell[] = [
  {
    disablePadding: false,
    align: "left",
    id: "partsCode",
    label: "Parts Code",
  },
  {
    disablePadding: false,
    align: "left",
    id: "productName",
    label: "Product Name",
  },
  {
    disablePadding: false,
    align: "left",
    id: "spec",
    label: "Spec",
  },
  {
    disablePadding: false,
    align: "left",
    id: "manufacturer",
    label: "Manufacturer",
  },
  {
    disablePadding: false,
    align: "left",
    id: "type",
    label: "Type",
  },
  {
    disablePadding: false,
    align: "left",
    id: "category",
    label: "Category",
  },
  {
    disablePadding: false,
    align: "left",
    id: "unit",
    label: "Unit",
  },
  {
    disablePadding: false,
    align: "left",
    id: "quantities",
    label: "Quantities",
  },
  // {
  //   disablePadding: false,
  //   align: "left",
  //   id: "amount",
  //   label: "Amount",
  // },
];
