import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  CircularProgress,
  Typography
} from "@mui/material";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { SERVER_CONFIG } from "../../../constants/ServerConfig";
import CommonList from "../../../component/CommonList";
import CommonSearch from "../../../component/CommonSearch";
import { Param, ParamType } from "../../../store/paramType";
import ParamDataTable from "../section/ParamDataTable";
import ParamDetailsPage from "./ParamDetailsPage";
import { useMethodologies } from "../../../context/MethodologyContext";
import { ADMIN_CONFIG } from "../../../constants/AdminConfig";
import axios from "axios";

interface FormData {
  id?: number;
  name: string;
  unit: ParamType | "";
  definition: string;
  version: string;
  methodologyIdName: string;
  methodologyId: number;
  standard: ParamType;
  region?: ParamType;
  description?: string;
  relatedDocumentFile?: File | null;
  fomula?: string;
}

const API_URL = SERVER_CONFIG.CARBON_SERVER + "/data-argument";

const ParamDataMngListPage: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const [isSaved, setIsSaved] = useState(false);
  const [standard, setStandard] = useState<string>("ALL");
  const [paramDataType, setParamDataType] = useState<string>("ALL");
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<Param[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedIdName, setSelectedIdName] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedParam, setSelectedParam] = useState<Param | null>(null);
  const [selectedRowId, setSelectedRowId] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [searchValue, setSearchValue] = useState("");

  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [showTabs, setShowTabs] = useState<boolean>(true);
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>("");
  const [paramFilter, setParamFilter] = useState<string>("ALL");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [formData, setFormData] = useState<FormData>({
    name: "",
    unit: "",
    definition: "",
    version: "",
    region: "" || undefined,
    methodologyIdName: "",
    methodologyId: 0,
    standard: ParamType.FIXED,
    fomula: ""
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(API_URL, {
        params: {
          page: page,
          size: rowsPerPage,
          standard: standard !== "ALL" ? standard : undefined,
          type: paramDataType !== "ALL" ? paramDataType : undefined,
          sort: "id,DESC",
          searchValue: debouncedSearchQuery.trim()
        }
      });

      const formattedData: Param[] = response.data.content.map((item: any) => ({
        ...item,
        value: parseFloat(item.value),
        createdAt: new Date(item.createdAt * 1000).toISOString()
      }));
      setData(formattedData);
      setTotalCount(response.data.totalElements || 0);
    } catch (error) {
      setError("Error fetching data");
      console.error("Error fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, standard, paramFilter, debouncedSearchQuery]);
  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 900);

    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [searchQuery]);
  const handleStandardChange = (event: SelectChangeEvent<string>) => {
    setStandard(event.target.value);
  };

  const handleParamDataTypeChange = (event: SelectChangeEvent<string>) => {
    setParamDataType(event.target.value);
  };

  const handleOpenModal = () => {
    setSelectedRowId(0);
    setModalOpen(true);
  };

  const handleRowClick = (row: Param) => {
    setSelectedRowId(row.id);
    setModalOpen(true);
  };

  const handleDelete = async (id: number) => {
    if (id === undefined) {
      return;
    }

    try {
      await axios.delete(`${API_URL}/${id}`);
      fetchData();
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const handleRowsPerPageChange = (value: number) => {
    setRowsPerPage(value);
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = setTimeout(() => {
      setDebouncedSearchQuery(value);
    }, 300);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedRowId(0);
    fetchData();
  };

  const headerButton = (
    <Button
      sx={{
        width: "113px",
        height: "45px",
        borderRadius: "40px",
        padding: "20px, 0px, 20px, 0",
        background: "#5D38E5",
        color: "#FFFFFF",
        textTransform: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textWeight: "600",
        textSize: "14px",
        lineHeight: "20px",
        "&:hover": {
          background: " #5a3acb"
        }
      }}
      onClick={handleOpenModal}
    >
      <AddIcon fontSize="small" />
      Create
    </Button>
  );

  return (
    <>
      <CommonList
        headerButton={headerButton}
        title={ADMIN_CONFIG.MENU_CARBON_REDUCTION_DATA_PARAMETER.TITLE}
      >
        <CommonSearch
          setRowsPerPage={handleRowsPerPageChange}
          setSearchContents={(value: any) => setSearchQuery(value)}
                    // onSearch={handleSearch}
          //           placeholder="데이터/인자, 방법론 ID 검색"
        >
          <Select
            id="standard"
            value={standard}
            onChange={handleStandardChange}
            IconComponent={(props) => (
              <KeyboardArrowDown {...props} sx={{ fontSize: "16px" }} />
            )}
            sx={{
              width: "13%",
              ml: 2,
              height: "42px",
              opacity: 1,
              backgroundColor: "#ffff",
              color: "#99989D",
              fontWeight: 600,
              fontSize: "12px",
              "&:hover": {
                borderColor: "#F9F6FD",
              },
              "& > fieldset": {
                border: 0,
                outline: 0,
              },
            }}
            style={{
              borderRadius: "10px",
              color: "#99989D",
              fontWeight: 600,
              fontSize: "12px",
              height: "42px",
            }}
          >
            <MenuItem
              sx={{ fontSize: "12px", fontWeight: "400", lineHeight: "18px" }}
              value="ALL"
            >
              All
            </MenuItem>
            <MenuItem
              sx={{ fontSize: "12px", fontWeight: "400", lineHeight: "18px" }}
              value="CHANGE"
            >
              변동
            </MenuItem>
            <MenuItem
              sx={{ fontSize: "12px", fontWeight: "400", lineHeight: "18px" }}
              value="FIXED"
            >
              고정
            </MenuItem>
          </Select>
        </CommonSearch>
        {loading ? (
          <div style={{ textAlign: "center", margin: "20px 0" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            {error ? (
              <div
                style={{ color: "red", textAlign: "center", marginTop: "10px" }}
              >
                {error}
              </div>
            ) : (
              <>
                <ParamDataTable
                  rowsPerPage={rowsPerPage}
                  data={data}
                  onDelete={handleDelete}
                  setRowsPerPage={handleRowsPerPageChange}
                  onRowClick={handleRowClick}
                  page={page}
                  setPage={setPage}
                  count={totalCount}
                />
              </>
            )}
          </>
        )}
      </CommonList>
      <ParamDetailsPage
        id={selectedRowId}
        open={modalOpen}
        onClose={handleCloseModal}
        onConfirm={fetchData}
        onDelete={() => formData.id !== undefined && handleDelete(formData.id)}
        title={selectedRowId ? "Update" : "Create"}
        content="Please fill out the parameter details below:"
      />
    </>
  );
};

export default ParamDataMngListPage;
