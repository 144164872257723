import CommonTable, { HeadCell, useVisibleRows } from "../../../../../component/CommonTable";
import { RequestList } from "./model/RequestData";
import { RequestManagementControlTableRow } from "./RequestManagementControlTableRow";

interface RequestManagementControlTableProps {
  headCells?: HeadCell[];
  rowsPerPage?: number;
  setRowsPerPage?: React.Dispatch<React.SetStateAction<number>>;
  page?: number;
  pageCount?: number;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  docCount?: any;
}

interface RequestDataType {
  [x: string]: string | number;
}

export function RequestManagementControlTable({
  headCells,
  rowsPerPage,
  setRowsPerPage,
  docCount,
  pageCount,
  page,
  setPage,
}: RequestManagementControlTableProps) {
  const { order, orderBy, handleRequestSort, handleChangePage, visibleRows } = useVisibleRows(RequestList, rowsPerPage || 10, setPage, page || 0);

  const pagedata: RequestDataType[] = visibleRows.slice((page || 0) * (rowsPerPage || 10), ((page || 0) + 1) * (rowsPerPage || 10));

  return (
    <CommonTable
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      page={page}
      docCount={docCount}
      pageCount={pageCount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      handleRequestSort={handleRequestSort}
      handleChangePage={handleChangePage}
      setPage={setPage}
    >
      {pagedata.map((data: RequestDataType, index: number) => (
        <RequestManagementControlTableRow row={data} key={index} />
      ))}
    </CommonTable>
  );
}
