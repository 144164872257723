import { Box, styled, TableCell, TableRow, Typography } from "@mui/material";
import { WalletDetailUiState } from "../model/WalletDetailUiState";
import { WalletDetailUiEvent } from "../model/WalletDetailUiEvent";
import CommonTable, {
  HeadCell,
  useVisibleRows,
} from "../../../../../component/CommonTable";
import { color } from "../../../../../theme/Color";
import { fontStyle } from "../../../../../theme/Style";

interface TransactionTableProps {
  uiState: WalletDetailUiState;
  uiEvent: WalletDetailUiEvent;
}

export function TransactionTable({ uiState, uiEvent }: TransactionTableProps) {
  const { order, orderBy, handleRequestSort, handleChangePage, visibleRows } =
    useVisibleRows(
      uiState.transactionInfo,
      uiState.rowsPerPage,
      (newPage: number) => uiEvent.handleUiStateChange("page", newPage),
      uiState.page
    );
  const TransactionTableCell = styled(TableCell)(({ theme }) => ({
    height: "26px",
    padding: "12px",
  }));
  return (
    <CommonTable
      headCells={TransactionHeadCells}
      headCellSx={{
        backgroundColor: color.gray200,
        fontStyle: fontStyle.semiboldS,
        color: color.gray800,
      }}
      docCount={uiState.docCount}
      page={uiState.page}
      pageCount={uiState.pageCount}
      rowsPerPage={uiState.rowsPerPage}
      setRowsPerPage={(newRowsPerPage: number) =>
        uiEvent.handleUiStateChange("rowsPerPage", newRowsPerPage)
      }
      setPage={(newPage: number) =>
        uiEvent.handleUiStateChange("page", newPage)
      }
      order={order}
      orderBy={orderBy}
      handleChangePage={handleChangePage}
      handleRequestSort={handleRequestSort}
    >
      {visibleRows.map(
        (data: { [x: string]: string | number | boolean }, index: number) => (
          <TableRow key={index} sx={{ height: "44px", padding: "10px 28px" }}>
            <TransactionTableCell sx={{ width: "120px" }}>
              <Typography
                fontStyle={fontStyle.regularS}
                sx={{ textAlign: "center" }}
              >
                {data.block}
              </Typography>
            </TransactionTableCell>
            <TransactionTableCell sx={{ width: "100px" }}>
              <Typography
                fontStyle={fontStyle.regularS}
                sx={{ textAlign: "center" }}
              >
                {data.txHash}
              </Typography>
            </TransactionTableCell>
            <TransactionTableCell sx={{ width: "100px" }}>
              <Typography
                fontStyle={fontStyle.regularS}
                sx={{ textAlign: "center" }}
              >
                {data.age}
              </Typography>
            </TransactionTableCell>
            <TransactionTableCell sx={{ width: "60px" }}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography
                  fontStyle={fontStyle.semiboldXXS}
                  sx={{
                    width: data.txType === true ? "26px" : "35px",
                    px: "8px",
                    py: "2px",
                    textAlign: "center",
                    backgroundColor:
                      data.txType === true
                        ? color.successCompleteBg
                        : color.proceedBg,
                    color:
                      data.txType === true
                        ? color.successComplete
                        : color.proceed,
                    borderRadius: "5px",
                  }}
                >
                  {data.txType === true ? "In" : "Out"}
                </Typography>
              </Box>
            </TransactionTableCell>
            <TransactionTableCell sx={{ width: "120px" }}>
              <Typography
                fontStyle={fontStyle.regularS}
                sx={{ textAlign: "center" }}
              >
                {data.form}
              </Typography>
            </TransactionTableCell>
            <TransactionTableCell sx={{ width: "120px" }}>
              <Typography
                fontStyle={fontStyle.regularS}
                sx={{ textAlign: "center" }}
              >
                {data.to}
              </Typography>
            </TransactionTableCell>
            <TransactionTableCell sx={{ width: "80px" }}>
              <Typography
                fontStyle={fontStyle.regularS}
                sx={{ textAlign: "center" }}
              >
                {data.value}
              </Typography>
            </TransactionTableCell>
            <TransactionTableCell sx={{ width: "120px" }}>
              <Typography
                fontStyle={fontStyle.regularS}
                sx={{ textAlign: "center" }}
              >
                {data.txFee}
              </Typography>
            </TransactionTableCell>
          </TableRow>
        )
      )}
    </CommonTable>
  );
}

const TransactionHeadCells: HeadCell[] = [
  {
    disablePadding: false,
    align: "center",
    id: "block",
    label: "Block",
  },
  {
    disablePadding: false,
    align: "center",
    id: "txHash",
    label: "TxHash",
  },
  {
    disablePadding: false,
    align: "center",
    id: "age",
    label: "Age",
  },
  {
    disablePadding: false,
    align: "center",
    id: "txType",
    label: "TxType",
  },
  {
    disablePadding: false,
    align: "center",
    id: "form",
    label: "Form",
  },
  {
    disablePadding: false,
    align: "center",
    id: "to",
    label: "To",
  },
  {
    disablePadding: false,
    align: "center",
    id: "value",
    label: "Value",
  },
  {
    disablePadding: false,
    align: "center",
    id: "txFee",
    label: "TxFee",
  },
];
