import { Backdrop, Box, Button, CircularProgress } from "@mui/material";
import CommonComboBox from "../../../../../component/CommonComboBox";
import { ApiService } from "../../../../../restAPI/ApiService";
import { CommonSelect } from "../../../../../component/CommonSelect";
import VerticalSpacer, { HorizontalSpacer } from "../../../../../component/Spacer";
import CommonNationComboBox, { NationComboBoxMode } from "../../../../../component/CommonNationComboBox";
import ProvinceSelectBox from "../../../../../pagesMobile/MobileProfileEdit/section/ProvinceSelectBox";
import CommonDateRange from "../../../../../component/CommonDateRange";
import { useEffect, useState } from "react";
import { Dayjs } from "dayjs";
import { fontStyle } from "../../../../../theme/Style";
import ScooterTabTable from "./component/ScooterTabTable";
import { ICON_DOWNLOAD } from "../../../../../constants/imagePath";
import CommonTextField from "../../../../../component/CommonTextField";
import { color } from "../../../../../theme/Color";
import ProgressBar from "../../../../../component/ProgressBar";

const ScooterTabPage = () => {
  const [docCount, setDocCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // 콤보 박스 검색어
  const [searchSNContents, setSearchSNContents] = useState("");

  // csv 다운로드 로딩동안 띄워줄 프로그래스바 상태관리 변수
  const [downloadLoading, setDownloadLoading] = useState(false);

  // 필터링을 위해 필요한 변수수
  const [scooterPN, setScooterPN] = useState("");

  const handleSearchSNContentsChange = (_: any, value: string) => {
    console.log(value);
    setSearchSNContents(value); // 검색어 업데이트
  };

  // 우측 상단 Date Range 관련 변수
  const [dateRange, setDateRange] = useState<{ startDate: Dayjs | null; endDate: Dayjs | null }>({
    startDate: null,
    endDate: null,
  });

  function handleBtnSearch() {
    setPage(0);
    const fmsData = {
      plateNumber: scooterPN,
      startDate: dateRange.startDate ? dateRange.startDate?.valueOf() : 0,
      endDate: dateRange.endDate ? dateRange.endDate?.valueOf() + 24 * 3600 * 1000 : 0,
      page: page,
    };
    ApiService.webPost("/fms/api/v1/scooter-info", fmsData).then((res) => {
      if (res.data) {
        setRows(res.data.body);
        if (res?.data.docCount > 10000) {
          setPageCount(Math.ceil(10000 / rowsPerPage) || 1);
          setDocCount(10000);
        } else {
          setPageCount(Math.ceil(res?.data.docCount / rowsPerPage) || 1);
          setDocCount(res?.data.docCount || 0);
        }
      }
    });
  }

  useEffect(() => {
    const fmsData = {
      plateNumber: scooterPN,
      startDate: dateRange.startDate ? dateRange.startDate?.valueOf() : 0,
      endDate: dateRange.endDate ? dateRange.endDate?.valueOf() + 24 * 3600 * 1000 : 0,
      page: page,
    };
    ApiService.webPost("/fms/api/v1/scooter-info", fmsData).then((res) => {
      if (res.data) {
        setRows(res.data.body);
        if (res?.data.docCount > 10000) {
          setPageCount(Math.ceil(10000 / rowsPerPage) || 1);
          setDocCount(10000);
        } else {
          setPageCount(Math.ceil(res?.data.docCount / rowsPerPage) || 1);
          setDocCount(res?.data.docCount || 0);
        }
      }
    });
  }, [rowsPerPage, page]);

  const csvDownload = () => {
    //
    return (
      <Box
        component="img"
        src={ICON_DOWNLOAD}
        sx={{ cursor: "pointer" }}
        onClick={() => {
          setDownloadLoading(true);
          const searchCondition = {
            plateNumber: scooterPN,
            startDate: dateRange.startDate ? dateRange.startDate?.valueOf() : 0,
            endDate: dateRange.endDate ? dateRange.endDate?.valueOf() + 24 * 3600 * 1000 : 0,
          };
          ApiService.webPost("/fms/api/v1/scooter-info-csv-file", searchCondition, {
            responseType: "blob",
          }).then((res) => {
            const blob = new Blob([res.data], { type: "text/csv;charset=utf-8;" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = "Scooter data.csv"; // 파일 이름 설정
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setDownloadLoading(false);
          });
        }}
      />
    );
  };

  return (
    <>
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={downloadLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box>
        <Box sx={{ display: "flex", gap: "16px" }}>
          {/* <CommonComboBox
            options={users}
            getOptionLabel={(option) => `${option.name} (${option.email})`} // 라벨 형식 정의
            renderOption={(props, option) => (
              <li {...props}>
                {option.name} <br /> {option.phoneNumber} <br /> {option.email}
              </li>
            )} // 옵션 렌더링 정의
            onKeyPress={() => {
              ApiService.WebPost("/pw/users/userList", {
                size: 10,
                page: 1,
                searchCondition: searchContents,
                sns: "",
                status: "",
                membership: "",
              }).then((res) => {
                if (res?.data.body) {
                  const formattedRows = res.data.body.list.map((item: any) => ({
                    uuid: item?.value?.uuid || "Unknown",
                    name: item?.value?.name || "Unknown",
                    phoneNumber: item?.value?.phoneNumber || "No Number",
                    email: item?.value?.email || "No Email",
                  }));
                  setUsers(formattedRows);
                }
              });
            }}
            sx={{
              width: "50%",
            }}
            onInputChange={handleInputChange} // 검색어 변경 시 호출
            onChange={(event, value) => {
              if (value?.uuid) {
                setUserUuid(value?.uuid);
              } else {
                setUserUuid("");
              }
            }}
            placeholder="User Name or Email"
          /> */}
          <CommonTextField
            value={scooterPN}
            onChange={(event) => {
              setScooterPN(event.target.value);
              // console.log(event.target.value);
            }}
            sx={{
              width: "50%",
              height: "42px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "10px",
                "& fieldset": {
                  borderColor: "white", // 기본 border 색상
                },
                "&:hover fieldset": {
                  borderColor: "white", // hover 시 border 색상
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white", // 포커스 시 border 색상
                  borderWidth: "1px",
                },
                "&.Mui-disabled fieldset": {
                  backgroundColor: color.gray200,
                  borderColor: color.gray300, // 비활성화 시 border 색상
                },
              },
            }}
            InputProps={{
              sx: {
                height: "42px",
                backgroundColor: "white",
              },
            }}
            placeholder="Scooter Plate Number"
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === "Enter") {
                event.preventDefault(); // 기본 Enter 동작 방지 (ex. 폼 제출)
                handleBtnSearch();
              }
            }}
          />
          {/* <CommonComboBox
            options={scooterSNList}
            getOptionLabel={(option) => `${option.scooterSN}`} // 라벨 형식 정의
            renderOption={(props, option) => <li {...props}>{option.scooterSN}</li>} // 옵션 렌더링 정의
            onKeyPress={() => {
              ApiService.WebPost("/pw/backend/api/scooter/searchCondition", {
                productName: "",
                searchCondition: searchSNContents,
                size: 10,
                page: 0,
              }).then((res) => {
                if (res?.data.body) {
                  const formattedRows = res.data.body.scooterList.map((item: any) => ({
                    scooterSN: item?.serialNumber || "Unknown",
                  }));
                  setScooterSNList(formattedRows);
                }
              });
            }}
            sx={{
              width: "50%",
            }}
            onInputChange={handleSearchSNContentsChange} // 검색어 변경 시 호출
            onChange={(event, value) => {
              if (value?.scooterSN) {
                setScooterSN(value?.scooterSN);
              } else {
                setScooterSN("");
              }
            }}
            placeholder="Scooter S/N"
          /> */}
          {/* <CommonSelect
            selectValue={scooterName}
            menuitems={modelNames ? ["All", ...modelNames.map((item: any) => item)] : []}
            onChange={(event) => {
              const selectedValue = event?.target.value;
              // "All"일 경우 빈 문자열로 설정
              setScooterName(selectedValue === "All" ? "" : selectedValue);
            }}
            placeholder="Scooter Name"
            sx={{
              height: "42px",
              width: "25%",
              fontStyle: fontStyle.regularS,
              "&.MuiOutlinedInput-root": {
                "& .MuiSelect-select": {
                  display: "flex",
                  alignItems: "center",
                  paddingRight: "16px !important",
                },
                "& fieldset": {
                  borderColor: "white",
                  zIndex: 400,
                },
                "&:hover fieldset": {
                  borderColor: "white",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                  borderWidth: "1px",
                },
              },
            }}
          /> */}
          <CommonDateRange
            value={dateRange}
            onChange={(newValue) => setDateRange(newValue)}
            views={["year", "month", "day"]}
            textFieldSx={{
              // width: "170px",
              height: "42px",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white", // 기본 보더 색상
                },
                "&:hover fieldset": {
                  borderColor: "white",
                },
                "&.Mui-focused fieldset": {
                  border: "1px solid",
                  borderColor: "white",
                },
              },
            }}
            InputPropsSx={{ height: "42px" }}
          />
          <Button
            sx={{
              backgroundColor: "#5D38E5",
              borderRadius: 5,
              ":hover": {
                backgroundColor: "#A38BFC",
              },
              color: "white",
              textTransform: "none",
              width: "85px",
              height: "40px",
            }}
            onClick={handleBtnSearch}
          >
            Search
          </Button>
        </Box>
        {/* <VerticalSpacer height={16} />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <CommonNationComboBox
            handleChange={(value) => {
              setCountry(value);
            }}
            mode={NationComboBoxMode.ADMIN}
            placeholder="Country"
          />
          <HorizontalSpacer width={16} />
          <ProvinceSelectBox
            country={country}
            value={province}
            onChange={(event) => {
              setProvince(event);
            }}
            mode={NationComboBoxMode.ADMIN}
            placeholder="City / State / Province"
          />
          <HorizontalSpacer width={16} />
          <CommonDateRange
            value={dateRange}
            onChange={(newValue) => setDateRange(newValue)}
            views={["year", "month", "day"]}
            textFieldSx={{
              // width: "170px",
              height: "42px",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white", // 기본 보더 색상
                },
                "&:hover fieldset": {
                  borderColor: "white",
                },
                "&.Mui-focused fieldset": {
                  border: "1px solid",
                  borderColor: "white",
                },
              },
            }}
            InputPropsSx={{ height: "42px" }}
          />
          <HorizontalSpacer width={16} />
          <Button
            sx={{
              backgroundColor: "#5D38E5",
              borderRadius: 5,
              ":hover": {
                backgroundColor: "#A38BFC",
              },
              color: "white",
              textTransform: "none",
              width: "85px",
              height: "40px",
            }}
            onClick={handleBtnSearch}
          >
            Search
          </Button>
        </Box> */}
      </Box>
      <VerticalSpacer height={16} />
      <ScooterTabTable
        rows={rows}
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        csvDownload={csvDownload()}
      />
    </>
  );
};

export default ScooterTabPage;
