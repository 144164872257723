import { Box, IconButton, TableCell, TableRow, Typography } from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import { HorizontalSpacer } from "../../../component/Spacer";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { color } from "../../../theme/Color";
import LocalizedDate, { DateFormatType } from "../../../component/LocalizedDate";
import CommonTable, { useVisibleRows } from "../../../component/CommonTable";
import { StatusBadge } from "./StatusBadge";

interface RowProps {
  row: any;
  index: any;
}

const Row = ({ row, index }: RowProps) => {
  const navigate = useNavigate();
  return (
    <>
      <TableRow
        onClick={() => {
          navigate(`/csMng/csDetails/${row.id}`);
        }}
      >
        <TableCell align="center" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.no}</Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <StatusBadge status={row.status}/>
        </TableCell>
        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.preferredStation}</Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.title}</Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.reporter}</Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.writer}</Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {row.completeDate == 0 ? (
              <></>
            ) : (
              <>
                {LocalizedDate({
                  date: row.completeDate,
                  type: DateFormatType.AdminType,
                })}
              </>
            )}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {LocalizedDate({
              date: row.registrationDate,
              type: DateFormatType.AdminType,
            })}
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
};

interface CsMngTableProps {
  mngRows?: any;
  rowsPerPage?: any;
  setRowsPerPage?: any;
  page?: any;
  pageCount?: any;
  setPage?: any;
  docCount?: number;
}

const CsMngTable = ({ mngRows, rowsPerPage, setRowsPerPage, docCount, page, pageCount, setPage }: CsMngTableProps) => {
  const headCells = [
    {
      id: "no",
      align: "center",
      disablePadding: false,
      label: "No",
    },

    {
      id: "status",
      align: "left",
      disablePadding: false,
      label: "Status",
    },

    {
      id: "preferredCSStation",
      align: "left",
      disablePadding: false,
      label: "Preferred CS Station",
    },

    {
      id: "title",
      align: "left",
      disablePadding: false,
      label: "Title",
    },

    {
      id: "reporter",
      align: "left",
      disablePadding: false,
      label: "Reporter",
    },
    {
      id: "writer",
      align: "left",
      disablePadding: false,
      label: "Writer",
    },
    {
      id: "completeDate",
      align: "left",
      disablePadding: false,
      label: "Complete Date",
    },
    {
      id: "registrationDate",
      align: "left",
      disablePadding: false,
      label: "Registration Date",
    },
  ];

  const { order, orderBy, handleRequestSort, handleChangePage, visibleRows } = useVisibleRows(mngRows || [], rowsPerPage, setPage, page);
  return (
    <CommonTable
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      page={page}
      docCount={docCount}
      pageCount={pageCount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      handleRequestSort={handleRequestSort}
      handleChangePage={handleChangePage}
      setPage={setPage}
    >
      {visibleRows?.length == 0 ? (
        <TableRow sx={{ px: " 4px", height: "1px" }}>
          <TableCell
            colSpan={headCells?.length}
            align="center"
            sx={{
              minWidth: "1032px",
              px: "13px",
              py: "12px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontStyle: fontStyle.regularS,
                color: color.gray600,
              }}
            >
              No Data
            </Typography>
          </TableCell>
        </TableRow>
      ) : (
        visibleRows.map((row: any, index: any) => <Row row={row} index={index} key={index} />)
      )}
    </CommonTable>
  );
};

export default CsMngTable;
