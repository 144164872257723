import { Box, Typography } from "@mui/material";
import { color } from "../../../theme/Color";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { fontStyle } from "../../../theme/Style";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";

interface CharacterNFTData {
  type: string;
  name: string;
  image: string;
  issuedDate: string;
}

interface CharacterNFTSwiperProps {
  characters: CharacterNFTData[];
  onClick: (character: CharacterNFTData) => void;
}

const CharacterNFTSwiper = ({
  characters,
  onClick,
}: CharacterNFTSwiperProps) => {
  const swiperStyles = {
    "--swiper-pagination-bottom": "0px",
    "--swiper-pagination-color": color.primary500,
    "--swiper-pagination-bullet-inactive-color": color.primary200,
    "--swiper-pagination-bullet-size": "6px",
    "--swiper-pagination-bullet-horizontal-gap": "2px",
  } as React.CSSProperties;

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        "& .swiper-pagination": {
          position: "relative",
        },
        "& .swiper-pagination-bullet": {
          width: "6px",
          height: "6px",
          backgroundColor: color.primary200,
          opacity: 1,
          borderRadius: "3px",
          transition: "all 0.3s ease",
        },
        "& .swiper-pagination-bullet-active": {
          width: "15px",
          borderRadius: "3px",
          backgroundColor: color.primary500,
        },
      }}
    >
      <Swiper
        style={swiperStyles}
        slidesPerView={"auto"}
        centeredSlides={true}
        spaceBetween={8}
        pagination={{
          clickable: true,
          bulletClass: "swiper-pagination-bullet",
          bulletActiveClass: "swiper-pagination-bullet-active",
        }}
        modules={[Pagination]}
      >
        {characters.map((character, index) => (
          <SwiperSlide key={index} style={{ width: "calc(100% - 40px)" }}>
            <Box
              onClick={() => onClick(character)}
              sx={{
                bgcolor: "white",
                borderRadius: "10px",
                p: "16px",
                display: "flex",
                gap: "16px",
                alignItems: "center",
              }}
            >
              <Box
                component="img"
                src={character.image}
                alt={character.name}
                sx={{
                  width: "80px",
                  height: "80px",
                  borderRadius: "5px",
                  objectFit: "cover",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2px",
                }}
              >
                <Typography
                  fontStyle={fontStyle.subTitleS}
                  color={color.gray700}
                >
                  {character.type} NFT
                </Typography>
                <Typography fontStyle={fontStyle.titleL} color={color.default}>
                  {character.name}
                </Typography>
                <Box sx={{ display: "flex", gap: "8px" }}>
                  <Typography
                    fontStyle={fontStyle.bodyMr}
                    color={color.gray600}
                  >
                    {LocalizedText("ua_h100_issued")}
                  </Typography>
                  <Typography
                    fontStyle={fontStyle.bodyMr}
                    color={color.gray900}
                  >
                    {character.issuedDate}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default CharacterNFTSwiper;
