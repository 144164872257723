import { Box, Typography } from "@mui/material";
import CommonTable, { HeadCell, useVisibleRows } from "../../../../component/CommonTable";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import { RegisterFindPartsTableRow } from "./RegisterFindPartsTableRow";
import { useEffect, useState } from "react";

interface RegisterFindPartsTableProps {
  headCells: HeadCell[];
  rowsPerPage: number;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  pageCount: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  docCount: number;
  materialsList: any[];
}

export function RegisterFindPartsTable({
  headCells,
  rowsPerPage,
  setRowsPerPage,
  docCount,
  page,
  setPage,
  materialsList,
}: RegisterFindPartsTableProps) {
  const { order, orderBy, handleChangePage, handleRequestSort, visibleRows } = useVisibleRows(materialsList, rowsPerPage || 6, setPage, page || 0);
  const pagedata: any = visibleRows;

  // checkbox 상태 관리
  const [checkboxState, setCheckboxState] = useState(false);

  const handleCheckboxChange = (state: boolean) => {
    setCheckboxState(state);
  };

  useEffect(() => {
    console.log(checkboxState);
  }, [checkboxState]);
  return (
    <Box>
      <CommonTable
        headCells={headCells}
        order={order}
        orderBy={orderBy}
        page={page}
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        handleChangePage={handleChangePage}
        handleRequestSort={handleRequestSort}
        setPage={setPage}
        paginationEnabled={false}
        checkbox={true}
        checkboxState={checkboxState}
        onCheckboxChange={handleCheckboxChange} // 상태 변경 핸들러 전달
      >
        {pagedata.map((data: any) => (
          <RegisterFindPartsTableRow row={data} key={data.partsCode} parentCheckboxState={checkboxState} onCheckboxChange={handleCheckboxChange} />
        ))}
      </CommonTable>
      {pagedata?.length == 0 ? (
        <Typography
          sx={{
            textAlign: "center",
            fontStyle: fontStyle.regularXS,
            color: color.gray600,
          }}
        >
          No Data
        </Typography>
      ) : null}
    </Box>
  );
}
