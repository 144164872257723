import { useState } from "react";
import { TabType } from "./model/TabType";
import { EcoPlatformWalletUiEvent } from "./model/EcoPlatformWalletUiEvent";
import { EcoPlatformWalletPage } from "./EcoPlatformWalletPage";
import { EcoPlatformWalletUiState } from "./model/EcoPlatformWalletUiState";

export function EcoPlatformWallet() {
  const [uiState, setUiState] = useState(new EcoPlatformWalletUiState());
  const [currentTab, setCurrentTab] = useState<TabType>(TabType.WALLET);

  const uiEvent: EcoPlatformWalletUiEvent = {
    onClickTab: function (tab: TabType): void {
      setCurrentTab(tab);
    },

    addressButtonClick: () => {
      setUiState((prev) => ({
        ...prev,
        infoTipOpen: true,
      }));
    },

    handleUiStateChange: (key: keyof EcoPlatformWalletUiState, value: any) => {
      setUiState((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    },

    copyClipboard: (id: string) => {
      navigator.clipboard.writeText(id);
    },
  };

  return (
    <EcoPlatformWalletPage
      currentTab={currentTab}
      uiEvent={uiEvent}
      uiState={uiState}
    />
  );
}
