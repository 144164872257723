import { useNavigate } from "react-router-dom";
import CommonList from "../../../../component/CommonList";
import { CommonWebHeaderButton } from "../../../../component/CommonWebHeaderButton";
import { ModelManagementPage } from "./section/ModelManagementPage";

const ModelManagement = () => {
  const navigate = useNavigate()
  return (
    <CommonList
      title="Product Name"
      headerButton={
        <CommonWebHeaderButton
          width="122px"
          height="45px"
          buttonContent="Register"
          addIcon={true}
          onClick={() => {
            navigate("/modelRegistration")
          }}
        />
      }
    >
      <ModelManagementPage />
    </CommonList>
  );
};

export default ModelManagement;
