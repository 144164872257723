import { Box } from "@mui/material";
import EmptyMyScooter from "./section/EmptyMyScooterImage";
import CommonButton from "../../component/CommonButton";
import { LocalizedText } from "../../di/LanguageRepositoryProvider";
import { useNavigate } from "react-router-dom";
import useAndroidBackHandler from "../../customHook/useAndroidBackHandler";
import { ROUTE_PATHS } from "../../constants/RouteConstants";

export default function EmptyScooterScreen() {
  const navigate = useNavigate();

  useAndroidBackHandler(() => {
    window.Android.onBackStackCleared();
  });

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <EmptyMyScooter
          title={LocalizedText("ua_e100_needcontract_title")}
          subTitle={LocalizedText("ua_e100_needcontract_message")}
        />
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: "0px",
          width: "100%",
          display: "flex",
        }}
      >
        <CommonButton
          title={LocalizedText("ua_e100_inquirecontract_button")}
          textColor="white"
          onClick={() => {
            navigate(ROUTE_PATHS.INQUIRY_PAGE);
          }}
          sx={{
            mx: "20px",
            mb: "14px",
          }}
        />
      </Box>
    </Box>
  );
}
