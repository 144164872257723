import { useState } from "react";
import { ProductNameRegistrationUiState } from "./model/ProductNameRegistrationUiState";
import { ProductNameRegistrationPage } from "./ProductNameRegistrationPage";
import { ProductNameRegistrationUiEvent } from "./model/ProductNameRegistrationUiEvent";
import { useNavigate } from "react-router-dom";
import { ApiService } from "../../../../../restAPI/ApiService";
import axios from "axios";

export function ProductNameRegistration() {
  const navigate = useNavigate();
  const [uiState, setUiState] = useState(new ProductNameRegistrationUiState());
  const uiEvent: ProductNameRegistrationUiEvent = {
    HandleUiStateChange: (
      key: keyof ProductNameRegistrationUiState,
      value: any
    ) => {
      setUiState((prevState) => ({
        ...prevState,
        [key]: value, // key에 해당하는 상태를 업데이트
      }));
    },
    backButtonClick: () => {
      uiEvent.HandleUiStateChange("backDialogOpen", true);
    },

    backClose: () => {
      uiEvent.HandleUiStateChange("backDialogOpen", false);
    },
    backOk: () => {
      navigate("/modelManagement");
      window.location.reload();
    },
    registerButtonClick: () => {
      uiEvent.HandleUiStateChange("registerDialogOpen", true);
    },

    registerClose: () => {
      uiEvent.HandleUiStateChange("registerDialogOpen", false);
    },
    registerOk: () => {
      uiEvent.HandleUiStateChange("registerDialogOpen", false);
      checkAndRegister();
    },
  };

  const checkAndRegister = async () => {
    try {
      const result = await registerProductName();
      if (!result) {
        return;
      }
      if (result) {
        navigate(`/modelDetail/${uiState.ProductId}`);
      } else {
        console.log("regist failed");
      }
    } catch (e) {
      console.log("regist error", e);
    }
  };

  async function registerProductName(): Promise<boolean | undefined> {
    const url = "/pw/aw/material/prodName/create";
    const requestBody = {
      id: uiState.ProductId,
      prodName: uiState.ProductName,
    };
    try {
      const res = await ApiService.webPost(url, requestBody);
      const data = res.data;
      if (typeof data === "object" && Object.keys(data).length === 0)
        return undefined;
      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.error("서버 에러 응답: ", e.response?.data);
      }
      throw e;
    }
  }
  return <ProductNameRegistrationPage uiState={uiState} uiEvent={uiEvent} />;
}
