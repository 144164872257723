import { useEffect, useState } from "react";
import { CarbonReductionPage } from "./CarbonReductionPage";
import { CarbonReductionUiState } from "./model/CarbonReductionUiState";
import { CarbonReductionUiEvent } from "./model/CarbonReductionUiEvent";
import { TabType } from "./model/TabType";
import {
  CharacterResponse,
  CouponResponse,
  ReductionHistoryResponse,
} from "./model/CarbonReductionResponse";
import { ApiService } from "../../../../restAPI/ApiService";
import { CarbonReductionMapper } from "./mapper/CarbonReductionMapper";

export function CarbonReduction() {
  const [uiState, setUiState] = useState(new CarbonReductionUiState());
  const itemsPerPage = 3;

  const UiEvent: CarbonReductionUiEvent = {
    OnClickTab: function (tab: TabType): void {
      setUiState((prev) => ({
        ...prev,
        currentTab: tab,
      }));
    },

    handlePrevPage: () => {
      setUiState((prev) => ({
        ...prev,
        currentPage: Math.max(prev.currentPage - 1, 0),
      }));
    },

    handleNextPage: () => {
      setUiState((prev) => ({
        ...prev,
        currentPage: Math.min(
          prev.currentPage + 1,
          Math.ceil(uiState.characterInfo.length / itemsPerPage) - 1
        ),
      }));
    },

    handleLearnMore: () => {
      setUiState((prev) => ({
        ...prev,
        visibleCoupon: uiState.visibleCoupon === 6 ? uiState.coupon.length : 6,
      }));
    },

    handlePrevYear: () => {
      setUiState((prev) => ({
        ...prev,
        historyYear: uiState.historyYear - 1,
      }));
    },

    handleNextYear: () => {
      setUiState((prev) => ({
        ...prev,
        historyYear: uiState.historyYear + 1,
      }));
    },
  };

  async function getCharacterInfo(): Promise<CharacterResponse | undefined> {
    const url = "";
    const requestBody = {};

    try {
      const res = await ApiService.webPost(url, requestBody);
      const data = res.data;
      if (typeof data === "object" && Object.keys(data).length === 0)
        return undefined;
      return data;
    } catch (e) {
      setUiState((prev) => ({
        ...prev,
        characterInfo: [],
      }));
      return undefined;
    }
  }

  async function getCouponInfo(): Promise<CouponResponse | undefined> {
    const url = "";
    const requestBody = {};

    try {
      const res = await ApiService.webPost(url, requestBody);
      const data = res.data;
      if (typeof data === "object" && Object.keys(data).length === 0)
        return undefined;
      return data;
    } catch (e) {
      setUiState((prev) => ({
        ...prev,
        coupon: [],
      }));
      return undefined;
    }
  }
  async function getReductionHistory(): Promise<
    ReductionHistoryResponse | undefined
  > {
    const url = "";
    const requestBody = {};

    try {
      const res = await ApiService.webPost(url, requestBody);
      const data = res.data;
      if (typeof data === "object" && Object.keys(data).length === 0)
        return undefined;
      return data;
    } catch (e) {
      setUiState((prev) => ({
        ...prev,
        reductionHistory: [],
        reductionHistoryDay: [],
        totalCarbonReduction: 0,
      }));
      return undefined;
    }
  }

  useEffect(() => {
    const fetchCharacterInfo = async () => {
      try {
        let characterResult = false;
        let couponResult = false;
        let reductionHistoryResult = false;
        const characterInfo = await getCharacterInfo();
        const couponInfo = await getCouponInfo();
        const reductionHistory = await getReductionHistory();
        if (characterInfo === undefined) {
          console.log("Character not found");
          characterResult = true;
        }
        if (couponInfo === undefined) {
          console.log("couponInfo not found");
          couponResult = true;
        }
        if (reductionHistory === undefined) {
          console.log("reductionHistory not found");
          reductionHistoryResult = true;
        }
        if (
          characterResult === true ||
          couponResult === true ||
          reductionHistoryResult === true
        ) {
          return;
        }
        setUiState(
          CarbonReductionMapper(
            characterInfo as CharacterResponse,
            couponInfo as CouponResponse,
            reductionHistory as ReductionHistoryResponse
          )
        );
      } catch (e) {
        console.log("CarbonReduction Error");
      }
    };
    fetchCharacterInfo();
  }, [uiState.historyYear]);

  return <CarbonReductionPage uiState={uiState} uiEvent={UiEvent} />;
}
