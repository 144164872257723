import { Backdrop, Box, Button, CircularProgress } from "@mui/material";
import CommonDateRange from "../../../../../component/CommonDateRange";
import CommonNationComboBox, { NationComboBoxMode } from "../../../../../component/CommonNationComboBox";
import VerticalSpacer, { HorizontalSpacer } from "../../../../../component/Spacer";
import ProvinceSelectBox from "../../../../../pagesMobile/MobileProfileEdit/section/ProvinceSelectBox";
import CommonComboBox from "../../../../../component/CommonComboBox";
import { ApiService } from "../../../../../restAPI/ApiService";
import { useEffect, useState } from "react";
import { Dayjs } from "dayjs";
import StationPowerUsageTable from "./component/StationPowerUsageTable";
import { ICON_DOWNLOAD } from "../../../../../constants/imagePath";
import CommonTextField from "../../../../../component/CommonTextField";

const StationPowerUsagePage = () => {
  const [docCount, setDocCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [stationName, setStationName] = useState("");

  // csv 다운로드 로딩동안 띄워줄 프로그래스바 상태관리 변수
  const [downloadLoading, setDownloadLoading] = useState(false);

  // 우측 상단 Date Range 관련 변수
  const [dateRange, setDateRange] = useState<{ startDate: Dayjs | null; endDate: Dayjs | null }>({
    startDate: null,
    endDate: null,
  });

  function handleBtnSearch() {
    if (stationName) {
      setPage(0);
      const SPUData = {
        stationName: stationName,
        startDate: dateRange.startDate ? dateRange.startDate.valueOf() : 0,
        endDate: dateRange.endDate ? dateRange.endDate.valueOf() + 24 * 3600 * 1000 : 0,
        page: 0,
      };
      ApiService.webPost("/pw/csms/data/stationPowerUsage", SPUData).then((res) => {
        if (res.data) {
          setRows(res.data.body);
          if (res?.data.docCount > 10000) {
            setPageCount(Math.ceil(10000 / rowsPerPage) || 1);
            setDocCount(10000);
          } else {
            setPageCount(Math.ceil(res?.data.docCount / rowsPerPage) || 1);
            setDocCount(res?.data.docCount || 0);
          }
        }
      });
    } else {
      alert("Station name을 입력하십시오");
    }
  }

  useEffect(() => {
    console.log(page);
    const SPUData = {
      stationName: stationName,
      startDate: dateRange.startDate ? dateRange.startDate.valueOf() : 0,
      endDate: dateRange.endDate ? dateRange.endDate.valueOf() + 24 * 3600 * 1000 : 0,
      page: page,
    };
    ApiService.webPost("/pw/csms/data/stationPowerUsage", SPUData).then((res) => {
      if (res.data) {
        setRows(res.data.body);
        if (res?.data.docCount > 10000) {
          setPageCount(Math.ceil(10000 / rowsPerPage) || 1);
          setDocCount(10000);
        } else {
          setPageCount(Math.ceil(res?.data.docCount / rowsPerPage) || 1);
          setDocCount(res?.data.docCount || 0);
        }
      }
    });
  }, [page]);

  const csvDownload = () => {
    return (
      <Box
        component="img"
        src={ICON_DOWNLOAD}
        sx={{ cursor: "pointer" }}
        onClick={() => {
          setDownloadLoading(true);
          const searchCondition = {
            stationName: stationName,
            startDate: dateRange.startDate ? dateRange.startDate?.valueOf() : 0,
            endDate: dateRange.endDate ? dateRange.endDate?.valueOf() + 24 * 3600 * 1000 : 0,
          };
          ApiService.webPost("/pw/csms/data/stationPowerUsage-csv-file", searchCondition, {
            responseType: "blob",
          }).then((res) => {
            const blob = new Blob([res.data], { type: "text/csv;charset=utf-8;" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = "Scooter data.csv"; // 파일 이름 설정
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setDownloadLoading(false);
          });
        }}
      />
    );
  };
  return (
    <>
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={downloadLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {/* <CommonComboBox
            options={stationSNList}
            getOptionLabel={(option) => `${option.svcId}`} // 라벨 형식 정의
            renderOption={(props, option) => <li {...props}>{option.svcId}</li>} // 옵션 렌더링 정의
            onKeyPress={() => {
              ApiService.webPost("/pw/backend/api/svc/findBySvcId", {
                svcCode: searchSNContents,
              }).then((res) => {
                if (res?.data) {
                  const formattedRows = res.data.body.map((item: any) => ({
                    svcId: item?.svcId || "Unknown",
                    svcCode: item?.svcCode || "Unknown",
                  }));
                  setStationSNList(formattedRows);
                }
              });
            }}
            sx={{
              width: "50%",
            }}
            onInputChange={handleSearchSNContentsChange} // 검색어 변경 시 호출
            onChange={(event, value) => {
              if (value?.svcId) {
                setStationId(value?.svcId);
              } else {
                setStationId(0);
              }
            }}
            placeholder="Station S/N"
            // disabled={batterySN ? true : false}
          /> */}
          <CommonTextField
            value={stationName}
            onChange={(event) => {
              setStationName(event.target.value);
              // // console.log(event.target.value);
            }}
            sx={{
              width: "50%",
              height: "42px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "10px",
                "& fieldset": {
                  borderColor: "white", // 기본 border 색상
                },
                "&:hover fieldset": {
                  borderColor: "white", // hover 시 border 색상
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white", // 포커스 시 border 색상
                  borderWidth: "1px",
                },
                "&.Mui-disabled fieldset": {
                  // backgroundColor: color.gray100,
                  borderColor: "white", // 비활성화 시 border 색상
                },
              },
            }}
            InputProps={{
              sx: {
                height: "42px",
                backgroundColor: "white",
              },
            }}
            placeholder="Station name"
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === "Enter") {
                event.preventDefault(); // 기본 Enter 동작 방지 (ex. 폼 제출)
                handleBtnSearch();
              }
            }}
          />
          <HorizontalSpacer width={16} />
          <CommonDateRange
            value={dateRange}
            onChange={(newValue) => setDateRange(newValue)}
            views={["year", "month", "day"]}
            textFieldSx={{
              // width: "170px",
              height: "42px",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white", // 기본 보더 색상
                },
                "&:hover fieldset": {
                  borderColor: "white",
                },
                "&.Mui-focused fieldset": {
                  border: "1px solid",
                  borderColor: "white",
                },
              },
            }}
            InputPropsSx={{ height: "42px" }}
          />
          <HorizontalSpacer width={16} />
          <Button
            sx={{
              backgroundColor: "#5D38E5",
              borderRadius: 5,
              ":hover": {
                backgroundColor: "#A38BFC",
              },
              color: "white",
              textTransform: "none",
              width: "85px",
              height: "40px",
            }}
            onClick={handleBtnSearch}
          >
            Search
          </Button>
        </Box>
      </Box>
      <VerticalSpacer height={16} />
      <StationPowerUsageTable
        rows={rows}
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        csvDownload={csvDownload()}
      />
    </>
  );
};

export default StationPowerUsagePage;
