import { Card, Grid } from "@mui/material";
import CommonList from "../../../../../../../component/CommonList";
import { CommonWebHeaderButton } from "../../../../../../../component/CommonWebHeaderButton";
import { color } from "../../../../../../../theme/Color";
import { MaterialDetailsBOMTree } from "./MaterialDetailsBOMTree";
import VerticalSpacer from "../../../../../../../component/Spacer";
import { MaterialDetailsQuantity } from "./MaterialDetailsQuantity";
import { MaterialDetailsDate } from "./MaterialDetailsDate";
import { useNavigate } from "react-router-dom";

export function MaterialDetails() {
  const navigate = useNavigate();
  const onClick = () => {
    navigate("/billOfMaterialsDetails");
  };
  return (
    <CommonList
      title="자재 상세"
      headerBackButton={true}
      navigateURL={`/productionManagement?tab=materials`}
      headerButton={
        <CommonWebHeaderButton buttonContent="Update" height="45px" sx={{ display: "flex", flexDirection: "column" }} onClick={onClick} />
      }
    >
      <Grid item lg={12}>
        <Card
          sx={{
            borderRadius: "15px",
            border: "1px solid",
            borderColor: color.gray300,
            padding: "24px",
            paddingBottom: "40px",
            boxShadow: 0,
          }}
        >
          <MaterialDetailsBOMTree />
        </Card>
        <VerticalSpacer height={20} />
        <Card
          sx={{
            borderRadius: "15px",
            border: "1px solid",
            borderColor: color.gray300,
            padding: "24px",
            paddingBottom: "40px",
            boxShadow: 0,
          }}
        >
          <MaterialDetailsQuantity />
        </Card>
        <VerticalSpacer height={20} />
        <Card
          sx={{
            borderRadius: "15px",
            border: "1px solid",
            borderColor: color.gray300,
            padding: "24px",
            paddingBottom: "40px",
            boxShadow: 0,
          }}
        >
          <MaterialDetailsDate />
        </Card>
      </Grid>
    </CommonList>
  );
}
