import { useState, useEffect, ReactNode } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "styled-components";
import { Divider } from "antd";
import GHGReductionTabTable from "../carbonReductionInfo/section/GHGReductionTab/component/GHGReductionTabTable";
import { SERVER_CONFIG } from "../../../constants/ServerConfig";
import { MonitoringReduction } from "../../../store/monitoringType";
import { ProjectList } from "../../../store/projectType";
import { ApiService } from "../../../restAPI/ApiService";

interface CustomTypographyProps {
  children: ReactNode;
  sx?: object;
}
const CustomCell = ({
  title,
  align,
  width
}: {
  title: string;
  align: "left" | "center" | "right";
  width?: string | number;
  color?: "#99989D";
}) => {
  return (
    <TableCell
      sx={{
        backgroundColor: "white",
        color: "black",
        fontWeight: 600,
        fontFamily: "var(--bs-font-sans-serif)",
        width: width,
        fontSize: 12
      }}
      align={align}
    >
      {title}
    </TableCell>
  );
};

interface MonitoringReductionTableProps {
  projectId: number;
  formDataStartDate: string;
  formDataEndData: string;
}

const MonitoringReductionTable: React.FC<MonitoringReductionTableProps> = ({
  projectId,
  formDataStartDate,
  formDataEndData,
}) => {
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [docCount, setDocCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [startDate, setStartDate] = useState(formDataStartDate);
  const [endDate, setEndDate] = useState(formDataEndData);
  const [totalReduction, setTotalReduction] = useState(0);
  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(event.target.value);
  };

  const [tableData, setTableData] = useState<MonitoringReduction[]>([]);

  useEffect(() => {
    const fetchReduction = async () => {
      if (!startDate || !endDate) {
        setTableData([]);
        return;
      }
      /* Greenery Code
      try {
        const url = `${SERVER_CONFIG.CARBON_SERVER}/monitoring/reduction?startDate=${startDate}&endDate=${endDate}`;
        const response = await fetch(url);
        console.log("fetchReduction response----->", response);
        if (response.ok) {
          const data = await response.json();
          setTableData(data.calculationList || []);
          setTotalReduction(data.totalReduction);
          console.log("Calculation Fetched data:", data);
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching monitoring reducition", error);
      }
      //*/

      const fetchProjectData = async () => {
        try {
          const response = await fetch(
            SERVER_CONFIG.CARBON_SERVER + "/project/list"
          );
          const data: ProjectList[] = await response.json();

          if (Array.isArray(data) && data.length > 0) {
            var projectName = "";

            for(var i=0; i<data.length ;++i){
              if(projectId == data[i].id){
                projectName = data[i].title;
                break
              }
            }

            const searchData = {
              projectName: projectName,
              country: "",
              province: "",
              startDate: new Date(startDate).getTime(),
              endDate: new Date(endDate).getTime(),
            };
            ApiService.webPost("/pw/aw/carbonReduction/project/carbonReductionInfoByProject", searchData).then((res) => {
              console.log(res.data);
              if (res.data) {
                let row = res.data.body;
                setRows(row);

                var ghgReductions = 0;
                for(var i=0; i<row.length ;++i){
                  if(row[i]?.ghgReductions){
                    ghgReductions += row[i]?.ghgReductions;
                  }
                }
                setTotalReduction(ghgReductions);
              }
            });
          }
        } catch (error) {
        }
      };
      fetchProjectData();
    };
    fetchReduction();
  }, [startDate, endDate]);

  const handleDownloadExcel = async () => {
    if(-1 === projectId){
      alert("Please check Project");
      return
    }
    if("" === startDate || "" === endDate){
      alert("Please check Monitoring Date");
      return
    }

    try{
      const response = await fetch(
        SERVER_CONFIG.CARBON_SERVER + "/project/list"
      );
      const data: ProjectList[] = await response.json();

      if (Array.isArray(data) && data.length > 0){
        var projectName = "";

        for(var i=0; i<data.length ;++i){
          if(projectId == data[i].id){
            projectName = data[i].title;
            break
          }
        }
        const fmsData = {
          projectName: projectName,
          startDate: new Date(startDate).getTime(),
          endDate: new Date(endDate).getTime(),
          strStartDate: startDate,
          strEndDate: endDate,
        };
        ApiService.webPost("/fms/api/v1/monitoringLawDataExcelDownload", fmsData).then((res) => {
            const blob = new Blob([res.data], { type: "text/csv;charset=utf-8;" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);

            let d = new Date();
            let yyyy = d.getFullYear();
            let mm = "" + (d.getMonth()+1);
            mm = (1 == mm.length ? 0 + mm : mm);
            let dd = "" + d.getDay();
            dd = (1 == dd.length ? 0 + dd : dd);
            let hh = "" + d.getHours();
            hh = (1 == hh.length ? 0 + hh : hh);
            let mi = "" + d.getMinutes();
            mi = (1 == mi.length ? 0 + mi : mi);
            let ss = "" + d.getSeconds();
            ss = (1 == ss.length ? 0 + ss : ss);
            const fileName = "monitoring_data_" + yyyy + mm + dd + "_" + hh + mi + ss + ".csv";

            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
//             setDownloadLoading(false);
        });
      }
    }catch(error){
      console.error(error);
    }
  };

  return (
    <>
      <Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box>
            <CustomTypography sx={{ fontWeight: "bold", marginLeft: 2 }}>
              모니터링 데이터 조회
            </CustomTypography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <CustomTextField
                type="date"
                name="startDate"
                sx={{ width: "40%" }}
                value={startDate}
                onChange={handleStartDateChange}
                size="small"
                InputProps={{
                  style: {
                    borderRadius: "7px",
                  },
                }}
              />
              <Typography sx={{ alignItems: "center", display: "flex" }}>
                {" "} ~ {" "}
              </Typography>
              <CustomTextField
                type="date"
                value={endDate}
                name="endDate"
                onChange={handleEndDateChange}
                sx={{ width: "80.26px" }}
                size="small"
                InputProps={{
                  style: {
                    borderRadius: "7px",
                  },
                }}
              />
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  width: "100%",
                  height: "auto",
                }}
              >
              </Box>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  padding: "12px 16px",
                  width: "182px",
                  height: "auto",
                }}
              >
                <Button
                  sx={{
                    background: "#5D38E5",
                    borderRadius: "40px",
                    color: "#FFFFFF",
                    lineHeight: "20px",
                    fontSize: "14px",
                    textTransform: "none",
                    "&:hover": {
                      background: " #5a3acb"
                    },
                    width: "182px",
                    height: "45px",
                  }}
                  onClick={handleDownloadExcel}
                >
                  Excel Download
                </Button>
              </Box>
            </Box>
          </Box>
          <Paper
            sx={{
              width: "100%",
              border: 0,
              borderColor: "#F3F3F4",
              padding: 2,
              mt: 1,
            }}
            elevation={0}
          >
            {/* Greenery Code
            <TableContainer>
              <Table
                sx={{
                  width: "100%",
                  borderCollapse: "collapse",
                  borderLeft: 0,
                  outline: "none",
                }}
                stickyHeader
              >
                <TableHead>
                  <TableRow sx={{ fontSize: 50 }}>
                    <CustomCell title="No" align="center" width="10px" />
                    <CustomCell title="사용자 No" align="center" />
                    <CustomCell title="모델" align="center" />
                    <CustomCell title="주행거리" align="center" />
                    <CustomCell title="배터리 사용량" align="center" />
                    <CustomCell title="베이스라인 배출량" align="center" />
                    <CustomCell title="블록체인 검증" align="center" />
                    <CustomCell title="온실가스 감축량" align="center" />
                    <CustomCell title="수집일시" align="center" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={8} align="center">
                        조회할 데이터가 없습니다
                      </TableCell>
                    </TableRow>
                  ) : (
                    tableData.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          ":hover": {
                            backgroundColor: "#F6F9FC",
                            cursor: "pointer",
                          },
                        }}
                      >
                        <TableCell component="th" scope="row" align="center">
                          {index + 1}
                        </TableCell>
                        <TableCell align="center">{row.uuid}</TableCell>
                        <TableCell align="center">{row.model}</TableCell>

                        <TableCell align="center">
                          {row.distance}
                          <span style={{ marginLeft: "3px" }}>km</span>
                        </TableCell>
                        <TableCell align="center">
                          {row.btyUsgPv}
                          <span style={{ marginLeft: "3px" }}>PV</span>
                        </TableCell>
                        <TableCell align="center">
                          {row.baseline}
                          <div>kgCO2-eq</div>
                        </TableCell>
                        <TableCell align="center">
                          <div>OK</div>
                          <a
                            href={row.blockchainUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: "underline" }}
                          >
                            바로 가기
                          </a>{" "}
                        </TableCell>
                        <TableCell align="center">
                          {row.reduction}
                          <div>tCO2-eq</div>
                        </TableCell>
                        <TableCell align="center">{row.createdAt}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            */}

            <GHGReductionTabTable
              rows={rows}
              docCount={docCount}
              rowsPerPage={rowsPerPage}
              pageCount={pageCount}
              page={page}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
            />
          </Paper>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: 2,
            }}
          >
            <Typography sx={{ fontWeight: "bold", fontSize: 25 }}>
              온실가스 감축량(순흡수량) 합계
            </Typography>
            <Typography sx={{ fontWeight: "bold", fontSize: 25 }}>
              {totalReduction}
              <span> tCO2-eq</span>
            </Typography>
          </Box>
          <Divider />
        </Box>
      </Box>
    </>
  );
};

const CustomTypography: React.FC<CustomTypographyProps> = ({
  children,
  ...props
}) => {
  return (
    <Typography
      {...props}
      sx={{
        color: "#99989D",
        ...props.sx,

        lineHeight: "18px",
        fontWeight: 700
      }}
    >
      {children}
    </Typography>
  );
};

const CustomTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
  minHeight: "48px",
  padding: "12px 16px",
  gap: "2px",
  borderRadius: "10px 0px 0px 0px",
  border: "1px solid transparent",
  opacity: 1,
  marginLeft: "0",
  "& .MuiInputBase-root": {
    height: "48px"
  }
}));

export default MonitoringReductionTable;
