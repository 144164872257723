import { Box, Card, Grid, Typography } from "@mui/material";
import { color } from "../../../../../../theme/Color";
import { fontStyle } from "../../../../../../theme/Style";
import VerticalSpacer from "../../../../../../component/Spacer";
import CommonDatePicker from "../../../../../../component/CommonDatePicker";
import { CommonSelect } from "../../../../../../component/CommonSelect";
import CommonTextField from "../../../../../../component/CommonTextField";
import { RegisterFindParts } from "./RegisterFindParts";
import { CommonWebHeaderButton } from "../../../../../../component/CommonWebHeaderButton";
import { RegisterBillOfMaterialTree } from "../../../../component/findPartList/RegisterBillOfMaterialTree";

const RequestCreateGrid = () => {
  return (
    <>
      <Grid item lg={12}>
        {/* Request 카드 */}
        <Card
          sx={{
            borderRadius: "15px",
            border: "1px solid",
            borderColor: color.gray300,
            padding: "24px",
            paddingBottom: "40px",
            boxShadow: 0,
          }}
        >
          <Typography fontStyle={fontStyle.semiboldL}>Request</Typography>
          <VerticalSpacer height={16} />
          <Box sx={{ display: "flex", gap: "28px", justifyContent: "space-between" }}>
            {/* Request 카드 왼쪽 요소 */}
            <Box sx={{ width: "100%" }}>
              {/* 첫번째 줄 */}
              <Box sx={{ display: "flex", justifyContent: "space-between", gap: "16px" }}>
                <Box sx={{ width: "100%" }}>
                  <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                    Desired Pickup Date
                  </Typography>
                  <VerticalSpacer height={8} />
                  <CommonDatePicker value={null} textFieldSx={{ width: "100%" }} InputPropsSx={{ height: "48px" }} />
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                    Request Branch
                  </Typography>
                  <CommonSelect
                    selectValue={""}
                    menuitems={[]}
                    onChange={(event) => {}}
                    sx={{
                      height: "48px",
                      width: "100%",
                      mt: "8px",
                      fontStyle: fontStyle.regularS,
                      color: "black",
                    }}
                  />
                </Box>
              </Box>
              <VerticalSpacer height={24} />
              {/* 두번째 줄 */}
              <Box sx={{ display: "flex", justifyContent: "space-between", gap: "16px" }}>
                <Box sx={{ width: "100%" }}>
                  <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                    Branch
                  </Typography>
                  <CommonSelect
                    selectValue={""}
                    menuitems={[]}
                    onChange={(event) => {}}
                    sx={{
                      height: "48px",
                      width: "100%",
                      mt: "8px",
                      fontStyle: fontStyle.regularS,
                      color: "black",
                    }}
                  />
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                    Requester
                  </Typography>
                  <CommonSelect
                    selectValue={""}
                    menuitems={[]}
                    onChange={(event) => {}}
                    sx={{
                      height: "48px",
                      width: "100%",
                      mt: "8px",
                      fontStyle: fontStyle.regularS,
                      color: "black",
                    }}
                    disabled
                  />
                </Box>
              </Box>
              <VerticalSpacer height={24} />
              {/* 세번째 줄 */}
              <Box sx={{ display: "flex", justifyContent: "space-between", gap: "16px" }}>
                <Box sx={{ width: "100%" }}>
                  <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                    Branch
                  </Typography>
                  <CommonSelect
                    selectValue={""}
                    menuitems={[]}
                    onChange={(event) => {}}
                    sx={{
                      height: "48px",
                      width: "100%",
                      mt: "8px",
                      fontStyle: fontStyle.regularS,
                      color: "black",
                    }}
                    disabled
                  />
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                    Requester
                  </Typography>
                  <CommonSelect
                    selectValue={""}
                    menuitems={[]}
                    onChange={(event) => {}}
                    sx={{
                      height: "48px",
                      width: "100%",
                      mt: "8px",
                      fontStyle: fontStyle.regularS,
                      color: "black",
                    }}
                    disabled
                  />
                </Box>
              </Box>
            </Box>
            {/* Request 카드 오른쪽 요소 */}
            <Box sx={{ width: "100%" }}>
              {/* 첫번째 줄 */}
              <Box sx={{ display: "flex", justifyContent: "space-between", gap: "16px" }}>
                <Box sx={{ width: "100%" }}>
                  <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                    Comment
                  </Typography>
                  <CommonTextField
                    value=""
                    sx={{ width: "100%", height: "146px", mt: "8px" }}
                    InputProps={{
                      sx: {
                        height: "146px",
                      },
                    }}
                    rows={6}
                    multiline
                  />
                </Box>
              </Box>
              <VerticalSpacer height={24} />
              {/* 두번째 줄 */}
              <Box sx={{ display: "flex", justifyContent: "space-between", gap: "16px" }}>
                <Box sx={{ width: "100%" }}>
                  <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                    Branch
                  </Typography>
                  <CommonSelect
                    selectValue={""}
                    menuitems={[]}
                    onChange={(event) => {}}
                    sx={{
                      height: "48px",
                      width: "100%",
                      mt: "8px",
                      fontStyle: fontStyle.regularS,
                      color: "black",
                    }}
                    disabled
                  />
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                    Requester
                  </Typography>
                  <CommonTextField
                    value="-"
                    onChange={(event) => {}}
                    sx={{
                      height: "48px",
                      width: "100%",
                      mt: "8px",
                      fontStyle: fontStyle.regularS,
                      color: "black",
                    }}
                    InputProps={{
                      sx: {
                        height: "48px",
                      },
                    }}
                    disabled
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Card>
      </Grid>
      <VerticalSpacer height={24} />
      <Grid item lg={12}>
        <Card
          sx={{
            borderRadius: "15px",
            border: "1px solid",
            borderColor: color.gray300,
            padding: "24px",
            boxShadow: 0,
          }}
        >
          <RegisterFindParts />
          <VerticalSpacer height={24} />
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CommonWebHeaderButton buttonContent="Add to List" addIcon height="36px" iconFontSize="18px" />
          </Box>
          <VerticalSpacer height={40} />
          <RegisterBillOfMaterialTree />
        </Card>
      </Grid>
    </>
  );
};

export default RequestCreateGrid;
