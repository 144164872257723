import { Box, CardContent, Typography } from "@mui/material";
import { fontStyle } from "../../../../../../../theme/Style";
import VerticalSpacer, { HorizontalSpacer } from "../../../../../../../component/Spacer";

interface QuantityByBranch {
  [key: string]: { name: string; quantity: number };
}

const quantityByBranch: QuantityByBranch = {
  DATA1: { name: "POPLE Station 1st Store", quantity: 200 },
  DATA2: { name: "POPLE Station WBC RiverSide Branch", quantity: 0 },
  DATA3: { name: "VERYWORDS Manufacture Factory", quantity: 1200 },
  DATA4: { name: "POPLE Station WBC RiverSide Branch", quantity: 0 },
  DATA5: { name: "Gwang-Ju Branch", quantity: 1000 },
  DATA6: { name: "POPLE Station WBC RiverSide Branch", quantity: 0 },
};

export function MaterialDetailsQuantity() {
  const entries = Object.entries(quantityByBranch); // entries 배열로 변환
  return (
    <CardContent
      sx={{
        width: "100%",
        padding: "0px",
        "&:last-child": {
          paddingBottom: 0,
        },
      }}
    >
      <Typography fontStyle={fontStyle.semiboldL}>Quantity by Branch</Typography>
      <VerticalSpacer height={18} />
      <Typography fontStyle={fontStyle.semiboldM}>Total</Typography>
      <VerticalSpacer height={20} />
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        {entries.map(([, details], index) => {
          if (index % 2 === 0) {
            return (
              <>
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <span>{details.name}</span>
                    <span>{details.quantity}</span>
                  </Box>
                  <HorizontalSpacer width={28} />
                  {entries[index + 1] && ( // 다음 항목이 있으면 추가
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <span>{entries[index + 1][1].name}</span>
                      <span>{entries[index + 1][1].quantity}</span>
                    </Box>
                  )}
                </Box>
                {index < entries.length - 2 && <VerticalSpacer height={20} />}
              </>
            );
          }
          return null; // 나머지 경우는 null 반환
        })}
      </Box>
    </CardContent>
  );
}
