import { Box, Card, CardContent, Typography } from "@mui/material";
import { fontStyle } from "../../../../../theme/Style";
import { color } from "../../../../../theme/Color";
import CommonButton from "../../../../../component/CommonButton";
import VerticalSpacer, { HorizontalSpacer } from "../../../../../component/Spacer";
import { NFTTable } from "./NFTTable";
import { WalletDetailUiState } from "../model/WalletDetailUiState";
import { WalletDetailUiEvent } from "../model/WalletDetailUiEvent";
import { TabType } from "../model/TabType";
import { CommonWebHeaderButton } from "../../../../../component/CommonWebHeaderButton";
import { TransactionTable } from "./TransactionTable";

export interface StatusCardProps {
  uiState: WalletDetailUiState;
  uiEvent: WalletDetailUiEvent;
  currentTab: TabType;
}

export function StatusCard({ uiState, uiEvent, currentTab }: StatusCardProps) {
  return (
    <Card
      sx={{
        width: "100%",
        padding: "0px",
        border: "1px solid",
        borderColor: color.gray300,
        borderRadius: "15px",
        boxShadow: "unset",
      }}
    >
      <CardContent
        sx={{
          padding: "24px",
          "&:last-child": {
            paddingBottom: "24px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography fontStyle={fontStyle.semiboldL}>Status</Typography>
          <Box sx={{ display: "flex" }}>
            <CommonWebHeaderButton
              onClick={() => uiEvent.onClickTab(TabType.NFT)}
              buttonContent="NFT"
              height="36px"
              textColor={
                currentTab === TabType.NFT ? color.primary500 : color.gray600
              }
              sx={{
                border: "1px solid",
                borderColor: currentTab === TabType.NFT ? "" : color.gray300,
                borderRadius: "54px",
                backgroundColor:
                  currentTab === TabType.NFT ? color.primary100 : color.white,
                display: "flex",
                flexDirection: "column",
                px: "16px",
                py: "8px",
              }}
            />
            <HorizontalSpacer width={12} />
            <CommonWebHeaderButton
              onClick={() => uiEvent.onClickTab(TabType.TRANSACTION)}
              buttonContent="Transaction"
              height="36px"
              textColor={
                currentTab === TabType.TRANSACTION
                  ? color.primary500
                  : color.gray600
              }
              sx={{
                border: "1px solid",
                borderColor:
                  currentTab === TabType.TRANSACTION ? "" : color.gray300,
                borderRadius: "54px",
                backgroundColor:
                  currentTab === TabType.TRANSACTION
                    ? color.primary100
                    : color.white,
                display: "flex",
                flexDirection: "column",
                px: "16px",
                py: "8px",
              }}
            />
          </Box>
        </Box>
        <VerticalSpacer height={16} />
        {currentTab === TabType.NFT && (
          <NFTTable uiState={uiState} uiEvent={uiEvent} />
        )}
        {currentTab === TabType.TRANSACTION && (
          <TransactionTable uiState={uiState} uiEvent={uiEvent} />
        )}
      </CardContent>
    </Card>
  );
}
