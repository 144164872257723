export enum ToastDuration {
  SHORT = 2000, // 2 seconds
  LONG = 4000, // 4 seconds
}

export enum ToastType {
  success,
  error,
  info,
  warning,
}

export interface ToastModel {
  id: string;
  message: string;
  duration: ToastDuration;
  type: ToastType;
}
