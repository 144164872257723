import { styled, TableCell, TableRow, Typography } from "@mui/material";
import CommonTable, {
  HeadCell,
  useVisibleRows,
} from "../../../../../component/CommonTable";
import { color } from "../../../../../theme/Color";
import { fontStyle } from "../../../../../theme/Style";
import { WalletDetailUiEvent } from "../model/WalletDetailUiEvent";
import { WalletDetailUiState } from "../model/WalletDetailUiState";
import LocalizedDate, {
  DateFormatType,
} from "../../../../../component/LocalizedDate";

interface NFTTableProps {
  uiState: WalletDetailUiState;
  uiEvent: WalletDetailUiEvent;
}

const NFTTableCell = styled(TableCell)(({ theme }) => ({
  height: "26px",
  padding: "8px 12px",
}));

export function NFTTable({ uiState, uiEvent }: NFTTableProps) {
  const { order, orderBy, handleRequestSort, handleChangePage, visibleRows } =
    useVisibleRows(
      uiState.nftInfo,
      uiState.rowsPerPage,
      (newPage: number) => uiEvent.handleUiStateChange("page", newPage),
      uiState.page
    );
  return (
    <CommonTable
      headCells={NFTHeadCells}
      headCellSx={{
        backgroundColor: color.gray200,
        fontStyle: fontStyle.semiboldS,
        color: color.gray800,
      }}
      docCount={uiState.docCount}
      page={uiState.page}
      pageCount={uiState.pageCount}
      rowsPerPage={uiState.rowsPerPage}
      setRowsPerPage={(newRowsPerPage: number) =>
        uiEvent.handleUiStateChange("rowsPerPage", newRowsPerPage)
      }
      setPage={(newPage: number) =>
        uiEvent.handleUiStateChange("page", newPage)
      }
      order={order}
      orderBy={orderBy}
      handleChangePage={handleChangePage}
      handleRequestSort={handleRequestSort}
    >
      {visibleRows.map(
        (data: { [x: string]: string | number }, index: number) => (
          <TableRow key={index} sx={{ height: "76px", padding: "10px 28px" }}>
            <NFTTableCell
              sx={{
                width: "60px",
                height: "60px",
                backgroundImage: `url(${data.nftImage})`,
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            ></NFTTableCell>
            <NFTTableCell sx={{ width: "120px" }}>
              <Typography
                fontStyle={fontStyle.regularS}
                sx={{ textAlign: "center" }}
              >
                {data.nftType}
              </Typography>
            </NFTTableCell>
            <NFTTableCell sx={{ width: "216px" }}>
              <Typography
                fontStyle={fontStyle.regularS}
                sx={{ textAlign: "center" }}
              >
                {data.nftId}
              </Typography>
            </NFTTableCell>
            <NFTTableCell sx={{ width: "120px" }}>
              <Typography
                fontStyle={fontStyle.regularS}
                sx={{ textAlign: "center" }}
              >
                {data.issuer}
              </Typography>
            </NFTTableCell>
            <NFTTableCell sx={{ width: "216px" }}>
              <Typography
                fontStyle={fontStyle.regularS}
                sx={{ textAlign: "center" }}
              >
                {LocalizedDate({
                  date: data.issueDate,
                  type: DateFormatType.AdminType,
                })}
              </Typography>
            </NFTTableCell>
            <NFTTableCell sx={{ width: "128px" }}>
              <Typography
                fontStyle={fontStyle.regularS}
                sx={{ textAlign: "center" }}
              >
                 {LocalizedDate({
                  date: data.receivedDate,
                  type: DateFormatType.AdminType,
                })}
              </Typography>
            </NFTTableCell>
          </TableRow>
        )
      )}
    </CommonTable>
  );
}

const NFTHeadCells: HeadCell[] = [
  {
    disablePadding: false,
    align: "center",
    id: "image",
    label: "Image",
  },
  {
    disablePadding: false,
    align: "center",
    id: "nftType",
    label: "NFT Type",
  },
  {
    disablePadding: false,
    align: "center",
    id: "nftId",
    label: "NFT ID",
  },
  {
    disablePadding: false,
    align: "center",
    id: "issuer",
    label: "Issuer",
  },
  {
    disablePadding: false,
    align: "center",
    id: "issueDate",
    label: "Issue Date",
  },
  {
    disablePadding: false,
    align: "center",
    id: "receivedDate",
    label: "Received Date",
  },
];
