export const fontStyle = {
  // App Design System
  headingL: {
    fontSize: "32px",
    lineHeight: "1.3",
    fontWeight: "600",
  },
  headingS: {
    fontSize: "22px",
    lineHeight: "1.5",
    fontWeight: "600",
  },
  bodyLb: {
    fontSize: "16px",
    lineHeight: "1.6",
    fontWeight: "700",
  },
  bodyLr: {
    fontSize: "16px",
    lineHeight: "1.6",
    fontWeight: "400",
  },
  bodyMb: {
    fontSize: "14px",
    lineHeight: "1.6",
    fontWeight: "700",
  },
  bodyMr: {
    fontSize: "14px",
    lineHeight: "1.6",
    fontWeight: "400",
  },
  bodySb: {
    fontSize: "13px",
    lineHeight: "1.4",
    fontWeight: "700",
  },
  bodySr: {
    fontSize: "13px",
    lineHeight: "1.4",
    fontWeight: "400",
  },
  titleS: {
    fontSize: "13px",
    lineHeight: "1.5",
    fontWeight: "600",
  },
  titleM: {
    fontSize: "14px",
    lineHeight: "1.6",
    fontWeight: "600",
  },
  titleL: {
    fontSize: "15.5px",
    lineHeight: "1.6",
    fontWeight: "600",
  },
  titleXL: {
    fontSize: "19px",
    lineHeight: "1.5",
    fontWeight: "600",
  },

  subTitleXS: {
    fontSize: "12px",
    lineHeight: "1.4",
    fontWeight: "500",
  },
  subTitleS: {
    fontSize: "13px",
    lineHeight: "1.5",
    fontWeight: "500",
  },
  subTitleM: {
    fontSize: "14px",
    lineHeight: "1.6",
    fontWeight: "500",
  },
  subTitleL: {
    fontSize: "15.5px",
    lineHeight: "1.6",
    fontWeight: "500",
  },
  subTitleXXL: {
    fontSize: "30px",
    lineHeight: "1.2",
    fontWeight: "500",
  },
  titleXs: {
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "16.8px",
    // letterSpacing: '-0.005em',
    // textAlign: left,
  },

  // Web Design System
  regularXXS: {
    fontSize: "10px",
    lineHeight: "14px",
    fontWeight: "400",
  },
  semiboldXXS: {
    fontSize: "10px",
    lineHeight: "14px",
    fontWeight: "600",
  },
  regularXS: {
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: "400",
  },
  boldXS: {
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: "700",
  },
  regularS: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "400",
  },
  semiboldXS: {
    fontSize: "12px",
    lineHeight: "28px",
    fontWeight: "600",
  },
  semiboldS: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "600",
  },
  regularM: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "400",
  },
  semiboldM: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "600",
  },
  regularL: {
    fontSize: "18px",
    lineHeight: "28px",
    fontWeight: "400",
  },
  semiboldL: {
    fontSize: "18px",
    lineHeight: "28px",
    fontWeight: "600",
  },
  // displayS: {
  //   fontSize: "15.5px",
  //   lineHeight: "1.6",
  //   fontWeight: "600",
  // },
  displayM: {
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: "600",
  },

  displayL: {
    fontSize: "32px",
    lineHeight: "42px",
    fontWeight: "500",
  },

  titleBig: {
    fontSize: "40px",
    lineHeight: "50px",
    fontWeight: "700",
  },
};
