import { WalletInfoResponse } from "../model/WalletDetailResponse";
import { WalletDetailUiState, WalletInfo } from "../model/WalletDetailUiState";

export function WalletDetailMapper(response: WalletInfoResponse) {
  const walletInfo: WalletInfo = {
    id: response.id,
    address: response.address,
    stationName: response.stationName,
    balance: response.balance,
    txCount: response.txCount,
    registrationDate: response.registrationDate,
    appearDate: response.appearDate,
    lastUpdateDate: response.lastUpdateDate,
    token: response.token,
  };
  return new WalletDetailUiState(walletInfo);
}
