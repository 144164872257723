import { TableRow } from "@mui/material";
import { MaterialsTableCell } from "./MaterialsTableCell";
import { fontStyle } from "../../../../../theme/Style";
import { color } from "../../../../../theme/Color";
import { Text } from "../../../../../component/Text";
import { useNavigate } from "react-router-dom";
import { MaterialsData } from "./model/MaterialsData";
import { ProductionManagementCellData } from "../../../model/ProductionManagementCellData";

interface MaterialsTableRowProps {
  row: MaterialsData;
}

export function MaterialsTableRow({ row }: MaterialsTableRowProps) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/materialDetails");
  };

  const materialCellData: ProductionManagementCellData[] = [
    new ProductionManagementCellData(`${row.no}`, "left", "36px", "24px"),
    // new ProductionManagementCellData(`${row.level}`, "left", "40px"),
    new ProductionManagementCellData(`${row.category}`, "left", "64px"),
    new ProductionManagementCellData(`${row.partsCode}`, "left", "83px"),
    new ProductionManagementCellData(`${row.productName}`, "left", "122px", "12px", "12px", onClick),
    new ProductionManagementCellData(`${row.spec}`, "left"),
    new ProductionManagementCellData(`${row.quantities}`, "left", "64px"),
    // new ProductionManagementCellData(`${row.amount}`, "left", "48px"),
    new ProductionManagementCellData(`${row.company}`, "left", "72px", "", "24px"),
  ];
  return (
    <TableRow>
      {materialCellData.map((data: ProductionManagementCellData, index: number) => (
        <MaterialsTableCell
          key={index}
          sx={{
            paddingLeft: data.paddingLeft,
            paddingRight: data.paddingRight,
            width: data.width || "auto",
            ...(index === materialCellData.length - 4 ? { flex: 1, maxWidth: "221px" } : {}),
          }}
          childrenContainerSx={{ width: data.width }}
          align={data.align}
          onClick={data.onClick}
          children={
            <Text
              sx={{
                fontStyle: fontStyle.regularXS,
                color: color.gray800,
                ...(data.onClick
                  ? {
                      cursor: "pointer",
                    }
                  : {}),
              }}
              text={data.value}
            />
          }
        />
      ))}
    </TableRow>
  );
}
