import { Box, Typography } from "@mui/material";
import Toolbar from "../../../../component/Toolbar";
import VerticalSpacer, { HorizontalSpacer } from "../../../../component/Spacer";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import { HorizontalGrayDivider } from "../../../../component/Divider";
import {
  ICON_INQUIRY_BLUE,
  ICON_LOGOUT,
} from "../../../../constants/appImagePath";
import { FAQDetailSideEffect } from "./FAQDetailSideEffect";
import { FAQDetailUiState } from "./FAQDetailUiState";
import { LocalizedText } from "../../../../di/LanguageRepositoryProvider";
import { ROUTE_PATHS } from "../../../../constants/RouteConstants";

interface FAQDetailRenderProps {
  uiState: FAQDetailUiState;
  sideEffect: FAQDetailSideEffect;
}

export default function FAQDetailRender({
  uiState,
  sideEffect,
}: FAQDetailRenderProps) {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Toolbar
        title={LocalizedText("ua_f111_faq")}
        onBack={() => {
          sideEffect.back();
        }}
      />
      <Box
        sx={{
          overflowY: "scroll",
          px: "20px",
        }}
      >
        <VerticalSpacer height={24} />
        <Typography
          sx={{
            fontStyle: fontStyle.titleS,
            color: color.gray600,
          }}
        >
          {uiState.category}
        </Typography>
        <Typography
          sx={{
            fontStyle: fontStyle.headingS,
            color: color.gray900,
          }}
        >
          {uiState.title}
        </Typography>
        <VerticalSpacer height={24} />
        <HorizontalGrayDivider />
        <VerticalSpacer height={16} />
        <Typography
          sx={{
            fontStyle: fontStyle.bodyLr,
            color: color.gray900,
            overflowWrap: "break-word",
            whiteSpace: "pre-line",
          }}
        >
          {uiState.content}
        </Typography>
        <VerticalSpacer height={79} />
        <Typography
          sx={{
            fontStyle: fontStyle.titleL,
            color: color.gray900,
          }}
        >
          {LocalizedText("ua_f111_morequestion")}
        </Typography>
        <VerticalSpacer height={8} />
        <InquiryButton
          onClick={() => {
            sideEffect.navigate(ROUTE_PATHS.INQUIRY_PAGE);
          }}
        />
        <VerticalSpacer height={16} />
      </Box>
    </Box>
  );
}

export function InquiryButton({ onClick }: { onClick: () => void }) {
  return (
    <Box
      sx={{
        px: "12px",
        height: "38px",
        width: "fit-content",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "10px",
        backgroundColor: color.primary100,
      }}
      onClick={onClick}
    >
      <img
        src={ICON_INQUIRY_BLUE}
        alt=""
        style={{
          width: "16px",
          height: "16px",
        }}
      />
      <HorizontalSpacer width={4} />
      <Typography
        sx={{
          fontStyle: fontStyle.titleM,
          color: color.primary500,
        }}
      >
        {LocalizedText("ua_f111_registerinquiry")}
      </Typography>
      <HorizontalSpacer width={4} />
    </Box>
  );
}
