import { Box, Typography } from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";

interface ProfileDetailItemProps {
  label: string;
  content?: string;
}

const ProfileDetailItem = ({ label, content }: ProfileDetailItemProps) => {
  return (
    <Box
      sx={{
        backgroundColor: color.white,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        p: "20px",
        boxSizing: "border-box",
        gap: "20px",
      }}
    >
      <Typography
        fontStyle={fontStyle.subTitleM}
        color={color.default}
        sx={{
          minWidth: "100px",
          flexShrink: 0,
        }}
      >
        {label}
      </Typography>
      <Typography
        fontStyle={fontStyle.bodyMr}
        color={color.gray700}
        sx={{
          flex: 1,
          whiteSpace: "pre-wrap",
          wordBreak: "break-all",
          textAlign: "right",
        }}
      >
        {content}
      </Typography>
    </Box>
  );
};

export default ProfileDetailItem;
