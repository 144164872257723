// BASE_PATH: /assets/component
const ICON_BASE_PATH = `${process.env.PUBLIC_URL}/assets/app/icon`;
const EMOJI_BASE_PATH = `${process.env.PUBLIC_URL}/assets/app/emoji`;
const IMAGE_BASE_PATH = `${process.env.PUBLIC_URL}/assets/app/image`;

// Emoji
export const EMOJI_HAPPY = `${EMOJI_BASE_PATH}/ic_happy.svg`;
export const EMOJI_HAPPY_FILL = `${EMOJI_BASE_PATH}/ic_happy_fill.svg`;
export const EMOJI_SAD = `${EMOJI_BASE_PATH}/ic_sad.svg`;
export const EMOJI_SAD_FILL = `${EMOJI_BASE_PATH}/ic_sad_fill.svg`;
export const EMOJI_SAD_GRAY600 = `${EMOJI_BASE_PATH}/ic_sad_gray600.svg`;
export const EMOJI_SOSO = `${EMOJI_BASE_PATH}/ic_soso.svg`;
export const EMOJI_SOSO_FILL = `${EMOJI_BASE_PATH}/ic_soso_fill.svg`;
export const EMOJI_WINK = `${EMOJI_BASE_PATH}/ic_wink.svg`;
export const EMOJI_WINK_FILL = `${EMOJI_BASE_PATH}/ic_wink_fill.svg`;

// Icon
export const ICON_POSITION = `${ICON_BASE_PATH}/ic_position.svg`;
export const ICON_TARGET = `${ICON_BASE_PATH}/ic_target.svg`;
export const ICON_BELL = `${ICON_BASE_PATH}/ic_bell.svg`;
export const ICON_PEN = `${ICON_BASE_PATH}/ic_pen.svg`;
export const ICON_BELL_FILL = `${ICON_BASE_PATH}/ic_bell_fill.svg`;
export const ICON_CARD = `${ICON_BASE_PATH}/ic_card.svg`;
export const ICON_INQUIRY = `${ICON_BASE_PATH}/ic_inquiry.svg`;
export const ICON_INQUIRY_BLUE = `${ICON_BASE_PATH}/ic_inquiry_blue.svg`;
export const ICON_PARTNERS = `${ICON_BASE_PATH}/ic_partners.svg`;
export const ICON_PARTNERS2 = `${ICON_BASE_PATH}/ic_partners2.svg`;
export const ICON_PERSON = `${ICON_BASE_PATH}/ic_person.svg`;
export const ICON_PHONE_CALL = `${ICON_BASE_PATH}/ic_phone_call.svg`;
export const ICON_SCOOTER = `${ICON_BASE_PATH}/ic_scooter.svg`;
export const ICON_CALENDAR_SCOOTER = `${ICON_BASE_PATH}/ic_calendar_scooter.svg`;
export const ICON_CALENDAR = `${ICON_BASE_PATH}/ic_calendar.svg`;
export const ICON_CALENDAR_GRAY = `${ICON_BASE_PATH}/ic_calendar_gray.svg`;
export const ICON_CALENDAR_DATE = `${ICON_BASE_PATH}/ic_calendar_date.svg`;
export const ICON_CHECK = `${ICON_BASE_PATH}/ic_check.svg`;
export const ICON_CHECK2 = `${ICON_BASE_PATH}/ic_check2.svg`;
export const ICON_UNCHECK = `${ICON_BASE_PATH}/ic_uncheck.svg`;
export const ICON_CLOCK = `${ICON_BASE_PATH}/ic_clock.svg`;

export const ICON_SPANNER = `${ICON_BASE_PATH}/ic_spanner.svg`;
export const ICON_BATTERY = `${ICON_BASE_PATH}/ic_battery.svg`;
export const ICON_BATTERY2 = `${ICON_BASE_PATH}/ic_battery2.svg`;
export const ICON_BATTERY_HORIZONTAL = `${ICON_BASE_PATH}/ic_battery_horizontal.svg`;
export const ICON_BATTERY_HORIZONTAL2 = `${ICON_BASE_PATH}/ic_battery_horizontal2.svg`;
export const ICON_RIGHT_ARROW = `${ICON_BASE_PATH}/ic_right_arrow.svg`;
export const ICON_DOWN_ARROW = `${ICON_BASE_PATH}/ic_down_arrow.svg`;
export const ICON_LEFT_ARROW = `${ICON_BASE_PATH}/ic_left_arrow.svg`;
export const ICON_RIGHT_ARROW_PRIMARY200 = `${ICON_BASE_PATH}/ic_right_arrow_primary200.svg`;
export const ICON_RIGHT_ARROW_GRAY600 = `${ICON_BASE_PATH}/ic_right_arrow_gray600.svg`;
export const ICON_GEAR = `${ICON_BASE_PATH}/ic_gear.svg`;
export const ICON_COPY = `${ICON_BASE_PATH}/ic_copy.svg`;
export const ICON_CAFE = `${ICON_BASE_PATH}/ic_cafe.svg`;
export const ICON_PHONE = `${ICON_BASE_PATH}/ic_phone.svg`;
export const ICON_PHONE2 = `${ICON_BASE_PATH}/ic_phone2.svg`;
export const ICON_PHONE3 = `${ICON_BASE_PATH}/ic_phone3.svg`;
export const ICON_REDUCTION_POINT = `${ICON_BASE_PATH}/ic_reduction_point.svg`;
export const ICON_CHARGE = `${ICON_BASE_PATH}/ic_charge.svg`;
export const ICON_CHARGE2 = `${ICON_BASE_PATH}/ic_charge2.svg`;
export const ICON_CS = `${ICON_BASE_PATH}/ic_cs.svg`;
export const ICON_TOGLE_RADIO = `${ICON_BASE_PATH}/ic_togle_radio.svg`;
export const ICON_WALLET = `${ICON_BASE_PATH}/ic_wallet.svg`;
export const ICON_WALLET_PRIMARY500 = `${ICON_BASE_PATH}/ic_wallet_primary500.svg`;
export const ICON_BUILDING = `${ICON_BASE_PATH}/ic_building.svg`;
export const ICON_LOGOUT = `${ICON_BASE_PATH}/ic_logout.svg`;
export const ICON_FAQ = `${ICON_BASE_PATH}/ic_faq.svg`;
export const ICON_X = `${ICON_BASE_PATH}/ic_x.svg`;
export const ICON_X_GRAY600 = `${ICON_BASE_PATH}/ic_x_gray600.svg`;
export const ICON_X_DEFAULT = `${ICON_BASE_PATH}/ic_x_default.svg`;
export const ICON_X_CIRCLE = `${ICON_BASE_PATH}/ic_x_circle.svg`;
export const ICON_X_CIRCLE_GRAY = `${ICON_BASE_PATH}/ic_x_circle_gray.svg`;
export const ICON_X_CIRCLE_PRIMARY500 = `${ICON_BASE_PATH}/ic_x_circle_primary500.svg`;
export const ICON_WARNING = `${ICON_BASE_PATH}/ic_warning.svg`;
export const ICON_QRCODE = `${ICON_BASE_PATH}/ic_qrcode.svg`;
export const ICON_SNS_APPLE = `${ICON_BASE_PATH}/ic_sns_apple.svg`;
export const ICON_SNS_FACEBOOK_1 = `${ICON_BASE_PATH}/ic_sns_facebook_1.svg`;
export const ICON_SNS_FACEBOOK_2 = `${ICON_BASE_PATH}/ic_sns_facebook_2.svg`;
export const ICON_SNS_GOOGLE = `${ICON_BASE_PATH}/ic_sns_google.svg`;
export const ICON_PIN = `${ICON_BASE_PATH}/ic_pin.svg`;
export const ICON_MEMBERSHIP_PERSON = `${ICON_BASE_PATH}/ic_membership_person.svg`;
export const ICON_MEMBERSHIP_BATTERY = `${ICON_BASE_PATH}/ic_membership_battery.svg`;
export const ICON_MEMBERSHIP_SCOOTER = `${ICON_BASE_PATH}/ic_membership_scooter.svg`;
export const ICON_RADIO_ON = `${ICON_BASE_PATH}/ic_radio_on.svg`;
export const ICON_RADIO_OFF = `${ICON_BASE_PATH}/ic_radio_off.svg`;
export const ICON_DOWNLOAD = `${ICON_BASE_PATH}/ic_download.svg`;
export const ICON_SEARCH = `${ICON_BASE_PATH}/ic_search.svg`;
export const ICON_PRIMARY_PIN = `${ICON_BASE_PATH}/ic_primary_pin.svg`;
export const ICON_BENEFIT = `${ICON_BASE_PATH}/ic_benefit.svg`;
export const ICON_SEARCH_GRAY = `${ICON_BASE_PATH}/ic_search_gray.svg`;
export const ICON_ALERT_CIRCLE = `${ICON_BASE_PATH}/ic_alert_circle.svg`;
export const ICON_QRSCAN = `${ICON_BASE_PATH}/ic_qrscan.svg`;
export const ICON_TRASH_GRAY = `${ICON_BASE_PATH}/ic_trash_gray.svg`;
export const ICON_RECEIPT = `${ICON_BASE_PATH}/ic_receipt.svg`;
export const ICON_INFO_CIRCLE_GRAY = `${ICON_BASE_PATH}/ic_info_circle_gray.svg`;
export const ICON_INFO_CIRCLE_GRAY800 = `${ICON_BASE_PATH}/ic_info_circle_gray800.svg`;
export const ICON_EDIT_PRIMARY = `${ICON_BASE_PATH}/ic_edit_primary.svg`;
export const ICON_HOME_BATTERY = `${ICON_BASE_PATH}/ic_home_battery.svg`;
export const ICON_HOME_MAGNIFYING_GLASS = `${ICON_BASE_PATH}/ic_home_magnifying_glass.svg`;

// Pin Icon
export const ICON_NAV_ARROW = `${ICON_BASE_PATH}/ic_nav_arrow.svg`;
export const ICON_CAFE_COMMON = `${ICON_BASE_PATH}/ic_cafe_common.svg`;
export const ICON_CAFE_CLICK = `${ICON_BASE_PATH}/ic_cafe_click.svg`;
export const ICON_CAFE_DISABLED = `${ICON_BASE_PATH}/ic_cafe_disabled.svg`;
export const ICON_CAFE_DISABLED_CLICK = `${ICON_BASE_PATH}/ic_cafe_disabled_click.svg`;
export const ICON_CHARGING_COMMON = `${ICON_BASE_PATH}/ic_charging_common.svg`;
export const ICON_CHARGING_CLICK = `${ICON_BASE_PATH}/ic_charging_click.svg`;
export const ICON_CHARGING_DISABLED = `${ICON_BASE_PATH}/ic_charging_disabled.svg`;
export const ICON_CHARGING_DISABLED_CLICK = `${ICON_BASE_PATH}/ic_charging_disabled_click.svg`;
export const ICON_CS_CENTER_COMMON = `${ICON_BASE_PATH}/ic_cs_center_common.svg`;
export const ICON_CS_CENTER_CLICK = `${ICON_BASE_PATH}/ic_cs_center_click.svg`;
export const ICON_CS_CENTER_DISABLED = `${ICON_BASE_PATH}/ic_cs_center_disabled.svg`;
export const ICON_CS_CENTER_DISABLED_CLICK = `${ICON_BASE_PATH}/ic_cs_center_disabled_click.svg`;
export const ICON_MANY_NOCHARGING = `${ICON_BASE_PATH}/ic_many_no_charging.svg`;
export const ICON_MANY_COMMON = `${ICON_BASE_PATH}/ic_many_common.svg`;
export const ICON_MANY_CLICK = `${ICON_BASE_PATH}/ic_many_click.svg`;
export const ICON_MANY_DISABLED = `${ICON_BASE_PATH}/ic_many_disabled.svg`;
export const ICON_MANY_DISABLED_CLICK = `${ICON_BASE_PATH}/ic_many_disabled_click.svg`;
export const ICON_PARTNERS_COMMON = `${ICON_BASE_PATH}/ic_partners_common.svg`;
export const ICON_PARTNERS_CLICK = `${ICON_BASE_PATH}/ic_partners_click.svg`;
export const ICON_PARTNERS_DISABLED = `${ICON_BASE_PATH}/ic_partners_disabled.svg`;
export const ICON_PARTNERS_DISABLED_CLICK = `${ICON_BASE_PATH}/ic_partners_disabled_click.svg`;

export const ICON_BATTERY_GOOD = `${ICON_BASE_PATH}/ic_battery_good.webp`;
export const ICON_BATTERY_NORMAL = `${ICON_BASE_PATH}/ic_battery_normal.webp`;
export const ICON_BATTERY_WARNING = `${ICON_BASE_PATH}/ic_battery_warning.webp`;

// Image
export const IMAGE_SCOOTER_01 = `${IMAGE_BASE_PATH}/img_scooter.png`;
export const IMAGE_SCOOTER_02 = `${IMAGE_BASE_PATH}/img_45_front_left_cutout_shadows.png`;
export const IMAGE_STATION_01 = `${IMAGE_BASE_PATH}/img_2_2_pople_01_2.png`;
export const IMAGE_BANNER_01 = `${IMAGE_BASE_PATH}/img_banner_01.png`;
export const IMAGE_MAP_BUTTON = `${IMAGE_BASE_PATH}/img_map_button.png`;
export const IMAGE_PONYA_HELLO_1 = `${IMAGE_BASE_PATH}/img_ponya_hello_1.png`;
export const IMAGE_PONYA_HELLO_2 = `${IMAGE_BASE_PATH}/img_ponya_hello_2.png`;
export const IMAGE_PONYA_HELLO_2_SHADOW = `${IMAGE_BASE_PATH}/img_ponya_hello_with_shadow.png`;
export const IMAGE_PETER_FIGHTING = `${IMAGE_BASE_PATH}/img_peter_fighting.png`;
export const IMAGE_PETER_FIGHTING2 = `${IMAGE_BASE_PATH}/img_peter_fighting2.png`;
export const IMAGE_PETER_SEARCH = `${IMAGE_BASE_PATH}/img_peter_search.png`;
export const IMAGE_PONYA_20 = `${IMAGE_BASE_PATH}/img_ponya_20.png`;
export const IMAGE_PETER_20 = `${IMAGE_BASE_PATH}/img_peter_20.png`;
export const IMAGE_DODO_20 = `${IMAGE_BASE_PATH}/img_dodo_20.png`;
export const IMAGE_PONYA_PICK = `${IMAGE_BASE_PATH}/img_ponya_pick.png`;
export const IMAGE_PETER_PICK = `${IMAGE_BASE_PATH}/img_peter_pick.png`;
export const IMAGE_DODO_PICK = `${IMAGE_BASE_PATH}/img_dodo_pick.png`;
export const IMAGE_PONYA = `${IMAGE_BASE_PATH}/img_ponya.png`;
export const IMAGE_PONYA_LEVEL1 = `${IMAGE_BASE_PATH}/img_ponya_level1.png`;
export const IMAGE_PONYA_LEVEL2 = `${IMAGE_BASE_PATH}/img_ponya_level2.png`;
export const IMAGE_PONYA_LEVEL3 = `${IMAGE_BASE_PATH}/img_ponya_level3.png`;
export const IMAGE_PONYA_LEVEL4 = `${IMAGE_BASE_PATH}/img_ponya_level4.png`;
export const IMAGE_PONYA_LEVEL5 = `${IMAGE_BASE_PATH}/img_ponya_level5.png`;
export const IMAGE_PETER_LEVEL1 = `${IMAGE_BASE_PATH}/img_peter_level1.png`;
export const IMAGE_PETER_LEVEL2 = `${IMAGE_BASE_PATH}/img_peter_level2.png`;
export const IMAGE_PETER_LEVEL3 = `${IMAGE_BASE_PATH}/img_peter_level3.png`;
export const IMAGE_PETER_LEVEL4 = `${IMAGE_BASE_PATH}/img_peter_level4.png`;
export const IMAGE_PETER_LEVEL5 = `${IMAGE_BASE_PATH}/img_peter_level5.png`;
export const IMAGE_DODO_LEVEL1 = `${IMAGE_BASE_PATH}/img_dodo_level1.png`;
export const IMAGE_DODO_LEVEL2 = `${IMAGE_BASE_PATH}/img_dodo_level2.png`;
export const IMAGE_DODO_LEVEL3 = `${IMAGE_BASE_PATH}/img_dodo_level3.png`;
export const IMAGE_DODO_LEVEL4 = `${IMAGE_BASE_PATH}/img_dodo_level4.png`;
export const IMAGE_DODO_LEVEL5 = `${IMAGE_BASE_PATH}/img_dodo_level5.png`;
export const IMAGE_DODO_SLEEP = `${IMAGE_BASE_PATH}/img_dodo_sleep.png`;
export const IMAGE_VC_BACKGROUND = `${IMAGE_BASE_PATH}/img_vc_background.png`;
export const IMAGE_QRCODE = `${IMAGE_BASE_PATH}/img_qrcode.png`;
export const IMAGE_MEMBERSHIP_BANNER = `${IMAGE_BASE_PATH}/img_membership_banner.png`;
export const IMAGE_US = `${IMAGE_BASE_PATH}/img_flag_us.svg`;
export const IMAGE_KHMER = `${IMAGE_BASE_PATH}/img_flag_khmer.svg`;
export const IMAGE_KOREA = `${IMAGE_BASE_PATH}/img_flag_korea.svg`;
export const IMAGE_VIETNAM = `${IMAGE_BASE_PATH}/img_flag_vietnam.svg`;
export const IMAGE_RESTAURANT = `${IMAGE_BASE_PATH}/img_restaurant.jpg`;
export const IMAGE_RESTAURANT2 = `${IMAGE_BASE_PATH}/img_restaurant2.jpg`;
export const IMAGE_RESTAURANT3 = `${IMAGE_BASE_PATH}/img_restaurant3.jpg`;
export const IMAGE_LOGIN_BACKGROUND = `${IMAGE_BASE_PATH}/img_login_background.webp`;
export const IMAGE_LOGIN_CHARACTER = `${IMAGE_BASE_PATH}/img_login_character.webp`;
export const IMAGE_EMPTY_IMAGE = `${IMAGE_BASE_PATH}/img_empty_image.png`;
export const IMAGE_EMPTY_IMAGE_CS = `${IMAGE_BASE_PATH}/img_empty_image_cs.png`;
export const IMAGE_EMPTY_IMAGE_CAFE = `${IMAGE_BASE_PATH}/img_empty_image_cafe.png`;
export const IMAGE_EMPTY_IMAGE_CHARGE = `${IMAGE_BASE_PATH}/img_empty_image_charge.png`;
export const IMAGE_EMPTY_IMAGE_PARTNERS = `${IMAGE_BASE_PATH}/img_empty_image_partners.png`;
export const IMAGE_NFT_CARD = `${IMAGE_BASE_PATH}/img_nft_card.png`;
export const IMAGE_NFT_COUPON_CARD = `${IMAGE_BASE_PATH}/img_nft_coupon_card.png`;

export const IMAGE_BATTERY_GOOD = `${IMAGE_BASE_PATH}/img_battery_good.png`;
export const IMAGE_BATTERY_BAD = `${IMAGE_BASE_PATH}/img_battery_bad.png`;
export const IMAGE_BATTERY_ERROR = `${IMAGE_BASE_PATH}/img_battery_error.png`;

export const IMAGE_BATTERY_STATION_USAGE_GUIDE_01 = `${IMAGE_BASE_PATH}/img_battery_station_usage_guide_01.webp`;
export const IMAGE_BATTERY_STATION_USAGE_GUIDE_02 = `${IMAGE_BASE_PATH}/img_battery_station_usage_guide_02.webp`;
export const IMAGE_BATTERY_STATION_USAGE_GUIDE_03 = `${IMAGE_BASE_PATH}/img_battery_station_usage_guide_03.webp`;
export const IMAGE_BATTERY_STATION_USAGE_GUIDE_04 = `${IMAGE_BASE_PATH}/img_battery_station_usage_guide_04.webp`;
export const IMAGE_BATTERY_STATION_USAGE_GUIDE_05 = `${IMAGE_BASE_PATH}/img_battery_station_usage_guide_05.webp`;
export const IMAGE_BATTERY_STATION_USAGE_GUIDE_06 = `${IMAGE_BASE_PATH}/img_battery_station_usage_guide_06.webp`;

export const IMAGE_PIN_PURPLE = `${IMAGE_BASE_PATH}/img_pin_purple.svg`;
export const IMAGE_SEARCH_PURPLE = `${IMAGE_BASE_PATH}/img_search_purple.svg`;

export const IMAGE_HOME_MAP = `${IMAGE_BASE_PATH}/img_home_map.webp`;
export const IMAGE_HOME_STATION = `${IMAGE_BASE_PATH}/img_home_station.webp`;
export const IMAGE_HOME_DUMMYBANNER = `${IMAGE_BASE_PATH}/img_home_dummybanner.png`;

export const APP_LOGO = `${IMAGE_BASE_PATH}/img_app_logo.png`;
export const APP_LOGO_VC = `${IMAGE_BASE_PATH}/img_app_logo_vc.png`;
export const APP_LANGUAGE_BACKGROUND = `${IMAGE_BASE_PATH}/img_app_bg.png`;
