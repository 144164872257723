import { Box, Typography } from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import { ICON_RIGHT_ARROW_GRAY600 } from "../../../constants/appImagePath";
import VerticalSpacer from "../../../component/Spacer";
import LinearProgressBar from "../../../component/LinearProgressBar";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";

interface TotalCarbonCardProps {
  totalCarbonReduction: number;
  levelName: string;
  levelMax: number;
  availableCoupon: number;
  onClick?: () => void;
  onCarbonReductionClick?: () => void;
  onCouponClick?: () => void;
}

const TotalCarbonCard = ({
  totalCarbonReduction,
  levelName,
  levelMax,
  availableCoupon,
  onClick,
  onCarbonReductionClick,
  onCouponClick,
}: TotalCarbonCardProps) => {
  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "white",
        borderRadius: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          pt: "16px",
          px: "16px",
          pb: "20px",
          boxSizing: "border-box",
          borderBottom: `1px solid ${color.gray200}`,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography fontStyle={fontStyle.titleM} color={color.default}>
            {LocalizedText("ua_h100_total_carbonreduction")}
          </Typography>
          <img
            src={ICON_RIGHT_ARROW_GRAY600}
            alt="Arrow"
            style={{
              width: "22px",
              height: "22px",
            }}
          />
        </Box>
        <VerticalSpacer height={8} />
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <Typography fontStyle={fontStyle.titleXL} color={color.default}>
              {totalCarbonReduction}
            </Typography>
            <Typography fontStyle={fontStyle.subTitleM} color={color.gray800}>
              kgCO₂-eq
            </Typography>
          </Box>
          <Typography fontStyle={fontStyle.subTitleXS} color={color.gray800}>
            Lv. {levelName}
          </Typography>
        </Box>
        <VerticalSpacer height={8} />
        <LinearProgressBar
          value={(totalCarbonReduction / levelMax) * 100}
          height={8}
          borderRadius={8}
          backgroundColor={color.gray200}
          barColor={color.secondary600}
        />
        <VerticalSpacer height={16} />
        <Box
          sx={{
            display: "flex",
            gap: "6px",
            px: "16px",
            py: "12px",
            bgcolor: "rgba(20, 195, 170, 0.08)",
            alignItems: "flex-end",
            borderRadius: "10px",
          }}
        >
          <Typography fontStyle={fontStyle.bodySr} color={color.gray900}>
            {LocalizedText(
              "ua_h100_left_carbon",
              `${levelMax - totalCarbonReduction}`
            )}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          px: "16px",
          py: "12px",
          boxSizing: "border-box",
          gap: "4px",
        }}
      >
        <Typography fontStyle={fontStyle.subTitleM} color={color.gray900}>
          {LocalizedText("ua_h100_coupon")}
        </Typography>
        <Typography fontStyle={fontStyle.subTitleS} color={color.secondary600}>
          {availableCoupon}
        </Typography>
      </Box>
    </Box>
  );
};

export default TotalCarbonCard;
