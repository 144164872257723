import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { fontStyle } from "../../../../theme/Style";
import VerticalSpacer from "../../../../component/Spacer";
import { CharacterCard } from "./section/CharacterCard";
import { CouponCard } from "./section/CouponCard";
import { ReductionHistory } from "./section/ReductionHistory";
import {
  CarbonReductionUiState,
  CharacterInfo,
} from "./model/CarbonReductionUiState";
import { CarbonReductionUiEvent } from "./model/CarbonReductionUiEvent";
import { color } from "../../../../theme/Color";
import { ICON_SELECT } from "../../../../constants/imagePath";

interface CarbonReductionPageProps {
  uiState: CarbonReductionUiState;
  uiEvent: CarbonReductionUiEvent;
}

export function CarbonReductionPage({
  uiState,
  uiEvent,
}: CarbonReductionPageProps) {
  const itemsPerPage = 3;
  const currentItems: CharacterInfo[] = uiState.characterInfo.slice(
    uiState.currentPage * itemsPerPage,
    (uiState.currentPage + 1) * itemsPerPage
  );

  return (
    <Grid container spacing={3}>
      <Grid item lg={12}>
        <VerticalSpacer height={16} />
        <Box sx={{ display: "flex", gap: 3 }}>
          {uiState.characterInfo.length === 0 && (
            <Card
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "0px",
                width: "100%",
                border: "1px solid",
                borderColor: color.gray300,
                borderRadius: "15px",
                boxShadow: "unset",
              }}
            >
              <CardContent
                sx={{
                  padding: "40px",
                  "&:last-child": {
                    paddingBottom: "40px",
                  },
                }}
              >
                <Typography fontStyle={fontStyle.regularM}>
                  No carbon reduction NFTs exist.
                </Typography>
              </CardContent>
            </Card>
          )}
        </Box>
        {uiState.characterInfo.length > 0 && (
          <>
            <Box
              sx={{
                display: "flex",
                height: "45px",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography fontStyle={fontStyle.semiboldL}>
                Carbon Reduction Character
              </Typography>
              {uiState.characterInfo.length > itemsPerPage && (
                <Box
                  sx={{
                    display: "flex",
                    width: "168px",
                    height: "45px",
                    borderRadius: "15px",
                    border: "1px solid",
                    borderColor: color.gray300,
                    backgroundColor: color.white,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "44px",
                      height: "44px",
                      transform: "rotate(90deg)",
                      cursor: "pointer",
                    }}
                    onClick={uiEvent.handlePrevPage}
                  >
                    <img
                      src={ICON_SELECT}
                      style={{ width: "28px", height: "28px" }}
                    ></img>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "80px",
                      height: "44px",
                      borderLeft: "1px solid",
                      borderRight: "1px solid",
                      borderColor: color.gray300,
                    }}
                  >
                    <Typography fontStyle={fontStyle.semiboldS}>
                      {uiState.currentPage + 1}/
                      {Math.ceil(uiState.characterInfo.length / itemsPerPage)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "44px",
                      height: "44px",
                      transform: "rotate(-90deg)",
                      cursor: "pointer",
                    }}
                    onClick={uiEvent.handleNextPage}
                  >
                    <img
                      src={ICON_SELECT}
                      style={{ width: "28px", height: "28px" }}
                    ></img>
                  </Box>
                </Box>
              )}
            </Box>
            <Box sx={{ display: "flex", gap: 3 }}>
              {currentItems.map((info, index) => (
                <CharacterCard key={index} data={info} />
              ))}
            </Box>
            <VerticalSpacer height={20} />
            <CouponCard uiState={uiState} uiEvent={uiEvent} />
            <VerticalSpacer height={20} />
            <ReductionHistory uiState={uiState} uiEvent={uiEvent} />
          </>
        )}
      </Grid>
    </Grid>
  );
}
