import { Box, Divider } from "@mui/material";

import { HorizontalSpacer } from "../../../../../component/Spacer";
import { fontStyle } from "../../../../../theme/Style";
import { color } from "../../../../../theme/Color";

export enum InventoryManagementTabType {
  INVENTORY = "inventory",
  RECEIVE_DELEVER = "receivedeliver",
}

export interface InventoryManagementTabProps {
  currentTab: InventoryManagementTabType;
  changeTab: (tab: InventoryManagementTabType) => void;
}

export function InventoryManagementTab({ currentTab, changeTab }: InventoryManagementTabProps) {
  return (
    <Box sx={{ display: "flex", height: "24px" }}>
      <HorizontalSpacer width={12} />
      <Box
        sx={{
          fontStyle: fontStyle.semiboldM,
          cursor: "pointer",
          color: currentTab == InventoryManagementTabType.INVENTORY ? color.primary500 : color.gray600,
        }}
        onClick={() => {
          changeTab(InventoryManagementTabType.INVENTORY);
        }}
      >
        Stock
      </Box>
      <HorizontalSpacer width={16} />
      <Divider orientation="vertical" />
      <HorizontalSpacer width={16} />
      <Box
        sx={{
          fontStyle: fontStyle.semiboldM,
          cursor: "pointer",
          color: currentTab == InventoryManagementTabType.RECEIVE_DELEVER ? color.primary500 : color.gray600,
        }}
        onClick={() => {
          changeTab(InventoryManagementTabType.RECEIVE_DELEVER);
        }}
      >
        In and Out
      </Box>
    </Box>
  );
}
