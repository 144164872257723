import { CardContent, Typography } from "@mui/material";
import { useState } from "react";
import { fontStyle } from "../../../../theme/Style";
import VerticalSpacer from "../../../../component/Spacer";
import { RegisterBillOfMaterialTreeTable } from "./RegisterBillOfMaterialTreeTable";
import { HeadCell } from "../../../../component/CommonTable";

export function RegisterBillOfMaterialTree() {
  const [docCount, setDocCount] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(4);
  const [rows, setRows] = useState<any[]>([]);
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState(0);
  return (
    <CardContent
      sx={{
        width: "100%",
        padding: "0px",
        "&:last-child": {
          paddingBottom: 0,
        },
      }}
    >
      <Typography fontStyle={fontStyle.semiboldL}>Bill of Material Tree</Typography>
      <VerticalSpacer height={16} />
      <RegisterBillOfMaterialTreeTable
        headCells={RegisterBillOfMaterialTreeHeadCells}
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        materialsList={rows}
      />
    </CardContent>
  );
}

const RegisterBillOfMaterialTreeHeadCells: HeadCell[] = [
  {
    disablePadding: false,
    align: "left",
    id: "category",
    label: "Category",
  },
  {
    disablePadding: false,
    align: "left",
    id: "partsCode",
    label: "Parts Code",
  },
  {
    disablePadding: false,
    align: "left",
    id: "productName",
    label: "Product Name",
  },
  {
    disablePadding: false,
    align: "left",
    id: "spec",
    label: "Spec",
  },
  {
    disablePadding: false,
    align: "left",
    id: "quantities",
    label: "Quantities",
  },
  {
    disablePadding: false,
    align: "left",
    id: "amount",
    label: "Amount",
  },
  {
    disablePadding: false,
    align: "left",
    id: "company",
    label: "Company",
  },
  {
    disablePadding: false,
    align: "left",
    id: "",
    label: "",
  },
  {
    disablePadding: false,
    align: "left",
    id: "",
    label: "",
  },
];
