import { Box } from "@mui/material";
import CommonButton from "../../../../../component/CommonButton";
import { TabType } from "../model/TabType";
import { color } from "../../../../../theme/Color";
import { CarbonReductionUiState } from "../model/CarbonReductionUiState";

interface ReductionHistoryTabProps {
  onClick: (tab: TabType) => void;
  uiState: CarbonReductionUiState;
}

export function ReductionHistoryTab({
  onClick,
  uiState,
}: ReductionHistoryTabProps) {
  return (
    <Box sx={{ display: "flex", alignItems: "flex-end", gap: "12px" }}>
      <CommonButton
        onClick={() => onClick(TabType.YEAR)}
        title="Year"
        textColor={
          uiState.currentTab === TabType.YEAR ? color.primary500 : color.gray600
        }
        sx={{
          height: "44px",
          width: "84px",
          borderRadius: "54px",
          backgroundColor:
            uiState.currentTab === TabType.YEAR ? color.primary100 : color.white,
          border: uiState.currentTab === TabType.YEAR ? "" : "1px solid",
          borderColor: uiState.currentTab === TabType.YEAR ? "" : color.gray300,
        }}
      />
      <CommonButton
        onClick={() => onClick(TabType.MONTH)}
        title="Month"
        textColor={
          uiState.currentTab === TabType.MONTH ? color.primary500 : color.gray600
        }
        sx={{
          height: "44px",
          width: "84px",
          borderRadius: "54px",
          backgroundColor:
            uiState.currentTab === TabType.MONTH ? color.primary100 : color.white,
          border: uiState.currentTab === TabType.MONTH ? "" : "1px solid",
          borderColor: uiState.currentTab === TabType.MONTH ? "" : color.gray300,
        }}
      />
      <CommonButton
        onClick={() => onClick(TabType.DAY)}
        title="Day"
        textColor={
          uiState.currentTab === TabType.DAY ? color.primary500 : color.gray600
        }
        sx={{
          height: "44px",
          width: "84px",
          borderRadius: "54px",
          backgroundColor:
            uiState.currentTab === TabType.DAY ? color.primary100 : color.white,
          border: uiState.currentTab === TabType.DAY ? "" : "1px solid",
          borderColor: uiState.currentTab === TabType.DAY ? "" : color.gray300,
        }}
      />
    </Box>
  );
}
