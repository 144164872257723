import { useEffect, useState } from "react";
import CommonList from "../../../component/CommonList";
import { Box, Button } from "@mui/material";
import { ADMIN_CONFIG } from "../../../constants/AdminConfig";
import VerticalSpacer, { HorizontalSpacer } from "../../../component/Spacer";

import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import GHGReductionTabPage from "./section/GHGReductionTab/GHGReductionTabPage";
import GHGReductionScooterTabPage from "./section/GHGReductionScooterTab/GHGReductionScooterTabPage";

const CarbonReductionInfoPage = () => {
  const [tabVelue, setTabVelue] = useState("GHGReduction");
  return (
    <>
      <CommonList title={ADMIN_CONFIG.MENU_CARBON_REDUCTION_INFO.TITLE} setModal={null}>
        {/* <Box sx={{ display: "flex" }} draggable={true}>
          <Box
            sx={{
              bgcolor: tabVelue == "GHGReduction" ? color.primary200 : "none",
              cursor: "pointer",
              fontStyle: fontStyle.semiboldS,
              color: color.primary500,
              px: "8px",
              py: "8px",
              borderRadius: "10px",
            }}
            onClick={() => {
              setTabVelue("GHGReduction");
            }}
          >
            GHG Reduction
          </Box>
          <HorizontalSpacer width={16} />
          <Box
            sx={{
              bgcolor: tabVelue == "GHGReductionScooter" ? color.primary200 : "none",
              cursor: "pointer",
              fontStyle: fontStyle.semiboldS,
              color: color.primary500,
              px: "8px",
              py: "8px",
              borderRadius: "10px",
            }}
            onClick={() => {
              setTabVelue("GHGReductionScooter");
            }}
          >
            GHG Reduction Scooter
          </Box>
        </Box> */}
        {tabVelue == "GHGReduction" && <GHGReductionTabPage />}
        {tabVelue == "GHGReductionScooter" && <GHGReductionScooterTabPage />}
      </CommonList>
    </>
  );
};

export default CarbonReductionInfoPage;
