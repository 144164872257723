import MyRideRender from "./MyRideRender";
import { MyRideUiState } from "./model/MyRideUiState";
import { useEffect, useState } from "react";
import { ApiService } from "../../restAPI/ApiService";
import ProgressBar from "../../component/ProgressBar";
import { MyRideUiEvent } from "./model/MyRideUiEvent";
import { UserProfile } from "../MobileProfile/model/UserProfile";
import { Box } from "@mui/material";
import { Simulate } from "react-dom/test-utils";
import LocalizedDate, { DateFormatType } from "../../component/LocalizedDate";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../constants/RouteConstants";

const MyRidePage = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [uiState, setUiState] = useState(new MyRideUiState());
  const [progress, setProgress] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {}, []);

  useEffect(() => {
    const requestBody = {
      uuid: localStorage.getItem("uuid") || "",
    };
    const init = async () => {
      await ApiService.MobilePost(
        "/pw/backend/api/scooter/userScoo",
        requestBody
      )
        .then(async (res) => {
          if (res?.data.body.length <= 0) {
            setUiState((prev) => {
              return {
                ...prev,
                hasScooter: false,
              };
            });

            await ApiService.MobilePost(
              "/pw/backend/api/scooterAgreement/list",
              requestBody
            )
              .then((res) => {
                const data = res?.data.body.handOverList;
                if (data.length > 0) {
                  setUiState((prev) => {
                    return {
                      ...prev,
                      hasScooterContractList: true,
                      scooterContractList: data,
                    };
                  });
                } else {
                  setUiState((prev) => {
                    return {
                      ...prev,
                      hasScooterContractList: false,
                    };
                  });
                }
                setLoading(false);
              })
              .catch((e) => {
                setError(`Failed to load scooter contract. ${e}`);
              });
          } else {
            setUiState((prev) => {
              return {
                ...prev,
                hasScooter: true,
                scooterList: res?.data.body,
              };
            });
            setLoading(false);
          }
        })
        .finally(() => {
          uiEventHandler.SetRefreshAPI(false);
        });
    };

    init().then((r) => {});
  }, [uiState.refreshAPI]);

  // useEffect(() => {
  //   console.log("uiState.refreshAPI is : ", uiState.refreshAPI);
  // }, [uiState.refreshAPI]);
  // useEffect(() => {
  //   if (hasQr == true) {
  //     setUiState((prev) => {
  //       return {
  //         ...prev,
  //         hasScooterTest: true,
  //       };
  //     });
  //   } else {
  //     setUiState((prev) => {
  //       return {
  //         ...prev,
  //         hasScooterTest: false,
  //       };
  //     });
  //   }
  // }, []);

  const uiEventHandler: MyRideUiEvent = {
    SetContractList: (event: any) => {
      setUiState((prev) => {
        return {
          ...prev,
          scooterContractList: event,
        };
      });
    },
    SetRefreshAPI: (event: boolean) => {
      setUiState((prev) => {
        return {
          ...prev,
          refreshAPI: event,
        };
      });
    },
    onClickPickUp: function (contractListItem: any): void {
      handleUserProfile(contractListItem);
    },
    navigate: (path: string) => {
      navigate(path);
    },
    closeUserInfoEnterDialog: function (): void {
      setUiState((prev) => {
        return {
          ...prev,
          isShownUserInfoEnterDialog: false,
        };
      });
    },
    goToAdditionalInfoPage: (contractListItem: any) => {
      navigate(ROUTE_PATHS.ADDITIONAL_INFO, {
        state: {
          state: {
            productName: contractListItem.productName,
            agreementDt: LocalizedDate({
              date: contractListItem.agreementDt,
              type: DateFormatType.YearMonthDay,
            }),
            agreementNumber: contractListItem.agreementNumber,
            modelPicUrl: contractListItem.modelPicUrl,
            receiptConfirmation: contractListItem.receiptConfirmation,
          },
        }, // 상태 전달
      });
    },
  };

  async function getUserProfile(): Promise<UserProfile | undefined> {
    const url = "/pw/users/detailProfile";
    const requestBody = {
      uuid: localStorage.getItem("uuid"),
    };

    return ApiService.post(url, requestBody)
      .then((res) => {
        const user: UserProfile = res.data.body;
        return user;
      })
      .catch((error) => {
        throw error;
      });
  }

  async function handleUserProfile(contractListItem: any) {
    setProgress(true);
    try {
      const userProfile = await getUserProfile();

      if (userProfile && userProfile.addressCountry && userProfile.addressDetail) {
        goToWaitingPage(contractListItem);
      } else {
        setUiState((prev) => {
          return {
            ...prev,
            isShownUserInfoEnterDialog: true,
          };
        });
      }
    } catch (error) {
      // TODO 에러 토스트 or 팝업
      console.error("Error handling user profile:", error);
    } finally {
      setProgress(false);
    }
  }

  function goToWaitingPage(contractListItem: any) {
    navigate(ROUTE_PATHS.SCOOTER_WAITING, {
      state: {
        state: {
          productName: contractListItem.productName,
          agreementDt: LocalizedDate({
            date: contractListItem.agreementDt,
            type: DateFormatType.YearMonthDay,
          }),
          agreementNumber: contractListItem.agreementNumber,
          modelPicUrl: contractListItem.modelPicUrl,
          receiptConfirmation: contractListItem.receiptConfirmation,
        },
      }, // 상태 전달
    });
  }

  if (loading) {
    return <ProgressBar />;
  }
  if (error) {
    return <div>{error}</div>;
  }
  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      {progress ? <ProgressBar /> : null}
      <MyRideRender uiState={uiState} uiEvent={uiEventHandler} />;
    </Box>
  );
};

export default MyRidePage;
