import { Box, Card, Grid } from "@mui/material";
import CommonList from "../../../../../../../../component/CommonList";
import { CommonWebHeaderButton } from "../../../../../../../../component/CommonWebHeaderButton";
import { color } from "../../../../../../../../theme/Color";
import VerticalSpacer from "../../../../../../../../component/Spacer";
import { RegisterBillOfMaterialDate } from "./BillOfMaterialDetailDate";
import FindInList from "../../../../../../component/findInList/FindInList";
import FindPartList from "../../../../../../component/findPartList/FindPartList";

export function BillOfMaterialsDetails() {
  return (
    <CommonList
      title="자재 명세 상세"
      headerBackButton={true}
      navigateURL="/materialDetails"
      headerButton={<CommonWebHeaderButton buttonContent="Update" height="45px" sx={{ display: "flex", flexDirection: "column" }} />}
    >
      <Grid item lg={12}>
        <FindPartList />
        <VerticalSpacer height={20} />
        <Card
          sx={{
            borderRadius: "15px",
            border: "1px solid",
            borderColor: color.gray300,
            padding: "24px",
            boxShadow: 0,
          }}
        >
          <RegisterBillOfMaterialDate />
        </Card>
      </Grid>
    </CommonList>
  );
}
