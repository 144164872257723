export class ProductNameRegistrationUiState {
  productList: ProductList[];
  searchContents: string;
  docCount: number;
  rowsPerPage: number;
  page: number;
  pageCount: number;
  ProductId: string;
  PartsCode: string;
  ProductName: string;
  image: string;
  modelName: string;
  backDialogOpen: boolean;
  registerDialogOpen: boolean;
  constructor(
    productList: ProductList[] = [
      {
        category: "",
        id: "",
        image: "",
        partsCode: "",
        productName: "",
        spec: "",
      },
    ],
    searchContents: string = "",
    docCount: number = 0,
    rowsPerPage: number = 4,
    page: number = 0,
    pageCount: number = 0,
    productId: string = "",
    partsCode: string = "",
    productName: string = "",
    image: string = "",
    modelName: string = "",
    backDialogOpen: boolean = false,
    registerDialogOpen: boolean = false
  ) {
    this.productList = productList;
    this.searchContents = searchContents;
    this.docCount = docCount;
    this.rowsPerPage = rowsPerPage;
    this.page = page;
    this.pageCount = pageCount;
    this.ProductId = productId;
    this.PartsCode = partsCode;
    this.ProductName = productName;
    this.image = image;
    this.modelName = modelName;
    this.backDialogOpen = backDialogOpen;
    this.registerDialogOpen = registerDialogOpen;
  }
}

export interface ProductList {
  category: string;
  id: string;
  image: string;
  partsCode: string;
  productName: string;
  spec: string;
}
