import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import VerticalSpacer from "../../../../../component/Spacer";
import { fontStyle } from "../../../../../theme/Style";
import { color } from "../../../../../theme/Color";
import { CommonSelect } from "../../../../../component/CommonSelect";
import { CommonWebHeaderButton } from "../../../../../component/CommonWebHeaderButton";
import { UserNFCDetailTagLogCard } from "./UserNFCDetailTagLogCard";
import { UserNFCUiState } from "../model/UserNFCUiState";
import LocalizedDate, {
  DateFormatType,
} from "../../../../../component/LocalizedDate";
import { UserNFCUiEvent } from "../model/UserNFCUiEvent";

interface UserNFCDetailCardProps {
  uiState: UserNFCUiState;
  uiEvent: UserNFCUiEvent;
}

export function UserNFCDetailCard({
  uiState,
  uiEvent,
}: UserNFCDetailCardProps) {
  const reasonMenuItem =
    uiState.nfcCard.valid === "Active"
      ? [
          "Regist(New)",
          "Regist(Lost)",
          "Regist(Defect)",
          "Regist(Request)",
          "Regist(etc)",
        ]
      : [
          "No Regist",
          "Regist(Lost)",
          "Regist(Defect)",
          "Regist(Request)",
          "Regist(etc)",
        ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Card
          sx={{
            width: "100%",
            padding: "0px",
            border: "1px solid",
            borderColor: color.gray300,
            borderRadius: "15px",
            boxShadow: "unset",
          }}
        >
          <CardContent sx={{ padding: "24px" }}>
            <Typography fontStyle={fontStyle.semiboldL}>Information</Typography>
            <VerticalSpacer height={16} />
            <Box>
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Card
              </Typography>
              <VerticalSpacer height={8} />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <CommonSelect
                  selectValue={uiState.nfcCard.cardName}
                  onChange={(event) => {}}
                  menuitems={[]}
                  sx={{ height: "48px" }}
                  disabled
                />
              </Box>
              <VerticalSpacer height={40} />
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Type
              </Typography>
              <VerticalSpacer height={8} />
              <Box
                sx={{
                  height: "48px",
                  backgroundColor: color.gray200,
                  border: "1px solid",
                  borderRadius: "10px",
                  borderColor: color.gray300,
                  px: "16px",
                  py: "12px",
                }}
              >
                <Typography
                  fontStyle={fontStyle.regularS}
                  color={color.gray600}
                >
                  {uiState.nfcCard.type}
                </Typography>
              </Box>
              <VerticalSpacer height={40} />
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Valid
              </Typography>
              <VerticalSpacer height={8} />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <CommonSelect
                  selectValue={uiState.nfcCard.valid}
                  onChange={(event) => {
                    uiEvent.selectValid(event.target.value);
                  }}
                  menuitems={["Active", "Inactive"]}
                  sx={{ height: "48px" }}
                  placeholder="Select"
                />
              </Box>
              <VerticalSpacer height={40} />
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Reason
              </Typography>
              <VerticalSpacer height={8} />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <CommonSelect
                  selectValue={uiState.nfcCard.reason}
                  onChange={(event) => {
                    uiEvent.selectReason(event.target.value);
                  }}
                  menuitems={reasonMenuItem}
                  sx={{ height: "48px" }}
                  disabled={uiState.nfcCard.valid === ""}
                  error={uiState.errors.reasonError}
                  placeholder="Select"
                />
              </Box>
              {uiState.errors.reasonError && (
                <Typography fontStyle={fontStyle.regularXS} color={color.error}>
                  Please select a reason.
                </Typography>
              )}
              <VerticalSpacer height={40} />
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Registration Date
              </Typography>
              <VerticalSpacer height={8} />
              <Box
                sx={{
                  height: "48px",
                  backgroundColor: color.gray200,
                  border: "1px solid",
                  borderRadius: "10px",
                  borderColor: color.gray300,
                  px: "16px",
                  py: "12px",
                }}
              >
                <Typography
                  fontStyle={fontStyle.regularS}
                  color={color.gray600}
                >
                  {LocalizedDate({
                    date: uiState.nfcCard.registrationDate,
                    type: DateFormatType.AdminType,
                  })}
                </Typography>
              </Box>
              <VerticalSpacer height={40} />
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <CommonWebHeaderButton
                  sx={{
                    width: "85px",
                    height: "45px",
                    borderRadius: "40px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  buttonContent="Update"
                  onClick={uiEvent.handleUpdateNFC}
                />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <UserNFCDetailTagLogCard uiState={uiState} />
      </Grid>
    </Grid>
  );
}
