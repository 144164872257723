import { Box, Button, Drawer, Typography } from "@mui/material";
import Toolbar from "../../../component/Toolbar";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import VerticalSpacer from "../../../component/Spacer";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import { MembershipPurchaseUiEvent } from "./MembershipPurchaseUiEvent";
import { MembershipPurchaseUiState } from "./MembershipPurchaseUiState";
import { MembershipPurchaseSideEffect } from "./MembershipPurchaseSideEffect";
import {
  ICON_MEMBERSHIP_BATTERY,
  ICON_MEMBERSHIP_PERSON,
  ICON_MEMBERSHIP_SCOOTER,
  IMAGE_MEMBERSHIP_BANNER,
} from "../../../constants/appImagePath";
import BenefitCard from "./section/BenefitCard";
import MembershipSection from "./section/MembershipSection";
import VoucherSection from "./section/VoucherSection";
import { ROUTE_PATHS } from "../../../constants/RouteConstants";
import PurchaseDrawer from "../section/PurchaseDrawer";
import CommonButton from "../../../component/CommonButton";

interface MembershipPurchaseRenderProps {
  sideEffect: MembershipPurchaseSideEffect;
  uiState: MembershipPurchaseUiState;
  uiEvent: MembershipPurchaseUiEvent;
}

const MembershipPurchaseRender = ({
  sideEffect,
  uiState,
  uiEvent,
}: MembershipPurchaseRenderProps) => {
  const purchaseButtonText = LocalizedText("ua_g410_purchasemembership_button");

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100vw",
          height: "100vh",
          alignItems: "center",
        }}
      >
        {/* 상단 toolbar */}
        <Box
          sx={{
            backgroundColor: "white",
            width: "100%",
          }}
        >
          <Toolbar
            title={LocalizedText("ua_g410_membership")}
            onBack={() => {
              window.history.back();
            }}
          />
        </Box>

        <Box
          sx={{
            backgroundColor: color.gray50,
            width: "100%",
            overflowY: "auto",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {/* 상단 배너 */}
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              src={IMAGE_MEMBERSHIP_BANNER}
              alt="membership-banner"
              sx={{ width: "100%" }}
            />
            <Box
              sx={{
                position: "absolute",
                width: "70%",
                height: "45%",
                top: "0%",
                left: "50%",
                transform: "translateX(-50%)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                fontStyle={fontStyle.headingS}
                color={color.default}
                sx={{ textAlign: "center", whiteSpace: "pre-line" }}
              >
                {LocalizedText("ua_g410_affordablemembership")}
              </Typography>
            </Box>
          </Box>

          <VerticalSpacer height={60} />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              px: "20px",
            }}
          >
            <Typography
              fontStyle={fontStyle.headingS}
              sx={{
                textAlign: "center",
                color: color.default,
                width: "70%",
                wordBreak: "keep-all",
              }}
            >
              {LocalizedText("ua_g410_membership_title")}
            </Typography>

            <VerticalSpacer height={56} />

            {/* 멤버십 Benefits */}
            <Box
              sx={{
                backgroundColor: "white",
                borderRadius: "10px",
                px: "16px",
                py: "6px",
              }}
            >
              <Typography fontStyle={fontStyle.titleM} color={color.primary500}>
                {LocalizedText("ua_g410_benefits")}
              </Typography>
            </Box>

            <VerticalSpacer height={16} />

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                px: "16px",
                width: "100%",
                boxSizing: "border-box",
              }}
            >
              <BenefitCard
                icon={ICON_MEMBERSHIP_PERSON}
                description={LocalizedText("ua_g410_variousmembership")}
              />

              <VerticalSpacer height={16} />

              <BenefitCard
                icon={ICON_MEMBERSHIP_SCOOTER}
                description={LocalizedText("ua_g410_economicalrides")}
              />

              <VerticalSpacer height={16} />

              <BenefitCard
                icon={ICON_MEMBERSHIP_BATTERY}
                description={LocalizedText("ua_g410_convenience")}
              />
            </Box>

            <VerticalSpacer height={66} />

            {/* Product */}
            <Box
              sx={{
                backgroundColor: "white",
                borderRadius: "10px",
                px: "16px",
                py: "6px",
              }}
            >
              <Typography fontStyle={fontStyle.titleM} color={color.primary500}>
                {LocalizedText("ua_g410_producttype")}
              </Typography>
            </Box>

            <VerticalSpacer height={16} />

            {/* Tabs */}
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                mb: "16px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "white",
                  borderRadius: "24px",
                  px: "12px",
                  py: "6px",
                  border: `1px solid ${
                    uiState.productType === "Membership"
                      ? color.primary500
                      : color.gray300
                  }`,
                }}
                onClick={() => uiEvent.HandleProductTypeChange("Membership")}
              >
                <Typography
                  fontStyle={fontStyle.bodySr}
                  color={
                    uiState.productType === "Membership"
                      ? color.primary500
                      : color.gray300
                  }
                >
                  {LocalizedText("ua_g410_membership")}
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: "white",
                  borderRadius: "24px",
                  px: "12px",
                  py: "6px",
                  border: `1px solid ${
                    uiState.productType === "Voucher"
                      ? color.primary500
                      : color.gray300
                  }`,
                }}
                onClick={() => uiEvent.HandleProductTypeChange("Voucher")}
              >
                <Typography
                  fontStyle={fontStyle.bodySr}
                  color={
                    uiState.productType === "Voucher"
                      ? color.primary500
                      : color.gray300
                  }
                >
                  {LocalizedText("ua_g410_voucher")}
                </Typography>
              </Box>
            </Box>

            {uiState.productType === "Membership" ? (
              <MembershipSection membershipData={uiState.membershipItems} />
            ) : (
              <VoucherSection voucherData={uiState.voucherItems} />
            )}

            <VerticalSpacer height={146} />
          </Box>
        </Box>
      </Box>

      {/* Membership 구매하기 버튼 */}
      {!uiState.isPurchaseButtonHidden && (
        <Box
          sx={{
            position: "fixed",
            bottom: "34px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
            px: "20px",
            boxSizing: "border-box",
          }}
        >
          <CommonButton
            title={purchaseButtonText}
            onClick={() => {
              uiEvent.ShowMembershipDrawer();
            }}
          />
        </Box>
      )}

      {/** Membership Drawer **/}
      <Drawer
        anchor="bottom"
        open={uiState.isMembershipDrawerOpen}
        onClose={uiEvent.HideMembershipDrawer}
        sx={{
          "& .MuiDrawer-paper": {
            width: "100%",
            height: "auto",
            maxHeight: "calc(100vh - 50px)",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          },
        }}
      >
        <PurchaseDrawer
          productOptions={uiState.membershipItems}
          selectedProduct={uiState.selectedMembership}
          onSelectProduct={(product) => {
            uiEvent.SelectMembership(product);
          }}
          onClose={uiEvent.HideMembershipDrawer}
          onPay={(product) => {
            sideEffect.navigate(ROUTE_PATHS.PAY_QR, {
              state: {
                productId: product.id,
                userUUID: localStorage.getItem("uuid") || "",
              },
            });
          }}
          titleKey={LocalizedText("ua_g411_modal_chargemembership")}
        />
      </Drawer>
    </>
  );
};

export default MembershipPurchaseRender;
