import { useEffect, useState } from "react";
import { WalletPage } from "./WalletPage";
import { WalletUiState } from "./model/WalletUiState";
import { WalletUiEvent } from "./model/WalletUiEvent";
import { useNavigate } from "react-router-dom";
import { ApiService } from "../../../restAPI/ApiService";

export function Wallet() {
  const navigate = useNavigate();
  const [uiState, setUiState] = useState(new WalletUiState());

  const uiEvent: WalletUiEvent = {
    onClick: (id: string | number) => {
      navigate(`/walletDetail/${id}`);
    },

    handleUiStateChange: (key: keyof WalletUiState, value: any) => {
      setUiState((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    },
  };

  async function checkWallet(): Promise<boolean | undefined> {
    const url = "";
    const requestBody = {};

    try {
      const res = await ApiService.webPost(url, requestBody);
      const data = res.data;
      if (typeof data === "object" && Object.keys(data).length === 0)
        return undefined;
      return data;
    } catch (e) {
      setUiState((prev) => ({
        ...prev,
        page: 0,
        pageCount: 1,
      }));
    }
  }
  useEffect(() => {
    checkWallet();
  }, [uiState.page, uiState.rowsPerPage]);
  return <WalletPage uiState={uiState} uiEvent={uiEvent} />;
}
