import { Box, CardContent, Typography } from "@mui/material";
import { useState } from "react";
import { MaterialRegistrationFindPartsTable } from "./MaterialRegistrationFindPartsTable";
import { ReceiveDeliverData } from "../model/RecieveDeliverData";
import { ReceiveDeliverUiState } from "../model/ReceiveDeliverUiState";
import { ReceiveDeliverUiEvent } from "../model/ReceiveDeliverUiEvent";
import { fontStyle } from "../../../../../../theme/Style";
import VerticalSpacer, { HorizontalSpacer } from "../../../../../../component/Spacer";
import { color } from "../../../../../../theme/Color";
import CommonTextField from "../../../../../../component/CommonTextField";
import CommonErrorText from "../../../../../../component/CommonErrorText";

interface MaterialRegistrationFindPartsProps {
  uiState: ReceiveDeliverUiState;
  uiEvent: ReceiveDeliverUiEvent;
}
export function MaterialRegistrationFindParts({ uiState, uiEvent }: MaterialRegistrationFindPartsProps) {
  return (
    <CardContent
      sx={{
        width: "100%",
        padding: "0px",
        "&:last-child": {
          paddingBottom: 0,
        },
      }}
    >
      <Typography fontStyle={fontStyle.semiboldL}>Request Parts</Typography>
      <VerticalSpacer height={16} />
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "89%",
          }}
        >
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Material Name
          </Typography>
          <VerticalSpacer height={8} />
          <CommonTextField
            sx={{ minHeight: "48px", flexGrow: 1 }}
            value={uiState.MaterialName}
            onChange={(event) => {
              // setBranch(event.target.value);
              uiEvent.HandleUiStateChange("MaterialName", event.target.value);
            }}
            error={uiState.MaterialNameError}
          />
          {uiState.MaterialNameError && <CommonErrorText value="Please enter a Material Name." sx={{ ml: "4px", mt: "4px" }} />}
        </Box>
        <HorizontalSpacer width={28} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "11%",
          }}
        >
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Count
          </Typography>
          <VerticalSpacer height={8} />
          <CommonTextField
            sx={{ minHeight: "48px", flexGrow: 1 }}
            value={uiState.Count.toString()}
            onChange={(event) => {
              // setBranch(event.target.value);
              uiEvent.HandleUiStateChange("Count", parseInt(event.target.value));
            }}
            inputProps={{
              type: "number", // 숫자만 입력 가능하도록 설정
            }}
            error={uiState.CountError}
          />
          {uiState.CountError && <CommonErrorText value="Please enter the count." sx={{ ml: "4px", mt: "4px" }} />}
        </Box>
      </Box>
    </CardContent>
  );
}
