import {
  Box,
  Divider,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { ApiService } from "../../restAPI/ApiService";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import VerticalSpacer from "../../component/Spacer";
import { PWUserAppMapUiEvent } from "./PWUserAppMapUiEvent";
import { LocalizedText } from "../../di/LanguageRepositoryProvider";
import { fontStyle } from "../../theme/Style";
import { color } from "../../theme/Color";
import { BannerModel } from "../MobilePartners/model/BannerModel";
import { getCountryCode } from "../../customHook/useLocale";
import { ROUTE_PATHS } from "../../constants/RouteConstants";
import { PWUserAppMapUiState } from "./PWUserAppMapUiState";
import { SearchServiceItem } from "./search/component/SearchServiceItem";
import { RecentSearchItem } from "./search/component/RecentSearchItem";
import { Text } from "../../component/Text";

interface SwiperPhotosProps {
  banners: BannerModel[];
}

// 배너 자리 보여주는 컴포넌트
const SwiperPhotos = ({ banners }: SwiperPhotosProps) => {
  const navigate = useNavigate();

  type CustomCSSProperties = React.CSSProperties & {
    "--swiper-pagination-color"?: string;
    "--swiper-pagination-top"?: string;
    "--swiper-pagination-left"?: string;
    "--swiper-pagination-right"?: string;
    "--swiper-pagination-fraction-color"?: string;
  };

  // Define the custom styles
  const swiperStyles: CustomCSSProperties = {
    "--swiper-pagination-fraction-color": "white",
    // "--swiper-pagination-top": "75%",
    // "--swiper-pagination-left": "75%",
    height: "100%",
    width: "100%",
  };

  function handleLinkAction(link: string | undefined) {
    if (!link) {
      console.log("Link does not exist");
      return;
    }

    if (link.startsWith("notice://")) {
      handleNoticeLink(link);
    } else if (link.startsWith("http")) {
      handleExternalLink(link);
    } else {
      console.error("Invalid link format : ", link);
    }
  }

  function handleNoticeLink(link: string) {
    const noticeId = link.split("notice://")[1];
    if (noticeId) {
      navigate(ROUTE_PATHS.NOTICE_DETAIL, { state: { id: noticeId } });
    } else {
      console.error("Invalid notice link id");
    }
  }

  function handleExternalLink(link: string) {
    if (window.Android) {
      window.Android.openExternalBrowser(link);
    } else if (window.webkit) {
      window.webkit.messageHandlers.openExternalBrowser.postMessage(link);
    } else {
      console.log("Web browser open: ", link);
    }
  }

  return (
    <>
      <Swiper
        style={swiperStyles}
        slidesPerView={1}
        // height={100}
        spaceBetween={10}
        pagination={{
          type: "fraction",
          renderFraction: function (currentClass: any, totalClass: any) {
            return (
              '<div style="background-color: gray; border-radius: 10px; display: inline-block; margin-left: 82%; width:12%;">' +
              '<span class="' +
              currentClass +
              '" style="">asdasdasdsadsad</span>' +
              "/" +
              '<span class="' +
              totalClass +
              '"></span>' +
              "</div>"
            );
          },
        }}
        modules={[Pagination]}
      >
        {banners.map((item, index) => (
          <SwiperSlide key={index}>
            <img
              src={item.image}
              style={{ width: "100%", height: "100%", borderRadius: 10 }}
              onClick={() => {
                handleLinkAction(item.link);
              }}
            />
            <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

interface SearchStationPageProps {
  uiEvent: PWUserAppMapUiEvent;
  uiState: PWUserAppMapUiState;
  haversine: (lat1: number, lon1: number, lat2: number, lot2: number) => number;
}

const SearchStationPage = ({
  uiEvent,
  uiState,
  haversine,
}: SearchStationPageProps) => {
  const navigate = useNavigate();
  const [searchWord, setSearchWord] = useState("");
  const [searchHistory, setSearchHistory] = useState<any>([]);
  const [searchResults, setSearchResults] = useState([]); // 검색 결과 상태 추가

  const translateWord = {
    ua_c200_searchstation: LocalizedText("ua_c200_searchstation"),
    ua_c200_recentresearch: LocalizedText("ua_c200_recentresearch"),
    ua_c200_last3months: LocalizedText("ua_c200_last3months"),
    ua_c200_deleteall: LocalizedText("ua_c200_deleteall"),
    ua_c200_noresults: LocalizedText("ua_c200_noresults"),
    ua_c200_wordcorrectly: LocalizedText("ua_c200_wordcorrectly"),
    ua_c200_nohistory: LocalizedText("ua_c200_nohistory"),
  };

  // 페이지가 로드될 때 로컬 스토리지에서 검색 기록을 가져옴
  useEffect(() => {
    const savedHistory = localStorage.getItem("searchHistory");
    if (savedHistory) {
      setSearchHistory(JSON.parse(savedHistory));
    }
  }, []);

  // 검색어 변경 핸들러
  const handleSearchWordChange = (event: any) => {
    setSearchWord(event.target.value);
    // API 요청 보내기
    const searchData = {
      searchCondition: event.target.value,
    };
    if (event.target.value.length > 0) {
      ApiService.MobilePost("/pw/backend/api/geo/search", searchData).then(
        (res: any) => {
          // API 응답 데이터를 searchResults 상태에 저장
          setSearchResults(res?.data.body || []);
          console.log("data : ", searchResults);
        },
      );
    }
  };

  useEffect(() => {
    if (searchWord.length === 0) {
      setSearchResults([]);
    }
  }, [searchWord]);

  useEffect(() => {
    console.log(searchResults);
  }, [searchResults]);

  // 특정 검색어 삭제 핸들러
  const handleDeleteHistory = (idToDelete: any) => {
    const updatedHistory = searchHistory.filter(
      (item: any) => item.svcAreaId !== idToDelete,
    );
    localStorage.setItem("searchHistory", JSON.stringify(updatedHistory));
    setSearchHistory(updatedHistory);
  };

  const handleSearchResultClick = (item: any) => {
    // 기존 검색 기록에서 클릭된 아이템을 찾기
    const existingIndex = searchHistory.findIndex(
      (historyItem: any) => historyItem.svcAreaId === item.svcAreaId,
    );

    let updatedHistory;
    if (existingIndex !== -1) {
      // 아이템이 존재하면, 해당 아이템을 제거하고 맨 앞에 추가
      const existingItem = searchHistory[existingIndex];
      const filteredHistory = searchHistory.filter(
        (historyItem: any) => historyItem.svcAreaId !== item.svcAreaId,
      );
      updatedHistory = [existingItem, ...filteredHistory];
    } else {
      // 중복이 아니면 검색 기록에 추가하고 가장 위에 위치시키기
      updatedHistory = [
        { svcAreaId: item.svcAreaId, svcAreaNm: item.svcAreaNm },
        ...searchHistory,
      ];
    }

    setSearchHistory(updatedHistory);
    localStorage.setItem("searchHistory", JSON.stringify(updatedHistory));

    uiEvent.SetSvcAreaId(item.svcAreaId);

    ApiService.MobilePost("/pw/backend/api/geo/searchGeoPoint", {
      svcAreaId: item.svcAreaId,
    })
      .then((res: any) => {
        const position = new google.maps.LatLng(
          res.data.body.lat,
          res.data.body.lon,
        );
        uiEvent.SetPositionChange(position);
        uiEvent.SetSvcFilterType([]);
        uiEvent.SetSelectedChip("");
        uiEvent.SetFinalPosition({
          lat: res.data.body.lat,
          lng: res.data.body.lon,
        });
      })
      .then(() => {
        uiEvent.SetIsSearchListClick(true);
      })
      .then(() => {
        uiEvent.SetIsClickSearchStation(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [banner, setBanner] = useState<BannerModel[]>([]);
  useEffect(() => {
    getBanners()
      .then((res) => {
        if (res === undefined) {
          console.log("No banner data");
          return;
        } else {
          setBanner(res);
        }
      })
      .catch((e) => {
        console.error("Failed to get banner", e);
      });
  }, []);

  async function getBanners(): Promise<BannerModel[] | undefined> {
    const url = "/pw/ua/contents/banner/random";
    const requestBody = {
      country: getCountryCode(),
    };
    console.log(getCountryCode());

    try {
      const res = await ApiService.post(url, requestBody);
      const data = res?.data.body;
      console.log(`BANNER RANDOM response`, res);
      if (typeof data === "object" && Object.keys(data).length === 0)
        return undefined;
      return Array.isArray(data) ? data : [data];
    } catch (e) {
      throw e;
    }
  }

  function handleLinkAction(link: string | undefined) {
    if (!link) {
      console.log("Link does not exist");
      return;
    }

    if (link.startsWith("notice://")) {
      handleNoticeLink(link);
    } else if (link.startsWith("http")) {
      handleExternalLink(link);
    } else {
      console.error("Invalid link format : ", link);
    }
  }

  function handleNoticeLink(link: string) {
    const noticeId = link.split("notice://")[1];
    if (noticeId) {
      navigate(ROUTE_PATHS.NOTICE_DETAIL, { state: { id: noticeId } });
    } else {
      console.error("Invalid notice link id");
    }
  }

  function handleExternalLink(link: string) {
    if (window.Android) {
      window.Android.openExternalBrowser(link);
    } else if (window.webkit) {
      window.webkit.messageHandlers.openExternalBrowser.postMessage(link);
    } else {
      console.log("Web browser open: ", link);
    }
  }

  function calculateDistance(lat?: number, lon?: number): string | undefined {
    // lat 또는 lon이 undefined인 경우 undefined 반환
    if (
      !uiState.isLocationPermissionGranted ||
      lat === undefined ||
      lon === undefined
    ) {
      return undefined;
    }

    const distanceInKm = haversine(uiState.userLat, uiState.userLng, lat, lon);

    // 1km 이하인 경우 m로 표시, 아니면 km로 표시
    if (distanceInKm <= 1) {
      return `${(distanceInKm * 1000).toFixed(0)}m`; // 미터로 변환
    }

    return `${distanceInKm.toFixed(2)}km`; // 소수점 2자리 고정
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "white",
          width: "100vw",
          height: "100vh",
        }}
      >
        {searchWord.length > 0 ? (
          <>
            {/* 검색어 입력 검색창 */}
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "calc(100% - 40px)",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <VerticalSpacer height={8} />
                <Paper
                  component="form"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    height: "52px",
                    borderRadius: "10px",
                    color: color.gray300,
                    border: 1,
                  }}
                  elevation={0}
                >
                  <div
                    style={{
                      height: "24px",
                      width: "24px",
                      marginLeft: "14px",
                      color: color.gray900,
                    }}
                    onClick={() => {
                      uiEvent.SetIsClickSearchStation(false);
                    }}
                  >
                    <ArrowBackIcon />
                  </div>

                  <InputBase
                    sx={{
                      marginLeft: "8px",
                      marginRight: "8px",
                      height: "52px",
                      flex: 1,
                    }}
                    placeholder=""
                    value={searchWord}
                    onChange={handleSearchWordChange}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault(); // 엔터 키 입력 시에만 기본 동작 차단
                      }
                    }}
                  />
                </Paper>
                <VerticalSpacer height={8} />
                <Box
                  sx={{
                    flexGrow: 1,
                  }}
                >
                  <List
                    sx={{
                      padding: 0,
                    }}
                  >
                    {searchResults?.map((item: any) => (
                      <SearchServiceItem
                        key={item.id}
                        serviceArea={item.svcAreaNm || "-"}
                        address={item.province ?? ""}
                        distance={calculateDistance(
                          item.geoPoint.lat,
                          item.geoPoint.lon,
                        )}
                        onClick={() => handleSearchResultClick(item)}
                      />
                    ))}
                  </List>
                </Box>
              </Box>

              {searchResults.length == 0 && (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    boxSizing: "border-box",
                    px: "20px",
                  }}
                >
                  {EmptyText(
                    translateWord.ua_c200_noresults,
                    translateWord.ua_c200_wordcorrectly,
                  )}
                </Box>
              )}
            </Box>
          </>
        ) : (
          <>
            {/* 검색창 */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "calc(100% - 40px)",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <VerticalSpacer height={8} />
                <Paper
                  component="form"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    height: "52px",
                    borderRadius: "10px",
                    color: color.gray300,
                    border: 1,
                  }}
                  elevation={0}
                >
                  <div
                    style={{
                      height: "24px",
                      width: "24px",
                      marginLeft: "14px",
                      color: color.gray900,
                    }}
                    onClick={() => {
                      uiEvent.SetIsClickSearchStation(false);
                    }}
                  >
                    <ArrowBackIcon />
                  </div>

                  <InputBase
                    sx={{
                      marginLeft: "8px",
                      marginRight: "8px",
                      flex: 1,
                    }}
                    placeholder={translateWord.ua_c200_searchstation}
                    value={searchWord}
                    onChange={handleSearchWordChange}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault(); // 엔터 키 입력 시에만 기본 동작 차단
                      }
                    }}
                  />
                </Paper>
                {banner ? (
                  <Box
                    sx={{
                      mt: "12px",
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  >
                    <SwiperPhotos banners={banner} />
                  </Box>
                ) : null}

                <VerticalSpacer height={16} />
              </Box>
            </Box>

            {/* 최근 검색어 목록 */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                alignItems: "center",
                boxSizing: "border-box",
              }}
            >
              <VerticalSpacer height={8} />
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  boxSizing: "border-box",
                  // backgroundColor: "red",
                }}
              >
                {searchHistory.length == 0 ? (
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {EmptyText(translateWord.ua_c200_nohistory, "")}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      boxSizing: "border-box",
                      px: "20px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        height: "22px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text
                        sx={{
                          fontStyle: fontStyle.subTitleM,
                          color: color.gray800,
                        }}
                        text={translateWord.ua_c200_recentresearch}
                      />

                      <Text
                        sx={{
                          fontStyle: fontStyle.subTitleM,
                          color: color.gray600,
                        }}
                        onClick={() => {
                          setSearchHistory([]);
                          localStorage.removeItem("searchHistory");
                        }}
                        text={translateWord.ua_c200_deleteall}
                      />
                    </Box>
                    <List
                      sx={{
                        padding: 0,
                      }}
                    >
                      {searchHistory?.map((item: any) => (
                        <RecentSearchItem
                          key={item.id}
                          title={item.svcAreaNm}
                          onClick={() => {
                            handleSearchResultClick(item);
                          }}
                          onDelete={() => {
                            handleDeleteHistory(item.svcAreaId);
                          }}
                        />
                      ))}
                    </List>
                  </Box>
                )}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

const EmptyText = (title: string, subTitle: string) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        boxSizing: "border-box",
      }}
    >
      <Typography
        sx={{
          fontStyle: fontStyle.subTitleM,
          color: color.gray800,
        }}
      >
        {title}
      </Typography>
      <VerticalSpacer height={4} />
      <Typography
        sx={{
          fontStyle: fontStyle.bodyMr,
          color: color.gray600,
          // whiteSpace: "pre-line",
        }}
      >
        {subTitle}
      </Typography>
    </Box>
  );
};

export default SearchStationPage;
