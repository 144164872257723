import { Box, Typography } from "@mui/material";
import { color } from "../../../theme/Color";
import { fontStyle } from "../../../theme/Style";
import MembershipInfo, {
  MembershipStatus,
} from "../../MobileChargingMembership/model/MembershipInfo";
import { ICON_RIGHT_ARROW_GRAY600 } from "../../../constants/appImagePath";
import LinearProgressBar from "../../../component/LinearProgressBar";
import VerticalSpacer from "../../../component/Spacer";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";

interface MileageCardProps {
  membershipItem?: MembershipInfo;
  status?: MembershipStatus;
  onClick?: () => void;
}

/* TODO
  MEMBERSHIP Status에 따라 UI 변경
    1. Membership 없는 경우
    2. 유효한 Membership 있는 경우
    3. 만료된 Membership 있는 경우
*/
const MileageCard = ({ membershipItem, status, onClick }: MileageCardProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        bgcolor: "white",
        borderRadius: "10px",
        width: "100%",
        height: "auto",
        p: "16px",
        boxSizing: "border-box",
      }}
    >
      {/* Membership */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          borderBottom: `1px solid ${color.gray200}`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "8px",
          }}
        >
          <Typography
            fontStyle={fontStyle.titleM}
            color={color.default}
            sx={{ mb: "8px" }}
          >
            {LocalizedText("common_membership")}
          </Typography>
          <img
            src={ICON_RIGHT_ARROW_GRAY600}
            alt="Arrow"
            style={{
              width: "22px",
              height: "22px",
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            mb: "6px",
          }}
        >
          <Typography fontStyle={fontStyle.titleXL} color={color.default}>
            972.3 km
          </Typography>
          <Typography fontStyle={fontStyle.subTitleS} color={color.gray600}>
            1,700 km
          </Typography>
        </Box>
        <LinearProgressBar
          value={57}
          height={8}
          borderRadius={8}
          backgroundColor={color.gray200}
          barColor={color.primary500}
        />
        <VerticalSpacer height={8} />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            mb: "16px",
          }}
        >
          <Typography fontStyle={fontStyle.subTitleS} color={color.primary500}>
            Premium
          </Typography>
          <Typography fontStyle={fontStyle.bodySr} color={color.gray700}>
            15 May 2024 ~ 14 May 2025
          </Typography>
        </Box>
      </Box>

      <VerticalSpacer height={16} />

      {/* Voucher */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography fontStyle={fontStyle.titleM} color={color.default}>
          {LocalizedText("common_voucher")}
        </Typography>
        <Typography fontStyle={fontStyle.titleL} color={color.default}>
          60 km
        </Typography>
      </Box>
    </Box>
  );
};

export default MileageCard;
