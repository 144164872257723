export class MoreProfileEditPageUiState {
  isShowAlertDialog: boolean = false;
  isShownPhoneNumberVerificationDialog: boolean = false;

  isDoEditCompleteDialog: boolean = false;
  isEditCompleteDialog: boolean = false;

  name: string = "";
  email: string = "";
  localNumber: string = "";
  phoneNumber: string = "";
  day: string = "";
  month: string = "";
  year: string = "";
  gender: string = "";
  country: string = "";
  cityCountyDistrict: string = "";
  cityDetail: string = "";

  localNumberError: string = "";
  phoneNumberError: string = "";
  dayError: string = "";
  monthError: string = "";
  yearError: string = "";
  genderError: string = "";
  countryError: string = "";
  cityCountyDistrictError: string = "";
  cityDetailError: string = "";
  tribeCategory: string = "";
  tribeName: string = "";
}
