import { Box } from "@mui/material";
import {
  ICON_X_GRAY600,
  IMAGE_SEARCH_PURPLE,
} from "../../../../constants/appImagePath";
import { HorizontalSpacer } from "../../../../component/Spacer";
import { Text } from "../../../../component/Text";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";

interface RecentSearchItemProps {
  title: string;
  onClick: () => void;
  onDelete: () => void;
}

export function RecentSearchItem({
  title,
  onClick,
  onDelete,
}: RecentSearchItemProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        height: "52px",
        width: "100%",
        alignItems: "center",
      }}
      onClick={onClick}
    >
      <img
        style={{
          width: "28px",
          height: "28px",
        }}
        src={IMAGE_SEARCH_PURPLE}
        alt={"pin"}
      />
      <HorizontalSpacer width={12} />
      <Text
        sx={{
          fontStyle: fontStyle.bodyLr,
          color: color.default,
          flexGrow: 1,
        }}
        text={title}
      />
      <HorizontalSpacer width={12} />
      <img
        style={{
          width: "24px",
          height: "24px",
        }}
        src={ICON_X_GRAY600}
        alt={"delete"}
        onClick={(event) => {
          event.stopPropagation();
          onDelete();
        }}
      />
    </Box>
  );
}
