import { Box, Card, CardContent, Typography } from "@mui/material";
import CommonList from "../../../../../component/CommonList";
import { color } from "../../../../../theme/Color";
import { fontStyle } from "../../../../../theme/Style";
import VerticalSpacer, {
  HorizontalSpacer,
} from "../../../../../component/Spacer";
import CommonTextField from "../../../../../component/CommonTextField";
import { ICON_ADDIMAGE } from "../../../../../constants/imagePath";
import { ProductNameDetailUiState } from "./model/ProductNameDetailUiState";
import { ProductNameDetailUiEvent } from "./model/ProductNameDetailUiEvent";
import { CommonWebHeaderButton } from "../../../../../component/CommonWebHeaderButton";
import CommonDialog from "../../../../../component/CommonDialog";
import { ADMIN_CONFIG } from "../../../../../constants/AdminConfig";

interface ProductNameDetailPageProps {
  uiState: ProductNameDetailUiState;
  uiEvent: ProductNameDetailUiEvent;
}

export function ProductNameDetailPage({
  uiState,
  uiEvent,
}: ProductNameDetailPageProps) {
  return (
    <CommonList
      title="Product Name Details"
      headerBackButton
      backbuttonOnclick={uiEvent.backButtonClick}
      headerButton={
        <CommonWebHeaderButton
          buttonContent="Update"
          sx={{ display: "flex", flexDirection: "column" }}
          onClick={uiEvent.updateButtonClick}
        />
      }
    >
      <Card
        sx={{
          padding: "0px",
          border: "1px solid",
          borderColor: color.gray300,
          borderRadius: "15px",
          boxShadow: "unset",
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            "&:last-child": {
              paddingBottom: "20px",
            },
          }}
        >
          <Typography fontStyle={fontStyle.semiboldL}>Product</Typography>
          <VerticalSpacer height={16} />
          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "50%" }}>
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Model Name
              </Typography>
              <VerticalSpacer height={8} />
              <CommonTextField
                sx={{ width: "100%" }}
                value={uiState.modelName}
                onChange={(event) => {
                  uiEvent.HandleUiStateChange("modelName", event.target.value);
                }}
              />
              <VerticalSpacer height={40} />
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Product Name
              </Typography>
              <VerticalSpacer height={8} />
              <Box
                sx={{
                  width: "100%",
                  height: "48px",
                  padding: "12px 16px",
                  borderRadius: "10px",
                  border: "1px solid",
                  borderColor: color.gray300,
                  backgroundColor: color.gray200,
                }}
              >
                <Typography
                  fontStyle={fontStyle.regularS}
                  color={color.gray600}
                >
                  {uiState.productName}
                </Typography>
              </Box>
              <VerticalSpacer height={40} />
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Parts Code
              </Typography>
              <VerticalSpacer height={8} />
              <Box
                sx={{
                  width: "100%",
                  height: "48px",
                  padding: "12px 16px",
                  borderRadius: "10px",
                  border: "1px solid",
                  borderColor: color.gray300,
                  backgroundColor: color.gray200,
                }}
              >
                <Typography
                  fontStyle={fontStyle.regularS}
                  color={color.gray600}
                >
                  {uiState.partsCode}
                </Typography>
              </Box>
              <VerticalSpacer height={40} />
            </Box>
            <HorizontalSpacer width={28} />
            <Box sx={{ width: "50%" }}>
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Image
              </Typography>
              <VerticalSpacer height={8} />
              {uiState.image === "" ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: color.gray200,
                    borderRadius: "10px",
                    width: "100%",
                    height: "324px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component="img"
                    src={ICON_ADDIMAGE}
                    sx={{
                      width: "40px", // 작은 아이콘 크기
                      height: "40px",
                    }}
                  />
                  <Box>
                    <VerticalSpacer height={16} />
                    <Typography
                      fontStyle={fontStyle.semiboldM}
                      color={color.gray600}
                    >
                      No Image
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <Box
                  component="img"
                  src={uiState.image}
                  sx={{
                    borderRadius: "10px",
                    width: "100%",
                    height: "324px",
                  }}
                ></Box>
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>
      <CommonDialog
        open={uiState.backDialogOpen}
        handleClose={uiEvent.backClose}
        handleOk={uiEvent.backOk}
        textSx={{ fontStyle: fontStyle.regularS, color: color.gray800 }}
      >
        {ADMIN_CONFIG.MSG.EDIT_STOP_AND_MOVE}
        <br />
        {ADMIN_CONFIG.MSG.EDIT_STOP_NOT_SAVED}
      </CommonDialog>
      <CommonDialog
        open={uiState.updateDialogOpen}
        handleClose={uiEvent.updateClose}
        handleOk={uiEvent.updateOk}
        textSx={{ fontStyle: fontStyle.regularS, color: color.gray800 }}
      >
        Would you like to update the product name?
      </CommonDialog>
    </CommonList>
  );
}
