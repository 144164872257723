import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { RegionDetailUiState } from "./model/RegionDetailUiState";
import { RegionDetailUiEvent } from "./model/RegionDetailUiEvent";
import RegionDetailRender from "./RegionDetailRender";
import { ApiService } from "../../../../restAPI/ApiService";
import CommonList from "../../../../component/CommonList";
import CommonDialog from "../../../../component/CommonDialog";
import { ADMIN_CONFIG } from "../../../../constants/AdminConfig";

const RegionDetailPage = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOk = () => {
    navigate("/regionAndServiceManagement");
    window.location.reload();
  };
  const [uiState, setUiState] = useState(new RegionDetailUiState());
  const uiEvent: RegionDetailUiEvent = {
    HandleUiStateChange: (key: keyof RegionDetailUiState, value: any) => {
      setUiState((prevState) => ({
        ...prevState,
        [key]: value, // key에 해당하는 상태를 업데이트
      }));
    },
  };
  const location = useLocation();
  const pathname = location.pathname;
  const regionSvcAreaId = pathname.split("/").pop(); // URL에서 마지막 부분을 추출

  useEffect(() => {
    ApiService.WebPost("/pw/backend/api/geo/detail", {
      svcAreaId: regionSvcAreaId,
    }).then((res) => {
      console.log(res?.data);
      try{
        uiEvent.HandleUiStateChange("Name", res?.data.body.svcAreaNm);
        uiEvent.HandleUiStateChange("Country", res?.data.body.ctryCd);
        uiEvent.HandleUiStateChange("StateProvinceRegion", res?.data.body.province);
        uiEvent.HandleUiStateChange("Location", res?.data.body.location);
        uiEvent.HandleUiStateChange("Latitude", res?.data.body.geoPoint.lat);
        uiEvent.HandleUiStateChange("Longitude", res?.data.body.geoPoint.lon);
      }catch(e){
        handleOk();
      }
    });
  }, []);

  const HeaderButton = () => {
    const handleCreate = () => {
      // 각 필드에 대한 오류 여부를 계산
      const updatedErrors = {
        NameError: uiState.Name === "",
        CountryError: uiState.Country === "Country",
        StateProvinceRegionError: uiState.StateProvinceRegion === "",
        CityError: uiState.City === "",
        LatitudeError: uiState.Latitude === 0,
        LongitudeError: uiState.Longitude === 0,
      };
      // 오류 상태를 uiState에 반영
      Object.entries(updatedErrors).forEach(([key, value]) => {
        uiEvent.HandleUiStateChange(key as keyof RegionDetailUiState, value);
      });
      // 오류가 없으면 콘솔 로그 출력
      if (!Object.values(updatedErrors).includes(true)) {
        const updateData = {
          ctryCd: uiState.Country,
          province: uiState.StateProvinceRegion,
          svcAreaId: regionSvcAreaId,
          location: uiState.Location,
          geoPoint: {
            lon: uiState.Longitude,
            lat: uiState.Latitude,
          },
          svcAreaNm: uiState.Name,
        };
        console.log(updateData);
        ApiService.WebPost("/pw/backend/api/geo/update", updateData).then(
          () => {
            window.location.reload();
          }
        );
      }
    };

    return (
      <Button
        sx={{
          backgroundColor: "#5D38E5",
          borderRadius: 5,
          ":hover": {
            backgroundColor: "#A38BFC",
          },
          color: "white",
          width: 110,
          height: 40,
        }}
        onClick={handleCreate}
      >
        Update
      </Button>
    );
  };

  const HeaderBackButton = () => {
    return (
      <IconButton
        sx={{
          ":hover": { backgroundColor: "#F2F2F2" },
          mb: 0.5,
          mr: 1,
        }}
        onClick={() => {
          handleClickOpen();
        }}
      >
        <ArrowBackIosNewIcon fontSize="medium" />
      </IconButton>
    );
  };
  return (
    <CommonList
      title="Area Registration"
      headerButton={HeaderButton()}
      headerBackButton={HeaderBackButton()}
    >
      <CommonDialog open={open} handleClose={handleClose} handleOk={handleOk}>
        {ADMIN_CONFIG.MSG.EDIT_STOP_AND_MOVE}<br/>
        {ADMIN_CONFIG.MSG.EDIT_STOP_NOT_SAVED}
      </CommonDialog>
      <RegionDetailRender uiState={uiState} uiEvent={uiEvent} />
    </CommonList>
  );
};

export default RegionDetailPage;
