export class UserWalletUiState {
  nftInfo: NFTInfo[];
  vcInfo: VCInfo[];
  issuedHistory: IssuedHistory[];
  issuedHistoryModal: boolean;
  constructor(
    nftInfo: NFTInfo[] = [
      {
        image: "https://via.placeholder.com/150",
        nftType: "Art",
        nftName: "Sunset in Paris",
        nftId: "nft001",
        issuer: "ArtCollective",
        issued: 1735869689000, // 오늘의 타임스탬프
        received: 1735869689000, // 오늘의 타임스탬프
        symbol: "ART",
      },
      {
        image: "https://via.placeholder.com/150",
        nftType: "Music",
        nftName: "Ocean Breeze",
        nftId: "nft002",
        issuer: "SoundWave",
        issued: 1735869689000, // 오늘의 타임스탬프
        received: 1735869689000, // 오늘의 타임스탬프
        symbol: "MUSIC",
      },
      {
        image: "https://via.placeholder.com/150",
        nftType: "Game",
        nftName: "Dragon Sword",
        nftId: "nft003",
        issuer: "GameWorld",
        issued: 1735869689000, // 오늘의 타임스탬프
        received: 1735869689000, // 오늘의 타임스탬프
        symbol: "GAME",
      },
      {
        image: "https://via.placeholder.com/150",
        nftType: "Sports",
        nftName: "Soccer Legend",
        nftId: "nft004",
        issuer: "SportsStars",
        issued: 1735869689000, // 오늘의 타임스탬프
        received: 1735869689000, // 오늘의 타임스탬프
        symbol: "SPORT",
      },
      {
        image: "https://via.placeholder.com/150",
        nftType: "Collectible",
        nftName: "Vintage Car",
        nftId: "nft005",
        issuer: "CarCollectors",
        issued: 1735869689000, // 오늘의 타임스탬프
        received: 1735869689000, // 오늘의 타임스탬프
        symbol: "COLLECT",
      },
    ],
    vcInfo: VCInfo[] = [
      {
        image: "https://via.placeholder.com/150", // 이미지 URL
        certificate: "Blockchain Certification",
        status: "Disposal",
        issuer: "OpenAI",
        issued: 1672531200000, // 2023-01-01 (Unix Timestamp in seconds)
        expried: 1704067200000, // 2024-01-01 (Unix Timestamp in seconds)
        vcId: "vc_1234567890",
      },
      {
        image: "https://via.placeholder.com/150", // 이미지 URL
        certificate: "AI Research Specialist",
        status: "",
        issuer: "DeepMind",
        issued: 1669852800000, // 2022-12-01 (Unix Timestamp in seconds)
        expried: 1701388800000, // 2023-12-01 (Unix Timestamp in seconds)
        vcId: "vc_0987654321",
      },
    ],
    issuedHistory: IssuedHistory[] = [
      {
        text1: "Test",
        text2: "text",
        date: 1701388800000,
      },
      {
        text1: "Test2",
        text2: "text2",
        date: 1701388800000,
      },
      {
        text1: "Test3",
        text2: "text3",
        date: 1701388800000,
      },
      {
        text1: "Test4",
        text2: "text4",
        date: 1701388800000,
      },
      {
        text1: "Test5",
        text2: "text5",
        date: 1701388800000,
      },
      {
        text1: "Test6",
        text2: "text6",
        date: 1701388800000,
      },
      {
        text1: "Test7",
        text2: "text7",
        date: 1701388800000,
      },
    ],
    issuedHistoryModal: boolean = false,
  ) {
    this.nftInfo = nftInfo;
    this.vcInfo = vcInfo;
    this.issuedHistory = issuedHistory;
    this.issuedHistoryModal = issuedHistoryModal;
  }
}

export interface NFTInfo {
  image: string;
  nftType: string;
  nftName: string;
  nftId: string;
  issuer: string;
  issued: number;
  received: number;
  symbol: string;
}

export interface VCInfo {
  image: string;
  certificate: string;
  status: string;
  issuer: string;
  issued: number;
  expried: number;
  vcId: string;
}

export interface IssuedHistory {
  text1: string;
  text2: string;
  date: number;
}