import { useEffect, useState } from "react";
import { NFTUiState } from "./model/NFTUiState";
import { NFTPage } from "./NFTPage";
import { NFTUiEvent } from "./model/NFTUiEvent";
import { ApiService } from "../../../restAPI/ApiService";
import { NFTMapper } from "./mapper/NFTMapper";
import { NFTResponse } from "./model/NFTResponse";

export function NFT() {
  const [uiState, setUiState] = useState(new NFTUiState());

  const uiEvent: NFTUiEvent = {
    handleUiStateChange: (key: keyof NFTUiState, value: any) => {
      setUiState((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    },
  };

  async function getNFTList(

  ): Promise<NFTResponse | undefined> {
    const url = "";
    const requestBody = {};

    try {
      const res = await ApiService.webPost(url, requestBody);
      const data = res.data;
      if (typeof data === "object" && Object.keys(data).length === 0)
        return undefined;
      return data;
    } catch (e) {
      setUiState((prev) => ({
        ...prev,
        page: 0,
        pageCount: 1,
      }));
      return undefined;
    }
  }

  useEffect(() => {
    const fetchNFTList = async () => {
      try {
        const nftInfo = await getNFTList();
        if (nftInfo === undefined) {
          console.log("NFT not found");
          return;
        }
        setUiState(NFTMapper(nftInfo));
      } catch (e) {
        console.log("NFT not found1");
      }
    };
    fetchNFTList();
  }, []);

  return <NFTPage uiState={uiState} uiEvent={uiEvent} />;
}
