import { Box, Card } from "@mui/material";
import { color } from "../../../../theme/Color";
import VerticalSpacer from "../../../../component/Spacer";
import { CommonWebHeaderButton } from "../../../../component/CommonWebHeaderButton";
import { RegisterFindParts } from "./RegisterFindParts";
import { RegisterBillOfMaterialTree } from "./RegisterBillOfMaterialTree";
import { FindPartListUiEvent } from "./model/FindPartListUiEvent";
import { FindPartListUiState } from "./model/FindPartListUiState";
import { useEffect, useState } from "react";
interface FindPartListProps {
  onSubmit?: (data: any) => void; // 부모에게 전달할 데이터의 타입 지정
}
const FindPartList = ({ onSubmit }: FindPartListProps) => {
  const [uiState, setUiState] = useState(new FindPartListUiState());
  const uiEvent: FindPartListUiEvent = {
    HandleUiStateChange: (key: keyof FindPartListUiState, value: any) => {
      setUiState((prevState) => ({
        ...prevState,
        [key]: value, // key에 해당하는 상태를 업데이트
      }));
    },
  };
  useEffect(() => {
    if (onSubmit) {
      onSubmit(uiState);
    }
  }, [uiState]);
  return (
    <>
      <Card
        sx={{
          borderRadius: "15px",
          border: "1px solid",
          borderColor: color.gray300,
          padding: "24px",
          boxShadow: 0,
        }}
      >
        <RegisterFindParts />
        <VerticalSpacer height={24} />
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CommonWebHeaderButton buttonContent="Add to List" addIcon height="36px" iconFontSize="18px" />
        </Box>
        <VerticalSpacer height={40} />
        <RegisterBillOfMaterialTree />
      </Card>
    </>
  );
};

export default FindPartList;
