import React from "react";
import { ToastModel, ToastType } from "./ToastModel";
import { color } from "../../theme/Color";
import { Box, Typography } from "@mui/material";
import { hexToRGBA } from "../../utils/ColorUtil";
import { fontStyle } from "../../theme/Style";

interface ToastProps {
  toast: ToastModel;
}

const Toast: React.FC<ToastProps> = ({ toast }) => {
  const getBackgroundColor = () => {
    switch (toast.type) {
      case ToastType.success:
        return color.backgroundSuccess;
      case ToastType.error:
        return color.backgroundError;
      case ToastType.warning:
        return color.backgroundWarning;
      default:
        return color.backgroundProgress;
    }
  };

  const borderAndTextColor = () => {
    switch (toast.type) {
      case ToastType.success:
        return color.success;
      case ToastType.error:
        return color.error;
      case ToastType.warning:
        return color.warning;
      default:
        return color.toolingBlue;
    }
  };

  return (
    <Box
      sx={{
        mt: "12px",
        width: "300px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        borderRadius: "6px",
        border: `1px solid ${borderAndTextColor()}`,
        backgroundColor: hexToRGBA(getBackgroundColor(), 0.95),
        zIndex: 9999,
      }}
      className="toast"
    >
      <Typography
        sx={{
          padding: "8px 16px",
          color: borderAndTextColor(),
          fontStyle: fontStyle.bodySr,
        }}
      >
        {toast.message}
      </Typography>
    </Box>
  );
};

export default Toast;
