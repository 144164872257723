import { useNavigate } from "react-router-dom";
import CommonTable, { useVisibleRows } from "../../../component/CommonTable";
import { Box, TableCell, TableRow, Typography } from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import LocalizedDate, { DateFormatType } from "../../../component/LocalizedDate";
import { ApiService } from "../../../restAPI/ApiService";
import { MultilingualUiState } from "../model/MultilingualUiState";
import { MultilingualUiEvent } from "../model/MultilingualUiEvent";

interface RowData {
  row: any;
  uiState: MultilingualUiState;
  uiEvent: MultilingualUiEvent;
}

const Row = ({ row, uiState, uiEvent }: RowData) => {
  return (
    <>
      <TableRow
        sx={{ cursor: "pointer" }}
        onClick={() => {
          //   navigate(`/inquiryMng/inquiryDetails/${row.id}`);
          ApiService.WebPost("/pw/aw/system/multilingual/detail", {
            id: row.id,
          }).then((res) => {
            const data = res?.data.body;
            uiEvent.HandleUiStateChange("ID", data.id);
            uiEvent.HandleUiStateChange("DetailDialogOpen", true);
            uiEvent.HandleUiStateChange("Code", data.code);
            uiEvent.HandleUiStateChange("Description", data.description);
            uiEvent.HandleUiStateChange("Language", data.language);
            uiEvent.HandleUiStateChange("State", data.state);
            uiEvent.HandleUiStateChange("Translation", data.translate);
            uiEvent.HandleUiStateChange("Type", data.type);
          });
        }}
      >
        <TableCell align="center" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.no}</Typography>
        </TableCell>
        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.language}</Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.code}</Typography>
        </TableCell>
        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.type}</Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0, maxWidth: "400px" }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.translate}</Typography>
        </TableCell>
        {/* <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.state}</Typography>
        </TableCell> */}

        <TableCell align="left" sx={{ height: "52px", py: 0, minWidth: "140px", maxWidth: "140px" }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {LocalizedDate({
              date: row.registrationDate,
              type: DateFormatType.AdminType,
            })}
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
};

interface MultilingualTableProps {
  mngRows?: [];
  rowsPerPage?: any;
  setRowsPerPage?: any;
  page?: any;
  pageCount?: any;
  setPage?: any;
  docCount?: number;
  uiState: MultilingualUiState;
  uiEvent: MultilingualUiEvent;
}

const MultilingualTable = ({
  mngRows,
  rowsPerPage,
  setRowsPerPage,
  docCount,
  page,
  pageCount,
  setPage,
  uiState,
  uiEvent,
}: MultilingualTableProps) => {
  const headCells = [
    {
      id: "no",
      align: "center",
      disablePadding: false,
      label: "No",
    },

    {
      id: "language",
      align: "left",
      disablePadding: false,
      label: "Language",
    },
    {
      id: "code",
      align: "left",
      disablePadding: false,
      label: "Code",
    },
    {
      id: "type",
      align: "left",
      disablePadding: false,
      label: "Type",
    },

    {
      id: "translate",
      align: "left",
      disablePadding: false,
      label: "Translate",
    },

    // {
    //   id: "state",
    //   align: "left",
    //   disablePadding: false,
    //   label: "State",
    // },
    {
      id: "registrationDate",
      align: "left",
      disablePadding: false,
      label: "Register Date",
    },
  ];

  const { order, orderBy, handleRequestSort, handleChangePage, visibleRows } = useVisibleRows(mngRows || [], rowsPerPage, setPage, page);
  return (
    <CommonTable
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      page={page}
      docCount={docCount}
      pageCount={pageCount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      handleRequestSort={handleRequestSort}
      handleChangePage={handleChangePage}
      setPage={setPage}
    >
      {visibleRows?.length == 0 ? (
        <TableRow sx={{ px: " 4px", height: "1px" }}>
          <TableCell
            colSpan={headCells?.length}
            align="center"
            sx={{
              minWidth: "1032px",
              px: "13px",
              py: "12px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontStyle: fontStyle.regularS,
                color: color.gray600,
              }}
            >
              No Data
            </Typography>
          </TableCell>
        </TableRow>
      ) : (
        visibleRows.map((row: { [x: string]: string | number }, index: any) => (
          <Row row={row as unknown as RowData} key={index} uiState={uiState} uiEvent={uiEvent} />
        ))
      )}
    </CommonTable>
  );
};

export default MultilingualTable;
