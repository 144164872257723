export class RegionMngCreateUiState {
  Name: string = "";
  Country: string = "Country";
  StateProvinceRegion: string = "";
  City: string = "";
  Location: string = "";
  Latitude: number = 0;
  Longitude: number = 0;

  NameError: boolean = false;
  CountryError: boolean = false;
  StateProvinceRegionError: boolean = false;
  LocationError: boolean = false;
  LatitudeError: boolean = false;
  LongitudeError: boolean = false;
}
