import { Box, Button, Card, CardHeader, Grid, IconButton, InputAdornment, Typography } from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import { ICON_EDIT, ICON_EDIT_BLUE, ICON_TRASH } from "../../../constants/imagePath";
import VerticalSpacer, { HorizontalSpacer } from "../../../component/Spacer";
import { CommonSelect } from "../../../component/CommonSelect";
import { color } from "../../../theme/Color";
import CommonTextField from "../../../component/CommonTextField";
import CommonDatePicker from "../../../component/CommonDatePicker";
import dayjs, { Dayjs } from "dayjs";
import LocalizedDate, { DateFormatType } from "../../../component/LocalizedDate";
import { MembershipVoucherUiEvent } from "../model/MembershipVoucherUiEvent";
import { MembershipVoucherUiState } from "../model/MembershipVoucherUiState";
import { useEffect, useState } from "react";
import { ApiService } from "../../../restAPI/ApiService";
import ProgressBar from "../../../component/ProgressBar";
import CommonDialog from "../../../component/CommonDialog";

interface MembershipVoucherCardProps {
  data?: any;
  countryLists: any;
  uiState: MembershipVoucherUiState;
  uiEvent: MembershipVoucherUiEvent;
}

const MembershipVoucherCard = ({ data, countryLists, uiState, uiEvent }: MembershipVoucherCardProps) => {
  const [VMCardData, setVMCardData] = useState<any>(data);
  const [invalidVMCardData, setInvalidVMCardData] = useState<any>({});
  const [isPostUpdateClick, setIsPostUpdateClick] = useState(false);
  const updateVMCardData = (key: string, value: any) => {
    setVMCardData((prevState: any) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const [deleteDialog, setDeleteDialog] = useState(false);

  const UpdateMVData = () => {
    const newValidation = Object.keys(VMCardData).reduce((acc: any, key: string) => {
      // 'label'을 제외하고 유효성 검사
      if (key !== "label" && key !== "id") {
        acc[key] =
          !VMCardData[key] || // 값이 없거나
          ((key === "country" || key === "type") && VMCardData[key] === "Select") || // country 또는 type이 Select인 경우
          (key === "distributeDate" && dayjs(VMCardData[key]).isBefore(minDate, "day")); // distributeDate가 minDate보다 이전인 경우
      }
      return acc;
    }, {});

    setInvalidVMCardData(newValidation); // validateVMCardData 상태 업데이트
  };

  useEffect(() => {
    // Check if all values are false
    const allValid = Object.values(invalidVMCardData).every((value) => value === false);

    if (isPostUpdateClick) {
      if (allValid) {
        // Your code here to handle the case where all values are false

        // price 값을 float로 변환
        const updatedVMCardData = {
          ...VMCardData,
          price: parseFloat(VMCardData.price), // price 값을 float로 변환
        };

        if (updatedVMCardData.id === "") {
          // id 키를 삭제
          const { id, ...newVMCardData } = updatedVMCardData; // id를 제외한 나머지 데이터를 새로운 객체로 만듦
          // console.log("새로 생성", newVMCardData);

          ApiService.WebPost("/pw/aw/product/MV/create", newVMCardData).then((res) => {
            window.location.reload();
          });
        } else {
          const finalUpdatedVMCardData = {
            ...VMCardData,
            price: parseFloat(VMCardData.price), // price 값을 float로 변환
            uuid: localStorage.getItem("user_uuid"),
          };
          console.log("finalUpdatedVMCardData is : ", finalUpdatedVMCardData);
          ApiService.WebPost("/pw/aw/product/MV/update", finalUpdatedVMCardData).then((res) => {
            window.location.reload();
          });
        }
      }
      setIsPostUpdateClick(false);
    }
  }, [invalidVMCardData]);

  const today: Dayjs = dayjs(); // 현재 날짜와 시간을 today 변수에 저장
  const minDate = today.add(30, "day");
  return (
    <Grid item lg={4}>
      <Card elevation={0} sx={{ borderRadius: "10px" }}>
        {uiState.EditCardId != VMCardData.id && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <CardHeader title={VMCardData.name} titleTypographyProps={{ fontStyle: fontStyle.semiboldM }} />
            <Box sx={{ display: "flex", m: "16px" }}>
              <IconButton
                onClick={() => {
                  setDeleteDialog(true);
                }}
              >
                <Box component={"img"} src={ICON_TRASH} sx={{ height: "20px" }} />
              </IconButton>
              <IconButton
                onClick={() => {
                  uiEvent.HandleUiStateChange("EditCardId", VMCardData.id);
                }}
              >
                <Box component={"img"} src={ICON_EDIT_BLUE} />
              </IconButton>
            </Box>
          </Box>
        )}

        <Box sx={{ m: "24px" }}>
          {/* Country */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography fontStyle={fontStyle.boldXS} color={color.gray600} flex={0.8}>
              Country
            </Typography>
            <Box flex={1.2} sx={{ position: "relative" }}>
              <CommonSelect
                selectValue={VMCardData.country}
                menuitems={countryLists}
                onChange={(event) => {
                  updateVMCardData("country", event.target.value);
                }}
                sx={{
                  height: "40px",
                  fontStyle: fontStyle.regularS,
                  color: "black",
                  width: "100%",
                }}
                disabled={uiState.EditCardId != VMCardData.id}
                error={invalidVMCardData.country}
              />
              {invalidVMCardData.country && (
                <Typography fontStyle={fontStyle.regularXXS} color={color.error} sx={{ position: "absolute" }}>
                  Please select a country.
                </Typography>
              )}
            </Box>
          </Box>

          <VerticalSpacer height={24} />
          {/* Name */}
          {uiState.EditCardId == VMCardData.id && (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600} flex={0.8}>
                  Name
                </Typography>
                <Box flex={1.2} sx={{ position: "relative" }}>
                  <CommonTextField
                    value={VMCardData.name}
                    onChange={(event) => {
                      updateVMCardData("name", event.target.value);
                    }}
                    sx={{ height: "40px" }}
                    InputProps={{
                      sx: {
                        height: "40px",
                      },
                    }}
                    disabled={uiState.EditCardId != VMCardData.id}
                    error={invalidVMCardData.name}
                  />
                  {invalidVMCardData.name && (
                    <Typography fontStyle={fontStyle.regularXXS} color={color.error} sx={{ position: "absolute" }}>
                      Please enter a name.
                    </Typography>
                  )}
                </Box>
              </Box>

              <VerticalSpacer height={24} />
            </>
          )}

          {/* Label */}
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <Typography fontStyle={fontStyle.boldXS} color={color.gray600} flex={0.8} sx={{ mt: "8px" }}>
              Label
            </Typography>
            <Box sx={{ flex: 1.2 }}>
              <CommonTextField
                value={VMCardData?.label ? VMCardData?.label[0] : ""}
                onChange={(event) => {
                  // label[0] 업데이트
                  const updatedLabel = [...VMCardData.label];
                  updatedLabel[0] = event.target.value;
                  updateVMCardData("label", updatedLabel);
                }}
                sx={{}}
                InputProps={{
                  sx: {
                    height: "40px",
                  },
                }}
                disabled={uiState.EditCardId != VMCardData.id}
              />
              <CommonTextField
                value={VMCardData?.label ? VMCardData?.label[1] : ""}
                onChange={(event) => {
                  // label[1] 업데이트
                  const updatedLabel = [...VMCardData.label];
                  updatedLabel[1] = event.target.value;
                  updateVMCardData("label", updatedLabel);
                }}
                multiline
                rows={2}
                sx={{ mt: "8px", width: "100%" }}
                InputProps={{
                  sx: {
                    py: "10px",
                  },
                }}
                disabled={uiState.EditCardId != VMCardData.id}
              />
            </Box>
          </Box>
          <VerticalSpacer height={24} />
          {/* Type */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography fontStyle={fontStyle.boldXS} color={color.gray600} flex={0.8}>
              Type
            </Typography>

            <Box flex={1.2} sx={{ position: "relative" }}>
              <CommonSelect
                selectValue={VMCardData.type}
                menuitems={["Membership", "Voucher"]}
                onChange={(event) => {
                  updateVMCardData("type", event.target.value);
                }}
                sx={{
                  height: "40px",
                  fontStyle: fontStyle.regularS,
                  color: "black",
                  width: "100%",
                }}
                disabled={uiState.EditCardId != VMCardData.id}
                error={invalidVMCardData.type}
              />
              {invalidVMCardData.type && (
                <Typography fontStyle={fontStyle.regularXXS} color={color.error} sx={{ position: "absolute" }}>
                  Please select a type.
                </Typography>
              )}
            </Box>
          </Box>
          <VerticalSpacer height={24} />
          {/* Price */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography fontStyle={fontStyle.boldXS} color={color.gray600} flex={0.8}>
              Price
            </Typography>
            <Box flex={1.2} sx={{ position: "relative" }}>
              <CommonTextField
                value={VMCardData.price}
                onChange={(event) => {
                  const inputValue = event.target.value;
                  // 숫자와 소수점만 허용하도록 정규식 사용
                  if (/^\d*\.?\d*$/.test(inputValue)) {
                    updateVMCardData("price", inputValue);
                  }
                }}
                sx={{ height: "40px", flex: 1.2 }}
                InputProps={{
                  sx: {
                    height: "40px",
                    zIndex: 200,
                  },
                  startAdornment: (
                    <InputAdornment position="start" sx={{ zIndex: 200 }}>
                      <Typography sx={{ color: color.gray600 }}>$</Typography>
                    </InputAdornment>
                  ),
                }}
                disabled={uiState.EditCardId != VMCardData.id}
                error={invalidVMCardData.price}
              />
              {invalidVMCardData.price && (
                <Typography fontStyle={fontStyle.regularXXS} color={color.error} sx={{ position: "absolute" }}>
                  Please enter price.
                </Typography>
              )}
            </Box>
          </Box>
          <VerticalSpacer height={24} />
          {/* Description */}
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <Typography fontStyle={fontStyle.boldXS} color={color.gray600} flex={0.8} sx={{ mt: "8px" }}>
              Description
            </Typography>
            <Box flex={1.2} sx={{ position: "relative" }}>
              <CommonTextField
                value={VMCardData.description}
                onChange={(event) => {
                  updateVMCardData("description", event.target.value);
                }}
                multiline
                rows={2}
                sx={{ display: "flex" }}
                InputProps={{
                  sx: {
                    // height: "48px",

                    py: "10px",
                  },
                }}
                disabled={uiState.EditCardId != VMCardData.id}
                error={invalidVMCardData.description}
              />
              {invalidVMCardData.description && (
                <Typography fontStyle={fontStyle.regularXXS} color={color.error} sx={{ position: "absolute" }}>
                  Please enter a description.
                </Typography>
              )}
            </Box>
          </Box>
          <VerticalSpacer height={24} />
          {/* Distribute Date */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography fontStyle={fontStyle.boldXS} color={color.gray600} flex={0.8}>
              Distribute Date
            </Typography>
            <Box flex={1.2} sx={{ position: "relative" }}>
              <CommonDatePicker
                value={dayjs(VMCardData.distributeDate)}
                minDate={minDate}
                // sx={{ flex: 1.2 }}
                onChange={(newDate: Dayjs | null) => {
                  updateVMCardData("distributeDate", newDate?.valueOf());
                }}
                InputPropsSx={{ height: "40px" }}
                textFieldSx={{ width: "100%", flex: 1.2 }}
                disabled={uiState.EditCardId != VMCardData.id}
              />
              {invalidVMCardData.distributeDate && (
                <Typography fontStyle={fontStyle.regularXXS} color={color.error} sx={{ position: "absolute" }}>
                  A distribute date can be set for 30 days ahead.
                </Typography>
              )}
            </Box>
          </Box>
          <VerticalSpacer height={24} />
          {/* Regist Date & Last Update Date */}
          {uiState.EditCardId != VMCardData.id && (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600} flex={0.8}>
                  Regist Date
                </Typography>
                <Typography fontStyle={fontStyle.regularXS}>
                  {LocalizedDate({
                    date: VMCardData.registrationDate,
                    type: DateFormatType.AdminType,
                  })}
                </Typography>
              </Box>
              <VerticalSpacer height={16} />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600} flex={0.8}>
                  Last Update Date
                </Typography>
                <Typography fontStyle={fontStyle.regularXS}>
                  {LocalizedDate({
                    date: VMCardData.lastUpdateDate,
                    type: DateFormatType.AdminType,
                  })}
                </Typography>
              </Box>
            </>
          )}
          <VerticalSpacer height={16} />
          {uiState.EditCardId == VMCardData.id && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                sx={{
                  // height: 40,
                  color: color.gray600,
                  ":hover": {
                    backgroundColor: "white",
                    color: color.gray400,
                  },
                  textTransform: "none",
                }}
                onClick={() => {
                  uiEvent.HandleUiStateChange("EditCardId", "");

                  if (VMCardData.id == "") {
                    const filteredData = uiState.MVDataRows.filter((data) => data.id !== VMCardData.id);
                    uiEvent.HandleUiStateChange("MVDataRows", filteredData);
                  }
                }}
                disableRipple
              >
                Cancel
              </Button>
              <Button
                sx={{
                  height: 40,
                  backgroundColor: "#5D38E5",
                  color: "white",
                  borderRadius: 5,
                  width: 110,
                  ":hover": {
                    backgroundColor: "#A38BFC",
                  },
                  textTransform: "none",
                }}
                onClick={() => {
                  setIsPostUpdateClick(true);
                  UpdateMVData();
                }}
              >
                {VMCardData.id == "" ? "Post" : "Update"}
              </Button>
            </Box>
          )}
        </Box>
      </Card>
      <CommonDialog
        textSx={{ fontStyle: fontStyle.regularS, color: color.gray800 }}
        open={deleteDialog}
        handleOk={() => {
          ApiService.WebDelete("/pw/aw/product/MV/delete", {
            id: VMCardData.id,
            uuid: localStorage.getItem("user_uuid"),
          }).then((res) => {
            console.log(res?.data);
            window.location.reload();
          });
        }}
        handleClose={() => {
          setDeleteDialog(false);
        }}
      >
        Would you like to delete the Membership or Voucher?
        <br />
        It cannot be restored afterwards.
      </CommonDialog>
    </Grid>
  );
};

export default MembershipVoucherCard;
