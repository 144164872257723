import { UserNFCUiState } from "./model/UserNFCUiState";
import { useState } from "react";
import { UserNFCUiEvent } from "./model/UserNFCUiEvent";
import { useNavigate } from "react-router-dom";
import { UserNFCPage } from "./UserNFCPage";
import { ApiService } from "../../../../restAPI/ApiService";
import CommonDialog from "../../../../component/CommonDialog";

export function UserNFC() {
  const navigate = useNavigate();
  const [uiState, setUiState] = useState(new UserNFCUiState());
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [failedDialogOpen, setFailedDialogOpen] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [registeredDialogOpen, setRegisteredDialogOpen] = useState(false);

  const uiEvent: UserNFCUiEvent = {
    handleUiStateChange: (key: keyof UserNFCUiState, value: any) => {
      setUiState((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    },

    selectValid: (value: string) => {
      setUiState((prevState) => ({
        ...prevState,
        nfcCard: {
          ...prevState.nfcCard,
          valid: value,
        },
      }));
    },
    selectReason: (value: string) => {
      setUiState((prevState) => ({
        ...prevState,
        nfcCard: {
          ...prevState.nfcCard,
          reason: value,
        },
      }));
    },

    validateFields: () => {
      const newErrors = {
        reasonError: uiState.nfcCard.reason === "",
      };
      setUiState((prev) => {
        return { ...prev, errors: newErrors };
      });

      if (newErrors.reasonError) {
        return false;
      }
      return true;
    },
    handleUpdateNFC: () => {
      if (uiEvent.validateFields()) {
        setUpdateDialogOpen(true);
      }
    },
  };

  const checkAndUpdate = async () => {
    setUpdateDialogOpen(false);
    try {
      // NFC 중복검사 하는 함수 또는 api

      const result = await updateNFT();
      if (result) {
        setSuccessDialogOpen(true);
      } else {
        setFailedDialogOpen(true);
      }
    } catch (e) {
      setFailedDialogOpen(true);
    }
  };

  async function updateNFT(): Promise<boolean | undefined> {
    const url = "";
    const requestBody = {};

    try {
      const res = await ApiService.webPost(url, requestBody);
      const data = res.data;
      if (typeof data === "object" && Object.keys(data).length === 0)
        return undefined;
      return data;
    } catch (e) {
      throw e;
    }
  }
  return (
    <>
      <UserNFCPage uiState={uiState} uiEvent={uiEvent} />
      <CommonDialog
        open={updateDialogOpen}
        handleClose={() => setUpdateDialogOpen(false)}
        handleOk={checkAndUpdate}
      >
        Would you like to update your NFC <br /> information?
      </CommonDialog>
      <CommonDialog
        open={failedDialogOpen}
        handleOk={() => setFailedDialogOpen(false)}
        cancleButton={false}
      >
        NFC information update failed.
      </CommonDialog>
      <CommonDialog
        open={successDialogOpen}
        handleOk={() => setSuccessDialogOpen(false)}
        cancleButton={false}
      >
        NFC information is updated.
      </CommonDialog>
      <CommonDialog
        open={registeredDialogOpen}
        handleOk={() => setRegisteredDialogOpen(false)}
        cancleButton={false}
      >
        The card is already registered.
      </CommonDialog>
    </>
  );
}
