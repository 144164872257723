import { Card, CardContent, Grid, Typography } from "@mui/material";
import { UserNFCCreateCard } from "./create/UserNFCCreateCard";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import VerticalSpacer from "../../../../component/Spacer";
import { UserNFCUiState } from "./model/UserNFCUiState";
import { UserNFCUiEvent } from "./model/UserNFCUiEvent";
import { CommonWebHeaderButton } from "../../../../component/CommonWebHeaderButton";
import { UserNFCDetailCard } from "./detail/UserNFCDetailCard";
import { UserNFCCreate } from "./create/UserNFCCreate";

interface UserNFCPageProps {
  uiState: UserNFCUiState;
  uiEvent: UserNFCUiEvent;
}

export function UserNFCPage({ uiState, uiEvent }: UserNFCPageProps) {
  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75} sx={{ mt: -3 }}>
      {uiState.nfcCard.cardName === "" && uiState.createCard === false && (
        <Grid item lg={12}>
          <Card
            sx={{
              width: "100%",
              padding: "0px",
              border: "1px solid",
              borderColor: color.gray300,
              borderRadius: "15px",
              boxShadow: "unset",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                px: "28px",
                py: "40px",
                "&:last-child": {
                  paddingBottom: "41px",
                },
              }}
            >
              <Typography fontStyle={fontStyle.regularM}>
                There are no registered NFC cards.
              </Typography>
              <VerticalSpacer height={24} />
              <CommonWebHeaderButton
                sx={{ width: "111px", height: "45px", borderRadius: "40px" }}
                addIcon
                buttonContent="Create"
                onClick={() => {
                  uiEvent.handleUiStateChange("createCard", true);
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      )}
      {uiState.nfcCard.cardName !== "" && (
        <Grid item lg={12}>
          <UserNFCDetailCard uiState={uiState} uiEvent={uiEvent} />
        </Grid>
      )}
      {uiState.createCard === true && (
        <Grid item lg={12}>
          <UserNFCCreate />
        </Grid>
      )}
    </Grid>
  );
}
