import { Box, Typography } from "@mui/material";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import VerticalSpacer from "../../../../component/Spacer";
import { ICON_RIGHT_ARROW_PRIMARY200 } from "../../../../constants/appImagePath";
import LinearProgressBar from "../../../../component/LinearProgressBar";
import { LocalizedText } from "../../../../di/LanguageRepositoryProvider";

interface MileageSectionProps {
  remainingMileage: number;
  maxMileage: number;
  onClick: () => void;
}

const MileageSection = ({ remainingMileage, maxMileage, onClick }: MileageSectionProps) => {
  const formatMileage = (mileage: number) => {
    return `${parseFloat(mileage.toFixed(1)).toLocaleString()} km`;
  };

  const isUnlimited = maxMileage >= 2_000_000;
  const displayRemainingMileage = isUnlimited ? "Unlimited" : formatMileage(remainingMileage);

  return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
      <Typography fontStyle={fontStyle.titleL} color={color.white}>
        {LocalizedText("ua_g420_remaining")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          onClick={onClick}
        >
          <Typography fontStyle={fontStyle.titleXL} color={color.white}>
            {displayRemainingMileage}
          </Typography>
          <img
            src={ICON_RIGHT_ARROW_PRIMARY200}
            alt="Arrow"
            style={{
              width: "32px",
              height: "32px",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default MileageSection;
