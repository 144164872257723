import { Box, Button } from "@mui/material";
import CommonComboBox from "../../../../../component/CommonComboBox";
import CommonNationComboBox, { NationComboBoxMode } from "../../../../../component/CommonNationComboBox";
import VerticalSpacer, { HorizontalSpacer } from "../../../../../component/Spacer";
import ProvinceSelectBox from "../../../../../pagesMobile/MobileProfileEdit/section/ProvinceSelectBox";
import CommonDateRange from "../../../../../component/CommonDateRange";
import { useEffect, useState } from "react";
import { Dayjs } from "dayjs";
import GHGReductionTabTable from "./component/GHGReductionTabTable";
import { ApiService } from "../../../../../restAPI/ApiService";

const GHGReductionTabPage = () => {
  const [docCount, setDocCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // 콤보 박스 검색어
  // const [searchContents, setSearchContents] = useState("");
  // const [searchSNContents, setSearchSNContents] = useState("");

  // 필터링을 위해 필요한 변수수
  const [country, setCountry] = useState("");
  const [province, setProvince] = useState("");

  // Project Name 변수
  const [projectName, setProjectName] = useState("");

  // const handleInputChange = (_: any, value: string) => {
  //   setSearchContents(value); // 검색어 업데이트
  // };

  // const handleSearchSNContentsChange = (_: any, value: string) => {
  //   console.log(value);
  //   setSearchSNContents(value); // 검색어 업데이트
  // };

  // 우측 상단 Date Range 관련 변수
  const [dateRange, setDateRange] = useState<{ startDate: Dayjs | null; endDate: Dayjs | null }>({
    startDate: null,
    endDate: null,
  });

  function handleBtnSearch() {
    if (projectName == "") {
      alert("Project Name을 선택 하십시오");
    } else if (dateRange.startDate == null || dateRange.endDate == null) {
      alert("조회 기간을 선택 하십시오");
    } else {
      const searchData = {
        projectName: projectName,
        country: country,
        province: province,
        startDate: dateRange.startDate.valueOf(),
        endDate: dateRange.endDate.valueOf(),
      };
      console.log(searchData);
      ApiService.webPost("/pw/aw/carbonReduction/project/carbonReductionInfoByProject", searchData).then((res) => {
        console.log(res.data);
        if (res.data) {
          setRows(res.data.body);
        }
      });
    }
  }

  return (
    <>
      <Box>
        <Box>
          <CommonComboBox
            options={["POPLE_01"]}
            getOptionLabel={(option) => `${option}`} // 라벨 형식 정의
            renderOption={(props, option) => <li {...props}>{option}</li>} // 옵션 렌더링 정의
            onKeyPress={() => {
              // ApiService.WebPost("/pw/users/userList", {
              //   size: 10,
              //   page: 1,
              //   searchCondition: searchContents,
              //   sns: "",
              //   status: "",
              //   membership: "",
              // }).then((res) => {
              //   if (res?.data.body) {
              //     console.log(res?.data.body);
              //     const formattedRows = res.data.body.list.map((item: any) => ({
              //       uuid: item?.value?.uuid || "Unknown",
              //       name: item?.value?.name || "Unknown",
              //       phoneNumber: item?.value?.phoneNumber || "No Number",
              //       email: item?.value?.email || "No Email",
              //     }));
              //     setUsers(formattedRows);
              //   }
              // });
            }}
            sx={{
              width: "100%",
            }}
            //   onInputChange={handleInputChange} // 검색어 변경 시 호출
            //   onChange={(event, value) => {
            //     if (value?.uuid) {
            //       setUserUuid(value?.uuid);
            //     } else {
            //       setUserUuid("");
            //     }
            //   }}
            onChange={(event, value) => {
              if (value) {
                setProjectName(value);
              } else {
                setProjectName("");
              }
            }}
            placeholder="Project Name"
          />
        </Box>
        <VerticalSpacer height={16} />
        <Box sx={{ display: "flex", gap: "16px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <CommonNationComboBox
              handleChange={(value) => {
                setCountry(value);
              }}
              mode={NationComboBoxMode.ADMIN}
              placeholder="Country"
            />
            <HorizontalSpacer width={16} />
            <ProvinceSelectBox
              country={country}
              value={province}
              onChange={(event) => {
                setProvince(event);
              }}
              mode={NationComboBoxMode.ADMIN}
              placeholder="City / State / Province"
            />
            <HorizontalSpacer width={16} />
            <CommonDateRange
              value={dateRange}
              onChange={(newValue) => setDateRange(newValue)}
              views={["year", "month", "day"]}
              textFieldSx={{
                // width: "170px",
                height: "42px",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "white", // 기본 보더 색상
                  },
                  "&:hover fieldset": {
                    borderColor: "white",
                  },
                  "&.Mui-focused fieldset": {
                    border: "1px solid",
                    borderColor: "white",
                  },
                },
              }}
              InputPropsSx={{ height: "42px" }}
            />
            <HorizontalSpacer width={16} />
            <Button
              sx={{
                backgroundColor: "#5D38E5",
                borderRadius: 5,
                ":hover": {
                  backgroundColor: "#A38BFC",
                },
                color: "white",
                textTransform: "none",
                width: "85px",
                height: "40px",
              }}
              onClick={handleBtnSearch}
            >
              Search
            </Button>
          </Box>
        </Box>
      </Box>
      <VerticalSpacer height={16} />
      <GHGReductionTabTable
        rows={rows}
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </>
  );
};

export default GHGReductionTabPage;
