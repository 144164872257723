import { useDispatch, useSelector } from "react-redux";
import { Box, CssBaseline, Toolbar } from "@mui/material";
import Header from "./Header/Header";
import SideBar from "./SideNav/SideBar";
import LoginRoutes from "../../../routes/LoginRoutes";
import MainRoutes from "../../../routes/MainRoutes";
import { rootState } from "../../../store";
import { login, logout } from "../../../store/auth";
import { useEffect, useState } from "react";
import { color } from "../../../theme/Color";

const ClippedDrawer = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state: rootState) => state.auth.isLoggedIn);

  const savedDrawerState =
    sessionStorage.getItem("isShownDrawer") === null
      ? true // 값이 없으면 기본값 true
      : sessionStorage.getItem("isShownDrawer") === "true";

  const [isShownDrawer, setIsShownDrawer] = useState<boolean>(savedDrawerState);

  useEffect(() => {
    sessionStorage.setItem("isShownDrawer", String(isShownDrawer));
  }, [isShownDrawer]);

  // 세션 스토리지에서 로그인 상태 가져오기
  useEffect(() => {
    const storedIsLoggedIn = localStorage.getItem("isLoggedIn");
    if (storedIsLoggedIn === "true") {
      dispatch(login());
    } else {
      dispatch(logout());
    }
  }, [dispatch]);

  function changeDrawerState(state: boolean) {
    setIsShownDrawer(state);
  }

  return (
    <>
      {isLoggedIn ? (
        <Box sx={{ backgroundColor: "#F2F2F2" }}>
          <Box
            sx={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              justifyContent: "center",
              overflowY: "scroll",
              // backgroundColor: "red",
            }}
          >
            <CssBaseline />
            <Header
              onClickDrawerButton={() => {
                changeDrawerState(!isShownDrawer);
              }}
            />
            <SideBar isOpen={isShownDrawer} />
            <Box
              sx={{
                transition: "margin-left 0.3s ease", // 부드러운 애니메이션
                marginLeft: isShownDrawer ? "0px" : "0px", // Drawer 상태에 따라 여백 조정
                width: "1100px",
                // boxSizing: "border-box",
              }}
            >
              <Toolbar />
              <MainRoutes />
            </Box>
          </Box>
        </Box>
      ) : (
        <LoginRoutes />
      )}
    </>
  );
};

export default ClippedDrawer;
