export class UserNFCUiState {
  nfcCard: NFCCard;
  tagLog: TagLog[];
  createCard: boolean;

  //validation
  errors = {
    reasonError: false,
  };
  constructor(
    nfcCard: NFCCard = {
      cardName: "",
      type: "",
      valid: "",
      reason: "",
      registrationDate: 0,
    },
    tagLog: TagLog[] = [
      {
        station: "Station1",
        status: "Success",
        tagDate: 1736226589218,
      },
      {
        station: "Station2",
        status: "Failed",
        tagDate: 1736226589218,
      },
    ],
    createCard: boolean = false
  ) {
    this.tagLog = tagLog;
    this.nfcCard = nfcCard;
    this.createCard = createCard;
  }
}

export interface NFCCard {
  cardName: string;
  type: string;
  valid: string;
  reason: string;
  registrationDate: number | undefined;
}

export interface TagLog {
  station: string;
  status: string;
  tagDate: number | undefined;
}
