import { Box, Divider } from "@mui/material";
import { useState } from "react";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import { HorizontalSpacer } from "../../../../component/Spacer";

export function NFTManagementTab() {
  const [tabValue, setTabValue] = useState("management");
  return (
    <Box sx={{ display: "flex", height: "24px" }}>
      <HorizontalSpacer width={16} />
      <Box
        sx={{
          fontStyle: fontStyle.semiboldM,
          cursor: "pointer",
          color: tabValue === "management" ? color.primary500 : color.gray600,
        }}
        onClick={() => {
          setTabValue("management");
        }}
      >
        Management
      </Box>
      <HorizontalSpacer width={16} />
      {/* <Divider orientation="vertical" />
      <HorizontalSpacer width={16} />
      <Box
        sx={{
          fontStyle: fontStyle.semiboldM,
          cursor: "pointer",
          color: tabValue === "record" ? color.primary500 : color.gray600,
        }}
        onClick={() => {
          setTabValue("record");
        }}
      >
        이력
      </Box> */}
    </Box>
  );
}
