import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiService } from "../../../restAPI/ApiService";
import { Box, TableCell, TableRow, Typography } from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import LocalizedDate, { DateFormatType } from "../../../component/LocalizedDate";
import CommonTable, { useVisibleRows } from "../../../component/CommonTable";
import { color } from "../../../theme/Color";
import { HorizontalSpacer } from "../../../component/Spacer";
import { StationDataType, StationUiState } from "../model/StationUiState";
import { StationUiEvent } from "../model/StationUiEvent";
interface StationMngTableProps {
  mngRows?: any;
  rowsPerPage?: any;
  setRowsPerPage?: any;
  page?: any;
  pageCount?: any;
  setPage?: any;
  docCount?: number;
  uiState: StationUiState;
  uiEvent: StationUiEvent;
}

interface RowProps {
  row: StationDataType;
  index: any;
  uiState: StationUiState;
  uiEvent: StationUiEvent;
}
const Row = ({ row, index, uiState, uiEvent }: RowProps) => {
  return (
    <>
      <TableRow>
        <TableCell align="center" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.no}</Typography>
        </TableCell>

        <TableCell
          onClick={() => {
            uiEvent.onClick(row.svcId.toString());
          }}
          align="left"
          sx={{ height: "52px", py: 0, cursor: "pointer" }}
        >
          <Typography fontStyle={fontStyle.regularXS}>{row.svcNm}</Typography>
        </TableCell>
        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.svcAreaNm}</Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          {row.svcStt == "OPEN" && (
            <Box
              sx={{
                fontStyle: fontStyle.regularXS,
                display: "flex",
                alignItems: "center",
                // justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: "7.5px",
                  height: "7.5px",
                  bgcolor: color.success,
                  borderRadius: "10px",
                }}
              />
              <HorizontalSpacer width={7.5} />
              {row.svcStt}
            </Box>
          )}
          {row.svcStt == "Close" && (
            <Box
              sx={{
                fontStyle: fontStyle.regularXS,
                display: "flex",
                alignItems: "center",
                // justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: "7.5px",
                  height: "7.5px",
                  bgcolor: color.warning2,
                  borderRadius: "10px",
                }}
              />
              <HorizontalSpacer width={7.5} />
              {row.svcStt}
            </Box>
          )}
          {row.svcStt == "New" && (
            <Box
              sx={{
                fontStyle: fontStyle.regularXS,
                display: "flex",
                alignItems: "center",
                // justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: "7.5px",
                  height: "7.5px",
                  bgcolor: color.progress,
                  borderRadius: "10px",
                }}
              />
              <HorizontalSpacer width={7.5} />
              {row.svcStt}
            </Box>
          )}
          {row.svcStt == "Preparing" && (
            <Box
              sx={{
                fontStyle: fontStyle.regularXS,
                display: "flex",
                alignItems: "center",
                // justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: "7.5px",
                  height: "7.5px",
                  bgcolor: color.gray600,
                  borderRadius: "10px",
                }}
              />
              <HorizontalSpacer width={7.5} />
              {row.svcStt}
            </Box>
          )}
          {row.svcStt == "Closure" && (
            <Box
              sx={{
                fontStyle: fontStyle.regularXS,
                display: "flex",
                alignItems: "center",
                // justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: "7.5px",
                  height: "7.5px",
                  bgcolor: color.error,
                  borderRadius: "10px",
                }}
              />
              <HorizontalSpacer width={7.5} />
              {row.svcStt}
            </Box>
          )}
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          {/* {row.link == "Linked" && (
            <Box
              sx={{
                fontStyle: fontStyle.regularXS,
                display: "flex",
                alignItems: "center",
                // justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: "7.5px",
                  height: "7.5px",
                  bgcolor: color.success,
                  borderRadius: "10px",
                }}
              />
              <HorizontalSpacer width={7.5} />
              {row.link}
            </Box>
          )} */}
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {" "}
            {uiState.countryList.find((country) => country.code === row.ctryCd)?.label || row.ctryCd}
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {LocalizedDate({
              date: row.lastUptDt,
              type: DateFormatType.AdminType,
            })}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {LocalizedDate({
              date: row.registerDt,
              type: DateFormatType.AdminType,
            })}
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
};

const StationMngTable = ({ rowsPerPage, setRowsPerPage, docCount, page, pageCount, setPage, uiState, uiEvent }: StationMngTableProps) => {
  const headCells = [
    {
      id: "no",
      align: "center",
      disablePadding: false,
      label: "No",
    },
    {
      id: "name",
      align: "left",
      disablePadding: false,
      label: "Name",
    },

    {
      id: "areaname",
      align: "left",
      disablePadding: false,
      label: "Area Name",
    },
    {
      id: "state",
      align: "left",
      disablePadding: false,
      label: "Status",
    },
    {
      id: "link",
      align: "left",
      disablePadding: false,
      label: "Link",
    },

    {
      id: "country",
      align: "left",
      disablePadding: false,
      label: "Country",
    },

    {
      id: "lastUpdateDate",
      align: "left",
      disablePadding: false,
      label: "Last Update Date",
    },
    {
      id: "registerDate",
      align: "left",
      disablePadding: false,
      label: "Register Date",
    },
  ];

  const { order, orderBy, handleRequestSort, handleChangePage, visibleRows } = useVisibleRows(uiState.data || [], rowsPerPage, setPage, page);

  return (
    <CommonTable
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      page={page}
      docCount={docCount}
      pageCount={pageCount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      handleRequestSort={handleRequestSort}
      handleChangePage={handleChangePage}
      setPage={setPage}
    >
      {Array.isArray(uiState.data) && uiState.data.length == 0 ? (
        <TableRow sx={{ px: " 4px", height: "1px" }}>
          <TableCell
            colSpan={headCells?.length}
            align="center"
            sx={{
              minWidth: "1032px",
              px: "13px",
              py: "12px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontStyle: fontStyle.regularS,
                color: color.gray600,
              }}
            >
              No Data
            </Typography>
          </TableCell>
        </TableRow>
      ) : (
        uiState.data?.map((row: StationDataType, index: number) => <Row row={row} index={index} key={index} uiState={uiState} uiEvent={uiEvent} />)
      )}
    </CommonTable>
  );
};

export default StationMngTable;
