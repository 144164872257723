import { UserWalletPage } from "./UserWalletPage";
import { TabType } from "./model/TabType";
import { UserWalletUiState } from "./model/UserWalletUiState";
import { useState } from "react";
import { UserWalletUiEvent } from "./model/UserWalletUiEvent";

export function UserWallet() {
  const [uiState, setUiState] = useState(
    new UserWalletUiState()
  );
  const [currentTab, setCurrentTab] = useState<TabType>(TabType.NFT);

  const userWalletUiEvent: UserWalletUiEvent = {
    OnClickTab: function (tab: TabType): void {
      setCurrentTab(tab)
    },

    HandleUiStateChange: (key: keyof UserWalletUiState, value: any) => {
      setUiState((prevState) => ({
        ...prevState,
        [key]: value,
      }))
    },
  };
  return (
    <UserWalletPage
      currentTab={currentTab}
      uiState={uiState}
      uiEvent={userWalletUiEvent}
    />
  );
}
