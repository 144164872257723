import { Box, Typography } from "@mui/material";
import { fontStyle } from "../../theme/Style";
import CommonButton from "../../component/CommonButton";
import { useEffect, useState } from "react";
import VerticalSpacer from "../../component/Spacer";
import Toolbar from "../../component/Toolbar";
import { useLocation, useNavigate } from "react-router-dom";
import useAndroidBackHandler from "../../customHook/useAndroidBackHandler";
import { LocalizedText } from "../../di/LanguageRepositoryProvider";
import { color } from "../../theme/Color";
import { ApiService } from "../../restAPI/ApiService";
import SimpleDialog from "../../component/dialog/SimpleDialog";
import ProgressBar from "../../component/ProgressBar";
import { ICON_EDIT_PRIMARY } from "../../constants/appImagePath";
import { UserProfile } from "../MobileProfile/model/UserProfile";
import { useTemporalErrorToast } from "../../component/toast/ConcurrentToastManager";
import LocalizedDate, { DateFormatType } from "../../component/LocalizedDate";
import { ROUTE_PATHS } from "../../constants/RouteConstants";

const MyRideWatingPage = () => {
  const navigate = useNavigate();
  const [qr, setQRData] = useState("");
  const [hasQr, setHasQr] = useState(false);
  const location = useLocation();
  const { state } = location.state || {}; // 전달된 상태를 받음
  const [openDialog, setOpenDialog] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState<UserProfile | undefined>(undefined);
  const showToast = useTemporalErrorToast();

  console.log("state is :", state);

  useAndroidBackHandler(() => {
    window.history.back();
  }, []);

  useEffect(() => {
    const handleAppToWeb = (event: Event) => {
      const customEvent = event as CustomEvent<string>;
      setQRData(customEvent.detail);
    };

    window.addEventListener("dispatchQRData", handleAppToWeb);
    return () => {
      window.removeEventListener("dispatchQRData", handleAppToWeb);
    };
  }, []);

  useEffect(() => {
    // console.log(hasQr);
    if (qr === "") {
      setHasQr(false);
    } else {
      setHasQr(true);
      navigate("/MyRide", {
        state: {
          hasQr: true,
        },
      });
    }
  }, [qr]);

  useEffect(() => {
    requestDetailProfile()
      .then((res) => {
        if (res) {
          setUserInfo(res);
        }
      })
      .catch((error) => {
        console.error("Error - Request Detail Profile :", error);
        showToast();
      });
  }, []);

  async function requestDetailProfile(): Promise<UserProfile | undefined> {
    const url = "/pw/users/detailProfile";
    const requestBody = {
      uuid: localStorage.getItem("uuid"),
    };
    try {
      const res = await ApiService.post(url, requestBody);
      if (res) {
        return res.data.body as UserProfile;
      } else {
        console.log(`requestDetailProfile failed ${res}`);
        return undefined;
      }
    } catch (error) {
      console.error("Error requestDetailProfile:", error);
      throw error;
    }
  }

  return (
    <>
      {loading && <ProgressBar />}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "white",
          width: "100vw",
          height: "100vh",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {/* 상단 toolbar */}
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Toolbar
            title={LocalizedText("ua_e310_gnb")}
            onBack={() => {
              window.history.back();
            }}
          />
        </Box>
        <Box
          sx={{
            width: "calc(100% - 40px)",
            height: "auto",
          }}
        >
          <VerticalSpacer height={20} />
          <Box sx={{ width: "100%", mb: 2 }}>
            <Typography fontStyle={fontStyle.headingS}>
              {LocalizedText("ua_e200_confirmation_title")}
            </Typography>
          </Box>
          <VerticalSpacer height={20} />

          {/* Scooter 정보 */}
          <Typography fontStyle={fontStyle.titleL} color={color.default}>
            {LocalizedText("ua_e200_scooterinfo")}
          </Typography>
          <VerticalSpacer height={12} />
          <Box
            sx={{
              width: "100%",
              backgroundColor: color.primary50,
              borderRadius: "10px",
              p: "16px",
              boxSizing: "border-box",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={state.modelPicUrl}
                style={{
                  backgroundColor: "white",
                  borderRadius: "5px",
                  height: "80px",
                  width: "80px",
                  objectFit: "cover",
                }}
              />
              <Box sx={{ display: "flex", ml: 2, flexDirection: "column" }}>
                <Typography fontStyle={fontStyle.titleM}>
                  {state.productName}
                </Typography>
                <Box
                  color={color.gray700}
                  sx={{ display: "flex" }}
                  fontStyle={fontStyle.subTitleM}
                >
                  {LocalizedText("ua_e200_contract")}
                  <Typography
                    sx={{ ml: 1 }}
                    fontStyle={fontStyle.bodyMr}
                    color={color.default}
                  >
                    {state.agreementDt}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <VerticalSpacer height={24} />

          {/* 사용자 정보 */}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography fontStyle={fontStyle.titleL} color={color.default}>
              {LocalizedText("ua_e200_userinfo")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                navigate(ROUTE_PATHS.ADDITIONAL_INFO);
              }}
            >
              <img
                src={ICON_EDIT_PRIMARY}
                style={{
                  width: "20px",
                  height: "20px",
                  objectFit: "cover",
                }}
              />
              <Typography
                fontStyle={fontStyle.subTitleM}
                color={color.primary500}
                sx={{ ml: 0.5 }}
              >
                {LocalizedText("ua_e200_edit")}
              </Typography>
            </Box>
          </Box>
          <VerticalSpacer height={12} />

          <Box
            sx={{
              width: "100%",
              backgroundColor: color.primary50,
              borderRadius: "10px",
              p: "16px",
              boxSizing: "border-box",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: "12px",
              }}
            >
              <Typography fontStyle={fontStyle.bodyMr} color={color.gray700}>
                {LocalizedText("ua_e200_name")}
              </Typography>
              <Typography
                fontStyle={fontStyle.bodyMr}
                color={color.default}
                textAlign="right"
                sx={{ width: "60%" }}
              >
                {userInfo?.name}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: "12px",
              }}
            >
              <Typography fontStyle={fontStyle.bodyMr} color={color.gray700}>
                {LocalizedText("ua_g200_birthofdate")}
              </Typography>
              <Typography
                fontStyle={fontStyle.bodyMr}
                color={color.default}
                textAlign="right"
                sx={{ width: "60%" }}
              >
                {LocalizedDate({
                  date: userInfo?.birthday || 0,
                  type: DateFormatType.YearMonthDay,
                })}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography fontStyle={fontStyle.bodyMr} color={color.gray700}>
                {LocalizedText("ua_g200_address")}
              </Typography>
              <Typography
                fontStyle={fontStyle.bodyMr}
                color={color.default}
                textAlign="right"
                whiteSpace="pre-line"
                sx={{ width: "60%" }}
              >
                {userInfo?.addressDetail}, {userInfo?.addressProvince},{" "}
                {userInfo?.addressCountry}
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* 하단 버튼 */}
        <Box
          sx={{
            position: "absolute",
            bottom: "0px",
            width: "91%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
          >
            {LocalizedText("ua_e200_pressbutton")}
          </Typography>
          <CommonButton
            title={LocalizedText("ua_e200_getscooter_button")}
            textColor="white"
            onClick={() => {
              setLoading(true);
              ApiService.WebPost("/pw/backend/api/scooterAgreement/received", {
                agreementNumber: state.agreementNumber,
                uuid: localStorage.getItem("uuid") || "",
              })
                .then((res) => {
                  console.log(res?.data.body);
                  if (res?.data.body.status == "not Hand Over") {
                    setOpenErrorDialog(true);
                    setLoading(false);
                  } else {
                    setOpenDialog(true);
                    setLoading(false);
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }}
            sx={{
              m: "20px",
            }}
            disabled={state.receiptConfirmation != "Hand Over"}
          />
        </Box>
      </Box>
      <SimpleDialog
        isOpen={openDialog}
        onPositive={() => {
          window.history.back();
        }}
        onClose={() => {}}
        children={LocalizedText("ua_e200_alert_complete_message")}
        positiveText={LocalizedText("common_alert_confirm_button")}
      />
      <SimpleDialog
        isOpen={openErrorDialog}
        onPositive={() => {
          window.history.back();
        }}
        onClose={() => {}}
        children={
          LocalizedText("ua_e200_alert_failed_message") + "\nReason : TIME_OUT"
        }
        positiveText={LocalizedText("common_alert_confirm_button")}
      />
    </>
  );
};

export default MyRideWatingPage;
