import { LanguageRepository } from "../LanguageRepository";
import { ApiService } from "../../restAPI/ApiService";

export class LanguageRepositoryImpl implements LanguageRepository {
  private static instance: LanguageRepository;
  private static languagePack: { [key: string]: any } = {};

  public static getInstance(): LanguageRepository {
    if (!this.instance) {
      this.instance = new LanguageRepositoryImpl();
    }
    return this.instance;
  }

  async getLanguagePack(): Promise<boolean> {
    try {
      const url = "/pw/aw/system/multilingual/findAll";
      const res = await ApiService.MobilePost(url);
      LanguageRepositoryImpl.languagePack = res!.data.body;
      return true;
    } catch (e) {
      return false;
    }
  }

  getLocalizedText(key: string, languageCode: string): string {
    const languagePack = LanguageRepositoryImpl.languagePack;
    if (
      languagePack &&
      languagePack[languageCode] &&
      languagePack[languageCode][key]
    ) {
      return languagePack[languageCode][key];
    }

    return "";
  }
}
