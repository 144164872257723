import { useEffect, useState } from "react";
import CommonList from "../../../../component/CommonList";
import { CommonWebHeaderButton } from "../../../../component/CommonWebHeaderButton";
import { Production } from "./production/Production";
import { ProductionManagementTab, ProductionManagementTabType } from "./section/ProductionManagementTab";
import { ProductionHistory } from "./productionhistory/ProductionHistory";
import { Materials } from "./materials/Materials";
import { MaterialHistory } from "./materialhistory/MaterialHistory";
import { SpecificationHistory } from "./specificationhistory/SpecificationHistory";
import { useLocation, useNavigate } from "react-router-dom";

const ProductionManagement = () => {
  const [currentTab, setCurrentTab] = useState<ProductionManagementTabType>(ProductionManagementTabType.PRODUCTION);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tabParam = params.get("tab");

    switch (tabParam) {
      case ProductionManagementTabType.MATERIALS:
        setCurrentTab(ProductionManagementTabType.MATERIALS);
        break;
      case ProductionManagementTabType.PRODUCTION_HISTORY:
        setCurrentTab(ProductionManagementTabType.PRODUCTION_HISTORY);
        break;
      case ProductionManagementTabType.MATERIAL_HISTORY:
        setCurrentTab(ProductionManagementTabType.MATERIAL_HISTORY);
        break;
      case ProductionManagementTabType.SPECIFICATION_HISTORY:
        setCurrentTab(ProductionManagementTabType.SPECIFICATION_HISTORY);
        break;
      default:
        setCurrentTab(ProductionManagementTabType.PRODUCTION);
        break;
    }
  }, [location.search]);
  const handleChangeTab = (tab: ProductionManagementTabType) => {
    setCurrentTab(tab);
    navigate(`/productionManagement?tab=${tab}`);
  };
  return (
    <CommonList
      title="Production"
      tabButton={<ProductionManagementTab currentTab={currentTab} changeTab={handleChangeTab} />}
      headerButton={
        (currentTab == ProductionManagementTabType.PRODUCTION ||
          currentTab == ProductionManagementTabType.MATERIALS ||
          currentTab == ProductionManagementTabType.PRODUCTION_HISTORY) && (
          <CommonWebHeaderButton
            width="122px"
            height="45px"
            buttonContent="Register"
            addIcon={true}
            onClick={() => {
              currentTab == ProductionManagementTabType.PRODUCTION && navigate("/productionRegistration");
              currentTab == ProductionManagementTabType.MATERIALS && navigate("/materialRegistration");
            }}
          />
        )
      }
    >
      {currentTab == ProductionManagementTabType.PRODUCTION && <Production />}
      {currentTab == ProductionManagementTabType.PRODUCTION_HISTORY && <ProductionHistory />}
      {currentTab == ProductionManagementTabType.MATERIALS && <Materials />}
      {currentTab == ProductionManagementTabType.MATERIAL_HISTORY && <MaterialHistory />}
      {currentTab == ProductionManagementTabType.SPECIFICATION_HISTORY && <SpecificationHistory />}
    </CommonList>
  );
};

export default ProductionManagement;
