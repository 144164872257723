import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { VCInfo } from "../model/UserWalletUiState";
import { fontStyle } from "../../../../../theme/Style";
import { color } from "../../../../../theme/Color";
import VerticalSpacer from "../../../../../component/Spacer";
import LocalizedDate, {
  DateFormatType,
} from "../../../../../component/LocalizedDate";

interface UserWalletNFTPageProps {
  data: VCInfo;
}

export function UserWalletVCPage({ data }: UserWalletNFTPageProps) {
  const renderTextRow = (label: string, value: string) => (
    <Box sx={{ display: "flex", height: "20px", gap: "8px" }}>
      <Typography fontStyle={fontStyle.regularS} color={color.gray600}>
        {label}
      </Typography>
      <Typography fontStyle={fontStyle.regularS}>{value}</Typography>
    </Box>
  );

  return (
    <Grid item xs={4}>
      <Card
        sx={{
          width: "100%",
          padding: "0px",
          border: "1px solid",
          borderColor: color.gray300,
          borderRadius: "15px",
          boxShadow: "unset",
        }}
      >
        <CardContent
          sx={{
            padding: "0px",
            "&:last-child": {
              paddingBottom: "0px",
            },
          }}
        >
          {/* 이미지 영역 */}
          <Box sx={{ position: "relative", width: "100%", height: "330.67px" }}>
            <img
              src={data.image}
              alt="Certificate"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
            {data.status !== "" && (
              <Box
                sx={{
                  width: "103px",
                  height: "36px",
                  borderRadius: "10px",
                  border: "2px solid",
                  borderColor: color.primary500,
                  position: "absolute",
                  top: "50%", // 이미지의 세로 중앙
                  left: "50%", // 이미지의 가로 중앙
                  transform: "translate(-50%, -50%) rotate(-30deg)", // 중앙 정렬 및 30도 회전
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  filter: "brightness(1.5)",
                }}
              >
                <Typography
                  fontStyle={fontStyle.semiboldM}
                  color={color.primary500}
                >
                  {data.status}
                </Typography>
              </Box>
            )}
          </Box>

          <Box
            sx={{
              width: "100%",
              height: "182px",
              padding: "20px",
              paddingBottom: "40px",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{ display: "flex", alignItems: "center", height: "18px" }}
                  fontStyle={fontStyle.semiboldXS}
                  color={color.gray600}
                >
                  {data.certificate}
                </Typography>
                <VerticalSpacer height={4} />
                <Box sx={{ height: "28px" }}>
                  <Typography fontStyle={fontStyle.semiboldL}>
                    {data.vcId}
                  </Typography>
                </Box>
              </Box>
              {data.status !== "" && (
                <Box
                  sx={{
                    backgroundColor: color.gray200,
                    borderRadius: "5px",
                    height: "26px",
                    px: "8px",
                    py: "4px",
                  }}
                >
                  <Typography
                    fontStyle={fontStyle.boldXS}
                    color={color.gray700}
                  >
                    {data.status}
                  </Typography>
                </Box>
              )}
            </Box>
            <VerticalSpacer height={4} />
            {/* Issuer, Issued, Received */}
            <Box
              sx={{
                height: "68px",
              }}
            >
              {renderTextRow("Issuer", data.issuer)}
              {renderTextRow(
                "Issued",
                LocalizedDate({
                  date: data.issued,
                  type: DateFormatType.AdminType,
                })
              )}
              {renderTextRow(
                "Expired",
                LocalizedDate({
                  date: data.expried,
                  type: DateFormatType.AdminType,
                })
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}
