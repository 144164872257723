import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import VerticalSpacer from "../../../../component/Spacer";
import CommonTextField from "../../../../component/CommonTextField";
import { CommonSelect } from "../../../../component/CommonSelect";
import { NFTDetailUiState } from "../model/NFTDetailUiState";
import { NFTDetailUiEvent } from "../model/NFTDetailUiEvent";
import { useRef } from "react";

interface NFTLevelCardProps {
  level: number;
  uiState: NFTDetailUiState;
  uiEvent: NFTDetailUiEvent;
}

export function NFTLevelCard({ level, uiState, uiEvent }: NFTLevelCardProps) {
  const levelData = uiState.levelInfos[level - 1];
  const levelError = uiState.errors.levelInfosError[level - 1];

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const clickFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      uiEvent.handleLevelInfoChange(level - 1, "image", file);
      uiEvent.handleLevelInfoChange(level - 1, "action", "update");
    }
  };

  const getCouponName = (id: string) => {
    return uiState.rewardOptions.find((option) => option.id === id)?.name || "";
  };

  return (
    <Grid item xs={6}>
      <Card
        sx={{
          borderRadius: 3,
          width: "100%",
          padding: "24px",
          // display: "flex",
          boxShadow: "unset",
          border: "1px solid",
          borderColor: color.gray300,
        }}
      >
        <CardContent
          sx={{
            width: "100%",
            padding: "0px",
          }}
        >
          <Box>
            <Typography fontStyle={fontStyle.semiboldL} color={color.default}>
              Level {level}
            </Typography>
            <VerticalSpacer height={16} />
          </Box>
        </CardContent>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "0px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "24px",
              alignItems: "center",
            }}
          >
            <Box>
              {/* 이미지 */}
              {levelData.image instanceof File ? (
                <Box
                  component="img"
                  src={URL.createObjectURL(levelData.image)}
                  sx={{
                    width: "188px",
                    height: "188px",
                    borderRadius: "10px",
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                  onClick={clickFileInput}
                />
              ) : (
                <Box
                  component="img"
                  src={levelData.image}
                  sx={{
                    width: "188px",
                    height: "188px",
                    cursor: "pointer",
                    borderRadius: "10px",
                  }}
                  onClick={clickFileInput}
                />
              )}
              <input
                type="file"
                accept=".jpg, .jpeg, .png"
                ref={fileInputRef}
                onChange={handleImageUpload}
                hidden
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Level name
                </Typography>
                <CommonTextField
                  value={levelData.levelName}
                  onChange={(e) => {
                    uiEvent.handleLevelInfoChange(
                      level - 1,
                      "levelName",
                      e.target.value
                    );
                  }}
                  sx={{
                    width: "100%",
                    height: "48px",
                    mt: "8px",
                  }}
                  InputProps={{ style: { height: "48px" } }}
                  error={levelError?.levelNameError}
                />
                {levelError?.levelNameError && (
                  <Typography
                    fontStyle={fontStyle.regularXS}
                    color={color.error}
                  >
                    Please enter a level name.
                  </Typography>
                )}
              </Box>
              <VerticalSpacer height={40} />
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Max Exp
                </Typography>
                <CommonTextField
                  value={level === 5 ? "-" : levelData.maxExp}
                  onChange={(e) => {
                    uiEvent.handleLevelInfoChange(
                      level - 1,
                      "maxExp",
                      e.target.value
                    );
                  }}
                  sx={{
                    width: "100%",
                    height: "48px",
                    mt: "8px",
                  }}
                  InputProps={{ style: { height: "48px" } }}
                  error={levelError?.maxExpError && level !== 5}
                  disabled={level === 5}
                />
                {levelError?.maxExpError && level !== 5 && (
                  <Typography
                    fontStyle={fontStyle.regularXS}
                    color={color.error}
                  >
                    Please enter a max exp.
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
          <VerticalSpacer height={40} />
          <Box
            sx={{
              // mx: "24px",
              // mt: "24px",
              // mb: "40px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
              Reward
            </Typography>
            <CommonSelect
              selectValue={
                level === 5 ? "" : getCouponName(levelData.coupon.id || "")
              }
              placeholder="Select"
              menuitems={uiState.rewardOptions
                .slice()
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((option) => option.name)}
              onChange={(event) => {
                uiEvent.handleLevelInfoChange(
                  level - 1,
                  "coupon",
                  uiState.rewardOptions.find(
                    (option) => option.name == event.target.value
                  )
                );
              }}
              sx={{
                height: "48px",
                fontStyle: fontStyle.regularS,
                color: "black",
                mt: "8px",
              }}
              error={levelError?.rewardError}
              disabled={level === 5}
            />
            {levelError?.rewardError && level !== 5 && (
              <Typography fontStyle={fontStyle.regularXS} color={color.error}>
                Please select a reward.
              </Typography>
            )}
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}
