import React, { useEffect, useState } from "react";
import { isBrowser } from "react-device-detect";
import { createTheme, ThemeProvider } from "@mui/material";
import ClippedDrawer from "./layout/BaseLayout/CommonLayout";
import MobileDrawer from "./layout/BaseLayout/MobileLayout/MobileDrawer";
import CombinedProvider from "./di/CombinedProvider";
import { AuthRepositoryProvider } from "./di/AuthRepositoryProvider";
import { MethodologyProvider } from "./context/MethodologyContext";
import MonitoringProvider from "./di/MonitoringProvider";
import "./App.css";
import "./Font.css";

const App = () => {
  // Admin Web Theme 설정
  const [theme] = useState(
    createTheme({
      typography: {
        fontFamily: "'OpenSans', sans-serif",
      },
    }),
  );

  useEffect(() => {
    function androidBackHandlerEvent(): void {
      const event = new CustomEvent("androidBackHandlerEvent");
      window.dispatchEvent(event);
    }

    window.androidBackHandlerEvent = androidBackHandlerEvent;
  }, []);

  return isBrowser ? (
    <ThemeProvider theme={theme}>
      <AuthRepositoryProvider>
        <MethodologyProvider>
          <MonitoringProvider>
            <ClippedDrawer />
          </MonitoringProvider>
        </MethodologyProvider>
      </AuthRepositoryProvider>
    </ThemeProvider>
  ) : (
    <CombinedProvider>
      <MobileDrawer />
    </CombinedProvider>
  );
};

export default App;
