import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AdditionalInfoUiState } from "./AdditionalInfoUiState";
import { AdditionalInfoUiEvent } from "./AdditionalInfoUiEvent";
import { AdditionalInfoSideEffect } from "./AdditionalInfoSideEffect";
import AdditionalInfoRender from "./AdditionalInfoRender";
import useAndroidBackHandler from "../../../customHook/useAndroidBackHandler";
import { ApiService } from "../../../restAPI/ApiService";
import { UserProfile } from "../../MobileProfile/model/UserProfile";
import { useTemporalErrorToast } from "../../../component/toast/ConcurrentToastManager";

const AdditionalInfoPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location.state || {};
  const [loading, setLoading] = useState(false);
  const [uiState, setUiState] = useState(new AdditionalInfoUiState());
  const showToast = useTemporalErrorToast();

  useEffect(() => {
    requestDetailProfile()
      .then((res) => {
        if (res) {
          setUiState((prev) => {
            return {
              ...prev,
              day: getBirthDay(res.birthday),
              month: getBirthMonth(res.birthday),
              year: getBirthYear(res.birthday),
              gender: res.gender ?? "",
              country: res.addressCountry ?? "",
              cityCountyDistrict: res.addressProvince ?? "",
              cityDetail: res.addressDetail ?? "",
              localNumber: res.countryNumber ?? "",
              phoneNumber: res.phoneNumber ?? "",
              tribeCategory: res.tribeCategory ?? "",
              tribeName: res.tribeName ?? "",
            };
          });
        }
      })
      .catch((error) => {
        console.error("Error - Request Detail Profile :", error);
        showToast();
      });
  }, []);

  useAndroidBackHandler(() => {
    if (window.Android) {
      if (uiState.isShowExitDialog) {
        uiEventHandler.ChangeExitDialogState(false);
      } else {
        window.history.back();
      }
    }
  }, [uiState.isShowExitDialog]);

  const uiEventHandler: AdditionalInfoUiEvent = {
    HandleDayChange: (event: string) => {
      setUiState((prev) => {
        return {
          ...prev,
          day: event,
        };
      });
    },
    HandleMonthChange: (event: string) => {
      setUiState((prev) => {
        return {
          ...prev,
          month: event,
        };
      });
    },
    HandleYearChange: (event: string) => {
      setUiState((prev) => {
        return {
          ...prev,
          year: event,
        };
      });
    },
    HandleGenderChange: (event: string) => {
      setUiState((prev) => {
        return {
          ...prev,
          gender: event,
        };
      });
    },
    HandleCountryChange: (event: string) => {
      setUiState((prev) => {
        return {
          ...prev,
          country: event,
        };
      });
    },
    HandleCityCountyDistrictChange: (event: string) => {
      setUiState((prev) => {
        return {
          ...prev,
          cityCountyDistrict: event,
        };
      });
    },
    HandleCityDetailChange: (event: string) => {
      setUiState((prev) => {
        return {
          ...prev,
          cityDetail: event,
        };
      });
    },
    VerifyingData: () => {
      let isValid = true;

      // 생년월일 검증
      // Day 검증
      if (uiState.day == "") {
        isValid = false;
        setUiState((prev) => ({
          ...prev,
          dayError: "error",
        }));
      } else {
        setUiState((prev) => ({
          ...prev,
          dayError: "",
        }));
      }
      // Month 검증
      if (uiState.month == "") {
        isValid = false;
        setUiState((prev) => ({
          ...prev,
          monthError: "error",
        }));
      } else {
        setUiState((prev) => ({
          ...prev,
          monthError: "",
        }));
      }
      // Year 검증
      if (uiState.year == "") {
        isValid = false;
        setUiState((prev) => ({
          ...prev,
          yearError: "error",
        }));
      } else {
        setUiState((prev) => ({
          ...prev,
          yearError: "",
        }));
      }

      // 성별 검증
      if (uiState.gender == "") {
        isValid = false;
        setUiState((prev) => ({
          ...prev,
          genderError: "error",
        }));
      } else {
        setUiState((prev) => ({
          ...prev,
          genderError: "",
        }));
      }

      // 주소 검증
      // Contry 검증
      if (uiState.country == "") {
        isValid = false;
        setUiState((prev) => ({
          ...prev,
          countryError: "error",
        }));
      } else {
        setUiState((prev) => ({
          ...prev,
          countryError: "",
        }));
      }
      // CityCountyDistrict 검증
      if (
        uiState.cityCountyDistrict?.trim() == "" ||
        uiState.cityCountyDistrict == null
      ) {
        isValid = false;
        setUiState((prev) => ({
          ...prev,
          cityCountyDistrictError: "error",
        }));
      } else {
        setUiState((prev) => ({
          ...prev,
          cityCountyDistrictError: "",
        }));
      }
      // CityDetail
      if (uiState.cityDetail?.trim() == "" || uiState.cityDetail == null) {
        isValid = false;
        setUiState((prev) => ({
          ...prev,
          cityDetailError: "error",
        }));
      } else {
        setUiState((prev) => ({
          ...prev,
          cityDetailError: "",
        }));
      }
      if (isValid) {
        sideEffectHandler.requestUpdateProfile();
      } else {
        console.log("Data is not valid");
      }
    },
    ChangeExitDialogState: (isShow: boolean) => {
      setUiState((prev) => {
        return {
          ...prev,
          isShowExitDialog: isShow,
        };
      });
    },
    ChangeCompleteDialogState: (isShow: boolean) => {
      setUiState((prev) => {
        return {
          ...prev,
          isShowCompleteDialog: isShow,
        };
      });
    },
  };
  const sideEffectHandler: AdditionalInfoSideEffect = {
    navigate: (target: string) => {
      navigate(target, {
        state: {
          state: state,
        },
      });
    },
    requestUpdateProfile: () => {
      requestEditProfile()
        .then(() => {
          console.log("Request Edit Profile Success");
        })
        .catch((error) => {
          console.error("Error - Request Edit Profile :", error);
          showToast();
        });
    },
  };

  async function requestDetailProfile(): Promise<UserProfile | undefined> {
    const url = "/pw/users/detailProfile";
    const requestBody = {
      uuid: localStorage.getItem("uuid"),
    };
    try {
      const res = await ApiService.post(url, requestBody);
      if (res) {
        return res.data.body as UserProfile;
      } else {
        console.log(`requestDetailProfile failed ${res}`);
        return undefined;
      }
    } catch (error) {
      console.error("Error requestDetailProfile:", error);
      throw error;
    }
  }

  async function requestEditProfile(): Promise<void> {
    const url = "/pw/users/editProfile";
    const requestBody = {
      uuid: localStorage.getItem("uuid"),
      birthday: updateBirthDay(uiState.day, uiState.month, uiState.year),
      addressCountry: uiState.country,
      addressProvince: uiState.cityCountyDistrict,
      addressDetail: uiState.cityDetail,
      phoneNumber: uiState.phoneNumber,
      countryNumber: uiState.localNumber,
      gender: uiState.gender,
      tribeName: uiState.tribeName,
      tribeCategory: uiState.tribeCategory,
    };

    try {
      const res = await ApiService.post(url, requestBody);
      console.log("PROFILE", res);
      if (res) {
        uiEventHandler.ChangeCompleteDialogState(true);
      } else {
        uiEventHandler.ChangeCompleteDialogState(false);
      }
    } catch (error) {
      throw error;
    }
  }
  const getBirthDay = (birth: number): string => {
    if (birth == 0) {
      return "";
    }
    const date = new Date(birth);
    return date.getDate().toString();
  };

  const getBirthMonth = (birth: number): string => {
    if (birth == 0) {
      return "";
    }
    const date = new Date(birth);
    const month = date.getMonth() + 1;
    return month.toString();
  };

  const getBirthYear = (birth: number): string => {
    if (birth == 0) {
      return "";
    }
    const date = new Date(birth);
    return date.getFullYear().toString();
  };

  const updateBirthDay = (day: string, month: string, year: string) => {
    const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
    console.log("BirthDay - Date", date);
    const time = date.getTime();
    if (time == 0) {
      return 1;
    } else {
      return time;
    }
  };

  return (
    <>
      <AdditionalInfoRender
        uiState={uiState}
        uiEvent={uiEventHandler}
        sideEffect={sideEffectHandler}
      />
    </>
  );
};

export default AdditionalInfoPage;
