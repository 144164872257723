import { getLocale, LANGUAGE_CODE_STORAGE_KEY } from "../customHook/useLocale";

export enum DateFormatType {
  YearMonthDayWeek,
  YearMonthDayTime,
  YearMonthDay,
  YearMonth,
  MonthDayWeek,
  AdminType,
  numeric,
  numericTime,
}

export interface LocalizedDateProps {
  // 백엔드에서 넘겨주는 Date 값은 long 타입의 숫자값
  date: any;
  // 표현 하고픈 데이터 타입
  type: DateFormatType;
  nationTimeZone?: string; // 언어 코드 (외부에서 주입 가능)
}

const LocalizedDate = ({ date, type, nationTimeZone }: LocalizedDateProps) => {
  const languageCode = localStorage.getItem(LANGUAGE_CODE_STORAGE_KEY);
  const formatCountry = languageCode === "en" || languageCode === "km" ? "en-GB" : "ko-KR";
  const locale = type === DateFormatType.AdminType ? "en-GB" : formatCountry;

  const validDate = new Date(date);

  if (isNaN(validDate.getTime())) {
    return "";
  }

  const formattedDate = new Intl.DateTimeFormat(locale, {
    ...getDateOptions(type, formatCountry),
    timeZone: nationTimeZone, // 캄보디아 시간대를 명시적으로 설정
  }).format(validDate);

  if (formattedDate == "Invalid Date") {
    return "";
  } else {
    return formattedDate;
  }
};

const getDateOptions = (type: DateFormatType, formatCountry: string): Intl.DateTimeFormatOptions => {
  const options: Intl.DateTimeFormatOptions = {};

  switch (type) {
    case DateFormatType.YearMonthDayWeek:
      options.weekday = formatCountry === "ko-KR" ? "long" : "short";
      options.day = "2-digit";
      options.month = "short";
      options.year = "numeric";
      break;
    case DateFormatType.YearMonthDayTime:
      options.day = "2-digit";
      options.month = "short";
      options.year = "numeric";
      options.hour = "2-digit";
      options.minute = "2-digit";
      options.hour12 = false;
      break;
    case DateFormatType.YearMonthDay:
      options.day = formatCountry === "ko-KR" ? "numeric" : "2-digit";
      options.month = formatCountry === "ko-KR" ? "numeric" : "short";
      options.year = "numeric";
      break;
    case DateFormatType.YearMonth:
      options.month = "short";
      options.year = "numeric";
      break;
    case DateFormatType.MonthDayWeek:
      options.weekday = "long";
      options.day = "2-digit";
      options.month = "short";
      break;
    case DateFormatType.AdminType:
      options.day = "2-digit";
      options.month = "short";
      options.year = "numeric";
      options.hour = "2-digit";
      options.minute = "2-digit";
      options.hour12 = false;
      break;
    case DateFormatType.numeric:
      options.day = "numeric";
      options.month = "numeric";
      options.year = "numeric";
      break;
    case DateFormatType.numericTime:
      options.day = "numeric";
      options.month = "numeric";
      options.year = "numeric";
      options.hour = "2-digit";
      options.minute = "2-digit";
      options.second = "2-digit";
      options.hour12 = false;
      break;

    default:
      break;
  }

  return options;
};

export default LocalizedDate;
