// RouteConstants.js

export const ROUTE_PATHS = {
  // ============ Login ============
  ADMIN_LOGIN: "/login*",
  ADMIN_LOGOUT: "/logout",
  ADMIN_LOGOUT_CALLBACK: "/logout/logout",
  APP_LOGIN: "/app_login",
  LOGIN_LANGUAGE: "/loginlanguage",
  LOGIN_MOBILE: "/loginmobile",
  LOGIN: "/ExistingAccount",
  WITHDRAWAL_INFO: "/withdrawal_info",

  LOGIN_TERMS_OF_USE: "/LoginTermsOfUse",
  LOGIN_TERMS_OF_USE_PRIVACY_DETAILS: "/LoginTermsOfUsePrivacyDetails",
  LOGIN_TERMS_OF_USE_DETAILS: "/LoginTermsOfUseDetails",
  PHONE_AUTH: "/phone_auth",
  LOGIN_DETAIL_INFO: "/LoginDetailInfo",
  LOGIN_FINISH_PAGE: "/LoginFinishPage",
  LOGIN_SERVICE_COUNTRY: "/login_service_country",

  CERT_ACCOUNT: "/certaccount",
  FIND_ACCOUNT: "/findaccount",

  // ============ Logout ============
  LOGOUT_MOBILE: "/LogoutMobile",
  LOGOUT_MOBILE_CALLBACK: "/LogoutMobile/logout",

  // ============ My Ride ============
  MY_RIDE: "/MyRide",
  SCOOTER_WAITING: "/ScooterWating",
  RIDING_REPORT: "/RidingReport",
  WARRANTY: "/Warranty",
  MY_SCOOTER_CONTRACT_LIST: "/MyScooterContractListScreen",
  BATTERY_STATION_USAGE_GUIDE: "/battery_station_usage_guide",
  ADDITIONAL_INFO: "/additional_info",

  // ============ Battery ============
  BATTERY: "/Battery",

  // ============ Map ============
  MAP: "/PWUserAppMap",
  MOBILE_HOME: "/mobile_home",

  // ============ Support ============
  SUPPORT: "/Support",
  FAQ_LIST: "/FAQList",
  FAQ_DETAIL: "/FAQDetail",
  INQUIRY_PAGE: "/InquiryPage",
  INQUIRY_DETAIL: "/InquiryDetail",
  INQUIRY_REGISTRATION: "/inquiry_registration",
  NOTICE: "/Notice",
  NOTICE_DETAIL: "/NoticeDetail",
  CS_LIST: "/cs_list",
  CUSTOMER_SUPPORT_DETAIL: "/cs_detail",
  CUSTOMER_SUPPORT_REGISTRATION: "/customer_support_registration",

  // ============ More ============
  MORE_PAGE: "/MorePage",
  PROFILE: "/Profile",
  EDIT_PROFILE: "/EditProfile",
  EDIT_PROFILE_PHONE_AUTH: "/MoreProfilePhoneAuth",
  WITHDRAWAL: "/Withdrawal",
  WITHDRAWAL_FINISH: "/WithdrawalFinish",
  PARTNERS: "/Partners",
  PARTNERS_DETAIL: "/PartnersDetail",

  // ============ Carbon Reduction NFT ============
  CARBON_REDUCTION_NFT: "/CarbonReductionNFT",
  PICK_NFT_CHARACTER: "/PickNFTChar",
  NFT_GRADE_PAGE: "/NFTGradePage",
  COUPON_HIS_PAGE: "/CouponHistory",

  // ============ Charging Membership ============
  CHARGING_MEMBERSHIP: "/ChargingMembership",
  PAY_QR: "/PayQR",
  PAY_RESTRICT_PAGE: "/PayRestrictPage",
  MEMBERSHIP_PURCHASE: "/MembershipPurchase",
  MEMBERSHIP_OVERVIEW: "/MembershipOverview",
  PURCHASE_HISTORY: "/PurchaseHistory",
  USAGE_HISTORY: "/usage_history",

  // ============ Wallet ============
  WALLET: "/Wallet",
  NFT_DETAIL: "/NFTDetail",
  ISSUED_HISTORY: "/IssuedHistory",

  // ============ Settings ============
  SETTING: "/Setting",
  NOTIFICATION: "/Notification",
  LANGUAGE: "/Language",
  TERMS_OF_SERVICE: "/terms_of_service",
  LOCATION_TERMS_OF_SERVICE: "/location_terms_of_service",
  BATTERY_TERMS_OF_SERVICE: "/battery_terms_of_service",
  PRIVACY_POLICY: "/privacy_policy",
  SERVICE_COUNTRY: "/service_country",

  // ============ Test Pages ============
  MOBILE_TEST: "/MobileTest",

  MAINTENANCE: "/Maintenance",
};
