export class MaterialsData {
  id: string;
  no: number;
  level: number;
  category: string;
  partsCode: string;
  productName: string;
  spec: string;
  quantities: string;
  amount: number;
  company: string;

  constructor(
    id: string,
    no: number,
    level: number,
    category: string,
    partsCode: string,
    productName: string,
    spec: string,
    quantities: string,
    amount: number,
    company: string
  ) {
    this.id = id;
    this.no = no;
    this.level = level;
    this.category = category;
    this.partsCode = partsCode;
    this.productName = productName;
    this.spec = spec;
    this.quantities = quantities;
    this.amount = amount;
    this.company = company;
  }
}

export enum MaterialType {
  NONE = "None",
  SERIAL_NUMBER = "Serial Number",
  MANUAL_COUNT = "Manual Count",
  MEMO = "Memo",
}
