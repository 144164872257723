import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { GridRowsProp } from "@mui/x-data-grid";
import CommonList from "../../component/CommonList";
import CommonSearch from "../../component/CommonSearch";
import { fontStyle } from "../../theme/Style";
import { color } from "../../theme/Color";
import VerticalSpacer, { HorizontalSpacer } from "../../component/Spacer";
import { ApiService } from "../../restAPI/ApiService";
import CsMngGrid from "./section/CsMngListGrid";
import CSMngTable from "./section/CsMngTable";

const CsMngPage = () => {
  // 페이지네이션 기능을 위한 변수
  const [rowsPerPage, setRowsPerPage] = useState(10); // 한 테이블당 보여줄 row 의 갯수
  const [rows, setRows] = useState([]); // 받아온 데이터
  const [page, setPage] = useState<number>(0); // 현재 페이지의 번호
  const [docCount, setDocCount] = useState<number>(0); // 총 row의 수
  const [pageCount, setPageCount] = useState(0); // 테이블 왼쪽 하단에 보여질 페이지의 갯수

  // 검색어 관련 변수
  const [searchContents, setSearchContents] = useState("");

  // 상태 관련 변수
  const [status, setStatus] = useState("");
  const handleShowHideChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value as string);
  };

  // Station 관련 변수
  const [stationList, setStationList] = useState([]);
  const [station, setStation] = useState("Station");
  const [stationId, setStationId] = useState(0);
  const handleStationChange = (event: SelectChangeEvent) => {
    console.log(event.target);
    if (event.target.value[0] == "") {
      setStationId(0);
    } else {
      setStationId(parseInt(event.target.value[0]));
    }
    setStation(event.target.value[1] as string);
  };

  useEffect(() => {
    setPage(0);
  }, [searchContents, status, station]);

  // 서버에 select 목록들 가져오기
  useEffect(() => {
    ApiService.WebPost("/pw/backend/api/svc/cs_center_list", {
      location: true,
    }).then((res) => {
      console.log(res?.data.body);
      setStationList(res?.data.body || []);
    });
  }, []);

  // 서버에 filter된 리스트들 가져오기
  useEffect(() => {
    ApiService.WebPost("/pw/aw/contents/cs/filter", {
      size: rowsPerPage,
      page: page + 1,
      searchContents: searchContents,
      station: stationId,
      status: status,
    }).then((res) => {
      setRows(res?.data.body);
      setPageCount(Math.ceil(res?.data.docCount / rowsPerPage));
      setDocCount(res?.data.docCount);
    });
  }, [page, rowsPerPage, stationId, status]);

  return (
    <CommonList title="CS">
      <CommonSearch
        placeHolder={"Title, Context, Reporter, Writer"}
        setSearchContents={setSearchContents}
        onKeyPress={() => {
          ApiService.WebPost("/pw/aw/contents/cs/filter", {
            size: rowsPerPage,
            page: page + 1,
            searchContents: searchContents,
            station: stationId,
            status: status,
          }).then((res) => {
            setRows(res?.data.body);
            setPageCount(Math.ceil(res?.data.docCount / rowsPerPage));
            setDocCount(res?.data.docCount);
          });
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Select
            value={status}
            onChange={handleShowHideChange}
            displayEmpty
            renderValue={(v) => (v?.length ? v : `Status`)}
            sx={{
              width: "auto",
              height: "42px",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&.MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "red",
                },
                "&:hover fieldset": {
                  borderColor: "yellow",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
              },
              bgcolor: "white",
              borderRadius: "10px",
              fontStyle: fontStyle.semiboldXS,
              color: color.gray600,
            }}
          >
            <MenuItem value={""} sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}>
              all
            </MenuItem>
            <MenuItem value={"Complete"} sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}>
              Complete
            </MenuItem>
            <MenuItem value={"Deleted"} sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}>
              Deleted
            </MenuItem>
            <MenuItem value={"Receipt"} sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}>
              Receipt
            </MenuItem>
            <MenuItem value={"Reservation"} sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}>
              Reservation
            </MenuItem>
          </Select>
          <HorizontalSpacer width={12} />
          <Select
            value={station}
            onChange={handleStationChange}
            displayEmpty
            renderValue={(v) => (v?.length ? v : `Station`)}
            sx={{
              width: "auto",
              height: "42px",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&.MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "red",
                },
                "&:hover fieldset": {
                  borderColor: "yellow",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
              },
              bgcolor: "white",
              borderRadius: "10px",
              fontStyle: fontStyle.semiboldXS,
              color: color.gray600,
            }}
          >
            <MenuItem value={["", "Station"]} sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}>
              all
            </MenuItem>
            {stationList.map((data: any, index: any) => {
              return (
                <MenuItem value={[data.svcId, data.svcNm]} key={index} sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}>
                  {data.svcNm}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
      </CommonSearch>
      <VerticalSpacer height={16} />
      <CSMngTable
        mngRows={rows}
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </CommonList>
  );
};

export default CsMngPage;
