import { Box } from "@mui/material";
import {
  BANNER_PREVIEW,
  BANNER_PREVIEW2,
} from "../../../../constants/imagePath";

import { color } from "../../../../theme/Color";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { BannerManagementUiEvent } from "../model/BannerManagementUiEvent";
import {
  BannerManagementUiState,
  showImageType,
} from "../model/BannerManagementUiState";
import BannerPreviewImage from "./BannerPreviewImage";

interface BannerPreviewProps {
  uiEvent: BannerManagementUiEvent;
  uiState: BannerManagementUiState;
  data: showImageType[];
}

const BannerPreview = ({ uiEvent, data, uiState }: BannerPreviewProps) => {
  return (
    <Box
      sx={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 2000,
        backgroundColor: "rgba(0, 0, 0, 0.3)", // 반투명 검정 배경
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        onClick={() => {
          uiEvent.HandleUiStateChange("bannerPreview", false);
        }}
        sx={{
          position: "absolute",
          top: "64px", // 화면 상단에서 20px
          right: "48px", // 화면 오른쪽에서 20px
          width: "56px", // 버튼 크기
          height: "56px", // 버튼 크기
          backgroundColor: color.white, // 흰색 배경
          borderRadius: "100px", // 원형 버튼
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        ✖
      </Box>
      <Box
        sx={{
          position: "relative", // 상대적 위치로 박스 배치
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={BANNER_PREVIEW}
          alt="Banner Preview"
          style={{
            width: "362px",
            height: "756px",
            borderRadius: "40px",
            marginRight: "30px",
          }}
        />
        <img
          src={BANNER_PREVIEW2}
          alt="Banner Preview"
          style={{
            width: "362px",
            height: "756px",
            borderRadius: "40px",
          }}
        />
        {/* 중앙에 274x340 크기의 박스 */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            position: "absolute",
            top: "18.8%", // 이미지의 세로 중앙에 배치
            left: "50%", // 이미지의 가로 중앙에 배치
            transform: "translate(-50%, -50%)", // 정확한 중앙 정렬
            width: "700px", // 박스의 가로 크기
            height: "82px", // 박스의 세로 크기
            zIndex: 1000, // 이미지 위로 배치
          }}
        >
          <BannerPreviewImage uiEvent={uiEvent} uiState={uiState} data={data} />
          <BannerPreviewImage uiEvent={uiEvent} uiState={uiState} data={data} />
        </Box>
      </Box>
    </Box>
  );
};

export default BannerPreview;
