import React from "react";
import { Box, SxProps, Theme } from "@mui/material";
import CommonDatePicker from "./CommonDatePicker";
import dayjs, { Dayjs } from "dayjs";

interface CommonDateRangeProps {
  value: { startDate: Dayjs | null; endDate: Dayjs | null }; // startDate와 endDate를 포함한 객체
  onChange: (newValue: { startDate: Dayjs | null; endDate: Dayjs | null }) => void; // 값 변경 핸들러
  disabled?: boolean; // disabled prop 추가
  sx?: SxProps<Theme>; // sx prop 추가
  InputPropsSx?: SxProps<Theme>; // sx prop 추가
  textFieldSx?: SxProps<Theme>; // sx prop 추가
  views?: Array<"year" | "month" | "day">; // views prop 추가
}

const CommonDateRange = ({
  value,
  onChange,
  disabled,
  InputPropsSx,
  textFieldSx,
  views, // 기본값 설정
}: CommonDateRangeProps) => {
  const handleStartDateChange = (newDate: Dayjs | null) => {
    onChange({
      startDate: newDate,
      endDate: value.endDate && newDate && newDate.isAfter(value.endDate) ? null : value.endDate,
    });
  };

  const handleEndDateChange = (newDate: Dayjs | null) => {
    onChange({
      startDate: value.startDate && newDate && newDate.isBefore(value.startDate) ? null : value.startDate,
      endDate: newDate,
    });
  };

  const today = dayjs();

  return (
    <Box display="flex" gap={2} flexDirection="row">
      <CommonDatePicker
        placeholder="Start Date"
        value={value.startDate}
        onChange={handleStartDateChange}
        views={views} // views prop 전달
        maxDate={value.endDate || today} // 종료 날짜보다 작아야 함
        textFieldSx={{
          width: "100%",
          ...textFieldSx,
        }}
        InputPropsSx={{
          ...InputPropsSx,
        }}
        disabled={disabled}
        clearable
      />
      <CommonDatePicker
        placeholder="End Date"
        value={value.endDate}
        onChange={handleEndDateChange}
        views={views} // views prop 전달
        minDate={value.startDate || today} // 시작 날짜 이후로 선택 제한
        textFieldSx={{
          width: "100%",
          ...textFieldSx,
        }}
        InputPropsSx={{
          ...InputPropsSx,
        }}
        disabled={disabled}
        clearable
      />
    </Box>
  );
};

export default CommonDateRange;
