import { initializeApp } from "firebase/app";
import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
} from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

// Firebase 초기화 설정
const firebaseConfig = {
  apiKey: "AIzaSyAdbuyNVVryMvoIzbOiFOVerOmt2lq_uWU",
  authDomain: "pople-b5a48.firebaseapp.com",
  projectId: "pople-b5a48",
  storageBucket: "pople-b5a48.firebasestorage.app",
  messagingSenderId: "878332985508",
  appId: "1:878332985508:web:a7306d8b4cc38d6f55a631",
  measurementId: "G-1CLJ2Y32PL",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider().setCustomParameters({
  prompt: "select_account",
});
// googleProvider.addScope("https://www.googleapis.com/auth/contacts.readonly");
const facebookProvider = new FacebookAuthProvider(app);
const appleProvider = new OAuthProvider("apple.com");
const analytics = getAnalytics(app);

export { auth, analytics, googleProvider, facebookProvider, appleProvider };
