import { TabType } from "./TabType";

export class CarbonReductionUiState {
  characterInfo: CharacterInfo[];
  coupon: CouponInfo[];
  reductionHistory: ReductionHistory[];
  reductionHistoryDay: ReductionHistoryDay[];

  totalCarbonReduction: number;

  historyYear: number;
  historyDay: number;

  currentTab: TabType;
  currentPage: number;
  visibleCoupon: number;
  constructor(
    characterInfo: CharacterInfo[] = [],
    coupon: CouponInfo[] = [],
    reductionHistory: ReductionHistory[] = [],
    reductionHistoryDay: ReductionHistoryDay[] = [
      {
        date: 0,
        start: 0,
        end: 0,
        mileage: 0,
        power: 0,
        carbonReduction: 0,
      },
    ],
    totalCarbonReduction: number = 0,
    historyYear: number = new Date().getFullYear(),
    historyDay: number = new Date().getTime(),
    currentTab: TabType = TabType.YEAR,
    currentPage: number = 0,
    visibleCoupon: number = 6
  ) {
    this.characterInfo = characterInfo;
    this.coupon = coupon;
    this.reductionHistory = reductionHistory;
    this.reductionHistoryDay = reductionHistoryDay;
    this.totalCarbonReduction = totalCarbonReduction;
    this.historyYear = historyYear;
    this.historyDay = historyDay;
    this.currentTab = currentTab;
    this.currentPage = currentPage;
    this.visibleCoupon = visibleCoupon;
  }
}

export interface CharacterInfo {
  image: string;
  level: string;
  id: string;
  carbonReduction: number;
  exp: number;
  maxExp: number;
  issueDate: number;
  completionDate: number;
}

export interface CouponInfo {
  name: string;
  content: string;
  availableDate: number;
  issuedDate: number;
  usedDate: number;
  status: string;
}

export interface ReductionHistory {
  label: string;
  value: number;
}

export interface ReductionHistoryDay {
  date: number;
  start: number;
  end: number;
  mileage: number;
  power: number;
  carbonReduction: number;
}
