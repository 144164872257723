export class ModelData {
  id: string;
  no: number;
  category: string;
  modelName: string;
  partsCode: string;
  productName: string;
  lastUpdateDate: number;
  registerDate: number;

  constructor(
    id: string,
    no: number,
    category: string,
    modelName: string,
    partsCode: string,
    productName: string,
    lastUpdateDate: number,
    registerDate: number
  ) {
    this.id = id;
    this.no = no;
    this.category = category;
    this.modelName = modelName;
    this.partsCode = partsCode;
    this.productName = productName;
    this.lastUpdateDate = lastUpdateDate;
    this.registerDate = registerDate;
  }
}

export const ModelList = Array.from({ length: 30 }, (_, index) => 
    new ModelData(
      "12345",
      index + 1,
      "ASSY",
      "BATTERY PACK",
      "VBP-220RGP",
      "BATTERY PACK",
      1716499200,
      1716499200
    )
  );
  