import { Card } from "@mui/material";
import { ProductConfirmationList } from "./ProductConfirmationList";
import { ProductBOMTree } from "./ProductBOMTree";
import { useEffect, useState } from "react";
import { FindInListUiState } from "./model/FindInListUiState";
import { FindInListUiEvent } from "./model/FindInListUiEvent";
interface FindInListProps {
  onSubmit?: (data: any) => void; // 부모에게 전달할 데이터의 타입 지정
}
const FindInList = ({ onSubmit }: FindInListProps) => {
  const [uiState, setUiState] = useState(new FindInListUiState());
  const uiEvent: FindInListUiEvent = {
    HandleUiStateChange: (key: keyof FindInListUiState, value: any) => {
      setUiState((prevState) => ({
        ...prevState,
        [key]: value, // key에 해당하는 상태를 업데이트
      }));
    },
  };
  useEffect(() => {
    if (onSubmit) {
      onSubmit(uiState);
    }
  }, [uiState]);
  return (
    <Card
      sx={{
        borderRadius: "15px",
        padding: "24px",
        boxShadow: 0,
      }}
    >
      <ProductConfirmationList uiState={uiState} uiEvent={uiEvent} />
      <ProductBOMTree uiState={uiState} uiEvent={uiEvent} />
    </Card>
  );
};

export default FindInList;
