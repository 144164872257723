import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import CommonList from "../../component/CommonList";
import { CommonWebHeaderButton } from "../../component/CommonWebHeaderButton";
import BatteryDetailCard from "./section/BatteryDetailCard";
import { BatteryDetailUiState } from "./model/BatteryDetailUiState";
import { BatteryDetailUiEvent } from "./model/BatteryDetailUiEvent";
import { ApiService } from "../../restAPI/ApiService";
import CommonDialog from "../../component/CommonDialog";
import { fontStyle } from "../../theme/Style";
import { ADMIN_CONFIG } from "../../constants/AdminConfig";

const BatteryDetail = () => {
  const [uiState, setUiState] = useState(new BatteryDetailUiState());
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const batterySerialNumber = pathname.split("/").pop();

  const uiEvent: BatteryDetailUiEvent = {
    HandleUiStateChange: (key: keyof BatteryDetailUiState, value: any) => {
      setUiState((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    },

    back: () => {
      uiEvent.HandleUiStateChange("backDialog", true);
    },

    BackDialogOk: () => {
      navigate("/batteryMng");
      window.location.reload();
    },
    BackDialogCancel: () => {
      uiEvent.HandleUiStateChange("backDialog", false);
      uiEvent.HandleUiStateChange("updateDialog", false);
    },

    update: () => {
      if (uiState.data.status !== "Defective" && uiState.status === "Defective") {
        uiEvent.HandleUiStateChange("updateDialog", true);
      }
    },
    updateDialogOk: () => {
      update();
      uiEvent.HandleUiStateChange("updateDialog", false);
      window.location.reload();
    },
  };

  useEffect(() => {
    ApiService.WebPost("/pw/backend/api/battery/detail", {
      battSerialNumber: batterySerialNumber,
    }).then((res) => {
      uiEvent.HandleUiStateChange("data", res?.data);
      uiEvent.HandleUiStateChange("status", res?.data.status);
      console.log("data: ", res?.data);
    });
  }, [uiState.battSerialNumber]);

  const update = () => {
    ApiService.WebPost("/pw/backend/api/battery/update", {
      battSerialNumber: batterySerialNumber,
      status: uiState.status,
    }).then((res) => {
      console.log("data", res?.data);
    });
  };
  return (
    <CommonList
      title="Battery Detail"
      headerBackButton
      backbuttonOnclick={uiEvent.back}
      headerButton={
        <CommonWebHeaderButton
          width="90px"
          height="45px"
          buttonContent="Update"
          sx={{ display: "flex", flexDirection: "column" }}
          onClick={uiEvent.update}
        />
      }
    >
      <BatteryDetailCard uiState={uiState} uiEvent={uiEvent} />
      <CommonDialog open={uiState.backDialog} handleOk={uiEvent.BackDialogOk} handleClose={uiEvent.BackDialogCancel}>
        <Typography fontStyle={fontStyle.regularS}>
          {ADMIN_CONFIG.MSG.EDIT_STOP_AND_MOVE}<br/>
          {ADMIN_CONFIG.MSG.EDIT_STOP_NOT_SAVED}
        </Typography>
      </CommonDialog>
      <CommonDialog open={uiState.updateDialog} handleOk={uiEvent.updateDialogOk} handleClose={uiEvent.BackDialogCancel} sx={{ height: "224px" }}>
        <Typography fontStyle={fontStyle.regularS}>
          Changes cannot be modified after this.
          <br />
          Do you want to change the battery status as <br />
          follows?
          <br />
          <br />
          Normal → Defective
        </Typography>
      </CommonDialog>
    </CommonList>
  );
};
export default BatteryDetail;
