import { TableCell, TableRow, Typography } from "@mui/material";
import { fontStyle } from "../../../../../../theme/Style";
import CommonTable, { useVisibleRows } from "../../../../../../component/CommonTable";
import { color } from "../../../../../../theme/Color";

interface RowProps {
  row: any;
  index: any;
}

const Row = ({ row, index }: RowProps) => {
  return (
    <>
      <TableRow sx={{ cursor: "pointer" }}>
        <TableCell align="center" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.projectName}</Typography>
        </TableCell>

        <TableCell align="center" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.country}</Typography>
        </TableCell>

        <TableCell align="center" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.province}</Typography>
        </TableCell>

        <TableCell align="center" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.totalScooterDistance}</Typography>
        </TableCell>

        <TableCell align="center" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.scooterSN}</Typography>
        </TableCell>

        <TableCell align="center" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.totalStationGrid}</Typography>
        </TableCell>

        <TableCell align="center" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.baselineEmissions}</Typography>
        </TableCell>
        <TableCell align="center" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.scooterEmissions}</Typography>
        </TableCell>
        <TableCell align="center" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.registrationDate}</Typography>
        </TableCell>
      </TableRow>
    </>
  );
};

interface GHGReductionTabTableProps {
  rows?: any;
  docCount?: number;
  rowsPerPage?: any;
  pageCount?: any;
  page?: any;
  setPage?: any;
  setRowsPerPage?: any;
}

const GHGReductionTabTable = ({ rows, docCount, rowsPerPage, pageCount, page, setPage, setRowsPerPage }: GHGReductionTabTableProps) => {
  const headCells = [
    {
      id: "projectName",
      align: "center",
      disablePadding: false,
      label: "Project Name",
    },
    {
      id: "country",
      align: "left",
      disablePadding: false,
      label: "Country",
    },
    {
      id: "province",
      align: "left",
      disablePadding: false,
      label: "City/State/Province",
    },
    {
      id: "totalScooterDistance",
      align: "left",
      disablePadding: false,
      label: "Total Scooter Distance(km)",
    },
    {
      id: "totalBatteryPowerUsage",
      align: "left",
      disablePadding: false,
      label: "Total Battery Power Usage",
    },
    {
      id: "totalStationGrid",
      align: "left",
      disablePadding: false,
      label: "Total Station Grid",
    },

    {
      id: "baselineEmissions",
      align: "left",
      disablePadding: false,
      label: "Baseline Emissions",
    },

    {
      id: "scooterEmissions",
      align: "left",
      disablePadding: false,
      label: "Scooter Emissions",
    },
    {
      id: "stationEmissions",
      align: "left",
      disablePadding: false,
      label: "Station Emissions",
    },
    {
      id: "GHGReductions",
      align: "left",
      disablePadding: false,
      label: "GHG Reductions",
    },
  ];

  const { order, orderBy, handleRequestSort, handleChangePage, visibleRows } = useVisibleRows(rows, rowsPerPage, setPage, page);

  return (
    <CommonTable
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      page={page}
      docCount={docCount}
      pageCount={pageCount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      handleRequestSort={handleRequestSort}
      handleChangePage={handleChangePage}
      setPage={setPage}
      noneRowPerPage={true}
    >
      {visibleRows?.length == 0 ? (
        <TableRow sx={{ px: " 4px", height: "1px" }}>
          <TableCell
            colSpan={headCells?.length}
            align="center"
            sx={{
              minWidth: "1032px",
              px: "13px",
              py: "12px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontStyle: fontStyle.regularS,
                color: color.gray600,
              }}
            >
              No Data
            </Typography>
          </TableCell>
        </TableRow>
      ) : (
        visibleRows.map((row: any, index: any) => <Row row={row} index={index} key={index} />)
      )}
    </CommonTable>
  );
};

export default GHGReductionTabTable;
