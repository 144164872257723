import { styled, TableCell, TableRow, Typography } from "@mui/material";
import CommonSearch from "../../../component/CommonSearch";
import CommonTable, {
  HeadCell,
  useVisibleRows,
} from "../../../component/CommonTable";
import { WalletUiState } from "./model/WalletUiState";
import VerticalSpacer from "../../../component/Spacer";
import LocalizedDate, {
  DateFormatType,
} from "../../../component/LocalizedDate";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import { WalletUiEvent } from "./model/WalletUiEvent";

interface WalletPageProps {
  uiState: WalletUiState;
  uiEvent: WalletUiEvent;
}
const WalletTableCell = styled(TableCell)(({ theme }) => ({
  height: "26px",
  padding: "12px",
}));

export function WalletPage({ uiState, uiEvent }: WalletPageProps) {
  const { order, orderBy, handleRequestSort, handleChangePage, visibleRows } =
    useVisibleRows(
      uiState.walletInfo,
      uiState.rowsPerPage,
      (newPage: number) => uiEvent.handleUiStateChange("page", newPage),
      uiState.page
    );
  return (
    <>
      <CommonSearch
        placeHolder={"Station Name"}
        searchContents={uiState.searchContents}
        setSearchContents={(newSearchContents: string) =>
          uiEvent.handleUiStateChange("searchContents", newSearchContents)
        }
      ></CommonSearch>
      <VerticalSpacer height={16} />
      <CommonTable
        headCells={walletHeadCells}
        docCount={uiState.docCount}
        page={uiState.page}
        pageCount={uiState.pageCount}
        rowsPerPage={uiState.rowsPerPage}
        setRowsPerPage={(newRowsperPage: number) =>
          uiEvent.handleUiStateChange("rowsPerPage", newRowsperPage)
        }
        setPage={(newPage: number) =>
          uiEvent.handleUiStateChange("page", newPage)
        }
        order={order}
        orderBy={orderBy}
        handleChangePage={handleChangePage}
        handleRequestSort={handleRequestSort}
      >
        {visibleRows.map(
          (data: { [x: string]: string | number }, index: number) => (
            <TableRow key={index} sx={{ height: "50px" }}>
              <WalletTableCell sx={{ paddingLeft: "24px" }}>
                <Typography
                  fontStyle={fontStyle.regularXS}
                  color={color.gray800}
                  sx={{ width: "36px" }}
                >
                  {data.no}
                </Typography>
              </WalletTableCell>
              <WalletTableCell
                sx={{ cursor: "pointer" }}
                onClick={() => uiEvent.onClick(data.id)}
              >
                <Typography
                  fontStyle={fontStyle.regularXS}
                  color={color.gray800}
                  sx={{ width: "264px" }}
                >
                  {data.address}
                </Typography>
              </WalletTableCell>
              <WalletTableCell>
                <Typography
                  fontStyle={fontStyle.regularXS}
                  color={color.gray800}
                  sx={{ width: "100px" }}
                >
                  {data.stationName}
                </Typography>
              </WalletTableCell>
              <WalletTableCell>
                <Typography
                  fontStyle={fontStyle.regularXS}
                  color={color.gray800}
                  sx={{ width: "272px" }}
                >
                  {data.balance}
                </Typography>
              </WalletTableCell>
              <WalletTableCell>
                <Typography
                  fontStyle={fontStyle.regularXS}
                  color={color.gray800}
                  sx={{ width: "72px" }}
                >
                  {data.txCount}
                </Typography>
              </WalletTableCell>
              <WalletTableCell sx={{ paddingRight: "24px" }}>
                <Typography
                  fontStyle={fontStyle.regularXS}
                  color={color.gray800}
                  sx={{ width: "120px" }}
                >
                  {LocalizedDate({
                    date: data.lastUpdateDate,
                    type: DateFormatType.numericTime,
                  })}
                </Typography>
              </WalletTableCell>
            </TableRow>
          )
        )}
      </CommonTable>
    </>
  );
}

const walletHeadCells: HeadCell[] = [
  {
    disablePadding: false,
    align: "left",
    id: "no",
    label: "No",
  },
  {
    disablePadding: false,
    align: "left",
    id: "address",
    label: "Address",
  },
  {
    disablePadding: false,
    align: "left",
    id: "stationName",
    label: "Station Name",
  },
  {
    disablePadding: false,
    align: "left",
    id: "balance",
    label: "Balance",
  },
  {
    disablePadding: false,
    align: "left",
    id: "txCount",
    label: "TxCount",
  },
  {
    disablePadding: false,
    align: "left",
    id: "lastupdateDate",
    label: "Last Update Date",
  },
];
