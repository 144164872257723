export class NFTUiState {
  nftInfo: NFTInfo[];
  searchContents: string;
  docCount: number;
  rowsPerPage: number;
  page: number;
  pageCount: number;
  constructor(
    nftInfo: NFTInfo[] = [],
    searchContents: string = "",
    docCount: number = 0,
    rowsPerPage: number = 10,
    page: number = 0,
    pageCount: number = 0
  ) {
    this.nftInfo = nftInfo;
    this.searchContents = searchContents;
    this.docCount = docCount;
    this.rowsPerPage = rowsPerPage;
    this.page = page;
    this.pageCount = pageCount;
  }
}

export interface NFTInfo {
  no: number;
  nftImage: string;
  nftType: string;
  symbol: string;
  nftId: string;
  issuer: number;
  issueDate: number;
  lastUpdatedDate: number;
}
