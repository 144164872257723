import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Card, CardContent, Grid, MenuItem, Select, TextField, Typography, Radio, SelectChangeEvent } from "@mui/material";
import VerticalSpacer from "../../../component/Spacer";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import { ApiService } from "../../../restAPI/ApiService";
import CommonDialog from "../../../component/CommonDialog";
import CommonTextField from "../../../component/CommonTextField";
import CommonMultiLineTextField from "../../../component/CommonMultiLineTextField";

interface FAQCreateGridProps {
  createDialogOpen: any;
  setCreateDialogOpen: any;
}

const FAQCreateGrid = ({ createDialogOpen, setCreateDialogOpen }: FAQCreateGridProps) => {
  const navigate = useNavigate();
  // title 관련
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  // content 관련
  const [content, setContent] = useState("");
  const [contentError, setContentError] = useState(false);
  const handleContentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContent(event.target.value);
  };

  // country 관련
  const [countryList, setCountryList] = useState([]);
  const [country, setCountry] = useState("Country");
  const [countryCode, setCountryCode] = useState("");
  const [countryCodeError, setCountryCodeError] = useState(false);
  const handleCountryChange = (event: SelectChangeEvent) => {
    setCountry(event.target.value[0] as string);
    setCountryCode(event.target.value[1] as string);
  };

  // category 관련
  const [categoryList, setCategoryList] = useState([]);
  const [category, setCategory] = useState("Category");
  const [categoryError, setCategoryError] = useState(false);
  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };

  // showHide 관련
  const [showHide, setShowHide] = useState<boolean>(true);
  const handleChange = (event: boolean) => {
    setShowHide(event);
  };

  // API로부터 Select 데이터 가져오기 (Country, Category)
  useEffect(() => {
    ApiService.WebPost("/pw/aw/contents/getCountry").then((res) => {
      setCountryList(res?.data.body);
    });
    ApiService.WebPost("/pw/aw/contents/faq/getCategory").then((res) => {
      setCategoryList(res?.data.body);
    });
  }, []);

  // 다이얼로그 닫기
  const handleClose = () => {
    setCreateDialogOpen(false);
  };

  // 데이터 검증 및 API 호출
  const handleOk = () => {
    let hasError = false;

    // 각 필드에 대한 데이터 검증
    if (title.length <= 0) {
      setTitleError(true);
      hasError = true;
      setCreateDialogOpen(false);
    } else {
      setTitleError(false);
    }

    if (content.length <= 0) {
      setContentError(true);
      hasError = true;
      setCreateDialogOpen(false);
    } else {
      setContentError(false);
    }

    console.log(countryCode);
    if (countryCode === "") {
      setCountryCodeError(true);
      hasError = true;
      setCreateDialogOpen(false);
    } else {
      setCountryCodeError(false);
    }

    if (category === "Category") {
      setCategoryError(true);
      hasError = true;
      setCreateDialogOpen(false);
    } else {
      setCategoryError(false);
    }

    // 데이터 검증이 통과되었을 때만 API 호출
    if (!hasError) {
      ApiService.WebPost("/pw/aw/contents/faq/create", {
        country: countryCode,
        showHide: showHide,
        category: category,
        title: title,
        uuid: "gJtIDJEBTozLt71O9iDr",
        content: content,
      })
        .then((res) => {
          console.log(res?.data);
          setCreateDialogOpen(false); // 다이얼로그 닫기
        })
        .then(() => {
          navigate("/FAQMng");
          window.location.reload();
        });
    }
  };

  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75} sx={{ mt: -5 }}>
      <Grid item lg={8}>
        <Card
          sx={{
            borderRadius: 3,
            width: "100%",
            display: "flex",
          }}
        >
          <CardContent
            sx={{
              width: "100%",
              padding: "0px",
              "&:last-child": {
                paddingBottom: 0,
              },
            }}
          >
            <Box
              sx={{
                mx: "24px",
                mt: "24px",
                mb: "40px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Title
                </Typography>
                <VerticalSpacer height={8} />
                <CommonTextField
                  value={title}
                  onChange={(event) => {
                    setTitle(event.target.value);
                  }}
                  sx={{ width: "100%", height: "48px" }}
                  InputProps={{
                    sx: {
                      height: "48px",
                    },
                  }}
                  error={titleError}
                />
                {titleError == true && (
                  <>
                    <VerticalSpacer height={4} />
                    <Typography fontStyle={fontStyle.regularXS} color={color.error}>
                      Please enter a title.
                    </Typography>
                  </>
                )}
              </Box>
              <VerticalSpacer height={40} />
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Contents
                </Typography>
                <VerticalSpacer height={8} />
                <CommonMultiLineTextField
                  placeholder="Please enter a description."
                  inputValue={content}
                  inputValueError={contentError === true}
                  heightPx={270}
                  maxLength={5000}
                  onChange={(value) => {
                    setContent(value);
                  }}
                  onClear={() => {
                    setContent("");
                  }}
                />
                {contentError == true && (
                  <>
                    <VerticalSpacer height={4} />
                    <Typography fontStyle={fontStyle.regularXS} color={color.error}>
                      Please enter up to 5000 characters.
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={4}>
        <Card
          sx={{
            borderRadius: 3,
            width: "100%",
            display: "flex",
          }}
        >
          <CardContent
            sx={{
              width: "100%",
              p: "24px",
              "&:last-child": {
                paddingBottom: "40px",
              },
            }}
          >
            <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
              Country
            </Typography>
            <Select
              value={country}
              error={countryCodeError === true}
              sx={{
                width: "100%",
                mt: 1,
                borderRadius: "12px",
              }}
              onChange={handleCountryChange}
              renderValue={(v) => {
                return v?.length ? v : null;
              }}
            >
              {countryList.map((data: any, index: any) => {
                return (
                  <MenuItem
                    value={[data.label, data.code]}
                    key={index}
                    sx={{
                      fontStyle: fontStyle.semiboldXS,
                      color: color.gray600,
                    }}
                  >
                    {data.label}
                  </MenuItem>
                );
              })}
            </Select>
            {countryCodeError == true && (
              <>
                <VerticalSpacer height={4} />
                <Typography fontStyle={fontStyle.regularXS} color={color.error}>
                  Please select a Country.
                </Typography>
              </>
            )}

            <VerticalSpacer height={40} />

            <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
              Category
            </Typography>
            <Select
              value={category}
              error={categoryError === true}
              sx={{
                width: "100%",
                mt: 1,
                borderRadius: "12px",
              }}
              onChange={handleCategoryChange}
              renderValue={(v) => {
                return v?.length ? v : null;
              }}
            >
              {categoryList.map((data: any, index: any) => {
                return (
                  <MenuItem key={index} value={data}>
                    {data}
                  </MenuItem>
                );
              })}
            </Select>
            {categoryError == true && (
              <>
                <VerticalSpacer height={4} />
                <Typography fontStyle={fontStyle.regularXS} color={color.error}>
                  Please select a category.
                </Typography>
              </>
            )}

            <VerticalSpacer height={40} />

            <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
              Show/Hide
            </Typography>
            <Box sx={{ display: "flex" }}>
              <Box>
                <Radio checked={showHide} onChange={() => handleChange(true)} value={true} />
                Show
              </Box>
              <Box sx={{ ml: 5 }}>
                <Radio checked={!showHide} onChange={() => handleChange(false)} value={false} />
                Hide
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>

      <CommonDialog open={createDialogOpen} handleClose={handleClose} handleOk={handleOk}>
        <Typography fontStyle={fontStyle.regularS} color={color.gray800}>
          Would you like to register the FAQ?
        </Typography>
      </CommonDialog>
    </Grid>
  );
};

export default FAQCreateGrid;
