import { TableRow } from "@mui/material";
import { RequestHistoryData } from "./model/RequestHistoryData";
import { RequestHistoryTableCell } from "./RequestHistoryTableCell";
import { Text } from "../../../../../component/Text";
import { fontStyle } from "../../../../../theme/Style";
import { color } from "../../../../../theme/Color";
import LocalizedDate, { DateFormatType } from "../../../../../component/LocalizedDate";

interface RequestHistoryTableRowProps {
  row: RequestHistoryData;
}

export function RequestHistoryTableRow({ row }: RequestHistoryTableRowProps) {
  return (
    <TableRow>
      <RequestHistoryTableCell
        sx={{ paddingLeft: "24px" }}
        childrenContainerSx={{ width: "36px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={`${row.no}`}
          />
        }
        align={"center"}
      />
      <RequestHistoryTableCell
        childrenContainerSx={{ width: "96px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={`${row.requestBranch}`}
          />
        }
        align={"center"}
      />
      <RequestHistoryTableCell
        childrenContainerSx={{ width: "96px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={`${row.branch}`}
          />
        }
        align={"center"}
      />
      <RequestHistoryTableCell
        // childrenContainerSx={{ width: "96px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={`${row.requester}`}
          />
        }
        align={"center"}
      />
      <RequestHistoryTableCell
        // childrenContainerSx={{ width: "96px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={`${row.updater}`}
          />
        }
        align={"center"}
      />
      <RequestHistoryTableCell
        childrenContainerSx={{ width: "79px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={`${row.status}`}
          />
        }
        align={"center"}
      />
      <RequestHistoryTableCell
        childrenContainerSx={{ width: "120px" }}
        sx={{ paddingRight: "24px" }}
        children={
          <Text
            sx={{
              fontStyle: fontStyle.regularXS,
              color: color.gray800,
            }}
            text={LocalizedDate({
              date: row.registerDate,
              type: DateFormatType.AdminType,
            })}
          />
        }
      />
    </TableRow>
  );
}
