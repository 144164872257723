import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import RequestCreateGrid from "./component/RequestCreateGrid";
import CommonList from "../../../../../component/CommonList";
import CommonDialog from "../../../../../component/CommonDialog";
import { ADMIN_CONFIG } from "../../../../../constants/AdminConfig";

const RequestCreatePage = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOk = () => {
    navigate("/requestManagement");
    window.location.reload();
  };
  const HeaderBackButton = () => {
    return (
      <IconButton
        sx={{
          ":hover": { backgroundColor: "#F2F2F2" },
          mb: 0.5,
          mr: 1,
        }}
        onClick={() => {
          handleClickOpen();
        }}
      >
        <ArrowBackIosNewIcon fontSize="medium" />
      </IconButton>
    );
  };

  const HeaderButton = () => {
    const handleCreate = () => {};

    return (
      <Button
        sx={{
          height: 40,
          backgroundColor: "#5D38E5",
          color: "white",
          borderRadius: 5,
          width: 110,
          ":hover": {
            backgroundColor: "#A38BFC",
          },
          textTransform: "none",
        }}
        onClick={handleCreate}
      >
        Register
      </Button>
    );
  };

  return (
    <CommonList title="Request Registration" headerButton={HeaderButton()} headerBackButton={HeaderBackButton()}>
      <CommonDialog open={open} handleClose={handleClose} handleOk={handleOk}>
        {ADMIN_CONFIG.MSG.EDIT_STOP_AND_MOVE}<br/>
        {ADMIN_CONFIG.MSG.EDIT_STOP_NOT_SAVED}
      </CommonDialog>
      <RequestCreateGrid />
    </CommonList>
  );
};

export default RequestCreatePage;
