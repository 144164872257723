import { Box, Card, Grid } from "@mui/material";
import CommonList from "../../../../../../../../component/CommonList";
import { CommonWebHeaderButton } from "../../../../../../../../component/CommonWebHeaderButton";
import FindPartList from "../../../../../../component/findPartList/FindPartList";

export function RegisterBillOfMaterials() {
  return (
    <CommonList
      title="자재 명세 등록"
      headerBackButton={true}
      navigateURL="/materialRegistration"
      headerButton={<CommonWebHeaderButton buttonContent="Register" height="45px" sx={{ display: "flex", flexDirection: "column" }} />}
    >
      <Grid item lg={12}>
        <FindPartList />
      </Grid>
    </CommonList>
  );
}
