import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Snackbar,
  SnackbarOrigin,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import CommonDatePicker from "../../../component/CommonDatePicker";
import CommonDialog from "../../../component/CommonDialog";
import { CommonSelect } from "../../../component/CommonSelect";
import VerticalSpacer, { HorizontalSpacer } from "../../../component/Spacer";
import CommonTextField from "../../../component/CommonTextField";
import CommonWebModal from "../../../component/CommonWebModal";
import { ICON_CLOSE, ICON_DOWNLOAD } from "../../../constants/imagePath";
import LocalizedDate, { DateFormatType } from "../../../component/LocalizedDate";
import { color } from "../../../theme/Color";
import { fontStyle } from "../../../theme/Style";
import { UserDetailsUiState } from "../UserDetailsUiState";
import { UserDetailsUiEvent } from "../UserDetailsUiEvent";
import dayjs, { Dayjs } from "dayjs";
import { ADMIN_CONFIG } from "../../../constants/AdminConfig";

interface State extends SnackbarOrigin {
  open: boolean;
}

interface UserDetailGridProps {
  uiState: UserDetailsUiState;
  uiEvent: UserDetailsUiEvent;
}

const currentYear = dayjs();

const UserDetailGrid = ({ uiState, uiEvent }: UserDetailGridProps) => {
  const [snackState, setSnackState] = useState<State>({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [statusState, setStatusState] = useState(false);

  useEffect(() => {
    if (uiState.Status == "PendingApproval") {
      setStatusState(false);
    } else if (uiState.Status != "PendingApproval") {
      setStatusState(true);
    }
  }, [uiState.Status]);

  const { vertical, horizontal, open } = snackState;

  const handleClick = (newState: SnackbarOrigin) => () => {
    setSnackState({ ...newState, open: true });
  };

  const handleClose = () => {
    setSnackState({ ...snackState, open: false });
  };

  const handleSendEmail = (email: string) => {
    handleClick({ vertical: "bottom", horizontal: "right" })();
    console.log(email);
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = event.target.files?.[0];
    if (uploadedFile) {
      const allowedExtensions = ["png", "jpg", "jpeg"];
      const fileExtension = uploadedFile.name.split(".").pop()?.toLowerCase();

      if (fileExtension && allowedExtensions.includes(fileExtension)) {
        uiEvent.HandleUiStateChange("IDCard", uploadedFile); // 파일 정보 저장
      } else {
        alert("Only PNG, JPG, and JPEG files are allowed.");
      }
    }
  };

  const [openModal, setOpenModal] = useState(false);
  const [fileUrl, setFileUrl] = useState<string | undefined>(undefined); // 초기 상태를 undefined로 설정
  const handleViewClick = () => {
    if (uiState.IDCard) {
      const Url = URL.createObjectURL(uiState.IDCard); // 파일 URL 생성
      console.log(Url);
      setFileUrl(Url);
      // window.open(fileUrl, "_blank"); // 새 창에서 이미지 보기
    }
    setOpenModal(true);
  };

  const [scale, setScale] = useState(1); // 확대 비율 상태
  const [position, setPosition] = useState({ x: 0, y: 0 }); // 이미지 위치 상태
  const [isDragging, setIsDragging] = useState(false); // 드래그 중인지 여부
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 }); // 드래그 시작 지점

  // 마우스 휠로 확대/축소
  const handleWheel = (event: React.WheelEvent) => {
    event.preventDefault();
    const zoomIntensity = 0.3;
    const newScale = event.deltaY < 0 ? scale + zoomIntensity : scale - zoomIntensity;
    setScale(Math.max(1, newScale)); // 최소 1배 축소까지 가능
  };

  // 마우스 클릭 시 드래그 시작
  const handleMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
    setIsDragging(true);
    setDragStart({
      x: event.clientX - position.x,
      y: event.clientY - position.y,
    });
  };

  // 드래그 중
  const handleMouseMove = (event: React.MouseEvent) => {
    if (isDragging) {
      const dx = (event.clientX - dragStart.x) / scale; // scale에 맞춰 이동량 조정
      const dy = (event.clientY - dragStart.y) / scale; // scale에 맞춰 이동량 조정

      setPosition({
        x: position.x + dx / 1.5, // 이동 속도 줄이기
        y: position.y + dy / 1.5, // 이동 속도 줄이기
      });

      setDragStart({
        x: event.clientX,
        y: event.clientY,
      });
    }
  };

  // 마우스를 떼면 드래그 종료
  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75} sx={{ mt: 0 }}>
      {uiState.DeleteAlert && (
        <Grid item lg={12}>
          <Box
            sx={{
              width: "100%",
              backgroundColor: color.errorBg,
              color: color.error,
              // height: "96px",
              borderRadius: "15px",
              border: 1,
              borderColor: color.error,
              display: "flex",
              flexDirection: "column",
              // alignItems: "center",
              justifyContent: "flex-start",
              px: "16px",
              py: "12px",
            }}
          >
            <Typography fontStyle={fontStyle.semiboldM}>
              Scheduled for deletion on{" "}
              {LocalizedDate({ date: uiState.LastModifiedDate + 30 * 24 * 60 * 60 * 1000, type: DateFormatType.numericTime })}
            </Typography>
            <Typography fontStyle={fontStyle.regularM}>
              Due to service policy, information from withdrawn/deleted accounts cannot be recovered 6 months after the withdrawal/deletion date.
            </Typography>
          </Box>
        </Grid>
      )}

      <Grid item lg={6}>
        <Card
          sx={{
            borderRadius: "15px",
            width: "100%",
            height: "auto",
            display: "flex",
          }}
        >
          <CardContent sx={{ width: "100%", m: 2 }}>
            <Box
              sx={{
                display: "flex",
                height: "100%",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Name
                </Typography>
                <CommonTextField
                  value={uiState.Name}
                  sx={{
                    width: "100%",
                    mt: "8px",
                    height: "48px",
                  }}
                  InputProps={{
                    style: {
                      height: "48px",
                      borderRadius: "10px",
                    },
                  }}
                  disabled
                />
              </Box>
              <VerticalSpacer height={40} />
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Email ID
                </Typography>
                <CommonTextField
                  value={uiState.Email}
                  sx={{
                    width: "100%",
                    mt: "8px",
                    height: "48px",
                  }}
                  InputProps={{
                    style: {
                      height: "48px",
                      borderRadius: "10px",
                    },
                  }}
                  disabled
                />
              </Box>
              <VerticalSpacer height={40} />
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Phone Number
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: 1,
                  }}
                >
                  <CommonSelect
                    selectValue={uiState.LocalNumber}
                    menuitems={["1", "82", "855"]}
                    onChange={(event) => {
                      uiEvent.HandleUiStateChange("LocalNumber", event.target.value);
                    }}
                    sx={{
                      height: "48px",
                      width: "18.5%",
                      fontStyle: fontStyle.regularS,
                      color: "black",
                    }}
                    error={uiState.LocalNumberError}
                  />

                  <CommonTextField
                    value={uiState.PhoneNumber}
                    onChange={(event) => {
                      uiEvent.HandleUiStateChange("PhoneNumber", event.target.value);
                    }}
                    sx={{ width: "78.5%", height: "48px" }}
                    InputProps={{
                      style: {
                        height: "48px",
                      },
                    }}
                    error={uiState.PhoneNumberError}
                  />
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={6}>
        <Card
          sx={{
            borderRadius: "15px",
            minHeight: 240,
            width: "100%",
            display: "flex",
          }}
        >
          <CardContent sx={{ width: "100%", m: 2 }}>
            <Box
              sx={{
                display: "flex",
                height: "100%",
                // minHeight: 330,
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              {/* Status */}
              <Box sx={{}}>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Status
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  {statusState == true && (
                    <Select
                      value={uiState.Status}
                      onChange={(event) => {
                        uiEvent.HandleUiStateChange("Status", event.target.value);
                      }}
                      size="small"
                      sx={{
                        width: "100%",
                        mt: 1,
                        height: "48px",
                        borderRadius: "10px",
                        "&.MuiOutlinedInput-root": {
                          // 테두리 속성
                          "& fieldset": {
                            borderColor: color.gray300,
                          },
                          "&:hover fieldset": {
                            borderColor: color.primary500,
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: color.primary500,
                            borderWidth: "1px",
                          },
                        },
                        fontStyle: fontStyle.regularS,
                      }}
                    >
                      <MenuItem value={ADMIN_CONFIG.PAGE_MEMBER.ACTIVE}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <FiberManualRecordIcon
                            sx={{
                              width: 12,
                              height: 12,
                              mr: 1.5,
                              color: "#43D697",
                            }}
                          />
                          {ADMIN_CONFIG.PAGE_MEMBER.ACTIVE}
                        </Box>
                      </MenuItem>
                      <MenuItem value={ADMIN_CONFIG.PAGE_MEMBER.BLOCKED}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <FiberManualRecordIcon
                            sx={{
                              width: 12,
                              height: 12,
                              mr: 1.5,
                              color: "red",
                            }}
                          />
                          {ADMIN_CONFIG.PAGE_MEMBER.BLOCKED}
                        </Box>
                      </MenuItem>
                      <MenuItem value={ADMIN_CONFIG.PAGE_MEMBER.DELETED}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <FiberManualRecordIcon
                            sx={{
                              width: 12,
                              height: 12,
                              mr: 1.5,
                              color: "gray",
                            }}
                          />
                          {ADMIN_CONFIG.PAGE_MEMBER.DELETED}
                        </Box>
                      </MenuItem>
                    </Select>
                  )}
                  {uiState.Status == "Blocked" && (
                    <CommonDatePicker
                      value={uiState.BlockEndDate ? dayjs(uiState.BlockEndDate) : null}
                      onChange={(date) => uiEvent.HandleUiStateChange("BlockEndDate", date?.valueOf())}
                      views={["day"]}
                      textFieldSx={{ width: "100%" }}
                      InputPropsSx={{ height: "48px", width: "100%", mt: 1 }}
                      placeholder="Select Date"
                      error={uiState.BlockEndDateError}
                      minDate={dayjs()}
                      // disabled
                    />
                  )}
                </Box>
              </Box>
              {/* Resident ID Card */}
              <VerticalSpacer height={40} />
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                    Resident ID Card
                  </Typography>
                  <Button
                    sx={{
                      height: "10px",
                      color: color.gray800,
                      textDecoration: "underline",
                      "&:hover": {
                        backgroundColor: "transparent",
                        color: color.gray600,
                        textDecoration: "underline",
                      },
                    }}
                    variant="text"
                    disableRipple
                    size="small"
                    component="label" // 파일 업로드 버튼
                  >
                    Update File
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg" // 허용 파일 타입
                      onChange={handleFileUpload}
                      hidden
                    />
                  </Button>
                </Box>

                <CommonTextField
                  // value={uiState.IDCard?.name || ""} // 파일 이름 표시
                  value={uiState.IDCard?.name ? uiState.IDCard?.name : uiState.IdCardImageFileName}
                  sx={{
                    width: "100%",
                    height: "48px",
                    mt: "8px",
                  }}
                  InputProps={{
                    style: {
                      height: "48px",
                      margin: 0,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        {/* <SearchIcon /> */}

                        <Box
                          sx={{
                            display: uiState?.idCardImageURL ? "flex" : uiState.IDCard ? "flex" : "none",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "60px",
                            height: "22px",
                            my: 0,
                            borderRadius: "22px",
                            fontStyle: fontStyle.semiboldXXS,
                            color: "white",
                            bgcolor: color.gray600,
                            cursor: "pointer",
                          }}
                          onClick={handleViewClick} // 클릭 시 이미지 미리보기
                        >
                          View
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </Box>
              {/* <VerticalSpacer height={40} /> */}

              {/* Type */}
              {/* <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Type
                </Typography>

                <CommonSelect
                  selectValue={uiState.Type}
                  menuitems={["General User", "Tour User"]}
                  onChange={(event) => {
                    uiEvent.HandleUiStateChange("Type", event.target.value);
                  }}
                  sx={{
                    height: "48px",
                    width: "100%",
                    mt: "8px",
                    fontStyle: fontStyle.regularS,
                    color: "black",
                  }}
                />
              </Box> */}
              {/* <VerticalSpacer height={40} /> */}
              {/* Group */}
              {/* <Box sx={{}}>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Group
                </Typography>
                <CommonSelect
                  selectValue={uiState.Group}
                  menuitems={
                    [
                      // "korea/seoul/gangnam",
                      // "korea/seoul/songpa",
                      // "korea/seoul/Seongbuk",
                    ]
                  }
                  onChange={(event) => {
                    uiEvent.HandleUiStateChange("Group", event.target.value);
                  }}
                  sx={{
                    height: "48px",
                    width: "100%",
                    mt: "8px",
                    fontStyle: fontStyle.regularS,
                    color: "black",
                  }}
                />
              </Box> */}
            </Box>
          </CardContent>
        </Card>
      </Grid>
      {/* Detail Grid */}
      <Grid item lg={12}>
        <Card
          sx={{
            borderRadius: "15px",
            minHeight: 240,
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardContent sx={{ width: "100%", height: "100%" }}>
            <CardHeader
              title={"Details"}
              titleTypographyProps={{ fontWeight: "bold", fontSize: 19 }}
              // sx={{ ml: -2, mb: 0 }}
            />
            <Box
              sx={{
                m: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* 첫번째 줄 */}
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Birth of Date</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      mt: 1,
                      justifyContent: "space-between",
                    }}
                  >
                    <CommonDatePicker
                      value={uiState.BirthOfDate !== null ? dayjs(uiState.BirthOfDate) : null}
                      onChange={(newDate: Dayjs | null) => {
                        uiEvent.HandleUiStateChange("BirthOfDate", newDate?.valueOf());
                      }}
                      views={["year", "month", "day"]}
                      textFieldSx={{ width: "100%" }}
                      InputPropsSx={{ height: "48px" }}
                    />
                  </Box>
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Gender</Typography>
                  <CommonSelect
                    selectValue={uiState.Gender}
                    menuitems={["Male", "Female"]}
                    onChange={(event) => {
                      uiEvent.HandleUiStateChange("Gender", event.target.value);
                    }}
                    sx={{
                      height: "48px",
                      width: "100%",
                      mt: "8px",
                      borderRadius: "10px",
                      fontStyle: fontStyle.regularS,
                      color: "black",
                    }}
                  />
                </Box>
              </Box>
              <VerticalSpacer height={40} />
              {/* 두번째 줄 */}
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Address</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      mt: 1,
                      justifyContent: "space-between",
                    }}
                  >
                    <CommonSelect
                      selectValue={uiState.CountryAddress}
                      menuitems={[
                        "Cambodia",
                        // , "Korea", "Usa"
                      ]}
                      onChange={(event) => {
                        uiEvent.HandleUiStateChange("CountryAddress", event.target.value);
                      }}
                      sx={{
                        height: "48px",
                        width: "48.5%",
                        mt: "8px",
                        borderRadius: "10px",
                        fontStyle: fontStyle.regularS,
                        color: "black",
                      }}
                    />

                    <CommonSelect
                      selectValue={uiState.CityAddress}
                      menuitems={[
                        "Siem Reap",
                        "Battambang",
                        "Banteay Meanchey",
                        "Phnom Penh",
                        "Kandal",
                        "Sihanoukville",
                        "Oddar Meanchey",
                        "Pursat",
                        "Kampong Thom",
                        "Kampong Speu",
                        "Svay Rieng",
                        "Takéo",
                        "Svay Rieng",
                        "Kampong Chhnang",
                        "Kampong Cham",
                        "Pailin",
                        "Prey Veng",
                        "Tboung Khmum",
                        "Kampot",
                        "Ratanakiri",
                        "Koh Kong",
                        "Kratié",
                        "Preah Vihear",
                        "Mondulkiri",
                        "Kep",
                        "Sihanoukville",
                        "other",
                      ]}
                      onChange={(event) => {
                        uiEvent.HandleUiStateChange("CityAddress", event.target.value);
                      }}
                      sx={{
                        height: "48px",
                        width: "48.5%",
                        mt: "8px",
                        borderRadius: "10px",
                        fontStyle: fontStyle.regularS,
                        color: "black",
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "48.5%",
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  <CommonTextField
                    value={uiState.DetailAddress}
                    onChange={(event) => {
                      uiEvent.HandleUiStateChange("DetailAddress", event.target.value);
                    }}
                    sx={{
                      width: "100%",
                      mt: "8px",
                      height: "48px",
                    }}
                    InputProps={{
                      style: {
                        height: "48px",
                        borderRadius: "10px",
                        // backgroundColor: color.gray200,
                        // color: color.gray600, // 비활성화 상태일 때 색상을 지정
                      },
                    }}
                  />
                </Box>
              </Box>
              <VerticalSpacer height={40} />
              {/* 세번째 줄 */}
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Tribe Category</Typography>
                  <CommonTextField
                    value={uiState.TribeCategory}
                    onChange={(event) => {
                      uiEvent.HandleUiStateChange("TribeCategory", event.target.value);
                    }}
                    sx={{
                      mt: "8px",
                      width: "100%",
                      height: "48px",
                    }}
                    InputProps={{
                      style: {
                        height: "48px",
                        borderRadius: "10px",
                        // backgroundColor: color.gray200,
                        // color: color.gray600, // 비활성화 상태일 때 색상을 지정
                      },
                    }}
                  />
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Tribe Name</Typography>
                  <CommonTextField
                    value={uiState.TribeName}
                    onChange={(event) => {
                      uiEvent.HandleUiStateChange("TribeName", event.target.value);
                    }}
                    sx={{
                      mt: "8px",
                      width: "100%",
                      height: "48px",
                    }}
                    InputProps={{
                      style: {
                        height: "48px",
                        borderRadius: "10px",
                        // backgroundColor: color.gray200,
                        // color: color.gray600, // 비활성화 상태일 때 색상을 지정
                      },
                    }}
                  />
                </Box>
              </Box>
              <VerticalSpacer height={40} />
              {/* 네번째 줄 */}
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Verified by</Typography>
                  <CommonTextField sx={{ width: "100%", mt: 1 }} value={""} disabled />
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Verified Date</Typography>
                  <CommonTextField
                    sx={{ width: "100%", mt: 1 }}
                    value={LocalizedDate({
                      date: uiState.UserVerifiedDate,
                      type: DateFormatType.numericTime,
                    })}
                    disabled
                  />
                </Box>
              </Box>
              <VerticalSpacer height={40} />
              {/* 다섯번째 줄 */}
              <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Registration Date</Typography>
                  <CommonTextField
                    sx={{ width: "100%", mt: 1 }}
                    value={LocalizedDate({
                      date: uiState.RegistrationDate,
                      type: DateFormatType.numericTime,
                    })}
                    disabled
                  />
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Last Modified Date</Typography>
                  <CommonTextField
                    sx={{ width: "100%", mt: 1 }}
                    value={LocalizedDate({
                      date: uiState.LastModifiedDate,
                      type: DateFormatType.numericTime,
                    })}
                    disabled
                  />
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <CommonWebModal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setPosition({ x: 0, y: 0 }); // 위치 초기화
          setScale(1); // 확대 비율 초기화
        }}
        title=""
        dialogContentSx={{ m: 0, p: 0 }}
        PaperProps={{ sx: { borderRadius: "15px" } }}
      >
        <Box sx={{ minHeight: "100px" }}>
          <Box
            sx={{
              display: "flex",
              height: "64px",
              px: "18px",
              py: "20px",
              justifyContent: "space-between",
            }}
          >
            <Typography fontStyle={fontStyle.semiboldM}>File Viewer</Typography>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box
                component="img"
                src={ICON_DOWNLOAD}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  const link: any = document.createElement("a");
                  link.href = fileUrl ? fileUrl : uiState.idCardImageURL; // 다운로드할 파일 URL
                  link.download = fileUrl ? uiState.IDCard?.name : uiState.IdCardImageFileName; // 다운로드될 파일 이름 설정
                  link.click();
                }}
              />
              <HorizontalSpacer width={20} />
              <Box
                component="img"
                src={ICON_CLOSE}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setOpenModal(false);
                  setPosition({ x: 0, y: 0 }); // 위치 초기화
                  setScale(1); // 확대 비율 초기화
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "black",
              overflow: "hidden", // 부모 Box를 넘어가지 않게 설정
              width: "100%", // 부모 Box의 너비 설정 (이미지 최대 크기와 동일)
              // height: "536px", // 부모 Box의 높이 설정 (이미지 최대 크기와 동일)
              cursor: isDragging ? "grabbing" : "grab", // 드래그 시 커서 모양 변경
            }}
            onMouseDown={handleMouseDown} // 마우스를 눌렀을 때 드래그 시작
            onMouseMove={handleMouseMove} // 마우스를 움직일 때 위치 변경
            onMouseUp={handleMouseUp} // 마우스를 떼면 드래그 종료
            onMouseLeave={handleMouseUp} // 마우스가 영역을 벗어나면 드래그 종료
          >
            {fileUrl ? (
              <Box
                component={"img"}
                src={fileUrl}
                alt="Selected"
                sx={{
                  minWidth: "300px",
                  minHeight: "300px",
                  maxWidth: "716px", // 최대 크기 제한 없음
                  maxHeight: "536px", // 최대 크기 제한 없음
                  objectFit: "contain",
                  transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`, // 확대 및 위치 이동
                  transition: isDragging ? "none" : "transform 0.2s", // 드래그 중에는 애니메이션 제거
                }}
                onWheel={handleWheel} // 마우스 휠로 확대/축소
              />
            ) : (
              <Box
                component={"img"}
                src={uiState.idCardImageURL}
                alt="Selected"
                sx={{
                  minWidth: "300px",
                  minHeight: "300px",
                  maxWidth: "716px", // 최대 크기 제한 없음
                  maxHeight: "536px", // 최대 크기 제한 없음
                  objectFit: "contain",
                  transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`, // 확대 및 위치 이동
                  transition: isDragging ? "none" : "transform 0.2s", // 드래그 중에는 애니메이션 제거
                }}
                onWheel={handleWheel} // 마우스 휠로 확대/축소
              />
            )}
          </Box>
        </Box>
      </CommonWebModal>
    </Grid>
  );
};

export default UserDetailGrid;
