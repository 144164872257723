import { Box, Typography } from "@mui/material";
import { ICON_CHECK, POPUP_PREVIEW } from "../../../../constants/imagePath";
import { PopupManagementUiEvent } from "../model/PopupManagementUiEvent";
import { color } from "../../../../theme/Color";
import { fontStyle } from "../../../../theme/Style";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination } from "swiper/modules";
import {
  PopupManagementUiState,
  showImageType,
} from "../model/PopupManagementUiState";

interface PopupPreviewProps {
  uiEvent: PopupManagementUiEvent;
  uiState: PopupManagementUiState;
  data: showImageType[];
}

const PopupPreview = ({ uiEvent, data, uiState }: PopupPreviewProps) => {
  return (
    <Box
      sx={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 2000,
        backgroundColor: "rgba(0, 0, 0, 0.3)", // 반투명 검정 배경
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        onClick={() => {
          uiEvent.HandleUiStateChange("popupPreview", false);
        }}
        sx={{
          position: "absolute",
          top: "64px", // 화면 상단에서 20px
          right: "48px", // 화면 오른쪽에서 20px
          width: "56px", // 버튼 크기
          height: "56px", // 버튼 크기
          backgroundColor: color.white, // 흰색 배경
          borderRadius: "100px", // 원형 버튼
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        ✖
      </Box>
      <Box
        sx={{
          position: "relative", // 상대적 위치로 박스 배치
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={POPUP_PREVIEW}
          alt="Popup Preview"
          style={{
            width: "362px",
            height: "756px",
            borderRadius: "40px",
          }}
        />
        {/* 중앙에 274x340 크기의 박스 */}
        <Box
          sx={{
            position: "absolute",
            top: "50%", // 이미지의 세로 중앙에 배치
            left: "50%", // 이미지의 가로 중앙에 배치
            transform: "translate(-50%, -50%)", // 정확한 중앙 정렬
            width: "274px", // 박스의 가로 크기
            height: "340px", // 박스의 세로 크기
            zIndex: 1000, // 이미지 위로 배치
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              width: "100%",
              height: "20.54px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "14.14px",
                height: "14.14px",
                border: "1.18px solid",
                borderRadius: "100px",
                borderColor: color.white,
                marginRight: "4.12px",
              }}
            >
              <img
                src={ICON_CHECK}
                style={{ width: "6.6px", height: "5.19px" }}
              />
            </Box>
            <Typography
              fontStyle={fontStyle.titleS}
              sx={{ marginRight: "7.07px", textDecoration: "underline" }}
              color={color.gray100}
            >
              Do not show today
            </Typography>
          </Box>
          <Box
            sx={{
              width: "274px",
              height: "274px",
              backgroundColor: color.gray100,
              borderTopLeftRadius: "8.84px",
              borderTopRightRadius: "8.84px",
            }}
          >
            <Swiper
              navigation={false}
              style={{
                width: "274px",
                height: "274px",
              }}
              pagination={{
                type: "fraction",
                renderFraction: (currentClass: any, totalClass: any) => {
                  return `<div style="
                          position: absolute;
                          right: 17px;
                          bottom: 8px;
                          height: 20px;
                          width:fit-content;
                          justify-content: center;
                          align-content: center;                             
                          background-color: ${color.black};
                          opacity: 0.4;
                          border-radius: 50px;
                          padding: 2px 8px;                           
                          font-style: ${fontStyle.bodySr};
                          color: ${color.gray300};
                        ">
                      <span class="${currentClass}"></span> / <span class="${totalClass}"></span>
                    </div>
                  `;
                },
              }}
              modules={[Pagination]}
              onSlideChange={(swiper: any) => {
                console.log("swiper.activeIndex :", swiper.activeIndex);
                uiEvent.HandleUiStateChange(
                  "previewImageIndex",
                  swiper.activeIndex
                );
              }}
            >
              {data?.map((item, index) => (
                <SwiperSlide key={index}>
                  <img
                    src={item.image}
                    alt={`Promotion ${index + 1}`}
                    style={{
                      width: "274px",
                      height: "274px",
                      objectFit: "cover",
                    }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "45.96px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: color.white,
              borderBottomLeftRadius: "8.84px",
              borderBottomRightRadius: "8.84px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "50%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                fontStyle={fontStyle.titleS}
                color={color.gray600}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "122.86px",
                  height: "28.28px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  uiEvent.HandleUiStateChange("popupPreview", false);
                }}
              >
                close
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "50%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                fontStyle={fontStyle.titleS}
                color={color.primary500}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "122.86px",
                  height: "28.28px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  uiEvent.redirectToUrl(uiState.previewImageIndex);
                }}
              >
                View Details
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PopupPreview;
