import { Box } from "@mui/material";
import { color } from "../../../../theme/Color";
import { Swiper, SwiperSlide } from "swiper/react";
import { fontStyle } from "../../../../theme/Style";
import { BannerManagementUiEvent } from "../model/BannerManagementUiEvent";
import {
  BannerManagementUiState,
  showImageType,
} from "../model/BannerManagementUiState";
import { Pagination } from "swiper/modules";

interface BannerPreviewImageProps {
  uiEvent: BannerManagementUiEvent;
  uiState: BannerManagementUiState;
  data: showImageType[];
}

const BannerPreviewImage = ({
  uiEvent,
  data,
  uiState,
}: BannerPreviewImageProps) => {
  return (
    <Box
      sx={{
        width: "310px",
        height: "82px",
        backgroundColor: color.gray100,
        borderTopLeftRadius: "8.84px",
        borderTopRightRadius: "8.84px",
      }}
    >
      <Swiper
        navigation={false}
        style={{
          width: "310px",
          height: "82px",
        }}
        pagination={{
          type: "fraction",
          renderFraction: (currentClass: any, totalClass: any) => {
            return `<div style="
                          position: absolute;
                          right: 0;
                          bottom: -5px;
                          height: 20px;
                          width:fit-content;
                          justify-content: center;
                          align-content: center;                             
                          background-color: ${color.black};
                          opacity: 0.4;
                          border-radius: 50px;
                          padding: 2px 8px;                           
                          font-style: ${fontStyle.bodySr};
                          color: ${color.gray300};
                        ">
                      <span class="${currentClass}"></span> / <span class="${totalClass}"></span>
                    </div>
                  `;
          },
        }}
        modules={[Pagination]}
        onSlideChange={(swiper: any) => {
          console.log("swiper.activeIndex :", swiper.activeIndex);
          uiEvent.HandleUiStateChange("previewImageIndex", swiper.activeIndex);
        }}
      >
        {data?.map((item, index) => (
          <SwiperSlide key={index}>
            <img
              src={item.image}
              alt={`Promotion ${index + 1}`}
              style={{
                width: "100%",
                height: "82px",
                objectFit: "cover",
                cursor: "pointer",
              }}
              onClick={() => {
                uiEvent.redirectToUrl(uiState.previewImageIndex);
              }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};
export default BannerPreviewImage;
