import { Box, TableCell, TableRow, Typography } from "@mui/material";
import { fontStyle } from "../../../../../../theme/Style";
import CommonTable, { useVisibleRows } from "../../../../../../component/CommonTable";
import { color } from "../../../../../../theme/Color";
import { ICON_DOWNLOAD } from "../../../../../../constants/imagePath";
import { ReactNode } from "react";

interface RowProps {
  row: any;
  index: any;
  tabVelue: string;
}

const Row = ({ row, index, tabVelue }: RowProps) => {
  return (
    <>
      <TableRow sx={{ cursor: "pointer" }}>
        <TableCell align="center" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.no}</Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.stationNm}</Typography>
        </TableCell>
        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.stationSn}</Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.batterySn}</Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.soc}</Typography>
        </TableCell>
        <TableCell align="left" sx={{ height: "52px", display: tabVelue == "Return" ? "" : "none", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.useSoc}</Typography>
        </TableCell>
        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.collectionDate}</Typography>
        </TableCell>
      </TableRow>
    </>
  );
};

interface BatteryReplacementRentalTableProps {
  tabVelue: string;
  rows?: any;
  docCount?: number;
  rowsPerPage?: any;
  pageCount?: any;
  page?: any;
  setPage?: any;
  setRowsPerPage?: any;
  csvDownload?: ReactNode;
}

const BatteryReplacementRentalTable = ({
  tabVelue,
  rows,
  docCount,
  rowsPerPage,
  pageCount,
  page,
  setPage,
  setRowsPerPage,
  csvDownload,
}: BatteryReplacementRentalTableProps) => {
  const headCells = [
    {
      id: "no",
      align: "center",
      disablePadding: false,
      label: "No",
    },
    {
      id: "stationNm",
      align: "left",
      disablePadding: false,
      label: "Station Name",
    },
    {
      id: "stationSN",
      align: "left",
      disablePadding: false,
      label: "Station S/N",
    },
    {
      id: "batterySN",
      align: "left",
      disablePadding: false,
      label: "Battery S/N",
    },
    {
      id: "soc",
      align: "left",
      disablePadding: false,
      label: "SOC (%)",
    },
    {
      id: "useSoc",
      align: "left",
      disablePadding: false,
      label: "Use SOC (%)",
      display: tabVelue == "Return" ? "" : "none",
    },
    {
      id: "collectionDate",
      align: "left",
      disablePadding: false,
      label: "Collection Date (local)",
    },
  ];

  const { order, orderBy, handleRequestSort, handleChangePage, visibleRows } = useVisibleRows(rows, rowsPerPage, setPage, page);

  return (
    <CommonTable
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      page={page}
      docCount={docCount}
      pageCount={pageCount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      handleRequestSort={handleRequestSort}
      handleChangePage={handleChangePage}
      setPage={setPage}
      csvDownload={csvDownload}
      noneRowPerPage={true}
    >
      {visibleRows?.length == 0 ? (
        <TableRow sx={{ px: "4px", height: "1px" }}>
          <TableCell
            colSpan={headCells?.length}
            align="center"
            sx={{
              minWidth: "1032px",
              px: "13px",
              py: "12px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontStyle: fontStyle.regularS,
                color: color.gray600,
              }}
            >
              No Data
            </Typography>
          </TableCell>
        </TableRow>
      ) : (
        visibleRows.map((row: any, index: any) => <Row row={row} index={index} key={index} tabVelue={tabVelue} />)
      )}
    </CommonTable>
  );
};

export default BatteryReplacementRentalTable;
