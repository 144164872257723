import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Divider, Typography } from "@mui/material";
import { LocalizedText } from "../../../../di/LanguageRepositoryProvider";
import VerticalSpacer from "../../../../component/Spacer";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";

import CommonButton from "../../../../component/CommonButton";
import IconTextDialog, {
  IconTextDialogType,
} from "../../../../component/dialog/IconTextDialog";
import { HasScooterUiState } from "../HasScooterUiState";
import { HasScooterUiEvent } from "../HasScooterUiEvent";
import { ApiService } from "../../../../restAPI/ApiService";
import { SERVER_CONFIG } from "../../../../constants/ServerConfig";
import ProgressBar from "../../../../component/ProgressBar";
import DuplicateModal from "./component/DuplicateModal";
import NFTCompleateModal from "./component/NFTCompleateModal";
import NewSignUpModal from "./component/NewSignUpModal";
import { ROUTE_PATHS } from "../../../../constants/RouteConstants";

interface PickNFTCharacterProps {
  uiState: HasScooterUiState;
  uiEvent: HasScooterUiEvent;
  lastPath: string;
}

const PickNFTCharacter = ({
  uiState,
  uiEvent,
  lastPath,
}: PickNFTCharacterProps) => {
  const navigate = useNavigate();
  const [char, setChar] = useState("Ponya");
  const [charData, setCharData] = useState<any>(undefined);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);
  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);
  const [newSignUpModalOpen, setNewSignUpModal] = useState(false);
  const [NFTCompleateModalOpen, setNFTCompleateModal] = useState(false);

  const handleDimEvent = (event: boolean) => {
    //TODO: 로그인탭(회원가입 시) 오류 발생
    // if (window.Android) {
    //   window.Android.isShownDialog(event);
    // }
    // if (window.webkit) {
    //   window.webkit.messageHandlers.isShownDialog.postMessage(event);
    // }
  };

  const handleCharChange = (char: string) => {
    setChar(char);
  };

  // 이미지 프리로드 함수
  const preloadImages = (images: string[]) => {
    images?.forEach((src) => {
      const img = new Image();
      img.src = src;
    });
  };

  useEffect(() => {
    setImgLoading(true);
    ApiService.MobilePost("/pw/backend/api/findAll")
      .then((res) => {
        const data = res?.data.body;
        setCharData(data);
        // 이미지 프리로드 실행
        const imageUrls = data?.map((item: any) => item.character);
        preloadImages(imageUrls);
      })
      .then(() => {
        setImgLoading(false);
      });
  }, []);

  function onSuccessLogin() {
    if (window.Android) {
      console.log("[Success Login][Android]");
      window.Android.onLoginSuccess();
    } else if (window.webkit) {
      console.log("[Success Login][iPhone]");
      window.webkit.messageHandlers.onLoginSuccess.postMessage(
        "onLoginSuccess",
      );
    } else {
      console.log("[Success Login][Web]");
      window.location.href = SERVER_CONFIG.FRONT_URL + "/PWUserAppMap";
    }
  }

  const CreateNft = (
    whatCase:
      | "not duplicate"
      | "email duplicate"
      | "phone duplicate"
      | "duplicate"
      | "",
  ) => {
    const reqBody = {
      grade: "Red",
      characterName: char,
      uuid: localStorage.getItem("uuid"),
    };
    ApiService.BlockchainPost("/nft/reqNftChar", reqBody).then((res) => {
      setLoading(false);
      // 현재 사용하는 BlockchainPost는 오류가 나도 무조건 then문을 타기때문에 해당 예외 처리 필수
      if (res?.data) {
        if (whatCase == "not duplicate") {
          setNewSignUpModal(true);
        } else if (
          whatCase == "email duplicate" ||
          whatCase == "phone duplicate"
        ) {
          setDuplicateModalOpen(true);
        } else if (whatCase == "duplicate") {
          setDuplicateModalOpen(true);
        } else {
          setNFTCompleateModal(true);
        }
      }
    });
  };

  const ua_g310_ponyainfo = LocalizedText("ua_g310_ponyainfo");
  const ua_g310_peterinfo = LocalizedText("ua_g310_peterinfo");
  const ua_g310_dodoinfo = LocalizedText("ua_g310_dodoinfo");
  const ua_g310_selectcharacter = LocalizedText("ua_g310_selectcharacter");
  const ua_g310_alert_selectsure_title = LocalizedText(
    "ua_g310_alert_selectsure_title",
  );
  const ua_g310_alert_selectsure_message = LocalizedText(
    "ua_g310_alert_selectsure_message",
  );

  return (
    <>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          overflowY: "scroll",
          scrollbarWidth: "none", // Firefox에서 스크롤바 숨기기
          "&::-webkit-scrollbar": {
            display: "none", // Webkit 브라우저에서    스크롤바 숨기기
          },
        }}
      >
        <VerticalSpacer height={16} />
        {loading && <ProgressBar />}
        {imgLoading && <ProgressBar />}
        <Box
          sx={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            overflowY: "scroll",
            scrollbarWidth: "none", // Firefox에서 스크롤바 숨기기
            "&::-webkit-scrollbar": {
              display: "none", // Webkit 브라우저에서    스크롤바 숨기기
            },
          }}
        >
          {/* 중간 요소 */}
          <Box
            sx={{
              width: "calc(100% - 40px)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              flexGrow: 1,
            }}
          >
            <Typography fontStyle={fontStyle.headingS}>
              {ua_g310_selectcharacter}
            </Typography>

            {/* 선택된 캐릭터 */}
            <Box sx={{ width: "100%" }}>
              {charData?.map((data: any) => {
                return (
                  <>
                    {char === data.id && (
                      <Box
                        component="img"
                        src={data.character}
                        width="100%"
                        minHeight="calc(100vw - 40px)"
                        loading="lazy"
                      />
                    )}
                  </>
                );
              })}
            </Box>

            {/* 캐릭터 선택 버튼 */}
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {charData?.map((data: any) => {
                return (
                  <Box
                    component="img"
                    src={data.thumbnail}
                    width="50px"
                    height="50px"
                    mx="5px"
                    sx={
                      char === data.id
                        ? {
                            border: 2,
                            borderColor: color.primary500,
                            boxSizing: "border-box",
                            borderRadius: "10px",
                          }
                        : { borderRadius: "10px" }
                    }
                    onClick={() => {
                      handleCharChange(data.id);
                    }}
                    loading="lazy"
                  />
                );
              })}
            </Box>
            <VerticalSpacer height={16} />
            <Divider sx={{ width: "100%" }} />
            <VerticalSpacer height={16} />
            {/* 설명 문구 */}
            <Box>
              {char === "Ponya" && (
                <Typography fontStyle={fontStyle.bodyMr} color={color.gray800}>
                  {ua_g310_ponyainfo}
                </Typography>
              )}
              {char === "Peter" && (
                <Typography fontStyle={fontStyle.bodyMr} color={color.gray800}>
                  {ua_g310_peterinfo}
                </Typography>
              )}
              {char === "Dodo" && (
                <Typography fontStyle={fontStyle.bodyMr} color={color.gray800}>
                  {ua_g310_dodoinfo}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>

        <VerticalSpacer height={8} />
        <VerticalSpacer height={66} />
        {/* 하단 버튼 */}
        <Box
          sx={{
            position: "absolute",
            bottom: "0px",
            width: "100%",
            display: "flex",
          }}
        >
          <CommonButton
            title="Select"
            textColor="white"
            onClick={() => {
              // navigate("/PickNFTChar");
              handleDimEvent(true);
              setDialogOpen(true);
            }}
            sx={{
              mx: "20px",
              mb: "14px",
            }}
          />
        </Box>

        <IconTextDialog
          title={ua_g310_alert_selectsure_title}
          content={ua_g310_alert_selectsure_message}
          type={IconTextDialogType.WARNING}
          positiveText="OK"
          negativeText="Cancel"
          isOpen={dialogOpen}
          onPositive={() => {
            handleDimEvent(false);
            if (lastPath == ROUTE_PATHS.PHONE_AUTH) {
              // lastPath가 LoginFinishPage인 경우는 초기 회원가입 로직에서 NFT 발급 받을때 말고는 존재할 수 없다.

              // // 백엔드에 mail과 휴대폰 번호는 넘겨서 중복되는 유저가 있는지 판단하는 api 과정

              /* 백엔드에서 api 준비 되지않아 이 코드는 주석 처리했습니다.
              ApiService.MobilePost("", {
                email: localStorage.getItem("email") || "",
                phoneNumber: localStorage.getItem("user_country_number") || "" + localStorage.getItem("user_phone_number") || "",
              }).then((res) => {
                setLoading(true);
                setDialogOpen(false);
                if (res?.data.state == "not duplicate") {
              //*/
              setLoading(true);
              setDialogOpen(false);
              CreateNft("");
              /*  CreateNft("not duplicate");
              
                } else if (res?.data.state == "email duplicate") {
              
                  CreateNft("email duplicate");
                } else if (res?.data.state == "phone duplicate") {
              
                  CreateNft("phone duplicate");
                } else if (res?.data.state == "duplicate") {
                  CreateNft("duplicate");
                }
                else {
                  CreateNft("not duplicate");
                }
              });
              //*/
            } else {
              // 그 외에 NFT 를 발급 받는 경우는 선택한 NFT 캐릭터가 Max Level(Mint Color) 일때만 존재한다.
              // uiEvent.SetwWhatScreen("HasNFTChar");
              setLoading(true);
              setDialogOpen(false);
              CreateNft("");
              // setNFTCompleateModal(true);
            }
          }}
          onNegative={() => {
            handleDimEvent(false);
            setDialogOpen(false);
          }}
          onClose={() => {
            handleDimEvent(false);
            setDialogOpen(false);
          }}
        />

        {/* 최초 회원가입 유저 */}
        <NewSignUpModal
          dialogOpen={newSignUpModalOpen}
          onClick={onSuccessLogin}
        />
        {/* 메일과 번호가 중복됐을 시 나오는 모달 */}
        <DuplicateModal
          dialogOpen={duplicateModalOpen}
          onClick={onSuccessLogin}
        />
        {/* User가  Max Level(Mint Color) 달성 후 새로운 NFT 캐릭터를 선택하는 case 인 경우의 모달 */}
        {/* todo 이부분 onClick는 추후에 바꿔야함 */}
        <NFTCompleateModal
          dialogOpen={NFTCompleateModalOpen}
          onClick={onSuccessLogin}
        />
      </Box>
    </>
  );
};

export default PickNFTCharacter;
