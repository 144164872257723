import {
  Box,
  Button,
  Divider,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import VerticalSpacer, { HorizontalSpacer } from "../../../component/Spacer";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import { useEffect, useRef, useState } from "react";
import CommonList from "../../../component/CommonList";
import { ICON_SHOW_WHITE } from "../../../constants/imagePath";
import CommonSearch from "../../../component/CommonSearch";
import { ApiService } from "../../../restAPI/ApiService";
import dayjs, { Dayjs } from "dayjs";
import axios from "axios";
import {
  BannerManagementUiState,
  showImageType,
} from "./model/BannerManagementUiState";
import { BannerResponse } from "./model/BannerResponse";
import { BannerManagementUiEvent } from "./model/BannerManagementUiEvent";
import BannerMngGrid, { DataType } from "./section/BannerManagementGrid";
import { useNavigate } from "react-router-dom";
import { CommonWebHeaderButton } from "../../../component/CommonWebHeaderButton";
import BannerPreview from "./section/BannerPreview";

const BannerMngPage = () => {
  const [rows, setRows] = useState([]); // 받아온 데이터
  const [tabVelue, setTabVelue] = useState("bannerMng");
  // 검색어 관련 변수
  const [searchContents, setSearchContents] = useState("");
  // 국가관련 변수
  const [countryList, setCountryList] = useState([]);
  const [country, setCountry] = useState("Country");
  const [countryCode, setCountryCode] = useState("");
  const handleCountryChange = (event: SelectChangeEvent) => {
    setCountry(event.target.value[0] as string);
    setCountryCode(event.target.value[1] as string);
  };
  // 상태 관련 변수
  const [status, setStatus] = useState("");
  const handleStatusChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value as string);
  };

  const newCardRef = useRef<HTMLDivElement | null>(null);

  const [uiState, setUiState] = useState(new BannerManagementUiState());

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [fileUrl, setFileUrl] = useState<string>("");

  const navigate = useNavigate();

  const handleFocus = () => {
    setTimeout(() => {
      if (newCardRef.current) {
        newCardRef.current.focus();
        newCardRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      } else {
        console.log("newCardRef is null");
      }
    }, 0);
  };

  async function createBanner(): Promise<BannerResponse> {
    const url = "/web/pw/aw/contents/banner/create";
    const headers = {
      "Content-Type": "multipart/form-data;charset=UTF-8",
    };
    const body = {
      title: uiState.name,
      state: uiState.status,
      country: uiState.country,
      startDate: uiState.startDay,
      endDate: uiState.endDay,
      link: uiState.url,
      uuid: localStorage.getItem("user_uuid") || "",
    };
    try {
      const formData = new FormData();
      formData.append(
        "body",
        new Blob([JSON.stringify(body)], { type: "application/json" })
      );

      if (!fileUrl) {
        console.log("이미지 없음");
      } else {
        const allowedExtensions = ["image/png", "image/jpeg", "image/jpg"];
        const mimeTypeToExtension: Record<string, string> = {
          "image/png": "png",
          "image/jpeg": "jpg",
          "image/jpg": "jpg",
        };
        const blobUrl = fileUrl;
        const response = await fetch(blobUrl);
        const blob = await response.blob();
        if (!allowedExtensions.includes(blob.type)) {
          console.error(
            "지원되지 않는 파일 형식입니다. png, jpg, jpeg만 가능합니다."
          );
          return Promise.reject("Invalid file type");
        }
        const extension = mimeTypeToExtension[blob.type];
        const fileName = `image.${extension}`; // 원래 확장자를 유지한 파일명
        formData.append("image", blob, fileName);
      }
      const request = await axios.post(url, formData, { headers });
      window.location.reload();
      return request.data as BannerResponse;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.error("서버 에러 응답: ", e.response?.data);
      }
      console.error("에러 발생: ", e);
      throw e;
    }
  }

  async function updateBanner(): Promise<BannerResponse> {
    const url = "/web/pw/aw/contents/banner/update";
    const headers = {
      "Content-Type": "multipart/form-data;charset=UTF-8",
    };
    const body = {
      title: uiState.name,
      state: uiState.status,
      country: uiState.country,
      startDate: uiState.startDay,
      endDate: uiState.endDay,
      link: uiState.url,
      uuid: localStorage.getItem("user_uuid") || "",
      id: uiState.id,
    };
    try {
      const formData = new FormData();
      formData.append(
        "body",
        new Blob([JSON.stringify(body)], { type: "application/json" })
      );

      if (!uiState.image) {
        console.log("이미지 없음");
      } else {
        const blobUrl = fileUrl;
        const response = await fetch(uiState.image as string);
        const blob = await response.blob();
        formData.append("image", blob, "image.png");
      }
      const request = await axios.post(url, formData, { headers });
      window.location.reload();
      return request.data as BannerResponse;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.error("서버 에러 응답: ", e.response?.data);
      }
      console.error("에러 발생: ", e);
      throw e;
    }
  }

  const uiEvent: BannerManagementUiEvent = {
    HandleUiStateChange: (key: keyof BannerManagementUiState, value: any) => {
      setUiState((prevState) => ({
        ...prevState,
        [key]: value, // key에 해당하는 상태를 업데이트
      }));
    },

    HandleFileUpload: (event) => {
      const uploadedFile = event.target.files?.[0];
      if (uploadedFile) {
        const allowedExtensions = ["png", "jpg", "jpeg"];
        const fileExtension = uploadedFile.name.split(".").pop()?.toLowerCase();

        if (fileExtension && allowedExtensions.includes(fileExtension)) {
          uiEvent.HandleUiStateChange("image", uploadedFile); // 파일 정보 저장
          const previewUrl = URL.createObjectURL(uploadedFile);
          setFileUrl(previewUrl);
        } else {
          alert("Only PNG, JPG, and JPEG files are allowed.");
        }
      }
    },

    HandleButtonClick: () => {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    },

    HandleDateChange: (date: Dayjs | null, field: "startDay" | "endDay") => {
      setUiState((prevState) => {
        const selectedDate = date || dayjs();
        const updatedState = {
          ...prevState,
          [field]: selectedDate.valueOf(),
        };

        // startDay와 startTime 결합
        if (updatedState.startDay) {
          updatedState.startTime
            ? dayjs(updatedState.startTime)
            : dayjs("00:00:00", "HH:mm:ss");
          const startDate = dayjs(updatedState.startDay)
            .set("hour", dayjs(updatedState.startTime).hour())
            .set("minute", dayjs(updatedState.startTime).minute())
            .set("second", dayjs(updatedState.startTime).second());

          updatedState.startDate = startDate.valueOf();
        }

        // endDay와 endTime 결합
        if (updatedState.endDay) {
          updatedState.endTime ? dayjs(updatedState.endTime) : dayjs(0);
          const endDate = dayjs(updatedState.endDay)
            .set("hour", dayjs(updatedState.endTime).hour())
            .set("minute", dayjs(updatedState.endTime).minute())
            .set("second", dayjs(updatedState.endTime).second());

          updatedState.endDate = endDate.valueOf();
        }

        return updatedState;
      });
    },

    HandleTimeChange: (
      newValue: Dayjs | null,
      field: "startTime" | "startTimes" | "endTime" | "endTimes"
    ) => {
      if (!newValue) {
        console.error("Invalid time value:", newValue);
        return;
      }

      setUiState((prevState) => {
        const dateField =
          field === "startTime" || field === "startTimes"
            ? "startDay"
            : "endDay";

        const baseDate = prevState[dateField]
          ? dayjs(prevState[dateField])
          : dayjs();

        const updatedDate = baseDate
          .set("hour", newValue.hour())
          .set("minute", newValue.minute())
          .set("second", newValue.second())
          .millisecond(0);

        const updatedState = {
          ...prevState,
          [field]: newValue.valueOf(), // Unix 타임스탬프로 저장
          [dateField]: updatedDate.valueOf(), // 결합된 날짜도 Unix로 저장
        };

        return updatedState;
      });
    },

    HandleNewCard: () => {
      setFileUrl("");
      setUiState((prevState) => ({
        ...prevState,
        createCard: true,
        editCard: false,
        name: "",
        country: "",
        status: "",
        startDate: 0,
        startDay: 0,
        startTime: 0,
        startTimes: 0,
        endDate: 0,
        endDay: 0,
        endTime: 0,
        endTimes: 0,
        url: "",
        id: "",
        image: undefined,
        inValidCheck: {
          name: false,
          country: false,
          status: false,
          startDate: false,
          startDay: false,
          startTime: false,
          endDate: false,
          endDay: false,
          endTime: false,
          url: false,
          image: false,
        },
      }));
    },

    HandleCancelClick: () => {
      uiEvent.HandleUiStateChange("cancelDialogOpen", false);
    },

    HandleCreateOk: () => {
      uiEvent.HandleUiStateChange("createDialogOpen", false);
      const isValid = ValidateUiState();
      if (isValid) {
        createBanner();
      } else {
      }
    },

    HandleCreateCancel: () => {
      uiEvent.HandleUiStateChange("createDialogOpen", false);
    },

    HandleCancelOk: () => {
      setUiState((prevState) => ({
        ...prevState,
        ...(uiState.createCard === true
          ? { createCard: false }
          : uiState.editCard === true
            ? { editCard: false }
            : {}),
        cancelDialogOpen: false,
        name: "",
        country: "",
        status: "",
        startDay: 0,
        startTime: 0,
        endDay: 0,
        endTime: 0,
        url: "",
        id: "",
      }));
      window.location.reload();
    },

    HandleCancelClose: () => {
      setUiState((prevState) => ({
        ...prevState,
        cancelDialogOpen: false,
      }));
    },

    HandleEditClick: (data: DataType) => {
      const startDate = dayjs(data.startDate);
      const endDate = dayjs(data.endDate);
      setFileUrl(data.image);
      setUiState((prevState) => ({
        ...prevState,
        editCard: true,
        createCard: false,
        originalData: {
          ...data,
          country: [], // 필요한 경우 빈 배열로 변환
        },
        name: data.title || "",
        country: data.country[1] || "",
        status: data.state || "",
        startDate: data.startDate || 0,
        startDay: data.startDate || 0,
        endDate: data.endDate || 0,
        endDay: data.endDate || 0,
        url: data.link || "",
        image: data.image as unknown as File, // 타입 변환
        id: data.id || "",
        startTimes: startDate.valueOf(),
        endTimes: endDate.valueOf(),
        inValidCheck: {
          name: false,
          country: false,
          status: false,
          startDate: false,
          startDay: false,
          endDate: false,
          endDay: false,
          endTime: false,
          url: false,
          image: false,
          startTime: false,
        },
      }));
    },
    HandleUpdateOk: () => {
      uiEvent.HandleUiStateChange("updateDialogOpen", false);
      const isValid = ValidateUiState();
      if (isValid) {
        updateBanner();
      } else {
      }
      // updatePopup();
    },

    HandleUpdateCancel: () => {
      uiEvent.HandleUiStateChange("updateDialogOpen", false);
    },

    HandleDeleteOk: () => {
      uiEvent.HandleUiStateChange("deleteDialogOpen", false);
      ApiService.WebDelete("/pw/aw/contents/banner/delete", {
        id: uiState.id,
        uuid: localStorage.getItem("user_uuid") || "",
      }).then((res) => {
        window.location.reload();
      });
    },
    HandleDeleteCancel: () => {
      uiEvent.HandleUiStateChange("deleteDialogOpen", false);
    },

    HandleCopyClick: (data: DataType) => {
      setUiState((prevState) => ({
        ...prevState,
        editCard: false,
        createCard: true,
        image: data.image,
        name: data.title,
        country: data.country[1],
        status: data.state,
        startDay: data.startDate,
        startTime: data.startDate,
        endDay: data.endDate,
        endTime: data.endDate,
        url: data.link,
        id: "",
      }));
      setFileUrl(data.image);
      handleFocus();
    },

    openBannerPreview: (data: showImageType[]) => {
      uiEvent.HandleUiStateChange("bannerPreview", true);
      setUiState((prev) => {
        return {
          ...prev,
          previewImage: data,
        };
      });
    },

    redirectToUrl: (index: number) => {
      console.log(
        "uiState.showImage[index].link : ",
        uiState.showImage[index].link
      );
      handleUrlAction(uiState.showImage[index].link);
    },
  };

  useEffect(() => {
    // startTime 또는 endTime이 변경되면, 날짜와 시간 결합을 시도
    if (uiState.startDay && uiState.startTime) {
      uiEvent.HandleDateChange(dayjs(uiState.startDay), "startDay");
    }

    if (uiState.endDay && uiState.endTime) {
      uiEvent.HandleDateChange(dayjs(uiState.endDay), "endDay");
    }
  }, [uiState.startTime, uiState.endTime, uiState.startDay, uiState.endDay]);

  const InvalidDataCheck = (
    key: keyof (typeof uiState)["inValidCheck"],
    value: boolean
  ) => {
    setUiState((prevState) => ({
      ...prevState,
      inValidCheck: {
        ...prevState.inValidCheck,
        [key]: value,
      },
    }));
  };

  const ValidateUiState = () => {
    let isValid = true;
    Object.keys(uiState.inValidCheck).forEach((key) => {
      if (
        key === "name" ||
        key === "country" ||
        key === "status" ||
        key === "url"
      ) {
        const isInvalid = uiState[key].length === 0;
        InvalidDataCheck(key, isInvalid);
        if (isInvalid) isValid = false;
      }
      if (key === "image") {
        const isInvalid = uiState[key] === undefined;
        InvalidDataCheck(key, isInvalid);
        if (isInvalid) isValid = false;
      }

      if (uiState.createCard === true && uiState.status === "Period") {
        if (key === "startDate" || key === "endDate") {
          const isInvalid =
            uiState.startDay === uiState.endDay || uiState.startDay === 0;
          InvalidDataCheck(key, isInvalid);
          if (isInvalid) isValid = false;
        }
      }
      if (uiState.editCard === true && uiState.status === "Period") {
        if (key === "startDay" || key === "endDay") {
          const isInvalid = uiState.startDay === uiState.endDay;
          InvalidDataCheck(key, isInvalid);
          if (isInvalid) isValid = false;
        }
      }
    });
    return isValid;
  };

  const TabButton = () => {
    return (
      <Box sx={{ display: "flex", height: "24px" }}>
        <HorizontalSpacer width={16} />
        <Box
          sx={{
            fontStyle: fontStyle.semiboldM,
            cursor: "pointer",
            color: tabVelue == "popupMng" ? color.primary500 : color.gray600,
          }}
          onClick={() => {
            navigate("/popupMng");
          }}
        >
          Popup
        </Box>
        <HorizontalSpacer width={16} />
        <Divider orientation="vertical" />
        <HorizontalSpacer width={16} />
        <Box
          sx={{
            fontStyle: fontStyle.semiboldM,
            cursor: "pointer",
            color: tabVelue == "bannerMng" ? color.primary500 : color.gray600,
          }}
          onClick={() => {
            setTabVelue("bannerMng");
          }}
        >
          Banner
        </Box>
        <HorizontalSpacer width={16} />
        {/* <Divider orientation="vertical" />
        <HorizontalSpacer width={16} />
        <Box
          sx={{
            fontStyle: fontStyle.semiboldM,
            cursor: "pointer",
            color: tabVelue == "popupHis" ? color.primary500 : color.gray600,
          }}
          onClick={() => {
            setTabVelue("popupHis");
          }}
        >
          Popup History
        </Box>
        <HorizontalSpacer width={16} />
        <Divider orientation="vertical" />
        <HorizontalSpacer width={16} />
        <Box
          sx={{
            fontStyle: fontStyle.semiboldM,
            cursor: "pointer",
            color: tabVelue == "bannerHis" ? color.primary500 : color.gray600,
          }}
          onClick={() => {
            setTabVelue("bannerHis");
          }}
        >
          Banner History
        </Box>
        <HorizontalSpacer width={16} /> */}
      </Box>
    );
  };

  // 서버에 select 목록들 가져오기
  useEffect(() => {
    ApiService.WebPost("/pw/aw/contents/getCountry").then((res) => {
      setCountryList(res?.data.body);
      uiEvent.HandleUiStateChange("countryList", res?.data.body);
    });
  }, []);

  // 서버에 filter된 리스트들 가져오기
  useEffect(() => {
    ApiService.WebPost("/pw/aw/contents/banner/filter", {
      country: countryCode,
      searchContents: searchContents,
      state: status,
    }).then((res) => {
      const showImages: showImageType[] = res?.data.body.map(
        (item: { image: string; link: string }) => ({
          image: item.image,
          link: item.link,
        })
      );
      uiEvent.HandleUiStateChange("showImage", showImages);
      console.log(res?.data.body);
      setRows(res?.data.body);
      uiEvent.HandleUiStateChange("data", res?.data.body);
    });
  }, [tabVelue, status, countryCode]);

  function handleUrlAction(url: string | undefined) {
    if (!url) {
      console.log("url does not exist");
      return;
    }

    if (url.startsWith("notice://")) {
      handleNoticeUrl(url);
    } else if (url.startsWith("http")) {
      handleExternalUrl(url);
    } else {
      console.error("Invalid url format : ", url);
    }
  }
  function handleNoticeUrl(url: string) {
    const noticeId = url.split("notice://")[1];
    if (noticeId) {
      navigate(`/announcement/announceDetails/${noticeId}`);
      console.log("navigate", noticeId);
    } else {
      console.error("Invalid notice url id");
    }
  }

  function handleExternalUrl(url: string) {
    window.location.href = url;
  }

  return (
    <CommonList
      title="Popup/Banner"
      headerButton={
        <CommonWebHeaderButton
          buttonContent="Show Status"
          icon={
            <img
              src={ICON_SHOW_WHITE}
              style={{ width: "18px", height: "18px" }}
            ></img>
          }
          height="49px"
          onClick={() => {
            uiEvent.openBannerPreview(uiState.showImage);
            console.log("uiState.showImage", uiState.showImage);
          }}
        />
      }
      tabButton={TabButton()}
    >
      <CommonSearch
        placeHolder={"Search"}
        setSearchContents={setSearchContents}
        onKeyPress={() => {
          ApiService.WebPost("/pw/aw/contents/banner/filter", {
            country: countryCode,
            searchContents: searchContents,
            state: status,
          }).then((res) => {
            console.log(res?.data.body);
            setRows(res?.data.body);
            uiEvent.HandleUiStateChange("data", res?.data.body);
          });
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Select
            value={country}
            onChange={handleCountryChange}
            renderValue={(v) => {
              return v?.length ? v : `Country`;
            }}
            sx={{
              width: "auto",
              height: "42px",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&.MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "red",
                },
                "&:hover fieldset": {
                  borderColor: "yellow",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
              },
              bgcolor: "white",
              borderRadius: "10px",
              fontStyle: fontStyle.semiboldXS,
              color: color.gray600,
            }}
          >
            <MenuItem
              value={["Country", ""]}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              all
            </MenuItem>
            {countryList?.map((data: any, index: any) => {
              return (
                <MenuItem
                  value={[data.label, data.code]}
                  key={index}
                  sx={{
                    fontStyle: fontStyle.semiboldXS,
                    color: color.gray600,
                  }}
                >
                  {data.label}
                </MenuItem>
              );
            })}
          </Select>
          <HorizontalSpacer width={12} />
          <Select
            value={status}
            onChange={handleStatusChange}
            displayEmpty
            renderValue={(v) => (v?.length ? v : `Status`)}
            sx={{
              width: "auto",
              height: "42px",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&.MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "red",
                },
                "&:hover fieldset": {
                  borderColor: "yellow",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
              },
              bgcolor: "white",
              borderRadius: "10px",
              fontStyle: fontStyle.semiboldXS,
              color: color.gray600,
            }}
          >
            <MenuItem
              value={""}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              All
            </MenuItem>
            <MenuItem
              value={"Show"}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              Show
            </MenuItem>
            <MenuItem
              value={"Period"}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              Period
            </MenuItem>
            <MenuItem
              value={"Hide"}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              Hide
            </MenuItem>
          </Select>
        </Box>
      </CommonSearch>
      <VerticalSpacer height={16} />
      <BannerMngGrid
        rows={rows}
        countryLists={countryList}
        uiState={uiState}
        uiEvent={uiEvent}
        fileInputRef={fileInputRef}
        fileUrl={fileUrl}
        newCardRef={newCardRef}
      />
      {uiState.bannerPreview == true && (
        <BannerPreview
          uiEvent={uiEvent}
          uiState={uiState}
          data={uiState.previewImage}
        />
      )}
    </CommonList>
  );
};

export default BannerMngPage;
