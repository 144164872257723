import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Drawer, Divider, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Toolbar, Typography, Collapse } from "@mui/material";
import { ApiService } from "../../../../restAPI/ApiService";
import { MenuList, MenuItem } from "../../../../menu-items/MenuItems";
import { SideBarSideEffect } from "./model/SideBarSideEffect";
import { SideBarUiEvent } from "./model/SideBarUiEvent";
import { SideBarUiState } from "./model/SideBarUiState";
import { SERVER_CONFIG } from "../../../../constants/ServerConfig";

interface SideBarRenderProps {
  isOpen: boolean;
  uiState: SideBarUiState;
  uiEvent: SideBarUiEvent;
  sideEffect: SideBarSideEffect;
}

export default function SideBar({ isOpen, uiState, uiEvent, sideEffect }: SideBarRenderProps) {
  const [openCollapse, setOpenCollapse] = useState<{ [key: string]: boolean }>({});
  const [openItem, setOpenItem] = useState<{ [key: string]: boolean }>({});

  const handleCollapseClick = (title: string) => {
    setOpenCollapse({
      [title]: !openCollapse[title],
    });
    setOpenItem({
      [title]: !openItem[title],
    });
  };

  const handleItemClick = (title: string, root: string) => {
    setOpenCollapse({
      [root]: openCollapse[root],
    });
    setOpenItem({
      [title]: (openItem[title] = true),
    });
    localStorage.setItem("collapseTitle", root);
    localStorage.setItem("itemTitle", title);
  };

  useEffect(() => {
    const root: any = localStorage.getItem("collapseTitle");
    const title: any = localStorage.getItem("itemTitle");

    if (SERVER_CONFIG.ON_FOR_GREENERY && "GREENERY_TEST_ACCOUNT_UUID_0" === localStorage.getItem("user_uuid") && (!root || !title)) {
      //       const menu = sideEffect.handelGetFirstPage({collapseTitle: 'carbonReduction', itemTitle: 'methodology'});
      localStorage.setItem("collapseTitle", "carbonReduction");
      localStorage.setItem("itemTitle", "methodology");
      return;
    }

    if (!root || !title) {
      ApiService.WebPost("/pw/users/admin/findPermission", {
        uuid: localStorage.getItem("user_uuid"),
      }).then((res) => {
        const menu = sideEffect.handelGetFirstPage(res?.data?.body);
        console.log("menu : ", menu);

        localStorage.setItem("collapseTitle", menu.collapseTitle);
        localStorage.setItem("itemTitle", menu.itemTitle);
      });
    }

    setOpenCollapse({
      [root]: !openCollapse[root],
    });
    setOpenItem({
      [title]: !openItem[title],
    });
  }, []);

  useEffect(() => {
    if (SERVER_CONFIG.ON_FOR_GREENERY && "GREENERY_TEST_ACCOUNT_UUID_0" === localStorage.getItem("user_uuid")) {
      console.log("[Success Login] user_uuid : ", localStorage.getItem("user_uuid"));
      uiEvent.onChangeStatistics([]);
      uiEvent.onChangeUserManagement([]);
      uiEvent.onChangeContent([]);
      uiEvent.onChangeMerchandise([]);
      uiEvent.onChangeProductionMaterial([]);
      uiEvent.onChangeOperations([]);
      uiEvent.onChangeCarbonReduction(["methodology", "project", "dataParameter", "monitoring"]);
      uiEvent.onChangeBlockchain([]);
      uiEvent.onChangeSystem([]);
      return;
    }

    ApiService.WebPost("/pw/users/admin/findPermission", {
      uuid: localStorage.getItem("user_uuid"),
    }).then((res) => {
      uiEvent.onChangeStatistics(res?.data?.body.statistics);
      uiEvent.onChangeUserManagement(res?.data?.body.userManagement);
      uiEvent.onChangeContent(res?.data?.body.content);
      uiEvent.onChangeMerchandise(res?.data?.body.merchandise);
      uiEvent.onChangeProductionMaterial(res?.data?.body.productionMaterial);
      uiEvent.onChangeOperations(res?.data?.body.operations);
      uiEvent.onChangeCarbonReduction(res?.data?.body.carbonReduction);
      uiEvent.onChangeBlockchain(res?.data?.body.blockchain);
      uiEvent.onChangeSystem(res?.data?.body.system);
    });
  }, [window.location.pathname]);

  const renderMenu = (menu: MenuItem, root: string, rootKey: string) => {
    if (menu.type === "group") {
      return (
        <React.Fragment key={menu.title}>
          {menu.title == "" ? null : (
            <ListSubheader
              sx={{
                alignItems: "center",
                display: "flex",
                mt: 2,
                ml: 2,
                height: 25,
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: 12,
                }}
              >
                {menu.title}
              </Typography>
            </ListSubheader>
          )}

          {menu.Children && menu.Children.map((child) => renderMenu(child, menu.title, menu.key))}
        </React.Fragment>
      );
    } else if (menu.type === "item") {
      const isCollapseItem = menu.Icon?.type?.type?.render?.displayName || "defaultValue";

      let list: string[] = sideEffect.handelGetList(rootKey);
      if (-1 === list.indexOf(menu.key) || !menu.show) return;

      if (isCollapseItem == "FiberManualRecordOutlinedIcon") {
        return (
          <ListItemButton
            key={menu.title}
            onClick={() => handleItemClick(menu.title, root)}
            component={Link}
            to={menu.url}
            sx={
              openItem[menu.title]
                ? {
                    borderRadius: 3,
                    borderTopRightRadius: "0",
                    borderBottomRightRadius: "0",
                    color: "black",
                    ":hover": {
                      color: "black",
                      backgroundColor: "white",
                    },
                    ml: 4,
                  }
                : {
                    borderRadius: 3,
                    borderTopRightRadius: "0",
                    borderBottomRightRadius: "0",
                    color: "gray",
                    ":hover": {
                      color: "black",
                      backgroundColor: "white",
                    },
                    ml: 4,
                  }
            }
          >
            <ListItemIcon sx={{ ml: -0.5, mr: -2 }}>{menu.Icon}</ListItemIcon>
            <ListItemText
              primary={menu.title}
              primaryTypographyProps={openItem[menu.title] ? { fontSize: "14px", fontWeight: "bold" } : { fontSize: "14px" }}
            />
          </ListItemButton>
        );
      } else {
        return (
          <ListItemButton
            key={menu.title}
            onClick={() => handleItemClick(menu.title, root)}
            component={Link}
            to={menu.url}
            sx={
              openItem[menu.title]
                ? {
                    ":hover": { color: "#5D96FF", backgroundColor: "#EEF3FF" },
                    backgroundColor: "#EFECFD",
                    borderRadius: 3,
                    borderTopRightRadius: "0",
                    borderBottomRightRadius: "0",
                    color: "#5D96FF",
                    ml: 4,
                  }
                : {
                    ":hover": { color: "#5D96FF", backgroundColor: "#EEF3FF" },
                    borderRadius: 3,
                    borderTopRightRadius: "0",
                    borderBottomRightRadius: "0",
                    ml: 4,
                  }
            }
          >
            <ListItemIcon sx={{ ml: -0.5, mr: -2 }}>{menu.Icon}</ListItemIcon>
            <ListItemText primary={menu.title} primaryTypographyProps={{ fontSize: "14px" }} />
            {openItem[menu.title] ? (
              <Box
                sx={{
                  backgroundColor: "#5D38E5",
                  position: "fixed",
                  ml: "226px",
                  width: "4px",
                  height: 25,
                }}
              />
            ) : (
              <Box />
            )}
          </ListItemButton>
        );
      }
    } else if (menu.type === "collapse" && menu.Children) {
      let list: string[] = sideEffect.handelGetList(menu.key);
      if (0 === list.length || !menu.show) return;

      return (
        <React.Fragment key={menu.title}>
          <ListItemButton
            sx={
              openCollapse[menu.title]
                ? {
                    ":hover": { color: "#5D96FF", backgroundColor: "#EEF3FF" },
                    color: "#5D96FF",
                    backgroundColor: "#EFECFD",
                    borderRadius: 3,
                    ml: 4,
                    borderTopRightRadius: "0",
                    borderBottomRightRadius: "0",
                  }
                : {
                    ":hover": { color: "#5D96FF", backgroundColor: "#EEF3FF" },
                    borderRadius: 3,
                    ml: 4,
                    borderTopRightRadius: "0",
                    borderBottomRightRadius: "0",
                  }
            }
            onClick={() => handleCollapseClick(menu.title)}
          >
            <ListItemIcon sx={{ ml: -0.5, mr: -2 }}>{menu.Icon}</ListItemIcon>
            <ListItemText primary={menu.title} primaryTypographyProps={{ fontSize: "14px" }} />
            {openCollapse[menu.title] ? <ExpandLess /> : <ExpandMore />}
            {openCollapse[menu.title] ? (
              <Box
                sx={{
                  position: "fixed",
                  ml: "226px",
                  width: "4px",
                  height: 25,
                  backgroundColor: "#5D38E5",
                }}
              />
            ) : (
              <Box />
            )}
          </ListItemButton>
          <Collapse in={openCollapse[menu.title] ?? false} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {menu.Children.map((child) => renderMenu(child, menu.title, menu.key))}
            </List>
          </Collapse>
        </React.Fragment>
      );
    } else if (menu.type === "another") {
      return (
        <React.Fragment key={menu.title}>
          <Divider sx={{ m: 3 }} />

          {menu.Children && menu.Children.map((child) => renderMenu(child, menu.title, menu.key))}
        </React.Fragment>
      );
    }
    return null;
  };

  return (
    <Box
      sx={{
        display: "flex",
        transition: "width 0.3s ease", // width 변화에 애니메이션 추가
        width: isOpen ? 270 : 0,
        overflow: "hidden", // 닫힌 상태에서 내용이 보이지 않도록 처리
      }}
    >
      <Drawer
        variant="persistent"
        open={isOpen}
        sx={{
          width: 270,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: 270,
            boxSizing: "border-box",
            border: 0,
            transition: "transform 0.3s ease", // 슬라이딩 효과
            transform: isOpen ? "translateX(0)" : "translateX(-100%)", // 열리고 닫힐 때 이동
          },
        }}
      >
        <Toolbar />
        <List sx={{ mt: 2, ml: -1 }} component="nav" aria-labelledby="nested-list-subheader">
          {Object.values(MenuList).map((menu) => renderMenu(menu, menu.title, menu.key))}
        </List>
      </Drawer>
    </Box>
  );
}
