import {
  Box,
  Card,
  CardContent,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { color } from "../../../../../theme/Color";
import { fontStyle } from "../../../../../theme/Style";
import VerticalSpacer, {
  HorizontalSpacer,
} from "../../../../../component/Spacer";
import { CommonSelect } from "../../../../../component/CommonSelect";
import { WalletDetailUiState } from "../model/WalletDetailUiState";
import { WalletDetailUiEvent } from "../model/WalletDetailUiEvent";
import LocalizedDate, {
  DateFormatType,
} from "../../../../../component/LocalizedDate";

interface InformationFieldProps {
  label: string;
  value?: string | number;
  isSelect?: boolean;
  selectValue?: string;
  menuItems?: string[];
  onChange?: (event: SelectChangeEvent) => void;
}

interface InformationCardProps {
  uiState: WalletDetailUiState;
  uiEvent: WalletDetailUiEvent;
}

function InformationField({
  label,
  value,
  isSelect = false,
  selectValue = "",
  menuItems = [],
  onChange = () => {},
}: InformationFieldProps) {
  return (
    <Box>
      <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
        {label}
      </Typography>
      <VerticalSpacer height={8} />
      {isSelect ? (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CommonSelect
            selectValue={selectValue}
            onChange={onChange}
            menuitems={menuItems}
            sx={{ height: "48px" }}
            placeholder="Select"
          />
        </Box>
      ) : (
        <Box
          sx={{
            height: "48px",
            backgroundColor: color.gray200,
            border: "1px solid",
            borderRadius: "10px",
            borderColor: color.gray300,
            px: "16px",
            py: "12px",
          }}
        >
          {value === 0 || undefined || "" ? "-" : value}
        </Box>
      )}
      <VerticalSpacer height={40} />
    </Box>
  );
}

export function InformationCard({ uiState, uiEvent }: InformationCardProps) {
  return (
    <Card
      sx={{
        width: "100%",
        padding: "0px",
        border: "1px solid",
        borderColor: color.gray300,
        borderRadius: "15px",
        boxShadow: "unset",
      }}
    >
      <CardContent
        sx={{
          padding: "24px",
          "&:last-child": {
            paddingBottom: "40px",
          },
        }}
      >
        <Typography fontStyle={fontStyle.semiboldL}>
          Wallet Information
        </Typography>
        <VerticalSpacer height={16} />
        <Box sx={{ display: "flex" }}>
          <Box sx={{ flex: 1 }}>
            <InformationField
              label="Address"
              value={uiState.walletInfo.address}
            />
            <InformationField
              label="Balance"
              value={uiState.walletInfo.balance}
            />
            <InformationField
              label="Registration Date"
              value={
                uiState.walletInfo.registrationDate === 0
                  ? "-"
                  : LocalizedDate({
                      date: uiState.walletInfo.registrationDate,
                      type: DateFormatType.numericTime,
                    })
              }
            />
            <InformationField
              label="Last Updated Date"
              value={
                uiState.walletInfo.lastUpdateDate === 0
                  ? "-"
                  : LocalizedDate({
                      date: uiState.walletInfo.lastUpdateDate,
                      type: DateFormatType.numericTime,
                    })
              }
            />
          </Box>
          <HorizontalSpacer width={20} />
          <Box sx={{ flex: 1 }}>
            <InformationField
              label="Station Name"
              value={uiState.walletInfo.stationName}
            />
            <InformationField
              label="TxCount"
              value={
                uiState.walletInfo.txCount === 0
                  ? "-"
                  : uiState.walletInfo.txCount
              }
            />
            <InformationField
              label="Appear Date"
              value={
                uiState.walletInfo.appearDate === 0
                  ? "-"
                  : LocalizedDate({
                      date: uiState.walletInfo.appearDate,
                      type: DateFormatType.numericTime,
                    })
              }
            />
            <InformationField
              selectValue={uiState.walletInfo.token}
              label="Token"
              isSelect
              menuItems={["Test1", "Test2"]}
              onChange={(event) => {
                uiEvent.selectToken(event.target.value);
              }}
            />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
