import { MenuItem, TextField, Typography } from "@mui/material";
import { color } from "../../../theme/Color";
import { fontStyle } from "../../../theme/Style";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";

export enum NationComboBoxMode {
  PHONE = "phone",
  ADDRESS = "address",
  ADMIN = "admin",
}

interface ProvinceSelectBoxProps {
  mode?: NationComboBoxMode;
  country: string;
  value?: string;
  onChange: (event: string) => void;
  error?: boolean;
  placeholder?: string;
}

const ProvinceSelectBox = ({ mode, country, value, onChange, error, placeholder }: ProvinceSelectBoxProps) => {
  const provinces = provinceList[country] || [];
  return (
    <TextField
      select
      variant={mode === NationComboBoxMode.ADMIN ? "outlined" : "standard"}
      value={value || ""}
      onChange={(event) => onChange(event.target.value)}
      // placeholder={placeholder}
      error={error}
      inputProps={{
        style:
          mode === NationComboBoxMode.ADMIN
            ? { height: "42px", zIndex: 100 }
            : {
                height: "50px",
                padding: "0px 0px",
              },
      }}
      SelectProps={{
        displayEmpty: true,
        style:
          mode === NationComboBoxMode.ADMIN
            ? { height: "42px", borderRadius: "10px" }
            : {
                height: "50px",
                padding: "0px 0px",
              },
      }}
      sx={
        mode === NationComboBoxMode.ADMIN
          ? {
              width: "250px",
              backgroundColor: "white",
              borderRadius: "10px",
              height: "42px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "10px",
                "& fieldset": {
                  borderColor: "white", // 기본 border 색상
                },
                "&:hover fieldset": {
                  borderColor: "white", // hover 시 border 색상
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white", // 포커스 시 border 색상
                  borderWidth: "1px",
                },
                "&.Mui-disabled fieldset": {
                  backgroundColor: color.gray200,
                  borderColor: color.gray300, // 비활성화 시 border 색상
                },
                fontSize: fontStyle.regularS.fontSize, // fontSize 추가
                lineHeight: fontStyle.regularS.lineHeight, // lineHeight 추가
                fontWeight: fontStyle.regularS.fontWeight, // fontWeight 추가
              },
            }
          : {
              width: "100%",
              flexGrow: 1,
              "& .MuiInput-root": {
                "&:before": {
                  // borderBottomColor: color.gray300, // 기본 테두리 색상
                },
                "&:hover:not(.Mui-disabled):before": {
                  borderBottomColor: error ? color.error : color.primary500, // 호버 시 테두리 색상
                },
                "&.Mui-focused:after": {
                  borderBottomColor: error ? color.error : color.primary500, // 포커스 시 테두리 색상
                },
                "&:after": {
                  borderBottomColor: error ? color.error : color.primary500, // 포커스가 풀린 후 기본 색상
                },
              },
            }
      }
    >
      <MenuItem value="" disabled hidden>
        <Typography fontStyle={fontStyle.regularS} color={color.gray400}>
          {NationComboBoxMode.ADMIN ? placeholder : LocalizedText("ua_b123_citystate")}
        </Typography>
      </MenuItem>
      {provinces.map((province) => (
        <MenuItem key={province} value={province}>
          {province}
        </MenuItem>
      ))}
    </TextField>
  );
};

const provinceList: { [countryCode: string]: string[] } = {
  "Korea, Republic of": [
    "Gangwon",
    "Gyeonggi",
    "Gyeongnam",
    "Gyeongbuk",
    "Gwangju",
    "Daegu",
    "Daejeon",
    "Busan",
    "Seoul",
    "Sejong",
    "Ulsan",
    "Incheon",
    "Jeonnam",
    "Jeonbuk",
    "Jeju",
    "Chungnam",
    "Chungbuk",
  ],
  Cambodia: [
    "Phnom Penh",
    "Siem Reap",
    "Banteay Meanchey",
    "Battambang",
    "Kampong Cham",
    "Kampong Chhnang",
    "Kampong Speu",
    "Kampong Thom",
    "Kampot",
    "Kandal",
    "Kep",
    "Koh Kong",
    "Kratie",
    "Mondulkiri",
    "Oddar Meanchey",
    "Pailin",
    "Preah Sihanouk",
    "Preah Vihear",
    "Prey Veng",
    "Pursat",
    "Ratanakiri",
    "Siem Reap",
    "Stung Treng",
    "Svay Rieng",
    "Takeo",
    "Tbong Khmum",
  ],
  Vietnam: [
    "An Giang",
    "Ba Ria-Vung Tau",
    "Bac Giang",
    "Bac Kan",
    "Bac Lieu",
    "Bac Ninh",
    "Ben Tre",
    "Binh Dinh",
    "Binh Duong",
    "Binh Phuoc",
    "Binh Thuan",
    "Ca Mau",
    "Can Tho",
    "Cao Bang",
    "Da Nang",
    "Dak Lak",
    "Dak Nong",
    "Dien Bien",
    "Dong Nai",
    "Dong Thap",
    "Gia Lai",
    "Ha Giang",
    "Ha Nam",
    "Ha Tinh",
    "Hai Duong",
    "Haiphong",
    "Hanoi",
    "Hau Giang",
    "Ho Chi Minh",
    "Hoa Binh",
    "Hung Yen",
    "Khanh Hoa",
    "Kien Giang",
    "Kon Tum",
    "Lai Chau",
    "Lam Dong",
    "Lang Song",
    "Lao Cai",
    "Long An",
    "Nam Dinh",
    "Nghe An",
    "Ninh Binh",
    "Ninh Thuan",
    "Phu Tho",
    "Phu Yen",
    "Quang Binh",
    "Quang Nam",
    "Quang Ngai",
    "Quang Ninh",
    "Quang Tri",
    "Soc Trang",
    "Son La",
    "Tay Ninh",
    "Thai Binh",
    "Thai Nguyen",
    "Thanh Hoa",
    "Thua Thien-Hue",
    "Tien Giang",
    "Tra Vinh",
    "Tuyen Quang",
    "Vinh Long",
    "Vinh Phuc",
    "Yen Bai",
  ],
};

export default ProvinceSelectBox;
