import { useEffect, useState } from "react";
import CommonSearch from "../../../component/CommonSearch";
import { ApiService } from "../../../restAPI/ApiService";
import { MembershipVoucherTable } from "./MembershipVoucherTable";
import { HeadCell } from "../../../component/CommonTable";
import VerticalSpacer from "../../../component/Spacer";

export function MembershipVoucher() {
  const [searchContents, setSearchContents] = useState("");
  const [docCount, setDocCount] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [rows, setRows] = useState<[]>();
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState(1);
  // const [loading, setLoading] = useState(true);

  useEffect(() => {
    ApiService.WebPost("/pw/aw/product/payHis/filter", {
      searchContents: searchContents,
      page: page + 1,
      size: rowsPerPage,
    }).then((res) => {
      setRows(res?.data.body);
      setPageCount(Math.ceil(res?.data.docCount / rowsPerPage || 1));
      setDocCount(res?.data.docCount || 0);
    });
    // .finally(() => {
    //   setLoading(false);
    // });
  }, [rowsPerPage, page]);

  useEffect(() => {
    setPage(0);
    setPageCount(1)
  }, [searchContents]);

  return (
    <>
      <CommonSearch
        placeHolder={"Parts Code, Product Name, Spec, Made"}
        searchContents={searchContents}
        setSearchContents={setSearchContents}
        onKeyPress={() => {
          ApiService.WebPost("/pw/aw/product/payHis/filter", {
            searchContents: searchContents,
            page: page + 1,
            size: rowsPerPage,
          }).then((res) => {
            setRows(res?.data.body);
            setPageCount(Math.ceil(res?.data.docCount / rowsPerPage || 1));
            setDocCount(res?.data.docCount || 0);
          });
        }}
      />
      <VerticalSpacer height={16} />
      <MembershipVoucherTable
        headCells={MembershipVoucherHeadCells}
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        rows={rows}
      />
    </>
  );
}

const MembershipVoucherHeadCells: HeadCell[] = [
  {
    disablePadding: false,
    align: "left",
    id: "no",
    label: "No",
  },
  {
    disablePadding: false,
    align: "left",
    id: "productName",
    label: "Product Name",
  },
  {
    disablePadding: false,
    align: "left",
    id: "type",
    label: "Type",
  },
  {
    disablePadding: false,
    align: "left",
    id: "price",
    label: "Price",
  },
  {
    disablePadding: false,
    align: "left",
    id: "buyer",
    label: "Buyer",
  },
  {
    disablePadding: false,
    align: "left",
    id: "available Date",
    label: "Available Date",
  },
  {
    disablePadding: false,
    align: "left",
    id: "purchase date",
    label: "Purchase Date",
  },
  {
    disablePadding: false,
    align: "left",
    id: "billing Date",
    label: "Billing Date",
  },
];
