import { useState } from "react";
import { UserNFCCreateCard } from "./UserNFCCreateCard";
import { UserNFCCreateUiState } from "./model/UserNFCCreateUiState";
import { UserNFCCreateUiEvent } from "./model/UserNFCCreateUiEvent";
import { ApiService } from "../../../../../restAPI/ApiService";
import { useNavigate } from "react-router-dom";
import CommonDialog from "../../../../../component/CommonDialog";

export function UserNFCCreate() {
  const navigate = useNavigate();
  const [uiState, setUiState] = useState(new UserNFCCreateUiState());
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [failedDialogOpen, setFailedDialogOpen] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [registeredDialogOpen, setRegisteredDialogOpen] = useState(false);

  const uiEvent: UserNFCCreateUiEvent = {
    handleUiStateChange: (key: keyof UserNFCCreateUiState, value: any) => {
      setUiState((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    },

    validateFields: () => {
      const newErrors = {
        cardIdError: uiState.cardId === "",
        reasonError: uiState.reason === "",
      };
      setUiState((prev) => {
        return { ...prev, errors: newErrors };
      });

      if (newErrors.cardIdError || newErrors.reasonError) {
        return false;
      }
      return true;
    },

    handleCreateNFC: () => {
      if (uiEvent.validateFields()) {
        setCreateDialogOpen(true);
      }
    },
  };

  const checkAndRegister = async () => {
    setCreateDialogOpen(false);
    try {
      // NFC 중복검사 하는 함수 또는 api

      const result = await createNFT();
      if (result) {
        setSuccessDialogOpen(true);
      } else {
        setFailedDialogOpen(true);
      }
    } catch (e) {
      setFailedDialogOpen(true);
    }
  };

  async function createNFT(): Promise<boolean | undefined> {
    const url = "";
    const requestBody = {};

    try {
      const res = await ApiService.webPost(url, requestBody);
      const data = res.data;
      if (typeof data === "object" && Object.keys(data).length === 0)
        return undefined;
      return data;
    } catch (e) {
      throw e;
    }
  }

  return (
    <>
      <UserNFCCreateCard uiState={uiState} uiEvent={uiEvent} />
      <CommonDialog
        open={createDialogOpen}
        handleClose={() => setCreateDialogOpen(false)}
        handleOk={checkAndRegister}
      >
        Would you like to register your NFC <br /> information?
      </CommonDialog>
      <CommonDialog
        open={failedDialogOpen}
        handleOk={() => setFailedDialogOpen(false)}
        cancleButton={false}
      >
        NFC information registration failed.
      </CommonDialog>
      <CommonDialog
        open={successDialogOpen}
        handleOk={() => setSuccessDialogOpen(false)}
        cancleButton={false}
      >
        NFC information is registered.
      </CommonDialog>
      <CommonDialog
        open={registeredDialogOpen}
        handleOk={() => setRegisteredDialogOpen(false)}
        cancleButton={false}
      >
        The card is already registered.
      </CommonDialog>
    </>
  );
}
