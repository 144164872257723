import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonList from "../../component/CommonList";
import CommonSearch from "../../component/CommonSearch";
import { fontStyle } from "../../theme/Style";
import { color } from "../../theme/Color";
import VerticalSpacer, { HorizontalSpacer } from "../../component/Spacer";
import BatteryMngTable from "./section/BatteryMngTable";
import { CommonSelect } from "../../component/CommonSelect";
import { BatteryDataType, BatteryUiState } from "./model/BatteryUiState";
import { BatteryUiEvent } from "./model/BatteryUiEvent";
import { ApiService } from "../../restAPI/ApiService";

const BatteryMngPage = () => {
  const navigate = useNavigate();
  // 페이지 관련
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState<number>(0);
  const [docCount, setDocCount] = useState<number>(0);

  // 검색어 관련
  const [searchContents, setSearchContents] = useState("");

  const [uiState, setUiState] = useState(new BatteryUiState());

  const uiEvent: BatteryUiEvent = {
    HandleUiStateChange: (key: keyof BatteryUiState, value: any) => {
      setUiState((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    },

    onClick: (batterySerialNumber: string) => {
      navigate(`/batteryMng/batteryDetail/${batterySerialNumber}`);
    },
  };

  useEffect(() => {
    ApiService.WebPost("/pw/backend/api/battery/searchCondition", {
      type: uiState.model,
      station: uiState.station,
      status: uiState.status,
      searchCondition: searchContents,
      page: page,
      size: rowsPerPage,
    })
      .then((res) => {
        const data = (res?.data.body.batteryList as BatteryDataType[]) || [];
        uiEvent.HandleUiStateChange("data", data);
        setPageCount(Math.ceil(res?.data.body.docCount / rowsPerPage) || 1);
        setDocCount(res?.data.body.docCount || 0);
      })
      .catch((e) => {
        uiEvent.HandleUiStateChange("data", []);
        setPageCount(1);
        console.error("error : ", e);
      });
  }, [uiState.model, uiState.station, uiState.status, rowsPerPage, page]);

  useEffect(() => {
    setPage(0);
  }, [searchContents, uiState.model, uiState.station, uiState.status]);

  return (
    <CommonList title="Battery">
      <CommonSearch
        placeHolder={"Battery S/N"}
        setSearchContents={setSearchContents}
        onKeyPress={() => {
          ApiService.WebPost("/pw/backend/api/battery/searchCondition", {
            type: uiState.model,
            station: uiState.station,
            status: uiState.status,
            searchCondition: searchContents,
            page: page,
            size: rowsPerPage,
          })
            .then((res) => {
              const data = (res?.data.body.batteryList as BatteryDataType[]) || [];
              uiEvent.HandleUiStateChange("data", data);
              setPageCount(Math.ceil(res?.data.body.docCount / rowsPerPage) || 1);
              setDocCount(res?.data.body.docCount || 0);
            })
            .catch((e) => {
              uiEvent.HandleUiStateChange("data", []);
              setPageCount(1);
              console.error("error : ", e);
            });
        }}
      >
        <Box sx={{ display: "flex" }}>
          <CommonSelect
            selectValue={uiState.model}
            onChange={(event) => {
              const selectedValue = event?.target.value;
              uiEvent.HandleUiStateChange("model", selectedValue === "All" ? "" : selectedValue);
            }}
            menuitems={["All", "VBP-220RGP"]}
            placeholder="Model"
          />
          <HorizontalSpacer width={12} />
          <CommonSelect
            selectValue={uiState.station}
            onChange={(event) => {
              const selectedValue = event?.target.value;
              uiEvent.HandleUiStateChange("station", selectedValue === "All" ? "" : selectedValue);
            }}
            menuitems={[
              "All",
              "st3_1_pople",
              "st3_2_pople",
              "st3_3_pople",
              "st9_woori_riverside_left",
              "st10_woori_riverside_left",
              "st11_woori_riverside_left",
            ]}
            placeholder="Station"
          />
          <HorizontalSpacer width={12} />
          <CommonSelect
            selectValue={uiState.status}
            onChange={(event) => {
              const selectedValue = event?.target.value;
              uiEvent.HandleUiStateChange("status", selectedValue === "All" ? "" : selectedValue);
            }}
            menuitems={["All", "Normal", "Defective"]}
            placeholder="Status"
          />
        </Box>
      </CommonSearch>

      <VerticalSpacer height={16} />
      <BatteryMngTable
        mngRows={uiState.data}
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        uiState={uiState}
        uiEvent={uiEvent}
      />
    </CommonList>
  );
};

export default BatteryMngPage;
