import { useEffect, useState } from "react";
import SupportRender from "./SupportRender";
import { SupportPageSideEffect } from "./SupportPageSideEffect";
import { useNavigate } from "react-router-dom";
import { SupportPageUiState } from "./SupportPageUiState";
import { SupportPageUiEvent } from "./SupportPageUiEvent";
import useAndroidBackHandler from "../../customHook/useAndroidBackHandler";
import { getCountryCode } from "../../customHook/useLocale";
import { ROUTE_PATHS } from "../../constants/RouteConstants";
import useSWR from "swr";
import { ApiService } from "../../restAPI/ApiService";
import { SupportButtonModel } from "./model/SupportButtonModel";

const SupportPage = () => {
  const navigate = useNavigate();
  const [uiState, setUiState] = useState(new SupportPageUiState());

  useAndroidBackHandler(() => {
    if (uiState.isShowEmergencyDialog) {
      uiEventHandler.hideEmergencyDialog();
    } else {
      window.Android.onBackStackCleared();
    }
  }, [uiState.isShowEmergencyDialog]);

  const { data: faqData, error: faqError } = useSWR<SupportButtonModel[]>(
    `/pw/ua/contents/faq/topView/${getCountryCode()}`,
    faqFetcher,
  );

  const { data: noticeData, error: noticeError } = useSWR<SupportButtonModel[]>(
    "/pw/ua/contents/announce/recent",
    (url: string) => noticeFetcher(url, { country: getCountryCode() }),
  );

  // `useEffect`에서 상태 업데이트를 최적화
  useEffect(() => {
    if (faqData) {
      setUiState((prev) => ({
        ...prev,
        faqTop4List: faqData,
      }));
    }
  }, [faqData]); // faqData가 변경될 때만 상태 업데이트

  useEffect(() => {
    if (noticeData) {
      setUiState((prev) => ({
        ...prev,
        noticeList: noticeData,
      }));
    }
  }, [noticeData]); // noticeData가 변경될 때만 상태 업데이트

  // 에러 처리 및 상태 업데이트 (최적화)
  useEffect(() => {
    if (faqError) {
      setUiState((prev) => ({
        ...prev,
        faqTop4List: [],
      }));
    }
  }, [faqError]);

  useEffect(() => {
    if (noticeError) {
      setUiState((prev) => ({
        ...prev,
        noticeList: [],
      }));
    }
  }, [noticeError]);

  const uiEventHandler: SupportPageUiEvent = {
    showEmergencyDialog: () => {
      if (window.Android) {
        window.Android.isShownDialog(true);
      }

      if (window.webkit) {
        window.webkit.messageHandlers.isShownDialog.postMessage(true);
      }

      setUiState((prev) => ({
        ...prev,
        isShowEmergencyDialog: true,
      }));
    },

    hideEmergencyDialog: function (): void {
      if (window.Android) {
        window.Android.isShownDialog(false);
      }

      if (window.webkit) {
        window.webkit.messageHandlers.isShownDialog.postMessage(false);
      }

      setUiState((prev) => ({
        ...prev,
        isShowEmergencyDialog: false,
      }));
    },
  };

  const sideEffectHandlers: SupportPageSideEffect = {
    callEmergency: (number: string) => {
      window.location.href = `tel:${number}`;
    },
    navigate: function (target: string, state?: any): void {
      if (state !== undefined) {
        navigate(target, { state: { id: state.state.id } });
      } else {
        navigate(target);
      }
    },
    moveToFaqDetail: function (id: string, uuid: string): void {
      navigate(ROUTE_PATHS.FAQ_DETAIL, { state: { id, uuid } });
    },
  };

  return (
    <SupportRender
      uiState={uiState}
      uiEvent={uiEventHandler}
      sideEffect={sideEffectHandlers}
    />
  );
};

// fetcher 함수 정의
const faqFetcher = async (url: string) => {
  return ApiService.post(url)
    .then((res) => res.data.body)
    .catch((error) => {
      throw error;
    });
};

// fetcher 함수 정의
const noticeFetcher = async (url: string, body: any) => {
  return ApiService.post(url, body)
    .then((res) => res.data.body)
    .catch((error) => {
      throw error;
    });
};

export default SupportPage;
