import { useEffect, useState } from "react";
import { CustomerSupportRegistrationPageUiState } from "./model/CustomerSupportRegistrationPageUiState";
import { CustomerSupportRegistrationPageUiEvent } from "./model/CustomerSupportRegistrationPageUiEvent";
import { CustomerSupportRegistrationPageSideEffect } from "./model/CustomerSupportRegistrationPageSideEffect";
import CustomerSupportRegistrationRender from "./CustomerSupportRegistrationRender";
import { InquiryResponse } from "../../MobileInquiry/registration/model/InquiryResponse";
import axios from "axios";
import { ApiService } from "../../../restAPI/ApiService";
import useAndroidBackHandler from "../../../customHook/useAndroidBackHandler";
import { CustomerSupportRegistrationBody } from "./model/CustomerSupportRegistrationBody";
import { CustomerSupportStationUiModel } from "./model/CustomerSupportStationUiModel";
import { CustomerSupportStationMapper } from "./mapper/CustomerSupportStationMapper";
import { Category } from "../../MobileSupport/faq/list/model/Category";
import { getCountryCode, getLanguageCode } from "../../../customHook/useLocale";

export default function CustomerSupportRegistrationPage() {
  const [uiState, setUiState] = useState(
    new CustomerSupportRegistrationPageUiState(),
  );
  const [preferredStationList, setPreferredStationList] =
    useState<CustomerSupportStationUiModel[]>();
  const [progress, setProgress] = useState(false);

  useAndroidBackHandler(() => {
    if (progress) {
      return;
    }
    if (uiState.isSubmitDialog) {
      window.history.back();
      changeSubmitDialog(false);
      return;
    }
    if (uiState.isBackDialog) {
      changeExitPageDialog(false);
    } else {
      changeExitPageDialog(true);
    }
  }, [progress, uiState.isBackDialog, uiState.isSubmitDialog]);

  useEffect(() => {
    getVinList().then((vinList) => {
      setUiState((prev) => {
        return {
          ...prev,
          vinList: vinList.map((vin) => {
            return {
              label: vin,
              isSelected: false,
            };
          }),
        };
      });
    });
  }, []);

  useEffect(() => {
    getPreferredStation().then((stations) => {
      setUiState((prev) => {
        return {
          ...prev,
          preferredStationCategoryList: stations.map((station) => {
            return {
              label: station.serviceAreaName,
              isSelected: false,
            };
          }),
        };
      });
    });
  }, []);

  useEffect(() => {
    if (
      uiState.preferredDate !== undefined &&
      uiState.preferredTime !== undefined
    ) {
      combineDateTime(uiState.preferredDate, uiState.preferredTime);
    }
  }, [uiState.preferredDate, uiState.preferredTime]);

  const uiEvent: CustomerSupportRegistrationPageUiEvent = {
    onClickSubmit: function (): void {
      const hasError = validateForm();
      if (hasError) {
        return;
      } else {
        sideEffect.postSubmit();
      }
    },
    onChangeTitleValue: function (value: string): void {
      setUiState((prev) => {
        return {
          ...prev,
          titleInputValue:
            value.length <= uiState.titleInputMaxLength
              ? value
              : value.substring(0, uiState.titleInputMaxLength),
        };
      });
    },
    onChangeContentValue: function (value: string): void {
      setUiState((prev) => {
        return {
          ...prev,
          contentInputValue:
            value.length <= uiState.contentInputMaxLength
              ? value
              : value.substring(0, uiState.contentInputMaxLength),
        };
      });
    },
    onSelectVIN: function (vin: string): void {
      setUiState((prev) => {
        return {
          ...prev,
          vinList: prev.vinList.map((it) => {
            return {
              ...it,
              isSelected: it.label === vin,
            };
          }),
        };
      });
    },
    onSelectPreferredStation: function (station: string): void {
      uiEvent.onSelectPreferredTime(undefined); // 선택한 시간 초기화
      uiEvent.onSelectPreferredDate(undefined); // 선택한 날짜 초기화

      const selectedStation = preferredStationList?.find(
        (item) => item.serviceAreaName === station,
      );

      setUiState((prev) => {
        return {
          ...prev,
          preferredStationCategoryList: prev.preferredStationCategoryList.map(
            (it) => {
              return {
                ...it,
                isSelected: it.label === station,
              };
            },
          ),
          openTime: selectedStation?.openTime ?? "", // 선택된 station의 openTime 설정
          closeTime: selectedStation?.closeTime ?? "", // 선택된 station의 closeTime 설정
        };
      });
    },
    onClickAddFile: function (url: string): void {
      setUiState((prev) => {
        return {
          ...prev,
          fileUrls: [...prev.fileUrls, url],
        };
      });
    },
    onClickDeleteFile: function (index: number): void {
      setUiState((prev) => {
        return {
          ...prev,
          fileUrls: prev.fileUrls.filter((_, i) => i !== index),
        };
      });
    },
    onClearTitleValue: function (): void {
      setUiState((prev) => {
        return {
          ...prev,
          titleInputValue: "",
        };
      });
    },
    onClearContentValue: function (): void {
      setUiState((prev) => {
        return {
          ...prev,
          contentInputValue: "",
        };
      });
    },
    onSelectPreferredDate: function (date: number | undefined): void {
      uiEvent.onSelectPreferredTime(undefined); // 선택한 시간 초기화
      setUiState((prev) => {
        return {
          ...prev,
          preferredDate: date,
        };
      });

      // 선택 불가능한 시간 조회 API 호출
      if (date === undefined) return;
      getBlockedStationTime(
        date,
        preferredStationList?.find((it) => {
          return (
            it.serviceAreaName ===
            uiState.preferredStationCategoryList.find((it) => it.isSelected)
              ?.label
          );
        })?.preferredStationId ?? 0,
      );
    },
    onSelectPreferredTime: function (time: number | undefined): void {
      setUiState((prev) => {
        return {
          ...prev,
          preferredTime: time,
        };
      });
    },
    onBack: function (): void {
      changeExitPageDialog(true);
    },
    onClickConfirmExitPageDialog: function (): void {
      changeExitPageDialog(false);
      window.history.back();
    },
    onClickCancelExitPageDialog: function (): void {
      changeExitPageDialog(false);
    },
    onClickConfirmSubmitDialog: function (): void {
      setUiState((prev) => {
        return {
          ...prev,
          isSubmitDialog: false,
        };
      });
      window.history.back();
    },
    onClickCancelSubmitDialog: function (): void {
      setUiState((prev) => {
        return {
          ...prev,
          isSubmitDialog: false,
        };
      });
    },
  };

  function changeExitPageDialog(state: boolean): void {
    setUiState((prev) => {
      return {
        ...prev,
        isBackDialog: state,
      };
    });
  }

  function changeSubmitDialog(state: boolean): void {
    setUiState((prev) => {
      return {
        ...prev,
        isSubmitDialog: state,
      };
    });
  }

  const sideEffect: CustomerSupportRegistrationPageSideEffect = {
    openCamera: function (): void {
      if (window.Android) {
        window.Android.openCameraEvent();
      }
      if (window.webkit) {
        window.webkit.messageHandlers.openCamera.postMessage("openCamera");
      }
    },

    postSubmit: function (): void {
      setProgress(true);

      function getSelectedCategoryLabel(category: Category[]): string | null {
        const selectedCategory = category.find(
          (category) => category.isSelected,
        );
        return selectedCategory ? selectedCategory.label : null;
      }

      const currentSelectedCategoryLabel = getSelectedCategoryLabel(
        uiState.preferredStationCategoryList,
      );
      const currentSelectedPreferredStationId = preferredStationList?.find(
        (it) => {
          return it.serviceAreaName == currentSelectedCategoryLabel;
        },
      )?.preferredStationId;
      if (currentSelectedPreferredStationId == null) return;
      postInquiry(
        uiState.fileUrls,
        uiState.titleInputValue,
        uiState.contentInputValue,
        localStorage.getItem("uuid") ?? "",
        currentSelectedPreferredStationId,
        uiState.preferredDateTime ?? 0,
        uiState.vinList.find((it) => it.isSelected)?.label ?? "",
      )
        .then((res) => {
          if (res.result) {
            changeSubmitDialog(true);
          } else {
            // TODO 실패 처리
            console.log("failed to post inquiry");
          }
        })
        .catch((e) => {
          console.error("unknown error", e);
        })
        .finally(() => {
          setProgress(false);
        });
    },
  };

  async function postInquiry(
    fileUrls: string[],
    title: string,
    content: string,
    uuid: string,
    preferredStationNumber: number,
    preferredDate: number,
    vin: string,
  ): Promise<InquiryResponse> {
    const url = "/mobile/pw/ua/contents/cs/create";
    const IMAGE_URL_KEY = "imageUrls";
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    const body: CustomerSupportRegistrationBody =
      new CustomerSupportRegistrationBody(
        title,
        content,
        uuid,
        preferredStationNumber,
        preferredDate,
        vin,
        getCountryCode(),
        getLanguageCode(),
      );

    try {
      const formData = new FormData();
      formData.append(
        "body",
        new Blob([JSON.stringify(body)], { type: "application/json" }),
      );

      if (fileUrls.length === 0) {
        formData.append(IMAGE_URL_KEY, "");
      } else {
        fileUrls.forEach((image, index) => {
          const imageFileExtension = "jpeg";
          const imageFileName = `cs_image_${index}_${
            localStorage.getItem("uuid") || ""
          }.${imageFileExtension}`;
          const base64Data = image.replace(/^data:image\/\w+;base64,/, "");
          const byteCharacters = atob(base64Data);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], {
            type: `image/${imageFileExtension}`,
          });

          formData.append(IMAGE_URL_KEY, blob, imageFileName);
        });
      }

      const request = await axios.post(url, formData, { headers });
      return request.data as InquiryResponse;
    } catch (e) {
      throw e;
    }
  }

  async function getVinList(): Promise<string[]> {
    const userId = localStorage.getItem("uuid") ?? "";
    const url = `/pw/ua/contents/cs/vinList`;
    const requestBody = {
      uuid: userId,
    };
    try {
      const response = await ApiService.MobilePost(url, requestBody);
      return response?.data.body ?? [];
    } catch (e) {
      console.log("failed to get VIN list", e);
      return [];
    }
  }

  async function getPreferredStation(): Promise<
    CustomerSupportStationUiModel[]
  > {
    const url = `/pw/backend/api/svc/cs_center_list`;
    const body = {
      location: true,
    };
    try {
      const response = await ApiService.post(url, body);
      const data = response?.data?.body;
      if (Array.isArray(data) && data.length > 0) {
        const preferredStationList = data.map(
          CustomerSupportStationMapper.toUiModel,
        );
        setPreferredStationList(preferredStationList);
        return preferredStationList;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  }

  function combineDateTime(date: number, time: number) {
    const dateObj = new Date(date);
    const timeObj = new Date(time);

    dateObj.setHours(timeObj.getHours());
    dateObj.setMinutes(timeObj.getMinutes());
    dateObj.setSeconds(0);

    setUiState((prev) => {
      return {
        ...prev,
        preferredDateTime: dateObj.getTime(),
      };
    });
  }

  function validateForm(): boolean {
    let hasError: boolean;
    const newUiState = { ...uiState };
    const selectedVIN = uiState.vinList.find((vin) => vin.isSelected);
    const selectedStation = uiState.preferredStationCategoryList.find(
      (station) => station.isSelected,
    );

    newUiState.vinError = !selectedVIN;
    newUiState.preferredStationError = !selectedStation;
    newUiState.titleInputError = uiState.titleInputValue.trim() === "";
    newUiState.preferredDateError = uiState.preferredDate === undefined;
    newUiState.preferredTimeError = uiState.preferredTime === undefined;
    hasError =
      newUiState.vinError ||
      newUiState.preferredStationError ||
      newUiState.preferredDateError ||
      newUiState.preferredTimeError ||
      uiState.titleInputValue.trim() === "";

    setUiState(newUiState);
    return hasError;
  }

  // 선택 불가능한 시간 조회 API
  async function getBlockedStationTime(
    datetime: number,
    preferredStationId: number,
  ) {
    const url = "/pw/ua/contents/cs/checkTime";
    const requestBody = {
      datetime: datetime,
      preferredStationId: preferredStationId,
    };

    setProgress(true);
    try {
      await ApiService.post(url, requestBody).then((res) => {
        const data = res.data?.body;
        if (data != null) {
          setUiState((prev) => {
            return {
              ...prev,
              blockedTime: data,
            };
          });
        } else {
          console.log("failed to get blocked station time");
        }
      });
    } catch (e) {
      console.log("failed to get blocked station time", e);
    } finally {
      setProgress(false);
    }
  }

  return (
    <CustomerSupportRegistrationRender
      uiState={uiState}
      uiEvent={uiEvent}
      sideEffect={sideEffect}
      progress={progress}
    />
  );
}
