import { useEffect, useState } from "react";
import { getLocale } from "../../../../customHook/useLocale";
import { Box, MenuItem, Select, styled } from "@mui/material";
import { color } from "../../../../theme/Color";
import { fontStyle } from "../../../../theme/Style";
import { LocalizedText } from "../../../../di/LanguageRepositoryProvider";

interface CustomTimePickerProps {
  onSelectTime: (time: number) => void;
  selectedTime: number | undefined;
  selectValueError?: boolean;
  selectedDate: undefined | number;
  openTime: string;
  closeTime: string;
  blockedTime?: number[];
  disabled?: boolean;
}

const CustomStyledMenuItem = styled(MenuItem)(({ theme }) => ({
  margin: "4px 8px",
  borderRadius: "10px",
  maxHeight: "38px",
  "&.Mui-selected": {
    backgroundColor: `${color.primary50} !important`, // 선택된 항목 배경색 변경
  },
}));

export default function CustomTimePicker({
  onSelectTime,
  selectedTime,
  selectValueError = false,
  selectedDate,
  openTime,
  closeTime,
  blockedTime,
  disabled,
}: CustomTimePickerProps) {
  const placeHolderText = LocalizedText("ua_f311_selecttime");
  const [isFocused, setIsFocused] = useState(false);
  const [currentSelectedTime, setCurrentSelectedTime] = useState<
    number | undefined
  >(selectedTime);
  const borderStyle = `1px solid ${
    isFocused
      ? color.primary500
      : selectValueError
        ? color.error
        : color.gray300
  }`;

  useEffect(() => {
    setCurrentSelectedTime(selectedTime);
  }, [selectedTime]);

  const formatLocalizedTime = (date: Date) => {
    const locale = getLocale();
    return new Intl.DateTimeFormat(locale, {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }).format(date);
  };

  const generateTimeSlots = (selectedDate: undefined | number) => {
    const times = [];
    const [openHour, openMinute] = openTime.split(":").map(Number);
    const [closeHour, closeMinute] = closeTime.split(":").map(Number);

    let startDate = new Date();
    startDate.setHours(openHour, openMinute, 0, 0); // 오픈 시간 설정
    const endDate = new Date();
    endDate.setHours(closeHour, closeMinute, 0, 0); // 클로즈 시간 설정

    const currentTime = new Date();

    const isToday =
      selectedDate === undefined ||
      new Date(selectedDate).toDateString() === currentTime.toDateString();

    while (startDate <= endDate) {
      const timeInMilliseconds = startDate.getTime();

      // 블록된 시간 비교: 시간(HH:mm)만 비교
      const isBlocked = blockedTime?.some((blocked) => {
        const blockedDate = new Date(blocked);
        return (
          blockedDate.getHours() === startDate.getHours() &&
          blockedDate.getMinutes() === startDate.getMinutes()
        );
      });

      const isPast = isToday && timeInMilliseconds < currentTime.getTime();

      if (!isBlocked && !isPast) {
        times.push({
          label: formatLocalizedTime(startDate),
          value: timeInMilliseconds,
        });
      }

      startDate = new Date(startDate.getTime() + 30 * 60 * 1000);
    }
    return times;
  };

  const timeSlots = generateTimeSlots(selectedDate);

  const handleTimeChange = (event: any) => {
    const selectedHour = event.target.value;
    onSelectTime(selectedHour);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Select
        sx={{
          width: "100%",
          height: "38px",
          "& .MuiInputBase-input": {
            fontStyle: fontStyle.bodyMr,
            color: selectedTime === undefined ? color.gray500 : color.gray800,
            border: `0px`,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: borderStyle,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: borderStyle,
          },
        }}
        value={currentSelectedTime ?? ""}
        variant="outlined"
        disabled={disabled || timeSlots?.length === 0}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onChange={handleTimeChange}
        displayEmpty={true}
        renderValue={(selected) => {
          if (selected === undefined || selected === 0) return placeHolderText;
          const foundSlot = timeSlots.find((it) => it.value === selected);
          return foundSlot ? foundSlot.label : placeHolderText;
        }}
      >
        {timeSlots?.map((it, index) => (
          <CustomStyledMenuItem key={index} value={it.value}>
            {it.label}
          </CustomStyledMenuItem>
        ))}
      </Select>
    </Box>
  );
}
