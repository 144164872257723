import { TableRow } from "@mui/material";
import { color } from "../../../theme/Color";
import { fontStyle } from "../../../theme/Style";
import { CouponHistoryTableCell } from "./CouponHistoryTableCell";
import { Text } from "../../../component/Text";
import LocalizedDate, {
  DateFormatType,
} from "../../../component/LocalizedDate";
import { PaymentManagementCellData } from "../model/PaymentManagementCellData";
import { PaymentValidType, Validbadge } from "./ValidBadge";

export interface CouponHistoryRow {
  no: number;
  name: string;
  type: string;
  // valid: boolean;
  owner: string;
  useDate: number;
  expiredDate: number;
  registrationDate: number;
}

interface CouponHistoryTableRowProps {
  row: CouponHistoryRow;
  docCount?: number | undefined;
}

export function CouponHistoryTableRow({
  row,
  docCount,
}: CouponHistoryTableRowProps) {
  const paymentCellData: PaymentManagementCellData[] = [
    new PaymentManagementCellData(
      `${docCount ? docCount - row.no + 1 : ""}`,
      "left",
      "36px",
      "24px"
    ),
    new PaymentManagementCellData(`${row.name}`, "left", "209px"),
    new PaymentManagementCellData(`${row.type}`, "left", "102px"),
    // new PaymentManagementCellData(
    //   row.valid ? (
    //     <Validbadge validType={PaymentValidType.YES} />
    //   ) : (
    //     <Validbadge validType={PaymentValidType.NO} />
    //   ),
    //   "left",
    //   "59px"
    // ),
    new PaymentManagementCellData(`${row.owner || ``}`, "left", "78px"),
    new PaymentManagementCellData(
      row.useDate == null
        ? ""
        : LocalizedDate({
            date: row.useDate,
            type: DateFormatType.numericTime,
          }),
      "left",
      "120px"
    ),
    new PaymentManagementCellData(
      row.expiredDate == null
        ? ""
        : LocalizedDate({
            date: row.expiredDate,
            type: DateFormatType.YearMonthDay,
          }),
      "left",
      "92px"
    ),
    new PaymentManagementCellData(
      row.registrationDate == null
        ? ""
        : LocalizedDate({
            date: row.registrationDate,
            type: DateFormatType.numericTime,
          }),
      "left",
      "120px"
    ),
  ];
  return (
    <TableRow>
      {paymentCellData.map((data: PaymentManagementCellData, index: number) => (
        <CouponHistoryTableCell
          key={index}
          sx={{
            paddingLeft: data.paddingLeft,
            paddingRight: data.paddingRight,
            width: data.width || "auto",
            ...(index === PaymentManagementCellData.length - 6
              ? { flex: 1, maxWidth: "66px" }
              : {}),
          }}
          childrenContainerSx={{ width: data.width }}
          align={data.align}
          children={
            typeof data.value === "string" ? (
              <Text
                sx={{
                  fontStyle: fontStyle.regularXS,
                  color: color.gray800,
                }}
                text={data.value}
              />
            ) : (
              data.value
            )
          }
        />
      ))}
    </TableRow>
  );
}
