import { Box, Typography } from "@mui/material";
import CommonTextField from "../../../component/CommonTextField";
import { Text } from "../../../component/Text";
import { color } from "../../../theme/Color";
import { fontStyle } from "../../../theme/Style";
import { useEffect, useState } from "react";
import { padStart } from "lodash";
import { LabeledPaper } from "./LabeledPaper";
import VerticalSpacer, { HorizontalSpacer } from "../../../component/Spacer";
import { ADMIN_CONFIG } from "../../../constants/AdminConfig";

interface StatusAlramProps {
  value: string; // Expecting values like "10000000", "01000000", etc.
}

const StatusAlram = ({ value }: StatusAlramProps) => {
  // 예시 사용
  // value = "C0040004"; //실 서비스 시 각주 처리
  console.log(value);
  let binaryResult = hexToBinary(value);

  function hexToBinary(hexValue: string) {
    // 16진수 값을 8비트씩 나누어 이진수로 변환
    let binaryValues = [];

    // 16진수 값을 2자리씩 나누어 변환
    for (let i = 0; i < hexValue.length; i += 2) {
      let hexByte = hexValue.slice(i, i + 2); // slice()를 사용하여 2자리 16진수 추출
      let binaryByte = parseInt(hexByte, 16).toString(2).padStart(8, "0"); // 16진수를 이진수로 변환하고 8비트로 맞춤
      binaryValues.push(binaryByte); // 이진수 값을 배열에 추가
    }
    return binaryValues;
  }

  useEffect(() => {
    setStatusBinary(binaryResult[0]); //binaryResult 1번째 요소는 warning이나 error를 확인할 수 있게해주는 2진수 비트
    setMessageBinary([binaryResult[1], binaryResult[2], binaryResult[3]]); //binaryResult 2,3,4번째 요소는 warning이나 error를 메세지를 확인할 수 있게해주는 2진수 비트
  }, [value]);

  const [statusBinary, setStatusBinary] = useState<string>();
  const [messageBinary, setMessageBinary] = useState<Array<string>>([]);

  const [status, setStatus] = useState("");
  const [errorText, setErrorText] = useState<Array<string>>([]);

  useEffect(() => {
    const binData = padStart(statusBinary, 8, "0");
    if ("0" == binData[0] && "0" == binData[1]) {
      setStatus(ADMIN_CONFIG.VALUE.HYPHEN);
    } else if ("0" == binData[0] && "1" == binData[1]) {
      setStatus(ADMIN_CONFIG.VALUE.WARNING);
    } else if ("1" == binData[0] && "0" == binData[1]) {
      setStatus(ADMIN_CONFIG.VALUE.ERROR);
    } else if ("1" == binData[0] && "1" == binData[1]) {
      setStatus(ADMIN_CONFIG.VALUE.ERROR + ADMIN_CONFIG.VALUE.WARNING);
    }
  }, [statusBinary]);

  useEffect(() => {
    const binData1 = padStart(messageBinary[0], 8, "0");
    const binData2 = padStart(messageBinary[1], 8, "0");
    const binData3 = padStart(messageBinary[2], 8, "0");
    let errors: string[] = []; // 오류 메시지를 담을 배열

    if ("1" == binData1[0]) errors.push(ADMIN_CONFIG.VALUE.ERROR_MESSAGE.UT_PROTECTION_CHARGE);
    if ("1" == binData1[1]) errors.push(ADMIN_CONFIG.VALUE.ERROR_MESSAGE.UT_WARNING_CHARGE);
    if ("1" == binData1[2]) errors.push(ADMIN_CONFIG.VALUE.ERROR_MESSAGE.OT_PROTECTION_CHARGE);
    if ("1" == binData1[3]) errors.push(ADMIN_CONFIG.VALUE.ERROR_MESSAGE.OT_WARNING_CHARGE);
    if ("1" == binData1[4]) errors.push(ADMIN_CONFIG.VALUE.ERROR_MESSAGE.PACK_OV_PROTECTION);
    if ("1" == binData1[5]) errors.push(ADMIN_CONFIG.VALUE.ERROR_MESSAGE.PACK_OV_WARNING);
    if ("1" == binData1[6]) errors.push(ADMIN_CONFIG.VALUE.ERROR_MESSAGE.PACK_UV_PROTECTION);
    if ("1" == binData1[7]) errors.push(ADMIN_CONFIG.VALUE.ERROR_MESSAGE.PACK_UV_WARNING);

    if ("1" == binData2[2]) errors.push(ADMIN_CONFIG.VALUE.ERROR_MESSAGE.CELL_DELTA_V_ERROR);
    if ("1" == binData2[3]) errors.push(ADMIN_CONFIG.VALUE.ERROR_MESSAGE.COMMUNICATION_PROTECTION);
    if ("1" == binData2[4]) errors.push(ADMIN_CONFIG.VALUE.ERROR_MESSAGE.OC_PROTECTION_DISCHARGE);
    if ("1" == binData2[5]) errors.push(ADMIN_CONFIG.VALUE.ERROR_MESSAGE.OC_WARNING_DISCHARGE);
    if ("1" == binData2[6]) errors.push(ADMIN_CONFIG.VALUE.ERROR_MESSAGE.OC_PROTECTION_CHARGE);
    if ("1" == binData2[7]) errors.push(ADMIN_CONFIG.VALUE.ERROR_MESSAGE.OC_WARNING_CHARGE);

    if ("1" == binData3[0]) errors.push(ADMIN_CONFIG.VALUE.ERROR_MESSAGE.UT_PROTECTION_DISCHARGE);
    if ("1" == binData3[1]) errors.push(ADMIN_CONFIG.VALUE.ERROR_MESSAGE.UT_WARNING_DISCHARGE);
    if ("1" == binData3[2]) errors.push(ADMIN_CONFIG.VALUE.ERROR_MESSAGE.OT_PROTECTION_DISCHARGE);
    if ("1" == binData3[3]) errors.push(ADMIN_CONFIG.VALUE.ERROR_MESSAGE.OT_WARNING_DISCHARGE);
    if ("1" == binData3[4]) errors.push(ADMIN_CONFIG.VALUE.ERROR_MESSAGE.OV_PROTECTION);
    if ("1" == binData3[5]) errors.push(ADMIN_CONFIG.VALUE.ERROR_MESSAGE.OV_WARNING);
    if ("1" == binData3[6]) errors.push(ADMIN_CONFIG.VALUE.ERROR_MESSAGE.UV_PROTECTION);
    if ("1" == binData3[7]) errors.push(ADMIN_CONFIG.VALUE.ERROR_MESSAGE.UV_WARNING);

    setErrorText(errors); // 한 번에 상태 업데이트
  }, [messageBinary]);

  return (
    <>
      <Text sx={{ fontStyle: fontStyle.boldXS, color: color.gray600 }} text={"Alarm"} />
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: "16px" }}>
        <Box
          sx={{
            borderRadius: "10px",
            border: 1,
            borderColor: color.gray300,
            height: "48px",
            mt: "8px",
            width: "50%",
            px: "14px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {status == ADMIN_CONFIG.VALUE.HYPHEN && status}
          {status == ADMIN_CONFIG.VALUE.WARNING && (
            <Box
              sx={{
                backgroundColor: color.warningWaiting2Bg,
                px: "8px",
                borderRadius: "5px",
                height: "26px",
                fontStyle: fontStyle.boldXS,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: color.warning2,
              }}
            >
              {ADMIN_CONFIG.VALUE.WARNING}
            </Box>
          )}
          {status == ADMIN_CONFIG.VALUE.ERROR && (
            <Box
              sx={{
                backgroundColor: color.errorBg,
                px: "8px",
                borderRadius: "5px",
                height: "26px",
                fontStyle: fontStyle.boldXS,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: color.error,
              }}
            >
              {ADMIN_CONFIG.VALUE.ERROR}
            </Box>
          )}
          {status == ADMIN_CONFIG.VALUE.ERROR + ADMIN_CONFIG.VALUE.WARNING && (
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  backgroundColor: color.warningWaiting2Bg,
                  px: "8px",
                  borderRadius: "5px",
                  height: "26px",
                  fontStyle: fontStyle.boldXS,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: color.warning2,
                }}
              >
                {ADMIN_CONFIG.VALUE.WARNING}
              </Box>
              <HorizontalSpacer width={8} />
              <Box
                sx={{
                  backgroundColor: color.errorBg,
                  px: "8px",
                  borderRadius: "5px",
                  height: "26px",
                  fontStyle: fontStyle.boldXS,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: color.error,
                }}
              >
                {ADMIN_CONFIG.VALUE.ERROR}
              </Box>
            </Box>
          )}
        </Box>
        <CommonTextField
          value={value}
          onChange={(event) => {}}
          sx={{ display: "flex", flexGrow: 1, height: "48px", mt: "8px", width: "50%" }}
          InputProps={{
            sx: {
              height: "48px",
            },
          }}
          disabled
        />
      </Box>
      <VerticalSpacer height={8} />
      <Box sx={{ borderRadius: "10px", border: 1, borderColor: color.gray300, height: "214px", p: 2 }}>
        {errorText.map((error, index) => (
          <Box key={index}>
            <Typography fontStyle={fontStyle.bodySr}>{error}</Typography>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default StatusAlram;
