export class EcoPlatformWalletUiState {
  searchContents: string;
  infoTipOpen: boolean;
  address: string;
  did: string;
  constructor(
    searchContents: string = "",
    infoTipOpen: boolean = false,
    address: string = "Address test",
    did: string = "did test"
  ) {
    this.searchContents = searchContents;
    this.infoTipOpen = infoTipOpen;
    this.address = address;
    this.did = did;
  }
}
