export class FAQDetailUiState {
  category: string;
  title: string;
  content: string;
  constructor(category: string, title: string, content: string) {
    this.category = category;
    this.title = title;
    this.content = content;
  }
}
