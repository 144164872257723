import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import CommonList from "../../../../component/CommonList";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import AdminDetailGrid from "./AdminDetailGrid";
import { useEffect, useState } from "react";
import { ApiService } from "../../../../restAPI/ApiService";
import CommonDialog from "../../../../component/CommonDialog";
import ProgressBar from "../../../../component/ProgressBar";
import VerticalSpacer from "../../../../component/Spacer";
import { color } from "../../../../theme/Color";
import { CommonWebHeaderButton } from "../../../../component/CommonWebHeaderButton";
import { ICON_TRASH } from "../../../../constants/imagePath";
import { DeleteResponse } from "./model/DeleteResponse";

const AdminDetailPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const userId = pathname.split("/").pop(); // URL에서 마지막 부분을 추출
  const [isLoading, setIsLoading] = useState(false);

  const [adminDetailData, setAdminDetailData] = useState({
    name: "",
    countryNumber: "",
    status: "",
    type: "",
    role: "",
    gender: "",
    addressCountry: "",
    addressDetail: "",
    addressProvince: "",
  });
  const updateAdminDetailData = (key: string, value: any) => {
    setAdminDetailData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleNavigate = (route: string) => {
    navigate(route);
    // 페이지를 새로고침
    window.location.reload();
  };

  useEffect(() => {
    ApiService.WebPost("/pw/users/admin/read", {
      uuid: userId,
    }).then((res) => {
      console.log(res?.data.body);
      setAdminDetailData(res?.data.body);
    });
  }, [userId]);

  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  async function handleDelete(): Promise<DeleteResponse | undefined> {
    const url = "/pw/users/admin/delete";
    const requestBody = { uuid: userId };
    try {
      const res = await ApiService.WebPost(url, requestBody);
      const data = res?.data;
      console.log("handledeleteon");
      if (typeof data === "object" && Object.keys(data).length === 0)
        return undefined;
      return data;
    } catch (e) {
      return undefined;
    }
  }

  const deleteAdmin = async () => {
    setDeleteDialogOpen(false);
    try {
      const deleteAdmin = await handleDelete();
      console.log("delete", deleteAdmin);
      navigate("/adminMng");
      window.location.reload();
      if (deleteAdmin === undefined) {
        console.log("delete error");
        return;
      }
    } catch (e) {
      console.log("delete error!", e);
    }
  };

  const HeaderButton = () => {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Button
          sx={{
            height: 40,
            backgroundColor: "#5D38E5",
            color: "white",
            borderRadius: 5,
            px: "20px",
            ":hover": {
              backgroundColor: "#A38BFC",
            },
            textTransform: "none",
          }}
          onClick={() => {
            // console.log(adminDetailData);

            setUpdateDialogOpen(true);
          }}
        >
          Update
        </Button>
        {/* &nbsp; &nbsp;
        <Typography fontSize={30} sx={{ mb: 0.5, color: "gray" }}>
          |
        </Typography>
        <IconButton sx={{ color: "red" }}>
          <DeleteOutlineOutlinedIcon fontSize="medium" />
        </IconButton> */}
      </Box>
    );
  };

  const HeaderBackButton = () => {
    return (
      <IconButton
        sx={{
          ":hover": { backgroundColor: "#F2F2F2" },
          mb: 0.5,
          mr: 1,
        }}
        onClick={() => {
          handleNavigate("/adminMng");
        }}
      >
        <ArrowBackIosNewIcon fontSize="medium" />
      </IconButton>
    );
  };

  return (
    <CommonList
      title="Admin Details"
      setModal={null}
      headerButton={
        <Box sx={{ display: "flex", gap: "12px" }}>
          <CommonWebHeaderButton
            buttonContent="Delete"
            sx={{
              backgroundColor: color.white,
              fontStyle: color.error,
              ":hover": {
                backgroundColor: color.red01,
              },
            }}
            icon={<img src={ICON_TRASH}></img>}
            height="45px"
            textColor={color.error}
            onClick={() => {
              setDeleteDialogOpen(true);
            }}
          />
          {HeaderButton()}
        </Box>
      }
      headerBackButton={HeaderBackButton()}
    >
      {isLoading && (
        <>
          <LinearProgress color="secondary" />
          <VerticalSpacer height={16} />
        </>
      )}
      <Box>
        <AdminDetailGrid
          adminDetailData={adminDetailData}
          updateAdminDetailData={updateAdminDetailData}
        />
      </Box>
      <CommonDialog
        open={updateDialogOpen}
        handleClose={() => {
          setUpdateDialogOpen(false);
        }}
        handleOk={() => {
          setIsLoading(true);
          ApiService.WebPost("/pw/users/admin/update", adminDetailData).then(
            (res) => {
              console.log(res?.data);
              setIsLoading(false);
            }
          );
          // console.log(adminDetailData);
          setUpdateDialogOpen(false);
        }}
      >
        Would you like to update?
      </CommonDialog>
      <CommonDialog
        open={deleteDialogOpen}
        handleClose={() => {
          setDeleteDialogOpen(false);
        }}
        handleOk={() => {
          deleteAdmin();
        }}
      >
        Would you like to delete the admin?
      </CommonDialog>
    </CommonList>
  );
};

export default AdminDetailPage;
