import { Box, Typography } from "@mui/material";
import {
  ICON_RIGHT_ARROW_GRAY600,
  IMAGE_PIN_PURPLE,
} from "../../../../constants/appImagePath";
import { HorizontalSpacer } from "../../../../component/Spacer";
import { Text } from "../../../../component/Text";
import { color } from "../../../../theme/Color";
import { fontStyle } from "../../../../theme/Style";

interface ServiceItemProps {
  serviceArea: string;
  address: string;
  distance?: string;
  onClick: () => void;
}

export function SearchServiceItem({
  serviceArea,
  address,
  distance,
  onClick,
}: ServiceItemProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        height: "69px",
        width: "100%",
        alignItems: "center",
      }}
      onClick={onClick}
    >
      <img
        style={{
          width: "28px",
          height: "28px",
        }}
        src={IMAGE_PIN_PURPLE}
        alt={"pin"}
      />
      <HorizontalSpacer width={12} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          height: "45px",
          overflow: "hidden",
        }}
      >
        <Text
          sx={{
            fontStyle: fontStyle.bodyLr,
            color: color.default,
          }}
          text={serviceArea}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {distance !== undefined && (
            <>
              <Typography
                sx={{
                  fontStyle: fontStyle.subTitleS,
                  color: color.default,
                }}
              >
                {distance}
              </Typography>
              <HorizontalSpacer width={8} />
            </>
          )}
          <Text
            sx={{
              fontStyle: fontStyle.subTitleS,
              color: color.gray600,
            }}
            text={address}
          />
        </Box>
      </Box>
      <HorizontalSpacer width={12} />
      <img
        style={{
          width: "24px",
          height: "24px",
        }}
        src={ICON_RIGHT_ARROW_GRAY600}
        alt={"pin"}
      />
    </Box>
  );
}
