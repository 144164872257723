export class NFTDetailUiState {
  nftCharacterName: string = "";
  distributeDate: number = 0;
  country: string = "";
  rewardOptions: CouponInfo[] = [];
  registrationDate: number = 0;
  lastModifiedDate: number = 0;

  // NFTLevelInfo
  levelInfos: NFTLevelInfo[] = Array.from({ length: 5 }, (_, i) => ({
    level: i + 1,
    levelName: "",
    image: undefined,
    maxExp: "",
    uuid: "",
    coupon: {
      id: "",
      name: "",
    },
    action: "unchanged",
    id: ""
  }));

  //validation
  errors = {
    nftCharacterNameError: false,
    distributeDateError: false,
    countryError: false,
    levelInfosError: Array.from({ length: 5 }, () => ({
      levelNameError: false,
      levelImageError: false,
      maxExpError: false,
      rewardError: false,
    })),
  };
  constructor(
    nftCharacterName: string = "",
    distributeDate: number = 0,
    country: string = "",
    rewardOptions: CouponInfo[] = [],
    registrationDate: number = 0,
    lastModifiedDate: number = 0,
    levelInfos: NFTLevelInfo[] = Array.from({ length: 5 }, (_, i) => ({
      level: i + 1,
      levelName: "",
      image: undefined,
      maxExp: "",
      uuid: "",
      coupon: {
        id: "",
        name: "",
      },
       action: "unchanged",
       id: ""
    })),
    errors = {
      nftCharacterNameError: false,
      distributeDateError: false,
      countryError: false,
      levelInfosError: Array.from({ length: 5 }, () => ({
        levelNameError: false,
        levelImageError: false,
        maxExpError: false,
        rewardError: false,
      })),
    },
  ) {
    this.nftCharacterName = nftCharacterName;
    this.distributeDate = distributeDate;
    this.country = country;
    this.rewardOptions = rewardOptions;
    this.levelInfos = levelInfos;
    this.errors = errors;
    this.registrationDate = registrationDate;
    this.lastModifiedDate = lastModifiedDate;
  }
}

export interface NFTLevelInfo {
  action: string;
  level: number;
  levelName: string;
  image: File | undefined;
  maxExp: string;
  // uuid: string;
  coupon: CouponInfo;
  id: string;
}

export interface CouponInfo {
  id: string;
  name: string;
}

