import { useCallback, useContext, useEffect, useState } from "react";
import axios from "axios";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { ReactNode } from "react";
import { styled } from "styled-components";
import { KeyboardArrowDown } from "@mui/icons-material";

import {
  Typography,
  Table,
  TableRow,
  TableCell,
  Box,
  IconButton,
  Card,
  CardContent,
  CardHeader,
  TextField,
  MenuItem,
  Select,
  Grid,
} from "@mui/material";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import MonitoringReductionTable from "../../section/MonitoringReductionTable";
import { ParamType } from "../../../../store/paramType";
import { ValueListItem } from "../../paramData/ParamDetailsPage";
import { SERVER_CONFIG } from "../../../../constants/ServerConfig";
import { StandardEnum } from "../../../../store/paramType";
import { MonitoringContext } from "../../../../di/MonitoringProvider";

interface CustomTypographyProps {
  children: ReactNode;
  sx?: object;
}
interface DataArgumentValueList {
  id: number;
  standard: string;
  valueList: ValueListItem[];
  unit: string;
  definition: string;
  methodologyId: number;
  selectedValue?: { id: string; item: string; value: number };
  name: NameType;
  selectedArg?: {
    valueList: Array<{ id: string; item: string; value: number }>;
  };
}

interface DataArgument {
  fixed: DataArgumentValueList[];
  change: DataArgumentValueList[];
  result: DataArgumentValueList[];
}
interface NameType {
  unit: string;
  definition: string;
}

const MonitoringCalcPage: React.FC<any> = ({
  id,
  formData,
  setFormData,
  handleTextChange,
  isNew = false,
  error: propsError,
}) => {
  const { tmpBaseline, tmpProject, setTmpBaseline, setTmpProject } =
    useContext(MonitoringContext);

  const [baselineArgs, setBaselineArgs] = useState<DataArgument>({
    fixed: [] as DataArgumentValueList[],
    change: [] as DataArgumentValueList[],
    result: [] as DataArgumentValueList[],
  });
  const [projectArgs, setProjectArgs] = useState<DataArgument>({
    fixed: [] as DataArgumentValueList[],
    change: [] as DataArgumentValueList[],
    result: [] as DataArgumentValueList[],
  });

  const dataArgumentInit = {
    id: "",
    name: "",
    standard: "",
    valueList: [],
    selectedValue: null,
    unit: "",
    definition: "",
  };

  const API_URL_ARG = SERVER_CONFIG.CARBON_SERVER + "/data-argument";
  const API_URL_ARGS = API_URL_ARG + "/monitoring";

  const requestArgParam = (purpose: string, methId: string) => {
    const standard = `standard=${StandardEnum.FIXED}&standard=${StandardEnum.CHANGE}&standard=${StandardEnum.RESULT}`;

    return axios.get(
      `${API_URL_ARGS}?purpose=${purpose}&${standard}&methodologyId=${methId}`
    );
  };

  const [baselineArgsList, setBaselineArgsList] = useState<Array<any>>([]);
  const [projectArgsList, setProjectArgsList] = useState<Array<any>>([]);

  const fetchMonitoringData = useCallback(
    async () => {
      const methodologyId = formData.methodologyId;

      if (!methodologyId) {
        console.error("Methodology ID is undefined.");
        return;
      }

      try {
        const { data: baselineData } = await requestArgParam(
          ParamType.BASELINE,
          methodologyId
        );
        const { data: projectData } = await requestArgParam(
          ParamType.PROJECT,
          methodologyId
        );

        setBaselineArgs({
          fixed: baselineData.filter(
            (e: any) => e.standard === StandardEnum.FIXED
          ),
          change: baselineData.filter(
            (e: any) => e.standard === StandardEnum.CHANGE
          ),
          result: baselineData.filter(
            (e: any) => e.standard === StandardEnum.RESULT
          ),
        });
        setProjectArgs({
          fixed: projectData.filter(
            (e: any) => e.standard === StandardEnum.FIXED
          ),
          change: projectData.filter(
            (e: any) => e.standard === StandardEnum.CHANGE
          ),
          result: projectData.filter(
            (e: any) => e.standard === StandardEnum.RESULT
          ),
        });
      } catch (error) {
        console.error("Error fetching monitoring data:", error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [baselineArgsList, projectArgsList]
  );

  const changeFormArrayValue = (
    listName: string,
    index: number,
    fieldName: string,
    value: any
  ) => {
    const logic = (prev: DataArgumentValueList[]) => {
      const newList = prev.map((el, idx) => {
        if (index === idx) {
          return { ...el, [fieldName]: value };
        }
        return el;
      });
      return newList;
    };

    if (listName === "baseline") {
      setBaselineArgsList(logic);
    } else if (listName === "project") {
      setProjectArgsList(logic);
    }
  };

  useEffect(() => {
    fetchMonitoringData();
    if (!id) {
      setBaselineArgsList(
        tmpBaseline?.length > 0 ? tmpBaseline : [dataArgumentInit]
      );
      setProjectArgsList(
        tmpProject?.length > 0 ? tmpProject : [dataArgumentInit]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    /* 목록 테이블 데이터 */
    setFormData((prev: any) => ({
      ...prev,
      dataArgumentValueIdList: {
        baseline: [
          ...Array.from(
            new Set(baselineArgsList?.map((el: any) => el?.selectedValue?.id))
          ).filter((el) => !!el),
        ],
        project: [
          ...Array.from(
            new Set(projectArgsList?.map((el: any) => el?.selectedValue?.id))
          ).filter((el) => !!el),
        ],
      },
    }));
    if (baselineArgsList?.length > 0) {
      setTmpBaseline(baselineArgsList);
    }
    if (projectArgsList?.length > 0) {
      setTmpProject(projectArgsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baselineArgsList, projectArgsList]);

  useEffect(() => {
    async function processArgData() {
      if (!formData.dataArgumentValueList) {
        return;
      }

      try {
        const argDataList = async (field: any) =>
          await Promise.all(
            formData.dataArgumentValueList[field].map(async (el: any) => {
              const { data } = await axios.get(
                `${API_URL_ARG}/${el.dataArgumentId}`
              );
              return {
                id: el.dataArgumentId,
                selectedValue: {
                  id: el.id,
                  item: el.item,
                  value: el.value,
                },
                standard: el.standard,
                name: el.name,
                unit: el.unit,
                definition: el.definition,
                purpose: el.purpose,
                valueList: data?.data?.valueList,
              };
            })
          );

        /* Baseline */
        const baselineData = await argDataList("baseline");
        /* Project */
        const projectData = await argDataList("project");

        const baseline = tmpBaseline?.length > 0 ? tmpBaseline : baselineData;
        const project = tmpProject?.length > 0 ? tmpProject : projectData;

        setBaselineArgsList(
          baseline.length > 0 ? baseline : [dataArgumentInit]
        );
        setProjectArgsList(project.length > 0 ? project : [dataArgumentInit]);
      } catch (error) {
        console.error("Error processing argument data:", error);
      }
    }

    if (baselineArgsList?.length <= 1 && projectArgsList?.length <= 1) {
      processArgData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.dataArgumentValueList]);

  const generateArgsFormArray = (
    list: DataArgumentValueList[],
    listType: "baseline" | "project" | "result",
    changeFormArrayValue: (
      listType: string,
      index: number,
      field: string,
      value: any
    ) => void
  ) => {
    return (
      <Table>
        <TableRow>
          <TableCell>구분</TableCell>
          <TableCell>데이터/인자</TableCell>
          {listType === "baseline" ||
          listType === "project" ||
          listType === "result" ? (
            <TableCell>항목</TableCell>
          ) : (
            ""
          )}
          {listType === "baseline" || listType === "project" ? (
            <TableCell>Value</TableCell>
          ) : (
            ""
          )}

          <TableCell>단위</TableCell>
          <TableCell>정의</TableCell>
          <TableCell></TableCell>
        </TableRow>
        {list.map((arg, idx) => {
          const argList = listType === "baseline" ? baselineArgs : projectArgs;
          const argStandardList =
            argList[arg.standard.toLocaleLowerCase() as keyof DataArgument];

          return (
            <TableRow key={idx} sx={{ border: 0 }}>
              <TableCell padding="none" sx={{ width: "15%", border: 0 }}>
                <Select
                  value={arg.standard}
                  onChange={(e) => {
                    changeFormArrayValue(
                      listType,
                      idx,
                      "standard",
                      e.target.value
                    );
                    changeFormArrayValue(listType, idx, "name", null);
                    changeFormArrayValue(listType, idx, "selectedArg", null);
                    changeFormArrayValue(listType, idx, "selectedValue", null);
                  }}
                  name="standard"
                  size="small"
                  IconComponent={(props) => (
                    <KeyboardArrowDown {...props} sx={{ fontSize: "16px" }} />
                  )}
                  sx={{
                    width: "100%",

                    opacity: 1,
                    borderRadius: "10px",

                    gap: "2px",
                    backgroundColor: "#ffff",
                    "&:hover": {
                      borderColor: "#F9F6FD",
                    },
                  }}
                  style={{ borderRadius: "7px" }}
                >
                  <MenuItem value={"FIXED"}>고정</MenuItem>
                  <MenuItem value={"CHANGE"}>변동</MenuItem>
                  <MenuItem value={"RESULT"}>결과</MenuItem>
                </Select>
              </TableCell>
              <TableCell
                padding="none"
                sx={{ width: "15%", padding: "4px", border: 0 }}
              >
                <Select
                  value={arg?.name}
                  name="name"
                  onChange={(e: any) => {
                    changeFormArrayValue(listType, idx, "name", e.target.value);
                    changeFormArrayValue(
                      listType,
                      idx,
                      "selectedArg",
                      e.target.value
                    );
                    console.log(" e.target.value", e.target.value);
                    changeFormArrayValue(
                      listType,
                      idx,
                      "selectedValue",
                      e.target.value?.valueList[0]
                    );
                  }}
                  size="small"
                  IconComponent={(props) => (
                    <KeyboardArrowDown {...props} sx={{ fontSize: "16px" }} />
                  )}
                  sx={{
                    width: "100%",
                    opacity: 1,
                    borderRadius: "10px",
                    gap: "2px",
                    backgroundColor: "#ffff",
                    "&:hover": {
                      borderColor: "#F9F6FD",
                    },
                  }}
                  style={{ borderRadius: "7px" }}
                  renderValue={(val: any) => {
                    return val.name || val;
                  }}
                >
                  {argStandardList?.flatMap((menuItem: any) => (
                    <MenuItem key={menuItem.id} value={menuItem}>
                      {menuItem.name}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
              <>
                <TableCell
                  padding="none"
                  sx={{ width: "15%", padding: "4px", border: 0 }}
                >
                  <Select
                    value={arg.selectedValue}
                    name="selectedValue"
                    onChange={(e) =>
                      changeFormArrayValue(
                        listType,
                        idx,
                        "selectedValue",
                        e.target.value
                      )
                    }
                    size="small"
                    disabled={arg.standard === "CHANGE"}
                    IconComponent={(props) => (
                      <KeyboardArrowDown {...props} sx={{ fontSize: "16px" }} />
                    )}
                    sx={{
                      width: "100%",
                      opacity: 1,
                      borderRadius: "10px",
                      gap: "2px",
                      backgroundColor:
                        arg.standard === "CHANGE" ? "#E9E9EA" : "#ffff",
                      "&:hover": {
                        borderColor:
                          arg.standard === "CHANGE" ? "transparent" : "#F9F6FD",
                      },
                    }}
                    style={{ borderRadius: "7px" }}
                    renderValue={(val) => {
                      const selected = arg?.selectedArg?.valueList.find(
                        (item: any) => item.id === val.id
                      );
                      return selected
                        ? selected.item
                        : arg.selectedValue
                          ? arg.selectedValue.item
                          : "";
                    }}
                  >
                    {arg?.selectedArg?.valueList?.map(
                      (item: any, idx: number) => (
                        <MenuItem key={item.id} value={item}>
                          {item.item}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </TableCell>
                <TableCell
                  padding="none"
                  sx={{ width: "10%", padding: "4px", border: 0 }}
                >
                  <TextField
                    sx={{
                      width: "100%",
                      backgroundColor:
                        arg.standard === "CHANGE" ? "#E9E9EA" : "#E9E9EA",
                      opacity: arg.standard === "CHANGE" ? 0.5 : 1,
                    }}
                    disabled={true}
                    size="small"
                    value={arg?.selectedValue?.value}
                    name="value"
                    InputProps={{ style: { borderRadius: "7px" } }}
                  />
                </TableCell>
                <TableCell
                  padding="none"
                  sx={{ width: "15%", padding: "4px", border: 0 }}
                >
                  <TextField
                    sx={{
                      width: "100%",
                      backgroundColor: "#E9E9EA",
                      opacity: arg.standard === "CHANGE" ? 0.5 : 1,
                    }}
                    size="small"
                    disabled={true}
                    value={arg?.name?.unit || ""}
                    name="unit"
                    InputProps={{ style: { borderRadius: "7px" } }}
                  />
                </TableCell>
                <TableCell
                  padding="none"
                  sx={{ width: "25%", padding: "4px", border: 0 }}
                >
                  <TextField
                    sx={{
                      width: "100%",
                      backgroundColor: "#E9E9EA",
                      opacity: arg.standard === "CHANGE" ? 0.5 : 1,
                    }}
                    size="small"
                    disabled={true}
                    value={arg?.name?.definition}
                    name="definition"
                    InputProps={{ style: { borderRadius: "7px" } }}
                  />
                </TableCell>
              </>

              <TableCell
                padding="none"
                sx={{ width: "5%", padding: "4px", border: 0 }}
              >
                <IconButton
                  onClick={() => {
                    if (listType === "baseline") {
                      setBaselineArgsList((prev) => {
                        const _newList = [...prev];
                        _newList.splice(idx, 1);
                        return _newList;
                      });
                    } else if (listType === "project") {
                      setProjectArgsList((prev) => {
                        const _newList = [...prev];
                        _newList.splice(idx, 1);
                        return _newList;
                      });
                    }
                  }}
                >
                  <DoNotDisturbOnIcon sx={{ fontSize: 20 }} />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}
      </Table>
    );
  };

  return (
    <>
      <Card
        sx={{
          marginTop: "20px",
          borderRadius: "20px",
        }}
      >
        <CardContent>
          <Box
            sx={{
              m: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CustomTypography sx={{ fontWeight: "bold" }}>
              베이스라인 데이터/인자
            </CustomTypography>
          </Box>
          <Box
            sx={{
              m: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {generateArgsFormArray(
              baselineArgsList,
              "baseline",
              changeFormArrayValue
            )}
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <IconButton
                onClick={() =>
                  setBaselineArgsList((prev) => [
                    ...prev,
                    {
                      ...dataArgumentInit,
                    },
                  ])
                }
              >
                <AddCircleIcon sx={{ fontSize: 40 }} />
              </IconButton>
            </Box>
            {/* <Box sx={{ width: "100%", mt: 5 }}>
              <CustomTypography sx={{ fontWeight: "bold" }}>
                베이스라인 배출량(흡수량) 산정 수식 설명
              </CustomTypography>
              <Box sx={{ mt: 2 }}>
                <TextEditor
                  setData={(val: string) =>
                    handleTextChange("formulaDescriptionBaseline", val)
                  }
                  minHeight={120}
                  data={formData.formulaDescriptionBaseline}
                />
              </Box>
            </Box> */}
          </Box>
        </CardContent>
      </Card>
      <Card
        sx={{
          marginTop: "20px",
          borderRadius: "20px",
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CustomTypography sx={{ fontWeight: "bold" }}>
              프로젝트 데이터/인자 정보
            </CustomTypography>
          </Box>
          <Box
            sx={{
              m: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {generateArgsFormArray(
              projectArgsList,
              "project",
              changeFormArrayValue
            )}
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <IconButton
                onClick={() =>
                  setProjectArgsList((prev) => [
                    ...prev,
                    {
                      ...dataArgumentInit,
                    },
                  ])
                }
              >
                <AddCircleIcon sx={{ fontSize: 40 }} />
              </IconButton>
            </Box>
            {/* <Box sx={{ width: "100%", mt: 5 }}>
              <CustomTypography sx={{ fontWeight: "bold" }}>
                프로젝트 배출량(흡수량) 산정 수식 설명
              </CustomTypography>
              <Box sx={{ mt: 2 }}>
                <TextEditor
                  setData={(val: string) =>
                    handleTextChange("formulaDescriptionProject", val)
                  }
                  minHeight={120}
                  data={formData.formulaDescriptionProject}
                />
              </Box>
            </Box> */}
          </Box>
        </CardContent>
      </Card>
      <Grid item lg={12}>
        <Card
          sx={{
            borderRadius: 3,
            minHeight: 240,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            mt: 2,
          }}
        >
          <CardContent sx={{ width: "100%", height: "100%" }}>
            <CardHeader
              title={"모니터링 데이터 조회"}
              titleTypographyProps={{ fontWeight: "bold", fontSize: 19 }}
            />
            <MonitoringReductionTable
              projectId={formData.projectId}
              formDataStartDate={formData.startDate}
              formDataEndData={formData.endDate}
            />
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

const CustomTypography: React.FC<CustomTypographyProps> = ({
  children,
  ...props
}) => {
  return (
    <Typography
      {...props}
      sx={{
        color: "#99989D",
        ...props.sx,

        lineHeight: "18px",
        fontWeight: 700,
      }}
    >
      {children}
    </Typography>
  );
};

export default MonitoringCalcPage;
