export class AdditionalInfoUiState {
  isShowExitDialog: boolean = false;
  isShowCompleteDialog: boolean = false;

  day: string = "";
  month: string = "";
  year: string = "";
  gender: string = "";
  country: string = "";
  cityCountyDistrict: string = "";
  cityDetail: string = "";

  dayError: string = "";
  monthError: string = "";
  yearError: string = "";
  genderError: string = "";
  countryError: string = "";
  cityCountyDistrictError: string = "";
  cityDetailError: string = "";

  // Api를 위한 데이터(화면 상 필요X)
  localNumber: string = "";
  phoneNumber: string = "";
  tribeCategory: string = "";
  tribeName: string = "";
}
