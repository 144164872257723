import { TableRow } from "@mui/material";

import { ProductBOMTreeTableCell } from "./ProductBOMTreeTableCell";
import { Text } from "../../../../component/Text";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import { ProductionManagementCellData } from "../../model/ProductionManagementCellData";
import { ProductBOMData } from "../../section/productionmanagement/production/section/model/ProductData";

interface ProductBOMTreeTableRowProps {
  row: ProductBOMData;
}

export function ProductBOMTreeTableRow({ row }: ProductBOMTreeTableRowProps) {
  const productCellData: ProductionManagementCellData[] = [
    new ProductionManagementCellData(`${row.category}`, "left", "81px", "4px"),
    new ProductionManagementCellData(`${row.partsCode}`, "left", "102px"),
    new ProductionManagementCellData(`${row.productName}`, "left", "120px"),
    new ProductionManagementCellData(`${row.spec}`, "left"),
    new ProductionManagementCellData(`${row.quantities}`, "left", "77px"),
    new ProductionManagementCellData(`${row.necessary}`, "left", "77px"),
    new ProductionManagementCellData(`${row.amount}`, "left", "67px"),
    new ProductionManagementCellData(`${row.company}`, "left", "108px"),
  ];

  return (
    <TableRow>
      {productCellData.map((data: ProductionManagementCellData, index: number) => (
        <ProductBOMTreeTableCell
          key={index}
          sx={{
            paddingLeft: data.paddingLeft,
            paddingRight: data.paddingRight,
            width: data.width || "auto",
            ...(index === productCellData.length - 5 ? { flex: 1, maxWidth: "232px" } : {}),
          }}
          childrenContainerSx={{ width: data.width }}
          align={data.align}
          children={
            <Text
              sx={{
                fontStyle: fontStyle.regularXS,
                color: data.value === `${row.amount}` && row.amount === 0 ? color.error : color.gray800,
              }}
              text={data.value}
            />
          }
        />
      ))}
    </TableRow>
  );
}
