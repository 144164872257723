import { Box, SelectChangeEvent } from "@mui/material";
import CommonList from "../../component/CommonList";
import { CouponSettingsTab } from "./CouponSettingsTab";
import CommonSearch from "../../component/CommonSearch";
import { useEffect, useState } from "react";
import { CommonSelect } from "../../component/CommonSelect";
import VerticalSpacer, { HorizontalSpacer } from "../../component/Spacer";
import CouponManagementRender from "./CouponManagementRender";
import { ApiService } from "../../restAPI/ApiService";
import { CouponManagementUiState } from "./model/CouponManagementUiState";
import { CouponManagementUiEvent } from "./model/CouponManagementUiEvent";

const CouponManagement = () => {
  const [searchContents, setSearchContents] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [type, setType] = useState("");
  const [uiState, setUistate] = useState(new CouponManagementUiState());
  const [country, setCountry] = useState<string>("");
  const countrytoCode: {
    "South Korea": string;
    Cambodia: string;
    Vietnam: string;
  } = {
    "South Korea": "KR",
    Cambodia: "KH",
    Vietnam: "VN",
  };

  const getCountryCode = (countryName: string): string => {
    return countrytoCode[countryName as keyof typeof countrytoCode] || countryName;
  };

  const ValidationCheck = () => {
    const errors = {
      name: uiState.name.length === 0,
      country: uiState.country === "Select",
      type: uiState.type === "Select",
      availableDays: uiState.availableDays.length === 0,
      description: uiState.description.length === 0,
    };

    setUistate((prevState) => ({
      ...prevState,
      inValidDataCheck: errors, // inValidDataCheck를 업데이트
    }));
    return Object.values(errors).includes(true);
  };

  const uiEvent: CouponManagementUiEvent = {
    HandleUiStateChange: (key: keyof CouponManagementUiState, value: any) => {
      setUistate((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    },

    HandleCreateCoupon() {
      const isValid = ValidationCheck();

      if (!isValid) {
        ApiService.WebPost("/pw/aw/product/coupon/create", {
          uuid: localStorage.getItem("user_uuid"),
          country: getCountryCode(uiState.country),
          name: uiState.name,
          type: uiState.type,
          availableDays: uiState.availableDays,
          description: uiState.description,
        }).then((res) => {
          window.location.reload();
          console.log("uiStatecountry : ", uiState.country, country);
        });
      }
    },

    HandleUpdateCoupon() {
      // ValidationCheck 함수 호출
      const isValid = ValidationCheck();

      if (!isValid) {
        ApiService.WebPost("/pw/aw/product/coupon/update", {
          country: getCountryCode(uiState.country),
          name: uiState.name,
          description: uiState.description,
          id: uiState.id,
          type: uiState.type,
          uuid: localStorage.getItem("user_uuid"),
          availableDays: uiState.availableDays,
        }).then((res) => {
          window.location.reload();
          console.log("country : ", uiState.country, country);
        });
      }
    },
  };

  const handleCountryChange = (event: SelectChangeEvent) => {
    const selectedLabel = event.target.value;
    const selectedCountry = uiState.countryList.find((item) => item.label === selectedLabel);

    if (selectedCountry) {
      setCountry(selectedCountry.label);
      setCountryCode(selectedCountry.value);
    }
  };

  const handleTypeChange = (event: SelectChangeEvent) => {
    setType(event.target.value as string);
  };

  useEffect(() => {
    ApiService.WebPost("/pw/aw/contents/getCountry").then((res) => {
      const countryList =
        res?.data.body.map((item: any) => ({
          label: `${item.label} (${item.code})`,
          value: item.code,
        })) || [];
      const updatedCountryList = [{ label: "All", value: "" }, ...countryList];
      uiEvent.HandleUiStateChange("countryList", updatedCountryList);
    });
  }, []);

  useEffect(() => {
    const selectedType = type === "All" ? "" : type;
    ApiService.WebPost("/pw/aw/product/coupon/filter", {
      country: countryCode,
      searchContents: searchContents,
      type: selectedType,
    }).then((res) => {
      uiEvent.HandleUiStateChange("couponDataRows", res?.data.body);
      console.log("data : ", uiState.couponDataRows);
    });
  }, [type, countryCode]);

  return (
    <CommonList title="Coupon Settings" tabButton={<CouponSettingsTab />}>
      <CommonSearch
        placeHolder="Name, Availability Days, Description"
        searchContents={searchContents}
        setSearchContents={setSearchContents}
        onKeyPress={() => {
          const selectedType = type === "All" ? "" : type;
          ApiService.WebPost("/pw/aw/product/coupon/filter", {
            country: countryCode,
            searchContents: searchContents,
            type: selectedType,
          }).then((res) => {
            uiEvent.HandleUiStateChange("couponDataRows", res?.data.body);
            console.log("data : ", uiState.couponDataRows);
          });
        }}
      >
        <Box sx={{ display: "flex" }}>
          <CommonSelect
            sx={{ minWidth: "103px", height: "42px" }}
            selectValue={country}
            onChange={handleCountryChange}
            menuitems={uiState.countryList?.map((item) => item.label)}
            placeholder="Country"
          />
          <HorizontalSpacer width={12} />
          <CommonSelect
            sx={{ minWidth: "85px", height: "42px" }}
            selectValue={type}
            onChange={handleTypeChange}
            menuitems={["All", "NFT Character", "Welcome Coupon"]}
            placeholder="Type"
          />
        </Box>
      </CommonSearch>
      <VerticalSpacer height={16} />
      <CouponManagementRender uiState={uiState} uiEvent={uiEvent} />
    </CommonList>
  );
};

export default CouponManagement;
