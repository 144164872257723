import { Box, Button, Card, CardHeader, Grid, InputAdornment, Typography } from "@mui/material";
import { UserDetailsUiEvent } from "../../../UserDetailsUiEvent";
import { UserDetailsUiState } from "../../../UserDetailsUiState";
import { color } from "../../../../../theme/Color";
import { fontStyle } from "../../../../../theme/Style";
import VerticalSpacer from "../../../../../component/Spacer";
import CommonTextField from "../../../../../component/CommonTextField";
import LocalizedDate, { DateFormatType } from "../../../../../component/LocalizedDate";
import { ApiService } from "../../../../../restAPI/ApiService";

interface UserScooterMaintenanceCardProps {
  uiState: UserDetailsUiState;
  uiEvent: UserDetailsUiEvent;
}
const UserScooterMaintenanceCard = ({ uiState, uiEvent }: UserScooterMaintenanceCardProps) => {
  const handleUpdateClick = (key: string, value: number) => {
    uiEvent.HandleUiStateChange("addLoadingState", true);
    if (!(key in uiState)) {
      console.error(`Invalid key: ${key}`);
      return; // key가 uiState에 없는 경우 함수 종료
    }

    // 현재 값을 가져와 업데이트
    let warrantyValue = (uiState as any)[key] + value;

    const updateData = {
      serialNumber: uiState.selectScooterSN,
      [key]: warrantyValue, // 동적으로 key를 설정
    };

    ApiService.MobilePost("/pw/backend/api/scooter/updateWarranty", updateData).then((res) => {
      if (res?.data.body) {
        uiEvent.HandleUiStateChange("addLoadingState", false);
      }
    });
  };

  return (
    <>
      <Grid item lg={12} sx={{ mt: "16px" }}>
        <Card
          sx={{
            borderRadius: "15px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            p: "24px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <CardHeader title="Maintenance" titleTypographyProps={{ fontStyle: fontStyle.semiboldL }} sx={{ p: "0px" }} />
          </Box>
          <VerticalSpacer height={24} />
          <Box sx={{ display: "flex", justifyContent: "space-between", gap: "20px" }}>
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Date of Warranty
              </Typography>
              <CommonTextField
                value={LocalizedDate({
                  date: uiState?.dateOfWarranty,
                  type: DateFormatType.AdminType,
                })}
                // onChange={(event) => {}}
                sx={{ height: "48px", mt: "8px", width: "100%" }}
                InputProps={{
                  sx: {
                    height: "48px",
                  },
                }}
                disabled
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Brake Pad Front
              </Typography>
              <CommonTextField
                value={LocalizedDate({
                  date: uiState?.warrantyOfBrakePadFront,
                  type: DateFormatType.AdminType,
                })}
                onChange={(event) => {}}
                sx={{ height: "48px", mt: "8px" }}
                InputProps={{
                  sx: {
                    height: "48px",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      {/* <SearchIcon /> */}

                      <Box
                        sx={{
                          display: uiState?.idCardImageURL ? "flex" : uiState.IDCard ? "flex" : "none",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "60px",
                          height: "22px",
                          my: 0,
                          borderRadius: "22px",
                          fontStyle: fontStyle.semiboldXXS,
                          color: "white",
                          bgcolor: color.gray600,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleUpdateClick("warrantyOfBrakePadFront", 24 * 60 * 60 * 540 * 1000);
                        }} // 클릭 시 이미지 미리보기
                      >
                        Update
                      </Box>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  readOnly: true,
                }}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Brake Pad Rear
              </Typography>
              <CommonTextField
                value={LocalizedDate({
                  date: uiState?.warrantyOfBrakePadRear,
                  type: DateFormatType.AdminType,
                })}
                onChange={(event) => {}}
                sx={{ height: "48px", mt: "8px" }}
                InputProps={{
                  sx: {
                    height: "48px",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      {/* <SearchIcon /> */}

                      <Box
                        sx={{
                          display: uiState?.idCardImageURL ? "flex" : uiState.IDCard ? "flex" : "none",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "60px",
                          height: "22px",
                          my: 0,
                          borderRadius: "22px",
                          fontStyle: fontStyle.semiboldXXS,
                          color: "white",
                          bgcolor: color.gray600,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleUpdateClick("warrantyOfBrakePadRear", 24 * 60 * 60 * 365 * 1000);
                        }} // 클릭 시 이미지 미리보기
                      >
                        Update
                      </Box>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  readOnly: true,
                }}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Brake Oil
              </Typography>
              <CommonTextField
                value={LocalizedDate({
                  date: uiState?.warrantyOfBrakeOil,
                  type: DateFormatType.AdminType,
                })}
                onChange={(event) => {}}
                sx={{ height: "48px", mt: "8px" }}
                InputProps={{
                  sx: {
                    height: "48px",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      {/* <SearchIcon /> */}

                      <Box
                        sx={{
                          display: uiState?.idCardImageURL ? "flex" : uiState.IDCard ? "flex" : "none",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "60px",
                          height: "22px",
                          my: 0,
                          borderRadius: "22px",
                          fontStyle: fontStyle.semiboldXXS,
                          color: "white",
                          bgcolor: color.gray600,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleUpdateClick("warrantyOfBrakeOil", 24 * 60 * 60 * 730 * 1000);
                        }} // 클릭 시 이미지 미리보기
                      >
                        Update
                      </Box>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  readOnly: true,
                }}
              />
            </Box>
          </Box>
          <VerticalSpacer height={40} />
          <Box sx={{ display: "flex", justifyContent: "space-between", gap: "20px" }}>
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Tire Front
              </Typography>
              <CommonTextField
                value={LocalizedDate({
                  date: uiState?.warrantyTireFront,
                  type: DateFormatType.AdminType,
                })}
                onChange={(event) => {}}
                sx={{ height: "48px", mt: "8px", width: "100%" }}
                InputProps={{
                  sx: {
                    height: "48px",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      {/* <SearchIcon /> */}

                      <Box
                        sx={{
                          display: uiState?.idCardImageURL ? "flex" : uiState.IDCard ? "flex" : "none",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "60px",
                          height: "22px",
                          my: 0,
                          borderRadius: "22px",
                          fontStyle: fontStyle.semiboldXXS,
                          color: "white",
                          bgcolor: color.gray600,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleUpdateClick("warrantyTireFront", 24 * 60 * 60 * 730 * 1000);
                        }} // 클릭 시 이미지 미리보기
                      >
                        Update
                      </Box>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  readOnly: true,
                }}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Tire Rear
              </Typography>
              <CommonTextField
                value={LocalizedDate({
                  date: uiState?.warrantyTireRear,
                  type: DateFormatType.AdminType,
                })}
                onChange={(event) => {}}
                sx={{ height: "48px", mt: "8px" }}
                InputProps={{
                  sx: {
                    height: "48px",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      {/* <SearchIcon /> */}

                      <Box
                        sx={{
                          display: uiState?.idCardImageURL ? "flex" : uiState.IDCard ? "flex" : "none",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "60px",
                          height: "22px",
                          my: 0,
                          borderRadius: "22px",
                          fontStyle: fontStyle.semiboldXXS,
                          color: "white",
                          bgcolor: color.gray600,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleUpdateClick("warrantyTireRear", 24 * 60 * 60 * 730 * 1000);
                        }} // 클릭 시 이미지 미리보기
                      >
                        Update
                      </Box>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  readOnly: true,
                }}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Disk Brake Plate Front
              </Typography>
              <CommonTextField
                value={LocalizedDate({
                  date: uiState?.warrantyDiskPanelFront,
                  type: DateFormatType.AdminType,
                })}
                onChange={(event) => {}}
                sx={{ height: "48px", mt: "8px" }}
                InputProps={{
                  sx: {
                    height: "48px",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      {/* <SearchIcon /> */}

                      <Box
                        sx={{
                          display: uiState?.idCardImageURL ? "flex" : uiState.IDCard ? "flex" : "none",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "60px",
                          height: "22px",
                          my: 0,
                          borderRadius: "22px",
                          fontStyle: fontStyle.semiboldXXS,
                          color: "white",
                          bgcolor: color.gray600,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleUpdateClick("warrantyDiskPanelFront", 24 * 60 * 60 * 1825 * 1000);
                        }} // 클릭 시 이미지 미리보기
                      >
                        Update
                      </Box>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  readOnly: true,
                }}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Disk Brake Plate Rear
              </Typography>
              <CommonTextField
                value={LocalizedDate({
                  date: uiState?.warrantyDiskPanelRear,
                  type: DateFormatType.AdminType,
                })}
                onChange={(event) => {}}
                sx={{ height: "48px", mt: "8px" }}
                InputProps={{
                  sx: {
                    height: "48px",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      {/* <SearchIcon /> */}

                      <Box
                        sx={{
                          display: uiState?.idCardImageURL ? "flex" : uiState.IDCard ? "flex" : "none",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "60px",
                          height: "22px",
                          my: 0,
                          borderRadius: "22px",
                          fontStyle: fontStyle.semiboldXXS,
                          color: "white",
                          bgcolor: color.gray600,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleUpdateClick("warrantyDiskPanelRear", 24 * 60 * 60 * 1825 * 1000);
                        }} // 클릭 시 이미지 미리보기
                      >
                        Update
                      </Box>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  readOnly: true,
                }}
              />
            </Box>
          </Box>
        </Card>
      </Grid>
    </>
  );
};

export default UserScooterMaintenanceCard;
