import { Autocomplete, Box, SxProps, TextField, Theme } from "@mui/material";
import { color } from "../theme/Color";
import { fontStyle } from "../theme/Style";
import { ICON_SEARCH } from "../constants/appImagePath";
import { HorizontalSpacer } from "./Spacer";

interface CommonComboBoxProps {
  options?: any;
  disabled?: boolean;
  getOptionLabel?: (option: any) => string; // 옵션 라벨 생성 함수 주입
  renderOption?: (props: React.HTMLAttributes<HTMLLIElement>, option: any) => React.ReactNode; // 옵션 렌더링 함수 주입
  onKeyPress?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  onInputChange?: (event: React.ChangeEvent<{}>, value: string) => void;
  onChange?: (event: React.SyntheticEvent, value: any) => void; // 추가된 부분
  placeholder?: string;
  sx?: SxProps<Theme>; // sx prop 추가
}

const CommonComboBox = ({
  options = [],
  disabled,
  getOptionLabel = (option) => option, // 기본값: 옵션을 그대로 사용
  renderOption = (props, option) => <li {...props}>{option}</li>, // 기본값: 단순 텍스트 렌더링
  onKeyPress,
  onInputChange,
  onChange,
  placeholder,
  sx,
}: CommonComboBoxProps) => {
  return (
    <>
      <Autocomplete
        disablePortal
        options={options}
        getOptionLabel={getOptionLabel} // 주입된 함수 사용
        renderOption={renderOption} // 주입된 함수 사용
        componentsProps={
          {
            // paper: {
            //   sx: {
            //     width: "fit-content",
            //   },
            // },
          }
        }
        sx={{
          width: 200,
          height: "42px",
          borderRadius: "10px",
          backgroundColor: "white",
          "& .MuiOutlinedInput-root": {
            px: "7px",
            height: "42px",
            borderRadius: "10px",
            "& fieldset": {
              borderColor: "white", // 기본 보더 색상
            },
            "&:hover fieldset": {
              borderColor: "white",
              //   borderColor: disabled ? color.gray300 : color.primary500, // 호버 시 보더 색상
            },
            "&.Mui-focused fieldset": {
              border: "1px solid",
              //   borderColor: color.primary500,
              borderColor: "white",
            },
            "&.Mui-disabled fieldset": {
              borderColor: "white", // 비활성화 시 border 색상
              color: color.gray300,
            },
            fontSize: fontStyle.regularS.fontSize, // fontSize 추가
            lineHeight: fontStyle.regularS.lineHeight, // lineHeight 추가
            fontWeight: fontStyle.regularS.fontWeight, // fontWeight 추가
          },
          ...sx,
        }}
        renderInput={(params) => (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <HorizontalSpacer width={12} />
            <Box component="img" src={ICON_SEARCH} />
            <TextField placeholder={placeholder || ""} {...params} />
          </Box>
        )}
        onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
          if (event.key === "Enter") {
            event.preventDefault(); // 기본 Enter 동작 방지 (ex. 폼 제출)
            if (onKeyPress) {
              onKeyPress(event);
            }
          }
        }}
        onInputChange={onInputChange} // 추가된 부분
        onChange={(event, value) => {
          if (onChange) {
            onChange(event, value); // 부모에서 처리할 수 있도록 전달
          }
        }}
        disabled={disabled}
      />
    </>
  );
};

export default CommonComboBox;
