import { TableCell, TableRow, Typography } from "@mui/material";
import CommonTable, { HeadCell, useVisibleRows } from "../../../../component/CommonTable";
import { RegionManagementTableRow } from "./RegionManagementTableRow";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";

interface RegionManagementTableProps {
  headCells?: HeadCell[];
  mngRows?: any;
  rowsPerPage?: number;
  setRowsPerPage?: React.Dispatch<React.SetStateAction<number>>;
  page?: number;
  pageCount?: number;
  setPage?: any;
  docCount?: any;
}

export function RegionManagementTable({
  headCells,
  mngRows,
  rowsPerPage,
  setRowsPerPage,
  docCount,
  pageCount,
  page,
  setPage,
}: RegionManagementTableProps) {
  const { order, orderBy, handleRequestSort, handleChangePage, visibleRows } = useVisibleRows(mngRows, 10, setPage, page);

  return (
    <CommonTable
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      page={page}
      docCount={docCount}
      pageCount={pageCount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      handleRequestSort={handleRequestSort}
      handleChangePage={handleChangePage}
      setPage={setPage}
    >
      {visibleRows?.length == 0 ? (
        <TableRow sx={{ px: " 4px", height: "1px" }}>
          <TableCell
            colSpan={headCells?.length}
            align="center"
            sx={{
              minWidth: "1032px",
              px: "13px",
              py: "12px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontStyle: fontStyle.regularS,
                color: color.gray600,
              }}
            >
              No Data
            </Typography>
          </TableCell>
        </TableRow>
      ) : (
        visibleRows.map((data: any) => <RegionManagementTableRow row={data} key={data.no} />)
      )}
    </CommonTable>
  );
}
