import { useEffect, useState } from "react";
import { WalletDetailUiEvent } from "./model/WalletDetailUiEvent";
import { WalletDetailUiState } from "./model/WalletDetailUiState";
import { WalletDetailPage } from "./WalletDetailPage";
import { ApiService } from "../../../../restAPI/ApiService";
import { TabType } from "./model/TabType";
import { WalletDetailMapper } from "./mapper/WalletDetailMapper";
import { WalletInfoResponse } from "./model/WalletDetailResponse";
import { useLocation, useNavigate } from "react-router-dom";

export function WalletDetail() {
  const navigate = useNavigate();
  const [uiState, setUiState] = useState(new WalletDetailUiState());
  const [currentTab, setCurrentTab] = useState<TabType>(TabType.NFT);
  const location = useLocation();
  const pathname = location.pathname;
  const id = pathname.split("/").pop() || "";

  const uiEvent: WalletDetailUiEvent = {
    handleUiStateChange: (key: keyof WalletDetailUiState, value: any) => {
      setUiState((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    },

    onClickTab: function (tab: TabType): void {
      setCurrentTab(tab);
      setUiState((prev) => ({
        ...prev,
        page: 0,
      }));
    },

    selectToken: (value: string) => {
      setUiState((prev) => ({
        ...prev,
        walletInfo: {
          ...prev.walletInfo,
          token: value,
        },
      }));
    },

    backButtonClick: () => {
      uiEvent.handleUiStateChange("backDialogOpen", true);
    },

    backClose: () => {
      uiEvent.handleUiStateChange("backDialogOpen", false);
    },
    backOk: () => {
      navigate("/wallet");
      window.location.reload();
    },
  };

  async function getWalletDetail(
    walletId: string
  ): Promise<WalletInfoResponse | undefined> {
    const url = "";
    const requestBody = {
      walletId: walletId,
    };

    try {
      const res = await ApiService.webPost(url, requestBody);
      const data = res.data;
      if (typeof data === "object" && Object.keys(data).length === 0)
        return undefined;
      return data;
    } catch (e) {
      setUiState((prev) => ({
        ...prev,
        page: 0,
        pageCount: 1,
      }));
      return undefined;
    }
  }
  useEffect(() => {
    const fetchWalletDetail = async () => {
      try {
        if (!id) {
        }
        const walletInfo = await getWalletDetail(id);
        if (walletInfo === undefined) {
          console.log("Wallet not found");
          return;
        }
        setUiState(WalletDetailMapper(walletInfo));
      } catch (e) {
        console.log("Wallet not found1");
      }
    };

    fetchWalletDetail();
  }, [id]);

  return (
    <WalletDetailPage
      uiState={uiState}
      uiEvent={uiEvent}
      currentTab={currentTab}
    />
  );
}
