import { Backdrop, Box, Button, CircularProgress, Typography } from "@mui/material";
import CommonComboBox from "../../../../../component/CommonComboBox";
import { ApiService } from "../../../../../restAPI/ApiService";
import { CommonSelect } from "../../../../../component/CommonSelect";
import VerticalSpacer, { HorizontalSpacer } from "../../../../../component/Spacer";
import CommonNationComboBox, { NationComboBoxMode } from "../../../../../component/CommonNationComboBox";
import ProvinceSelectBox from "../../../../../pagesMobile/MobileProfileEdit/section/ProvinceSelectBox";
import CommonDateRange from "../../../../../component/CommonDateRange";
import { useEffect, useState } from "react";
import { Dayjs } from "dayjs";
import { fontStyle } from "../../../../../theme/Style";
import { color } from "../../../../../theme/Color";
import BatteryReplacementRentalTable from "./component/BatteryReplacementRentalTable";
import BatteryReplacementReturnTable from "./component/BatteryReplacementReturnTable";
import { ICON_DOWNLOAD } from "../../../../../constants/imagePath";
import { CheckboxState, CommonCheckbox } from "../../../../../component/CommonCheckbox";
import CommonTextField from "../../../../../component/CommonTextField";

const BatteryReplacementPage = () => {
  const [docCount, setDocCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // csv 다운로드 로딩동안 띄워줄 프로그래스바 상태관리 변수
  const [downloadLoading, setDownloadLoading] = useState(false);

  // 필터링을 위해 필요한 변수
  const [stationName, setStationName] = useState("");
  const [batterySN, setBatterySN] = useState("");

  // 우측 상단 Date Range 관련 변수
  const [dateRange, setDateRange] = useState<{ startDate: Dayjs | null; endDate: Dayjs | null }>({
    startDate: null,
    endDate: null,
  });

  function handleBtnSearch() {
    setPage(0);
    if (tabVelue == "Rental") {
      const bttReplaceData = {
        stationName: stationName,
        bty_sn: batterySN,
        startDate: dateRange.startDate ? dateRange.startDate.valueOf() : 0,
        endDate: dateRange.endDate ? dateRange.endDate.valueOf() + 24 * 3600 * 1000 : 0,
        page: page,
        viewUnpublishedData: viewUnpublish,
      };
      ApiService.webPost("/pw/csms/data/battery-raw/REN", bttReplaceData).then((res) => {
        if (res.data) {
          setRows(res.data.body);
          if (res?.data.docCount > 10000) {
            setPageCount(Math.ceil(10000 / rowsPerPage) || 1);
            setDocCount(10000);
          } else {
            setPageCount(Math.ceil(res?.data.docCount / rowsPerPage) || 1);
            setDocCount(res?.data.docCount || 0);
          }
        }
      });
    } else if (tabVelue == "Return") {
      const bttReplaceData = {
        stationName: stationName,
        bty_sn: batterySN,
        startDate: dateRange.startDate ? dateRange.startDate.valueOf() : 0,
        endDate: dateRange.endDate ? dateRange.endDate.valueOf() + 24 * 3600 * 1000 : 0,
        page: page,
        viewUnpublishedData: viewUnpublish,
      };
      ApiService.webPost("/pw/csms/data/battery-raw/RET", bttReplaceData).then((res) => {
        if (res.data) {
          setRows(res.data.body);
          if (res?.data.docCount > 10000) {
            setPageCount(Math.ceil(10000 / rowsPerPage) || 1);
            setDocCount(10000);
          } else {
            setPageCount(Math.ceil(res?.data.docCount / rowsPerPage) || 1);
            setDocCount(res?.data.docCount || 0);
          }
        }
      });
    }
  }

  const [tabVelue, setTabVelue] = useState("Rental");
  const [viewUnpublish, setViewUnpublish] = useState(false);

  useEffect(() => {
    setPage(0);
    setBatterySN("");
    setStationName("");
    setDateRange({ startDate: null, endDate: null });
  }, [tabVelue]);

  useEffect(() => {
    if (tabVelue == "Rental") {
      const bttReplaceData = {
        stationName: stationName,
        bty_sn: batterySN,
        startDate: dateRange.startDate ? dateRange.startDate.valueOf() : 0,
        endDate: dateRange.endDate ? dateRange.endDate.valueOf() + 24 * 3600 * 1000 : 0,
        page: page,
        viewUnpublishedData: viewUnpublish,
      };
      ApiService.webPost("/pw/csms/data/battery-raw/REN", bttReplaceData).then((res) => {
        if (res.data) {
          setRows(res.data.body);
          if (res?.data.docCount > 10000) {
            setPageCount(Math.ceil(10000 / rowsPerPage) || 1);
            setDocCount(10000);
          } else {
            setPageCount(Math.ceil(res?.data.docCount / rowsPerPage) || 1);
            setDocCount(res?.data.docCount || 0);
          }
        }
      });
    } else if (tabVelue == "Return") {
      const bttReplaceData = {
        stationName: stationName,
        bty_sn: batterySN,
        startDate: dateRange.startDate ? dateRange.startDate.valueOf() : 0,
        endDate: dateRange.endDate ? dateRange.endDate.valueOf() + 24 * 3600 * 1000 : 0,
        page: page,
        viewUnpublishedData: viewUnpublish,
      };
      ApiService.webPost("/pw/csms/data/battery-raw/RET", bttReplaceData).then((res) => {
        if (res.data) {
          setRows(res.data.body);
          if (res?.data.docCount > 10000) {
            setPageCount(Math.ceil(10000 / rowsPerPage) || 1);
            setDocCount(10000);
          } else {
            setPageCount(Math.ceil(res?.data.docCount / rowsPerPage) || 1);
            setDocCount(res?.data.docCount || 0);
          }
        }
      });
    }
  }, [tabVelue, page, viewUnpublish]);

  const csvDownload = () => {
    return (
      <Box
        component="img"
        src={ICON_DOWNLOAD}
        sx={{ cursor: "pointer" }}
        onClick={() => {
          setDownloadLoading(true);
          if (tabVelue == "Rental") {
            const searchCondition = {
              stationName: stationName,
              bty_sn: "",
              startDate: dateRange.startDate ? dateRange.startDate.valueOf() : 0,
              endDate: dateRange.endDate ? dateRange.endDate.valueOf() + 24 * 3600 * 1000 : 0,
              viewUnpublishedData: viewUnpublish,
            };
            ApiService.webPost("/pw/csms/data/battery-raw-csv-file/REN", searchCondition, {
              responseType: "blob",
            }).then((res) => {
              const blob = new Blob([res.data], { type: "text/csv;charset=utf-8;" });
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download = "Battery rental data.csv"; // 파일 이름 설정
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              setDownloadLoading(false);
            });
          } else if (tabVelue == "Return") {
            const searchCondition = {
              stationName: stationName,
              bty_sn: "",
              startDate: dateRange.startDate ? dateRange.startDate.valueOf() : 0,
              endDate: dateRange.endDate ? dateRange.endDate.valueOf() + 24 * 3600 * 1000 : 0,
              viewUnpublishedData: viewUnpublish,
            };
            ApiService.webPost("/pw/csms/data/battery-raw-csv-file/RET", searchCondition, {
              responseType: "blob",
            }).then((res) => {
              const blob = new Blob([res.data], { type: "text/csv;charset=utf-8;" });
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download = "Battery return data.csv"; // 파일 이름 설정
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              setDownloadLoading(false);
            });
          }
        }}
      />
    );
  };

  return (
    <>
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={downloadLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ display: "flex", alignItems: "center" }} draggable={true}>
        <Box
          sx={{
            bgcolor: tabVelue == "Rental" ? color.primary200 : "none",
            cursor: "pointer",
            fontStyle: fontStyle.semiboldS,
            color: color.primary500,
            px: "8px",
            py: "8px",
            borderRadius: "10px",
          }}
          onClick={() => {
            setTabVelue("Rental");
          }}
        >
          Rental
        </Box>
        <HorizontalSpacer width={16} />
        <Box
          sx={{
            bgcolor: tabVelue == "Return" ? color.primary200 : "none",
            cursor: "pointer",
            fontStyle: fontStyle.semiboldS,
            color: color.primary500,
            px: "8px",
            py: "8px",
            borderRadius: "10px",
          }}
          onClick={() => {
            setTabVelue("Return");
          }}
        >
          Return
        </Box>
        <HorizontalSpacer width={16} />
        {localStorage.getItem("user_role") == "Lead" && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CommonCheckbox
              checkboxState={viewUnpublish ? CheckboxState.Checked : CheckboxState.Unchecked}
              checkboxName=""
              onClick={(newState) => {
                console.log(newState);
                if (newState == CheckboxState.Checked) {
                  setViewUnpublish(true);
                } else if (newState == CheckboxState.Unchecked) {
                  setViewUnpublish(false);
                }
              }}
              sx={{ height: "16px", width: "16px", margin: 0 }}
            />
            <HorizontalSpacer width={4} />
            <Typography
              sx={{
                fontStyle: fontStyle.regularXS,
                color: color.gray600,
              }}
            >
              Viewing Unpublished Data
            </Typography>
          </Box>
        )}
      </Box>
      <VerticalSpacer height={16} />
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <CommonTextField
            value={stationName}
            onChange={(event) => {
              setStationName(event.target.value);
              // // console.log(event.target.value);
            }}
            sx={{
              width: "50%",
              height: "42px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "10px",
                "& fieldset": {
                  borderColor: "white", // 기본 border 색상
                },
                "&:hover fieldset": {
                  borderColor: "white", // hover 시 border 색상
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white", // 포커스 시 border 색상
                  borderWidth: "1px",
                },
                "&.Mui-disabled fieldset": {
                  // backgroundColor: color.gray100,
                  borderColor: "white", // 비활성화 시 border 색상
                },
              },
            }}
            InputProps={{
              sx: {
                height: "42px",
                backgroundColor: "white",
              },
            }}
            placeholder="Station name"
            disabled={batterySN ? true : false}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === "Enter") {
                event.preventDefault(); // 기본 Enter 동작 방지 (ex. 폼 제출)
                handleBtnSearch();
              }
            }}
          />
          {/* <HorizontalSpacer width={16} />
          <CommonTextField
            value={batterySN}
            onChange={(event) => {
              setBatterySN(event.target.value);
              // // console.log(event.target.value);
            }}
            sx={{
              width: "25%",
              height: "42px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "10px",
                "& fieldset": {
                  borderColor: "white", // 기본 border 색상
                },
                "&:hover fieldset": {
                  borderColor: "white", // hover 시 border 색상
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white", // 포커스 시 border 색상
                  borderWidth: "1px",
                },
                "&.Mui-disabled fieldset": {
                  // backgroundColor: color.gray100,
                  borderColor: "white", // 비활성화 시 border 색상
                },
              },
            }}
            InputProps={{
              sx: {
                height: "42px",
                backgroundColor: "white",
              },
            }}
            placeholder="Battery S/N"
            disabled={tabVelue == "Rental" ? true : stationName ? true : false}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === "Enter") {
                event.preventDefault(); // 기본 Enter 동작 방지 (ex. 폼 제출)
                handleBtnSearch();
              }
            }}
          /> */}
          <HorizontalSpacer width={16} />
          <CommonDateRange
            value={dateRange}
            onChange={(newValue) => setDateRange(newValue)}
            views={["year", "month", "day"]}
            textFieldSx={{
              // width: "170px",
              height: "42px",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white", // 기본 보더 색상
                },
                "&:hover fieldset": {
                  borderColor: "white",
                },
                "&.Mui-focused fieldset": {
                  border: "1px solid",
                  borderColor: "white",
                },
              },
            }}
            InputPropsSx={{ height: "42px" }}
          />
          <HorizontalSpacer width={16} />
          <Button
            sx={{
              backgroundColor: "#5D38E5",
              borderRadius: 5,
              ":hover": {
                backgroundColor: "#A38BFC",
              },
              color: "white",
              textTransform: "none",
              width: "85px",
              height: "40px",
            }}
            onClick={handleBtnSearch}
          >
            Search
          </Button>
        </Box>
      </Box>
      <VerticalSpacer height={16} />
      <BatteryReplacementRentalTable
        tabVelue={tabVelue}
        rows={rows}
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        csvDownload={csvDownload()}
      />
      {/* {tabVelue == "Rental" && (
        
      )} */}
      {/* {tabVelue == "Return" && (
        <BatteryReplacementReturnTable
          rows={rows}
          docCount={docCount}
          rowsPerPage={rowsPerPage}
          pageCount={pageCount}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
      )} */}
    </>
  );
};

export default BatteryReplacementPage;
