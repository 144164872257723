import { Box, TableCell, TableRow, Typography } from "@mui/material";
import CommonTable, { useVisibleRows } from "../../../component/CommonTable";
import { fontStyle } from "../../../theme/Style";
import { useNavigate } from "react-router-dom";
import LocalizedDate, { DateFormatType } from "../../../component/LocalizedDate";
import { color } from "../../../theme/Color";

interface RowProps {
  row: any;
  index: any;
}

const Row = ({ row, index }: RowProps) => {
  const navigate = useNavigate();
  const rowData = row;
  return (
    <>
      <TableRow
      // sx={{ cursor: "pointer" }}
      // onClick={() => {
      //   navigate(`/usrMng/usrDetails/${rowData.uuid}`);
      // }}
      >
        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{rowData.imei}</Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Box fontStyle={fontStyle.regularXXS}>{rowData.vehicleType}</Box>
        </TableCell>
        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{rowData.sysSoc}</Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{rowData.speedMode}</Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{rowData.speedKmh}</Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{rowData.singleRideMile}</Typography>
        </TableCell>
        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{rowData.runTotalMile}</Typography>
        </TableCell>
        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{rowData.remainingMileage}</Typography>
        </TableCell>
        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{rowData.runTime}</Typography>
        </TableCell>
        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{rowData.vehicleStatus}</Typography>
        </TableCell>
        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{rowData.errorCode}</Typography>
        </TableCell>
        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{rowData.lockState}</Typography>
        </TableCell>
        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{rowData.leverStatus}</Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0, width: "150px" }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {rowData.timestamp == 0 ? (
              <></>
            ) : (
              <>
                {LocalizedDate({
                  date: row.timestamp,
                  type: DateFormatType.AdminType,
                })}
              </>
            )}
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
};

interface StatisticsScooterTableProps {
  mngRows?: any;
  rowsPerPage?: any;
  setRowsPerPage?: any;
  page?: any;
  pageCount?: any;
  setPage?: any;
  docCount?: number;
}

const StatisticsScooterTable = ({ mngRows, rowsPerPage, setRowsPerPage, docCount, page, pageCount, setPage }: StatisticsScooterTableProps) => {
  const headCells = [
    {
      id: "imei",
      align: "left",
      disablePadding: false,
      label: "Imei",
    },
    {
      id: "vehicleType",
      align: "center",
      disablePadding: false,
      label: "Type",
    },
    {
      id: "sysSoc",
      align: "left",
      disablePadding: false,
      label: "Soc",
    },
    {
      id: "speedMode",
      align: "left",
      disablePadding: false,
      label: "Mode",
    },
    {
      id: "speedKmh",
      align: "left",
      disablePadding: false,
      label: "Speed",
    },
    {
      id: "singleRideMile",
      align: "left",
      disablePadding: false,
      label: "Drive Distance",
    },
    {
      id: "runTotalMile",
      align: "left",
      disablePadding: false,
      label: "Total Mile",
    },
    {
      id: "remainingMileage",
      align: "left",
      disablePadding: false,
      label: "Remain Mileage",
    },
    {
      id: "runTime",
      align: "left",
      disablePadding: false,
      label: "Run Time",
    },
    {
      id: "vehicleStatus",
      align: "left",
      disablePadding: false,
      label: "Vehicle Status",
    },
    {
      id: "errorCode",
      align: "left",
      disablePadding: false,
      label: "Error",
    },
    {
      id: "lockState",
      align: "left",
      disablePadding: false,
      label: "Lock",
    },
    {
      id: "leverStatus",
      align: "left",
      disablePadding: false,
      label: "Lever Status",
    },
    {
      id: "timestamp",
      align: "left",
      disablePadding: false,
      label: "Report Date",
    },
  ];

  const { order, orderBy, handleRequestSort, handleChangePage, visibleRows } = useVisibleRows(mngRows, rowsPerPage, setPage, page);

  return (
    <CommonTable
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      page={page}
      docCount={docCount}
      pageCount={pageCount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      handleRequestSort={handleRequestSort}
      handleChangePage={handleChangePage}
      setPage={setPage}
    >
      {visibleRows?.length == 0 ? (
        <TableRow sx={{ px: " 4px", height: "1px" }}>
          <TableCell
            colSpan={headCells?.length}
            align="center"
            sx={{
              minWidth: "1032px",
              px: "13px",
              py: "12px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontStyle: fontStyle.regularS,
                color: color.gray600,
              }}
            >
              No Data
            </Typography>
          </TableCell>
        </TableRow>
      ) : (
        visibleRows.map((row: any, index: any) => <Row row={row} index={index} key={index} />)
      )}
    </CommonTable>
  );
};

export default StatisticsScooterTable;
